import { Component, OnInit } from '@angular/core';
import { Session } from 'src/app/globals/session';
import { FeatureCollectionModel } from 'src/app/models/geojson/feature-collection-model';
import { GeometryModel } from 'src/app/models/geojson/geometry-model';
import { SessionModel } from 'src/app/models/session/session-model';
import { GeojsonService } from 'src/app/services/geojson.service';
import { SessionService } from 'src/app/services/session.service';
import { MapService } from 'src/app/shared/map/map.service';
import { StripState, StripStoreService } from './strip-store.service';

@Component({
  selector: 'app-strip-entry',
  templateUrl: './strip-entry.component.html',
  styleUrls: ['./strip-entry.component.css']
})
export class StripEntryComponent implements OnInit {

  constructor(private stripStore: StripStoreService, public session: Session) { }
  ngOnInit(): void {
    this.isShowBackgroundStrip = this.stripStore.state.isShowBackgroundStrip;
    this.stripStore.watch(this.setBackgroundStrip.bind(this))
  }

  toggleState(){
    this.setBackgroundStrip({
      isShowBackgroundStrip: !this.stripStore.state.isShowBackgroundStrip
    })
  }

  isShowBackgroundStrip = false;

  setBackgroundStrip(stripState: StripState) {
    this.isShowBackgroundStrip = stripState.isShowBackgroundStrip;
    this.stripStore.state = {
      isShowBackgroundStrip: stripState.isShowBackgroundStrip
    }
    // this.showBackgroundStrip(!this.isShowBackgroundStrip);
  }

}
