import {Component} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {JourneyStoreService} from './journey-store.service';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-journey-entry',
  templateUrl: './journey-entry.component.html',
  styleUrls: ['./journey-entry.component.css']
})
export class JourneyEntryComponent{

  constructor(public journeyStore: JourneyStoreService, public session: Session) { }

  close(){
    this.journeyStore.state = {
      isShowJourneys: false
    }
    Matomo.push(['trackEvent', 'Actions', 'Closed journey menu'])
  }

  openJourney() {
    this.journeyStore.state = {
      isShowJourneys: true
    }
    Matomo.push(['trackEvent', 'Actions', 'Opened journey menu'])
  }
}
