import * as turf from "@turf/turf";
import {GeometryHelper} from "./geometry.helper";
import {GeometryModel} from "../models/geojson/geometry-model";
import {GeometryTypeEnum} from "../enums/geometry-type-enum.enum";
import {RoadNode} from "@mycitymagine/ts-citymagine-core";
import {ArrayHelper} from "./array.helper";
import {FeatureCollectionModel} from "../models/geojson/feature-collection-model";

export class LineStringHelper {

  static orderByClosestEnding(currentLinestring: GeometryModel, otherLinestrings: GeometryModel[]): GeometryModel[] {
    otherLinestrings.sort((a, b) =>
        (LineStringHelper.getDistanceBetweenEndingLineStrings(currentLinestring,a) > LineStringHelper.getDistanceBetweenEndingLineStrings(currentLinestring,b)) ? 1 : -1);

    return otherLinestrings;
  }

  static getClosestEndingLineString(currentLinestring: GeometryModel, otherLinestrings: GeometryModel[]): GeometryModel {
    let minDistance: number;
    let result: GeometryModel;

    for (let linestring of otherLinestrings) {
      if (!GeometryHelper.isSame(currentLinestring, linestring) && (minDistance == undefined
          || minDistance > LineStringHelper.getDistanceBetweenEndingLineStrings(currentLinestring, linestring))) {
        minDistance = LineStringHelper.getDistanceBetweenEndingLineStrings(currentLinestring, linestring);
        result = linestring;
      }
    }

    return result;
  }

  static l1MostRightThanl2(currentLineString: GeometryModel, nextLineString1: GeometryModel, nextLineString2: GeometryModel): boolean {
    let currentPoint = LineStringHelper.getEndPoint(currentLineString);

    nextLineString1 = LineStringHelper.checkNeedReverse(currentPoint, nextLineString1) ? LineStringHelper.reverseLineString(nextLineString1) : nextLineString1;
    nextLineString2 = LineStringHelper.checkNeedReverse(currentPoint, nextLineString2) ? LineStringHelper.reverseLineString(nextLineString2) : nextLineString2;

    let nextPoint1 = GeometryHelper.createPoint(nextLineString1.coordinates[1]);
    let nextPoint2 = GeometryHelper.createPoint(nextLineString2.coordinates[1]);

    let currentBearing = GeometryHelper.getBearing(GeometryHelper.createPoint(currentLineString.coordinates[currentLineString.coordinates.length - 2]), currentPoint);
    let next1Degrees = GeometryHelper.addDegrees(GeometryHelper.getBearing(currentPoint, nextPoint1), -currentBearing);
    let next2Degrees = GeometryHelper.addDegrees(GeometryHelper.getBearing(currentPoint, nextPoint2), -currentBearing);

    return next1Degrees > next2Degrees;
  }

  static sliceLineStringPure(startPoint: GeometryModel, endPoint: GeometryModel, linestring_o: GeometryModel): GeometryModel {
    let slice;
    if (startPoint == undefined)
      startPoint = LineStringHelper.getStartPoint(linestring_o);
    if (endPoint == undefined)
      endPoint = LineStringHelper.getEndPoint(linestring_o);
    let linestring = GeometryHelper.createLineString(JSON.parse(JSON.stringify(linestring_o.coordinates)), {...linestring_o.properties});

    slice = turf.lineSlice(startPoint.toTurf(), endPoint.toTurf(), linestring.toTurf());

    if (linestring_o.coordinates[0][2] !== undefined) {
      // preserve z
      slice.geometry.coordinates[0][2] = slice.geometry.coordinates[1][2];
      // @ts-ignore
      slice.geometry.coordinates[slice.geometry.coordinates.length - 1][2] = slice.geometry.coordinates[slice.geometry.coordinates.length - 2][2];
    }
    return GeometryHelper.createLineString(slice.geometry.coordinates, linestring.properties);
  }

  static cleanCoords(linestring: GeometryModel) {
    return GeometryHelper.createLineString(turf.cleanCoords(linestring.toTurf()).coordinates, linestring.properties)
  }

  static getDistanceBetweenEndingLineStrings(linestring1: GeometryModel, linestring2: GeometryModel): number {
    let result: number;

    if (GeometryHelper.isValidGeometryType(linestring1, GeometryTypeEnum.LINESTRING)
        && GeometryHelper.isValidGeometryType(linestring2, GeometryTypeEnum.LINESTRING)) {

      // end1 <> start2
      result = GeometryHelper.getDistance(LineStringHelper.getEndPoint(linestring1), LineStringHelper.getStartPoint(linestring2));

      // start1 <> start2
      let start1_start2 = GeometryHelper.getDistance(LineStringHelper.getStartPoint(linestring1), LineStringHelper.getStartPoint(linestring2));
      if (start1_start2 < result) {
        result = start1_start2;
      }

      // end1 <> end2
      let end1_end2 = GeometryHelper.getDistance(LineStringHelper.getEndPoint(linestring1), LineStringHelper.getEndPoint(linestring2));
      if (end1_end2 < result) {
        result = end1_end2;
      }

      // start1 <> end2
      let start1_end2 = GeometryHelper.getDistance(LineStringHelper.getStartPoint(linestring1), LineStringHelper.getEndPoint(linestring2));
      if (start1_end2 < result) {
        result = start1_end2;
      }
    }

    return result;
  }

  static getClosestLineStrings(point:GeometryModel, linestrings: GeometryModel[], limit: number):GeometryModel[] {
    let result: GeometryModel[] = [];
    for (let linestring of linestrings) {
      if (LineStringHelper.getDistanceToPoint(point,linestring) <= limit) {
        result.push(linestring);
      }
    }
    return LineStringHelper.orderByClosest(point, result);
  }

  static orderByClosest(point:GeometryModel, linestrings: GeometryModel[]): GeometryModel[] {
    linestrings.sort((a, b) =>
        (LineStringHelper.getDistanceToPoint(point,a) > LineStringHelper.getDistanceToPoint(point,b)) ? 1 : -1);
    return linestrings;
  }

  static orderByClosestLL(point: GeometryModel, linestringNodes: RoadNode<GeometryModel>[]): RoadNode<GeometryModel>[] {
    linestringNodes.forEach(value => value.dt.properties["tmp"] = LineStringHelper.getDistanceToPoint(point, value.dt));
    linestringNodes.sort((a, b) =>
        (a.dt.properties > b.dt.properties) ? 1 : -1); // Todo remove tmp var
    return linestringNodes;
  }

  static getClosetPointOnLineString(point: GeometryModel, linestring: GeometryModel): GeometryModel {
    let coordsAsVectors: GeometryModel[] = [...linestring.coordinates.slice(0, -1)].map((value, i) => GeometryHelper.createLineString([value, linestring.coordinates[i + 1]]))
    let ln: GeometryModel = coordsAsVectors.sort((a, b) =>
      (LineStringHelper.getDistanceToPoint(point, a) > LineStringHelper.getDistanceToPoint(point, b)) ? 1 : -1)[0];
    return GeometryHelper.getDistance(point, GeometryHelper.createPoint(ln.coordinates[0])) < GeometryHelper.getDistance(point, GeometryHelper.createPoint(ln.coordinates[1])) ?
      GeometryHelper.createPoint(ln.coordinates[0]) : GeometryHelper.createPoint(ln.coordinates[1])
  }

  static orderByClosestPointOnLine(point: GeometryModel, linestring: GeometryModel): GeometryModel[] {
    let coordsAsPoints: GeometryModel[] = [...linestring.coordinates].map(value => GeometryHelper.createPoint(value))
    return coordsAsPoints.sort((a, b) =>
      (GeometryHelper.getDistance(point, a) > GeometryHelper.getDistance(point, b)) ? 1 : -1);
  }

  static getPointAlong(linestring: GeometryModel, distance: number): GeometryModel {
    return this.getClosetPointOnLineString(this.getAlong(linestring, distance), linestring);
  }

  static getIndexPointAlong(linestring: GeometryModel, distance: number): number {
    let point: GeometryModel = LineStringHelper.getPointAlong(linestring, distance);
    return ArrayHelper.deepIndexOf(linestring.coordinates, [point.coordinates[0], point.coordinates[1]])
  }


  static getDistanceToPoint(point:GeometryModel, linestring: GeometryModel):number {
      return turf.pointToLineDistance(point.toTurf(), linestring.toTurf(), {units: 'meters'});
  }

  static getAlong(linestring:GeometryModel, distance: number):GeometryModel {
    let result = turf.along(linestring.toTurf(), distance, {units: 'meters'});

    if (result) {
      return GeometryHelper.createPoint(result.geometry.coordinates);
    }
  }

  static getStartPoint(linestring: GeometryModel): GeometryModel {
    if (GeometryHelper.isValidGeometryType(linestring, GeometryTypeEnum.LINESTRING)) {
      return GeometryHelper.createPoint(linestring.coordinates[0]);
    } else if (GeometryHelper.isValidGeometryType(linestring, GeometryTypeEnum.MULTILINESTRING)) {
      return GeometryHelper.createPoint(linestring.coordinates[0][0]);
    }
  }

  static getEndPoint(linestring: GeometryModel): GeometryModel {
    if (GeometryHelper.isValidGeometryType(linestring, GeometryTypeEnum.LINESTRING)) {
      return GeometryHelper.createPoint(linestring.coordinates[linestring.coordinates.length-1]);

    } else if (GeometryHelper.isValidGeometryType(linestring, GeometryTypeEnum.MULTILINESTRING)) {
      let lastLineString = linestring.coordinates[linestring.coordinates.length-1];
      return GeometryHelper.createPoint(lastLineString[lastLineString.length-1]);

    }
  }

  static getEndingBounds(geometry: GeometryModel): GeometryModel {
    let geometryXmin: GeometryModel = LineStringHelper.getXminEndingPoint(geometry);
    let geometryYmin: GeometryModel = LineStringHelper.getYminEndingPoint(geometry);
    let geometryXmax: GeometryModel = LineStringHelper.getXmaxEndingPoint(geometry);
    let geometryYmax: GeometryModel = LineStringHelper.getYmaxEndingPoint(geometry);

    let polygonBbox = GeometryHelper.createBbox(geometryXmin.coordinates[0],
        geometryYmin.coordinates[1],
        geometryXmax.coordinates[0],
        geometryYmax.coordinates[1]);

    return polygonBbox;
  }

  static getLineStringsWithEndingPoint(endingPoint: GeometryModel, linestrings: GeometryModel[]): GeometryModel[] {
    let result: GeometryModel[] = [];

    for (let linestring of linestrings) {
      if (GeometryHelper.isValidGeometryType(linestring, GeometryTypeEnum.LINESTRING)) {
        if (LineStringHelper.isEndingPoint(endingPoint, linestring)) {
          result.push(linestring);
        }
      }
    }

    return result;
  }

  static isStartPoint(point: GeometryModel, linestring: GeometryModel): boolean {
    return (linestring.coordinates[0][0] == point.coordinates[0]
        && linestring.coordinates[0][1] == point.coordinates[1]);
  }

  static isEndPoint(point: GeometryModel, linestring: GeometryModel): boolean {
    return (linestring.coordinates[linestring.coordinates.length-1][0] == point.coordinates[0]
        && linestring.coordinates[linestring.coordinates.length-1][1] == point.coordinates[1]);
  }

  static isEndingPoint(point: GeometryModel, linestring: GeometryModel): boolean {
    return (LineStringHelper.isStartPoint(point, linestring)
              || LineStringHelper.isEndPoint(point, linestring));
  }

  static getRightLineString(currentLineString: GeometryModel, nextLineString1: GeometryModel, nextLineString2: GeometryModel): GeometryModel {
    let currentPoint = LineStringHelper.getEndPoint(currentLineString);

    nextLineString1 = LineStringHelper.checkNeedReverse(currentPoint, nextLineString1)?LineStringHelper.reverseLineString(nextLineString1):nextLineString1;
    nextLineString2 = LineStringHelper.checkNeedReverse(currentPoint, nextLineString2)?LineStringHelper.reverseLineString(nextLineString2):nextLineString2;

    let nextPoint1 = GeometryHelper.createPoint(nextLineString1.coordinates[1]);
    let nextPoint2 = GeometryHelper.createPoint(nextLineString2.coordinates[1]);

    let currentBearing = GeometryHelper.getBearing(GeometryHelper.createPoint(currentLineString.coordinates[currentLineString.coordinates.length-2]), currentPoint);
    let next1Degrees = GeometryHelper.addDegrees(GeometryHelper.getBearing(currentPoint, nextPoint1),-currentBearing);
    let next2Degrees = GeometryHelper.addDegrees(GeometryHelper.getBearing(currentPoint, nextPoint2),-currentBearing);

    if (next1Degrees > next2Degrees) {
      return nextLineString1;
    } else {
      return nextLineString2;
    }
  }

  static getCenter(linestring: GeometryModel): GeometryModel {
    return GeometryHelper.createPoint(turf.along(linestring.toTurf(), GeometryHelper.getLength(linestring)/2, {units: "meters"}).geometry.coordinates);
  }

  static checkNeedReverse(currentPoint: GeometryModel, linestring: GeometryModel): boolean {
    return (GeometryHelper.getDistance(currentPoint, LineStringHelper.getStartPoint(linestring))
        > GeometryHelper.getDistance(currentPoint, LineStringHelper.getEndPoint(linestring)));
  }

  static reverseLineString(linestring: GeometryModel): GeometryModel {
    let reverseCoordinates = [];

    for (let i in linestring.coordinates) {
      reverseCoordinates.unshift(linestring.coordinates[i]);
    }
    linestring.coordinates = reverseCoordinates;

    return linestring;
  }

  static extractLineStrings(geometry: GeometryModel): GeometryModel[] {
    let result: GeometryModel[] = [];

    if (GeometryHelper.isValidGeometryType(geometry, GeometryTypeEnum.MULTILINESTRING)) {
      for (let i in geometry.coordinates) {
        result.push(GeometryHelper.createLineString(geometry.coordinates[i]));
      }
    } else if (GeometryHelper.isValidGeometryType(geometry, GeometryTypeEnum.LINESTRING)) {
      result.push(geometry);
    }

    return result;
  }

  static excludeLineString(exclude: GeometryModel, linestrings: GeometryModel[]): GeometryModel[] {
    let result: GeometryModel[] = [];
    if (GeometryHelper.isValidGeometryType(exclude, GeometryTypeEnum.LINESTRING)) {
      for (let linestring of linestrings) {
        if (!GeometryHelper.isSame(exclude, linestring)) {
          result.push(linestring);
        }
      }
    }
    return result;
  }

  static mergeToLineString(geometries: GeometryModel[], properties: any = {}): GeometryModel {
    let coordinates: any[] = [];
    for (let geometry of geometries) {
      if (GeometryHelper.isValidGeometryType(geometry, GeometryTypeEnum.LINESTRING)) {
        coordinates = coordinates.concat(geometry.coordinates);
      } else if (GeometryHelper.isValidGeometryType(geometry, GeometryTypeEnum.POINT)) {
        coordinates.push(geometry.coordinates);
      }
    }
    return GeometryHelper.createLineString(coordinates, properties);
  }

  static mergeFromMultiLineString(multiLineString: GeometryModel): GeometryModel {
    let coordinates: any[] = [];
    for (let i in multiLineString.coordinates) {
        coordinates = coordinates.concat(multiLineString.coordinates[i]);
    }
    return GeometryHelper.createLineString(coordinates);
  }

  static sliceLineString(startPoint: GeometryModel, endPoint: GeometryModel, linestring: GeometryModel): GeometryModel {
    let slice = turf.lineSlice(startPoint.toTurf(), endPoint.toTurf(), linestring.toTurf());
    // preserve z
    slice.geometry.coordinates[0][2] = slice.geometry.coordinates[1][2];
    // @ts-ignore
    slice.geometry.coordinates[slice.geometry.coordinates.length-1][2] = slice.geometry.coordinates[slice.geometry.coordinates.length-2][2];

    return GeometryHelper.createLineString(slice.geometry.coordinates, linestring.properties);
  }

  static getLineOffsetToPolygon(linestring: GeometryModel, distance: number): GeometryModel {
    let polygon = turf.buffer(linestring.toTurf(), distance, {units: 'meters'});
    return GeometryHelper.createPolygon(polygon.geometry.coordinates, linestring.properties);
  }

  static getLineOffset(linestring: GeometryModel, distance: number): GeometryModel {
    let polygon = turf.lineOffset(linestring.toTurf(), distance, {units: 'meters'});
    return GeometryHelper.createLineString(polygon.geometry.coordinates, linestring.properties);
  }

  static getIntersectionPoints(linestring1: GeometryModel, linestring2: GeometryModel): GeometryModel[] {
    let result: GeometryModel[] = [];

    let points = turf.lineIntersect(linestring1.toTurf(), linestring2.toTurf())
    points.features.forEach(value => {
      result.push(GeometryHelper.createPoint(value.geometry.coordinates));
    })

    return result;
  }

  static getNearestPoint(point: GeometryModel, linestring: GeometryModel): GeometryModel {
    let nearest = turf.nearestPointOnLine(linestring.toTurf(), point.toTurf(), {units: 'meters'});
    return GeometryHelper.createPoint(nearest.geometry.coordinates);
  }

  static isJoin(linestring1: GeometryModel, linestring2: GeometryModel, tolerence: number = 5): boolean {
    return (GeometryHelper.getDistance(LineStringHelper.getEndPoint(linestring1), LineStringHelper.getStartPoint(linestring2)) < tolerence);
  }

  static getXminEndingPoint(geometry: GeometryModel): GeometryModel {
    let xMinPoint: GeometryModel = new GeometryModel();

    if (GeometryHelper.isValidGeometryType(geometry, GeometryTypeEnum.MULTILINESTRING)) {
      for (let i in geometry.coordinates) {
        let linestring: GeometryModel = GeometryHelper.createLineString(geometry.coordinates[i]);
        let comparePoint: GeometryModel;
        if (linestring.coordinates[0][0] < linestring.coordinates[linestring.coordinates.length -1][0]) {
          comparePoint = GeometryHelper.createPoint(linestring.coordinates[0]);
        } else {
          comparePoint = GeometryHelper.createPoint(linestring.coordinates[linestring.coordinates.length -1]);
        }

        if (!GeometryHelper.isValidGeometry(xMinPoint) || xMinPoint.coordinates[0] > comparePoint.coordinates[0]) {
          xMinPoint = comparePoint;
        }
      }
    }
    return xMinPoint;
  }

  static getXmaxEndingPoint(geometry: GeometryModel): GeometryModel {
    let xMaxPoint: GeometryModel = new GeometryModel();

    if (GeometryHelper.isValidGeometryType(geometry, GeometryTypeEnum.MULTILINESTRING)) {
      for (let i in geometry.coordinates) {
        let linestring: GeometryModel = GeometryHelper.createLineString(geometry.coordinates[i]);
        let comparePoint: GeometryModel;
        if (linestring.coordinates[0][0] > linestring.coordinates[linestring.coordinates.length -1][0]) {
          comparePoint = GeometryHelper.createPoint(linestring.coordinates[0]);
        } else {
          comparePoint = GeometryHelper.createPoint(linestring.coordinates[linestring.coordinates.length -1]);
        }

        if (!GeometryHelper.isValidGeometry(xMaxPoint) || xMaxPoint.coordinates[0] < comparePoint.coordinates[0]) {
          xMaxPoint = comparePoint;
        }
      }
    }
    return xMaxPoint;
  }

  static getYminEndingPoint(geometry: GeometryModel): GeometryModel {
    let yMinPoint: GeometryModel = new GeometryModel();

    if (GeometryHelper.isValidGeometryType(geometry, GeometryTypeEnum.MULTILINESTRING)) {
      for (let i in geometry.coordinates) {
        let linestring: GeometryModel = GeometryHelper.createLineString(geometry.coordinates[i]);
        let comparePoint: GeometryModel;
        if (linestring.coordinates[0][1] < linestring.coordinates[linestring.coordinates.length -1][1]) {
          comparePoint = GeometryHelper.createPoint(linestring.coordinates[0]);
        } else {
          comparePoint = GeometryHelper.createPoint(linestring.coordinates[linestring.coordinates.length -1]);
        }

        if (!GeometryHelper.isValidGeometry(yMinPoint) || yMinPoint.coordinates[1] > comparePoint.coordinates[1]) {
          yMinPoint = comparePoint;
        }
      }
    }
    return yMinPoint;
  }

  static getYmaxEndingPoint(geometry: GeometryModel): GeometryModel {
    let yMaxPoint: GeometryModel = new GeometryModel();

    if (GeometryHelper.isValidGeometryType(geometry, GeometryTypeEnum.MULTILINESTRING)) {
      for (let i in geometry.coordinates) {
        let linestring: GeometryModel = GeometryHelper.createLineString(geometry.coordinates[i]);
        let comparePoint: GeometryModel;
        if (linestring.coordinates[0][1] > linestring.coordinates[linestring.coordinates.length -1][1]) {
          comparePoint = GeometryHelper.createPoint(linestring.coordinates[0]);
        } else {
          comparePoint = GeometryHelper.createPoint(linestring.coordinates[linestring.coordinates.length -1]);
        }

        if (!GeometryHelper.isValidGeometry(yMaxPoint) || yMaxPoint.coordinates[1] < comparePoint.coordinates[1]) {
          yMaxPoint = comparePoint;
        }
      }
    }
    return yMaxPoint;
  }

  static extendEndLineString(l: GeometryModel, multiplier: number = 1) {
    let dx: number = l.coordinates[l.coordinates.length - 1][0] - l.coordinates[l.coordinates.length - 2][0];
    let dy: number = l.coordinates[l.coordinates.length - 1][1] - l.coordinates[l.coordinates.length - 2][1];

    l.coordinates.push([l.coordinates[l.coordinates.length - 1][0] + (dx * multiplier), l.coordinates[l.coordinates.length - 1][1] + (dy * multiplier)])
  }

  static extendStartLineString(l: GeometryModel, multiplier: number = 1) {
    let dx: number = l.coordinates[0][0] - l.coordinates[1][0];
    let dy: number = l.coordinates[0][1] - l.coordinates[1][1];

    l.coordinates.unshift([l.coordinates[0][0] + (dx * multiplier), l.coordinates[0][1] + (dy * multiplier)])
  }
  static combineLineStringFeaturesCollection(featureCollection: FeatureCollectionModel) {
    let turfFeatureCollection = [];
    featureCollection.features.forEach(feature => {
      turfFeatureCollection.push(turf.lineString(feature.geometry.coordinates));
    });
    let featuresToCombine = turf.featureCollection(turfFeatureCollection);
    return turf.combine(featuresToCombine)
  }
}
