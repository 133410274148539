import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {environment} from 'src/environments/environment';
import {ViewerStoreService} from './viewer-store.service';

declare let gtag: Function;

@Component({
  selector: 'app-viewer-entry',
  templateUrl: './viewer-entry.component.html',
  styleUrls: ['./viewer-entry.component.css']
})
export class ViewerEntryComponent implements OnInit {

  constructor(private viewerStore: ViewerStoreService,
              public session: Session) { }

  ngOnInit(): void {
  }

  showViewer() {
    this.viewerStore.state = {
      isShowViewer: !this.viewerStore.state.isShowViewer,
      isShowGoogle: false,
      mode: '',
      viewIdentifier: ''
    };
    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'MenuViewer');
    }
  }

}
