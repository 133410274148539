import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {StreetlightEntryPanelComponent} from './streetlight-entry-panel/streetlight-entry-panel.component';
import {StreetlightStoreService} from './streetlight-store.service';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-streetlight-entry',
  templateUrl: './streetlight-entry.component.html',
  styleUrls: ['./streetlight-entry.component.css']
})
export class StreetlightEntryComponent implements OnInit {

  constructor(public session: Session, public streetlightStore: StreetlightStoreService, public panelService: PanelService) { }


  ngOnInit(): void {
  }

  openMenuStreetLight(){
    this.panelService.push(
      StreetlightEntryPanelComponent,
      null
    )
  }

  close(event:Event){
    event.preventDefault();
    event.stopPropagation();
    this.streetlightStore.reset();
    Matomo.push(['Removed streelights elements'])
  }

}
