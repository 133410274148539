import {ISerializable} from '../iserializable';
import {FeatureModel} from './feature-model';
import {GeometryModel} from "./geometry-model";

export class FeatureCollectionModel implements ISerializable {

  public type: string = 'FeatureCollection';
  public features: FeatureModel[] = [];
  public properties: object;
  public geometry: GeometryModel;

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.features !== undefined) {
      this.features = input.features.map(feature => new FeatureModel().deserialize(feature));
    }
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
