import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from "../globals/session";
import {ObjectElementModel} from "../models/annotation/object-element-model";
import {DoneResponseModel} from "../models/done-response-model";

@Injectable({
  providedIn: 'root'
})
export class AnnotationService {

  constructor(private session: Session, private httpService: HttpClient) {
  }

  getObjectElement(elementIdentifier: string): Observable<ObjectElementModel> {
    const url = environment.urlAnnotationGetObjectElement;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      elementIdentifier: elementIdentifier
    }, options).pipe(map(data => new ObjectElementModel().deserialize(data['element'])),
      catchError(() => throwError('Connection not possible'))
    );
  }

  updateObjectElement(element: ObjectElementModel): Observable<DoneResponseModel> {
    const url = environment.urlAnnotationUpdateObjectElement;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      elements: [
        element
      ]
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError(() => throwError('Cannot update element'))
    );
  }

  deleteObjectElement(elementIdentifier): Observable<DoneResponseModel> {
    const url = environment.urlAnnotationDeleteObjectElement;
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      elementIdentifier: elementIdentifier
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError(() => throwError('Cannot update element'))
    );
  }

  createObjectElement(element: ObjectElementModel): Observable<DoneResponseModel> {
    const url = environment.urlAnnotationCreateObjectElement;
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      elements: [
        element
      ]
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError(() => throwError('Cannot update element'))
    );
  }
}
