import {IFeatureProperties} from '../geojson/ifeature-properties';
import {ISerializable} from '../iserializable';

export class FeatureObjectPoiModel implements IFeatureProperties, ISerializable {

  public featureType: string;

  public poiIdentifier: string;
  public poiType: string;
  public poiCategory: string;
  public poiCode: string;
  public poiName: string;
  public poiDisplayName: string;
  public poiCounty: string;
  public poiState: string;
  public poiCountry: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
  toExcel(): any {
    let item: any = {};

    item.poiDisplayName = this.poiDisplayName;
    item.poiName = this.poiName;
    item.poiCode = this.poiCode;
    item.poiIdentifier = this.poiIdentifier;

    return item;
  }
}
