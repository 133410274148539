    <div class="ui-g sticky" style="padding-right: 10px;">
      <div class="ui-g-1 titleButton">
        <i class="fa fa-arrow-left" (click)="show(false)"></i>
      </div>
      <div class="ui-g-10">
        <h1>{{ 'visibilityOnDemandEntry.title' | translate }}</h1>
      </div>
      <div class="ui-g-1 titleButton">
        <i class="fa fa-eraser"[ngClass]="{'itemUnchecked':_isShowElement, 'itemDisabled': !_isShowElement}"
           (click)="clear()"></i>
      </div>
    </div>

    <div class="title">{{ 'visibilityOnDemandEntry.profile' | translate }}</div>
    <div style="margin-left:5px">
      <h3 style="width:90%; margin:20px 7px 5px 7px">{{ 'visibilityOnDemandEntry.speedLimit' | translate }} {{speedLimit}}</h3>
      <p-slider [ngModel]="speedLimit" [style]="{'width':'90%'}" [step]="10" [min]="30" [max]="130"
                (onSlideEnd)="setSpeedLimit($event)"></p-slider>
      <h3 style="width:90%; margin:20px 7px 5px 7px">{{ 'visibilityOnDemandEntry.driverHeight' | translate }} {{driverHeight}}</h3>
      <p-slider [ngModel]="driverHeight" [style]="{'width':'90%'}" [step]="0.1" [min]="1" [max]="2.4"
                (onSlideEnd)="setDriverHeight($event)"></p-slider>
      <h3 style="width:90%; margin:20px 7px 5px 7px">{{ 'visibilityOnDemandEntry.wayWidth' | translate }} {{wayWidth}}</h3>
      <p-slider [ngModel]="wayWidth" [style]="{'width':'90%'}" [step]="0.5" [min]="6" [max]="20"
                (onSlideEnd)="setWayWidth($event)"></p-slider>
    </div>
