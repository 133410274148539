<p-treeTable [value]="_treeNodeByElementTypes" [style]="{'margin-top':'20px'}">
  <ng-template pTemplate="header">
    <tr>
      <th align="left">{{ 'statistic.type' | translate }}</th>
      <th width="52px">{{ 'statistic.quality' | translate }}</th>
      <th width="78px">{{ 'statistic.size' | translate }}</th>
      <th width="78px" *ngIf="_userHasGrid">{{ 'statistic.price' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
    <tr>
      <td>
        <span style="display: flex; justify-content: space-between;">
          <p-treeTableToggler *ngIf="displayedElementTypesArray.includes(rowData.elementType)" [rowNode]="rowNode"
                              style="align-self: center;"></p-treeTableToggler>
          <span pTooltip="{{rowData.elementType}}">{{rowData.elementType | truncate: 16: 'end'}}</span>
          <span>({{rowData.quantity | number:'1.0-0'}})</span>
        </span>
      </td>
      <td align="right">
        <span *ngIf="rowData.quality">{{rowData.quality | number:'1.0-0'}}%</span>
      </td>
      <td align="right">
        <span *ngIf="rowData.size !== '-'">{{rowData.size | number:'1.0-0'}} {{rowData.sizeType}}</span>
        <span *ngIf="rowData.size === '-'">{{rowData.size}}</span>
        <br *ngIf="rowData.sizeSquareMeter !== undefined">
        <span
          *ngIf="rowData.sizeSquareMeter !== undefined">{{rowData.sizeSquareMeter | number:'1.0-0'}} {{rowData.sizeTypeSquareMeter}}</span>
      </td>
      <td align="right" *ngIf="_userHasGrid">
        <span *ngIf="rowData.price !== '-'">{{rowData.price | number:'1.0-0'}} {{ _gridCurrency }} </span><span
        *ngIf="rowData.price === '-'">{{rowData.price}}</span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer" let-columns>
    <tr *ngIf="_treeNodeByElementTypes.length === 0" >
        <td [attr.colspan]="_userHasGrid ? 4 : 3">
          {{ 'statistic.nodata' | translate }}
        </td>
    </tr>
  </ng-template>
</p-treeTable>
