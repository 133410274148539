import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonUtils {

  constructor() {}

  static merge(values: any, defaultValues: any = {}): any {
    let result: any = {};

    if (defaultValues) {
      Object.keys(defaultValues)
        .forEach(key => result[key] = defaultValues[key]);
    }

    if (values) {
      Object.keys(values)
        .forEach(key => result[key] = values[key]);
    }

    return result;
  }
}
