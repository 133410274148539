import { Injectable } from '@angular/core';
import { ToastsComponent } from 'src/app/components/main/toasts/toasts.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public toast: ToastsComponent;

  constructor() { }

  setToast(spinner:ToastsComponent){
    this.toast = spinner;
  }

  getToast(){
    return this.toast;
  }
}
