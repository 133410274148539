import {FeatureModel} from "../models/geojson/feature-model";
import {GeometryModel} from "../models/geojson/geometry-model";
import {SegmentTypeEnum} from "../enums/segment-type-enum.enum";
import {FeatureHelper} from "./feature-helper";
import {GeometryHelper} from "./geometry.helper";
import {LineStringHelper} from "./linestring-helper";
import {GraphHelper, getRandomColor, createNodeAnnotation} from "./graph.helper";
import {RoadNode, linkTypeEnum, Side, RoadNodeAnnotation, stateEnum, Lane} from "../linked-list/road-node";
import {RoadList} from "../linked-list/road-list";
import {GeometryTypeEnum} from "../enums/geometry-type-enum.enum";


interface GraduationObject {
  graduations: GeometryModel[],
  tags: GeometryModel[]
};

function allSegmentsAreDone(segments: RoadNode<GeometryModel>[]) {
    return !segments.some(value => value.state !== stateEnum.DONE);
}


function idt(n: RoadNodeAnnotation<GeometryModel>) { // TODO remove this debug function
    return n.dt.properties.segment_identifier;
}

export function msToTime(ms: number) {
    let seconds: number = +(ms / 1000).toFixed(1);
    let minutes: number = +(ms / (1000 * 60)).toFixed(1);
    let hours: number = +(ms / (1000 * 60 * 60)).toFixed(1);
    let days: number = +(ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (seconds < 60) return seconds + " Sec";
    else if (minutes < 60) return minutes + " Min";
    else if (hours < 24) return hours + " Hrs";
    else return days + " Days"
}

function dropDuplicatesSegmentId(arr: GeometryModel[]): GeometryModel[] {
    return arr.filter((thing, index) => {
        const _thing = thing.properties.segment_identifier;
        return index === arr.findIndex(obj => {
            return obj.properties.segment_identifier === _thing;
        });
    });
}

export class ComputeLaneHelper {

    // ioGeojsonService: IoGeojsonService;

    // data: FeatureModel[] = [];

    private roadGraph: GraphHelper;
    private startPoints: Lane<GeometryModel>[] = [];

    private colors = {
        'RIVE': {
            ROUNDABOUT: '#0010FF',
            SUSPENDER: '#00A0FF',
            PATH: '#00FFDD',
            SINGLE_WAY: '#00FF74',
            HIGHWAY: '#2FFF00',
            DOUBLE_WAY: '#BACE1C'
        },
        'AXE': {
            ROUNDABOUT: '#131a86',
            SUSPENDER: '#36627d',
            PATH: '#4c9c91',
            SINGLE_WAY: '#007d36',
            HIGHWAY: '#8dff73',
            DOUBLE_WAY: '#758211'
        }
    };

    private width = {
        'RIVE': {
            ROUNDABOUT: '2.5',
            SUSPENDER: '2.5',
            PATH: '2.5',
            SINGLE_WAY: '2.5',
            HIGHWAY: '2.5',
            DOUBLE_WAY: '2.5'
        },
        'AXE': {ROUNDABOUT: '2', SUSPENDER: '2', PATH: '2', SINGLE_WAY: '2', HIGHWAY: '2', DOUBLE_WAY: '2'}
    };
    response: FeatureModel[] = [];

    constructor() {
    }

    graduate(segment: GeometryModel, distance: number): GraduationObject {
      let currentPosition: number = 0;
      let graduationPoint: GeometryModel;
      let graduations: GeometryModel[] = [];

      let tags: GeometryModel[]= [];

      let i: number = 0;
      let shift: number = 0.01;
      let extendedSegment: GeometryModel = segment.clone();

      LineStringHelper.extendStartLineString(extendedSegment);
      let lenDiff: number = GeometryHelper.getLength(extendedSegment) - GeometryHelper.getLength(segment);

      LineStringHelper.extendEndLineString(extendedSegment);

      while (currentPosition < GeometryHelper.getLength(segment)) {
        graduationPoint = LineStringHelper.getAlong(segment, currentPosition);
        let firstPoint: GeometryModel = LineStringHelper.getAlong(extendedSegment, currentPosition - shift + lenDiff);
        let secondPoint: GeometryModel = LineStringHelper.getAlong(extendedSegment, currentPosition + shift + lenDiff);

        let g: GeometryModel = GeometryHelper.createLineString([firstPoint.coordinates, secondPoint.coordinates]);
        g = GeometryHelper.rotateLineStringAroundPoint(g, graduationPoint, 90);

        let multiplier = 1000;
        if (!(i % 5) || i == 0) {
          if (!(i % 10) || i == 0)
            multiplier *= 2;
          else
            multiplier *= 1.5;
        }

        LineStringHelper.extendEndLineString(g, multiplier);
        LineStringHelper.extendStartLineString(g, multiplier);
        graduations.push(g);

        let t: GeometryModel = new GeometryModel();
        t.type = GeometryTypeEnum.POINT;
        t.coordinates = g.coordinates[g.coordinates.length - 1];
        t.properties = {};
        t.properties.currentDistance = currentPosition;
        tags.push(t);
        currentPosition += distance;
        ++i;
      }
      let graduationObject: GraduationObject = {
        graduations: graduations,
        tags: tags
      };
      return graduationObject;
    }

    process(segments: GeometryModel[]) {





        // For each segments
        if (segments.length == 0) {

            return;
        }

        this.roadGraph = new GraphHelper(segments, createNodeAnnotation, true);
        let graph: RoadList<GeometryModel> = this.roadGraph.graph;

        // ComputeLaneHelper.printGraph(graph);



        //ComputeLaneHelper.changeNextToLinkFromGraph(graph) // This edit the graph for the specific case of the annotation.

        this.computeLanesFromGraph(graph); // TODO fix RangeError: Maximum call stack size exceeded

        for (let sLane of this.startPoints) {
            let lane: GeometryModel = ComputeLaneHelper.mergeLane(sLane);
            this.computeFeature(new RoadNodeAnnotation<GeometryModel>(lane.clone()));
        }

        graph.changeStateFromHeads();
        let roadFullSegmentsList: RoadList<GeometryModel>[] = this.roadGraph.toSubListsFromHeads(graph.getSavedHeads(), graph);
        let roadFullSegmentsMergedArray: GeometryModel[] = GraphHelper.mergeLists(roadFullSegmentsList);

        for (let mergedSegment of roadFullSegmentsMergedArray)
            this.computeAxes(new RoadNodeAnnotation<GeometryModel>(mergedSegment.clone()));


        return this.response;
    }

    private static printGraph(graph: RoadList<GeometryModel>) {

        graph.changeStateFromHeads();
        for (let h of graph.getSavedHeads()) {
            // graph.printFromHead(h); // function disabled in linked-list

        }

    }

    private computeLanesFromGraph(graph: RoadList<GeometryModel>) {
        // node right et left = null
        for (let head of graph.getSavedHeads()) {
            graph.setHead(head);
            this.computeLanesFromHead(graph);
        }
    }

    private computeLanesFromHead(graph: RoadList<GeometryModel>) {
        graph.changeStateFromHead(stateEnum.WAITING, graph.getHead());
        let nodeSide: any;
        nodeSide = ComputeLaneHelper.getMissingLanesNode(graph.getHead() as RoadNodeAnnotation<GeometryModel>);

        while (nodeSide !== undefined) {
            let {node, side} = nodeSide;



            graph.changeStateFromHead(stateEnum.WAITING, graph.getHead());
            let st: any = ComputeLaneHelper.getStartPoint(node, side);
            let {startNode, reversed} = st;



            this.startPoints.push(this.addLane((!nodeSide.side) as unknown as Side, !reversed, startNode));
            this.getRestOfLine(startNode, (!nodeSide.side) as unknown as Side, !reversed);

            graph.changeStateFromHeads();
            nodeSide = ComputeLaneHelper.getMissingLanesNode(graph.getHead() as RoadNodeAnnotation<GeometryModel>);
        }
    }

    private static getMissingLanesNode(node: RoadNodeAnnotation<GeometryModel>): any {
        function getMissingLanesNode__(node: RoadNodeAnnotation<GeometryModel>): any {
            let rv: any;

            if (node.state === stateEnum.DONE)
                return undefined;
            node.state = stateEnum.DONE;
            if (node.getRightSide() === undefined)
                return {node: node, side: Side.RIGHT};
            if (node.getLeftSide() === undefined)
                return {node: node, side: Side.LEFT};

            node.state = stateEnum.DONE;
            for (let n of node.next) {
                rv = getMissingLanesNode__(n as RoadNodeAnnotation<GeometryModel>);
                if (rv !== undefined)
                    return rv;
            }
            for (let p of node.prev) {
                rv = getMissingLanesNode__(p as RoadNodeAnnotation<GeometryModel>);
                if (rv !== undefined)
                    return rv;
            }
            for (let l of node.links) {
                rv = getMissingLanesNode__(l[0] as RoadNodeAnnotation<GeometryModel>);
                if (rv !== undefined)
                    return rv;
            }
            return undefined;
        }

        return getMissingLanesNode__.call(this, node);
    }

    private static getStartPoint(node: RoadNodeAnnotation<GeometryModel>, dir: Side, reversed: boolean = false): any { // If loop then start point is anywhere
        let nextNode: [RoadNodeAnnotation<GeometryModel>, linkTypeEnum] = [node, linkTypeEnum.L_START];
        let nextReversed: boolean = !!(nextNode[1] & linkTypeEnum.L_END);


        while (nextNode !== undefined && nextNode[0] !== undefined && !((nextReversed ? stateEnum.PROCESSING : stateEnum.PROCESSING2) & nextNode[0].state)) {
            node = nextNode[0];
            reversed = nextReversed;
            node.state |= reversed ? stateEnum.PROCESSING : stateEnum.PROCESSING2;
            nextNode = this.getFollowingNode(nextNode[0], dir, reversed);
            if (nextNode !== undefined && nextNode[0] !== undefined) {
                nextReversed = !!(nextNode[1] & linkTypeEnum.L_END);
            }
        }
        return {startNode: node, reversed: reversed};
    }

    getRestOfLine(node: RoadNodeAnnotation<GeometryModel>, side: Side, reversed: boolean = false) {
        let {nextNode, nextReversed} = this.getNext(node, side, reversed);
        if (nextNode !== undefined) {
            this.getRestOfLine(nextNode, side, nextReversed);
        }
    }

    private getNext(node: RoadNodeAnnotation<GeometryModel>, side: Side, reversed: boolean) {
        let nextNode: [RoadNodeAnnotation<GeometryModel>, linkTypeEnum] = ComputeLaneHelper.getFollowingNode(node, side, reversed);
        if (nextNode == undefined || nextNode[0] == undefined)
            return {nextNode: undefined, nextReversed: undefined};
        let nextReversed: boolean = !!(nextNode[1] & linkTypeEnum.L_END);

        if ((((nextReversed ? (!side) as unknown as Side : side) == Side.RIGHT) && nextNode[0].getRightSide() !== undefined) ||
            (((nextReversed ? (!side) as unknown as Side : side) == Side.LEFT) && nextNode[0].getLeftSide() !== undefined)) { // Lane already created
            if ((side == Side.RIGHT) != reversed)
                node.addNextRight((nextReversed ? (!side) as unknown as Side : side) == Side.RIGHT ? nextNode[0].getRightSide() : nextNode[0].getLeftSide());
            if ((side == Side.LEFT) != reversed)
                node.addNextLeft((nextReversed ? (!side) as unknown as Side : side) == Side.RIGHT ? nextNode[0].getRightSide() : nextNode[0].getLeftSide());
            return {nextNode: undefined, nextReversed: undefined};
        }

        let newLane = this.addLane(side, nextReversed, nextNode[0]);

        if ((side == Side.RIGHT) != reversed) {
            node.addNextRight(newLane);
        } else {
            node.addNextLeft(newLane);
        }
        return {nextNode: nextNode[0], nextReversed: nextReversed};
    }

    private addLane(side: Side, reversed: boolean, node: RoadNodeAnnotation<GeometryModel>): Lane<GeometryModel> {
        let newLane: Lane<GeometryModel> = new Lane<GeometryModel>(reversed ? (!side) as unknown as Side : side, reversed);
        let distance: number = Math.floor(reversed != (side == Side.RIGHT) ? node.dt.properties.segment_width / 2 : -node.dt.properties.segment_width / 2); // Math floor prevent weird offset bugs
        newLane.dt = LineStringHelper.getLineOffset(node.dt.clone(), distance).clone();
        node.addLane(newLane);

        return newLane;
    }

    private static getFollowingNode(node: RoadNode<GeometryModel>, side: Side, reversed: boolean): [RoadNodeAnnotation<GeometryModel>, linkTypeEnum] {
        let currentLS: GeometryModel = reversed ? LineStringHelper.reverseLineString(node.dt.clone()) : node.dt.clone();
        let all: [RoadNode<GeometryModel>, linkTypeEnum][] = node.links.concat(node.next.map((node: any) => ([node, linkTypeEnum.END | linkTypeEnum.L_START]))).concat(node.prev.map((node: any) => ([node, linkTypeEnum.START | linkTypeEnum.L_END])));

        let ordered: [RoadNode<GeometryModel>, linkTypeEnum][] = ComputeLaneHelper.orderLinksByMostRight(currentLS, all.filter(item => (!!(item[1] & linkTypeEnum.END) != reversed)));
        if (ordered.length == 0)
            return undefined;
        if (side == Side.RIGHT)
            return [ordered[0][0] as RoadNodeAnnotation<GeometryModel>, ordered[0][1]];
        return [ordered[ordered.length - 1][0] as RoadNodeAnnotation<GeometryModel>, ordered[ordered.length - 1][1]];
    }

    static orderLinksByMostRight(segment: GeometryModel, otherSegments: [RoadNode<GeometryModel>, linkTypeEnum][]): [RoadNode<GeometryModel>, linkTypeEnum][] {
        return otherSegments.sort((a, b) => (
            LineStringHelper.l1MostRightThanl2(segment, a[1] && linkTypeEnum.L_END ? LineStringHelper.reverseLineString(a[0].dt.clone()) : a[0].dt,
                b[1] && linkTypeEnum.L_END ? LineStringHelper.reverseLineString(b[0].dt.clone()) : b[0].dt) ? -1 : 1));
    }

    private computeAxes(node: RoadNodeAnnotation<GeometryModel>) {
        let segmentType: string = node.dt.properties.segment_type;
        let segmentLanes: number = +node.dt.properties.segment_lanes;
        let segmentAxis: number = segmentLanes - 1;
        let segmentWidth: number = +node.dt.properties.segment_width;

        let properties: any = node.dt.properties;
        if (segmentAxis > 0) {
            let var_: string = ((Math.random() + 1) * 444 * 1000000).toString().slice(1, 7);
            properties['stroke'] = "#" + var_;
            properties['stroke'] = getRandomColor();
            properties['stroke-width'] = this.width['AXE'][segmentType as SegmentTypeEnum];
            properties['line-type'] = 'AXE';
            if (segmentAxis == 1) {
                this.response.push(FeatureHelper.createFeature(node.dt, {...properties}));
            } else {
                for (let i: number = 1; i < segmentAxis; i++) {
                    let offset: number = segmentWidth / segmentLanes * i;
                    this.response.push(FeatureHelper.createFeature(LineStringHelper.getLineOffset(node.dt, offset / 2), {...properties}));
                    this.response.push(FeatureHelper.createFeature(LineStringHelper.getLineOffset(node.dt, -offset / 2), {...properties}));
                }
            }
        }
    }

    private computeFeature(node: RoadNodeAnnotation<GeometryModel>) {
        let segmentType: SegmentTypeEnum = node.dt.properties['segment_type'] as SegmentTypeEnum;
        let segmentLanes: number = node.dt.properties['segment_lanes'];
        let segmentAxis: number = segmentLanes - 1;
        let segmentWidth: number = node.dt.properties['segment_width'];

        // Display initial axis
        let properties = node.dt.properties;
        let var_: string = ((Math.random() + 1) * 444 * 1000000).toString().slice(1, 7);
        properties['stroke'] = "#" + var_;
        properties['stroke-width'] = 4;
        properties['stroke-opacity'] = 0.85;
        properties['line-type'] = 'RIVE';
        properties['bearing'] = GeometryHelper.getBearing(GeometryHelper.createPoint(node.dt.coordinates[0]), GeometryHelper.createPoint(node.dt.coordinates[node.dt.coordinates.length - 1]));

        this.response.push(FeatureHelper.createFeature(node.dt, {...properties}))

    }

//     async send() {
//         if (this.args['output_file'] !== undefined) {

//             this.ioGeojsonService.writeGeoJsonFile(this.args['output_file'], this.response);

//
//         } else if (this.args['create'] === true) {
//             let dao: DsAnnotationLinesDao = new DsAnnotationLinesDao(
//                 this.args['host'],
//                 this.args['username'],
//                 this.args['password'],
//                 this.args['database'] || 'citymagine_ds');
//
// //            await dsVisibilityDao.deleteVisibilityCurve('SPEEDLIMIT', this.args['way_identifier']);
//
//             for (let feature of this.response) {
//                 await dao.createLine(feature);
//             }
//

//         } else {
//             let featureCollection = new FeatureCollectionModel();
//             featureCollection.features = this.response;

//         }
//     }

    private static mergeLane(sLane: Lane<GeometryModel>): GeometryModel {
        /**
         * Merge every segments within a lane
         */
        let lanePrev: Lane<GeometryModel> = sLane;
        let firstLane: Lane<GeometryModel> = sLane;
        let fullLane: GeometryModel = sLane.reversed ? LineStringHelper.reverseLineString(sLane.dt.clone()) : sLane.dt.clone();
        fullLane.properties["identifiers"] = [fullLane.properties.segment_identifier];

        for (let lane: Lane<GeometryModel> = sLane.next; lane !== undefined && lane !== firstLane; lane = lane.next) {
            let laneDT: GeometryModel = lane.reversed ? LineStringHelper.reverseLineString(lane.dt.clone()) : lane.dt.clone();
            let firstLaneDT: GeometryModel = firstLane.reversed ? LineStringHelper.reverseLineString(firstLane.dt.clone()) : firstLane.dt.clone();
            let laneDTSave: GeometryModel = laneDT.clone();
            let firstLaneDTSave: GeometryModel = firstLaneDT.clone();

            let intersectionLoop: GeometryModel = undefined;

            if (lane.next === firstLane) {
                const __ret = ComputeLaneHelper.loopMerge(firstLaneDT, laneDT, intersectionLoop, firstLaneDTSave, laneDTSave, fullLane);
                firstLaneDT = __ret.firstLaneDT;
                laneDT = __ret.laneDT;
                intersectionLoop = __ret.intersectionLoop;
            }

            let lanePrevDT: GeometryModel = lanePrev.reversed ? LineStringHelper.reverseLineString(lanePrev.dt.clone()) : lanePrev.dt.clone();
            let lanePrevDTSave: GeometryModel = lanePrevDT.clone();
            laneDTSave = laneDT.clone();

            lanePrevDT = ComputeLaneHelper.extrapolateLineEnd(lanePrevDT);
            laneDT = ComputeLaneHelper.extrapolateLineStart(laneDT);

            let intersectionNext: GeometryModel = LineStringHelper.getIntersectionPoints(lanePrevDT, laneDT)[0]; // Standard intersection
            const __ret = ComputeLaneHelper.mergeNextSegment(intersectionNext, lanePrevDT, lanePrevDTSave, laneDT, laneDTSave, fullLane);
            lanePrevDT = __ret.lanePrevDT;
            laneDT = __ret.laneDT;

            laneDT = LineStringHelper.sliceLineStringPure(intersectionNext, intersectionLoop, laneDT);
            fullLane = LineStringHelper.sliceLineStringPure(intersectionLoop, intersectionNext, fullLane);

            fullLane = LineStringHelper.cleanCoords(LineStringHelper.mergeToLineString([fullLane, laneDT], {"identifiers": fullLane.properties.identifiers.concat([laneDT.properties.segment_identifier])}));
            lanePrev = lane;
        }
        return fullLane;
    }

    private static mergeNextSegment(intersectionNext: GeometryModel, lanePrevDT: GeometryModel, lanePrevDTSave: GeometryModel, laneDT: GeometryModel, laneDTSave: GeometryModel, fullLane: GeometryModel) {
        if (intersectionNext === undefined) {
            lanePrevDT = lanePrevDTSave;
            laneDT = laneDTSave;

            let p1: number[] = [...fullLane.coordinates[fullLane.coordinates.length - 1]];
            let p2: number[] = [...laneDT.coordinates[0]];

            p1[0] += ((p2[0] - p1[0]) / 2);
            p1[1] += ((p2[1] - p1[1]) / 2);

            laneDT.coordinates[0] = [...p1];
            fullLane.coordinates[fullLane.coordinates.length - 1] = [...p1];

        } else {
            fullLane.coordinates.push(lanePrevDT.coordinates[lanePrevDT.coordinates.length - 1])
        }
        return {lanePrevDT, laneDT};
    }

    private static loopMerge(firstLaneDT: GeometryModel, laneDT: GeometryModel, intersectionLoop: GeometryModel, firstLaneDTSave: GeometryModel, laneDTSave: GeometryModel, fullLane: GeometryModel) {
        /**
         * Merge segments when first point is connected to the last one
         */
        firstLaneDT = ComputeLaneHelper.extrapolateLineStart(firstLaneDT);
        laneDT = ComputeLaneHelper.extrapolateLineEnd(laneDT);

        intersectionLoop = LineStringHelper.getIntersectionPoints(firstLaneDT, laneDT)[0]; // Loop intersection

        if (intersectionLoop === undefined) {
            firstLaneDT = firstLaneDTSave;
            laneDT = laneDTSave;

            let p1: number[] = [...laneDT.coordinates[laneDT.coordinates.length - 1]]
            let p2: number[] = [...firstLaneDT.coordinates[0]];

            p1[0] += ((p2[0] - p1[0]) / 2);
            p1[1] += ((p2[1] - p1[1]) / 2);

            firstLaneDT.coordinates[0] = [...p1];
            laneDT.coordinates[laneDT.coordinates.length - 1] = [...p1];
        }
        fullLane.coordinates.unshift(firstLaneDT.coordinates[0]);
        return {firstLaneDT, laneDT, intersectionLoop};
    }

    private static extrapolateLineEnd(line: GeometryModel): GeometryModel {
        let p1: number[] = line.coordinates[line.coordinates.length - 2];
        let p2: number[] = line.coordinates[line.coordinates.length - 1];


        let ratio: number = 1.5;
        let coord3: number[] = [p2[0] + ratio * (p2[0] - p1[0]), p2[1] + ratio * (p2[1] - p1[1])];

        line.coordinates.push(coord3);
        return line;
    }

    private static extrapolateLineStart(line: GeometryModel): GeometryModel {
        let p1: number[] = line.coordinates[1];
        let p2: number[] = line.coordinates[0];

        let ratio: number = 1.5;
        let coord3: number[] = [p2[0] + ratio * (p2[0] - p1[0]), p2[1] + ratio * (p2[1] - p1[1])];

        line.coordinates.unshift(coord3);
        return line;
    }

    private static changeNextToLinkFromGraph(graph: RoadList<GeometryModel>) {
        graph.changeStateFromHeads();
        for (let head of graph.getSavedHeads()) {
            ComputeLaneHelper.changeNextToLinkFromHead(head);
        }
    }

    private static changeNextToLinkFromHead(node: RoadNode<GeometryModel>) {
        if (node.state == stateEnum.DONE)
            return;
        node.state = stateEnum.DONE;
        if (node.next.length == 0 && node.links.filter(x => x[1] & linkTypeEnum.END && x[0].dt.properties.segment_lanes == node.dt.properties.segment_lanes).length == 1) {
            let linkToChange = node.links.filter(x => x[1] & linkTypeEnum.END && x[1] && linkTypeEnum.L_START && x[0].dt.properties.segment_lanes == node.dt.properties.segment_lanes)[0];

            if (linkToChange[1] && linkTypeEnum.L_END) {
                GraphHelper.reverseNodeAlreadyComputed(linkToChange[0]);
            }
            node.next.push(linkToChange[0]);
            node.links = node.links.filter(x => !(x[1] & linkTypeEnum.END && x[0].dt.properties.segment_lanes == node.dt.properties.segment_lanes));
            node.next[0].links = node.next[0].links.filter(x => !(x[1] & linkTypeEnum.START && x[1] && linkTypeEnum.L_END && x[0].dt.properties.segment_lanes == node.dt.properties.segment_lanes));
            linkToChange[0].prev.push(node);
        }
        for (let n of node.next)
            ComputeLaneHelper.changeNextToLinkFromHead(n);
        for (let l of node.links)
            ComputeLaneHelper.changeNextToLinkFromHead(l[0]);
    }

}
