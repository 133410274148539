import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {ConformityElementType, ConformityStoreService} from '../conformity-store.service';
import Matomo from "../../../../../stats";


@Component({
  selector: 'app-conformity-entry-panel',
  templateUrl: './conformity-entry-panel.component.html',
  styleUrls: ['./conformity-entry-panel.component.css']
})
export class ConformityEntryPanelComponent implements OnInit, PanelBase<any>, OnDestroy {
  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }

  subscriptions: Subscription[] = []

  constructor(private spinnerService: SpinnerService,
              private mapService: MapService,
              private toastService: ToastService,
              private panelService: PanelService,
              private conformityStore: ConformityStoreService) {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  data: any;

  searchDangerActivated = false;
  searchConformityActivated = false;

  selectedConformityValues: string[] = [];
  selectedDangerValues: string[] = [];

  conformityElements: ConformityElementType[];
  dangerElements: ConformityElementType[];

  show(show: boolean = true) {
    if (!show) {
      this.panelService.pop();
    }
  }

  clear() {
    Matomo.push(['trackEvent', 'Actions', 'Removed conformity'])
    this.clearSearch();
    this.show(false)
  }

  ngOnInit(): void {
    this.subscriptions.push(this.conformityStore.applyAndWatch(_ => this.conformityStore.applyOn(this)));
    this.searchConformity();
    this.searchDanger();
  }

  search() {
    const state = this.conformityStore.buildStateFromComponent(this)
    state.selectedConformityValues = this.selectedConformityValues;
    state.selectedDangerValues = this.selectedDangerValues;
    this.conformityStore.state = state;
  }


  clearSearch() {
    this.conformityStore.reset();
  }


  selectConformityChangeHandler(event) {
    this.searchConformityActivated = true
    this.selectedConformityValues = event;
    this.search();
  }

  selectDangerChangeHandler(event) {
    this.searchDangerActivated = true;
    this.selectedDangerValues = event;
    this.search();
  }

  getLocale() {
    return window.location.pathname.split('/')[1]
  }

  searchConformity() {
    const state = this.conformityStore.buildStateFromComponent(this)
    state.searchConformityActivated = true;
    this.conformityStore.state = state;
  }

  clearConformity() {
    const state = this.conformityStore.buildStateFromComponent(this)
    state.searchConformityActivated = false;
    state.searchDangerActivated = false;
    state.selectedConformityValues = []
    state.selectedDangerValues = []
    this.conformityStore.state = state;
    this.show(false);
  }

  searchDanger() {
    const state = this.conformityStore.buildStateFromComponent(this)
    state.searchDangerActivated = true;
    this.conformityStore.state = state;
  }

  clearDanger() {
    const state = this.conformityStore.buildStateFromComponent(this)
    state.searchDangerActivated = false;
    state.selectedDangerValues = [];
    this.conformityStore.state = state;
  }

}
