import {Injectable} from '@angular/core';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {EventCodeEnum} from 'src/app/enums/event-code-enum.enum';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {Session} from 'src/app/globals/session';
import {WindowEventHelper} from 'src/app/helpers/window-event.helper';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MasterDataService} from 'src/app/services/master-data.service';
import {ProcessingService} from 'src/app/services/processing.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {HeliosState, HeliosStoreService} from './helios-store.service';
import {HeliosViewPanelComponent} from './helios-view-panel/helios-view-panel.component';
import {MapImageEnum} from "../../../../enums/map-image-enum.enum";

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class HeliosService {

  subscriptions = []
  clickBound = false;


  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }

  constructor(private session: Session,
    private processingService: ProcessingService,
    private masterdataService: MasterDataService,
    private geojsonService: GeojsonService,
    private spinnerService: SpinnerService,
    private mapService:MapService,
    private toastService: ToastService,
    private panelService: PanelService,
    private heliosStore: HeliosStoreService) {
      this.heliosStore.applyAndWatch(this.showElementWrapper.bind(this))
    }

  showElementWrapper(state: HeliosState){
    this.mapService.callWhenAvailable(_=>this.showElement(state))
  }

  showElement(state: HeliosState) {

    let dateBegin = state.selectedYearMinValue.toString();
    let dateEnd = state.selectedYearHighValue.toString();

    // Show Markers
    if (state._isShowElement === true) {
      this.spinner.addOperation("MenuHeliosComponent.showElementHelios");

      this.map.removeLayer(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_HELIOS, MapSourceEnum.SOURCE_ELEMENT);

      // TODO includes class filter and exclude filter
      let tileUrl = environment.urlTilesElements;
      tileUrl = tileUrl + '?elementType=' + ElementTypesEnum.ELEMENT_TYPE_HELIOS;
      tileUrl = tileUrl + '&isPrimary=' + true
        + '&isRelevant=' + state.isRelevant + '&isConfirmed=' + state.isRelevant;

      if (state.selectedTypeHeliosWorks !== undefined && state.selectedTypeHeliosWorks.length > 0) {
          tileUrl = tileUrl + '&elementCategory=' + state.selectedTypeHeliosWorks;
      }
      if(dateBegin){
        tileUrl = tileUrl + '&elementDateBegin='+dateBegin
      }
      if(dateEnd){
        tileUrl = tileUrl + '&elementDateEnd='+dateEnd
      }
      if (state.selectedNetworks.length > 0) {
        state.selectedNetworks.forEach((network: string) => {
          if (network.length > 0) {
            tileUrl = tileUrl + '&elementNetworkList=' + network;
          }
        });
      }
      let layout = {
        "icon-image": "{element_class}",
        "icon-size": 0.15,
        "icon-rotate": ["get", "bearing"],
        "icon-rotation-alignment": "map",
        "icon-allow-overlap": true,
        "icon-ignore-placement": true
      };

      this.map.addSymbolLayerTiles(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_HELIOS, tileUrl, MapSourceEnum.SOURCE_ELEMENT, false, layout, ElementTypesEnum.ELEMENT_TYPE_HELIOS);

      this._onLayerViewElement(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_HELIOS, MapSourceEnum.SOURCE_ELEMENT);

      if(!this.clickBound) {

        this.map.map.on("click", MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_HELIOS, e => {
          e.preventDefault();

          const layout = {
            'icon-image': MapImageEnum.ICON_CIRCLE,
            'icon-size': 0.75
          }
          this.map.addSymbolLayer(MapLayerEnum.LAYER_CIRCLE, MapSourceEnum.SOURCE_ELEMENT + MapSourceEnum.SOURCE_CIRCLE, layout,
            this.map.createSymbolLayerDataFromMapboxFeatures(e.features[0]));

          this.viewElement(e.features[0].properties['element_identifier']);
      });
        this.clickBound = true;
      }

      this.spinner.removeOperation("MenuHeliosComponent.showElementHelios");

      WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_HELIOS_LOADED);


    } else {
      this.map.removeLayer(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_HELIOS, MapSourceEnum.SOURCE_ELEMENT);

      WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_HELIOS_LOADED);
    }

    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ShowHeliosElement');
    }
  }

  _onLayerViewElement(layer, source) {
    this.map.clickLayerMap(layer);
    this.subscriptions.push(this.map.onClickLayerMap.subscribe(e => {
      let features = this.map.map.queryRenderedFeatures(e.point);
      features.forEach(e => {
        if (e.source === source) {
          this.viewElement(e.properties.elementIdentifier);
        }
      })
    }));

  }

  viewElement(elementIdentifier:string){
    this.spinner.addOperation("MenuHeliosComponent.viewElement");
    this.geojsonService.getObjectElementCollection( undefined,undefined,undefined,undefined, ElementTypesEnum.ELEMENT_TYPE_HELIOS, undefined, undefined,undefined, elementIdentifier,undefined,undefined, undefined, undefined,undefined,undefined, undefined).subscribe(response => {
      if (response && response.features.length > 0) {
        this.panelService.open(
          HeliosViewPanelComponent,
            response.features.pop() as any
          )
      } else {
        this.toast.showToastWarn();
      }
      this.spinner.removeOperation("MenuHeliosComponent.viewElement");
    }, error => {
      this.toast.showToastError();
      this.spinner.removeOperation("MenuHeliosComponent.viewElement");
    });

    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ViewHeliosElement');
    }

  }

}
