import {Component} from '@angular/core';
import {PanelBase} from "../../shared/panel/component/PanelBase";
import {Session} from "../../globals/session";
import {PanelService} from "../../shared/panel/panel.service";
import {ClearService} from "../menu/main-panel/clear.service";
import {Subscription} from "rxjs";
import {PricingService} from "../../services/pricing.service";
import {PricingGridCollectionModel} from "../../models/pricing/pricing-grid-collection-model";
import {PricingGridModel} from "../../models/pricing/pricing-grid-model";
import {SessionModel} from "../../models/session/session-model";
import {MenuItem, SelectItem} from "primeng/api";
import {GroundState, GroundStoreService} from "../menu/main-panel/ground-entry/ground-store.service";
import {SignState, SignStoreService} from "../menu/main-panel/sign-entry/sign-store.service";
import {LaneState, LaneStoreService} from "../menu/main-panel/lane-entry/lane-store.service";
import {SearchStoreService} from "../main/search/search-store.service";
import {StatsStoreService} from "./stats-store.service";
import {StatisticService} from "../../services/statistic.service";
import {SpinnerService} from "../../shared/spinner/spinner.service";
import Matomo from "../../stats";
import {MapService} from "../../shared/map/map.service";
import {
  StreetlightState,
  StreetlightStoreService
} from "../menu/main-panel/streetlight-entry/streetlight-store.service";
import {FurnitureState, FurnitureStoreService} from "../menu/main-panel/furniture-entry/furniture-store.service";
import {BarrierState, BarrierStoreService} from "../menu/main-panel/barrier-entry/barrier-store.service";
import {BankState, BankStoreService} from "../menu/main-panel/bank-entry/bank-store.service";
import {RiverSignState, RiverSignStoreService} from "../menu/main-panel/river-sign-entry/river-sign-store.service";
import {StatisticCollectionModel} from "../../models/statistic/statistic-collection-model";
import {TranslateService} from "@ngx-translate/core";

export class Grid {
  name: string;
  code: string;
  currency: string;
}

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})

export class StatsComponent implements PanelBase<null> {
  constructor(public session: Session,
              private panelService: PanelService,
              private pricingService: PricingService,
              private statsStore: StatsStoreService,
              private searchStore: SearchStoreService,
              private spinnerService: SpinnerService,
              private statisticService: StatisticService,
              private mapService: MapService,
              private groundStore: GroundStoreService,
              private signStore: SignStoreService,
              private laneStore: LaneStoreService,
              private streetlightStore: StreetlightStoreService,
              private furnitureStore: FurnitureStoreService,
              private barrierStore: BarrierStoreService,
              private bankStore: BankStoreService,
              private riverSignStore: RiverSignStoreService,
              private translate: TranslateService) {
  }

  data: any;
  subscriptions: Subscription[] = [];
  exportItems: MenuItem[] = [];
  isWorkspaceDefined = false;
  _isRefreshed: boolean = false;
  _waitingExport: boolean = false;
  currentSession: SessionModel = this.session.currentSession;
  pricingGrids: SelectItem[];
  selectedGrid: Grid;
  statesElement = [this.groundStore.state, this.signStore.state, this.laneStore.state, this.streetlightStore.state,
    this.furnitureStore.state, this.barrierStore.state, this.bankStore.state, this.riverSignStore.state];
  searchState = this.searchStore.state;
  objectArrayStatisticFeatures: StatisticCollectionModel[];
  activityIds: string[];
  _userHasGrid: boolean;
  _gridCurrency= '';
  gridInitiated = false;
  activeStates: string[];

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  ngOnInit(): void {
    this.mapService.getMap().onClearWorkspace.subscribe(() => {
      this._isRefreshed = false;
    });
    this.session.onChangeWorkspace.subscribe(() => {
      this._isRefreshed = false;
    });
    this.groundStore.applyAndWatch(() => this.refresh());
    this.signStore.applyAndWatch(() => this.refresh());
    this.laneStore.applyAndWatch(() => this.refresh());
    this.furnitureStore.applyAndWatch(() => this.refresh());
    this.streetlightStore.applyAndWatch(() => this.refresh());
    this.bankStore.applyAndWatch(() => this.refresh());
    this.barrierStore.applyAndWatch(() => this.refresh());
    this.riverSignStore.applyAndWatch(() => this.refresh());

    this.translate.get('statistic.export').subscribe((response: string) => {
      this.exportItems.push(
        {
          label: response + ' GEOJSON', command: () => {
            this.exportFeatures('GEOJSON');
          }
        },{
          label: response + ' CSV', command: () => {
            this.exportFeatures('CSV');
          }
        });
        console.log("translate")
        console.log(this.exportItems)
      });
    
    let pricingGridTemp = [];
    this.pricingService.getUserPricingGrids(this.currentSession.user.userId).subscribe((resp: PricingGridCollectionModel) => {
      if (resp) {
        if (resp.pricingGrids.length > 0) {
          resp.pricingGrids.forEach((pricingGridModel: PricingGridModel) => {
            pricingGridTemp.push({
              label: pricingGridModel.gridName,
              value: {name: pricingGridModel.gridName, code: pricingGridModel.gridCode, currency: pricingGridModel.currency}
            });
          });
          this._userHasGrid = true;
        } else {
          this._userHasGrid = false;
        }
        this.gridInitiated = true;
        this.pricingGrids = pricingGridTemp;
        this.getStatisticByGridCode();
      }
    });
    Object.keys(this.statsStore.state).forEach(key => {
      this[key] = this.statsStore.state[key];
    });
  }

  refresh() {
    this.statesElement = [this.groundStore.state, this.signStore.state, this.laneStore.state, this.streetlightStore.state,
    this.furnitureStore.state, this.barrierStore.state, this.bankStore.state, this.riverSignStore.state];
    this._isRefreshed = false;
  }

  close() {
    this.panelService.close(true);
  }

  onChangeCode(gridCode: string) {
    this._isRefreshed = false;
    this._gridCurrency = this.pricingGrids.find(g => g.value.code === gridCode)?.value.currency;
    this.getStatisticByGridCode(gridCode);
  }

  getStatisticByGridCode(pricingGrid?: string) {
    let objectArrayStatisticFeatures = [];
    this.activityIds = [];
    this.activeStates = [];
    this.spinner.addOperation("StatisticComponent.getStats");
    this.statesElement.forEach((state: GroundState | SignState | LaneState | StreetlightState | FurnitureState
      | BankState | BarrierState | RiverSignState) => {
      if (state._isShowElement) {
        this.activeStates.push(state.elementType);
      }
    });
    if(this.activeStates.length === 0) {
      this._isRefreshed = true;
      this.objectArrayStatisticFeatures = [];
      this.spinner.removeOperation("StatisticComponent.getStats");
    } else {
      let activeStateHandled = 0;
      this.statesElement.forEach((state: GroundState | SignState | LaneState | StreetlightState | FurnitureState
        | BankState | BarrierState | RiverSignState) => {
        if (state._isShowElement) {
          if (this.searchStore.state.drawnGeometry !== undefined) {
            this.statisticService.getStatisticByElementType(state, this.searchStore.state.drawnGeometry, pricingGrid).subscribe((response: StatisticCollectionModel) => {
              this.activityIds.push(response.activityId);
              if(!pricingGrid) {
                this._gridCurrency = "";
              }
              response.elementType = state.elementType;
              objectArrayStatisticFeatures.push(response);
              this._isRefreshed = true;
              activeStateHandled++;
              if (activeStateHandled === this.activeStates.length) {
                this.objectArrayStatisticFeatures = objectArrayStatisticFeatures;
                this.spinner.removeOperation("StatisticComponent.getStats");
              }
            });
          }
        }
      });
    }
  }

  exportFeatures(format: string) {
    Matomo.push(['trackEvent', 'Actions', 'Export stats'])
    this._waitingExport = true;
    this.spinner.addOperation("StatisticComponent.exportFeatures");
    this.statisticService.getStatisticExport(this.activityIds, format).subscribe(response => {
      this.spinner.removeOperation("StatisticComponent.exportFeatures");
      const link = document.createElement("a");
      link.download = 'export.xlsx'; //not working
      link.href = response.file;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.spinner.removeOperation("StatisticComponent.exportFeatures");
      this._waitingExport = false;
    });
  }
}
