import {ISerializable} from '../iserializable';
import {GeometryModel} from "../geojson/geometry-model";
import {AlertPropertiesModel} from "./alert-properties-model";

export class AlertModel implements ISerializable {


  public alertIdentifier: string;
  public alertPriority: string;
  public alertType: string;
  public alertCategory: string;
  public alertTimestamp: string;
  public alertDateBegin: string;
  public alertDateEnd: string;
  public alertGeometry: GeometryModel;
  public featureType: string;
  public alertImpact: string;
  public alertComment: string;
  public alertStorageType: string;
  public alertStorageUrl01: string;
  public alertStorageUrl02: string;
  public alertStorageUrl03: string;
  public alertStorageUrl04: string;
  public alertStorageFile01: string;
  public alertStorageFile02: string;
  public alertStorageFile03: string;
  public alertStorageFile04: string;
  public alertActive: boolean;
  public alertPush: boolean;
  public alertClosed: boolean;
  public alertProperties: AlertPropertiesModel;


  deserialize(input: any): this {
    Object.assign(this, input);
    this.alertGeometry = new GeometryModel().deserialize(input.geometry);
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
