<div class="ui-g sticky" style="padding-right: 10px;">
  <div class="ui-g-2 titleButton">
    <i class="fa fa-arrow-left" (click)="show(false)"></i>
  </div>
  <div class="ui-g-8">
    <h1>{{ 'riverSignEntry.title' | translate }}</h1>
  </div>
</div>

<div *ngIf="element">
  <div class="subMenu">
    <div style= "display: flex; flex-direction:column; margin-top: 10px;" class="listMenuElement">

      <div style="display: flex; justify-content: space-between; border-bottom: 1px solid rgba(0, 0, 0, 0.2);">
        <div style="display: flex; justify-content: space-between; min-height: 140px; flex-direction: column;">
          <span style="font-weight: bold; margin-bottom: 20px;" class="signTitle">{{element.elementClassDetail?.classDescription}} ({{element.elementCategory}})</span>
          <div style="flex-direction: row; justify-content: flex-start; order: 0; margin-bottom: 10px">
            <p  class="listMenuElementTitle">{{ 'common.quality' | translate }}</p>
            <p >{{element.elementQuality | elementQualityPipe}} ({{element.elementQuality}})</p>
            <app-quality-selector class="qualityBubble" *ngIf="qualityList !== undefined" [data]="element" [qualityList]="qualityList" (dataChange)="setNewElementQuality($event)"></app-quality-selector>
          </div >
        </div>
        <img alt="sign icon" *ngIf="(elementIcon !== undefined)" [src]="elementIcon" class="compareIconSign" style="margin: 0 10px"/>
      </div>

      <app-crop-image *ngIf="element" [element]="element" [viewType]="viewType"></app-crop-image>

    </div>
  </div>
</div>

<p-accordion [multiple]="true" *ngIf="element">
  <p-accordionTab header="{{ 'common.properties' | translate }}" *ngIf="showQualification">
    <div>
      <ul class="subMenu">
        <li class="listMenuElement" *ngIf="yearBuild">
          <p class="listMenuElementTitle">{{ 'common.yearBuild' | translate }}</p>
          <p>{{yearBuild}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement" *ngIf="builder">
          <p class="listMenuElementTitle">{{ 'common.builder' | translate }}</p>
          <p>{{builder}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement" *ngIf="filmClass">
          <p class="listMenuElementTitle">{{ 'common.filmClass' | translate }}</p>
          <p>{{filmClass}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="implementType">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.implement' | translate }}</p>
          <p>{{implementType}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="anchorType">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.anchor' | translate }}</p>
          <p>{{anchorType}}</p>
        </li>
      </ul>

    </div>
  </p-accordionTab>
  <p-accordionTab header="{{ 'common.metrics' | translate }}" *ngIf="showMetrics">
    <div>
      <ul class="subMenu" *ngIf="size">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.size' | translate }}</p>
          <p>{{size}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement" *ngIf="heightSp">
          <p class="listMenuElementTitle">{{ 'common.heightSp' | translate }}</p>
          <p>{{heightSp}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
  <p-accordionTab header="{{ 'common.snapshot' | translate }}">
    <div>
      <ul class="subMenu">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.date' | translate }}</p>
          <p>{{element.elementTimestamp | moment: 'LLL'}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="element.snapshotName">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.snapshotName' | translate }}</p>
          <p>{{element.snapshotName}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="element.snapshotIdentifier">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.snapshotIdentifier' | translate }}</p>
          <p>{{element.snapshotIdentifier}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
  <p-accordionTab header="{{ 'common.details' | translate }}">
    <div>
      <ul class="subMenu">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.elementClass' | translate }}</p>
          <p>{{element.elementClass}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.elementIdentifier' | translate }}</p>
          <p>{{element.elementIdentifier}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="element.viewIdentifier">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.viewIdentifier' | translate }}</p>
          <p>{{element.viewIdentifier}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
  <p-accordionTab header="{{ 'common.localization' | translate }}">
    <div>
      <ul class="subMenu">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.coordinates' | translate }}</p>
          <p>Long: {{showLong}}</p>
          <p>Lat : {{showLat}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.openLocationCode' | translate }}</p>
          <p>{{openLocCode}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
</p-accordion>
<p-confirmDialog [style]="{width: '50vw'}"  [transitionOptions]="'200ms'"  key="SignComponent"></p-confirmDialog>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
