import {ISerializable} from '../iserializable';

export class ClassModel implements ISerializable {

  public datasetType: string;
  public classCode: string;
  public classLabel: string;
  public classGroup: string;
  public classDescription: string;
  public classProperties: any;

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
