import {Injectable} from '@angular/core';
import {FeatureModel} from "../models/geojson/feature-model";
import {GeometryModel} from "../models/geojson/geometry-model";
import * as turf from "@turf/turf";
import {GeometryTypeEnum} from "../enums/geometry-type-enum.enum";
import {FeatureTypesEnum} from "../enums/feature-types-enum.enum";
import {FeatureCollectionModel} from "../models/geojson/feature-collection-model";
import {LineStringHelper} from "./linestring-helper";
import {DirectionsEnum} from "../enums/direction-enum";

@Injectable({
  providedIn: 'root'
})
export class GeometryHelper {

  constructor() {}

  static rotateLineStringAroundPoint(obj: GeometryModel, rotationPoint: GeometryModel, angle: number): any {
    let res: any = turf.transformRotate(obj.toTurf(), angle, {pivot: rotationPoint.coordinates});

    return GeometryHelper.createLineString(res.coordinates)
  }

  static getDefaultPoint(): FeatureModel {

    let value: FeatureModel = new FeatureModel();
    value.geometry = new GeometryModel();
    value.geometry.type = GeometryTypeEnum.POINT;
    value.geometry.coordinates = [2.3707129698604206, 48.85722798031489]; // Paris

    return value;
  }

  // static createPoint(coordinates: number[]): GeometryModel {
  //   let result: GeometryModel = new GeometryModel();
  //   result.type = GeometryTypeEnum.POINT;
  //   result.coordinates = coordinates;
  //
  //   return result;
  // }


  static createPoint(coordinates: number[], properties: any = undefined): GeometryModel {
    let result: GeometryModel = new GeometryModel();
    result.type = GeometryTypeEnum.POINT;
    result.coordinates = coordinates;
    result.properties = properties;

    return result;
  }

  static createLineStringToSquare(firstCoordinates: number[], secondCoordinates: number[]) {
    let line = turf.lineString([firstCoordinates, [secondCoordinates[0],firstCoordinates[1]], secondCoordinates, [firstCoordinates[0], secondCoordinates[1]],firstCoordinates]);

    let result: GeometryModel = new GeometryModel();
    result.type = line.geometry.type;
    result.coordinates = line.geometry.coordinates;

    return result;
  }

  static createPolygonFromLine(geometry): GeometryModel {
    let polygon = turf.lineToPolygon(geometry);

    let result: GeometryModel = new GeometryModel();
    result.type = polygon.geometry.type;
    result.coordinates = polygon.geometry.coordinates;

    return result;
  }

  static createPointFromLngLat(lngLat): GeometryModel {
    let result: GeometryModel = new GeometryModel();
    result.type = GeometryTypeEnum.POINT;
    result.coordinates = [lngLat.lng, lngLat.lat];

    return result;
  }

  static createFeatureCollection(geometry: GeometryModel, properties: any = {}): FeatureCollectionModel {

    let value: FeatureCollectionModel = new FeatureCollectionModel();
    value.features.push(GeometryHelper.createFeature(geometry, properties));

    return value;
  }


  static createFeature(geometry: GeometryModel, properties: any = {}): FeatureModel {

    let value: FeatureModel = new FeatureModel();
    value.geometry = geometry;
    value.properties = properties;

    return value;
  }

  static isValidFeature(value: FeatureModel): boolean {
    return (value !== undefined
      && value.type === FeatureTypesEnum.FEATURE
      && GeometryHelper.isValidGeometry(value.geometry))
  }

  static isValidGeometry(value: GeometryModel): boolean {

    return (value !== undefined
      && value.type !== undefined
      && value.type !== ''
      && value.coordinates !== undefined
      && value.coordinates.length > 0)
  }

  static isValidPoint(value: GeometryModel): boolean {
    return (value !== undefined
      && value.type !== undefined
      && value.type == GeometryTypeEnum.POINT
      && value.coordinates !== undefined
      && value.coordinates.length > 0)
  }

  static isValidLineString(value: GeometryModel): boolean {
    return (value !== undefined
      && value.type !== undefined
      && value.type == GeometryTypeEnum.LINE_STRING
      && value.coordinates !== undefined
      && value.coordinates.length > 0)
  }

  static isValidPolygon(value: GeometryModel): boolean {
    return (value !== undefined
      && value.type !== undefined
      && (value.type == GeometryTypeEnum.POLYGON || value.type == GeometryTypeEnum.MULTI_POLYGON)
      && value.coordinates !== undefined
      && value.coordinates.length > 0)
  }

  static getCentroid(geometry: GeometryModel): GeometryModel {
    let result: GeometryModel;

    if (geometry !== undefined) {
      let value = turf.centroid(geometry.toTurf());

      result = new GeometryModel();
      result.type = value.geometry.type;
      result.coordinates = value.geometry.coordinates;
    }

    return result;
  }

  static getLength(geometry: GeometryModel): number {
    let result: number = 0.00;

    if (geometry !== undefined) {
      result = turf.length(geometry, {units: 'meters'});
    }

    return result;
  }

  static getSurface(geometry: GeometryModel): number {
    let result: number = 0.00;

    if (geometry !== undefined) {
      result = turf.area(geometry);
    }

    return result;
  }

  static getBounds(geometry: GeometryModel) {
    return turf.bbox(geometry);
  }

  static createLineString(coordinates: any[], properties: any = undefined): GeometryModel {
    let result: GeometryModel = new GeometryModel();
    result.type = GeometryTypeEnum.LINE_STRING;
    result.coordinates = coordinates;
    result.properties = properties;

    return result;
  }

  static createLineStringFromPoints(points: GeometryModel[], properties: any = undefined): GeometryModel {
    let coordinates: any[] = [];

    for (let point of points) {
      coordinates.push(point.coordinates);
    }

    let result: GeometryModel = new GeometryModel();
    result.type = GeometryTypeEnum.LINE_STRING;
    result.coordinates = coordinates;
    result.properties = properties || points[0].properties;

    return result;
  }

  static createPolygon(coordinates: any[], properties: any = undefined): GeometryModel {
    let result: GeometryModel = new GeometryModel();
    result.type = GeometryTypeEnum.POLYGON;
    result.coordinates = coordinates;
    result.properties = properties;

    return result;
  }

  static createPolygonFromLineString(linestring: GeometryModel): GeometryModel {
    let polygon = turf.lineToPolygon(linestring.toTurf());

    let result: GeometryModel = new GeometryModel();
    result.type = polygon.geometry.type;
    result.coordinates = polygon.geometry.coordinates;

    return result;
  }

  static createMultiLineString(linestrings: GeometryModel[], properties: any = undefined): GeometryModel {
    if (linestrings !== undefined && linestrings.length > 0) {
      let result: GeometryModel = new GeometryModel();
      result.type = GeometryTypeEnum.MULTI_LINESTRING;

      for (let linestring of linestrings) {
        result.coordinates.push(linestring.coordinates);
      }

      result.properties = properties;
      return result;
    }
    return;
  }

  static createBbox(xMin:number, yMin:number, xMax:number, yMax:number): GeometryModel {
    let coordinates = [[[xMin,yMin], [xMax, yMin], [xMax, yMax], [xMin, yMax], [xMin,yMin]]];

    let result: GeometryModel = new GeometryModel();
    result.type = GeometryTypeEnum.POLYGON;
    result.coordinates = coordinates;

    return result;
  }

  static isValidGeometryType(value: GeometryModel, type: GeometryTypeEnum): boolean {
    return (GeometryHelper.isValidGeometry(value) && value.type == type)
  }

  static getBearing(point1: GeometryModel, point2: GeometryModel): number {
    return turf.bearing(point1.toTurf(), point2.toTurf());
  }

  static addDegrees(degrees:number, add:number): number {
    degrees += add;
    while (degrees > 180) {
      degrees = degrees - 360;
    }
    while (degrees < -180) {
      degrees = degrees + 360;
    }
    return degrees;
  }

  static getMidpoint(point1: GeometryModel, point2: GeometryModel): GeometryModel {
    return GeometryHelper.createPoint(turf.midpoint(point1.toTurf(), point2.toTurf()).geometry.coordinates);
  }

  static getDistance(point1: GeometryModel, point2: GeometryModel): number {
    let result: number = 0.00;

    if (GeometryHelper.isValidGeometryType(point1, GeometryTypeEnum.POINT)
      && GeometryHelper.isValidGeometryType(point2, GeometryTypeEnum.POINT)) {
      return turf.distance(point1.coordinates, point2.coordinates, {units: "meters"});
    }

    return result;
  }

  static getSumLength(geometry: GeometryModel[]): number {
    let result: number = 0.00;

    for (let i in geometry) {
      result += turf.length(geometry[i].toTurf(), {units: 'meters'});
    }

    return result;
  }

  static filterByProperties(geometries: GeometryModel[], key:string, value:any): GeometryModel[] {
    let result: GeometryModel[] = [];

    if(geometries !== undefined && geometries.length > 0) {
      for (let geometry of geometries) {
        if(geometry.properties[key] == value) {
          result.push(geometry);
        }
      }
    }

    return result;
  }

  static isPointsWithinPolygon(featureCollection: any, polygonGeometry: GeometryModel) {

    let ptsWithin: any;
    let coordinates = [];
    if (polygonGeometry.type === GeometryTypeEnum.POLYGON) {
      featureCollection.features.forEach(feature => {
        if (feature.geometry.type === GeometryTypeEnum.POINT) {
          coordinates.push(feature.geometry.coordinates);
        } else if (feature.geometry.type === GeometryTypeEnum.LINESTRING) {
          coordinates.push(feature.geometry.coordinates[0]);
        } else {
          coordinates.push(feature.geometry.coordinates[0][0]);
        }
      });

      let points = turf.points(coordinates);
      let searchWithin = turf.polygon(polygonGeometry.coordinates);

      ptsWithin = turf.pointsWithinPolygon(points, searchWithin);
      return ptsWithin;

    }
  }

  static booleanPointInPolygon(point: number[], polygonGeometry: GeometryModel) {
    let pt = turf.point(point);
    if  (polygonGeometry.type === GeometryTypeEnum.POLYGON) {
      let poly = turf.polygon(polygonGeometry.coordinates);
      return turf.booleanPointInPolygon(pt, poly);
    } else if (polygonGeometry.type === GeometryTypeEnum.MULTI_POLYGON) {
      let poly = turf.multiPolygon(polygonGeometry.coordinates);
      return turf.booleanPointInPolygon(pt, poly);
    }
    return;
  }

  static isParallelBearing(bearing1: number, bearing2: number, max: number = 40) {
    return this.degreesDiff(bearing1, bearing2) < max || this.degreesDiff(bearing1, bearing2) > 180 - max;
  }

  static isOpposite(bearing1: number, bearing2: number, max: number = 40) {
    return this.degreesDiff(bearing1, bearing2) > 180 - max;
  }

  static isFollowing(bearing1: number, bearing2: number, max: number = 40, reversed: boolean = false) {
    return this.isParallelBearing(bearing1, bearing2, max) && ((reversed && this.isOpposite(bearing1, bearing2, max)) || !this.isOpposite(bearing1, bearing2, max))
  }

  static degreesDiff(degrees1: number, degrees2: number): number {
    if ((degrees1 <= 0 && degrees2 <= 0) || (degrees1 >= 0 && degrees2 >= 0))
      return Math.abs(degrees1 - degrees2);
    degrees2 = this.addDegrees(degrees2, degrees1 * -1);
    degrees1 = 0;
    return Math.abs(degrees1 - degrees2);
  }

  static isSame(geometry1: GeometryModel, geometry2: GeometryModel): boolean {
    if (geometry1.type !== geometry2.type) {
      return false;
    }

    if (GeometryHelper.isValidGeometryType(geometry1, GeometryTypeEnum.POINT)) {

      return (geometry1.coordinates[0] == geometry2.coordinates[0]
        && geometry1.coordinates[1] == geometry2.coordinates[1]);

    } else if (GeometryHelper.isValidGeometryType(geometry1, GeometryTypeEnum.LINE_STRING)) {

      let startPoint = GeometryHelper.createPoint(geometry2.coordinates[0]);
      let endPoint = GeometryHelper.createPoint(geometry2.coordinates[geometry2.coordinates.length-1]);

      return (LineStringHelper.isStartPoint(startPoint, geometry1)
        || LineStringHelper.isEndPoint(startPoint, geometry1))
        && (LineStringHelper.isStartPoint(endPoint, geometry1)
          || LineStringHelper.isEndPoint(endPoint, geometry1));
    }
  }

  static getTurnDirection(currentPoint: GeometryModel, nextPoint1: GeometryModel, nextPoint2: GeometryModel): DirectionsEnum {
    let currentBearing = GeometryHelper.getBearing(currentPoint, nextPoint1);
    let next2Degrees = GeometryHelper.addDegrees(GeometryHelper.getBearing(currentPoint, nextPoint2), -currentBearing);

    if (0 > next2Degrees) {
      return DirectionsEnum.Left;
    } else {
      return DirectionsEnum.Right;
    }
  }

  static getPointToLineDistance(point: GeometryModel, line: GeometryModel): number {
    let result: number = 0.00;

    if (GeometryHelper.isValidGeometryType(point, GeometryTypeEnum.POINT)
      && GeometryHelper.isValidGeometryType(line, GeometryTypeEnum.LINESTRING)) {
      return turf.pointToLineDistance(turf.point(point.coordinates), turf.lineString(line.coordinates), {units: "meters"});
    }

    return result;
  }

  static bufferFromGeometry(geometry: GeometryModel, geometryWidth: number) {
    let geo = turf.lineString(geometry.coordinates);
    return turf.buffer(geo, geometryWidth);
  }

  static unionFromGeometryPolygon(geometry: GeometryModel[]) {
    // let poly1 = turf.polygon(geometry[0].coordinates);
    // let poly2 = turf.polygon(geometry[1].coordinates);
    // let union = turf.union(poly1, poly2);

    if (geometry.length > 0) {
      let polygons = [];
      geometry.forEach(geo => {
        polygons.push(turf.polygon(geo.coordinates));
      });
      let unions = turf.union(...polygons);
      return unions;
    }
    // let unions = turf.union(...geometry);
    // if (geometry.length > 2) {
    //   geometry.forEach(geo => {
    //     let poly = turf.polygon(geo.coordinates);
    //     let newUnion = turf.polygon(union);
    //     union = turf.union(union, poly);
    //   });
    // }
  }
}
