import moment                       from "moment"
import { Component, OnInit      }   from '@angular/core';
import { Subscription           }   from 'rxjs';
import { Session                }   from 'src/app/globals/session';
import { FeatureModel           }   from 'src/app/models/geojson/feature-model';
import { GeojsonService         }   from 'src/app/services/geojson.service';
import { MapService             }   from 'src/app/shared/map/map.service';
import { PanelBase              }   from 'src/app/shared/panel/component/PanelBase';
import { PanelService           }   from 'src/app/shared/panel/panel.service';
import { SpinnerService         }   from 'src/app/shared/spinner/spinner.service';
import { ToastService           }   from 'src/app/shared/toast/toast.service';
import { RoadFsiStoreService    }   from '../road-fsi-store.service';
import { RoadFsiService         }   from "../road-fsi.service";
import { TranslateService       }   from "@ngx-translate/core";

import Matomo                       from "../../../../../stats";

declare let gtag: Function;

interface Timings       { 't0': number, 't1': number, 'delta_t': number }
interface Analytics     { 
      n_severities  : any, f_severities: any
    , n_users       : any, f_users: any
    , n_years       : any, f_years: any 
    , n_months      : any, f_months: any
    , n_dows        : any, f_dows: any
    , n_hours       : any, f_hours: any
    , n_daytimes    : any, f_daytimes: any
    , n_ages        : any, f_ages: any
}

@Component({
  selector      : 'app-road-fsi-entry-panel',
  templateUrl   : './road-fsi-entry-panel.component.html',
  styleUrls     : ['./road-fsi-entry-panel.component.css']
})

export class RoadFsiPanelComponent implements OnInit, PanelBase<any> {
    /*
    * COMPONENT / PROPERTIES _______________________________
    */
    data                        : any                                   ;
    analytics                   : Analytics                             ;
    timings                     : Timings                               ;
    _isVisible                  : boolean           = true;
    _isShowElement              : boolean           = false             ;
    element                     : FeatureModel      = new FeatureModel();
    subscriptions               : Subscription[]    = [];
    users                       : string[]          = [];
    severities                  : string[]          = [];
    ages                        : string[]          = [];
    daytimes                    : string[]          = [];
    years                       : number[]          = [];
    months                      : string[]          = [];
    dows                        : string[]          = [];
    hours                       : string[]          = [];
    isVisible                   : any               ; 
    selectedAges                : string[]          = [];
    selectedUsers               : string[]          = [];
    selectedYears               : number[]          = [];   
    selectedMonths              : string[]          = [];
    selectedDOWs                : string[]          = [];
    selectedHours               : string[]          = [];
    selectedSeverities          : string[]          = [];
    selectedDensity             : boolean           = false;
    state                       : any               ;
    dimensions                  : string[]          = []

    /*
     * COMPONENT / HELPERS _____________________________________
     */
    get spinner()       { return this.spinnerService.getSpinner() }
    get map()           { return this.mapService.getMap() }
    get toast()         { return this.toastService.getToast() }
    isActiveDensity()   { return this.selectedDensity }
    isActiveAge(x)      { return this.selectedAges.includes(x) || this.selectedAges.length == 0 } 
    isActiveDaytime(x)  { 
        let hours: number[];
        if(x == "night"    ) hours = [ 0, 1, 2, 3, 4, 5];
        if(x == "morning"  ) hours = [ 6, 7, 8, 9,10,11];
        if(x == "afternoon") hours = [12,13,14,15,16,17];
        if(x == "evening"  ) hours = [18,19,20,21,22,23];
        return hours.reduce((memo, h) => memo && this.selectedHours.includes(''+h), true) 
    } 
    isActiveUser(x)     { return this.selectedUsers.includes(x)     || this.selectedUsers.length == 0 } 
    isActiveYear(x)     { return this.selectedYears.includes(x)     || this.selectedYears.length == 0 } 
    isActiveMonth(x)    { return this.selectedMonths.includes(x)    || this.selectedMonths.length == 0 } 
    isActiveDOW(x)      { return this.selectedDOWs.includes(x)      || this.selectedDOWs.length == 0 } 
    isActiveHour(x)     { return this.selectedHours.includes(x)     || this.selectedHours.length == 0 } 
    isActiveSeverity(x) { return this.selectedSeverities.includes(x)|| this.selectedSeverities.length == 0 } 

    show(show: boolean = true) {
        this._isVisible = show;
        if (!show) {
            this.panelService.pop();
            Matomo.push(['trackEvent', 'Actions', 'Removed crash'])
        } 
    }

    refresh() {
        this.roadFsiStore.refresh();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    showElement() {
        const state = this.roadFsiStore.buildStateFromComponent(this)
        state._isShowElement = true;
        this.roadFsiStore.state = state;
        this.updateAnalytics()
    }

    clear() {
        this.roadFsiStore.reset();
        this.show(false);
    }

    close(event: Event) {
        this.roadFsiStore.reset();
        this.show(false);
    }

    updateAnalytics(){
        let that            = this                                  ;
        let map         :any= this.mapService.getMap().map          ;
        let data        :any= map.querySourceFeatures('fsi_geoSourceCRASH', {sourceLayer: 'fsi_geo'});
        if(this.selectedDensity){
            return;
        }
        this.resetAnalytics()
        for(let i = 0 ; i < data.length ; i++){
            let p   : any   = data[i].properties   ;
            let s   : string= p.severity           ;
            let u   : string= p.road_user          ;
            let yob : number= p.yob              ;
            let t   : string= (new Date(p.t_accident)).toISOString() ;
            let y   : number= (new Date(p.t_accident)).getFullYear();
            let m   : string = p.t_month; 
            let dow : string = p.t_dow ;
            let h   : number = parseInt(p.t_hour);
            if(this.severities.includes(s)  ) this.analytics.n_severities[`${s}`]   += 1;
            if(this.users.includes(u)       ) this.analytics.n_users[`${u}`]        += 1;
            if(this.years.includes(y)       ) this.analytics.n_years[`${y}`]        += 1;
            if(this.months.includes(''+m)   ) this.analytics.n_months[`${m}`]       += 1;
            if(this.dows.includes(''+dow)   ) this.analytics.n_dows[`${dow}`]       += 1;
                 if(h <  6) this.analytics.n_daytimes[`night`    ] += 1
            else if(h < 12) this.analytics.n_daytimes[`morning`  ] += 1
            else if(h < 18) this.analytics.n_daytimes[`afternoon`] += 1
            else if(h < 24) this.analytics.n_daytimes[`evening`  ] += 1
            this.analytics.n_hours[`${h}`] += 1
        }
        // totals ...
        this.analytics.n_severities.total   = this.severities.reduce((total, x) => total + that.analytics.n_severities[`${x}`], 0);
        this.analytics.n_users.total        = this.users.reduce(     (total, x) => total + that.analytics.n_users[`${x}`     ], 0);
        this.analytics.n_years.total        = this.years.reduce(     (total, x) => total + that.analytics.n_years[`${x}`     ], 0);
        this.analytics.n_months.total       = this.months.reduce(    (total, x) => total + that.analytics.n_months[`${x}`    ], 0);
        this.analytics.n_dows.total         = this.dows.reduce(      (total, x) => total + that.analytics.n_dows[`${x}`      ], 0);
        this.analytics.n_hours.total        = this.hours.reduce(     (total, x) => total + that.analytics.n_hours[`${x}`     ], 0);
        this.analytics.n_daytimes.total     = this.daytimes.reduce(  (total, x) => total + that.analytics.n_daytimes[`${x}`  ], 0);
        // frequencies ...
        this.severities.forEach(x => that.analytics.f_severities[`${x}`] = that.analytics.n_severities[`${x}`] / that.analytics.n_severities.total);
        this.users.forEach(     x => that.analytics.f_users[     `${x}`] = that.analytics.n_users[     `${x}`] / that.analytics.n_users.total);
        this.years.forEach(     x => that.analytics.f_years[     `${x}`] = that.analytics.n_years[     `${x}`] / that.analytics.n_years.total);
        this.months.forEach(    x => that.analytics.f_months[    `${x}`] = that.analytics.n_months[    `${x}`] / that.analytics.n_months.total);
        this.dows.forEach(      x => that.analytics.f_dows[      `${x}`] = that.analytics.n_dows[      `${x}`] / that.analytics.n_dows.total);
        this.hours.forEach(     x => that.analytics.f_hours[     `${x}`] = that.analytics.n_hours[     `${x}`] / that.analytics.n_hours.total);
        this.daytimes.forEach(  x => that.analytics.f_daytimes[  `${x}`] = that.analytics.n_daytimes[  `${x}`] / that.analytics.n_daytimes.total);
        // checksums ...
        ["severities", "users", "years", "months", "dows", "hours", "daytimes"].forEach(dimension => {
            this.analytics[`f_${dimension}`].total   = this[dimension].reduce((total, x) => total + that.analytics[`f_${dimension}`][`${x}`], 0);
        })
    }

    resetAnalytics(){
        let that = this;
        let dimensions: string[] = ["severities", "users", "ages", "daytimes", "years", "months", "dows", "hours"];
        this.analytics  = { 
              n_severities: {}  , f_severities  : {} 
            , n_users: {}       , f_users       : {} 
            , n_years: {}       , f_years       : {} 
            , n_months: {}      , f_months      : {}
            , n_dows: {}        , f_dows        : {}
            , n_hours: {}       , f_hours       : {}
            , n_daytimes: {}    , f_daytimes    : {}
            , n_ages: {}        , f_ages        : {}
        }
        dimensions.forEach(dimension => {
            that[dimension].forEach(value => {
                that.analytics[`n_${dimension}`][`${value}`] = 0
                that.analytics[`f_${dimension}`][`${value}`] = 0
                that.analytics[`n_${dimension}`].total = 0
                that.analytics[`f_${dimension}`].total = 0
            })
        })
    }

    /*
     * COMPONENT / CONSTRUCTOR ______________________________________________
     */
    constructor(
          private session               : Session
        , private geojsonService        : GeojsonService
        , private spinnerService        : SpinnerService
        , private mapService            : MapService
        , private toastService          : ToastService
        , private panelService          : PanelService
        , private translate             : TranslateService
        , private roadFsiStore          : RoadFsiStoreService
        , private roadFsiService        : RoadFsiService
    ) {
        let that    :any        = this                          ;
        let map     :any        = this.mapService.getMap().map  ;
        this.roadFsiService     = roadFsiService                ;
        this.spinnerService     = spinnerService                ;
        this.users              = roadFsiStore.state.users      ;
        this.severities         = roadFsiStore.state.severities ;
        this.years              = roadFsiStore.state.years      ;
        this.hours              = roadFsiStore.state.hours      ;
        this.daytimes           = roadFsiStore.state.daytimes   ;
        this.selectedUsers      = roadFsiStore.state.selectedUsers;
        this.selectedSeverities = roadFsiStore.state.selectedSeverities;
        this.selectedYears      = roadFsiStore.state.selectedYears;
        this.selectedHours      = roadFsiStore.state.selectedHours;
        this.isVisible          = roadFsiStore.state.isVisible;
        this.selectedDensity    = roadFsiStore.state.selectedDensity;

        /*
         * Run ...
         */
        this.resetAnalytics();

        /* 
         * Handlers : Analytics, Timings ...
         */
        let startEvent    :string = "sourcedataloading";
        let endEvent      :string = "idle";
        map.on(startEvent, function(e) {
            that.timings.t0         = new Date()
            spinnerService.getSpinner().addOperation("MainMenuComponent.showRoadFsiData");
            setTimeout(function(){
                spinnerService.getSpinner().removeOperation("MainMenuComponent.showRoadFsiData");
            }, 60000);
        })
        map.on(endEvent, function(e){ /* End event*/
            that.timings.t1         = new Date()
            that.timings.delta_t    = that.timings.t1 - that.timings.t0
            spinnerService.getSpinner().removeOperation("MainMenuComponent.showRoadFsiData");
        })
        map.on("sourcedata", function(e) {
            let isDoneLoading: boolean = true         &&
                map.getSource(      'fsi_geoSourceCRASH')   &&
                map.isSourceLoaded( 'fsi_geoSourceCRASH')   
            if(isDoneLoading) that.updateAnalytics();
        });
    }

    ngOnInit() {
        this.timings            = {'t0': null, 't1': null, 'delta_t': null};
        this.subscriptions.push(this.session.onChangeWorkspace.subscribe(geometry => {
            this.refresh();
        }));
        this.roadFsiStore.useProxy(this);
        this.showElement();
    }

    /*
     * COMPONENT / HANDLERS ___________________________________________________
     */
    toggleVisibility(x: string){    this.isVisible[x] = !this.isVisible[x]; }
    toggleAges()        { this.selectedAges         = this.selectedAges.length          ? [] : this.roadFsiStore.state.ages; }
    toggleHours()       { this.selectedHours        = this.selectedHours.length         ? [] : this.roadFsiStore.state.hours; }
    toggleDOWs()        { this.selectedDOWs         = this.selectedDOWs.length          ? [] : this.roadFsiStore.state.dows; }
    toggleMonths()      { this.selectedMonths       = this.selectedMonths.length        ? [] : this.roadFsiStore.state.months; }
    toggleYears()       { this.selectedYears        = this.selectedYears.length         ? [] : this.roadFsiStore.state.years; }
    toggleUsers()       { this.selectedUsers        = this.selectedUsers.length         ? [] : this.roadFsiStore.state.users; }
    toggleSeverities()  { this.selectedSeverities   = this.selectedSeverities.length    ? [] : this.roadFsiStore.state.severities; }
}
