import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {VisibilityEntryPanelComponent} from './visibility-entry-panel/visibility-entry-panel.component';
import {VisibilityStoreService} from './visibility-store.service';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-visibility-entry',
  templateUrl: './visibility-entry.component.html',
  styleUrls: ['./visibility-entry.component.css']
})
export class VisibilityEntryComponent implements OnInit {

  constructor(public session: Session, public visibilityStore: VisibilityStoreService, public panelService: PanelService) {
  }

  ngOnInit(): void {
  }

  openMenuVisibility() {
    this.panelService.push(
      VisibilityEntryPanelComponent,
      null
    )
    Matomo.push(['trackEvent', 'Actions', 'Opened visibility menu'])
  }

  removeVisibility() {
    this.visibilityStore.reset();
    Matomo.push(['trackEvent', 'Actions', 'Removed visibility elements'])

  }
}
