<div class="ui-g sticky" style="padding-right: 10px;">
  <div class="ui-g-1 titleButton">
    <i class="fa fa-arrow-left" (click)="show(false)"></i>
  </div>
  <div class="ui-g-10">
    <h1>{{ 'groundEntry.title' | translate }}</h1>
  </div>

  <div class="ui-g-1 titleButton" *ngIf="_versions;else noVersion">
    <i class="fa fa-history itemUnchecked" (click)="showVersions()"></i>
  </div>
  <ng-template #noVersion>
    <div class="ui-g-1 disabled-icon">
    <i class="fa fa-history itemDisabled" pTooltip="{{ 'groundEntry.groundViewPanel.historyDisable' | translate }}"
       tooltipPosition="right"></i>
    </div>
  </ng-template>
</div>

<div *ngIf="element">
  <div class="subMenu">
    <div style="display: flex; flex-direction:column; margin-top: 10px;" class="listMenuElement">

      <div style="display: flex; justify-content: space-between; border-bottom: 1px solid rgba(0, 0, 0, 0.2);">
        <div style="display: flex; justify-content: space-between; min-height: 140px; flex-direction: column;">
          <span style="font-weight: bold; margin-bottom: 20px;"
                class="signTitle">{{element.elementClassDetail?.classDescription}} ({{element.elementCategory}})</span>
          <div style="flex-direction: row; justify-content: flex-start; order: 0; margin-bottom: 10px">
            <p class="listMenuElementTitle">{{ 'common.quality' | translate }}</p>
            <p>{{element.elementQuality | elementQualityPipe}} ({{element.elementQuality}})</p>
            <app-quality-selector class="qualityBubble" *ngIf="qualityList !== undefined" [data]="element"
                                  [qualityList]="qualityList"
                                  (dataChange)="setNewElementQuality($event)"></app-quality-selector>
          </div>
        </div>
        <img alt="sign icon" *ngIf="(elementIcon !== undefined)" [src]="elementIcon" class="compareIconSign"
             style="margin: 0 10px"/>
      </div>

      <app-crop-image *ngIf="element" [element]="element" [viewType]="viewType"></app-crop-image>

    </div>
  </div>
</div>

<p-accordion [multiple]="true" *ngIf="element">
  <p-accordionTab header="{{ 'common.product' | translate }}">
    <div>
      <ul class="subMenu" *ngIf="productColor">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.color' | translate }}</p>
          <p>{{productColor }}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="productType">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.type' | translate }}</p>
          <p>{{productType | elementAxeGround}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="productAxis">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.axis' | translate }}</p>
          <p>{{productAxis | elementAxeGround}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="productDirection">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.direction' | translate }}</p>
          <p>{{productDirection | elementAxeGround}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="productSize">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.size' | translate }}</p>
          <p>{{productSize}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>

  <p-accordionTab header="{{ 'common.metrics' | translate }}">
    <div>
      <ul class="subMenu" *ngIf="fill_coef">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'groundEntry.groundViewPanel.coefFill' | translate }}</p>
          <p>{{fill_coef | number}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="linear_length">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'groundEntry.groundViewPanel.linearLength' | translate }}</p>
          <p>{{linear_length | number}} m</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="painted_length">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'groundEntry.groundViewPanel.paintedLength' | translate }}</p>
          <p>{{painted_length | number}} m</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="painted_surface">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'groundEntry.groundViewPanel.paintedSurface' | translate }}</p>
          <p>{{painted_surface | number}} m2</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="surface">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'groundEntry.groundViewPanel.surface' | translate }}</p>
          <p>{{surface | number}} m2</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="unit_number">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'groundEntry.groundViewPanel.unit' | translate }}</p>
          <p>{{unit_number}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>

  <p-accordionTab header="{{ 'common.snapshot' | translate }}">
    <div>
      <ul class="subMenu">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.date' | translate }}</p>
          <p>{{element.elementTimestamp | moment: 'LLL'}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="element.snapshotName">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.snapshotName' | translate }}</p>
          <p>{{element.snapshotName}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="element.snapshotIdentifier">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.snapshotIdentifier' | translate }}</p>
          <p>{{element.snapshotIdentifier}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
  <p-accordionTab header="{{ 'common.details' | translate }}">
    <div>
      <ul class="subMenu">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.elementClass' | translate }}</p>
          <p>{{element.elementClass}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.elementIdentifier' | translate }}</p>
          <p>{{element.elementIdentifier}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="element.viewIdentifier">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.viewIdentfier' | translate }}</p>
          <p>{{element.viewIdentifier}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
  <p-accordionTab header="{{ 'common.localization' | translate }}">
    <div>
      <ul class="subMenu">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.coordinates' | translate }}</p>
          <p>Long: {{showLong}}</p>
          <p>Lat : {{showLat}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.openLocationCode' | translate }}</p>
          <p>{{openLocCode}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
</p-accordion>
<p-confirmDialog [style]="{width: '50vw'}" [transitionOptions]="'200ms'" key="GroundComponent"></p-confirmDialog>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
