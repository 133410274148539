import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from "../globals/session";
import {StatusEnum} from "../enums/status-enum.enum";
import {StatusListModel} from "../models/masterData/status-list-model";
import {ListCollectionModel} from "../models/masterData/list-collection-model";
import {ElementClassCollectionModel} from "../models/masterData/element-class-collection-model";
import {ConfigCollectionModel} from "../models/masterData/config-collection-model";


@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(private session: Session, private httpService: HttpClient) {
  }

  getStatusList(sessionIdentifier): Observable<StatusListModel> {
    const url = environment.urlGetStatusList;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
        sessionIdentifier: sessionIdentifier
      },
      statusGroup: StatusEnum.STATUS_GROUP_PROCESSING
    }, options).pipe(map(data => new StatusListModel().deserialize(data)),
      catchError(() => throwError('Status List not found'))
    );
  }

  getList(listCode): Observable<ListCollectionModel> {
    const url = environment.urlMasterdataGetLists;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      listCode,
    }, options).pipe(map(data => {

        return new ListCollectionModel().deserialize(data);
      }),
      catchError((err) => throwError(err + ' List not found ' + listCode))
    );
  }

  getLists(listGroup): Observable<ListCollectionModel> {
    const url = environment.urlMasterdataGetLists;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      listGroup,
    }, options).pipe(map(data => new ListCollectionModel().deserialize(data)),
      catchError(() => throwError('List not found'))
    );
  }

  getListCode(listCode): Observable<ListCollectionModel> {
    const url = environment.urlMasterdataGetLists;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      listCode,
    }, options).pipe(map(data => new ListCollectionModel().deserialize(data)),
      catchError(() => throwError('List not found'))
    );
  }

  getElementClass(elementType: string, category: string = undefined, classCode:string = undefined): Observable<ElementClassCollectionModel> {
    const url = environment.urlGetElementClass;

    // this.loaderService.addOperation('getElementClass');

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)

    const options = {headers: headers};

    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL,
          ResponseLevelEnum.PAGING
        ],
      },
      elementType: elementType,
      classCategory: category,
      classCode: classCode
    }, options).pipe(map(data => {
        // this.loaderService.removeOperation('getElementClass');
        return new ElementClassCollectionModel().deserialize(data);
      }),
      catchError(error => {
        // this.loaderService.removeOperation('getElementClass');
        // this.messageService.add(MessageHelper.createMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
        return throwError('Element class list not found');
      })
    );
  }

  getElementClassByClassNameByPost(elementClass: string): Observable<ElementClassCollectionModel> {
    const url = environment.urlGetElementClass;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)

    const options = {headers: headers};

    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL,
          ResponseLevelEnum.PAGING
        ],
      },
      elementClass: elementClass
    }, options).pipe(map(data => {
        return new ElementClassCollectionModel().deserialize(data);
      }),
      catchError(error => {
        return throwError('Element class not found');
      })
    );
  }

  getElementClassByClassName(elementClass: string): Observable<ElementClassCollectionModel> {
    const url = `${environment.urlElementClass}?elementClass=${elementClass}`;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)

    const options = {headers: headers};

    return this.httpService.get(url, options).pipe(map(data => {
        return new ElementClassCollectionModel().deserialize(data);
      }),
      catchError(error => {
        return throwError('Element class not found');
      })
    );
  }

  getConfigurations(codeConfig): Observable<ConfigCollectionModel> {
    const url = environment.urlMasterdataGetConfig;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      configCode: codeConfig
    }, options).pipe(map(data => new ConfigCollectionModel().deserialize(data)),
      catchError((_err) => {
        return throwError('Config not displayable');
      })
    );
  }
}
