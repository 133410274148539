import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ListCodeEnum} from 'src/app/enums/list-code-enum.enum';
import {ViewTypesEnum} from 'src/app/enums/view-types-enum.enum';
import {Session} from 'src/app/globals/session';
import {ObjectElementModel} from 'src/app/models/annotation/object-element-model';
import {ListCollectionModel} from 'src/app/models/masterData/list-collection-model';
import {ListValueModel} from 'src/app/models/masterData/list-value-model';
import {AnnotationService} from 'src/app/services/annotation.service';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MasterDataService} from 'src/app/services/master-data.service';
import {VersionService} from 'src/app/services/versions/version.service';
import {ViewerService} from 'src/app/services/viewer.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {ViewVersionComponent} from "../../../sharedViews/view-version/view-version.component";

declare let OpenLocationCode: any;

@Component({
  selector: 'app-ground-view-panel',
  templateUrl: './ground-view-panel.component.html',
  styleUrls: ['./ground-view-panel.component.css']
})
export class GroundViewPanelComponent implements OnInit, PanelBase<string> {

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }

  constructor(private annotationService: AnnotationService,
              private geojsonService: GeojsonService,
              public session: Session,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private masterDataService: MasterDataService,
              private viewerService: ViewerService,
              private versionService: VersionService,
              private spinnerService: SpinnerService,
              private mapService: MapService,
              private toastService: ToastService,
              private panelService: PanelService) {
  }

  data: string;

  element: ObjectElementModel = null;
  elementIcon: string = '';
  viewType: ViewTypesEnum = ViewTypesEnum.VIEW_GROUND_L;

  productColor: string = '';
  productAxis: string = '';
  productDirection: string = '';
  productType: string = '';
  productSize: string = '';

  fill_coef = '';
  linear_length = '';
  painted_length = '';
  painted_surface = '';
  surface = '';
  unit_number = '';

  _isVisible: boolean = false;

  onEdition: boolean = false;

  openLocCode: Uint8Array;
  showLong: string;
  showLat: string;

  qualityList: ListValueModel[];

  _versions = null;
  _showVersions = false;

  ngOnInit() {
    this.masterDataService.getList(ListCodeEnum.QUALITY_THRESHOLD).subscribe((response: ListCollectionModel) => {
      if (response) {
        this.qualityList = response.lists[0].listValues;
      }
    });
    this.selectedElement = this.data
  }

  ngOnDestroy(): void {
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if (show) {
      this.onEdition = false;
    }
    if (!show && !this.onEdition) {
      this.map.resizeElementsMarkers();
    }
    if (!show) {
      this.panelService.pop();
    }
  }

  set selectedElement(elementIdentifier: string) {
    this._versions = null;
    this._showVersions = false;
    if (elementIdentifier !== undefined) {
      this.versionService
      .getObjectElementVersions(elementIdentifier)
      .subscribe(response => this._versions = response != null && response.length > 0 ? response : null);

      this.annotationService.getObjectElement(elementIdentifier).subscribe((objectElement: ObjectElementModel) => {

        this.element = objectElement;
        if (this.element.elementGeometry.type === "Point") {
          let openLocCode = new OpenLocationCode();
          this.showLat = this.element.elementGeometry.coordinates[1];
          this.showLong = this.element.elementGeometry.coordinates[0];
          //its possible to have more precise data if we add 11 as third parameter
          this.openLocCode = openLocCode.encode(this.element.elementGeometry.coordinates[0], this.element.elementGeometry.coordinates[1]);
        } else {
          let openLocCode = new OpenLocationCode();
          this.showLat = this.element.elementGeometry.coordinates[0][1];
          this.showLong = this.element.elementGeometry.coordinates[0][0];
          //its possible to have more precise data if we add 11 as third parameter
          this.openLocCode = openLocCode.encode(this.element.elementGeometry.coordinates[0][0], this.element.elementGeometry.coordinates[0][1]);
        }

        if (this.element.elementClassDetail) {
          this.elementIcon = environment.urlMedia + this.element.elementClassDetail.classIconFilePath;
        } else {
          this.elementIcon = undefined;
        }

        if (this.element.elementProperties['qualification'] != undefined) {
          this.productColor = this.element.elementProperties['qualification']['colour'];
          this.productAxis = this.element.elementProperties['qualification']['axis'];
          this.productDirection = this.element.elementProperties['qualification']['direction'];
          this.productType = this.element.elementProperties['qualification']['painting_type'];
          this.productSize = this.element.elementProperties['qualification']['size'];
        }

        if (this.element.elementProperties['metrics'] !== undefined) {
          this.fill_coef = this.element.elementProperties['metrics']['fill_factor'];
          this.linear_length = this.element.elementProperties['metrics']['length'];
          this.painted_length = this.element.elementProperties['metrics']['painted_length'];
          this.painted_surface = this.element.elementProperties['metrics']['painted_surface'];
          this.surface = this.element.elementProperties['metrics']['surface'];
          this.unit_number = this.element.elementProperties['metrics']['unit_number'];
        }
      });

      this.show();
    } else {
      this.show(false);
    }
  }

  setNewElementQuality(e) {
    this.element = e;
    this.spinner.addOperation("ViewGroundComponent.updateElement");

    this.annotationService.updateObjectElement(this.element).subscribe(response => {
      if (response) {
      }
      this.spinner.removeOperation("ViewGroundComponent.updateElement");

    });
  }

  showVersions() {
    this.panelService.push(
      ViewVersionComponent,
      this._versions
    )
  }
}
