import { Injectable } from '@angular/core';
import { Store } from 'src/app/globals/store';

const actualYear: number = new Date().getFullYear();

export class RoughnessState{
  maxGoodQuality: number = 3;
  minWorstQuality: number = 7;

  minPeriod : number = actualYear - 5;
  maxPeriod: number = actualYear;

  period = [0,Infinity]
  quality = [3,7]

  activated = false;
}

@Injectable({
  providedIn: 'root' 
})
export class RoughnessStoreService extends Store<RoughnessState> {

  constructor() {
    super(RoughnessState)
  }
}
