import {GeometryTypeEnum} from "../enums/geometry-type-enum.enum";
// import {GeojsonDao} from "../dao/geojson-dao";
import {FeatureHelper} from "./feature-helper";
import {GeometryHelper} from "./geometry.helper";
import {RoadNode, linkTypeEnum} from "../linked-list/road-node";


import {GeometryModel} from "../models/geojson/geometry-model";
import {FeatureModel} from "../models/geojson/feature-model";

export function msToTime(ms: number) {
  let seconds: number = +(ms / 1000);
  let minutes: number = +(ms / (1000 * 60));
  let hours: number = +(ms / (1000 * 60 * 60));
  let days: number = +(ms / (1000 * 60 * 60 * 24));

  if (seconds < 60) return seconds.toFixed(1) + " Sec";
  else if (minutes < 60) return minutes.toFixed(1) + " Min";
  else if (hours < 24) return hours.toFixed(1) + " Hrs";
  else return days.toFixed(1) + " Days"
}

export function idt(n: RoadNode<GeometryModel>) {
  if (n && n.dt)
    return n.dt.properties.segment_identifier;
  return undefined
}

export function timeEstimation(iterationsDone: number, iterationsToDo: number, start: number) {
  let current = new Date().getTime();

  let timeRemaining = (current - start) * (iterationsToDo / iterationsDone);


}

export function getTimeEstimation(iterationsDone: number, iterationsToDo: number, start: number): string {
  let current = new Date().getTime();

  let timeRemaining = (current - start) * (iterationsToDo / iterationsDone);

  return ' \x1b[35m|\x1b[0m Remaining: ' + msToTime(timeRemaining) + ' \x1b[35m|\x1b[0m Elapsed: ' + msToTime(current - start);
}

export function dropDuplicatesSegmentId(arr: GeometryModel[]): GeometryModel[] {
  return arr.filter((thing, index) => {
    const _thing = thing.properties.segment_identifier;
    return index === arr.findIndex(obj => {
      return obj.properties.segment_identifier === _thing;
    });
  });
}

// export async function importSegmentsAroundWay(way_identifier: string, geojsonDao: GeojsonDao, range: number): Promise<string> {
//
//   let query: string = "select st_astext(way_geo) as way_geo from ds_way_main where way_identifier = '" + way_identifier + "'";
//   let result = await geojsonDao.fetchGeoJson(query, undefined);
//   let geo_point: string = result[0].properties.way_geo
//
//   query = "select s.segment_identifier,\n" +
//     "       s.segment_class,\n" +
//     "       s.segment_type,\n" +
//     "       s.segment_axis,\n" +
//     "       s.segment_lanes,\n" +
//     "       s.segment_width,\n" +
//     "       s.segment_speedlimit_min,\n" +
//     "       s.segment_speedlimit_max,\n" +
//     "       (s.segment_properties::json #>> '{way,urban}')::text as segment_urban,\n" +
//     "       s.segment_geo_type,\n" +
//     "       s.ds_identifier,\n" +
//     "       s.segment_level,\n" +
//     "       array_agg(ln.way_identifier) as way_identifiers,\n" +
//     "       MIN(dm.way_geo_zoom) as way_geo_zoom,\n" +
//     "       st_asgeojson(s.segment_geo) segment_geojson\n" +
//     "    from ds_segment_main s left join ds_segment_ln_way_main ln on s.segment_identifier = ln.segment_identifier\n" +
//     "                           left join ds_way_main dm on ln.way_identifier = dm.way_identifier\n" +
//     "    where ST_DWithin(s.segment_geo, ST_GeomFromText('" + geo_point + "'), " + range + ") = True" +
//     "    group by s.segment_identifier, s.segment_class, s.segment_type, s.segment_axis, s.segment_lanes, s.segment_width, s.segment_speedlimit_min," +
//     "             s.segment_speedlimit_max, segment_urban, s.segment_geo_type, s.ds_identifier, s.ds_identifier, segment_geojson" +
//     "            , s.segment_level"
//
//   return query;
// }

export async function importPrPointsOnWay(way_identifier: string) {
  return "select " +
    "pr_identifier," +
    "system_id," +
    "pr_county," +
    "pr_way_name," +
    "pr_way_type," +
    "pr_name," +
    "pr_display_name," +
    "pr_code," +
    "pr_axis," +
    "pr_position," +
    "st_asgeojson(pr_geo) pr_geo," +
    "pr_order," +
    "way_identifier" +
    " from ds_pr_point where way_identifier = '" + way_identifier + "' and is_deleted = false"
}

export async function importSegmentsAroundPoint(longitude: number, latitude: number, range: number): Promise<string> {

  return "select s.segment_identifier,\n" +
    "       s.segment_class,\n" +
    "       s.segment_type,\n" +
    "       s.segment_axis,\n" +
    "       s.segment_lanes,\n" +
    "       s.segment_width,\n" +
    "       s.segment_speedlimit_min,\n" +
    "       s.segment_speedlimit_max,\n" +
    "       (s.segment_properties::json #>> '{way,urban}')::text as segment_urban,\n" +
    "       s.segment_geo_type,\n" +
    "       s.ds_identifier,\n" +
    "       s.segment_level,\n" +
    "       array_agg(ln.way_identifier) as way_identifiers,\n" +
    "       MIN(dm.way_geo_zoom) as way_geo_zoom,\n" +
    "       st_asgeojson(s.segment_geo) segment_geojson\n" +
    "    from ds_segment_main s left join ds_segment_ln_way_main ln on s.segment_identifier = ln.segment_identifier\n" +
    "                           left join ds_way_main dm on ln.way_identifier = dm.way_identifier\n" +
    "    where ST_DWithin(s.segment_geo, ST_GeomFromText('POINT(" + longitude + " " + latitude + ")'), " + range + ") = True" +
    "    group by s.segment_identifier, s.segment_class, s.segment_type, s.segment_axis, s.segment_lanes, s.segment_width, s.segment_speedlimit_min," +
    "             s.segment_speedlimit_max, segment_urban, s.segment_geo_type, s.ds_identifier, s.ds_identifier, segment_geojson" +
    "            , s.segment_level";
}

export async function importSegmentsGeoPoint(geo_point: string, range: number): Promise<string> {

  return "select s.segment_identifier,\n" +
    "       s.segment_class,\n" +
    "       s.segment_type,\n" +
    "       s.segment_axis,\n" +
    "       s.segment_lanes,\n" +
    "       s.segment_width,\n" +
    "       s.segment_speedlimit_min,\n" +
    "       s.segment_speedlimit_max,\n" +
    "       (s.segment_properties::json #>> '{way,urban}')::text as segment_urban,\n" +
    "       s.segment_geo_type,\n" +
    "       s.ds_identifier,\n" +
    "       s.segment_level,\n" +
    "       array_agg(s.segment_state) as segment_state,\n" +
    "       array_agg(s.segment_county) as segment_county,\n" +
    "       array_agg(ln.way_identifier) as way_identifiers,\n" +
    "       st_asgeojson(s.segment_geo) segment_geojson\n" +
    "    from ds_segment_main s left join ds_segment_ln_way_main ln on s.segment_identifier = ln.segment_identifier\n" +
    "    where ST_DWithin(s.segment_geo, ST_GeomFromText('" + geo_point + "'), " + range + ") = True" +
    "    group by s.segment_identifier, s.segment_class, s.segment_type, s.segment_axis, s.segment_lanes, s.segment_width, s.segment_speedlimit_min," +
    "             s.segment_speedlimit_max, segment_urban, s.segment_geo_type, s.ds_identifier, s.ds_identifier, segment_geojson" +
    "            , s.segment_level";
}

export async function importSegmentsInSquare(args: any): Promise<string> {
  return "select " +
    "       s.segment_identifier,\n" +
    "       s.segment_class,\n" +
    "       s.segment_type,\n" +
    "       s.segment_axis,\n" +
    "       s.segment_lanes,\n" +
    "       s.segment_width,\n" +
    "       s.segment_speedlimit_min,\n" +
    "       s.segment_speedlimit_max,\n" +
    "       (s.segment_properties::json #>> '{way,urban}')::text as segment_urban,\n" +
    "       s.segment_geo_type,\n" +
    "       s.ds_identifier,\n" +
    "       s.segment_level,\n" +
    "       array_agg(s.segment_state) as segment_state,\n" +
    "       array_agg(s.segment_county) as segment_county,\n" +
    "       array_agg(ln.way_identifier) as way_identifiers,\n" +
    "       st_asgeojson(s.segment_geo) segment_geojson,\n" +
    "       st_asgeojson(st_buffer(s.segment_geo, s.segment_width * 1.5)) buffered_segment_geojson,\n" +
    "       st_asgeojson(st_segmentize(st_buffer(s.segment_geo, s.segment_width * 1.5), "+ args['segment_size'] +")) small_buffered_segment_geojson\n" +
    "    from ds_segment_main s left join ds_segment_ln_way_main ln on s.segment_identifier = ln.segment_identifier\n" +
    "    where ST_DWithin(" +
    "                     s.segment_geo," +
    "                    ST_Polygon('LINESTRING(" +
    args['pointA_coordX'] + " " + args['pointA_coordY'] + "," +
    args['pointB_coordX'] + " " + args['pointA_coordY'] + "," +
    args['pointB_coordX'] + " " + args['pointB_coordY'] + "," +
    args['pointA_coordX'] + " " + args['pointB_coordY'] + "," +
    args['pointA_coordX'] + " " + args['pointA_coordY'] +
    "                                                                            )'::geometry, 4326), " + args['range'] + ") = True" +
    "    group by s.segment_identifier, s.segment_class, s.segment_type, s.segment_axis, s.segment_lanes, s.segment_width, s.segment_speedlimit_min," +
    "             s.segment_speedlimit_max, segment_urban, s.segment_geo_type, s.ds_identifier, s.ds_identifier, segment_geojson, buffered_segment_geojson, small_buffered_segment_geojson" +
    "            , s.segment_level";
}

export function computeFeatureRandomColorFromNode(node: RoadNode<GeometryModel>, response: FeatureModel[], additionalProperties: any = {}) {
  let properties = Object.assign({}, node.dt.properties, additionalProperties);
  properties['stroke'] = getRandomColor();
  properties['stroke-width'] = 3;
  properties['stroke-opacity'] = 0.85;
  properties['bearing'] = GeometryHelper.getBearing(GeometryHelper.createPoint(node.dt.coordinates[0]), GeometryHelper.createPoint(node.dt.coordinates[node.dt.coordinates.length - 1]));
  properties['bearing-start'] = GeometryHelper.getBearing(GeometryHelper.createPoint(node.dt.coordinates[0]), GeometryHelper.createPoint(node.dt.coordinates[1]));
  properties['bearing-end'] = GeometryHelper.getBearing(GeometryHelper.createPoint(node.dt.coordinates[node.dt.coordinates.length - 2]), GeometryHelper.createPoint(node.dt.coordinates[node.dt.coordinates.length - 1]));

  properties['links'] = node.links.map(x =>
    x[1] & linkTypeEnum.NEXT ? 'NEXT ' + idt(x[0]) :
      x[1] & linkTypeEnum.PREV ? 'PREV ' + idt(x[0]) : '');

  response.push(FeatureHelper.createFeature(node.dt, {...properties}))
}

export function computeFeatureRandomColorFromGeo(geo: GeometryModel, response: FeatureModel[], additionalProperties: any = {}) {
  let properties = Object.assign({}, geo.properties, additionalProperties);
  properties['stroke'] = getRandomColor();
  properties['stroke-width'] = 3;
  properties['stroke-opacity'] = 0.85;
  properties['bearing'] = GeometryHelper.getBearing(GeometryHelper.createPoint(geo.coordinates[0]), GeometryHelper.createPoint(geo.coordinates[geo.coordinates.length - 1]));
  properties['bearing-start'] = GeometryHelper.getBearing(GeometryHelper.createPoint(geo.coordinates[0]), GeometryHelper.createPoint(geo.coordinates[1]));
  properties['bearing-end'] = GeometryHelper.getBearing(GeometryHelper.createPoint(geo.coordinates[geo.coordinates.length - 2]), GeometryHelper.createPoint(geo.coordinates[geo.coordinates.length - 1]));
  properties['lane_width'] = properties['segment_lanes'] ? properties['segment_width'] / properties['segment_lanes'] : 0;

  delete properties['nodes'];

  response.push(FeatureHelper.createFeature(geo, properties))
}

export function computeFeatureColorFromGeo(geo: GeometryModel, response: FeatureModel[], additionalProperties: any = {}, color='#5a6034') {
  let properties = Object.assign({}, geo.properties, additionalProperties);
  properties['stroke'] = color;
  properties['stroke-width'] = 3;
  properties['stroke-opacity'] = 0.85;
  properties['bearing'] = GeometryHelper.getBearing(GeometryHelper.createPoint(geo.coordinates[0]), GeometryHelper.createPoint(geo.coordinates[geo.coordinates.length - 1]));
  properties['bearing-start'] = GeometryHelper.getBearing(GeometryHelper.createPoint(geo.coordinates[0]), GeometryHelper.createPoint(geo.coordinates[1]));
  properties['bearing-end'] = GeometryHelper.getBearing(GeometryHelper.createPoint(geo.coordinates[geo.coordinates.length - 2]), GeometryHelper.createPoint(geo.coordinates[geo.coordinates.length - 1]));
  properties['lane_width'] = properties['segment_lanes'] ? properties['segment_width'] / properties['segment_lanes'] : 0

  delete properties['nodes'];
  response.push(FeatureHelper.createFeature(geo, properties))
}

export function computeFeatureColorFromGeoShift(geo: GeometryModel, response: FeatureModel[], additionalProperties: any = {}, color='#5a6034', totalShift = 0.00003) {
  let properties = Object.assign({}, geo.properties, additionalProperties);

  if (geo.type == GeometryTypeEnum.LINESTRING) {
    properties['stroke'] = color;
    properties['stroke-width'] = 3;
    properties['stroke-opacity'] = 0.85;
    properties['lane_width'] = properties['segment_lanes'] ? properties['segment_width'] / properties['segment_lanes'] : 0;
    properties['bearing'] = GeometryHelper.getBearing(GeometryHelper.createPoint(geo.coordinates[0]), GeometryHelper.createPoint(geo.coordinates[geo.coordinates.length - 1]));
    properties['bearing-start'] = GeometryHelper.getBearing(GeometryHelper.createPoint(geo.coordinates[0]), GeometryHelper.createPoint(geo.coordinates[1]));
    properties['bearing-end'] = GeometryHelper.getBearing(GeometryHelper.createPoint(geo.coordinates[geo.coordinates.length - 2]), GeometryHelper.createPoint(geo.coordinates[geo.coordinates.length - 1]));
  }
  if (geo.type == GeometryTypeEnum.POINT) {
    properties['marker-color'] = color;
    delete properties['stroke'];
    delete properties['stroke-width'];
    delete properties['stroke-opacity']
  }

  delete properties['nodes'];

  function shiftPoint(coordinates: number[]) {
    let xShift: number = totalShift * Math.random() * (Math.random() < 0.5 ? -1 : 1);
    let yShift: number = totalShift * (1 - Math.abs(xShift)) * (Math.random() < 0.5 ? -1 : 1);

    coordinates[0] = coordinates[0] + xShift;
    coordinates[1] = coordinates[1] + yShift;
    return coordinates;
  }

  if (geo.type == GeometryTypeEnum.POINT)
    geo.coordinates = shiftPoint(geo.coordinates);
  if (geo.type == GeometryTypeEnum.LINESTRING)
    geo.coordinates.forEach((c, i) => geo.coordinates[i] = shiftPoint(c));
  response.push(FeatureHelper.createFeature(geo, properties))
}


export function getRandomColor() {
  let letters: string = '0123456789ABCDEF';
  let color: string = '#';
  for (let i: number = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function slow(c: number = 1000000000) { // TODO remove me
  let i = 0;
  while (i < c) {
    i++;
  }

}

export function getNodeFromSegmentIdentifier(idt: string, segments: RoadNode<GeometryModel>[]): RoadNode<GeometryModel> {
  for (let s of segments)
    if (s.dt.properties['segment_identifier'] == idt)
      return s;
  return null;
}

export function printNode(node: RoadNode<GeometryModel>) {







}

export function printNodeFromIdt(idt: string, segments: RoadNode<GeometryModel>[]) {
  printNode(getNodeFromSegmentIdentifier(idt, segments))
}

export function getBearingNode(node: RoadNode<GeometryModel>) {
  return GeometryHelper.getBearing(GeometryHelper.createPoint(node.dt.coordinates[0]), GeometryHelper.createPoint(node.dt.coordinates[node.dt.coordinates.length - 1]))
}

export function getBearingNodeIdt(idt: string, segments: RoadNode<GeometryModel>[]) {
  return getBearingNode(getNodeFromSegmentIdentifier(idt, segments))
}

export function segmentType(node: RoadNode<GeometryModel>) {
  return node.dt.properties.segment_type
}

// const {bgWhite} = require("chalk");

// export class ProgressBar {
//   total: number;
//   current: number;
//   bar_length: number = Math.max(0, Math.min(process.stdout.columns - 80, 35));
//   startTime: any;
//   items: string[] = ['/', '-', '\\', '|', '/', '-', '\\', '|'];
//   pos: number = 0;
//
//   constructor(total: number, private title: string = 'Progress', private testMode: boolean = false) {
//     this.init(total)
//   }
//
//   init(total: number) {
//     this.total = total;
//     this.current = 0;
//     this.update(this.current);
//     this.startTime = new Date().getTime()
//   }
//
//   update(current: number = this.current + 1) {
//     if (this.testMode)
//       return;
//     this.current = current;
//     const current_progress = this.current / this.total;
//     this.draw(current_progress);
//   }
//
//   draw(current_progress: number) {
//     const filled_bar_length: number = +(current_progress * this.bar_length).toFixed(
//       0
//     );
//     const empty_bar_length = this.bar_length - filled_bar_length;
//
//     const filled_bar = this.get_bar(filled_bar_length, " ", bgWhite);
//     const empty_bar = this.get_bar(empty_bar_length, ".");
//     const percentage_progress = (current_progress * 100).toFixed(0);
//
//     if (process.stdout.clearLine) {
//       process.stdout.clearLine(-1);
//       process.stdout.cursorTo(0);
//       process.stdout.write(
//         this.title + ` ${filled_bar}${empty_bar} ${this.items[this.pos]} ${percentage_progress}%` +
//         getTimeEstimation(this.current, this.total - this.current, this.startTime)
//       );
//     }
//     this.pos = this.pos == this.items.length - 1 ? 0 : this.pos + 1
//   }
//
//   get_bar(length: number, char: string = '#', color: Function = (a: string) => a) {
//     let str = "";
//     for (let i = 0; i < length; i++) {
//       str += char;
//     }
//     return color(str);
//   }
//
//   end() {
//     this.update(this.total)
//     if (!this.testMode)

//   }
//
// }


// export class FileManager {
//
//   // Here we import the File System module of node
//   private fs = require('fs');
//
//   constructor() {
//   }
//
//   createFile(fileName: string) {
//
//     this.fs.writeFile(fileName, '', (err: any) => {
//       if (err)


//     });
//   }
//
//   write(fileName: string, content: any) {
//     this.fs.writeFile(fileName, content.toString(), (err: any) => {
//       if (err)


//     });
//   }
//
//   read(fileName: string): any {
//     try {
//       return this.fs.readFileSync(fileName, 'utf8');
//     } catch (err) {

//     }
//   }
// }
