<form [formGroup]="searchForm"  class="ui-g-12 ui-fluid ui-g-nopad searchComponent">
  <div class="form-group ui-inputgroup ui-fluid">
    <p-autoComplete #autocomplete class="ui-autocomplete" (completeMethod)="search($event)" [suggestions]="filteredResults"
                    autofocus="true" id="searchNominatim" placeholder="{{ 'searchComponent.autocomplete.placeholder' | translate }}" (onSelect)="searchSelect($event)"
                    formControlName="searchText" [(ngModel)]="searchStore.state.searchText"
                    (onFocus)="autocomplete.show()"
                    (onFocus)="history()"
                    (onClear)="clearItem(autocomplete)">

      <ng-template let-filteredResults pTemplate="item">
        <div class="history-item">
          <i class="fa fa-history" style="width:14%" *ngIf="displayHistory"></i>
          <div class="ui-city" style="width:100%">{{filteredResults}}</div>
          <i class="fa fa-times titleButton2" (click)="eraseHistoryEntry($event, filteredResults)" pTooltip="{{'searchComponent.autocomplete.template' | translate}}" tooltipPosition="right" *ngIf="displayHistory"></i>
        </div>
      </ng-template>
    </p-autoComplete>

    <div style="position: relative; top:1px; cursor: pointer;" pTooltip="{{'searchComponent.autocomplete.buttonAdvanced' | translate}}" tooltipPosition="right">
      <i class="fa fa-plus titleButton" (click)="openMenuSearch()"></i>
    </div>
  </div>
</form>

