import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {ModelingService} from "../../../services/modeling.service";
import {GeojsonService} from "../../../services/geojson.service";
import {ToastMessageEnum} from '../../../enums/toast-message-enum.enum'
import { ToastService } from 'src/app/shared/toast/toast.service';
import {SearchService} from "../search/search.service";
import {StatisticService} from "../../../services/statistic.service";

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.css']
})
export class ToastsComponent implements OnInit {

  toastMessageError: string;
  toastSummaryError: string;

  constructor(private messageService: MessageService,
              private modelingService: ModelingService,
              private geojsonService: GeojsonService,
              private searchService: SearchService,
              private statsService: StatisticService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.modelingService.ErrorMessage.subscribe( error => {
      this.toastMessageError = error.message;
      this.toastSummaryError = error.name + ' ' + error.status;
    });
    this.geojsonService.ErrorMessage.subscribe( error => {
      this.toastMessageError = error.message;
      this.toastSummaryError = error.name + ' ' + error.status;
    });
    this.searchService.ErrorMessage.subscribe( error => {
      this.toastMessageError = error.message;
      this.toastSummaryError = error.name + ' ' + error.status;
      this.showToastError();
    });
    this.statsService.ErrorMessage.subscribe( error => {
      this.toastMessageError = error.message;
      this.toastSummaryError = error.name + ' ' + error.status;
      this.showToastError();
    });

    this.toastService.setToast(this);
  }

  showToastError() {
    this.messageService.add({severity:'info', summary: this.toastSummaryError, detail: this.toastMessageError});
  }

  showToastWarn() {
    this.messageService.add(ToastMessageEnum.TOAST_WARN);
  }
}
