<p-sidebar [(visible)]="_isVisible" [showCloseIcon]="false" [modal]="false" [style]="{'width':'26em'}">
    <div class="ui-g sticky" style="padding-right: 10px;">
      <div class="ui-g-1 titleButton">
        <i class="fa fa-arrow-left" (click)="show(false)"></i>
      </div>
      <div class="ui-g-11">
        <h1>{{ 'furnitureEntry.title' | translate }}</h1>
      </div>
    </div>
    <div class="imageContainer" *ngIf="element">
  <!--    <button *ngIf="elementImage === undefined" pButton type="button" icon="pi pi-camera" class="ui-button-primary ui-button-margin viewerButton" (click)="showViewerNewElement()"></button>-->
  <!--    <img alt="sign image" *ngIf="(elementImage !== undefined)" [src]="elementImage" class="compareImageSign" (click)="showViewer()"/>-->
      <button *ngIf="elementImage === undefined" pButton type="button" icon="pi pi-camera" class="ui-button-primary ui-button-margin viewerButton" ></button>
      <img alt="sign image" *ngIf="(elementImage !== undefined)" [src]="elementImage" class="compareImageFurniture" />
      <img alt="sign icon" *ngIf="(elementIcon !== undefined)" [src]="elementIcon" class="compareImageFurniture"/>
    </div>
    <div *ngIf="element">
      <ul class="subMenu">
        <li class="listMenuElement" >
          <span class="signTitle">{{element.elementClassDetail.classDescription}} ({{element.elementCategory}})</span>
          <app-quality-selector *ngIf="qualityList !== undefined" [data]="element" [qualityList]="qualityList" (dataChange)="setNewElementQuality($event)"></app-quality-selector>

        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.quality' | translate }}</p>
          <p>{{element.elementQuality | elementQualityPipe}} ({{element.elementQuality}})</p>
        </li>
      </ul>
    </div>
    <p-accordion [multiple]="true" *ngIf="element">
      <p-accordionTab header="{{ 'common.properties' | translate }}" *ngIf="showQualification">
        <div>
          <ul class="subMenu">
            <li class="listMenuElement" *ngIf="yearBuild">
              <p class="listMenuElementTitle">{{ 'common.yearBuild' | translate }}</p>
              <p>{{yearBuild}}</p>
            </li>
          </ul>
          <ul class="subMenu">
            <li class="listMenuElement" *ngIf="builder">
              <p class="listMenuElementTitle">{{ 'common.builder' | translate }}</p>
              <p>{{builder}}</p>
            </li>
          </ul>
          <ul class="subMenu">
            <li class="listMenuElement" *ngIf="filmClass">
              <p class="listMenuElementTitle">{{ 'common.filmClass' | translate }}</p>
              <p>{{filmClass}}</p>
            </li>
          </ul>
          <ul class="subMenu" *ngIf="implementType">
            <li class="listMenuElement" >
              <p class="listMenuElementTitle">{{ 'common.implement' | translate }}</p>
              <p>{{implementType}}</p>
            </li>
          </ul>
          <ul class="subMenu" *ngIf="anchorType">
            <li class="listMenuElement" >
              <p class="listMenuElementTitle">{{ 'common.anchor' | translate }}</p>
              <p>{{anchorType}}</p>
            </li>
          </ul>

        </div>
      </p-accordionTab>
      <p-accordionTab header="{{ 'common.metrics' | translate }}" *ngIf="showMetrics">
        <div>
          <ul class="subMenu" *ngIf="size">
            <li class="listMenuElement" >
              <p class="listMenuElementTitle">{{ 'common.size' | translate }}</p>
              <p>{{size}}</p>
            </li>
          </ul>
          <ul class="subMenu">
            <li class="listMenuElement" *ngIf="heightSp">
              <p class="listMenuElementTitle">{{ 'common.heightSp' | translate }}</p>
              <p>{{heightSp}}</p>
            </li>
          </ul>
        </div>
      </p-accordionTab>
      <p-accordionTab header="{{ 'common.details' | translate }}">
        <div>
          <ul class="subMenu">
            <li class="listMenuElement" >
              <p class="listMenuElementTitle">{{ 'common.elementClass' | translate }}</p>
              <p>{{element.elementClass}}</p>
            </li>
          </ul>
          <ul class="subMenu">
            <li class="listMenuElement" >
              <p class="listMenuElementTitle">{{ 'common.elementIdentifier' | translate }}</p>
              <p>{{element.elementIdentifier}}</p>
            </li>
          </ul>
          <ul class="subMenu" *ngIf="element.viewIdentifier">
            <li class="listMenuElement" >
              <p class="listMenuElementTitle">{{ 'common.viewIdentifier' | translate }}</p>
              <p>{{element.viewIdentifier}}</p>
            </li>
          </ul>
        </div>
      </p-accordionTab>
      <p-accordionTab header="{{ 'common.localization' | translate }}">
        <div>
          <ul class="subMenu">
            <li class="listMenuElement" >
              <p class="listMenuElementTitle">{{ 'common.coordinates' | translate }}</p>
              <p>Long: {{showLong}}</p>
              <p>Lat : {{showLat}}</p>
            </li>
          </ul>
          <ul class="subMenu">
            <li class="listMenuElement" >
              <p class="listMenuElementTitle">{{ 'common.openLocationCode' | translate }}</p>
              <p>{{openLocCode}}</p>
            </li>
          </ul>
        </div>
      </p-accordionTab>
    </p-accordion>
  </p-sidebar>
  <p-confirmDialog [style]="{width: '50vw'}"  [transitionOptions]="'200ms'"  key="SignComponent"></p-confirmDialog>
  <p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
