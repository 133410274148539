let urlApi          = window['env']['apiUrl'        ]; 
let oauthClientId   = window['env']['oauthClientId' ];
let oauthIssuerUrl  = window['env']['oauthIssuerUrl']; 
let urlMedia        = window['env']['mediaUrl'      ]; 
let storageUrl      = window['env']['storageUrl'    ]; 
let matomoSiteId    = window['env']['matomoSiteId'  ]; 

export const environment = {
    auth: {
          domain    : oauthIssuerUrl
        , clientId  : oauthClientId
        , audience  : 'ApiMain'
        , urlApi
    }
    , urlApi
    , production                    		: false
    , googleTracking                		: false
    , urlMedia
    , urlStorage                    		: storageUrl

    , urlGetStatisticElements       		: `${urlApi}/v2/statistic/elements`                                     		// Statistics API
    , urlGetExportStatisticElements 		: `${urlApi}/v2/statistic/elements/document`                            		// Statistics API

    , urlGetLocationMarker          		: `${urlApi}/citymagine-datasource-3.0/geojson/location-markers`        		// Datasource API
    , getSegmentsComputed           		: `${urlApi}/citymagine-datasource-3.0/geojson/getAnnotation`           		// Datasource API
    , urlGetMainSegments            		: `${urlApi}/citymagine-datasource-3.0/geojson/getMainSegments`         		// Datasource API

    , urlTilesElements              		: `${urlApi}/v2/tile/elements/{z}/{x}/{y}.mvt`                          		// Tiles API
    , urlTilesWays                  		: `${urlApi}/citymagine-datasource-3.0/tiles/mainWays/{z}/{x}/{y}.mvt?` 		// Tiles API

    , urlRoadRisk                   		: `${urlApi}/v2/tile/risks/{z}/{x}/{y}.mvt`                             		// Road Safety & Accidentology
    , urlRoadFlow                   		: `${urlApi}/v2/tile/flows/{z}/{x}/{y}.mvt`                             		// Road Safety & Accidentology
    , urlRoadFsi                    		: `${urlApi}/v2/tile/fsis/{z}/{x}/{y}.mvt`                              		// Road Safety & Accidentology
    , urlRoadFsiDetails             		: `${urlApi}/v2/tile/fsis/accident/{id}.json`                           		// Road Safety & Accidentology
    , urlRoadFsiVictims             		: `${urlApi}/v2/tile/fsis/victim/{id}.json`                             		// Road Safety & Accidentology

    , urlApiNominatim               		: 'https://open-street-api.citymagine.com/search?q='                    		// Nominatim
    , urlApiNominatimParam          		: '&format=json&polygon_geojson=1&limit=10&polygon_threshold=0.001'     		// Nominatim // &countrycodes=FR,BE
    , urlApiNominatimReverse        		: 'https://open-street-api.citymagine.com/reverse?format=json&'         		// Nominatim

    , accessMapboxToken             		: 'pk.eyJ1IjoiY2l0eW1hZ2luZSIsImEiOiJjajZwNG1yZTEwandtMzJwczd2eGszbXdyIn0.W909QH_JtJ9YRSIlBdkUZg'

    , tilesPrServer                 		: `${urlApi}/citymagine-datasource-3.0/tiles/pr/{z}/{x}/{y}.mvt`
    , tilesRoadFsiServer            		: `${urlApi}/citymagine-datasource-3.0/tiles/crashs/{z}/{x}/{y}.mvt`
    , tilesVisibilityServer         		: `${urlApi}/citymagine-datasource-3.0/tiles/visibilityCurves/{z}/{x}/{y}.mvt`
    , tilesRoughnessServer          		: `${urlApi}/citymagine-datasource-3.0/tiles/roughness/{z}/{x}/{y}.mvt`
    , tilesGlareServer              		: `${urlApi}/citymagine-datasource-3.0/tiles/glares/{z}/{x}/{y}.mvt`
    , tilesCurveServer              		: `${urlApi}/citymagine-datasource-3.0/tiles/curves/{z}/{x}/{y}.mvt`
    , tilesNarrowRoadsServer        		: `${urlApi}/citymagine-datasource-3.0/tiles/narrowroad/{z}/{x}/{y}.mvt`
    , tilesAlertServer              		: `${urlApi}/citymagine-api-3.0/rest/tile/alerts/{z}/{x}/{y}.mvt`
    , tilesSnapshotServer           		: `${urlApi}/v2/tile/snapshots/{z}/{x}/{y}.mvt`

    , mapStyleDefault               		: `${urlMedia}/v3/mapbox-sprite/style.json`
    , mapStyleSatellite             		: 'mapbox://mapbox.satellite'

    , mapIgnAuth                    		: 'c3VwcG9ydEBjaXR5bWFnaW5lLmNvbTpzdXBwb3J0QGNpdHltYWdpbmUuY29t'
    , mapIgnUrl                     		: 'https://wxs.ign.fr/ortho/geoportail/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&STYLE=normal&FORMAT=image/jpeg'

    , urlAckProcessTask             		: `${urlApi}/citymagine-api-1.0/rest/tasks/ackProcessTask`                      // TaskService
    , urlCloseProcessTask           		: `${urlApi}/citymagine-api-1.0/rest/tasks/closeProcessTask`                    // TaskService
    , urlCreateProcessTask          		: `${urlApi}/citymagine-api-1.0/rest/tasks/createProcessTask`                   // TaskService
    , urlGetProcessTasks            		: `${urlApi}/citymagine-api-1.0/rest/tasks/getProcessTasks`                     // TaskService

    , urlGeoJsonGetModelPoiCollection   	: `${urlApi}/citymagine-api-1.0/rest/geojson/getModelPoiCollection`     		// Modeling Service
    , urlGetModelingCollection          	: `${urlApi}/citymagine-api-1.0/rest/geojson/getModelingCollection`     		// Modeling Service
    , urlGetModelWayCollection          	: `${urlApi}/citymagine-api-1.0/rest/geojson/getModelWayCollection`     		// Modeling Service
    , urlGetModelZoneCollection         	: `${urlApi}/citymagine-api-1.0/rest/geojson/getModelZoneCollection`    		// Modeling Service

    , urlPricingGetUserPricingGrid      	: `${urlApi}/citymagine-api-1.0/rest/pricing/users/`                    		// Pricing Service
    , urlPricingCreatePricingGrid       	: `${urlApi}/citymagine-api-1.0/rest/pricing/createPricingGrid`         		// Pricing Service
    , urlPricingGetPricingGrids         	: `${urlApi}/citymagine-api-1.0/rest/pricing/getPricingGrids`           		// Pricing Service
    , urlPricingUpdatePricingGrid       	: `${urlApi}/citymagine-api-1.0/rest/pricing/updatePricingGrid`         		// Pricing Service
    , urlPricingDeletePricingGrid       	: `${urlApi}/citymagine-api-1.0/rest/pricing/deletePricingGrid`         		// Pricing Service
    , urlPricingGetPricingGridDetails   	: `${urlApi}/citymagine-api-1.0/rest/pricing/getPricingGridDetails`     		// Pricing Service
    , urlPricingCreatePricingGridProduct	: `${urlApi}/citymagine-api-1.0/rest/pricing/createPricingGridProduct`  		// Pricing Service
    , urlPricingUpdatePricingGridProduct	: `${urlApi}/citymagine-api-1.0/rest/pricing/updatePricingGridProduct`  		// Pricing Service
    , urlPricingDeletePricingGridProduct	: `${urlApi}/citymagine-api-1.0/rest/pricing/deletePricingGridProduct`  		// Pricing Service

    , urlFindNearestCapturePoint        	: `${urlApi}/citymagine-api-1.0/rest/viewer/findNearestCapturePoint`    		// Viewer Service
    , urlGetViewCollection              	: `${urlApi}/citymagine-api-1.0/rest/geojson/getViewCollection`         		// Viewer Service
    , urlFindNearestViewCollection      	: `${urlApi}/citymagine-api-1.0/rest/geojson/findNearestViewCollection` 		// Viewer Service
    , urlGetCapturePoint                	: `${urlApi}/citymagine-api-1.0/rest/viewer/getCapturePoint`            		// Viewer Service

    , urlGeoJsonGetObjectViewCollection     : `${urlApi}/citymagine-api-1.0/rest/geojson/getObjectViewCollection`           // GeoJson Service
    , urlGeoJsonGetObjectElementCollection  : `${urlApi}/citymagine-api-1.0/rest/geojson/getObjectElementCollection`        // GeoJson Service
    , urlGeoJsonGetSnapshotCollection       : `${urlApi}/citymagine-api-1.0/rest/geojson/getSnapshotCollection?`            // GeoJson Service
    , urlGetPreviousAndNextViewCollection   : `${urlApi}/citymagine-api-1.0/rest/geojson/getPreviousAndNextViewCollection`  // GeoJson Service
    , urlGetObjectAlertCollection           : `${urlApi}/citymagine-api-1.0/rest/geojson/getObjectAlertCollection`          // GeoJson Service

    , urlGetSession						    : `${urlApi}/citymagine-api-1.0/rest/session/getSession`						// Session Service
    , urlGetDomains						    : `${urlApi}/citymagine-api-1.0/rest/session/getDomains`						// Session Service
    , urlRevokeSession				        : `${urlApi}/citymagine-api-1.0/rest/session/revokeSession`						// Session Service

    , urlGetStatusList				        : `${urlApi}/citymagine-api-1.0/rest/masterdata/getStatus`						// Master Data Service
    , urlMasterdataGetLists				    : `${urlApi}/citymagine-api-1.0/rest/masterdata/getLists`						// Master Data Service
    , urlGetElementClass				    : `${urlApi}/citymagine-api-1.0/rest/masterdata/getElementClass`				// Master Data Service
    , urlElementClass				        : `${urlApi}/citymagine-api-1.0/rest/masterdata/elementClass`				    // Master Data Service
    , urlMasterdataGetConfig				: `${urlApi}/citymagine-api-1.0/rest/masterdata/getConfigurations`				// Master Data Service

    , urlProcessingGetSnapshots				: `${urlApi}/citymagine-api-1.0/rest/processing/getSnapshots`				    // Processing Service
    , urlProcessingUpdateSnapshotStatus		: `${urlApi}/citymagine-api-1.0/rest/processing/updateSnapshotStatus`		    // Processing Service
    , urlGetAnalysisClass				    : `${urlApi}/citymagine-api-1.0/rest/processing/getAnalysisClass`				// Processing Service
    , urlProcessingUpdateAnalysisElement	: `${urlApi}/citymagine-api-1.0/rest/processing/updateAnalysisElement`		    // Processing Service

    , urlAnnotationGetObjectElement		    : `${urlApi}/citymagine-api-1.0/rest/annotation/getObjectElement`				// Annotation Service
    , urlAnnotationUpdateObjectElement		: `${urlApi}/citymagine-api-1.0/rest/annotation/updateObjectElement`		    // Annotation Service
    , urlAnnotationDeleteObjectElement		: `${urlApi}/citymagine-api-1.0/rest/annotation/deleteObjectElement`		    // Annotation Service
    , urlAnnotationCreateObjectElement		: `${urlApi}/citymagine-api-1.0/rest/annotation/createObjectElement`		    // Annotation Service

    , urlVersionGetObjectElementVersions    : `${urlApi}/citymagine-api-1.0/rest/version`						            // Version Service
    , urlVersionGetHasObjectElementVersions	: `${urlApi}/citymagine-api-1.0/rest/version/hasVersions`						// Version Service

    , urlSecurityResetPassword		        : `${urlApi}/citymagine-api-1.0/rest/security/resetPassword`				    // Security Service
    , urlSecurityUpdateUser		            : `${urlApi}/citymagine-api-1.0/rest/security/updateUser`						// Security Service

    , urlCreateObjectAlert		            : `${urlApi}/citymagine-api-1.0/rest/alerting/createObjectAlert`				// Alerting Service
    , urlUpdateObjectAlert		            : `${urlApi}/citymagine-api-1.0/rest/alerting/updateObjectAlert`				// Alerting Service
    , urlGetObjectAlert		                : `${urlApi}/citymagine-api-1.0/rest/alerting/getObjectAlert`				    // Alerting Service

    , urlGetDefaultDomain                   : `${urlApi}/citymagine-api-1.0/rest/user/defaultDomain`
    , urlSetDefaultDomain                   : `${urlApi}/citymagine-api-1.0/rest/user/profile`
    , urlSetCurrentDomain                   : (domain) => `${urlApi}/citymagine-api-1.0/rest/user/domain/${domain}`

    , matomoSiteId
};
