import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Session} from "../../../../../globals/session";
import {ProcessingService} from "../../../../../services/processing.service";
import {GeojsonService} from "../../../../../services/geojson.service";
import {SpinnerService} from "../../../../../shared/spinner/spinner.service";
import {MapService} from "../../../../../shared/map/map.service";
import {ToastService} from "../../../../../shared/toast/toast.service";
import {PanelService} from "../../../../../shared/panel/panel.service";
import {LocationMarkerStoreService} from "../location-marker-store.service";
import {PanelBase} from "../../../../../shared/panel/component/PanelBase";
import {TranslateService} from "@ngx-translate/core";

const SELECTED_WAY_TYPES = "selectedWayTypes";

@Component({
  selector: 'app-location-marker-entry-panel',
  templateUrl: './location-marker-entry-panel.component.html',
  styleUrls: ['./location-marker-entry-panel.component.css']
})
export class LocationMarkerEntryPanelComponent implements OnInit, PanelBase<any> {

  constructor(private session: Session,
              private processingService: ProcessingService,
              private geojsonService: GeojsonService,
              private spinnerService: SpinnerService,
              private mapService: MapService,
              private toastService: ToastService,
              private panelService: PanelService,
              private locationMarkerStore: LocationMarkerStoreService,
              private translate: TranslateService) {
  }

  data: any;

  subscriptions: Subscription[] = [];

  isShowLocationMarker: boolean = false;
  _isVisible: boolean = true;
  isPrimary: boolean = true;

  selectedWayTypes: string[];
  wayTypes: any[] = [];
  wayTypeNames: string[] = ["A", "N", "D", "M", "F"];

  ngOnInit(): void {
    this.subscriptions.push(this.session.onChangeWorkspace.subscribe(geometry => {
      this.refresh();
    }));
    this.locationMarkerStore.useProxy(this);
    this.isShowLocationMarker = true;
    this.wayTypes = [];
    this.wayTypeNames.forEach(wayTypeName => {
      const wayToTranslate: string = "locationMarkerEntry." + wayTypeName;
      this.translate.get(wayToTranslate).subscribe((response: string) => {
        this.wayTypes.push({letter: wayTypeName, name: response})
      });
    });
    this.selectedWayTypes = this.getUserSelectedWayTypes();
    this.isShowLocationMarker = true;
  }

  getUserSelectedWayTypes(): string[] {
    let selectedWayTypes = localStorage.getItem(SELECTED_WAY_TYPES);
    if (!selectedWayTypes || selectedWayTypes.length == 0) {
      selectedWayTypes = this.wayTypeNames[0];
      localStorage.setItem(SELECTED_WAY_TYPES, selectedWayTypes);
    }
    return selectedWayTypes.split('-');
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if (!show) {
      this.panelService.pop();
    }
  }

  updateWayTypes(wayType: string) {
    this.locationMarkerStore.state.isShowLocationMarker = true;
    let selectedWayTypesInStorage = "";
    if (this.selectedWayTypes.includes(wayType)) {
      if(this.selectedWayTypes.length > 1) {
        this.selectedWayTypes = this.selectedWayTypes.filter(type => type != wayType);
        if(this.selectedWayTypes) {
          selectedWayTypesInStorage = this.selectedWayTypes[0];
        }
        this.selectedWayTypes.slice(1).forEach(type => selectedWayTypesInStorage += "-" + type);
      }
    } else {
      this.selectedWayTypes.push(wayType);
      selectedWayTypesInStorage = localStorage.getItem(SELECTED_WAY_TYPES) + '-' + wayType
    }
    localStorage.setItem(SELECTED_WAY_TYPES, selectedWayTypesInStorage);
    this.refresh();
  }

  refresh() {
    this.locationMarkerStore.refresh();
  }

  close(event: Event) {
    this.locationMarkerStore.reset();
    this.show(false)
  }
}
