import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from "../globals/session";
import {ClassCollectionModel} from "../models/processing/class-collection-model";
import {SnapshotModel} from "../models/processing/snapshot-model";
import {StatusEnum} from "../enums/status-enum.enum";
import {SnapshotUpdateModel} from "../models/processing/snapshot-update-model";
import {AnalysisElementUpdateModel} from "../models/processing/analysis-element-update-model";

@Injectable({
  providedIn: 'root'
})
export class ProcessingService {

  constructor(private session: Session, private httpService: HttpClient) {
  }

  getSnapshots( snapshotIdentifier: string): Observable<SnapshotModel> {
    const url = environment.urlProcessingGetSnapshots;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.SNAPSHOT_GEO,
          ResponseLevelEnum.SNAPSHOT_EVENTS,
          ResponseLevelEnum.SNAPSHOT_DETAILS,
          ResponseLevelEnum.STATUS_DETAILS,
        ]
      },
      snapshotIdentifier: snapshotIdentifier
    }, options).pipe(map(data => new SnapshotModel().deserialize(data)),
      catchError(() => throwError('Snapshot not found'))
    );
  }


  updateSnapshotStatus(snapshotIdentifier: string): Observable<SnapshotUpdateModel> {
    const url = environment.urlProcessingUpdateSnapshotStatus;
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      snapshotIdentifier: snapshotIdentifier,
      statusCode: StatusEnum.STATUS_CODE_CAPTURE_REQUESTED,
      statusReasonCode: StatusEnum.STATUS_REASON_CODE_USER_INPUT
    }, options).pipe(map(data => new SnapshotUpdateModel().deserialize(data)),
      catchError(() => throwError('Snapshot not found'))
    );
  }

  getAnalysisClass(datasetType: string, classGroup: string = undefined): Observable<ClassCollectionModel> {
    const url = environment.urlGetAnalysisClass;
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      datasetType: datasetType,
      classGroup: classGroup
    }, options).pipe(map(data => new ClassCollectionModel().deserialize(data)),
      catchError(() => throwError('Element Collection not found'))
    );
  }

  updateAnalysisElement(element): Observable<AnalysisElementUpdateModel> {
    const url = environment.urlProcessingUpdateAnalysisElement;
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      elementType: element.elementType,
      elementIdentifier: element.elementIdentifier,
      newElementClass: element.elementClass,
      newElementCategory: element.elementCategory,
      newElementName: element.elementName,
      newElementQuality: element.elementQuality,
      isRelevant: true,
    }, options).pipe(map(data => new AnalysisElementUpdateModel().deserialize(data)),
      catchError(() => throwError('Cannot update element'))
    );
  }

}
