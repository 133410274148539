import {EventEmitter, Injectable, Output} from '@angular/core';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MasterDataService} from 'src/app/services/master-data.service';
import {ProcessingService} from 'src/app/services/processing.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {SearchState, SearchStoreService} from "./search-store.service";
import {GeometryHelper} from "../../../helpers/geometry.helper";
import {Session} from "../../../globals/session";
import {GeometryModel} from "../../../models/geojson/geometry-model";
import {GeometryTypeEnum} from "../../../enums/geometry-type-enum.enum";

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }

  subscriptions = []

  constructor(
    private session: Session,
    private processingService: ProcessingService,
    private geojsonService: GeojsonService,
    private masterdataService: MasterDataService,
    private mapService:MapService,
    private toastService: ToastService,
    private panelService: PanelService,
    private spinnerService: SpinnerService,
    private searchStore: SearchStoreService) {
      this.searchStore.applyAndWatch(this.showElementWrapper.bind(this))
    }
  errorMessage: any;
  @Output() ErrorMessage: EventEmitter<any> = new EventEmitter();

    showElementWrapper(searchState: SearchState){
      this.mapService.callWhenAvailable((_)=>this.setNewWorkspace(searchState))
    }

    setNewWorkspace(searchState: SearchState) {
      if  (searchState.endingLocationMarker !== undefined && searchState.startingLocationMarker !== undefined
            && !searchState.isDrawnLocationMarker) {
        let locationStartArray = searchState.startingLocationMarker.split('-');
        let locationEndArray = searchState.endingLocationMarker.split('-');
        let county = locationStartArray[0];
        let way = locationStartArray[1].slice(0, 1) + ' ' + locationStartArray[1].slice((locationStartArray[1].length - 1) * - 1);
        let from = Number(locationStartArray[2].slice((locationStartArray[2].length - 2) * - 1));
        let to = Number(locationEndArray[2].slice((locationEndArray[2].length - 2) * - 1));
        searchState.searchText = 'Du PR' + from + ' au PR' + to + ' (' + locationStartArray[0] + '-' + locationStartArray[1] + ')';
        this.searchStore.state = searchState;
        this.geojsonService.getLocationMarkerIds(county, way, from, to).subscribe(response => {
          if (response !== undefined && response.features.length > 0) {
            response.features.sort((a,b) => b.properties['pr_code'] - a.properties['pr_code'])
            response.features.shift();
            let combineSegments = new GeometryModel();
            combineSegments.type = GeometryTypeEnum.MULTILINESTRING;
            combineSegments.coordinates = [];
            let count = 0;
            let bufferedSegments = [];
            response.features.forEach(locationMarker => {
              this.geojsonService.getLocationMarkerSegment(locationMarker.properties['pr_identifier']).subscribe(resp => {
                count++;
                let segment = new GeometryModel();
                segment.type = resp.features[0].geometry.type;
                segment.coordinates = resp.features[0].geometry.coordinates;
                let segmentWidth = 0.020;
                let bufferedSegment = GeometryHelper.bufferFromGeometry(segment, segmentWidth);
                let newGeo = new GeometryModel();
                newGeo.type = bufferedSegment.geometry.type;
                newGeo.coordinates = bufferedSegment.geometry.coordinates;
                bufferedSegments.push(newGeo);

                if (count === response.features.length) {
                  let unionGeo = GeometryHelper.unionFromGeometryPolygon(bufferedSegments);
                  let finalGeo = new GeometryModel();
                  finalGeo.type = unionGeo.geometry.type;
                  finalGeo.coordinates = unionGeo.geometry.coordinates;
                  this.session.setWorkspace((finalGeo));
                  searchState.drawnGeometry = finalGeo;
                  searchState.isDrawnLocationMarker = true;
                  this.searchStore.state = searchState;
                }
              });
            });
          } else {
            this.errorMessage = {};
            this.errorMessage.name = 'Il n\'y a pas de segment de PR de trouvé pour ces valeurs';
            this.ErrorMessage.emit(this.errorMessage);
          }
        });
      }

      // Google Analytics
      if (environment.googleTracking) {
        gtag('event', 'ShowGroundElement');
      }
    }
  }
