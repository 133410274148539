<div *ngIf="loading" class="loaderContainer">
  <p-progressSpinner></p-progressSpinner>
</div>
    <div class="ui-g sticky" style="padding-right: 10px;">
      <div class="ui-g-2 titleButton">
        <i class="fa fa-arrow-left" (click)="show(false)"></i>
      </div>
      <div class="ui-g-7">
        <h1>{{ 'userEntry.title' | translate }}</h1>
      </div>
    </div>
    <ul class="subMenu">
      <li class="listMenu">
        <i class="fa fa-user"></i>
        <span class="ui-g-10">{{currentSession.user.userFirstName}} {{currentSession.user.userLastName}}</span>
      </li>
      <li class="listMenu">
        <i class="fa fa-envelope"></i>
        <span class="ui-g-10">{{currentSession.user.userEmail}}</span>
      </li>
      <li class="listMenu" *ngIf="domainList.length && domainList.length > 1 && !session.embedded">
        <i class="fa fa-map-signs"></i>
        <span  class="ui-g-3">{{ 'userEntry.domain' | translate }}</span>
        <p-dropdown (onChange)="setCurrentDomain($event)" [(ngModel)]="currentDomain" [options]="domainList">
          <ng-template let-zone pTemplate="selectedItem">
            {{currentDomainLabel}}
          </ng-template>
        </p-dropdown>
      </li>
      <li class="listMenu">
        <i class="fa fa-language"></i>
        <span class="ui-g-3">{{ 'userEntry.locale' | translate }}</span>
        <p-dropdown (onChange)="setLocale($event.value)" [(ngModel)]="currentLocale" [options]="localeList">
          <ng-template let-item pTemplate="selectedItem">
            {{item.label | translate}}
          </ng-template>
          <ng-template let-item pTemplate="item">
            {{item.label | translate}}
          </ng-template>
        </p-dropdown>
      </li>
      <li class="listMenu" *ngIf="!session.embedded" (click)="logOut()">
        <i class="fa fa-sign-out"></i>
        <span class="ui-g-10">{{ 'userEntry.disconnection' | translate }}</span>
      </li>
    </ul>
  <p-confirmDialog [style]="{width: '50vw'}"  [transitionOptions]="'200ms'"  key="SignComponent"></p-confirmDialog>
  <p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
