import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from "../globals/session";
import {ConnectionOriginEnum} from "../enums/connection-origin-enum";
import {SessionModel} from "../models/session/session-model";
import {DoneResponseModel} from "../models/done-response-model";
import {DomainCollectionModel} from "../models/session/domain-collection-model";
import {StringUtils} from "../helpers/string.utils";
import {MessageHelper} from "../helpers/message.helper";
import {MessageSeverityEnum} from "../enums/message-severity-enum.enum";
import {MessageService} from "primeng/api";


@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private session: Session, private httpService: HttpClient, private messageService: MessageService) {
  }

  getSession(): Observable<SessionModel> {
    const url = environment.urlGetSession;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_CITYMAP,
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      }
    }, options).pipe(map(data => new SessionModel().deserialize(data['session'])),
      catchError(() => throwError('Connection not possible'))
    );
  }

  revokeSession(sessionIdentifier): Observable<DoneResponseModel> {
    const url = environment.urlRevokeSession;
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_CITYMAP,
        sessionIdentifier: sessionIdentifier
      }
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError(() => throwError('Closing session not possible'))
    );
  }

  getDomains(username: string = null): Observable<DomainCollectionModel> {

    let url = environment.urlGetDomains;

    if (StringUtils.isNotEmpty(username)) {
      url = url + "?username=" + username;
    }

    return this.httpService.get(url).pipe(map(data => {
        return new DomainCollectionModel().deserialize(data);
      }),
      catchError(error => {
        this.messageService.add(MessageHelper.createMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
        return throwError('Domain list not accessible');
      })
    );
  }
}
