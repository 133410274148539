import {IFeatureProperties} from './ifeature-properties';
import {ISerializable} from '../iserializable';
import {ViewBbox} from "./viewBbox.model";
import {ElementTypesEnum} from "../../enums/element-types-enum.enum";
import {ElementClassModel} from "../masterData/element-class-model";
import {GeometryModel} from "./geometry-model";
import {StringUtils} from "../../helpers/string.utils";
import {ProductModel} from "../pricing/product-model";

export class FeatureObjectElementModel implements IFeatureProperties, ISerializable {

  public featureType: string;

  public elementIdentifier: string;
  public elementOrder: number;
  public elementTimestamp: string;

  public elementType: string;
  public elementClass: string;
  public elementCategory: string;

  public elementUrl: string;

  public elementName: string;
  public elementProperties: object = {};
  public elementClassDetail: ElementClassModel;

  public viewIdentifier: string;
  public viewBbox: ViewBbox;

  public snapshotIdentifier: string;
  public snapshotName: string;
  public snapshotDate: string;
  public elementQuality: number;

  public elementPricingProduct: ProductModel;
  public elementPricingValue: number = 0;
  public elementPricingMethod: string;

  public elementCount: number;

  deserialize(input: any): this {
    Object.assign(this, input);

    this.elementClassDetail = new ElementClassModel().deserialize(input.elementClassDetail);

    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }

  toExcel(): any {
    let item: any = {};

    if (this.elementType === ElementTypesEnum.ELEMENT_TYPE_REFLECTO){
      if (this.elementProperties != undefined) {
        item.elementName = this.elementName;
        item.elementClass = this.elementClass;
        item.elementQuality = this.elementQuality;
        item.elementTimestamp = this.elementTimestamp;
        item.elementRL = this.elementProperties["RL"];
        item.elementRLMax = this.elementProperties["RL_Max"];
        item.elementRLMin = this.elementProperties["RL_Min"];
      }
    } else {

      item.elementCategory = this.elementCategory;
      item.elementCount = this.elementCount;
      item.elementName = this.elementName;
      item.elementClass = this.elementClass;
      item.elementQuality = this.elementQuality;
      item.elementTimestamp = this.elementTimestamp;
      item.elementRL = this.elementProperties["RL"];
      item.elementRLMax = this.elementProperties["RL_Max"];
      item.elementRLMin = this.elementProperties["RL_Min"];

      if (this.elementType === ElementTypesEnum.ELEMENT_TYPE_GROUND || this.elementType === ElementTypesEnum.ELEMENT_TYPE_LANE) {
        if (this.elementProperties != undefined
          && this.elementProperties["qualification"] != undefined) {
          item.qualificationAxis = this.elementProperties["qualification"]["axis"];
          item.qualificationColour = this.elementProperties["qualification"]["colour"];
          item.qualificationDirection = this.elementProperties["qualification"]["direction"];
          item.qualificationSize = this.elementProperties["qualification"]["size"];
          item.qualificationPainting = this.elementProperties["qualification"]["painting_type"];
        }

        if (this.elementProperties != undefined
          && this.elementProperties["metrics"] != undefined) {
          item.metricsUnitNumber = this.elementProperties["metrics"]["unit_number"];
          item.metricsLinearLength = this.elementProperties["metrics"]["length"];
          item.metricsPaintedLength = this.elementProperties["metrics"]["painted_length"];
          item.metricsSurface = this.elementProperties["metrics"]["surface"];
          item.metricsPaintedSurface = this.elementProperties["metrics"]["painted_surface"];
        }

        item.elementPricingValue =  this.elementPricingValue;
        item.elementPricingMethod =  this.elementPricingMethod;

        if (this.elementProperties != undefined
          && this.elementProperties["citymapper"] != undefined) {
          item.ciymapperelement = this.elementProperties["citymapper"]["params"]["type"];
          item.ciymapperPr = this.elementProperties["citymapper"]["params"]["position_pr"];
          item.ciymapperLength = this.elementProperties["citymapper"]["params"]["largeur"];
        }

      }

      if (this.elementType === ElementTypesEnum.ELEMENT_TYPE_SIGN) {
        if (this.elementProperties != undefined
          && this.elementProperties["qualification"] != undefined) {

          item.qualificationYearBuild = this.elementProperties["qualification"]["year_build"];
          item.qualificationFilmClass = this.elementProperties["qualification"]["film_class"];
          item.qualificationImplementType = this.elementProperties["qualification"]["implement_type"];
          item.qualificationBuilder = this.elementProperties["qualification"]["builder"];
          item.qualificationAnchor = this.elementProperties["qualification"]["anchor_type"];

        }
      }
    }
    return item;
  }
}
