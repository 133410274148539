import {ISerializable} from "../iserializable";
import {UserModel} from "./user-model";
import {DomainModel} from "./domain-model";

export class SessionModel implements ISerializable {

  public sessionIdentifier: string;
  public user: UserModel;
  public domain: DomainModel;
  public connectionDate: string;
  public expirationDate: string;

  deserialize(input: any): this {
    Object.assign(this, input);

    this.user = new UserModel().deserialize(input.user);

    this.domain = new DomainModel().deserialize(input.domain);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
