import {Injectable} from '@angular/core';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {EventCodeEnum} from 'src/app/enums/event-code-enum.enum';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {WindowEventHelper} from 'src/app/helpers/window-event.helper';
import {FeatureCollectionModel} from 'src/app/models/geojson/feature-collection-model';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {LaneState, LaneStoreService} from './lane-store.service';
import {LaneViewPanelComponent} from './lane-view-panel/lane-view-panel.component';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class LaneService {

  subscriptions = []

  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }

  constructor(private laneStore: LaneStoreService,
              private mapService: MapService,
              private spinnerService: SpinnerService,
              private toastService: ToastService,
              private panelService: PanelService,) {
                this.laneStore.applyAndWatch(this.updateSearchWrapper.bind(this))
  }


  updateSearchWrapper(laneState: LaneState){
    this.mapService.callWhenAvailable(_=>this.updateSearch(laneState));
  }

  updateSearch(laneState: LaneState){
    if (laneState._isShowElement === true) {
      this.spinner.addOperation("MainMenuLane.showElementLane");

      laneState.arrayLaneContinue = [];
      laneState.arrayLaneDotted = [];

      this.map.removeLayer(MapLayerEnum.LAYER_LANE_TEXT + 'Full', MapSourceEnum.SOURCE_ELEMENT);
      this.map.removeLayer(MapLayerEnum.LAYER_LANE_TEXT + 'Dashed', MapSourceEnum.SOURCE_ELEMENT);
      this.map.removeLayer(MapLayerEnum.LAYER_LANE + 'Full', MapSourceEnum.SOURCE_ELEMENT);
      this.map.removeLayer(MapLayerEnum.LAYER_LANE + 'Dashed', MapSourceEnum.SOURCE_ELEMENT);

      let featureCollectionLane: FeatureCollectionModel = new FeatureCollectionModel();

      let layoutLane = {
        'line-cap': 'round',
        'line-join': 'round'
      };

      let paint = {
        'line-color': '#31a2ec',
        'line-width': 2,
        'line-dasharray': [2, 0],
        'line-opacity': 0.8
      };

      let paintDashed = {
        'line-color': '#ec8245',
        'line-width': 2,
        'line-dasharray': [2, 2],
        'line-opacity': 0.8
      };

      let paintTextFull = {
        "text-color": "#ec8245",
        "text-halo-width": 1,
        "text-halo-color": "rgba(255,255,255,0.5)"
      };
      let paintTextDashed = {
        "text-color": "#31a2ec",
        "text-halo-width": 1,
        "text-halo-color": "rgba(255,255,255,0.5)"
      };

      let tileUrl = environment.urlTilesElements;
      tileUrl = tileUrl + '?elementType=' + ElementTypesEnum.ELEMENT_TYPE_LANE;
      tileUrl = tileUrl + '&isPrimary=' + laneState.isPrimary + '&isRelevant=true' + '&elementQualityMin=' + laneState.selectedMinQuality + '&elementQualityMax=' + laneState.selectedHighQuality;
      const classSelected = laneState.classSelected;

      if (this.laneStore.state.selectedNetworks.length > 0) {
        this.laneStore.state.selectedNetworks.forEach((network: string) => {
          if (network.length > 0) {
            tileUrl = tileUrl + '&elementNetworkList=' + network;
          }
        });
      }
      this.laneStore.state.callUrl = tileUrl;
      let tileUrlDashed = tileUrl + '&elementCategory=LANE_DASHED';
      let tileUrlFull = tileUrl + '&elementCategory=LANE_FULL';
      let elementClassList: string[];
      if (Object.keys(classSelected).length > 0) {
        elementClassList = [];
        Object.keys(classSelected).map(k=>classSelected[k]).forEach(element => {
          elementClassList.push(element.elementClass);

          if (element.classCategory === 'LANE_DASHED') {
            tileUrlDashed = tileUrlDashed + '&elementClassList=' + element.elementClass;
            this.laneStore.state.callUrl = tileUrl + '&elementClass=' + element.elementClass;
            this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_LANE + 'Dashed', tileUrlDashed, MapSourceEnum.SOURCE_ELEMENT, MapSourceEnum.SOURCE_VISIBILITY +'Dashed', false, layoutLane, paintDashed);
          } else if (element.classCategory === 'LANE_FULL') {
            tileUrlFull = tileUrlFull + '&elementClassList=' + element.elementClass;
            this.laneStore.state.callUrl = tileUrl + '&elementClass=' + element.elementClass;
            this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_LANE + 'Full', tileUrlFull, MapSourceEnum.SOURCE_ELEMENT, MapSourceEnum.SOURCE_VISIBILITY +'Full', false, layoutLane, paint);
          }
        });

      } else {

        this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_LANE + 'Full', tileUrlFull, MapSourceEnum.SOURCE_ELEMENT, MapSourceEnum.SOURCE_VISIBILITY +'Full', false, layoutLane, paint);
        this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_LANE + 'Dashed', tileUrlDashed, MapSourceEnum.SOURCE_ELEMENT, MapSourceEnum.SOURCE_VISIBILITY +'Dashed', false, layoutLane, paintDashed);

      }

      this.map.map.on("click", MapLayerEnum.LAYER_LANE + 'Full', e => {
          e.preventDefault();
          this.viewElement(e.features[0].properties['element_identifier']);
      });

      this.map.map.on("click", MapLayerEnum.LAYER_LANE + 'Dashed', e => {
        e.preventDefault();
        this.viewElement(e.features[0].properties['element_identifier']);
      });

      this.map.map.on('mousemove', MapLayerEnum.LAYER_LANE + 'Full', e => {
        this.map.addLinesTextTiles(MapLayerEnum.LAYER_LANE_TEXT + 'Full', tileUrlFull, MapSourceEnum.SOURCE_ELEMENT, MapSourceEnum.SOURCE_VISIBILITY +'Full', false, layoutLane, paintTextFull, e.features, MapLayerEnum.LAYER_LANE + 'Full');
      });
      this.map.map.on('mousemove', MapLayerEnum.LAYER_LANE + 'Dashed', e => {
        this.map.addLinesTextTiles(MapLayerEnum.LAYER_LANE_TEXT +'Dashed', tileUrlDashed, MapSourceEnum.SOURCE_ELEMENT, MapSourceEnum.SOURCE_VISIBILITY+'Dashed', false, layoutLane, paintTextDashed, e.features, MapLayerEnum.LAYER_LANE + 'Dashed');
      });

      this._onLayerViewElement(MapLayerEnum.LAYER_LANE + 'Full', MapSourceEnum.SOURCE_VISIBILITY +'Full');
      this._onLayerViewElement(MapLayerEnum.LAYER_LANE + 'Dashed', MapSourceEnum.SOURCE_VISIBILITY +'Dashed');

      this.spinner.removeOperation("MainMenuLane.showElementLane");

    } else {
      this.map.removeLayer(MapLayerEnum.LAYER_LANE_TEXT + 'Full', MapSourceEnum.SOURCE_ELEMENT);
      this.map.removeLayer(MapLayerEnum.LAYER_LANE_TEXT + 'Dashed', MapSourceEnum.SOURCE_ELEMENT);
      this.map.removeLayer(MapLayerEnum.LAYER_LANE + 'Full', MapSourceEnum.SOURCE_ELEMENT);
      this.map.removeLayer(MapLayerEnum.LAYER_LANE + 'Dashed', MapSourceEnum.SOURCE_ELEMENT);

      WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_LANE_LOADED);
    }

    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ShowLaneElement');
    }
  }

  _onLayerViewElement(layer, source) {
    this.map.clickLayerMap(layer);
    this.subscriptions.push(this.map.onClickLayerMap.subscribe(e => {
      let features = this.map.map.queryRenderedFeatures(e.point);
      features.forEach(e => {
        if (e.source === source) {
          this.viewElement(e.properties.elementIdentifier);
        }
      })
    }));
  }

  viewElement(elementIdentifier: string) {
    if (elementIdentifier !== undefined) {
      this.panelService.open(
        LaneViewPanelComponent,
        elementIdentifier
      )
    }

  // Google Analytics
  if (environment.googleTracking) {
    gtag('event', 'ViewLaneElement');
  }
}

}
