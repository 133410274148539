import { Pipe, PipeTransform } from '@angular/core';
import {StringUtils} from "../../helpers/string.utils";

@Pipe({
  name: 'elementAxeGround'
})
export class ElementAxeGroundPipe implements PipeTransform {

  transform(classGroup: string): string {
    let elementGroundAxeTransfrom = '';

    if(classGroup === 'Axe'){
      elementGroundAxeTransfrom = 'Axe central'
    }
    if(classGroup === 'Axe_G'){
      elementGroundAxeTransfrom = 'Axe - gauche'
    }
    if(classGroup === 'Axe_D'){
      elementGroundAxeTransfrom = 'Axe - droite'
    }
    if(classGroup === 'Voie_G'){
      elementGroundAxeTransfrom = 'Voie - gauche'
    }
    if(classGroup === 'Voie_D'){
      elementGroundAxeTransfrom = 'Voie - droite'
    }
    if(classGroup === 'AxeV1V2_G'){
      elementGroundAxeTransfrom = 'Axe voie 1 - gauche'
    }
    if(classGroup === 'AxeV1V2_D'){
      elementGroundAxeTransfrom = 'Axe voie 1 - droite'
    }
    if(classGroup === 'AxeV2V3_G'){
      elementGroundAxeTransfrom = 'Axe voie 2 - gauche'
    }
    if(classGroup === 'AxeV2V3_G'){
      elementGroundAxeTransfrom = 'Axe voie 2 - gauche'
    }
    if(classGroup === 'AxeV3V4_G'){
      elementGroundAxeTransfrom = 'Axe voie 3 - gauche'
    }
    if(classGroup === 'AxeV3V4_D'){
      elementGroundAxeTransfrom = 'Axe voie 3 - droite'
    }
    if(classGroup === 'Hors_Rive'){
      elementGroundAxeTransfrom = 'Hors rive'
    }
    if(classGroup === 'BAU'){
      elementGroundAxeTransfrom = 'Bande d\'arrêt d\'urgence'
    }
    if(classGroup === 'Rive_D'){
      elementGroundAxeTransfrom = 'Rive droite'
    }
    if(classGroup === 'Rive_G'){
      elementGroundAxeTransfrom = 'Rive gauche'
    }
    if(classGroup === 'TPC'){
      elementGroundAxeTransfrom = 'Terre plein central'
    }
    if(classGroup === 'PisteCyclable'){
      elementGroundAxeTransfrom = 'Piste cyclable'
    }
    if(classGroup === 'sensCourant'){
      elementGroundAxeTransfrom = 'Sens courant'
    }
    if(classGroup === 'sensOppose'){
      elementGroundAxeTransfrom = 'Sens contraire'
    }

    if(classGroup === 'Peinture'){
      elementGroundAxeTransfrom = 'Peinture'
    }
    if(classGroup === 'BandeCollee'){
      elementGroundAxeTransfrom = 'Bande collée'
    }
    if(classGroup === 'ResineVNTPvizispot'){
      elementGroundAxeTransfrom = 'Résine VNTP Vizispot'
    }
    if(classGroup === 'ResineThermoplastique'){
      elementGroundAxeTransfrom = 'Résine préfabriquée'
    }
    if(classGroup === 'ResineAfroid'){
      elementGroundAxeTransfrom = 'Résine à froid'
    }
    if(classGroup === 'ResinePrefabriquee'){
      elementGroundAxeTransfrom = 'Résine à chaud'
    }

    if (StringUtils.isEmpty(elementGroundAxeTransfrom)) {
      elementGroundAxeTransfrom = classGroup;
    }
    return elementGroundAxeTransfrom;
  }
}
