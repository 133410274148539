import { BrowserModule	                                    } from '@angular/platform-browser';
import { RouterModule	                                    } from '@angular/router';
import { NgModule	                                        } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule	} from '@angular/common/http';
import { BrowserAnimationsModule	                        } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule	                } from '@angular/forms';

import { Session	                                        } from "./globals/session";

import { AppComponent	                                    } from './app.component';
import { LoginComponent	                                    } from './components/login/login.component';
import { MapComponent	                                    } from './components/map/map.component';
import { SearchComponent	                                } from './components/main/search/search.component';
import { ViewerComponent	                                } from './components/main/viewer/viewer.component';

import { GeojsonService	                                    } from "./services/geojson.service";
import { ModelingService	                                } from "./services/modeling.service";

import { AutoCompleteModule								    } from 'primeng/autocomplete';
import { SidebarModule										} from 'primeng/sidebar';
import { ButtonModule										} from 'primeng/button';
import { CheckboxModule										} from 'primeng/checkbox';
import { DragDropModule										} from 'primeng/dragdrop';
import { TableModule										} from 'primeng/table';
import { DialogModule										} from 'primeng/dialog';
import { ChipsModule										} from 'primeng/chips';
import { AccordionModule									} from 'primeng/accordion';
import { TooltipModule										} from 'primeng/tooltip';
import { SliderModule										} from 'primeng/slider';
import { OrderListModule									} from 'primeng/orderlist';
import { DataViewModule										} from 'primeng/dataview';
import { PanelModule										} from 'primeng/panel';
import { SelectButtonModule									} from 'primeng/selectbutton';
import { DropdownModule										} from 'primeng/dropdown';
import { RadioButtonModule									} from 'primeng/radiobutton';
import { ProgressBarModule									} from 'primeng/progressbar';
import { InputSwitchModule									} from 'primeng/inputswitch';
import { ConfirmDialogModule								} from 'primeng/confirmdialog';
import { MultiSelectModule									} from 'primeng/multiselect';
import { GalleriaModule										} from 'primeng/galleria';
import { ConfirmationService, MessageService				} from 'primeng/api';
import { ToastModule										} from "primeng/toast";
import { CalendarModule										} from "primeng/calendar";
import { SpinnerModule										} from 'primeng/spinner';
import { FileUploadModule								    } from 'primeng/fileupload';

import { Ng5SliderModule									} from 'ng5-slider';

import { ToastsComponent									} from './components/main/toasts/toasts.component';

import { SpinnerComponent									} from './components/main/spinner/spinner.component';
import { DashToDowndashPipe									} from "./globals/pipes/dashToDowndashPipe";
import { ElementQualityPipe									} from "./globals/pipes/element-quality.pipe";
import { FilterByFieldValuePipe								} from "./globals/pipes/filter-by-field-value.pipe";
import { ElementClassSelectorComponent						} from "./components/selectors/element-class-selector/element-class-selector.component";

import { ElementNameGroundPipe								} from './globals/pipes/element-name-ground.pipe';
import { ElementGroupGroundPipe								} from './globals/pipes/element-group-ground.pipe';
import { ElementAxeGroundPipe								} from './globals/pipes/element-axe-ground.pipe';

import { SpiderifyComponent									} from './components/main/spiderify/spiderify.component';
import { SpiderifyHelper									} from "./helpers/spiderify.helper";
import { ElementAlertPipe									} from "./globals/pipes/element-alert.pipe";
import { QualitySelectorComponent							} from "./components/selectors/quality-selector/quality-selector.component";
import { ElementGroupLanePipe								} from "./globals/pipes/element-group-lane.pipe";
import { ComputeLaneHelper									} from "./helpers/compute-lane.helper";
import { ViewVersionComponent								} from './components/menu/sharedViews/view-version/view-version.component';
import { ElementFilePathToClassPipe							} from './globals/pipes/element-file-path-to-class.pipe';
import { CommonModule										} from '@angular/common';
import { ElementClassNamePipe								} from './globals/pipes/element-class-name.pipe';
import { DatasourceService									} from "./services/datasource.service";
import { FeatureHelper										} from "./helpers/feature-helper";
import { ComputeVisibilityHelper							} from "./helpers/compute-visibility.helper";
import { SharedModule										} from './shared/shared.module';
import { MainPanelComponent									} from './components/menu/main-panel/main-panel.component';
import { BackgroundEntryComponent							} from './components/menu/main-panel/background-entry/background-entry.component';
import { CustomBackgroundEntryComponent                     } from './components/menu/main-panel/custom-background-entry/custom-background-entry.component';
import { StripEntryComponent								} from './components/menu/main-panel/strip-entry/strip-entry.component';
import { BackgroundDetailsEntryComponent                    } from './components/menu/main-panel/background-details-entry/background-details-entry.component';
import { ViewerEntryComponent								} from './components/menu/main-panel/viewer-entry/viewer-entry.component';
import { JourneyEntryComponent								} from './components/menu/main-panel/journey-entry/journey-entry.component';
import { SignEntryComponent									} from './components/menu/main-panel/sign-entry/sign-entry.component';
import { SignEntryPanelComponent							} from './components/menu/main-panel/sign-entry/sign-entry-panel/sign-entry-panel.component';
import { SignViewPanelComponent								} from './components/menu/main-panel/sign-entry/sign-view-panel/sign-view-panel.component';
import { GroundEntryComponent								} from './components/menu/main-panel/ground-entry/ground-entry.component';
import { GroundEntryPanelComponent  	                    } from './components/menu/main-panel/ground-entry/ground-entry-panel/ground-entry-panel.component';
import { GroundViewPanelComponent       	                } from './components/menu/main-panel/ground-entry/ground-view-panel/ground-view-panel.component';
import { LaneEntryComponent									} from './components/menu/main-panel/lane-entry/lane-entry.component';
import { LaneEntryPanelComponent							} from './components/menu/main-panel/lane-entry/lane-entry-panel/lane-entry-panel.component';
import { LaneViewPanelComponent								} from './components/menu/main-panel/lane-entry/lane-view-panel/lane-view-panel.component';
import { RetroreflexionEntryComponent	                    } from './components/menu/main-panel/retroreflexion-entry/retroreflexion-entry.component';
import { RetroreflexionEntryPanelComponent      	        } from './components/menu/main-panel/retroreflexion-entry/retroreflexion-entry-panel/retroreflexion-entry-panel.component';
import { FurnitureEntryComponent							} from './components/menu/main-panel/furniture-entry/furniture-entry.component';
import { FurnitureViewPanelComponent                        } from './components/menu/main-panel/furniture-entry/furniture-view-panel/furniture-view-panel.component';
import { FurnitureEntryPanelComponent	                    } from './components/menu/main-panel/furniture-entry/furniture-entry-panel/furniture-entry-panel.component';
import { BarrierEntryComponent								} from './components/menu/main-panel/barrier-entry/barrier-entry.component';
import { BarrierEntryPanelComponent     	                } from './components/menu/main-panel/barrier-entry/barrier-entry-panel/barrier-entry-panel.component';
import { BarrierViewPanelComponent	                        } from './components/menu/main-panel/barrier-entry/barrier-view-panel/barrier-view-panel.component';
import { HeliosEntryComponent								} from './components/menu/main-panel/helios-entry/helios-entry.component';
import { HeliosEntryPanelComponent                          } from './components/menu/main-panel/helios-entry/helios-entry-panel/helios-entry-panel.component';
import { HeliosViewPanelComponent                           } from './components/menu/main-panel/helios-entry/helios-view-panel/helios-view-panel.component';
import { AlertEntryComponent								} from './components/menu/main-panel/alert-entry/alert-entry.component';
import { AlertEntryPanelComponent                           } from './components/menu/main-panel/alert-entry/alert-entry-panel/alert-entry-panel.component';
import { AlertViewPanelComponent							} from './components/menu/main-panel/alert-entry/alert-view-panel/alert-view-panel.component';
import { StreetlightEntryComponent							} from './components/menu/main-panel/streetlight-entry/streetlight-entry.component';
import { StreetlightEntryPanelComponent     	            } from './components/menu/main-panel/streetlight-entry/streetlight-entry-panel/streetlight-entry-panel.component';
import { StreetlightViewPanelComponent                      } from './components/menu/main-panel/streetlight-entry/streetlight-view-panel/streetlight-view-panel.component';
import { ConformityEntryComponent							} from './components/menu/main-panel/conformity-entry/conformity-entry.component';
import { ConformityEntryPanelComponent                      } from './components/menu/main-panel/conformity-entry/conformity-entry-panel/conformity-entry-panel.component';
import { VisibilityEntryComponent							} from './components/menu/main-panel/visibility-entry/visibility-entry.component';
import { VisibilityEntryPanelComponent                      } from './components/menu/main-panel/visibility-entry/visibility-entry-panel/visibility-entry-panel.component';
import { VisibilityOnDemandEntryComponent                   } from './components/menu/main-panel/visibility-on-demand-entry/visibility-on-demand-entry.component';
import { VisibilityOnDemandEntryPanelComponent          	} from './components/menu/main-panel/visibility-on-demand-entry/visibility-on-demand-entry-panel/visibility-on-demand-entry-panel.component';
import { LocationMarkerEntryComponent                       } from './components/menu/main-panel/location-marker-entry/location-marker-entry.component';
import { UserEntryComponent									} from './components/menu/main-panel/user-entry/user-entry.component';
import { UserEntryPanelComponent							} from './components/menu/main-panel/user-entry/user-entry-panel/user-entry-panel.component';
import { WorkspaceRequiredDirective							} from './globals/workspace-required.directive';
import { ShortcutsComponent									} from './components/menu/shortcuts/shortcuts.component';
import { ShortcutComponent									} from './components/menu/shortcuts/shortcut/shortcut.component';
import { RoughnessEntryComponent							} from './components/menu/main-panel/roughness-entry/roughness-entry.component';
import { RoughnessEntryPanelComponent                       } from './components/menu/main-panel/roughness-entry/roughness-entry-panel/roughness-entry-panel.component';
import { GoogleStreetViewComponent							} from './components/main/google-street-view/google-street-view.component';
import { InputNumberModule, ProgressSpinnerModule, TabViewModule, TreeModule, TreeTableModule } from "primeng";
import { SplitButtonModule								    } from 'primeng/splitbutton';
import { GoogleMapsModule									} from "@angular/google-maps";
import { RiverSignEntryComponent							} from './components/menu/main-panel/river-sign-entry/river-sign-entry.component';
import { BankEntryComponent									} from './components/menu/main-panel/bank-entry/bank-entry.component';
import { RiverSignViewPanelComponent                        } from './components/menu/main-panel/river-sign-entry/river-sign-view-panel/river-sign-view-panel.component';
import { BankViewPanelComponent								} from './components/menu/main-panel/bank-entry/bank-view-panel/bank-view-panel.component';
import { BankEntryPanelComponent							} from './components/menu/main-panel/bank-entry/bank-entry-panel/bank-entry-panel.component';
import { RiverSignEntryPanelComponent                       } from './components/menu/main-panel/river-sign-entry/river-sign-entry-panel/river-sign-entry-panel.component';
import { environment										} from 'src/environments/environment';
import { AuthModule										    } from '@auth0/auth0-angular';
import { HttpCredentialsRequestInterceptor					} from './globals/interceptors/HttpCredentialsRequestInterceptor';
import { TruncatePipe										} from './globals/pipes/Truncate.pipe';
import { SearchEntryPanelComponent							} from './components/main/search/search-entry-panel/search-entry-panel.component';
import { StatsComponent										} from './components/stats/stats.component';
import { ListElementComponent								} from "./components/stats/list-element/list-element.component";
import { TranslateLoader, TranslateModule					} from '@ngx-translate/core';
import { TranslateHttpLoader								} from '@ngx-translate/http-loader';
import { CropImageComponent									} from './components/menu/sharedViews/crop-image/crop-image.component';
import { LogoComponent										} from './components/menu/logo/logo.component';
import { MomentPipe										    } from "./globals/pipes/moment.pipe";
import { LocationMarkerEntryPanelComponent					} from "./components/menu/main-panel/location-marker-entry/location-marker-entry-panel/location-marker-entry-panel.component";

import { RoadFsiEntryComponent								} from './components/menu/main-panel/road-fsi-entry/road-fsi-entry.component';
import { RoadFsiPanelComponent 								} from './components/menu/main-panel/road-fsi-entry/road-fsi-entry-panel/road-fsi-entry-panel.component';
import { RoadFsiViewPanelComponent 							} from './components/menu/main-panel/road-fsi-entry/road-fsi-view-panel/road-fsi-view-panel.component';
import { RoadRiskEntryComponent							    } from "./components/menu/main-panel/road-risk-entry/road-risk-entry.component";
import { RoadRiskEntryPanelComponent	                    } from "./components/menu/main-panel/road-risk-entry/road-risk-entry-panel/road-risk-entry-panel.component";
import { RoadFlowEntryComponent 							} from './components/menu/main-panel/road-flow-entry/road-flow-entry.component';
import { RoadFlowEntryPanelComponent                        } from "./components/menu/main-panel/road-flow-entry/road-flow-entry-panel/road-flow-entry-panel.component";

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        ViewerComponent,
        MapComponent,
        SearchComponent,
        SpinnerComponent,
        DashToDowndashPipe,
        FilterByFieldValuePipe,
        ElementQualityPipe,
        MomentPipe,
        ElementClassSelectorComponent,
        QualitySelectorComponent,
        LoginComponent,
        LoginComponent,
        LoginComponent,
        ToastsComponent,
        ElementNameGroundPipe,
        ElementGroupGroundPipe,
        ElementGroupLanePipe,
        ElementAxeGroundPipe,
        ElementAlertPipe,
        SpiderifyComponent,
        ViewVersionComponent,
        ElementFilePathToClassPipe,
        ElementClassNamePipe,
        MainPanelComponent,
        BackgroundEntryComponent,
        CustomBackgroundEntryComponent,
        StripEntryComponent,
        BackgroundDetailsEntryComponent,
        ViewerEntryComponent,
        JourneyEntryComponent,
        SignEntryComponent,
        SignEntryPanelComponent,
        SignViewPanelComponent,
        GroundEntryComponent,
        GroundEntryPanelComponent,
        GroundViewPanelComponent,
        LaneEntryComponent,
        LaneEntryPanelComponent,
        LaneViewPanelComponent,
        RetroreflexionEntryComponent,
        RetroreflexionEntryPanelComponent,
        FurnitureEntryComponent,
        FurnitureViewPanelComponent,
        FurnitureEntryPanelComponent,
        BarrierEntryComponent,
        BarrierEntryPanelComponent,
        BarrierViewPanelComponent,
        HeliosEntryComponent,
        HeliosEntryPanelComponent,
        HeliosViewPanelComponent,
        AlertEntryComponent,
        AlertEntryPanelComponent,
        AlertViewPanelComponent,
        StreetlightEntryComponent,
        StreetlightEntryPanelComponent,
        StreetlightViewPanelComponent,
        ConformityEntryComponent,
        ConformityEntryPanelComponent,
        VisibilityEntryComponent,
        VisibilityEntryPanelComponent,
        VisibilityOnDemandEntryComponent,
        VisibilityOnDemandEntryPanelComponent,
        RoadFsiEntryComponent,
        RoadFsiPanelComponent,
        RoadFsiViewPanelComponent,
        LocationMarkerEntryComponent,
        UserEntryComponent,
        UserEntryPanelComponent,
        WorkspaceRequiredDirective,
        ShortcutsComponent,
        ShortcutComponent,
        RoughnessEntryComponent,
        RoughnessEntryPanelComponent,
        GoogleStreetViewComponent,
        RiverSignEntryComponent,
        RiverSignEntryPanelComponent,
        RiverSignViewPanelComponent,
        BankEntryComponent,
        BankViewPanelComponent,
        BankEntryPanelComponent,
        TruncatePipe,
        SearchEntryPanelComponent,
        StatsComponent,
        ListElementComponent,
        CropImageComponent,
        LogoComponent,
        RoadRiskEntryComponent,
        RoadRiskEntryPanelComponent,
        LocationMarkerEntryPanelComponent,
        RoadFlowEntryComponent,
        RoadFlowEntryPanelComponent
    ],
    imports: [
        ProgressSpinnerModule,
        CommonModule,
        AccordionModule,
        AutoCompleteModule,
        ButtonModule,
        BrowserModule,
        BrowserAnimationsModule,
        CheckboxModule,
        ChipsModule,
        DialogModule,
        DragDropModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        SidebarModule,
        TableModule,
        TooltipModule,
        SliderModule,
        Ng5SliderModule,
        OrderListModule,
        DataViewModule,
        PanelModule,
        SelectButtonModule,
        DropdownModule,
        RadioButtonModule,
        ProgressBarModule,
        ToastModule,
        ConfirmDialogModule,
        GalleriaModule,
        MultiSelectModule,
        CalendarModule,
        SpinnerModule,
        InputSwitchModule,
        FileUploadModule,
        SharedModule,
        TabViewModule,
        GoogleMapsModule,
        SplitButtonModule,
        AuthModule.forRoot({
            domain: environment.auth.domain,
            clientId: environment.auth.clientId,
            useRefreshTokens: true,
            redirectUri: window.location.href,
            cacheLocation: 'localstorage',
            audience: environment.auth.audience,
            httpInterceptor: {
                allowedList: [
                    {
                        uri: environment.auth.urlApi,
                        tokenOptions: {
                            audience: environment.auth.audience,
                        }
                    }
                ]
            }
        }),
        TreeTableModule,
        RouterModule.forRoot([]),
        TranslateModule.forRoot({
            defaultLanguage: "en",
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        TreeModule,
        InputNumberModule
    ],
    providers: [TruncatePipe, ElementQualityPipe, ElementAxeGroundPipe, ElementGroupGroundPipe, ElementNameGroundPipe, ElementAlertPipe, Session, GeojsonService, DatasourceService, ModelingService, MessageService, ConfirmationService, SpiderifyHelper, ComputeLaneHelper, ComputeVisibilityHelper, FeatureHelper,
        {
            provide: HTTP_INTERCEPTORS, useClass: HttpCredentialsRequestInterceptor, multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
