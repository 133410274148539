import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { ElementTypesEnum } from 'src/app/enums/element-types-enum.enum';
import { MapLayerEnum } from 'src/app/enums/map-layer-enum.enum';
import { MapSourceEnum } from 'src/app/enums/map-source-enum.enum';
import { Session } from 'src/app/globals/session';
import { FeatureModel } from 'src/app/models/geojson/feature-model';
import { GeojsonService } from 'src/app/services/geojson.service';
import { MapService } from 'src/app/shared/map/map.service';
import { PanelBase } from 'src/app/shared/panel/component/PanelBase';
import { PanelService } from 'src/app/shared/panel/panel.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { StreetlightStoreService } from '../streetlight-store.service';
import { StreetlightViewPanelComponent } from '../streetlight-view-panel/streetlight-view-panel.component';
import { StreetlightService } from '../streetlight.service';



@Component({
  selector: 'app-streetlight-entry-panel',
  templateUrl: './streetlight-entry-panel.component.html',
  styleUrls: ['./streetlight-entry-panel.component.css']
})
export class StreetlightEntryPanelComponent implements OnInit, PanelBase<any> {

  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }


  constructor(public session: Session, private httpService: HttpClient, private geojsonService: GeojsonService,
              private appComponent: AppComponent,
              private spinnerService: SpinnerService,
              private mapService:MapService,
              private toastService: ToastService,
              private panelService: PanelService,
              private streetlightStore: StreetlightStoreService) { }
  data: any;

  selectedNetworks: string[] = [];


  environment = environment;
  subscriptions: Subscription[] = [];

  isConfirmed : boolean = true;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  selectedElement : FeatureModel;

  ngOnInit() {
    this.streetlightStore.useProxy(this);
    this.subscriptions.push(this.session.onChangeWorkspace.subscribe( geometry => {
      this.refresh();
    }));
    this._isShowElement = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s=>s.unsubscribe())
  }

  clear() {
    // Reset filters
    this.streetlightStore.reset();
    this.show(false)
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if(!show){
      this.panelService.pop();
    }
  }
  refresh() {
    this.streetlightStore.refresh()
  }


}
