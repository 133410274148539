import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name:
    'dashToDowndash'
})

export class DashToDowndashPipe implements PipeTransform {

  transform(elementName: string): string {
    if (elementName.includes('-')) {
      elementName = elementName.replace('-', '_');
    } else {
      elementName = elementName;
    }
    return elementName
  }


}
