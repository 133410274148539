import { Injectable, Component, OnInit, ElementRef } from "@angular/core"                   ;
import { TranslateService       }   from "@ngx-translate/core"                              ;
import { Subscription           }   from "rxjs"                                             ;
import   Matomo                     from "../../../../../stats"                             ;
import { PanelBase              }   from "../../../../../shared/panel/component/PanelBase"  ;
import { Session                }   from "../../../../../globals/session"                   ;
import { ProcessingService      }   from "../../../../../services/processing.service"       ;
import { GeojsonService         }   from "../../../../../services/geojson.service"          ;
import { SpinnerService         }   from "../../../../../shared/spinner/spinner.service"    ;
import { MapService             }   from "../../../../../shared/map/map.service"            ;
import { ToastService           }   from "../../../../../shared/toast/toast.service"        ;
import { PanelService           }   from "../../../../../shared/panel/panel.service"        ;
import { RoadRiskStoreService   }   from "../road-risk-store.service"                      ;
import { RoadRiskService        }   from "../road-risk.service"                            ;

interface RoadType      { name : string, code: string }
interface DataVersion   { name : string, code: string }
interface Timings       { 't0': number, 't1': number, 'delta_t': number }
interface Cutoffs       { '0': number, '1': number, '2': number, '3': number, '4': number, '5': number}
interface Analytics     {
      n_quantiles       : any, f_quantiles     : any
    , n_km_quantiles    : any, f_km_quantiles  : any 
    , n_roadTypes       : any, f_roadTypes     : any
    , n_km_roadTypes    : any, f_km_roadTypes  : any 
}

@Component({
      selector          : "app-road-risk-entry-panel"
    , templateUrl       : "./road-risk-entry-panel.component.html"
    , styleUrls         : ["./road-risk-entry-panel.component.css"]
})

@Injectable({ providedIn: "root" })

export class RoadRiskEntryPanelComponent implements OnInit, PanelBase<any> {
    /*
     * COMPONENT / PROPERTIES _______________________________
     */
    data                        : any                   ;

    subscriptions               : Subscription[] = []   ;

    _isShowElement              : boolean   = false     ;
    _isVisible                  : boolean   = true      ;
    isPrimary                   : boolean   = true      ;

    roadUser                    : any[]     = []        ;
    quantiles                   : string[]  = []        ;
    roadTypes                   : string[]  = []        ;

    roadTypeOptions             : RoadType[]            ;
    selectDataVersionOptions    : DataVersion[]         ;
    timings                     : Timings               ;
    analytics                   : Analytics             ;
    nCutoffDecimals             : number                ;
    selectedRoadTypes           : string[]              ;
    selectedRoadUser            : string    = "SRS_AUTO";
    selectedQuantiles           : string[]  = ['1', '2', '3', '4', '5'];
    selectedDataVersion         : string    = "VERSION_20230710_v3"  ;
    selectedCutoffs             : Cutoffs               ;
    dimensions                  : string[]  = []        ;

    root;

    /*
     * COMPONENT / HELPERS _____________________________________
     */
    get spinner()   { return this.spinnerService.getSpinner();  }
    get map()       { return this.mapService.getMap();          }
    get toast()     { return this.toastService.getToast();      }

    show(show: boolean = true) {
        this._isVisible = show;
        if (!show) {
            this.panelService.pop();
        }
    }

    refresh() {
        this.roadRiskStoreService.refresh();
    }

    close(event: Event) {
        this.roadRiskStoreService.reset();
        this.show(false);
    }

    isActiveRoadType(roadTypeCode){ 
        return this.selectedRoadTypes.includes(roadTypeCode) || this.selectedRoadTypes.length == 0 
    }

    isActiveQuantile(x){ 
        return this.selectedQuantiles.includes(x) || this.selectedQuantiles.length == 0 
    }

    updateAnalytics(){
        let that            = this                                  ;
        let map         :any= this.mapService.getMap().map          ;
        let data        :any= map.querySourceFeatures('risk_geoFull', {sourceLayer: 'risk_geo'});
        this.resetAnalytics()
        let cutoffs     :any= this.roadRiskStoreService.state.selectedCutoffs;
        for(let i = 0 ; i < data.length ; i++){
            let p: any      = data[i].properties    ;
            let r: string   = p.road_type           ;
            let s: number   = p.sc                  ;
            let km: number  = p.length_in_m / 1000  ;
            if(this.roadTypes.includes(r)){ 
                this.analytics.n_roadTypes[   `${r}`] += 1
                this.analytics.n_km_roadTypes[`${r}`] += km
            }
                   if(s > cutoffs['1']){ this.analytics.n_quantiles['1'] += 1; this.analytics.n_km_quantiles['1'] += km
            } else if(s > cutoffs['2']){ this.analytics.n_quantiles['2'] += 1; this.analytics.n_km_quantiles['2'] += km
            } else if(s > cutoffs['3']){ this.analytics.n_quantiles['3'] += 1; this.analytics.n_km_quantiles['3'] += km
            } else if(s > cutoffs['4']){ this.analytics.n_quantiles['4'] += 1; this.analytics.n_km_quantiles['4'] += km
            } else                     { this.analytics.n_quantiles['5'] += 1; this.analytics.n_km_quantiles['5'] += km
            }
        }
        // totals ...
        this.analytics.n_quantiles.total   = this.quantiles.reduce((total, x) => total + that.analytics.n_quantiles[   `${x}`], 0);
        this.analytics.n_km_quantiles.total= this.quantiles.reduce((total, x) => total + that.analytics.n_km_quantiles[`${x}`], 0);
        this.analytics.n_roadTypes.total   = this.roadTypes.reduce((total, x) => total + that.analytics.n_roadTypes[   `${x}`], 0);
        this.analytics.n_km_roadTypes.total= this.roadTypes.reduce((total, x) => total + that.analytics.n_km_roadTypes[`${x}`], 0);
        // frequencies ...
        this.quantiles.forEach(x => that.analytics.f_quantiles[   `${x}`] = that.analytics.n_quantiles[   `${x}`] / that.analytics.n_quantiles.total     );
        this.quantiles.forEach(x => that.analytics.f_km_quantiles[`${x}`] = that.analytics.n_km_quantiles[`${x}`] / that.analytics.n_km_quantiles.total  );
        this.roadTypes.forEach(x => that.analytics.f_roadTypes[   `${x}`] = that.analytics.n_roadTypes[   `${x}`] / that.analytics.n_roadTypes.total     );
        this.roadTypes.forEach(x => that.analytics.f_km_roadTypes[`${x}`] = that.analytics.n_km_roadTypes[`${x}`] / that.analytics.n_km_roadTypes.total  );
        // (checksum) make sure total is 100%, if not, it must be revealed: 
        ["quantiles", "roadTypes"].forEach(dimension => {
            this.analytics[`f_${dimension}`   ].total   = this[dimension].reduce((total, x) => total + that.analytics[`f_${dimension}`][`${x}`], 0);
            this.analytics[`f_km_${dimension}`].total   = this[dimension].reduce((total, x) => total + that.analytics[`f_km_${dimension}`][`${x}`], 0);
        })
    }

    resetAnalytics(){
        let that        = this;
        let dimensions: string[] = ["quantiles", "roadTypes"];
        this.analytics  = { 
              n_quantiles      : {}, f_quantiles     : {}
            , n_km_quantiles   : {}, f_km_quantiles  : {} 
            , n_roadTypes      : {}, f_roadTypes     : {}
            , n_km_roadTypes   : {}, f_km_roadTypes  : {} 
        }
        dimensions.forEach(dimension => {
            that[dimension].forEach(value => {
                that.analytics[`n_${dimension}`     ][`${value}`] = 0
                that.analytics[`n_km_${dimension}`  ][`${value}`] = 0
                that.analytics[`f_${dimension}`     ][`${value}`] = 0
                that.analytics[`f_km_${dimension}`  ][`${value}`] = 0
                that.analytics[`n_${dimension}`].total = 0
                that.analytics[`f_${dimension}`].total = 0
                that.analytics[`n_km_${dimension}`].total = 0
                that.analytics[`f_km_${dimension}`].total = 0
            })
        })
    }

    /*
     * COMPONENT / CONSTRUCTOR _________________________________
     */
    constructor(
          private session               : Session
        , private processingService     : ProcessingService
        , private geojsonService        : GeojsonService
        , private spinnerService        : SpinnerService
        , private mapService            : MapService
        , private toastService          : ToastService
        , private panelService          : PanelService
        , private translate             : TranslateService
        , private roadRiskStoreService  : RoadRiskStoreService
        , private roadRiskService       : RoadRiskService
    ) {
        this.roadTypeOptions = [
              {code: 'highways'         , name: 'Highways' }
            , {code: 'primary'          , name: 'Primary' }
            , {code: 'secondary'        , name: 'Secondary' }
            , {code: 'tertiary'         , name: 'Tertiary' }
            , {code: 'residential'      , name: 'Residential' }
            , {code: 'unclassified'     , name: 'Unclassified' }
        ];
        this.selectDataVersionOptions = [
              {code: 'VERSION_20230710_v3', name: 'Version 2023-07-10 (Score v3)'}
            , {code: 'VERSION_20230120_v2', name: 'Version 2023-01-20 (Score v2)'}
            , {code: 'VERSION_20140614_v2', name: 'Version 2014-06-14 (Score v2)'}
            , {code: 'VERSION_20130620_v2', name: 'Version 2013-06-20 (Score v2)'}
        ];
        let that    :any    = this
        let map     :any    = this.mapService.getMap().map
        this.roadRiskStoreService = roadRiskStoreService;
        this.roadRiskService   = roadRiskService  ;
        this.spinnerService     = spinnerService    ;
        map.on("sourcedata", function(e) {
            let isComponentData: boolean = true     &&
                map.getSource('risk_geoFull')       &&
                map.isSourceLoaded('risk_geoFull')  ;
            if (isComponentData)  that.updateAnalytics();
        });
        /*
         * Timings and spinner  ...
         */
        map.on("sourcedataloading", function(e) { /* Start event */
            that.timings.t0         = new Date()
            spinnerService.getSpinner().addOperation("MainMenuComponent.showRoadFlowData");
            setTimeout(function(){
                spinnerService.getSpinner().removeOperation("MainMenuComponent.showRoadFlowData");
            }, 60000);
        })
        map.on("idle", function(e){ /* End event*/
            that.timings.t1         = new Date()
            that.timings.delta_t    = that.timings.t1 - that.timings.t0
            spinnerService.getSpinner().removeOperation("MainMenuComponent.showRoadFlowData");
        })
    }

    ngOnInit() {
        this.resetAnalytics();
        this.timings = {'t0': null, 't1': null, 'delta_t': null};
        this.subscriptions.push(this.session.onChangeWorkspace.subscribe(geometry => {
            this.refresh();
        }));
        this.roadRiskStoreService.useProxy(this);
        for(let i = 0 ; i < this.roadTypeOptions.length ; i++){
            this.selectedRoadTypes.push(this.roadTypeOptions[i].code);
        }
        this.quantiles          = [...this.roadRiskStoreService.state.quantiles];
        this.selectedQuantiles  = [...this.roadRiskStoreService.state.quantiles];
        this.setCutoffs_using_dataVersionAndRoadUser()
        if(!this._isShowElement) {
            this.translate.get('common.auto'    ).subscribe((response: string) => { this.roadUser.push({name: response, class: 'SRS_AUTO'}) })
            this.translate.get('common.moto'    ).subscribe((response: string) => { this.roadUser.push({name: response, class: 'SRS_MOTO'}) })
            this.translate.get('common.bicycle' ).subscribe((response: string) => { this.roadUser.push({name: response, class: 'SRS_BICY'}) })
            this.translate.get('common.peds'    ).subscribe((response: string) => { this.roadUser.push({name: response, class: 'SRS_PEDS'}) })
            this._isShowElement = true;
        }
    }

    /*
     * COMPONENT / HANDLERS ___________________________________________________
     */
    setCutoffs_using_dataVersionAndRoadUser(){
        let allCutoffs: any     = this.roadRiskStoreService.state.cutoffs
        let thisCutoffs: any    = (allCutoffs[this.selectedDataVersion] || allCutoffs["default"])[this.selectedRoadUser]
        Object.keys(thisCutoffs).forEach(k => {  this.selectedCutoffs[k] = thisCutoffs[k] })
        this.nCutoffDecimals = this.selectedDataVersion.match(/v2/) ? 0 : 7
        this.roadRiskService.updateSearch(this.roadRiskStoreService.state)
    }

    toggleQuantiles(){
        if(this.selectedQuantiles.length){
            this.selectedQuantiles = [];
        } else {
            this.selectedQuantiles = ['1', '2', '3', '4', '5'];
        }
    }

    toggleRoadTypes(){
        if(this.selectedRoadTypes.length){
            this.selectedRoadTypes = [];
        } else {
            this.selectedRoadTypes = this.roadRiskStoreService.state.roadTypes;
        }
    }

    selectRoadUser(roadUser: any) {
        this.selectedRoadUser = roadUser;
        this.setCutoffs_using_dataVersionAndRoadUser()
    }

    selectDataVersion(event: any) {
        this.selectedDataVersion = event.value.code ;
        this.setCutoffs_using_dataVersionAndRoadUser()
    }

    selectRoadType(event: any) {
        let selectedRoadTypes = event.value;
        this.selectedRoadTypes = []
        for(let i = 0 ; i < selectedRoadTypes.length; i++){
            this.selectedRoadTypes = [...this.selectedRoadTypes, selectedRoadTypes[i].code ];
        }
    }

    selectQuantile(q: string) {
        // Keep empty
    }

    selectCutoff(quantile: string, cutoff: number) {
        this.selectedCutoffs[quantile] = cutoff
        this.roadRiskService.updateSearch(this.roadRiskStoreService.state)
    }
}
