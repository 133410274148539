import {Injectable} from '@angular/core';
import {Store} from 'src/app/globals/store';
import {GeometryModel} from "../../../models/geojson/geometry-model";

export class SearchState {
  searchText: string;
  startingLocationMarker: string;
  endingLocationMarker: string;
  drawnGeometry: GeometryModel;
  convexGeometry: any;
  isDrawnLocationMarker: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SearchStoreService extends Store<SearchState> {

  constructor() {
    super(SearchState)
  }
}
