<div class="ui-g sticky" style="padding-right: 10px;">
  <div class="ui-g-1 titleButton">
    <i class="fa fa-arrow-left" (click)="show(false)"></i>
  </div>
  <div class="ui-g-10">
    <h1>{{ 'conformityEntry.title' | translate }}</h1>
  </div>
  <div class="ui-g-1 titleButton">
    <i class="fa fa-eraser" [ngClass]="{'itemUnchecked':searchConformityActivated, 'itemDisabled': !searchConformityActivated}"
    (click)="clearConformity()"></i>
  </div>
</div>
<div class="content">
  <p-header style="padding-top: 10px !important; padding-bottom: 5px !important">
    {{ 'common.selectByElementType' | translate }}
  </p-header>
  <div class="container">
    <label [for]="conformity.value" class="conformityContainer" *ngFor="let conformity of conformityElements"
      [ngClass]="{activated:!conformity.disabled}">
      <div *ngIf="conformity.disabled" class="disabledSurface"></div>
      <p class="conformityTitle">
        {{conformity.labels[getLocale()]}}
      </p>
      <span class="line"></span>
      <div class="imagesContainer">
        <i *ngFor="let image of conformity.images" [class]="'ct-icon '+image+''"></i>
      </div>
      <span class="line"></span>
      <p-checkbox *ngIf="!conformity.disabled" [name]="conformity.labels[getLocale()]"
      [value]="conformity.value"
        [ngModel]="selectedConformityValues"
        (ngModelChange)="selectConformityChangeHandler($event)"
        [inputId]="conformity.value">
      </p-checkbox>
      <p-checkbox *ngIf="conformity.disabled"
        [disabled]="true"
        [name]="conformity.labels[getLocale()]">
      </p-checkbox>
    </label>
  </div>
</div>
<hr>
<div class="ui-g sticky" style="padding-right: 10px;">
  <div class="ui-g-1 titleButton">
<!--      <i class="fa fa-arrow-left" (click)="show(false)"></i>-->
  </div>
  <div class="ui-g-11">
    <h1>{{ 'conformityEntry.conformityEntryPanel.dangerZone' | translate }}</h1>
  </div>
</div>
<div class="content">
  <p-header style="padding-top: 10px !important; padding-bottom: 5px !important">
    {{ 'common.selectByElementType' | translate }}
  </p-header>
  <div class="container">
    <label [for]="danger.value" class="conformityContainer" *ngFor="let danger of dangerElements"
            [ngClass]="{activated:!danger.disabled}">
      <div *ngIf="danger.disabled" class="disabledSurface"></div>
      <p class="conformityTitle">
        {{danger.labels[getLocale()]}}
      </p>
      <span class="line"></span>
      <div class="imagesContainer">
        <i *ngFor="let image of danger.images" [class]="'ct-icon '+image+''"></i>
      </div>
      <span class="line"></span>
      <p-checkbox *ngIf="!danger.disabled" [name]="danger.labels[getLocale()]"
                  [value]="danger.value"
                  [ngModel]="selectedDangerValues"
                  (ngModelChange)="selectDangerChangeHandler($event)"
                  [inputId]="danger.value">
      </p-checkbox>
      <p-checkbox *ngIf="danger.disabled"
                  [disabled]="true"
                  [name]="danger.labels[getLocale()]">
      </p-checkbox>
    </label>
  </div>
</div>

