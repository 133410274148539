import {ISerializable} from '../iserializable';
import {StatisticModel} from "./statistic-model";

export class StatisticCollectionModel implements ISerializable {

  public elementType: string;
  public activityId: string;
  public statistics: StatisticModel[];

  deserialize(input: any): this {
    Object.assign(this, input);

    this.statistics = input.statistics.map(item => new StatisticModel().deserialize(item));
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
