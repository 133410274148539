import {IFeatureProperties} from './ifeature-properties';
import {ISerializable} from '../iserializable';
import {ElementModel} from "./element-model";

export class FeatureObjectViewModel implements IFeatureProperties, ISerializable {

  public featureType: string;

  public viewOrder: number;
  public viewProperties: object;
  public viewIdentifier: string;
  public viewOrientation: number;
  public viewType: string;
  public viewUrl: string;
  public viewTimestamp: string;

  public elements: ElementModel[] = [];

  public snapshotIdentifier: string;
  public snapshotName: string;

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.elements && input.elements.length > 0) {
      this.elements = input.elements.map(element => new ElementModel().deserialize(element));
    }

    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
  toExcel(): any {
    let item: any = {};

    item.viewType = this.viewType;
    item.viewUrl = this.viewUrl;

    return item;
  }
}
