import { Injectable } from '@angular/core';
import { MapComponent } from 'src/app/components/map/map.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private elementClassImageToHandle = new Set();
  private handledElementClassImage = new Set()
  private elementClassImagePath = {};
  public map: MapComponent;
  private waitings : ((c:MapComponent)=>void)[] = []
  constructor() { }

  setMap(map:MapComponent){
    this.map = map;
    this.waitings.forEach(w=>w(this.map))
    this.waitings = []
    this.map.map.on('styleimagemissing',(e)=>{
      this.handleMissingImage(e);
    })
  }

  getMap():MapComponent | undefined{
    return this.map;
  }

  callWhenAvailable(callback: (c:MapComponent)=>void){
    if(this.map){
      callback(this.map)
    } else {
      this.waitings.push(callback);
    }
  }

  addMissingElementClassImage(elementClass:string, path:string){
    if(!this.elementClassImageToHandle.has(elementClass)){
      this.elementClassImageToHandle.add(elementClass)
      this.elementClassImagePath[elementClass] = path;
    }
  }

  handleMissingImage(element){
    const id = element.id;
    if(!this.handledElementClassImage.has(id) && this.elementClassImageToHandle.has(id)){
      this.handledElementClassImage.add(id);
      this.map.loadImage(id, environment.urlMedia + this.elementClassImagePath[id])
    }
  }


}
