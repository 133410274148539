import {IFeatureProperties} from '../geojson/ifeature-properties';
import {ISerializable} from '../iserializable';

export class FeatureObjectZoneModel implements IFeatureProperties, ISerializable {

  public featureType: string;

  public zoneName: string;
  public zoneDisplayName: string;
  public zoneCity: string;
  public zoneCounty: string;
  public zoneState: string;
  public zonePostcode: string;
  public zoneCountry: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
  toExcel(): any {
    let item: any = {};

    item.zoneDisplayName = this.zoneDisplayName;
    item.zoneName = this.zoneName;

    return item;
  }
}
