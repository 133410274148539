<div class="ui-g sticky" style="padding-right: 10px;">
    <div class="ui-g-1 titleButton">
    <i class="fa fa-arrow-left" (click)="close()"></i>
    </div>
    <div class="ui-g-10">
    <h1>{{ 'roughnessEntry.title' | translate }}</h1>
    </div>
    <div class="ui-g-1 titleButton">
    <i class="fa fa-eraser" [ngClass]="{'itemUnchecked':activated, 'itemDisabled': !activated}" (click)="clear()"></i>
    </div>
</div>

<div class="title">{{ 'common.yearRange' | translate }}</div>
<div class="custom-slider3 yearsSlider">
    <ng5-slider  [(value)]="minPeriod" [(highValue)]="maxPeriod" [options]="options" ></ng5-slider>
</div>

<div class="title">{{ 'common.qualityRange' | translate }}</div>
<div class="iri-slider qualitySlider">
    <ng5-slider [(value)]="maxGoodQuality" [(highValue)]="minWorstQuality" [options]="qualitySliderOptions"></ng5-slider>
</div>
