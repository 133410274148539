import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Session} from "../../globals/session";
import {environment} from "../../../environments/environment";
import {GeometryModel} from "../../models/geojson/geometry-model";
import * as mapboxgl from "mapbox-gl";
import {AnySourceData, Map, MapboxGeoJSONFeature, Marker} from "mapbox-gl";
import {GeometryHelper} from "../../helpers/geometry.helper";
import {MapLayerEnum} from "../../enums/map-layer-enum.enum";
import {MapSourceEnum} from "../../enums/map-source-enum.enum";
import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import * as MapboxLanguage from '@mapbox/mapbox-gl-language';
import * as turf from "@turf/turf";
import {Geometry} from "@turf/turf";
import {GeometryTypeEnum} from "../../enums/geometry-type-enum.enum";
import {FeatureCollectionModel} from "../../models/geojson/feature-collection-model";
import {FeatureModel} from "../../models/geojson/feature-model";
import {StringUtils} from "../../helpers/string.utils";
import {WindowEventHelper} from "../../helpers/window-event.helper";
import {EventCodeEnum} from "../../enums/event-code-enum.enum";
import {MapImageEnum} from "../../enums/map-image-enum.enum";
import {SnapshotCollectionModel} from "../../models/annotation/snapshot-collection-model";
import {MapService} from 'src/app/shared/map/map.service';
import {ReducersService} from 'src/app/globals/reducers.service';
import {SearchStoreService} from "../main/search/search-store.service";
import {PanelService} from "../../shared/panel/panel.service";


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  map: Map;
  // @ts-ignore
  mapboxgl: mapboxgl;
  draw: MapboxDraw;

  // -- Scale
  scale = new mapboxgl.ScaleControl({
    maxWidth: 80,
    unit: 'metric'
  });

  @ViewChild('map') imageMap: ElementRef;
  @ViewChild('canvas') imageCanvas: ElementRef;

  isActiveMeasure = false;
  measureDistance: string = '0 Km';
  currentMarkers: any;
  beforeCurrentObj = {
    geometry: {
      coordinates: [[0, 0], [0, 0]]
    }
  };

  isDrawn = false;

  isShowBackgroundStrip: boolean = false;
  isShowBackgroundDetails: boolean = true;
  isUserInteractionsActive: boolean = true;

  @Output() onClickMap: EventEmitter<GeometryModel> = new EventEmitter();
  @Output() onClickLayerMap: EventEmitter<any> = new EventEmitter();
  @Output() onCloseViewer: EventEmitter<boolean> = new EventEmitter();
  @Output() onMapMoved: EventEmitter<GeometryModel> = new EventEmitter<GeometryModel>();
  @Output() onClearWorkspace: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  constructor(private session: Session,
              private mapService: MapService,
              private searchStore: SearchStoreService,
              private reducers: ReducersService, private panelService: PanelService) {
  }

  ngOnInit() {

    // Mapbox
    // @ts-ignore
    mapboxgl.accessToken = environment.accessMapboxToken;
    this.map = new mapboxgl.Map({
      container: 'map',
      style: environment.mapStyleDefault,
      center: GeometryHelper.getDefaultPoint().geometry.coordinates as any, // Paris
      zoom: 6,      preserveDrawingBuffer: true,
      transformRequest: (url) => {
        if (url.startsWith("https://wxs.ign.fr/")) {
          return {
            url: url,
            headers: {"Authorization": "Basic " + environment.mapIgnAuth}
          };
        } else if (url.startsWith(environment.urlApi)) {
          return {
            url: url,
            headers: {"Authorization": "Bearer " + this.session.token,},
            credentials: 'include'
          };
        }
      }
    });

    // Controls
    // -- Compass
    this.map.addControl(new mapboxgl.NavigationControl({
      showCompass: true,
      showZoom: false
    }), 'top-right');

    // MouseCursor
    this.map.getCanvas().style.cursor = 'default';
    // -- Draw control
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: false,
        trash: false
      }
    });
    this.map.addControl(this.draw);

    this.map.on('moveend', () => {
      if (!this.isUserInteractionsActive) {
        this.makeActive();
      }
      if (this.isShowBackgroundStrip) {
        let centroid = this.map.getBounds().getCenter();

        let geometry: GeometryModel = new GeometryModel();
        geometry.type = GeometryTypeEnum.POINT;
        geometry.coordinates = [
          centroid.lng,
          centroid.lat
        ];

        this.onMapMoved.emit(geometry);
      }
    });

    this.map.on('draw.create', (e) => {
      const data = this.draw.getAll();
      if (data.features.length > 0) {
        const id = data.features[0].id;
        this.draw.delete(id);
      }

      this.map.getCanvas().style.cursor = 'default';
      let geometry: GeometryModel = new GeometryModel();
      geometry.type = e.features[0].geometry.type;
      geometry.coordinates = e.features[0].geometry.coordinates;
      const state = this.searchStore.buildStateFromComponent(this);
      state.drawnGeometry = new GeometryModel();
      state.drawnGeometry = geometry;
      this.searchStore.state = state;

      this.isDrawn = false;
      this.session.setWorkspace(geometry);
    });
    this.map.addControl(this.scale, 'bottom-right');
    this.map.on("style.load", () => {
      const waiting = () => {
        if (!this.map.isStyleLoaded()) {
          setTimeout(waiting, 200);
        } else {
          this._loadAfterMapLoaded();
        }
      };
      this.mapService.setMap(this);
      waiting();
    });


    // External events
    this.session.onChangeWorkspace.subscribe(geometry => {
      this._changeWorkspace(geometry);
    });

    this.currentMarkers = [];

    this.loadImage(MapImageEnum.IMAGE_CAMERA, MapImageEnum.URL_CAMERA);

    this.loadImage(MapImageEnum.IMAGE_LOCATION_MARKER_D, MapImageEnum.URL_LOCATION_MARKER);
    this.loadImage(MapImageEnum.IMAGE_LOCATION_MARKER_A, MapImageEnum.URL_LOCATION_MARKER_W);
    this.loadImage(MapImageEnum.IMAGE_LOCATION_MARKER_N, MapImageEnum.URL_LOCATION_MARKER_W);
    this.loadImage(MapImageEnum.IMAGE_LOCATION_MARKER_M, MapImageEnum.URL_LOCATION_MARKER_M);
    this.loadImage(MapImageEnum.IMAGE_LOCATION_MARKER_P, MapImageEnum.URL_LOCATION_MARKER_W);
    this.loadImage(MapImageEnum.IMAGE_LOCATION_MARKER_F, MapImageEnum.URL_LOCATION_MARKER_F);


      /*
       * ROAD FSIs ICONS _________________________________________
       */
      this.loadImage(MapImageEnum.IMAGE_unscathed_auto      , MapImageEnum.URL_LOCATION_unscathed_auto);
      this.loadImage(MapImageEnum.IMAGE_unscathed_hgv       , MapImageEnum.URL_LOCATION_unscathed_hgv);
      this.loadImage(MapImageEnum.IMAGE_unscathed_bicycle   , MapImageEnum.URL_LOCATION_unscathed_bicycle);
      this.loadImage(MapImageEnum.IMAGE_unscathed_moto      , MapImageEnum.URL_LOCATION_unscathed_moto);
      this.loadImage(MapImageEnum.IMAGE_unscathed_scooter   , MapImageEnum.URL_LOCATION_unscathed_scooter);
      this.loadImage(MapImageEnum.IMAGE_unscathed_peds      , MapImageEnum.URL_LOCATION_unscathed_peds);

      this.loadImage(MapImageEnum.IMAGE_light_auto          , MapImageEnum.URL_LOCATION_light_auto);
      this.loadImage(MapImageEnum.IMAGE_light_hgv           , MapImageEnum.URL_LOCATION_light_hgv);
      this.loadImage(MapImageEnum.IMAGE_light_bicycle       , MapImageEnum.URL_LOCATION_light_bicycle);
      this.loadImage(MapImageEnum.IMAGE_light_moto          , MapImageEnum.URL_LOCATION_light_moto);
      this.loadImage(MapImageEnum.IMAGE_light_scooter       , MapImageEnum.URL_LOCATION_light_scooter);
      this.loadImage(MapImageEnum.IMAGE_light_peds          , MapImageEnum.URL_LOCATION_light_peds);

      this.loadImage(MapImageEnum.IMAGE_severe_auto         , MapImageEnum.URL_LOCATION_severe_auto);
      this.loadImage(MapImageEnum.IMAGE_severe_hgv          , MapImageEnum.URL_LOCATION_severe_hgv);
      this.loadImage(MapImageEnum.IMAGE_severe_bicycle      , MapImageEnum.URL_LOCATION_severe_bicycle);
      this.loadImage(MapImageEnum.IMAGE_severe_moto         , MapImageEnum.URL_LOCATION_severe_moto);
      this.loadImage(MapImageEnum.IMAGE_severe_scooter      , MapImageEnum.URL_LOCATION_severe_scooter);
      this.loadImage(MapImageEnum.IMAGE_severe_peds         , MapImageEnum.URL_LOCATION_severe_peds);

      this.loadImage(MapImageEnum.IMAGE_killed_auto         , MapImageEnum.URL_LOCATION_killed_auto);
      this.loadImage(MapImageEnum.IMAGE_killed_hgv          , MapImageEnum.URL_LOCATION_killed_hgv);
      this.loadImage(MapImageEnum.IMAGE_killed_bicycle      , MapImageEnum.URL_LOCATION_killed_bicycle);
      this.loadImage(MapImageEnum.IMAGE_killed_moto         , MapImageEnum.URL_LOCATION_killed_moto);
      this.loadImage(MapImageEnum.IMAGE_killed_scooter      , MapImageEnum.URL_LOCATION_killed_scooter);
      this.loadImage(MapImageEnum.IMAGE_killed_peds         , MapImageEnum.URL_LOCATION_killed_peds);

    this.loadImage(MapImageEnum.IMAGE_HORIZONTALE, MapImageEnum.URL_HELIOS_HORIZONTALE);
    this.loadImage(MapImageEnum.IMAGE_VERTICALE, MapImageEnum.URL_HELIOS_VERTICALE);
    this.loadImage(MapImageEnum.IMAGE_IMPLANTATION, MapImageEnum.URL_HELIOS_IMPLANTATION);
    this.loadImage(MapImageEnum.ICON_CIRCLE, MapImageEnum.URL_ICON_CIRCLE);

    this.panelService.subjectLeft.subscribe(event => {
      if ("POP" === event.type || "CLOSE" === event.type) {
        this.removeLayer(MapLayerEnum.LAYER_CIRCLE)
      }
    })
  }

  _loadAfterMapLoaded() {
    // Events
    this.map.on('click', e => {
      let geometry: GeometryModel = new GeometryModel();
      geometry.type = GeometryTypeEnum.POINT;
      geometry.coordinates = [
        e.lngLat.lng,
        e.lngLat.lat
      ];

      WindowEventHelper.sendEvent(EventCodeEnum.MAP_CLICK, geometry);
      this.onClickMap.emit(geometry);
    });

    this.map.on('touchstart', e => {
      let geometry: GeometryModel = new GeometryModel();
      geometry.type = GeometryTypeEnum.POINT;
      geometry.coordinates = [
        e.lngLat.lng,
        e.lngLat.lat
      ];
      WindowEventHelper.sendEvent(EventCodeEnum.MAP_CLICK, geometry);
      this.onClickMap.emit(geometry);
    });

    // Locale
    let lang: string = 'en';
    if (StringUtils.isNotEmpty(this.session.sessionLocale)) {
      lang = this.session.sessionLocale.slice(0, 2);
    }
    this.map.addControl(new MapboxLanguage({
      defaultLanguage: lang
    }));

    let scaleUnit = 'metric';
    const scaleOnMap = document.querySelector(".mapboxgl-ctrl.mapboxgl-ctrl-scale");
    scaleOnMap.addEventListener("click", () => {
      if (scaleUnit === 'metric') {
        scaleUnit = 'imperial';
        this.scale.setUnit('imperial');
      } else {
        scaleUnit = 'metric';
        this.scale.setUnit('metric');
      }
    });
    (<any>scaleOnMap).style.cursor = "pointer";

    // Workspace
    this._changeWorkspace(this.session.getWorkspace());
  }

  clickLayerMap(layer) {
    // Events
    this.map.on('click', layer, e => {
      e.preventDefault();
      let geometry: GeometryModel = new GeometryModel();
      geometry.type = GeometryTypeEnum.POINT;
      geometry.coordinates = [
        e.lngLat.lng,
        e.lngLat.lat
      ];
      WindowEventHelper.sendEvent(EventCodeEnum.MAP_LAYER_CLICK, geometry);
      this.onClickLayerMap.emit(e);
    });
    this.map.on('mouseenter', layer, () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    this.map.on('mouseleave', layer, () => {
      if (this.isDrawn === true) {
        this.map.getCanvas().style.cursor = 'crosshair';
      } else if (this.isDrawn === false) {
        this.map.getCanvas().style.cursor = 'default';
      }
    });

  }

  // Navigation
  zoomIn() {
    this.map.zoomIn();
  }

  zoomOut() {
    this.map.zoomOut();
  }

  // Measure tool
  toggleMeasure() {
    this.isActiveMeasure = !this.isActiveMeasure;
    if (this.isActiveMeasure) {
      this.onCloseViewer.emit(false);
      this._measureDraw();
    } else {
      this._measureRemove();
    }
  }

  _measureDraw() {
    // TODO : rename variables & cleanup code inutile
    const geojson = {
      type: 'FeatureCollection',
      features: []
    };
    const time = String(new Date().getTime());
    // Used to draw a line between points
    const linestring = {
      type: 'Feature',
      geometry: {
        type: GeometryTypeEnum.LINE_STRING,
        coordinates: []
      },
      properties: {
        name: 'Dinagat Islands'
      }
    };
    const measureFunction = (e) => {
      if (this.isActiveMeasure) {
        const mapLayer = this.map.getLayer(MapLayerEnum.LAYER_MEASURE_POINTS);
        if (typeof mapLayer === 'undefined') {
          this.map.addSource(MapSourceEnum.SOURCE_MEASURE, {
            type: 'geojson',
            data: geojson
          } as AnySourceData);

          this.map.addLayer({
            id: MapLayerEnum.LAYER_MEASURE_POINTS,
            type: 'circle',
            source: MapSourceEnum.SOURCE_MEASURE,
            paint: {
              'circle-radius': 3,
              'circle-color': '#000'
            },
            filter: ['in', '$type', GeometryTypeEnum.POINT]
          });
          this.map.addLayer({
            id: MapLayerEnum.LAYER_MEASURE_LINES,
            type: 'line',
            source: MapSourceEnum.SOURCE_MEASURE,
            layout: {
              'line-cap': 'round',
              'line-join': 'round'
            },
            paint: {
              'line-color': '#000',
              'line-width': 2.5
            },
            filter: ['in', '$type', GeometryTypeEnum.LINE_STRING]
          });
        }
        const features = this.map.queryRenderedFeatures(e.point, {layers: [MapLayerEnum.LAYER_MEASURE_POINTS]});
        // Remove the linestring from the group
        // So we can redraw it based on the points collection
        if (geojson.features.length > 1) {
          geojson.features.pop();
        }
        // If a feature was clicked, remove it from the map
        if (features.length) {
          const id = features[0].properties.id;
          geojson.features = geojson.features.filter(function (point) {
            return point.properties.id !== id;
          });
        } else {
          const point = {
            type: 'Feature',
            geometry: {
              type: GeometryTypeEnum.POINT,
              coordinates: [
                e.lngLat.lng,
                e.lngLat.lat
              ]
            },
            properties: {
              id: time
            }
          };
          geojson.features.push(point);
        }
        if (geojson.features.length < 2) {
          this.measureDistance = "0 Km";
        }
        if (geojson.features.length > 1) {
          linestring.geometry.coordinates = geojson.features.map(function (point) {
            return point.geometry.coordinates;
          });
          geojson.features.push(linestring);
          // @ts-ignore
          this.measureDistance = turf.lineDistance(linestring).toLocaleString() + " Km";
        }
        // @ts-ignore
        this.map.getSource(MapSourceEnum.SOURCE_MEASURE).setData(geojson);
      }
    }
    this.map.on('click', measureFunction);
    this.map.on('touchstart', measureFunction)
  }

  measureLayers(featureCollectionLayer) {
    let value = 0;

    featureCollectionLayer.features.forEach(e => {
      if (e.geometry.type == 'LineString') {
        let line = turf.lineString(e.geometry.coordinates);
        let length = turf.length(line, {units: 'kilometers'});
        value = value + length;
      }
    });
    return value;
  }

  //testing function to check openLocationCode
  // drawRectangle(obj){
  //   const mapLayer = this.map.getLayer('maine');
  //   if (typeof mapLayer !== 'undefined') {
  //     this.map.removeLayer('maine').removeSource('maine');
  //   }
  //   var line = turf.lineString([[obj.latitudeLo, obj.longitudeLo], [obj.latitudeHi, obj.longitudeHi]]);
  //   var bbox = turf.bbox(line);
  //   var bboxPolygon = turf.bboxPolygon(bbox);
  //
  //   // var ptsWithin = turf.pointsWithinPolygon(points, bboxPolygon);
  //   this.map.addSource('maine', {
  //     "type": "geojson",
  //     "data": bboxPolygon
  //   });
  //   this.map.addLayer({
  //     'id': 'maine',
  //     'type': 'fill',
  //     'source': 'maine',
  //     'layout': {},
  //     'paint': {
  //       'fill-color': '#088',
  //       'fill-opacity': 0.8
  //     }
  //   });
  // }
  /// end testing function to check openLocationCode

  measureLayersGetLongDistances(featureCollectionLayer, distanceByUser) {
    let value = 0;
    let shortLineDistance = 0;
    let obj = {length: 0, element: {}};
    let arrayCollectionObj = [];
    let arrayObj = [];
    let beforeBoolean = false;

    featureCollectionLayer.features.forEach(e => {
      if (e.geometry.type == 'LineString') {
        let line = turf.lineString(e.geometry.coordinates);
        let length = turf.length(line, {units: 'kilometers'});
        value = value + length;
        //check if last point is the same of first one, so it belongs to the same line
        var pt2 = turf.point(this.beforeCurrentObj.geometry.coordinates[1]);
        var pt3 = turf.point(e.geometry.coordinates[0]);
        if (turf.booleanEqual(pt3, pt2) || beforeBoolean) {
          //to add the line before detection to have the good distance
          if (shortLineDistance === 0) {
            shortLineDistance = length;
          }
          shortLineDistance = shortLineDistance + length;
          if (shortLineDistance > distanceByUser) {
            obj = {
              length: shortLineDistance,
              element: this.beforeCurrentObj
            };
            arrayObj.push(obj)
          }
          beforeBoolean = true;
        }
        //if red line is broken, reset values and push the info
        if (!turf.booleanEqual(pt3, pt2)) {
          if (arrayObj.length > 0) {
            arrayObj = arrayObj[arrayObj.length - 1];
            arrayCollectionObj.push(arrayObj);
          }
          beforeBoolean = false;
          shortLineDistance = 0;
          arrayObj = []
        }
        this.beforeCurrentObj = e;
      }
    });

    //condition if all lines are red
    if (arrayObj.length > 0 && arrayCollectionObj.length === 0) {
      arrayObj = arrayObj[arrayObj.length - 1];
      arrayCollectionObj.push(arrayObj);
    }
    return arrayCollectionObj;
  }

  _measureRemove() {
    const mapLayer = this.map.getLayer(MapLayerEnum.LAYER_MEASURE_POINTS);
    if (typeof mapLayer !== 'undefined') {
      this.map.removeLayer(MapLayerEnum.LAYER_MEASURE_POINTS).removeLayer(MapLayerEnum.LAYER_MEASURE_LINES).removeSource(MapSourceEnum.SOURCE_MEASURE);
    }
    this.isActiveMeasure = false;
    this.measureDistance = "0 Km";
  }

  get isShowMeasure() {
    return this.map.getLayer(MapLayerEnum.LAYER_MEASURE_POINTS);
  }

  // Workspace
  fitToWorkspace() {
    //code to avoid error toTurf() not function
    let geometry: GeometryModel = this.session.getWorkspace();


    let geometryToTurf: any = turf.helpers.geometry(geometry.type, geometry.coordinates);

    let bbox: any = turf.bbox(geometryToTurf);

    // original code
    // let bbox: any = turf.bbox(this.session.getWorkspace().toTurf());
    this.map.fitBounds(bbox, {padding: 20});
  }

  _changeWorkspace(geometry: GeometryModel) {
    if (this.map !== undefined && geometry !== undefined) {
      const state = this.searchStore.buildStateFromComponent(this);
      state.drawnGeometry = new GeometryModel();
      state.drawnGeometry = geometry;
      this.searchStore.state = state;
      let geometryToTurf = null;
      this._workspaceRemove()
      if (geometry.type === GeometryTypeEnum.POLYGON) {
        geometryToTurf = turf.helpers.feature(geometry);
        this.putWorkspaceBeforeAnyCustomLayer(geometryToTurf)
      } else if (geometry.type === GeometryTypeEnum.MULTI_POLYGON) {
        let maskMultiPolygon: any;
        let i = 0;
        geometry.coordinates.forEach(coord => {
          geometryToTurf = turf.helpers.feature(geometry);
          if (i === 0) {
            maskMultiPolygon = geometryToTurf;
          } else {
            maskMultiPolygon.geometry.coordinates.push(geometryToTurf.geometry.coordinates[1])
          }
          i++;
        });
        this.putWorkspaceBeforeAnyCustomLayer(geometryToTurf);
      }
      this.fitToWorkspace();
    }
  }

  getWorkspaceCentroid(): GeometryModel {
    return GeometryHelper.getCentroid(this.session.getWorkspace());
  }

  _workspaceRemove() {
    if (this.map.getLayer(MapLayerEnum.LAYER_WORKSPACE) !== undefined) {
      this.map.removeLayer(MapLayerEnum.LAYER_WORKSPACE);
    }
    if (this.map.getLayer(MapLayerEnum.LAYER_WORKSPACE + "line") !== undefined) {
      this.map.removeLayer(MapLayerEnum.LAYER_WORKSPACE + "line");
    }
    if (this.map.getSource(MapSourceEnum.SOURCE_WORKSPACE) !== undefined) {
      this.map.removeSource(MapSourceEnum.SOURCE_WORKSPACE);
    }
    this.onClearWorkspace.emit(true);
  }

  // Draw Polygon
  startDrawPolygon() {
    this.clearMap();
    this.isDrawn = true;
    const state = this.searchStore.buildStateFromComponent(this);
    state.drawnGeometry = undefined;
    this.searchStore.state = state;
    this.map.getCanvas().style.cursor = 'crosshair';
    this.draw.changeMode('draw_polygon');
  }

  // Map Background
  showBackground(show: boolean) {
    let layers = this.map.getStyle().layers;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].type === 'symbol') {
        break;
      }
      this.map.setLayoutProperty(layers[i].id, "visibility", (show) ? "visible" : "none");
    }
  }

  showBackgroundDetails(show: boolean) {
    this.isShowBackgroundDetails = show;
    let layers = this.map.getStyle().layers;
    let start = false;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].type === 'symbol') {
        start = true;
      }
      if (start && layers[i].source === 'composite') {
        this.map.setLayoutProperty(layers[i].id, "visibility", (show) ? "visible" : "none");
      }
    }
  }

  // Map Layers
  getFirstLayerId(): string {
    let layers = this.map.getStyle().layers;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].type === 'symbol' && layers[i].source === 'composite') {
        return layers[i].id;
      }
    }
    return undefined;
  }

  getRoadLabelLayerId(): string {
    let layers = this.map.getStyle().layers;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i]['source-layer'] === 'admin' && layers[i].source === 'composite') {
        return layers[i].id;
      }
    }
    return undefined;
  }

  showSourceLayer(name: string, show: boolean) {
    let layers = this.map.getStyle().layers;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i]["source-layer"] === name) {
        this.map.setLayoutProperty(layers[i].id, "visibility", (show) ? "visible" : "none");
      }
    }
  }

  removeLayer(layerName: string, sourceName: string = undefined) {
    if (this.map.getLayer(layerName) !== undefined && this.map.getLayer(layerName)) {
      this.map.removeLayer(layerName);
    }

    if (sourceName !== undefined) {
      if (this.map.getSource(sourceName) !== undefined) {
        this.map.removeSource(sourceName);
      }
    }
  }

  removeLayersContain(contain: string) {
    let layers = this.map.getStyle().layers;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i]['id'] !== undefined && layers[i]['id'].toString().indexOf(contain) > -1) {
        // @ts-ignore
        this.removeLayer(layers[i]['id'], layers[i]['source']);
      }
    }
  }

  addLineLayer(layerName: string, sourceName: string, layout: any, paint: any, data: FeatureCollectionModel) {
    this.removeLayer(layerName, sourceName);
    this.map.addSource(sourceName, {
      "type": "geojson",
      "data": data.toJson()
    });

    this.map.addLayer({
      "id": layerName,
      "type": "line",
      "source": sourceName,
      "layout": layout,
      "paint": paint
    });
  }

  addViewLayer(layerName: string, data: FeatureModel) {
    let id = data.properties['viewIdentifier'];
    let sourceName = 'src-' + layerName;

    if (this.map.getLayer(layerName + id) !== undefined) {
      return;
    }

    this.map.addSource(sourceName + id, {
      "type": "image",
      'url': environment.urlStorage + data.properties['viewUrl'],
      'coordinates': [
        data.geometry.coordinates[0][0],
        data.geometry.coordinates[0][1],
        data.geometry.coordinates[0][2],
        data.geometry.coordinates[0][3]
      ]
    });

    this.map.addLayer({
      "id": layerName + id,
      "type": "raster",
      "source": sourceName + id,
      'paint': {'raster-opacity': 1}
    });

    this.moveLayerFirst(layerName + id);
  }

  addLinesTextTiles(layerName: string, tileUrl: string, sourceLayer: string, sourceName: string, beforeWorkspace: boolean = false, layoutLine: any = undefined, paint: any = undefined, data: MapboxGeoJSONFeature[], layerRef: string) {
    if (this.map.getLayer(layerName) !== undefined) {
      this.map.removeLayer(layerName);
    }

    let layerWorkspace = this.map.getLayer(MapLayerEnum.LAYER_WORKSPACE);
    if (beforeWorkspace && typeof layerWorkspace !== 'undefined') {
      this.map.addLayer({
        "id": layerName,
        "type": "symbol",
        "source": sourceName,
        "minzoom": 18,
        "source-layer": sourceLayer,
        "layout": {
          "symbol-placement": "line",
          "text-font": ["Open Sans Regular"],
          "text-field": '{element_name}',
          "text-size": 18
        },
        "paint": paint
      }, (beforeWorkspace) ? MapLayerEnum.LAYER_WORKSPACE : undefined);
    } else {
      this.map.addLayer({
        "id": layerName,
        "type": "symbol",
        "minzoom": 18,
        "source": sourceName,
        "source-layer": sourceLayer,
        "layout": {
          "symbol-placement": "line",
          "text-font": ["Open Sans Regular"],
          "text-field": "{element_name}", // part 2 of this is how to do it
          "text-size": 18,
        },
        "paint": paint
      });
    }

  }

  addSnapshotLayer(layerName: string, sourceName: string, layout: any, paint: any, data: SnapshotCollectionModel) {
    this.removeLayer(layerName, sourceName);
    this.map.addSource(sourceName, {
      "type": "geojson",
      "data": data.toJson()
    });

    this.map.addLayer({
      "id": layerName,
      "type": "line",
      "source": sourceName,
      "layout": layout,
      "paint": paint
    });
  }

  addCircleLayer(layerName: string, sourceName: string, beforeWorkspace: boolean = false, paint: any = undefined) {
    this.removeLayer(layerName);

    let layerWorkspace = this.map.getLayer(MapLayerEnum.LAYER_WORKSPACE);
    if (beforeWorkspace && typeof layerWorkspace !== 'undefined') {

      this.map.addLayer({
        "id": layerName,
        "type": "circle",
        "source": sourceName,
        "paint": paint,
        'filter': ['==', '$type', 'Point']
      }, (beforeWorkspace) ? MapLayerEnum.LAYER_WORKSPACE : undefined);

    } else {
      this.map.addLayer({
        "id": layerName,
        "type": "circle",
        "source": sourceName,
        "paint": paint,
        'filter': ['==', '$type', 'Point']
      });
    }
  }

  createSymbolLayerDataFromMapboxFeatures(mapboxFeature: MapboxGeoJSONFeature): FeatureCollectionModel {
    const feature = new FeatureModel();
    feature.type = mapboxFeature.type;
    feature.geometry = new GeometryModel(GeometryTypeEnum[mapboxFeature.geometry.type], (<Geometry>mapboxFeature.geometry).coordinates);
    feature.properties = mapboxFeature.properties;
    const featureCollection = new FeatureCollectionModel();
    featureCollection.geometry = feature.geometry;
    featureCollection.properties = {};
    featureCollection.features = [feature]
    return featureCollection;
  }

    addPolygonLayerTiles(
          layerName         : string
        , tileUrl           : string
        , sourceName        : string
        , layout            : any
        , paint             : any) {
        this.removeLayer(layerName, sourceName + 'Source' );
        this.map.addSource(sourceName + 'Source', {
            "type": "vector",
            "tiles": [tileUrl]
        });
        this.map.addLayer({
            "id": layerName,
            "type": "fill",
            "source": sourceName + 'Source',
            "source-layer": sourceName,
            "layout": layout,
            "paint": paint
        });
    }



  addSymbolLayer(layerName: string, sourceName: string, layout: any, data: FeatureCollectionModel) {
    this.removeLayer(layerName, sourceName);

    this.map.addSource(sourceName, {
      "type": "geojson",
      "data": data.toJson()
    });

    this.map.addLayer({
      "id": layerName,
      "type": "symbol",
      "source": sourceName,
      "layout": layout
    });
  }

  addSymbolLabelLayer(layerName: string, sourceName: string, layout: any, layoutLabel: any, data: FeatureCollectionModel, paint?: any) {
    this.removeLayer(layerName);
    this.removeLayer(layerName + 'Label', sourceName);

    this.map.addSource(sourceName, {
      "type": "geojson",
      "data": data.toJson()
    });

    if (paint !== undefined) {

      this.map.addLayer({
        "id": layerName,
        "type": "symbol",
        "source": sourceName,
        "layout": layout
      });

      this.map.addLayer({
        "id": layerName + 'Label',
        "type": "symbol",
        "source": sourceName,
        "layout": layoutLabel,
        "paint": paint
      });
    } else {
      this.map.addLayer({
        "id": layerName,
        "type": "symbol",
        "source": sourceName,
        "layout": layout
      });

      this.map.addLayer({
        "id": layerName + 'Label',
        "type": "symbol",
        "source": sourceName,
        "layout": layoutLabel,
        "paint": paint
      });
    }
  }

  addLabelLayer(layerName: string, sourceName: string, layoutLabel: any, data: FeatureCollectionModel, paint?: any) {
    this.removeLayer(layerName);
    this.removeLayer(layerName + 'Label', sourceName);

    this.map.addSource(sourceName, {
      "type": "geojson",
      "data": data.toJson()
    });

    if (paint !== undefined) {
      this.map.addLayer({
        "id": layerName + 'Label',
        "type": "symbol",
        "source": sourceName,
        "layout": layoutLabel,
        "paint": paint
      });
    } else {
      this.map.addLayer({
        "id": layerName + 'Label',
        "type": "symbol",
        "source": sourceName,
        "layout": layoutLabel,
        "paint": paint
      });
    }
  }

  addSymbolLabelLayerTiles(layerName: string, tileUrl: string, sourceLayer: string, sourceName: string, beforeWorkspace: boolean = false, layoutIcon: any = undefined, layoutLabel: any = undefined) {
    this.removeLayer(layerName);
    this.removeLayer(layerName + 'Label', sourceName);

    this.map.addSource(sourceName, {
      "type": "vector",
      "tiles": [tileUrl]
    });

    let layerWorkspace = this.map.getLayer(MapLayerEnum.LAYER_WORKSPACE);
    if (typeof layerWorkspace !== 'undefined') {
      this.map.addLayer({
        "id": layerName,
        "type": "symbol",
        "source": sourceName,
        "source-layer": sourceLayer,
        "layout": layoutIcon
      }, MapLayerEnum.LAYER_WORKSPACE);

      this.map.addLayer({
        "id": layerName + "Label",
        "type": "symbol",
        "source": sourceName,
        "source-layer": sourceLayer,
        "layout": layoutLabel
      }, MapLayerEnum.LAYER_WORKSPACE);

    } else {

      this.map.addLayer({
        "id": layerName,
        "type": "symbol",
        "source": sourceName,
        "source-layer": sourceLayer,
        "layout": layoutIcon
      });

      this.map.addLayer({
        "id": layerName + "Label",
        "type": "symbol",
        "source": sourceName,
        "source-layer": sourceLayer,
        "layout": layoutLabel
      });
    }
  }

  addSymbolLayerTiles(layerName: string, tileUrl: string, sourceName: string,
                      beforeWorkspace: boolean = false, layoutIcon: any = undefined, elementType?: string) {
    this.removeLayer(layerName, sourceName + 'Source' + elementType);

    this.map.addSource(sourceName + 'Source' + elementType, {
      "type": "vector",
      "tiles": [tileUrl]
    });

    let layerWorkspace = this.map.getLayer(MapLayerEnum.LAYER_WORKSPACE);
    if (beforeWorkspace && typeof layerWorkspace !== 'undefined') {

      this.map.addLayer({
        "id": layerName,
        "type": "symbol",
        "source": sourceName + 'Source' + elementType,
        "source-layer": sourceName,
        "layout": layoutIcon
      }, (beforeWorkspace) ? MapLayerEnum.LAYER_WORKSPACE : undefined);

    } else {

      this.map.addLayer({
        "id": layerName,
        "type": "symbol",
        "source": sourceName + 'Source' + elementType,
        "source-layer": sourceName,
        "layout": layoutIcon
      });
    }
  }

  addLineVectorLayerTiles(layerName: string, tileUrl: string, sourceLayer: string, sourceName: string, beforeWorkspace: boolean = false, layoutLine: any = undefined, paint: any = undefined) {
    this.removeLayer(layerName, sourceName);

    this.map.addSource(sourceName, {
      "type": "vector",
      "tiles": [tileUrl]
    });

    let layerWorkspace = this.map.getLayer(MapLayerEnum.LAYER_WORKSPACE);
    if (beforeWorkspace && typeof layerWorkspace !== 'undefined') {

      this.map.addLayer({
        "id": layerName,
        "type": "line",
        "source": sourceName,
        "source-layer": sourceLayer,
        "layout": layoutLine,
        "paint": paint
      }, (beforeWorkspace) ? MapLayerEnum.LAYER_WORKSPACE : undefined);

    } else {
      this.map.addLayer({
        "id": layerName,
        "type": "line",
        "source": sourceName,
        "source-layer": sourceLayer,
        "layout": layoutLine,
        "paint": paint
      });
    }
  }


  addLineVectorLayer3DTiles(layerName: string, tileUrl: string, sourceLayer: string, sourceName: string, beforeWorkspace: boolean = false, layoutLine: any = undefined, paint: any = undefined) {
    this.removeLayer(layerName, sourceName);

    this.map.addSource(sourceName, {
      "type": "vector",
      "tiles": [tileUrl]
    });

    let layerWorkspace = this.map.getLayer(MapLayerEnum.LAYER_WORKSPACE);
    if (beforeWorkspace && typeof layerWorkspace !== 'undefined') {

      this.map.addLayer({
        "id": layerName,
        "type": "fill-extrusion",
        "source": sourceName,
        "source-layer": sourceLayer,
        "layout": layoutLine,
        "paint": paint
      }, (beforeWorkspace) ? MapLayerEnum.LAYER_WORKSPACE : undefined);

    } else {
      this.map.addLayer({
        "id": layerName,
        "type": "fill-extrusion",
        "source": sourceName,
        "source-layer": sourceLayer,
        "layout": layoutLine,
        "paint": paint
      });
    }
  }

  addCircleLayerTiles(layerName: string, tileUrl: string, sourceLayer: string, sourceName: string, beforeWorkspace: boolean = false, paint: any = undefined) {
    this.removeLayer(layerName, sourceName);

    this.map.addSource(sourceName, {
      "type": "vector",
      "tiles": [tileUrl]
    });

    let layerWorkspace = this.map.getLayer(MapLayerEnum.LAYER_WORKSPACE);
    if (beforeWorkspace && typeof layerWorkspace !== 'undefined') {

      this.map.addLayer({
        "id": layerName,
        "type": "circle",
        "source": sourceName,
        "source-layer": sourceLayer,
        "paint": paint
      }, (beforeWorkspace) ? MapLayerEnum.LAYER_WORKSPACE : undefined);

    } else {
      this.map.addLayer({
        "id": layerName,
        "type": "circle",
        "source": sourceName,
        "source-layer": sourceLayer,
        "paint": paint
      });
    }
  }

  updateSourceData(sourceName: string, featureCollection: FeatureCollectionModel) {
    if (this.map.getSource(sourceName) !== undefined) {
      // @ts-ignore
      this.map.getSource(sourceName).setData(featureCollection.toJson());
    }
  }

  addRasterWmtsLayer(layerName: string, sourceName: string, url: string) {
    this.removeLayer(layerName, sourceName);

    this.map.addSource(sourceName, {
      "type": "raster",
      "tiles": [url],
      "tileSize": 256
    });

    this.map.addLayer({
      "id": layerName,
      "type": "raster",
      "source": sourceName,
    });
  }

  addRasterLayer(layerName: string, sourceName: string, url: string) {
    this.removeLayer(layerName, sourceName);

    this.map.addSource(sourceName, {
      "type": "raster",
      "url": url,
      "tileSize": 256
    });

    this.map.addLayer({
      "id": layerName,
      "type": "raster",
      "source": sourceName,
    });
  }

  moveLayerFirst(layerName: string) {
    this.map.moveLayer(layerName, this.getFirstLayerId());
  }

  moveLayerAfterBackground(layerName: string) {
    if (this.map.getLayer(MapLayerEnum.LAYER_SATELLITE_IGN)) {
      this.map.moveLayer(layerName, MapLayerEnum.LAYER_SATELLITE_IGN);
    } else if (this.map.getLayer(MapLayerEnum.LAYER_SATELLITE)) {
      this.map.moveLayer(layerName, MapLayerEnum.LAYER_SATELLITE);
    } else {
      this.map.moveLayer(layerName, this.getFirstLayerId());
    }
  }

  moveLayerBeforeRoadLabel(layerName: string) {
    this.map.moveLayer(layerName, this.getRoadLabelLayerId());
  }

  // Marker
  addElementMarker(feature: FeatureModel, elementMarker: HTMLElement, htmlPopup: string = undefined) {
    if (!GeometryHelper.isValidFeature(feature)) {
      return;
    }
    let geometry: GeometryModel;
    if (feature.geometry.type === GeometryTypeEnum.POINT) {
      geometry = feature.geometry;
    } else {
      geometry = GeometryHelper.getCentroid(feature.geometry);
    }

    let marker: Marker = new mapboxgl.Marker(elementMarker);
    marker.setLngLat([geometry.coordinates[0], geometry.coordinates[1]]);

    if (htmlPopup !== undefined) {
      const popup = new mapboxgl.Popup({offset: 5})
      .setHTML(htmlPopup);
      marker.setPopup(popup);
      this.map.on('closeAllPopups', () => {
        popup.remove();
      });
    }

    marker.addTo(this.map);
    this.currentMarkers.push(elementMarker);
  }

  addPopup(geometry, description) {
    let popup = new mapboxgl.Popup();
    this.map.on('closeAllPopups', () => {
      popup.remove();
    });
    this.map.fire('closeAllPopups');
    popup
    .setLngLat(geometry)
    .setHTML(description)
    .addTo(this.map);
  }

  addPopupOnLayer(layer, feature, color) {

    // Create a popup, but don't add it to the map yet.
    var popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    this.map.on('mouseenter', layer, (e) => {

      this.map.getCanvas().style.cursor = 'default';

      // @ts-ignore
      let coordinates;
      if (e.features[0].geometry.type == 'LineString') {
        coordinates = e.features[0].geometry.coordinates[0];
      } else {
        // @ts-ignore
        coordinates = e.features[0].geometry.coordinates;
      }


      let quality: number = e.features[0].properties.elementQuality;
      let nameSnapshot: string = e.features[0].properties.snapshotName || '';
      let jour: string = e.features[0].properties.elementTimestamp.substr(8, 2);
      let mois: string = e.features[0].properties.elementTimestamp.substr(5, 2);
      let annee: string = e.features[0].properties.elementTimestamp.substr(0, 4);
      let datesnapshot: string = jour + '/' + mois + '/' + annee;

      let description: string = '<div class="tooltipQuality" style="background:' + color + ';">' +
        ' Date du relevé : ' + datesnapshot + ' <br/> ' +
        ' Indice Qualité : ' + quality + ' <br/> ' +
        nameSnapshot + ' <br/> ' + '</div>';

      popup
      .setLngLat(coordinates)
      .setHTML(description)
      .addTo(this.map);
    });

    this.map.on('mouseleave', layer, () => {
      this.map.getCanvas().style.cursor = 'pointer';
      popup.remove();
    });
  }

  resizeElementsMarkers() {
    this.currentMarkers.forEach(marker => {
      marker.style.width = '28px';
      marker.style.height = '28px';
    });
  }


    // Image
    loadImage(imageName: string, url: string) {
        if (!this.map.hasImage(imageName)) {
            this.map.loadImage(url, (error, image) => {
                if (error) {
                    throw error;
                }
                this.map.addImage(imageName, image);
            });
        }
    }

  removeImage(imageName: string) {
    if (this.map.hasImage(imageName)) {
      this.map.removeImage(imageName);
    }
  }

  clearMap() {
    this._measureRemove();
    this._workspaceRemove();
  }

  makeActive() {
    this.isUserInteractionsActive = true;
  }

  makeInactiveForAnimation() {
    this.isUserInteractionsActive = false;
  }

  putWorkspaceBeforeAnyCustomLayer(geometryToTurf: any) {
    this.map.addSource(MapSourceEnum.SOURCE_WORKSPACE, {
      "type": "geojson",
      "data": geometryToTurf
    });

    let firstCustomLayer = this.map.getStyle().layers.find(l => l.id.indexOf("city_") > -1);
    if (firstCustomLayer) {
      this.map.addLayer({
        "id": MapLayerEnum.LAYER_WORKSPACE + "line",
        "type": "line",
        "source": MapSourceEnum.SOURCE_WORKSPACE,
        "paint": {
          "line-width": 3,
          "line-color": MapComponent.getLineColorForWorkspace(this.map)
        }
      }, firstCustomLayer.id);

      this.map.addLayer({
        "id": MapLayerEnum.LAYER_WORKSPACE,
        "type": "fill",
        "source": MapSourceEnum.SOURCE_WORKSPACE,
        "paint": {
          'fill-color': 'lightgrey',
          'fill-opacity': 0.1,
          'fill-outline-color': MapComponent.getLineColorForWorkspace(this.map)
        }
      }, firstCustomLayer.id);

    } else {
      this.map.addLayer({
        "id": MapLayerEnum.LAYER_WORKSPACE + "line",
        "type": "line",
        "source": MapSourceEnum.SOURCE_WORKSPACE,
        "paint": {
          "line-width": 3,
          "line-color": MapComponent.getLineColorForWorkspace(this.map)
        }
      });

      this.map.addLayer({
        "id": MapLayerEnum.LAYER_WORKSPACE,
        "type": "fill",
        "source": MapSourceEnum.SOURCE_WORKSPACE,
        "paint": {
          'fill-color': 'lightgrey',
          'fill-opacity': 0.1,
          'fill-outline-color': MapComponent.getLineColorForWorkspace(this.map)
        }
      });

    }


  }

  static getLineColorForWorkspace(map: mapboxgl.Map) {
    if (map.getLayer(MapLayerEnum.LAYER_SATELLITE_IGN) || map.getLayer(MapLayerEnum.LAYER_SATELLITE)) {
      return "red"
    }
    return "black"
  }
}
