import {ISerializable} from '../iserializable';
import {GeometryModel} from "../geojson/geometry-model";
import {ObjectViewModel} from "../common/object-view-model";
import {JsonUtils} from "../../helpers/json.utils";
import {BboxObject} from "../detection/bbox-object";
import {ElementClassModel} from "../masterData/element-class-model";

export class ObjectElementVersionModel implements ISerializable {

  public elementIdentifier: string;
  public elementOrder: number;
  public elementTimestamp: string;

  public creationDate: string;
  public updateDate: string;
  public versionCreationDate:string;

  public elementType: string;
  public elementClass: string;
  public elementClassDetail: ElementClassModel;
  public elementCategory: string;

  public elementName: string;
  public elementProperties: any = {};
  public elementStorageFilePath: string;
  public elementStorageFileName: string;

  public elementStorageFile: string;

  public elementScoringDetect: number;
  public elementScoringClass: number;
  public elementQuality: number;

  public elementGeometry: GeometryModel;

  public isConfirmed: boolean;
  public isDuplicate: boolean;
  public isRelevant: boolean;
  public isReclassified: boolean;
  public needValidation: boolean;
  public isPrimary: boolean;
  public isQualified: boolean;

  public view: ObjectViewModel;
  public viewBbox: BboxObject;
  public viewIdentifier: string;
  public elementNetwork: string;

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.elementGeometry) {
      this.elementGeometry = new GeometryModel().deserialize(input.elementGeometry);
    }

    if (input.elementClassDetail) {
      this.elementClassDetail = new ElementClassModel().deserialize(input.elementClassDetail);

      // load default properties
      this.elementProperties = JsonUtils.merge(this.elementProperties, this.elementClassDetail.classProperties);
      this.elementProperties["ui"] = this.elementClassDetail.classProperties["ui"];
      this.elementProperties["layout"] = this.elementClassDetail.classProperties["layout"];
    }

    if (input.view) {
      this.view = new ObjectViewModel().deserialize(input.view);
    }

    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
