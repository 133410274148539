<div class="ui-g sticky" style="padding-right: 10px;">
    <div class="ui-g-1 titleButton">
    <i class="fa fa-arrow-left" (click)="show(false)"></i>
    </div>
    <div class="ui-g-10">
    <h1>{{ 'retroreflexionEntry.title' | translate }}</h1>
    </div>
    <div class="ui-g-1 titleButton">
    <i class="fa fa-eraser" [ngClass]="{'itemUnchecked':_isShowElement, 'itemDisabled': !_isShowElement}" (click)="clear()"></i>
    </div>
</div>

<div class="title">{{ 'common.yearRange' | translate }}</div>
<div class="custom-slider3 yearsSlider">
    <ng5-slider  [value]="selectedYearMinValue" [highValue]="selectedYearHighValue" [options]="options" (userChangeEnd)="updateYearValues($event)"></ng5-slider>
</div>

<div class="title">{{ 'common.qualityRange' | translate }}</div>
<div class="custom-slider4 qualitySlider">
    <ng5-slider [value]="selectedMinQuality" [highValue]="selectedHighQuality" (userChangeEnd)="updateQualityValues($event)" [options]="qualitySliderOptions"></ng5-slider>
</div>

<div class="title">{{ 'retroreflexionEntry.retroreflexionEntryPanel.distanceWork' | translate }}</div>
<div class="custom-slider3 yearsSlider">
    <ng5-slider [value]="selectedDistance" (userChangeEnd)="updateDistanceValues($event)"  [options]="distanceliderOptions"></ng5-slider>
</div>

<div>
  <div class="title">{{ 'common.networkFilter' | translate }}</div>
  <p-chips [(ngModel)]="selectedNetworks" ></p-chips>
</div>

<p-dataView [value]="typeAxes" layout="grid" layout="grid">
    <p-header class="headerDataView">{{ 'retroreflexionEntry.retroreflexionEntryPanel.axisType' | translate }}</p-header>
    <ng-template let-axes pTemplate="gridItem">
    <div style="padding:.5em" class="ui-g-6">
        <p-panel [header]="axes.name" [style]="{'text-align':'center', 'font-size':'10px'}">
        <img src="assets/axe/{{axes.code}}.png" width="70">
        <hr class="ui-widget-content" style="border-top:0">
        <p-radioButton name="groupAxe" [value]="axes.class" [(ngModel)]="selectedTypeAxe"></p-radioButton>
        </p-panel>
    </div>
    </ng-template>
</p-dataView>
