import {ISerializable} from '../iserializable';
import {SelectItem} from "primeng/api";

export class ListValueModel implements  ISerializable, SelectItem {

  valueCode: string;
  valueText: string;
  valueParameters: any;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }

  toString(): string {
    return this.valueText;
  }

  /* SelectItem interface */
  get label(): string {
    return this.valueText;
  }

  get value(): string {
    return this.valueCode;
  }
}
