import {ISerializable} from '../iserializable';
import {ClassModel} from './class-model';
import {MediaConfigModel} from "./mediaConfig.model";

export class ClassCollectionModel implements ISerializable {

  public classList: ClassModel[];
  public mediaConfig: MediaConfigModel;

  deserialize(input: any): this {
    Object.assign(this, input);

    this.classList = input.classList.map(item => new ClassModel().deserialize(item));
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
