import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(private http: HttpClient) { }


  getDefaultDomain(){
    return this.http.get<{defaultDomain:number}>(environment.urlGetDefaultDomain);
  }

  
  setDefautDomain(domain){
    return this.http.put(environment.urlSetDefaultDomain,{defaultDomain:domain})
  }
  
  setCurrentDomain(domain){
    return this.http.post(environment.urlSetCurrentDomain(domain),{})
  }


}
