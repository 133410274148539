import {Component, Input, OnInit} from '@angular/core';
import {MapComponent} from "../../map/map.component";
import * as mapboxgl from "mapbox-gl";
import {SpiderifyHelper} from "../../../helpers/spiderify.helper";
import {ElementTypesEnum} from "../../../enums/element-types-enum.enum";
import {environment} from "../../../../environments/environment";
import {Marker} from "mapbox-gl";
import { Subscription} from "rxjs";


@Component({
  selector: 'app-spiderify',
  template: ''
})
export class SpiderifyComponent implements OnInit {


  @Input() mapComponent: MapComponent;
  private allElements: any;
  private elementMarkers = [];
  showSign: boolean;

  constructor(private spiderifyHelper: SpiderifyHelper) {
  }

  SOURCE = "elements";

  subscriptions: Subscription[] = [];

  SPIDER_TYPE = "marker"; // marker: use Mapbox's Marker. layer: Use a Mabpbox point layer
  MAX_LEAVES_TO_SPIDERIFY = 255; // Max leave to display when spiderify to prevent filling the map with leaves
  CIRCLE_TO_SPIRAL_SWITCHOVER = this.SPIDER_TYPE.toLowerCase() === "marker" ? 10 : 15; // When below number, will display leave as a circle. Over, as a spiral
  CIRCLE_OPTIONS = {
    distanceBetweenPoints: 50
  };
  SPIRAL_OPTIONS = {
    rotationsModifier: 1250, // Higher modifier = closer spiral lines
    distanceBetweenPoints: this.SPIDER_TYPE.toLowerCase() === "marker" ? 42 : 32, // Distance between points in spiral
    radiusModifier: 50000, // Spiral radius
    lengthModifier: 1000 // Spiral length modifier
  };

  SPIDER_LEGS = true;
  SPIDER_LEGS_LAYER_NAME = "";
  SPIDER_LEGS_LAYER_NAME_ARRAY = [];
  SPIDER_LEGS_PAINT_OPTION = {
    "line-width": 3,
    "line-color": "rgba(128, 128, 128, 0.5)"
  };

  SPIDER_LEAVES_LAYER_NAME = `spider-leaves-${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  clusterMarkers = [];
  spiderifiedCluster = {};
  spiderLeavesCollection = [];
  currentClusterSpiderified: number;

  ngOnInit() {
    this.subscriptions.push(this.spiderifyHelper.currentMessage.subscribe(allElements => {
      this.allElements = allElements;
      if (this.allElements) {
        this.SOURCE = this.allElements.features[0].properties.snapshotIdentifier;
        this.onSpidify(this.allElements);
        this.clearSpiderifiedCluster();
      }
    }));
    this.subscriptions.push(this.spiderifyHelper.currentDelete.subscribe(deleteElements => {
      if (deleteElements) {
        this.removeSourceAndLayer();
        this.clearSpiderifiedCluster();
      }
    }));
    this.currentClusterSpiderified = -1;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

  }

  clearSpierifiedMarkers() {
    if (this.clusterMarkers.length > 0) {
      for (let i = 0; i < this.clusterMarkers.length; i++) {
        this.clusterMarkers[i].remove();
      }
    }
    this.clusterMarkers = [];
    this.elementMarkers = [];
  }

  removeSourceAndLayerSpiderLegsAndLeaves(map, id) {
    if (map.getLayer(id) != null) map.removeLayer(id);
    if (map.getSource(id) != null) map.removeSource(id);
  }

  removeSourceAndLayer() {
    if (this.mapComponent.map.getLayer("clusters") !== undefined) {
      this.mapComponent.map.removeLayer("clusters");
    }
    if (this.mapComponent.map.getLayer("cluster-count") !== undefined) {
      this.mapComponent.map.removeLayer("cluster-count");
    }
    if (this.mapComponent.map.getLayer("unclustered-point") !== undefined) {
      this.mapComponent.map.removeLayer("unclustered-point");
    }
    if (this.mapComponent.map.getSource(this.SOURCE) !== undefined) {
      this.mapComponent.map.removeSource(this.SOURCE);
    }
  }

  clearSpiderifiedCluster() {
    this.spiderifiedCluster = undefined;
    // this.currentClusterSpiderified = undefined;
    this.spiderLeavesCollection = [];
    this.SPIDER_LEGS_LAYER_NAME_ARRAY.forEach((e) => {
      this.removeSourceAndLayerSpiderLegsAndLeaves(this.mapComponent.map, e);
    });
    this.removeSourceAndLayerSpiderLegsAndLeaves(this.mapComponent.map, this.SPIDER_LEAVES_LAYER_NAME);
    this.clearSpierifiedMarkers();
    this.SPIDER_LEGS_LAYER_NAME_ARRAY = [];
  }

  generateEquidistantPointsInCircle({totalPoints = 1, options = this.CIRCLE_OPTIONS}) {
    let points = [];
    let theta = (Math.PI * 2) / totalPoints;
    let angle = theta;
    for (let i = 0; i < totalPoints; i++) {
      angle = theta * i;
      points.push({
        x: options.distanceBetweenPoints * Math.cos(angle),
        y: options.distanceBetweenPoints * Math.sin(angle)
      });
    }
    return points;
  }

  generateEquidistantPointsInSpiral({totalPoints = 10, options = this.SPIRAL_OPTIONS}) {
    let points = [{x: 0, y: 0}];
    // Higher modifier = closer spiral lines
    const rotations = totalPoints * options.rotationsModifier;
    const distanceBetweenPoints = options.distanceBetweenPoints;
    const radius = totalPoints * options.radiusModifier;
    // Value of theta corresponding to end of last coil
    const thetaMax = rotations * 2 * Math.PI;
    // How far to step away from center for each side.
    const awayStep = radius / thetaMax;
    for (
      let theta = distanceBetweenPoints / awayStep;
      points.length <= totalPoints + options.lengthModifier;
    ) {
      points.push({
        x: Math.cos(theta) * (awayStep * theta),
        y: Math.sin(theta) * (awayStep * theta)
      });
      theta += distanceBetweenPoints / (awayStep * theta);
    }
    return points.slice(0, totalPoints);
  }

  generateLeavesCoordinates({nbOfLeaves}) {
    let points;
    // Position cluster's leaves in circle if below threshold, spiral otherwise
    if (nbOfLeaves < this.CIRCLE_TO_SPIRAL_SWITCHOVER) {
      points = this.generateEquidistantPointsInCircle({
        totalPoints: nbOfLeaves
      });
    } else {
      points = this.generateEquidistantPointsInSpiral({
        totalPoints: nbOfLeaves
      });
    }
    return points;
  }

  spiderifyCluster({map, source, clusterToSpiderify}) {
    let spiderlegsCollection = [];
    map
      .getSource(source)
      .getClusterLeaves(
        clusterToSpiderify.id,
        this.MAX_LEAVES_TO_SPIDERIFY,
        0,
        (error, features) => {
          if (error) {
            return;
          }
          let leavesCoordinates = this.generateLeavesCoordinates({
            nbOfLeaves: features.length
          });
          let clusterXY = map.project(clusterToSpiderify.coordinates);
          this.SPIDER_LEGS_LAYER_NAME = `spider-legs-${Math.random()}`;
          this.SPIDER_LEGS_LAYER_NAME_ARRAY.push(this.SPIDER_LEGS_LAYER_NAME);
          // Generate spiderlegs and leaves coordinates
          features.forEach((element, index) => {
            let spiderLeafLatLng = map.unproject([
              clusterXY.x + leavesCoordinates[index].x,
              clusterXY.y + leavesCoordinates[index].y
            ]);

            if (this.SPIDER_TYPE.toLowerCase() === "marker") {
              let elementMarker = document.createElement('div');
              elementMarker.className = 'marker-' + ElementTypesEnum.ELEMENT_TYPE_SIGN;
              elementMarker.style.backgroundImage = 'url(' + environment.urlMedia + "/v3/icon/sign-fr/element/" + element.properties.elementName.toUpperCase() + '.png)';
              elementMarker.style.width = '28px';
              elementMarker.style.height = '28px';
              elementMarker.style.backgroundRepeat = 'no-repeat';
              elementMarker.style.backgroundSize = 'contain';

              elementMarker.addEventListener('click', (event) => {
                this.elementMarkers.forEach(marker => {
                  marker.style.width = '28px';
                  marker.style.height = '28px';
                });
                event.preventDefault();
                this.spiderifyHelper.onShowElement(element.properties.elementIdentifier);
                elementMarker.style.width = '45px';
                elementMarker.style.height = '45px';
              }, false);

              elementMarker.addEventListener('touchstart', (event) => {
                this.elementMarkers.forEach(marker => {
                  marker.style.width = '28px';
                  marker.style.height = '28px';
                });
                event.preventDefault();
                this.spiderifyHelper.onShowElement(element.properties.elementIdentifier);
                elementMarker.style.width = '45px';
                elementMarker.style.height = '45px';
              }, false);

              let marker: Marker = new mapboxgl.Marker(elementMarker);
              marker.setLngLat(spiderLeafLatLng);

              this.elementMarkers.push(elementMarker);
              const markerRef = marker.addTo(this.mapComponent.map);
              this.clusterMarkers.push(
                markerRef
              );
            }

            if (this.SPIDER_LEGS) {
              spiderlegsCollection.push({
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    clusterToSpiderify.coordinates,
                    [spiderLeafLatLng.lng, spiderLeafLatLng.lat]
                  ]
                }
              });
            }
          });

          // Draw spiderlegs and leaves coordinates
          if (this.SPIDER_LEGS) {
            map.addLayer({
              id: this.SPIDER_LEGS_LAYER_NAME,
              type: "line",
              source: {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: spiderlegsCollection
                }
              },
              paint: this.SPIDER_LEGS_PAINT_OPTION
            });
          }

          if (this.SPIDER_TYPE.toLowerCase() === "marker") {
            this.clusterMarkers.forEach(marker => marker.addTo(map));
          }
        }
      );
  }

  onSpidify(featureCollection) {

    this.removeSourceAndLayer();
    if (featureCollection) {
      var colors = ['#53ae5d', '#ff6d10', '#ff00ff', '#e3dfdc'];
      var featureCollectionWithColor = {
        type: "FeatureCollection",
        features: []
      };
      var featureArray = [];
      featureCollection.features.forEach(function (feature) {
        if (feature.properties.elementQuality > 80) {
          feature.properties.colorprop = colors[0];
        } else if (feature.properties.elementQuality < 30) {
          feature.properties.colorprop = colors[2];
        } else {
          feature.properties.colorprop = colors[1]
        }
        featureArray.push(feature);
      });
      featureCollectionWithColor.features = featureArray;

      let a: any = {
        type: "geojson",
        data: featureCollectionWithColor,
        cluster: true,
        clusterMaxZoom: 18, // Max zoom to cluster points on
        clusterRadius: 30, // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties: {
          "sum-r": ["+", ["at", 0, ["to-rgba", ["get", "colorprop"]]]],
          "sum-g": ["+", ["at", 1, ["to-rgba", ["get", "colorprop"]]]],
          "sum-b": ["+", ["at", 2, ["to-rgba", ["get", "colorprop"]]]]
        }
      }
      this.mapComponent.map.addSource(this.SOURCE, a);

      this.mapComponent.map.addLayer({
        id: "clusters",
        type: "circle",
        source: this.SOURCE,
        filter: ["has", "point_count"],
        paint: {
          "circle-color": ["rgb",
            ["/", ["get", "sum-r"], ["get", "point_count"]],
            ["/", ["get", "sum-g"], ["get", "point_count"]],
            ["/", ["get", "sum-b"], ["get", "point_count"]]],
          'circle-opacity': 1,
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            12,
            10,
            15,
            50,
            25
          ],
        }
      });

      this.mapComponent.map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: this.SOURCE,
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-size": 14,
        },
        paint: {
          "text-color": "#191919"
        }
      });

      this.mapComponent.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: this.SOURCE,
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': "#f24619",
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });
      const layers = ["clusters", "unclustered-point"];
      layers.forEach((e) => {
        this.onShowLayers(e);
      });

      this.mapComponent.map.on("click", "unclustered-point", e => {
        let element: any = this.mapComponent.map.queryRenderedFeatures(e.point, {
          layers: ["unclustered-point"]
        });
        this.spiderifyHelper.onShowElement(element[0].properties.elementIdentifier);
      });

      this.mapComponent.map.on("touchstart", "unclustered-point", e => {
        let element: any = this.mapComponent.map.queryRenderedFeatures(e.point, {
          layers: ["unclustered-point"]
        });
        this.spiderifyHelper.onShowElement(element[0].properties.elementIdentifier);
      });

      this.mapComponent.map.on("click", "clusters", e => {
        this.clearSpiderifiedCluster();
        let features: any = this.mapComponent.map.queryRenderedFeatures(e.point, {
          layers: ["clusters"]
        });
        let clusterId = features[0].properties.cluster_id;
        if (clusterId !== this.currentClusterSpiderified) {
          this.spiderifiedCluster = {
            id: clusterId,
            coordinates: features[0].geometry.coordinates
          };

          this.spiderifyCluster({
            map: this.mapComponent.map,
            source: this.SOURCE,
            clusterToSpiderify: this.spiderifiedCluster
          });
          this.currentClusterSpiderified = clusterId;
        } else {
          this.currentClusterSpiderified = undefined;
        }

      })
        .on("click", e => {
          this.mapComponent.resizeElementsMarkers();
        })
        .on("zoomstart", () => {
          this.clearSpiderifiedCluster();
        });

      this.mapComponent.map.on("touchstart", "clusters", e => {
        this.clearSpiderifiedCluster();
        let features: any = this.mapComponent.map.queryRenderedFeatures(e.point, {
          layers: ["clusters"]
        });
        let clusterId = features[0].properties.cluster_id;
        if (clusterId !== this.currentClusterSpiderified) {

          this.spiderifiedCluster = {
            id: clusterId,
            coordinates: features[0].geometry.coordinates
          };

          this.spiderifyCluster({
            map: this.mapComponent.map,
            source: this.SOURCE,
            clusterToSpiderify: this.spiderifiedCluster
          });
          this.currentClusterSpiderified = clusterId;
        } else {
          this.currentClusterSpiderified = undefined;
        }

      })
        .on("touchstart", e => {
          this.mapComponent.resizeElementsMarkers();
        })
        .on("zoomstart", () => {
          this.clearSpiderifiedCluster();
        });

    }
    //show markers on zoom>18
    this.mapComponent.map.on("zoom", () => {
      let zoom = this.mapComponent.map.getZoom();
      if (zoom > 15.5 && this.showSign) {
        this.clearSpiderifiedCluster();
        featureCollection.features.forEach((element, index) => {
          let elementMarker = document.createElement('div');
          elementMarker.className = 'marker-' + ElementTypesEnum.ELEMENT_TYPE_SIGN;
          elementMarker.style.backgroundImage = 'url(' + environment.urlMedia + "/icon/sign-fr/element/" + element.properties.elementName.toUpperCase() + '.png)';
          elementMarker.style.width = '28px';
          elementMarker.style.height = '28px';
          elementMarker.style.backgroundRepeat = 'no-repeat';
          elementMarker.style.backgroundSize = 'contain';

          elementMarker.addEventListener('click', (event) => {
            this.elementMarkers.forEach(marker => {
              marker.style.width = '28px';
              marker.style.height = '28px';
            });
            event.preventDefault();
            this.spiderifyHelper.onShowElement(element.properties.elementIdentifier);
            elementMarker.style.width = '45px';
            elementMarker.style.height = '45px';
          }, false);

          elementMarker.addEventListener('touchstart', (event) => {
            this.elementMarkers.forEach(marker => {
              marker.style.width = '28px';
              marker.style.height = '28px';
            });
            event.preventDefault();
            this.spiderifyHelper.onShowElement(element.properties.elementIdentifier);
            elementMarker.style.width = '45px';
            elementMarker.style.height = '45px';
          }, false);

          let marker: Marker = new mapboxgl.Marker(elementMarker);
          marker.setLngLat(element.geometry.coordinates);

          this.elementMarkers.push(elementMarker);
          const markerRef = marker.addTo(this.mapComponent.map);
          this.clusterMarkers.push(
            markerRef
          );
        });
      } else if (zoom <= 15.5) {
        this.clearSpiderifiedCluster();
      }

    })
  }

  onShowLayers(layer) {
    this.mapComponent.map.on("mouseenter", layer, () => (this.mapComponent.map.getCanvas().style.cursor = "pointer"));
    this.mapComponent.map.on("mouseleave", layer, () => (this.mapComponent.map.getCanvas().style.cursor = ""));
  }


}
