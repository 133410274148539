import {Component, Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {PanelBase} from './component/PanelBase';
import Matomo from "../../stats";

type PanelEventBase = {type:string}

type PushEvent<T,C extends PanelBase<T> & Component> = PanelEventBase & {
  type: "PUSH",
  data: {
    componentData : T
    component: C
  }
}

type OpenEvent<T,C extends PanelBase<T> & Component> = PanelEventBase & {
  type: "OPEN",
  data: {
    componentData : T
    component: C
  }
}

type CloseEvent = PanelEventBase &{
  type : "CLOSE"
}

type PopEvent = PanelEventBase &{
  type: "POP"
}


export type PanelEvent = OpenEvent<any, any> | PushEvent<any, any> | CloseEvent | PopEvent;

@Injectable({
  providedIn: 'root'
})
export class PanelService {
  public subjectLeft = new Subject<PanelEvent>()
  public subjectRight = new Subject<PanelEvent>()

  constructor(
  ) {
  }

  push<T,C extends(new (...params)=>PanelBase<T>)>(component: C, data: T, isRight?: boolean){
    if (isRight !== undefined) {
      this.subjectRight.next({
        type: "PUSH",
        data: {
          componentData: data,
          component
        }
      })
    } else {
      this.subjectLeft.next({
        type: "PUSH",
        data: {
          componentData: data,
          component
        }
      })
    }
  }

  pop(isRight?: boolean){
    if (isRight !== undefined) {
      this.subjectRight.next({type:"POP"})
    } else {
      this.subjectLeft.next({type:"POP"})
    }
  }

  open<T,C extends(new (...params)=>PanelBase<T>)>(component: C, data: T, isRight?: boolean){
    if (isRight !== undefined) {
      this.subjectRight.next({
        type: "OPEN",
        data: {
          componentData: data,
          component
        }
      })
    } else {
      this.subjectLeft.next({
        type: "OPEN",
        data: {
          componentData: data,
          component
        }
      })
    }
  }

  close(isRight?: boolean){
    if (isRight !== undefined) {
      Matomo.push(['trackEvent', 'Actions', 'Closed left menu'])
      this.subjectRight.next({type:"CLOSE"})
    } else {
      Matomo.push(['trackEvent', 'Actions', 'Closed right/stats menu'])
      this.subjectLeft.next({type:"CLOSE"})
    }  }
}
