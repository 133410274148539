import {Component, OnInit, ViewChild} from '@angular/core';
import {SessionService} from './services/session.service';

import {Session} from "./globals/session";
import {SpinnerComponent} from "./components/main/spinner/spinner.component";
import {ViewerComponent} from "./components/main/viewer/viewer.component";
import {MapComponent} from "./components/map/map.component";
import {GeojsonService} from "./services/geojson.service";
import {MapLayerEnum} from "./enums/map-layer-enum.enum";
import {MapSourceEnum} from "./enums/map-source-enum.enum";
import {SpiderifyComponent} from "./components/main/spiderify/spiderify.component";
import {ViewerStoreService} from './components/menu/main-panel/viewer-entry/viewer-store.service';
import {PanelService} from './shared/panel/panel.service';
import {MainPanelComponent} from './components/menu/main-panel/main-panel.component';
import {GeometryModel} from "./models/geojson/geometry-model";
import {TranslateService} from "@ngx-translate/core";
import {StatsComponent} from "./components/stats/stats.component";
import Matomo from "./stats";
import * as moment from 'moment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  @ViewChild(SpinnerComponent)
  private spinner: SpinnerComponent;

  @ViewChild(ViewerComponent)
  public viewerComponent: ViewerComponent;

  @ViewChild(MapComponent)
  public mapComponent: MapComponent;

  @ViewChild(SpiderifyComponent)
  public spiderifyComponent: SpiderifyComponent;


  constructor(public session: Session,
              private sessionService: SessionService,
              private geojsonService: GeojsonService,
              public viewerStore: ViewerStoreService,
              public translate: TranslateService,
              private panelService: PanelService) {

    const languages: string[] = ['en', 'fr', 'pt-br', 'es', 'de', 'it'];
    translate.addLangs(languages);
    translate.setDefaultLang('en');
    let locale = "en";
    const storedLocale = window.localStorage.getItem('locale');
    if (storedLocale) {
      locale = storedLocale;
    } else {
      const navigatorLanguage = navigator.language.toLowerCase();
      if (navigatorLanguage && languages.includes(navigatorLanguage)) {
        locale = navigatorLanguage;
      } else {
        const splitNavigatorLanguage = navigatorLanguage.split('-')[0];
        for (const language of languages) {
          if (splitNavigatorLanguage === language || splitNavigatorLanguage === language.split('-')[0]) {
            locale = language;
            break;
          }
        }
      }
    }
    translate.use(locale);
    window.localStorage.setItem('locale', locale);
    moment.locale(locale);
  }

  title = 'CityMap';

  snapshot = "";
  showSign: boolean = false;

  geometry: GeometryModel;

  ngOnInit(): void {
    this.snapshot = this.getUrlParam('snapshot', '');

    if (this.snapshot) {
      this.geojsonService.getSnapshotCollection(this.snapshot).subscribe(resp => {
        if (resp) {
          this.session.setWorkspace(resp.features[0].geometry);
          //showJourney
          let layout = {
            'line-cap': 'round',
            'line-join': 'round'
          };
          let paint = {
            'line-color': '#a6a6a6',
            'line-width': 5,
            'line-opacity': .8
          };
          setTimeout(() => {
            this.spinner.addOperation("AppComponent.journey");
            this.mapComponent.fitToWorkspace();
            //timeOut to avoid error not style on map
            setTimeout(() => {
              this.mapComponent.addLineLayer(MapLayerEnum.LAYER_JOURNEY, MapSourceEnum.SOURCE_JOURNEY, layout, paint, resp);
              this.spinner.removeOperation("AppComponent.journey");
            }, 500);
          }, 500);
        }
      });
    }
  }

  handleTabChange(e) {
    this.viewerStore.state.isShowViewer = !this.viewerStore.state.isShowViewer;
    this.viewerStore.state.isShowGoogle = !this.viewerStore.state.isShowGoogle;
  }

  setShowSign(e) {
    this.spiderifyComponent.showSign = e;
  }

  // get values from Url
  getUrlVars() {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      return vars[key] = value;
    });
    return vars;
  }

  // add values by default
  getUrlParam(parameter, defaultvalue) {
    let urlparameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
      urlparameter = this.getUrlVars()[parameter];
    }
    return urlparameter;
  }

  toggleMenu() {
    this.panelService.open(MainPanelComponent, null);
    Matomo.push(['trackEvent', 'Actions', 'Opened left menu'])
  }

  toggleStat() {
    this.panelService.open(StatsComponent, null, true);
    Matomo.push(['trackEvent', 'Actions', 'Opened stats'])
    this.panelService.close();
  }

}
