import { Injectable } from '@angular/core';
import { ElementClassModel } from '@mycitymagine/ts-citymagine-core/dist/models/masterdata/element-class-model';
import { Options } from 'ng5-slider';
import { Subscription } from 'rxjs';
import { Store } from 'src/app/globals/store';
import { environment } from 'src/environments/environment';

export class RiverSignState{

  elementType: string = 'RIVER';
  countSelected: number = 0;

  selectedNetworks: string[]= [];

  environment = environment;
  subscriptions: Subscription[] = [];

  classGroups: string[] = [];
  classSelected: {[key :string]:ElementClassModel} = {};

  isRelevant: boolean = true;
  isElementRemoved: boolean = true;

  selectedMinQuality: number = 0;
  selectedHighQuality: number = 100;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  selectedElement : string;
  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear;

  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };

  excludeCategories: string[] = [];
  excludeElementNames: string[] = ['USELESS'];

  callUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class RiverSignStoreService extends Store<RiverSignState>{

  constructor() {
    super(RiverSignState);
  }
}
