import {ISerializable} from "./iserializable";

export class EventModel implements ISerializable {

  public eventCode: string;
  public eventDetails: any;

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
