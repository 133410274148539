import {ISerializable} from '../iserializable';
import {ListModel} from "./list-model";

export class ListCollectionModel implements ISerializable {

  lists: ListModel[];

  deserialize(input: any): this {
    Object.assign(this, input);
    this.lists = input.lists.map(list => new ListModel().deserialize(list));

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
