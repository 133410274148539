import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {RiverSignEntryPanelComponent} from './river-sign-entry-panel/river-sign-entry-panel.component';
import {RiverSignStoreService} from './river-sign-store.service';
import {MapService} from "../../../../shared/map/map.service";
import Matomo from "../../../../stats";

@Component({
  selector: 'app-river-sign-entry',
  templateUrl: './river-sign-entry.component.html',
  styleUrls: ['./river-sign-entry.component.css']
})
export class RiverSignEntryComponent implements OnInit {

  constructor(public session: Session,
              private panelService: PanelService, private mapService: MapService,
              private riverSignStore: RiverSignStoreService) {
  }

  isSearching = false;

  openMenuRiverSign() {
    this.panelService.push(RiverSignEntryPanelComponent, null);
    Matomo.push(['trackEvent', 'Actions', 'Opened river sign menu'])
  }

  get map() {
    return this.mapService.getMap();
  }

  close(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.riverSignStore.reset();
    Matomo.push(['trackEvent', 'Actions', 'Removed river sign'])
  }

  ngOnInit(): void {
  }

}
