import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {BankEntryPanelComponent} from "./bank-entry-panel/bank-entry-panel.component";
import {BankStoreService} from "./bank-store.service";
import Matomo from "../../../../stats";

@Component({
  selector: 'app-bank-entry',
  templateUrl: './bank-entry.component.html',
  styleUrls: ['./bank-entry.component.css']
})
export class BankEntryComponent implements OnInit {

  constructor(public session: Session, public bankStore: BankStoreService, public panelService: PanelService) { }

  ngOnInit(): void {
  }

  openMenuBank(){
    this.panelService.push(BankEntryPanelComponent,null)
    Matomo.push(['trackEvent', 'Actions', 'Opened banks menu'])
  }

  close(event:Event){
    event.preventDefault();
    event.stopPropagation();
    this.bankStore.reset();
    Matomo.push(['trackEvent', 'Actions', 'Removed banks'])
  }

}
