import {Injectable} from '@angular/core';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {EventCodeEnum} from 'src/app/enums/event-code-enum.enum';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {Session} from 'src/app/globals/session';
import {WindowEventHelper} from 'src/app/helpers/window-event.helper';
import {GeojsonService} from 'src/app/services/geojson.service';
import {ProcessingService} from 'src/app/services/processing.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {RetroreflexionState, RetroreflexionStoreService} from './retroreflexion-store.service';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class RetroreflexionService {

  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }

  constructor(private session: Session,
    private processingService: ProcessingService,
    private geojsonService: GeojsonService,
    private spinnerService: SpinnerService,
    private mapService:MapService,
    private toastService: ToastService,
    private panelService: PanelService,
    private retroreflexionStore: RetroreflexionStoreService) {
      this.retroreflexionStore.applyAndWatch(this.searchRetroreflexionWrapper.bind(this));
  }

  searchRetroreflexionWrapper(state: RetroreflexionState){
    this.mapService.callWhenAvailable((_)=>this.searchRetroreflexion(state))
  }

  searchRetroreflexion(retroreflexionState: RetroreflexionState){
    let dateBegin = retroreflexionState.selectedYearMinValue.toString();
    let dateEnd = retroreflexionState.selectedYearHighValue.toString();

    // Remove Markers
    let elements = document.getElementsByClassName('marker-' + ElementTypesEnum.ELEMENT_TYPE_REFLECTO_RED);
    while (elements.length > 0) {
      elements[0].remove();
    }

    // Show Layers
    if (retroreflexionState._isShowElement === true) {
      this.map.removeLayer(MapLayerEnum.LAYER_REFLECTO + 'Line', MapSourceEnum.SOURCE_ELEMENT);
      this.map.removeLayer(MapLayerEnum.LAYER_REFLECTO + 'Point', MapSourceEnum.SOURCE_ELEMENT);
      this.spinner.addOperation("MainMenuComponent.showElementQuality");

      let tileUrl = environment.urlTilesElements;
      tileUrl = tileUrl + '?elementType=' + ElementTypesEnum.ELEMENT_TYPE_REFLECTO;
      // tileUrl = tileUrl + '&isPrimary=' + this.isPrimary + '&isRelevant=true';

      if (retroreflexionState.selectedTypeAxe) {
        tileUrl = tileUrl + '&elementClass=%' + retroreflexionState.selectedTypeAxe + '%';
        // tileUrl = tileUrl + '&elementClass=' + this.selectedTypeAxe;
      }

      if (retroreflexionState.selectedNetworks.length > 0) {
        retroreflexionState.selectedNetworks.forEach((network: string) => {
          if (network.length > 0) {
            tileUrl = tileUrl + '&elementNetworkList=' + network;
          }
        });
      }

      tileUrl = tileUrl + '&elementDateBegin=' + retroreflexionState.selectedYearMinValue + '&elementDateEnd=' + retroreflexionState.selectedYearHighValue

      let tileUrlLine = tileUrl + '&excludeElementNames=Static';
      let tileUrlPoint = tileUrl + '&elementName=Static';
      const white = ['all', ['==', ['get', 'element_quality'], 0]];
      const red = ['<', ['get', 'element_quality'], retroreflexionState.selectedMinQuality];
      const orange = ['all', ['>=', ['get', 'element_quality'], retroreflexionState.selectedMinQuality], ['<', ['get', 'element_quality'], retroreflexionState.selectedHighQuality]];
      const green = ['>=', ['get', 'element_quality'], retroreflexionState.selectedHighQuality];

      let layout = {
        'line-cap': 'round',
        'line-join': 'round'
      };

      let paint = {
        'line-width': 5,
        'line-color': [
          'case',
          white,
          '#fff6fc',
          red,
          '#dd1e37',
          orange,
          '#dd8d0a',
          green,
          '#21dd15',
          '#21dd15'
        ]
      };

      let paintPoint = {
        'circle-radius': 5,
        'circle-color': [
          'case',
          white,
          '#fff6fc',
          red,
          '#dd1e37',
          orange,
          '#dd8d0a',
          green,
          '#21dd15',
          '#21dd15'
        ]
      };
      ///:

      this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_REFLECTO + 'Line', tileUrlLine, MapSourceEnum.SOURCE_ELEMENT, MapSourceEnum.SOURCE_REFLECTO + 'Line', false, layout, paint);
      this.map.addCircleLayerTiles(MapLayerEnum.LAYER_REFLECTO + 'Point', tileUrlPoint, MapSourceEnum.SOURCE_ELEMENT, MapSourceEnum.SOURCE_REFLECTO + 'Point', false, paintPoint);

      this.map.map.on('click', MapLayerEnum.LAYER_REFLECTO + 'Line', (e) => {
        e.preventDefault();


        // const coordinates = e.features[0].geometry.coordinates.slice();
        let properties = JSON.parse(e.features[0].properties['element_properties']);
        const coordinates = [properties['GPS_Long'],properties['GPS_Lat']];
        let quality: number = e.features[0].properties['element_quality'];
        // let nameSnapshot: string = e.features[0].properties.snapshotName || '';
        let jour: string = e.features[0].properties['element_timestamp'].substr(8, 2);
        let mois: string = e.features[0].properties['element_timestamp'].substr(5, 2);
        let annee: string = e.features[0].properties['element_timestamp'].substr(0, 4);
        let datesnapshot: string = jour + '/' + mois +'/'  + annee;

        let color;
        if  (quality === 0) {
          color = '#fff6fc';
        } else if (quality < retroreflexionState.selectedMinQuality) {
          color = '#dd1e37';
        } else if (quality >= retroreflexionState.selectedMinQuality && quality < retroreflexionState.selectedHighQuality) {
          color = '#dd8d0a';
        } else {
          color = '#21dd15';
        }

        let description: string =  '<div class="tooltipQuality" style="background:'+ color + ';">' +
          ' Date du relevé : '+ datesnapshot +' <br/> ' +
          ' Indice Qualité : '+ quality;
        //
        // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        //   coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        // }
        //
        this.map.addPopup(coordinates, description);
      });
      this.map.map.on('mouseenter', MapLayerEnum.LAYER_REFLECTO + 'Line', () => {
        this.map.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.map.on('mouseleave', MapLayerEnum.LAYER_REFLECTO + 'Line', () => {
        this.map.map.getCanvas().style.cursor = 'default';
      });

      this.map.map.on('click', MapLayerEnum.LAYER_REFLECTO + 'Point', (e) => {



        e.preventDefault();

        const coordinates = [e.lngLat.lng, e.lngLat.lat];
        let quality: number = e.features[0].properties['element_quality'];
        // let nameSnapshot: string = e.features[0].properties.snapshotName || '';
        let jour: string = e.features[0].properties['element_timestamp'].substr(8, 2);
        let mois: string = e.features[0].properties['element_timestamp'].substr(5, 2);
        let annee: string = e.features[0].properties['element_timestamp'].substr(0, 4);
        let datesnapshot: string = jour + '/' + mois +'/'  + annee;

        let color;
        if  (quality === 0) {
          color = '#fff6fc';
        } else if (quality < retroreflexionState.selectedMinQuality) {
          color = '#dd1e37';
        } else if (quality >= retroreflexionState.selectedMinQuality && quality < retroreflexionState.selectedHighQuality) {
          color = '#dd8d0a';
        } else {
          color = '#21dd15';
        }

        let description: string =  '<div class="tooltipQuality" style="background:'+ color + ';">' +
          ' Date du relevé : '+ datesnapshot +' <br/> ' +
          ' Indice Qualité : '+ quality;
        // const coordinates = e.features[0].geometry.coordinates.slice();
        // const description = e.features[0].properties.description;
        //
        // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        //   coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        // }
        //
        this.map.addPopup(coordinates, description);
      });
      this.map.map.on('mouseenter', MapLayerEnum.LAYER_REFLECTO + 'Point', () => {
        this.map.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.map.on('mouseleave', MapLayerEnum.LAYER_REFLECTO + 'Point', () => {
        this.map.map.getCanvas().style.cursor = 'default';
      });

      this.spinner.removeOperation("MainMenuComponent.showElementQuality");

      // WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_QUALITY_LOADED, response.features);
    } else {

      this.map.removeLayer(MapLayerEnum.LAYER_REFLECTO + 'Line', MapSourceEnum.SOURCE_ELEMENT);
      this.map.removeLayer(MapLayerEnum.LAYER_REFLECTO + 'Point', MapSourceEnum.SOURCE_ELEMENT);
      // this.map.removeLayer(MapLayerEnum.LAYER_QUALITY_RED + "_STATIC");
      // this.map.removeLayer(MapLayerEnum.LAYER_QUALITY_WHITE + "_STATIC");
      // this.map.removeLayer(MapLayerEnum.LAYER_QUALITY_ORANGE + "_STATIC");
      // this.map.removeLayer(MapLayerEnum.LAYER_QUALITY_GREEN + "_STATIC");
      //
      // this.map.removeLayer(MapLayerEnum.LAYER_QUALITY_RED, MapSourceEnum.SOURCE_QUALITY_RED);
      // this.map.removeLayer(MapLayerEnum.LAYER_QUALITY_WHITE, MapSourceEnum.SOURCE_QUALITY_WHITE);
      // this.map.removeLayer(MapLayerEnum.LAYER_QUALITY_ORANGE, MapSourceEnum.SOURCE_QUALITY_ORANGE);
      // this.map.removeLayer(MapLayerEnum.LAYER_QUALITY_GREEN, MapSourceEnum.SOURCE_QUALITY_GREEN);

      WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_QUALITY_LOADED);
    }
    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ShowQualityElement');
    }
  }
}
