import * as turf from "@turf/turf";
import {GeometryHelper} from "./geometry.helper";
import {GeometryModel} from "../models/geojson/geometry-model";
import {GeometryTypeEnum} from "../enums/geometry-type-enum.enum";
import {ArrayHelper} from "./array.helper";
import {RoadNode} from "../linked-list/road-node";
import {LineStringHelper} from "./linestring-helper";

export class RoadNodeHelper {
    static orderByClosest(point: GeometryModel, linestringNodes: RoadNode<GeometryModel>[]): RoadNode<GeometryModel>[] {
        linestringNodes.forEach(value => value.dt.properties["tmp"] = LineStringHelper.getDistanceToPoint(point, value.dt));
        linestringNodes.sort((a, b) =>
            (a.dt.properties.tmp > b.dt.properties.tmp) ? 1 : -1);
        linestringNodes.forEach(value => delete value.dt.properties.tmp);
        return linestringNodes;
    }

    static orderByMostRight(segment: RoadNode<GeometryModel>, otherSegments: RoadNode<GeometryModel>[]) {
        return otherSegments.sort((a, b) => (LineStringHelper.l1MostRightThanl2(segment.dt, a.dt, b.dt) ? -1 : 1));
    }


}
