import {IFeatureProperties} from './ifeature-properties';
import {ISerializable} from '../iserializable';

import {AlertPropertiesModel} from "../alerting/alert-properties-model";
import {AlertAddress} from "../alerting/alert-address";

export class FeatureObjectAlertModel implements IFeatureProperties, ISerializable {

  public alertType: string;

  public alertPush: boolean;
  public alertComment: string;
  public alertPriority: string;
  public alertUrl01: string;
  public alertUrl02: string;
  public alertUrl03: string;
  public alertUrl04: string;
  public alertStorageUrl01: string;
  public alertStorageUrl02: string;
  public alertStorageUrl03: string;
  public alertStorageUrl04: string;
  public alertActive: boolean;
  public alertDateEnd: string;
  public featureType: string;
  public alertIdentifier : string;
  public alertTimestamp: string;
  public alertDateBegin: string;
  public alertCategory : string;
  public alertClosed: boolean;
  public alertImpact: string;
  public alertSpeed: number;
  public alertProperties: AlertPropertiesModel;
  public alertAddress: AlertAddress;

  public alertImageBbox: any;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
  toExcel(): any {
    let item: any = {};

    item.alertType = this.alertType;
    item.alertCategory = this.alertCategory;
    item.alertImpact = this.alertImpact;
    item.alertDateBegin = this.alertDateBegin;
    item.alertDateEnd = this.alertDateEnd;
    item.alertSpeed = this.alertProperties.alertSpeed;
    item.alertComment = this.alertComment;
    item.alertRegie = this.alertProperties.alertSpeaker;
    if (this.alertAddress !== null) {
      item.alertAddressCity = this.alertAddress.city;
      item.alertAddress = this.alertAddress.street_name;
    } else {
      item.alertAddressCity = "";
      item.alertAddress = "";
    }
    return item;
  }
}
