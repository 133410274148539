import {ISerializable} from "../iserializable";
import {GeometryModel} from "../geojson/geometry-model";

export class ObjectViewModel implements ISerializable {

  public captureIdentifier: string;
  public viewIdentifier: string;
  public viewOrder: number;
  public viewType: string;
  public viewTimestamp: string;
  public viewGeometry: GeometryModel;
  public viewOrientation: number;
  public viewProperties: object;
  public viewUrl: string;
  public viewStorageType: string;
  public viewStorageFilePath: string;
  public viewStorageFileName: string;
  public viewStorageFileSize: number;
  public viewStorageFileType: string;

  public snapshotIdentifier: string;
  public snapshotName: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
