<div class="titleMenu">
    <app-logo></app-logo>
    <a tabindex="0" role="button" (click)="close()">
        <span class="pi pi-times ng-tns-c48-2"></span>
    </a>
</div>

<ul class="subMenu" style="background: rgba(0,0,0,.05);">
    <div class="ui-g-12">
        {{ 'mainPanel.renderingLayout' | translate }}
        <span (click)="clearAll()" appWorkspaceRequired style="float: right;cursor:pointer;">
            <i class="fa fa-eraser"></i>&nbsp;
            <span>{{ 'mainPanel.clear' | translate }}</span>
        </span>
    </div>
    <li *ngIf="!isWorkspaceDefined" class="listDisclaimer">
        <span class="disclaimer">{{ 'mainPanel.disclaimer' | translate }}</span>
    </li>
    <div class="ui-g-12 ui-g-nopad">
        <app-background-entry></app-background-entry>
        <app-custom-background-entry></app-custom-background-entry>
        <app-strip-entry></app-strip-entry>
        <app-background-details-entry></app-background-details-entry>
        <app-journey-entry></app-journey-entry>
        <app-viewer-entry></app-viewer-entry>
    </div>
</ul>

<ul class="subMenu">
    <div class="ui-g-12">{{ 'mainPanel.assetManagement' | translate }}</div>
    <div class="ui-g-12 ui-g-nopad">
        <app-sign-entry></app-sign-entry>
        <app-ground-entry></app-ground-entry>
        <app-lane-entry></app-lane-entry>
        <app-barrier-entry></app-barrier-entry>
        <app-furniture-entry></app-furniture-entry>
        <app-streetlight-entry></app-streetlight-entry>
        <app-location-marker-entry></app-location-marker-entry>
        <app-helios-entry></app-helios-entry>
        <app-alert-entry></app-alert-entry>
    </div>
    <div class="ui-g-12 ui-g-nopad">
        <app-river-sign-entry></app-river-sign-entry>
        <app-bank-entry></app-bank-entry>
    </div>
</ul>


<ul class="subMenu">
    <div class="ui-g-12">{{ 'mainPanel.roadQuality' | translate }}</div>
    <div class="ui-g-12 ui-g-nopad">
        <app-conformity-entry></app-conformity-entry>
        <app-visibility-entry></app-visibility-entry>
        <app-visibility-on-demand-entry></app-visibility-on-demand-entry>
        <app-roughness-entry></app-roughness-entry>
        <app-retroreflexion-entry></app-retroreflexion-entry>
    </div>
</ul>

<ul class="subMenu">
    <div class="ui-g-12">{{ 'mainPanel.roadSafety' | translate }}</div>
    <div class="ui-g-12 ui-g-nopad">
        <app-road-fsi-entry></app-road-fsi-entry>
        <app-road-risk-entry></app-road-risk-entry>
        <app-road-flow-entry></app-road-flow-entry>
    </div>
</ul>


<ul class="subMenu" style="background: rgba(0,0,0,.05);">
    <div class="ui-g-12">
        {{ 'mainPanel.userParameters' | translate }}
    </div>
    <div class="ui-g-12 ui-g-nopad">
        <app-user-entry></app-user-entry>
        <div class="ui-g-12 ui-g-nopad listMenu" (click)="navigateDoc()">
            <i class="fa fa-circle-question"></i>
            <span> {{ 'mainPanel.help' | translate }}</span>
        </div>
    </div>
</ul>
