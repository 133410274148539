import { Injectable } from '@angular/core';
import { Options } from 'ng5-slider';
import { Store } from 'src/app/globals/store';
import { ElementClassModel } from 'src/app/models/masterData/element-class-model';

export class BarrierState{

  elementType: string = 'BARRIER';
  countSelected: number;

  selectedNetworks: string[]= [];

  classGroups: string[] = [];
  classSelected: {[key :string]:ElementClassModel} = {};

  isRelevant: boolean = true;
  isElementRemoved: boolean = true;

  selectedMinQuality: number = 0;
  selectedHighQuality: number = 100;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;
  _showClassMenu: boolean = false;

  qualitySliderOptions: Options = {
    floor: 0,
    ceil: 100,
    showOuterSelectionBars: true
  };
  selectedElement : string;
  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear;
  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };

  excludeCategories: string[] = [];
  excludeElementNames: string[] = [];

  onCreate: boolean = false;
  callUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class BarrierStoreService extends Store<BarrierState>{

  constructor() {
    super(BarrierState)
  }
}
