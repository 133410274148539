import { Component, OnInit } from '@angular/core';
import {Session} from "../../../../globals/session";
import {PanelService} from "../../../../shared/panel/panel.service";
import Matomo from "../../../../stats";
import {RoadRiskStoreService} from "./road-risk-store.service";
import {RoadRiskEntryPanelComponent} from "./road-risk-entry-panel/road-risk-entry-panel.component";

@Component({
    selector: 'app-road-risk-entry',
    templateUrl: './road-risk-entry.component.html',
    styleUrls: ['./road-risk-entry.component.css']
})
export class RoadRiskEntryComponent implements OnInit {

    constructor(
        public session          : Session
        , public roadRiskStore    : RoadRiskStoreService
        , public panelService     : PanelService
    ) { }

    ngOnInit(): void {
    }

    openMenuRoadRisk(){
        this.panelService.push(
            RoadRiskEntryPanelComponent,
            null
        )
        Matomo.push(['trackEvent', 'Actions', 'Opened roadRisk menu'])
    }

    close(event:Event){
        event.preventDefault();
        event.stopPropagation();
        this.roadRiskStore.reset();
        Matomo.push(['trackEvent', 'Actions', 'Removed roadRisk'])
    }

}
