export enum stateEnum {
  WAITING = 1 << 0,
  PROCESSING = 1 << 1,
  PROCESSING2 = 1 << 2,
  DONE = 1 << 3,
  PRIVATE = 1 << 4,
}

// Thanks to bitwise operators, one value contains multiple informations about the link.
export enum linkTypeEnum { // Type is composed by different informations :
  NONE = 0,
  END = 1 << 0,                // Next segment is linked to the end of current
  START = 1 << 1,              // Next segment is linked to the start of current
  // AND
  L_END = 1 << 2,              // Current segment is linked to end of linked segment
  L_START = 1 << 3,            // Current segment is linked to start of linked segment
  // AND
  NEXT = 1 << 4,               // Next logical segment
  PREV = 1 << 5,               // Prev logical segment
  ROUNDABOUT = 1 << 6,         // Next logical segment (enter in RA)
  INTERSECTION = 1 << 8,
  UNKNOWN = 1 << 9,
  T_JUNCTION = 1 << 10,
  CROSSROADS = 1 << 11,
}

const typeLinkMap = new Map<linkTypeEnum, linkTypeEnum>([

  [linkTypeEnum.END, linkTypeEnum.L_END],
  [linkTypeEnum.L_END, linkTypeEnum.END],

  [linkTypeEnum.START, linkTypeEnum.L_START],
  [linkTypeEnum.L_START, linkTypeEnum.START],

  [linkTypeEnum.NEXT, linkTypeEnum.PREV],
  [linkTypeEnum.PREV, linkTypeEnum.NEXT],

  [linkTypeEnum.ROUNDABOUT, linkTypeEnum.ROUNDABOUT],

  [linkTypeEnum.INTERSECTION, linkTypeEnum.INTERSECTION],
  [linkTypeEnum.T_JUNCTION, linkTypeEnum.T_JUNCTION],
  [linkTypeEnum.CROSSROADS, linkTypeEnum.CROSSROADS],

  [linkTypeEnum.UNKNOWN, linkTypeEnum.UNKNOWN]
]);

export {typeLinkMap}

export enum Side {
  RIGHT,
  LEFT
}

export class Lane<T> {
  public next: Lane<T>;
  public prev: Lane<T>;
  public dt: T;

  constructor(public side: Side, public reversed: boolean /* Is reversed according to the way we use it*/) {
  }
}

export class Axe<T> {
  public next: Axe<T>;
  public prev: Axe<T>;

  public dt: T;
}

export class RoadNode<T> {
  public next: RoadNode<T>[] = []; // Next segments same way (only one next is possible) // TODO : array type is not used (max one next for the moment)
  public prev: RoadNode<T>[] = []; // Prev segments same way (only one prev is possible) // TODO : array type is not used (max one prev for the moment)
  public links: [RoadNode<T>, linkTypeEnum][] = []; // Links to other ways / round about
  public dt: T;
  public state: stateEnum = stateEnum.WAITING;
  public privateState: stateEnum = stateEnum.WAITING;
  public recompute: boolean = false;
  public randomId: number = Math.random(); // TODO remove this debug var

  constructor(data: T) {

    this.dt = data;
  }
}

export class RoadNodeAnnotation<T> extends RoadNode<T> {
  constructor(data: T) {
    super(data);
  }

  public lanes: Lane<T>[] = [];

  getRightSide(): Lane<T> {
    return this.lanes.filter(x => x.side == Side.RIGHT)[0];
  }

  getLeftSide(): Lane<T> {
    return this.lanes.filter(x => x.side == Side.LEFT)[0];
  }

  getNextRightLane(): Lane<T> {
    return this.getRightSide().next;
  }

  getNextLeftLane(): Lane<T> {
    return this.getLeftSide().next;
  }

  addNextRight(nextNode: Lane<T>) {
    this.getRightSide().next = nextNode;
    nextNode.prev = this.getRightSide();
  }

  addPrevRight(nextNode: Lane<T>) {
    this.getRightSide().prev = nextNode;
    nextNode.next = this.getRightSide();
  }

  addNextLeft(nextNode: Lane<T>) {
    this.getLeftSide().next = nextNode;
    nextNode.prev = this.getLeftSide();
  }

  addPrevLeft(nextNode: Lane<T>) {
    this.getLeftSide().prev = nextNode;
    nextNode.next = this.getLeftSide();
  }

  addLane(lane: Lane<T>, way: string = "no way", si: string = "no way") {
    if ((lane.side == Side.RIGHT && this.getRightSide()) || (lane.side == Side.LEFT && this.getLeftSide()))
      throw "Can't add " + (lane.side == Side.RIGHT ? "Right" : "Left") + " side: Already exists\n" + "way identifier origin: " + way + "\n" + "si: " + si + "\n" + (new Error().stack);
    this.lanes.push(lane);
  }
}
