import {Component, OnInit} from '@angular/core';
import {SessionService} from "../../services/session.service";
import {Session} from "../../globals/session";
import {StringUtils} from "../../helpers/string.utils";
import {environment} from "../../../environments/environment";
import {map} from 'rxjs/operators';
import {AuthService} from '@auth0/auth0-angular';
import {AreaService} from "../../services/area.service";
import {HttpClient} from "@angular/common/http";

declare let gtag: Function;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
      private http: HttpClient,
      private authService: AuthService,
      private sessionService: SessionService,
      private areaService: AreaService,
      private session: Session) {
  }

  ngOnInit() {
    let token: string = this.getUrlParam('token', '');
    if (StringUtils.isNotEmpty(token)) {
      this.session.token = token;
      this.sessionService.getSession().pipe(map(session => {
        this.sessionService.getDomains().subscribe(domains => {
          this.session.loadSession(session);
          this.session.domains = domains,
              this.session.isLogged = true;
          this.session.isLogging = false;
          this.session.sessionLocale = this.session.currentSession.user.userLocale;
          this.session.sessionProperties = this.session.currentSession.user.userProperties;
          this.session.embedded = true;
        })
      })).subscribe()
    } else {
      this.logWithAuth0();
    }


  }

  logWithAuth0() {
    this.authService.isAuthenticated$.subscribe(isAuth => {
      if (!isAuth) {
        this.login();
      } else {
        this.authService.getAccessTokenSilently({audience: environment.auth.audience, ignoreCache: true}).subscribe(t => {
          this.session.token = t;
          this.sessionService.getSession().pipe(map(session => {
            this.sessionService.getDomains().subscribe(domains => {
              this.session.loadSession(session);
              this.session.domains = domains;
              this.session.isLogged = true;
              this.session.isLogging = false;
              this.session.sessionLocale = this.session.currentSession.user.userLocale;
              this.session.sessionProperties = this.session.currentSession.user.userProperties;
              setTimeout(() => {
                this.areaService.fetchAreas().subscribe(_areas => {
                  const userArea = this.areaService.getUserSelectedArea();
                  const geometry = userArea.geometry;
                  this.session.setWorkspace(geometry);
                });
              }, 300)
            })
          })).subscribe()
        })
      }
    })
  }

  login() {
    this.authService.loginWithRedirect({redirect_uri: window.location.href})
  }

  // get values from Url
  getUrlVars() {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      return vars[key] = value;
    });
    return vars;
  }

  // add values by default
  getUrlParam(parameter, defaultvalue) {
    let urlparameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
      urlparameter = this.getUrlVars()[parameter];
    }
    return urlparameter;
  }

}
