import {Injectable} from '@angular/core';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {EventCodeEnum} from 'src/app/enums/event-code-enum.enum';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {Session} from 'src/app/globals/session';
import {WindowEventHelper} from 'src/app/helpers/window-event.helper';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MasterDataService} from 'src/app/services/master-data.service';
import {ProcessingService} from 'src/app/services/processing.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {FurnitureState, FurnitureStoreService} from './furniture-store.service';
import {FurnitureViewPanelComponent} from './furniture-view-panel/furniture-view-panel.component';
import {MapImageEnum} from "../../../../enums/map-image-enum.enum";

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class FurnitureService {

  subscriptions = []
  clickBound = false;

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }

  constructor(public session: Session,
              private processingService: ProcessingService,
              private masterdataService: MasterDataService,
              private geojsonService: GeojsonService,
              private spinnerService: SpinnerService,
              private mapService: MapService,
              private toastService: ToastService,
              private panelService: PanelService,
              private furnitureStore: FurnitureStoreService) {
    this.furnitureStore.applyAndWatch(this.showElementWrapper.bind(this))
  }

  showElementWrapper(state: FurnitureState) {
    this.mapService.callWhenAvailable(_ => this.showElement(state))
  }

  showElement(state: FurnitureState) {
    if (state._isShowElement === true) {
      this.spinner.addOperation("MainMenuComponent.showElementFurniture");
      this.map.removeLayer(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_FURNITURE, MapSourceEnum.SOURCE_ELEMENT);

      let excludeCategories = [];
      let excludeElementNames = [];
      if (state.isElementRemoved) {
        excludeElementNames = ['USELESS'];
        // excludeElementNames = ['pphm', 'd20'];
      } else {
        excludeCategories = undefined;
        excludeElementNames = undefined;
      }

      // TODO includes class filter and exclude filter
      let tileUrl = environment.urlTilesElements;
      tileUrl = tileUrl + '?elementType=' + ElementTypesEnum.ELEMENT_TYPE_FURNITURE;
      tileUrl = tileUrl + '&isPrimary=' + true
        + '&isRelevant=' + state.isRelevant + '&isConfirmed=' + state.isRelevant + '&elementQualityMin=' + state.selectedMinQuality
        + '&elementQualityMax=' + state.selectedHighQuality;

      tileUrl = tileUrl + '&elementDateBegin=' + state.selectedYearMinValue + '&elementDateEnd=' + state.selectedYearHighValue

      let elementClassList: string[];
      const classSelected = state.classSelected;
      if (classSelected !== undefined) {
        elementClassList = [];
        Object.keys(classSelected)
          .map(key => classSelected[key])
          .forEach(element => {
            elementClassList.push(element.elementClass)
            tileUrl = tileUrl + '&elementClassList=' + element.elementClass;
          });
      }

      if (this.furnitureStore.state.selectedNetworks.length > 0) {
        this.furnitureStore.state.selectedNetworks.forEach((network: string) => {
          if (network.length > 0) {
            tileUrl = tileUrl + '&elementNetworkList=' + network;
          }
        });
      }

      let layout = {
        "icon-image": "{element_class}",
        "icon-size": 0.25,
        "icon-rotate": ["get", "bearing"],
        "icon-rotation-alignment": "map",
        "icon-allow-overlap": true,
        "icon-ignore-placement": true
      };

      this.furnitureStore.state.callUrl = tileUrl;

      this.map.addSymbolLayerTiles(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_FURNITURE, tileUrl, MapSourceEnum.SOURCE_ELEMENT, false, layout, ElementTypesEnum.ELEMENT_TYPE_SIGN);

      this._onLayerViewElement(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_FURNITURE, MapSourceEnum.SOURCE_ELEMENT);

      this.spinner.removeOperation("MainMenuComponent.showElementFurniture");

      WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_FURNITURE_LOADED);

      if (!this.clickBound) {

        this.map.map.on("click", MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_FURNITURE, e => {
          e.preventDefault();

          const layout = {
            'icon-image': MapImageEnum.ICON_CIRCLE,
            'icon-size': 0.75
          }
          this.map.addSymbolLayer(MapLayerEnum.LAYER_CIRCLE, MapSourceEnum.SOURCE_ELEMENT + MapSourceEnum.SOURCE_CIRCLE, layout,
            this.map.createSymbolLayerDataFromMapboxFeatures(e.features[0]));

          this.viewElement(e.features[0].properties['element_identifier']);
        });
        this.clickBound = true;
      }
    } else {
      WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_FURNITURE_LOADED);
      this.map.removeLayer(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_FURNITURE, MapSourceEnum.SOURCE_ELEMENT);
    }
    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ShowFurnitureElement');
    }
  }

  _onLayerViewElement(layer, source) {
    this.map.clickLayerMap(layer);
    this.subscriptions.push(this.map.onClickLayerMap.subscribe(e => {
      let feature = this.map.map.queryRenderedFeatures(e.point)?.find(e => e === source);
      if (feature) {
        this.viewElement(feature.properties.elementIdentifier);
      }
    }));
  }

  viewElement(elementIdentifier: string) {
    if (elementIdentifier !== undefined) {
      this.panelService.open(
        FurnitureViewPanelComponent,
        elementIdentifier
      )
    }
    if (environment.googleTracking) {
      gtag('event', 'ViewSignElement');
    }
  }

}
