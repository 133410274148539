import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
 name: 'truncate'
})

export class TruncatePipe implements PipeTransform {

  transform(value: string, maxLength = 40, position?: string): string {
    if (position !== undefined) {
      if (position === 'start' && value.length > maxLength) {
        return '...' + value.substring(value.length - maxLength);
      } else if (position === 'middle' && value.length > maxLength){
        let frontChar = Math.ceil(maxLength/2);
        let backChar = Math.floor(maxLength/2);
        return value.substring(0, frontChar) + '...' + value.substring(value.length - backChar);
      } else if (position === 'end' && value.length > maxLength) {
          return value.substring(0,maxLength) + '...';
      }
      return value;
    } else {
      if(value.length > maxLength){
          return value.substring(0, maxLength) + '...';
      }
      return value;
    }
  }
}
