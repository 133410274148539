import { Injectable } from '@angular/core';
import { Store } from 'src/app/globals/store';
import { GeometryModel } from 'src/app/models/geojson/geometry-model';

export class VisibilityOnDemandState{
  _isShowElement: boolean = false;
  driverHeight: number = 1.5;
  speedLimit: number = 70;
  wayWidth: number = 6;
  wayIdentifier = ''
  segments: GeometryModel[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class VisibilityOnDemandStoreService extends Store<VisibilityOnDemandState> {

  constructor() {
    super(VisibilityOnDemandState)
  }
}
