import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ListValueModel} from "../../../models/masterData/list-value-model";
import {FeatureObjectElementModel} from "../../../models/geojson/feature-object-element-model";
import {ObjectElementModel} from "../../../models/annotation/object-element-model";

@Component({
  selector: 'app-quality-selector',
  templateUrl: './quality-selector.component.html',
  styleUrls: ['./quality-selector.component.css']
})
export class QualitySelectorComponent implements OnInit {

  _data: ObjectElementModel;

  @Output() dataChange = new EventEmitter<ObjectElementModel>();
  @Input()
  set data(data: ObjectElementModel) {
    this._data = data;
  }
  get data(): ObjectElementModel {
    return this._data;
  }

  _qualityList: ListValueModel[];

  @Output() qualityChange = new EventEmitter<ListValueModel>();
  @Input()
  set qualityList(qualityList: ListValueModel[]) {
    this._qualityList = qualityList;
  }
  get qualityList(): ListValueModel[] {
    return this._qualityList;
  }

  constructor() { }

  ngOnInit(): void {
  }

  setNewElementQuality(quality: ListValueModel) {
    this._data.elementQuality = (quality.valueParameters.minimumQuality + quality.valueParameters.maximumQuality) / 2;
    this.dataChange.emit(this._data);
  }
}
