import {ISerializable} from '../iserializable';
import {HeaderModel} from "../header-model";
import {ElementClassModel} from "./element-class-model";

export class ElementClassCollectionModel implements ISerializable {

  public header: HeaderModel;
  public elementClasses: ElementClassModel[] = [];

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.features !== undefined) {
      this.elementClasses = input.elementClasses.map(elementClass => new ElementClassModel().deserialize(elementClass));
    }
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
