<div class="ui-g" style="padding-right: 10px;">
  <div class="ui-g-1 titleButton">
    <i class="fa fa-arrow-left" (click)="show(false)"></i>
  </div>
  <div class="ui-g-10">
    <h1>{{ 'alertEntry.alertEntryPanel.title' | translate }}</h1>
  </div>
  <div class="ui-g-1 titleButton">
    <i class="fa fa-eraser" [ngClass]="{'itemUnchecked':_isShowElement, 'itemDisabled': !_isShowElement}"
       (click)="clear()"></i>
  </div>
</div>

<div>
  <div class="title">{{ 'alertEntry.alertEntryPanel.filter' | translate }}</div>
  <div class="filterLists" style="margin-left: 10px;">
    <p-dropdown placeholder="{{ 'alertEntry.alertEntryPanel.nature' | translate }}" [options]="alertCategoryValues"
                [showClear]="true" [(ngModel)]="alertCategory" [ngModelOptions]="{standalone: true}"
                [style]="{'padding':'5px', 'margin': '5px', 'width': '90%'}"
                (ngModelChange)="showElement()"></p-dropdown>
    <p-dropdown placeholder="{{ 'alertEntry.alertEntryPanel.impact' | translate }}" [options]="alertImpactValues"
                [showClear]="true" [(ngModel)]="alertImpact" [ngModelOptions]="{standalone: true}"
                [style]="{'padding':'5px', 'margin': '5px', 'width': '90%'}"
                (ngModelChange)="showElement()"></p-dropdown>
    <div>
      <p-checkbox class="listMenuElement" name="groupname" binary="true" [(ngModel)]="alertActive"
                  label="{{ 'alertEntry.alertEntryPanel.checkbox' | translate }}" (ngModelChange)="showElement()"
                  [style]="{'margin-top': '10px'}"></p-checkbox>
    </div>
  </div>
</div>

<div>
  <div class="title">{{ 'alertEntry.alertEntryPanel.periodLimit' | translate }}</div>
  <div class="flagFilters" style="margin-left: 10px;">
    <p-calendar placeholder="{{ 'common.from' | translate }}" dateFormat="dd-mm-yy" [(ngModel)]="dateBegin"
                [locale]="fr" [ngModelOptions]="{standalone: true}" [style]="{'width': '90%', 'margin-bottom': '10px'}"
                (ngModelChange)="showElement()"></p-calendar>
    <p-calendar placeholder="{{ 'common.tobis' | translate }}" dateFormat="dd-mm-yy" [(ngModel)]="dateEnd" [locale]="fr"
                [ngModelOptions]="{standalone: true}" [style]="{'width': '90%', 'margin-bottom': '10px'}"
                (ngModelChange)="showElement()"></p-calendar>
  </div>
</div>


