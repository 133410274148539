import {Injectable} from '@angular/core';
import {EventCodeEnum} from 'src/app/enums/event-code-enum.enum';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {SearchTypeEnum} from 'src/app/enums/search-type-enum.enum';
import {Session} from 'src/app/globals/session';
import {WindowEventHelper} from 'src/app/helpers/window-event.helper';
import {FeatureCollectionModel} from 'src/app/models/geojson/feature-collection-model';
import {ModelingService} from 'src/app/services/modeling.service';
import {MapService} from 'src/app/shared/map/map.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {environment} from 'src/environments/environment';
import {JourneyStoreService} from './journey-store.service';

class JourneyState {
  isShowJourneys = false
}

@Injectable({
  providedIn: 'root'
})
export class JourneyService {
  constructor(private journeyStore: JourneyStoreService,
    private spinnerService: SpinnerService,
    private mapService: MapService,
    private modelingService: ModelingService,
    private session: Session) {
    journeyStore.applyAndWatch(this.setShowJourney.bind(this))
  }

  setShowJourney(state: JourneyState) {
    this.mapService.callWhenAvailable(map => {
      if (this.session.hasRole('ROLE_MAP_SNAPSHOT_VIEW')) {
        if (state.isShowJourneys === true) {
          this.spinnerService.getSpinner().addOperation("MainMenuComponent.journey");

          let tileUrl = environment.tilesSnapshotServer;

          let layout = {
            'line-cap': 'round',
            'line-join': 'round'
          };

          let paint = {
            'line-color': '#a6a6a6',
            'line-width': 5,
            'line-opacity': .8
          };

          map.addLineVectorLayerTiles(MapLayerEnum.LAYER_JOURNEY, tileUrl, MapSourceEnum.SOURCE_LAYER_JOURNEY, MapSourceEnum.SOURCE_JOURNEY, false, layout, paint);
          this.spinnerService.getSpinner().removeOperation("MainMenuComponent.journey");

        } else {
          map.removeLayer(MapLayerEnum.LAYER_JOURNEY, MapSourceEnum.SOURCE_JOURNEY);
        }
      } else {
        if (state.isShowJourneys === true) {
          this.spinnerService.getSpinner().addOperation("MainMenuComponent.journey");

          this.modelingService.getModelWayCollection('', SearchTypeEnum.SEARCH_BY_CITY, this.session.getWorkspace()).subscribe((response: FeatureCollectionModel) => {
            if (response && response.features.length > 0) {
              let layout = {
                'line-cap': 'round',
                'line-join': 'round'
              };
              let paint = {
                'line-color': '#a6a6a6',
                'line-width': 5,
                'line-opacity': .8
              };

              map.addLineLayer(MapLayerEnum.LAYER_JOURNEY, MapSourceEnum.SOURCE_JOURNEY, layout, paint, response);
              map.moveLayerBeforeRoadLabel(MapLayerEnum.LAYER_JOURNEY);
            } else {

            }

            WindowEventHelper.sendEvent(EventCodeEnum.JOURNEY_LOADED, response.features);

            this.spinnerService.getSpinner().removeOperation("MainMenuComponent.journey");
          }
          );
        } else {
          map.removeLayer(MapLayerEnum.LAYER_JOURNEY, MapSourceEnum.SOURCE_JOURNEY);

          WindowEventHelper.sendEvent(EventCodeEnum.JOURNEY_LOADED);
        }
      }
    })
  }
}
