export enum MapImageEnum {
  IMAGE_CAMERA = 'imgCamera',
  URL_CAMERA = 'assets/images/orientation_viewer.png',
  // URL_CAMERA = 'assets/images/camera.png',

  IMAGE_LOCATION_MARKER = 'imgLocationMarker',
  IMAGE_LOCATION_MARKER_W = 'imgLocationMarker_W',
  IMAGE_LOCATION_MARKER_D = 'borne-pr-D',
  IMAGE_LOCATION_MARKER_A = 'borne-pr-A',
  IMAGE_LOCATION_MARKER_N = 'borne-pr-N',
  IMAGE_LOCATION_MARKER_M = 'borne-pr-M',
  IMAGE_LOCATION_MARKER_P = 'borne-pr-P',
  IMAGE_LOCATION_MARKER_F = 'borne-pr-F',
  URL_LOCATION_MARKER = 'assets/images/borne-pr-jaune.png',
  URL_LOCATION_MARKER_W = 'assets/images/borne-pr.png',
  URL_LOCATION_MARKER_F = 'assets/images/borne-pr-bleu.png',
  URL_LOCATION_MARKER_M = 'assets/images/borne-pr-bleu-ciel.png',


  IMAGE_unscathed_auto      = 'road_fsi_unscathed_auto'     , URL_LOCATION_unscathed_auto   = 'assets/images/road_fsi_unscathed_auto.png',
  IMAGE_unscathed_hgv       = 'road_fsi_unscathed_hgv'      , URL_LOCATION_unscathed_hgv    = 'assets/images/road_fsi_unscathed_hgv.png',
  IMAGE_unscathed_bicycle   = 'road_fsi_unscathed_bicycle'  , URL_LOCATION_unscathed_bicycle= 'assets/images/road_fsi_unscathed_bicycle.png',
  IMAGE_unscathed_moto      = 'road_fsi_unscathed_moto'     , URL_LOCATION_unscathed_moto   = 'assets/images/road_fsi_unscathed_moto.png',
  IMAGE_unscathed_scooter   = 'road_fsi_unscathed_scooter'  , URL_LOCATION_unscathed_scooter= 'assets/images/road_fsi_unscathed_scooter.png',
  IMAGE_unscathed_peds      = 'road_fsi_unscathed_peds'     , URL_LOCATION_unscathed_peds   = 'assets/images/road_fsi_unscathed_peds.png',

  IMAGE_light_auto          = 'road_fsi_light_auto'         , URL_LOCATION_light_auto       = 'assets/images/road_fsi_light_auto.png',
  IMAGE_light_hgv           = 'road_fsi_light_hgv'          , URL_LOCATION_light_hgv        = 'assets/images/road_fsi_light_hgv.png',
  IMAGE_light_bicycle       = 'road_fsi_light_bicycle'      , URL_LOCATION_light_bicycle    = 'assets/images/road_fsi_light_bicycle.png',
  IMAGE_light_moto          = 'road_fsi_light_moto'         , URL_LOCATION_light_moto       = 'assets/images/road_fsi_light_moto.png',
  IMAGE_light_scooter       = 'road_fsi_light_scooter'      , URL_LOCATION_light_scooter    = 'assets/images/road_fsi_light_scooter.png',
  IMAGE_light_peds          = 'road_fsi_light_peds'         , URL_LOCATION_light_peds       = 'assets/images/road_fsi_light_peds.png',

  IMAGE_severe_auto         = 'road_fsi_severe_auto'        , URL_LOCATION_severe_auto      = 'assets/images/road_fsi_severe_auto.png',
  IMAGE_severe_hgv          = 'road_fsi_severe_hgv'         , URL_LOCATION_severe_hgv       = 'assets/images/road_fsi_severe_hgv.png',
  IMAGE_severe_bicycle      = 'road_fsi_severe_bicycle'     , URL_LOCATION_severe_bicycle   = 'assets/images/road_fsi_severe_bicycle.png',
  IMAGE_severe_moto         = 'road_fsi_severe_moto'        , URL_LOCATION_severe_moto      = 'assets/images/road_fsi_severe_moto.png',
  IMAGE_severe_scooter      = 'road_fsi_severe_scooter'     , URL_LOCATION_severe_scooter   = 'assets/images/road_fsi_severe_scooter.png',
  IMAGE_severe_peds         = 'road_fsi_severe_peds'        , URL_LOCATION_severe_peds      = 'assets/images/road_fsi_severe_peds.png',

  IMAGE_killed_auto         = 'road_fsi_killed_auto'        , URL_LOCATION_killed_auto      = 'assets/images/road_fsi_killed_auto.png',
  IMAGE_killed_hgv          = 'road_fsi_killed_hgv'         , URL_LOCATION_killed_hgv       = 'assets/images/road_fsi_killed_hgv.png',
  IMAGE_killed_bicycle      = 'road_fsi_killed_bicycle'     , URL_LOCATION_killed_bicycle   = 'assets/images/road_fsi_killed_bicycle.png',
  IMAGE_killed_moto         = 'road_fsi_killed_moto'        , URL_LOCATION_killed_moto      = 'assets/images/road_fsi_killed_moto.png',
  IMAGE_killed_scooter      = 'road_fsi_killed_scooter'     , URL_LOCATION_killed_scooter   = 'assets/images/road_fsi_killed_scooter.png',
  IMAGE_killed_peds         = 'road_fsi_killed_peds'        , URL_LOCATION_killed_peds      = 'assets/images/road_fsi_killed_peds.png',

  IMAGE_CRASH_WHITE         = 'road_fsi_unscathed'          , URL_LOCATION_CRASH_WHITE      = 'assets/images/road_fsi_unscathed.png',
  IMAGE_CRASH_RED           = 'road_fsi_severe'             , URL_LOCATION_CRASH_RED        = 'assets/images/road_fsi_severe.png',
  IMAGE_CRASH_BLUE          = 'road_fsi_light'              , URL_LOCATION_CRASH_BLUE       = 'assets/images/road_fsi_light.png',
  IMAGE_CRASH_BLACK         = 'road_fsi_killed'             , URL_LOCATION_CRASH_BLACK      = 'assets/images/road_fsi_killed.png',

  IMAGE_HORIZONTALE = 'helios.map.event.HORIZONTALE',
  IMAGE_VERTICALE = 'helios.map.event.VERTICALE',
  IMAGE_IMPLANTATION = 'helios.map.event.IMPLANTATION',
  ICON_CIRCLE = 'icon-circle',

  URL_HELIOS_HORIZONTALE = 'assets/images/HORIZONTALE.png',
  URL_HELIOS_VERTICALE = 'assets/images/VERTICALE.png',
  URL_HELIOS_IMPLANTATION = 'assets/images/IMPLANTATION.png',
  URL_ICON_CIRCLE = 'assets/images/circle4.png',

  IMAGE_ACTIF = 'travaux-true',
  IMAGE_INACTIF = 'travaux-false',
  ELAGAGE_ACTIF = 'ELAGAGE-true',
  ELAGAGE_INACTIF = 'ELAGAGE-false',
  ACCIDENTS_ACTIF = 'ACCIDENTS-true',
  ACCIDENTS_INACTIF = 'ACCIDENTS-false',
  PROPRETE_ACTIF = 'PROPRETE-true',
  PROPRETE_INACTIF = 'PROPRETE-false',
  MANIF_ACTIF = 'MANIFESTATION-true',
  MANIF_INACTIF = 'MANIFESTATION-false',
  MAINTENANCE_ACTIF = 'MAINTENANCE-true',
  MAINTENANCE_INACTIF = 'MAINTENANCE-false',
  FAUCHAGE_ACTIF = 'FAUCHAGE-true',
  FAUCHAGE_INACTIF = 'FAUCHAGE-false',
  RESEAUX_ACTIF = 'RESEAUX-true',
  RESEAUX_INACTIF = 'RESEAUX-false',
  URL_IMAGE_ACTIF = 'assets/images/travaux_Actif.png',
  URL_IMAGE_INACTIF = 'assets/images/travaux_Inactif.png',
  URL_LOCATION_ELAGAGE_INACTIF = 'assets/images/elagage_Inactif.png',
  URL_LOCATION_ELAGAGE_ACTIF = 'assets/images/elagage_Actif.png',
  URL_LOCATION_PROPRETE_INACTIF = 'assets/images/proprete_Inactif.png',
  URL_LOCATION_PROPRETE_ACTIF = 'assets/images/proprete_Actif.png',
  URL_LOCATION_MANIF_INACTIF = 'assets/images/manifestation_Inactif.png',
  URL_LOCATION_MANIF_ACTIF = 'assets/images/manifestation_Actif.png',
  URL_LOCATION_ACCIDENTS_INACTIF = 'assets/images/accident_Inactif.png',
  URL_LOCATION_ACCIDENTS_ACTIF = 'assets/images/accident_Actif.png',
  URL_LOCATION_MAINTENANCE_INACTIF = 'assets/images/travaux_Inactif.png',
  URL_LOCATION_MAINTENANCE_ACTIF = 'assets/images/travaux_Actif.png',
  URL_LOCATION_FAUCHAGE_INACTIF = 'assets/images/fauchage_Inactif.png',
  URL_LOCATION_FAUCHAGE_ACTIF = 'assets/images/fauchage_Actif.png',
  URL_LOCATION_RESEAUX_INACTIF = 'assets/images/travaux_Inactif.png',
  URL_LOCATION_RESEAUX_ACTIF = 'assets/images/travaux_Actif.png',

  IMAGE_GPS_RGP = 'imgGpsRpg',
  IMAGE_ALERT= 'travaux.png',
  IMAGE_ALERT_CLOSED= 'travaux_Inactif.png',
  URL_GPS_RGP = 'assets/images/balise_rgp.png'
}
