import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Session} from "../globals/session";
import {GeometryModel} from "../models/geojson/geometry-model";
import {MapService} from "../shared/map/map.service";
import {Area} from "../models/area";
import {AuthService} from "@auth0/auth0-angular";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";

const SELECTED_AREA = "selectedArea";

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  areas: Area[];

  constructor(private http: HttpClient, private mapService: MapService, private session: Session, private _authService: AuthService) {
  }

  public getAreas(): Area[] {
    return this.areas;
  }

  public setAreas(areas: Area[]) {
    this.areas = areas;
  }

  /**
   * Returns user's current selected area ID, or undefined if no selected area
   */
  public getUserSelectedAreaId(): string {
    const lastSelectedAreaID = localStorage.getItem(SELECTED_AREA);
    if (!lastSelectedAreaID || lastSelectedAreaID.length == 0) {
      const firstAreaAvailable = this.areas && this.areas.length > 0 ? this.areas[0].id : undefined;
      localStorage.setItem(SELECTED_AREA, firstAreaAvailable);
      return firstAreaAvailable;
    }
    return lastSelectedAreaID;
  }

  public getUserSelectedArea(): Area {
    let userArea = this.findAreaByIdRecursive(this.areas, this.getUserSelectedAreaId());
    if (userArea) {
      return userArea;
    } else {
      localStorage.setItem(SELECTED_AREA, this.areas[0].id);
      return this.areas[0];
    }
  }

  private findAreaByIdRecursive(areas: any[], id: string) {
    if (areas == undefined || areas.length === 0) {
      return null;
    }
    let areaWithThisId = areas.find(a => a.id === id);
    if (areaWithThisId) {
      return areaWithThisId;
    }
    let oneOfChildIsTheProperOne = undefined;
    areas.forEach(n => {
      let recursive = this.findAreaByIdRecursive(n.children, id);
      if (recursive) {
        oneOfChildIsTheProperOne = recursive;
      }
    });
    return oneOfChildIsTheProperOne;


  }

  public setUserSelectedArea(id: string, type: string, coordinates: []) {
    localStorage.setItem(SELECTED_AREA, id);
    if (!type && !coordinates) {
      this.mapService.getMap().clearMap();
      return;
    }
    const geometry = new GeometryModel();
    geometry.type = type;
    geometry.coordinates = coordinates;
    this.session.setWorkspace(geometry);
  }


  fetchAreas(): Observable<Area[]> {
    return this.http.get<Area[]>(`${environment.urlApi}/v2/users/areas`).pipe(map(areas => {
      this.areas = areas;
      return areas;
    }));
  }
}
