import { Injectable } from '@angular/core';
import { Store } from 'src/app/globals/store';
import { FeatureCollectionModel } from 'src/app/models/geojson/feature-collection-model';

export class LocationMarkerState{
  isShowLocationMarker = false;
  dataGraduations: FeatureCollectionModel;
  dataGraduationTags: FeatureCollectionModel;
  locationMarkerIdDisplay: string[];
  countSelected = 0;
  isPrError: boolean = false;
  _isVisible: boolean = true;

  selectedWayTypes: string[] = ["A"];
  wayTypes: any[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class LocationMarkerStoreService extends Store<LocationMarkerState> {

  constructor() {
    super(LocationMarkerState)
  }
}
