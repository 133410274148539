<div class="ui-g sticky" style="padding-right: 10px;">
    <div class="ui-g-1 titleButton">
    <i class="fa fa-arrow-left" (click)="show(false)"></i>
    </div>
    <div class="ui-g-10">
    <h1>{{ 'heliosEntry.title' | translate }}</h1>
    </div>
    <div class="ui-g-1 titleButton">
    <i class="fa fa-eraser" [ngClass]="{'itemUnchecked':_isShowElement, 'itemDisabled': !_isShowElement}" (click)="clear()"></i>
    </div>
</div>

<div class="title">{{ 'common.yearRange' | translate }}</div>
<div class="custom-slider3 yearsSlider">
    <ng5-slider  [value]="selectedYearMinValue" [highValue]="selectedYearHighValue" [options]="options" (userChangeEnd)="updateYearValues($event)"></ng5-slider>
</div>

<div class="title">{{ 'common.networkFilter' | translate }}</div>
<p-chips [(ngModel)]="selectedNetworks" ></p-chips>

<p-dataView [value]="typeHeliosWorks" layout="grid" layout="grid">
    <p-header class="headerDataView">{{ 'heliosEntry.heliosEntryPanel.workType' | translate }}</p-header>
    <ng-template let-helios pTemplate="gridItem">
    <div class="ui-g-6">
        <p-panel [header]="helios.name"  [style]="{'text-align':'center', 'font-size':'10px'}">
        <hr class="ui-widget-content" style="border-top:0">
        <img src="assets/images/{{helios.flag}}" width="50">
        <hr class="ui-widget-content" style="border-top:5px">
        <p-radioButton name="groupcrash" [value]="helios.class" [(ngModel)]="selectedTypeHeliosWorks" ></p-radioButton>
        </p-panel>
    </div>
    </ng-template>
</p-dataView>

