import { Injectable     } from '@angular/core';
import { Store          } from 'src/app/globals/store';
import { Subscription   } from "rxjs";

export class RoadRiskState{

    _isShowElement      : boolean = false;
    _isVisible          : boolean = true;
    isPrimary           : boolean = true;

    roadTypes           : string[] = ["highways", "primary", "secondary", "tertiary", "residential", "unclassified"]     ;
    roadUser            : any[] = [];
    srsScoreMax         : number = 5;
    dataVersions        : string[] = [];
    quantiles           : string[] = ['1', '2', '3', '4', '5'];
    cutoffs             : any = {
        'VERSION_20230710_v3': {
              'SRS_AUTO'    : { '5': 0, '4': 0.00052327 , '3': 0.00056929   , '2': 0.0023736297 , '1': 0.0061250865}
            , 'SRS_MOTO'    : { '5': 0, '4': 0.00012560 , '3': 0.00016971   , '2': 0.0006640400 , '1': 0.0021511754}
            , 'SRS_BICY'    : { '5': 0, '4': 0.00007111 , '3': 0.00014292   , '2': 0.0004618200 , '1': 0.0014136769}
            , 'SRS_PEDS'    : { '5': 0, '4': 0.00007637 , '3': 0.00019791   , '2': 0.0005484300 , '1': 0.0017440966}
        }, 'default'        : {
              'SRS_AUTO'    : { '5': 0, '4':   3230.0   , '3':   6617.0     , '2':  10262.0     , '1':  13097.0}
            , 'SRS_MOTO'    : { '5': 0, '4':   9714.0   , '3':  10683.0     , '2':  16421.0     , '1':  21415.0}
            , 'SRS_BICY'    : { '5': 0, '4': 177194.0   , '3': 178962.0     , '2': 280611.0     , '1': 351336.0}
            , 'SRS_PEDS'    : { '5': 0, '4':    787.0   , '3':    826.0     , '2':   1258.0     , '1':   1707.0}
        }
    };

    selectedCutoffs     : any = {}     ;
    selectedRoadUser    : string = "SRS_AUTO";
    selectedQuantiles   : number[] = [];
    selectedSRSScores   : number[] = [];
    selectedRoadTypes   : string[] = [];
    selectedDataVersion : string = "VERSION_20230710_v3";

    subscriptions       : Subscription[] = [];

    callUrl: string;

}

@Injectable({
  providedIn: 'root'
})
export class RoadRiskStoreService extends Store<RoadRiskState> {
  constructor() {
    super(RoadRiskState)
  }
}
