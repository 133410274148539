import {ISerializable} from "../iserializable";

export class Vector3dModel implements ISerializable {

  public x: number;
  public y: number;
  public z: number;

  public properties: any;

  constructor(x: number, y: number, z: number = 0) {
    this.x = x;
    this.y = y;
    this.z = z;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toString(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }

  getCoords2d(): number[] {
    return [this.x, this.y];
  }

  clone() {
    return new Vector3dModel(this.x, this.y, this.z);
  }

  clone2d() {
    return new Vector3dModel(this.x, this.y);
  }

  add(v: Vector3dModel) {
    this.x += v.x;
    this.y += v.y;
    this.z += v.z;
  }

  addZ(z: number) {
    this.z += z;
  }

  addY(y: number) {
    this.y += y;
  }

  addX(x: number) {
    this.x += x;
  }

  addScalar(s: number) {
    this.x += s;
    this.y += s;
    this.z += s;
  }

  sub(v: Vector3dModel) {
    this.x -= v.x;
    this.y -= v.y;
    this.z -= v.z;
  }

  subScalar(s: number) {
    this.x -= s;
    this.y -= s;
    this.z -= s;
  }

  multiply(v: Vector3dModel) {
    this.x *= v.x;
    this.y *= v.y;
    this.z *= v.z;
  }

  multiplyScalar(s: number) {
    this.x *= s;
    this.y *= s;
    this.z *= s;
  }

  divide(v: Vector3dModel) {
    this.x /= v.x;
    this.y /= v.y;
    this.z /= v.z;
  }

  divideScalar(s: number) {
    this.multiplyScalar(1 / s);
  }

  lengthSq(): number {
    return this.x * this.x + this.y * this.y + this.z * this.z;
  }

  length(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
  }

  manhattanLength(): number {
    return Math.abs(this.x) + Math.abs(this.y) + Math.abs(this.z);
  }

  normalize() {
    return this.divideScalar(this.length() || 1);
  }

  setLength(length: number) {
    this.normalize();
    this.multiplyScalar(length);
  }

  distanceTo(v: Vector3dModel): number {
    return Math.sqrt(this.distanceToSquared(v));

  }

  distanceToSquared(v: Vector3dModel): number {
    const dx = this.x - v.x, dy = this.y - v.y, dz = this.z - v.z;
    return dx * dx + dy * dy + dz * dz;

  }

  distance2dTo(v: Vector3dModel): number {
    return Math.sqrt(this.distance2dToSquared(v));

  }

  distance2dToSquared(v: Vector3dModel): number {
    const dx = this.x - v.x, dy = this.y - v.y;
    return dx * dx + dy * dy;

  }

  equals(v: Vector3dModel): boolean {
    return ((v.x === this.x) && (v.y === this.y) && (v.z === this.z));
  }
}
