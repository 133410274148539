<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_LANE')"
appWorkspaceRequired
[ngClass]="{'itemChecked':this.laneStore.state._isShowElement, 'itemUnchecked': !this.laneStore.state._isShowElement}"
(click)="openMenuLane()">
<i class="fa fa-road"></i>
<span class="text">{{ 'laneEntry.title' | translate }}</span><br />
<a role="button" *ngIf="laneStore.state._isShowElement">
    <span (click)="close($event)" class="pi pi-times ng-tns-c48-2">
    </span>
</a>
</li>
