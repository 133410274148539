import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import * as CityViewer from '@mycitymagine/cityviewer-lib';
import {Session} from "../../../globals/session";
import {GeojsonService} from "../../../services/geojson.service";
import {FeatureCollectionModel} from "../../../models/geojson/feature-collection-model";
import {MapComponent} from "../../map/map.component";
import {GeometryModel} from "../../../models/geojson/geometry-model";
import {MapImageEnum} from "../../../enums/map-image-enum.enum";
import {MapLayerEnum} from "../../../enums/map-layer-enum.enum";
import {MapSourceEnum} from "../../../enums/map-source-enum.enum";
import {Subscription} from "rxjs";
import {environment} from "../../../../environments/environment";
import {MessageService} from "primeng/api";
import {ViewerService} from "../../../services/viewer.service";
import {GeometryHelper} from "../../../helpers/geometry.helper";
import {ElementModel} from "../../../models/viewer/element-model";
import {CapturePointResponseModel} from "../../../models/viewer/capture-point-response-model";
import {CaptureModel} from "../../../models/viewer/capture-model";
import {ViewModel} from "../../../models/viewer/view-model";
import {ViewTypesEnum} from "../../../enums/view-types-enum.enum";
import {ViewerStoreService} from '../../menu/main-panel/viewer-entry/viewer-store.service';
import {ObjectElementModel} from "../../../models/annotation/object-element-model";
import {AnnotationService} from "../../../services/annotation.service";
import {BboxObject} from "../../../models/detection/bbox-object";

declare let gtag: Function;

const DEFAULT_IMAGE_WIDTH = 6000;

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css']
})
export class ViewerComponent implements OnInit, OnDestroy {

  @Input() map: MapComponent;
  @ViewChild('container') div;

  @Output() visibleChange = new EventEmitter<boolean>();

  @Input()
  set visible(show: boolean) {
    console.log('show', show);
    this._visible = show;
    this.visibleChange.emit(this._visible);

    if (show) {
      if (this.viewerStore.state.geometry !== undefined) {
        this.loadCaptureGeographic(this.viewerStore.state.geometry, 20);
      }
      setTimeout(() => {
        // External events
        this.subscriptions.push(this.map.onClickLayerMap.subscribe(e => {
          if (!this._isLoadingView) {
            this._isLoadingView = !this._isLoadingView;
            if (e.features[0] !== undefined) {
              this.annotationService.getObjectElement(e.features[0].properties['element_identifier']).subscribe( (objectElement: ObjectElementModel) => {
                this.loadCaptureView(objectElement.viewIdentifier, objectElement.viewBbox);
                this.viewerStore.state.viewIdentifier = objectElement.viewIdentifier;
                this.viewerStore.state.bbox = objectElement.viewBbox;
              });
            }
          }
        }));
        this.subscriptions.push(this.map.onClickMap.subscribe(geometry => {
          // TODO Handle layer views (journeys like google maps) in next ticket)
          console.log(geometry);
          this.viewerStore.state.geometry = geometry;
          this.loadCaptureGeographic(geometry, 20);
        }));


        this.subscriptions.push(this.session.onChangeWorkspace.subscribe(geometry => {
          this.loadCaptureGeographic(geometry, 1000);
        }));

        if (this.location) {
          this._moveCamera(this.location);
        }
      }, 100)


    } else {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());

      if (this.map) {
        this.map.removeLayer(MapLayerEnum.LAYER_CAMERA, MapSourceEnum.SOURCE_CAMERA);

        this.isCameraLoaded = false;
      }
    }
  }

  get visible(): boolean {
    return this._visible;
  }

  private _visible: boolean = false;

  private location: GeometryModel;
  private mode: string = ViewTypesEnum.VIEW_PANO_360_M;

  private container: any;
  private panoPlayer: any;

  private imageUrl: string;

  private capture: CaptureModel;
  private views: ViewModel[] = [];
  private elements: ElementModel[] = [];
  private storageUrl: String;

  private isCameraLoaded: boolean = false;
  public isViewerLarge: boolean = false;
  private subscriptions: Subscription[] = [];

  private _isLoadingView: boolean = false;

  constructor(
    private session: Session,
    private geojsonService: GeojsonService,
    private annotationService: AnnotationService,
    private messageService: MessageService,
    private viewerService: ViewerService,
    private viewerStore: ViewerStoreService) {
  }

  ngOnInit() {
    // PanoPlayer
    const myTimeout = setTimeout(this.ngOnInit.bind(this), 0);
    this.container = document.getElementById('container');
    console.log('initviewer');
    if (this.container !== null) {
      clearTimeout(myTimeout);

      let panoPlayerOptions = {
        'showNavigation': true,
        'showMeasureTool': true,
        'showViewMetadata': true,
        'showCloseMe': true,
        'showCompass': true,
        'showBbox': true,
        'showAltitude': true,
        'showBboxTool': false,
        'showSettings': true,
        'showResolutionImage': true
      };
      this.panoPlayer = new CityViewer(this.container, panoPlayerOptions);

      // Events
      this.container.addEventListener('closeMe', this._sendCloseMe.bind(this), false);

      this.container.addEventListener('groundNext', this._linearNextAction.bind(this), false);
      this.container.addEventListener('groundPrevious', this._linearPreviousAction.bind(this), false);

      this.container.addEventListener('panoFront', this._panoFrontAction.bind(this), false);
      this.container.addEventListener('panoLeft', this._panoLeftAction.bind(this), false);
      this.container.addEventListener('panoRight', this._panoRightAction.bind(this), false);
      this.container.addEventListener('panoBack', this._panoBackAction.bind(this), false);
      this.container.addEventListener('panoLinearNext', this._linearNextAction.bind(this), false);
      this.container.addEventListener('panoLinearPrevious', this._linearPreviousAction.bind(this), false);

      this.container.addEventListener('imageFormatTypeChange', this._imageFormatTypeChangeAction.bind(this), false);

      this.container.addEventListener('fullscreen', this._toggleViewerSize.bind(this), false);

      // //TODO remove when citylib viewer update
      // let uselessControlBarPanolens = document.getElementById('container').children[2];
      // uselessControlBarPanolens.remove();
      // //TODO end

      this.container.addEventListener('moveViewer', this.getViewerOrientation.bind(this), false);

      this.viewerStore.applyAndWatch(state => {
        if (state.isShowViewer && state.viewIdentifier) {
          this.displayView(state.viewIdentifier, state.mode, state.bbox);
        }
      })
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.map.removeLayer(MapLayerEnum.LAYER_CAMERA, MapSourceEnum.SOURCE_CAMERA);
  }

  getViewerOrientation() {
    this.map.map.setLayoutProperty(MapLayerEnum.LAYER_CAMERA, 'icon-rotate', this.panoPlayer._getViewerDegreesOrientation() + this.capture.captureOrientation);
  }

  _moveCamera(geometry: GeometryModel) {
    let data: FeatureCollectionModel = new FeatureCollectionModel();
    data.features.push(GeometryHelper.createFeature(geometry));

    if (this.isCameraLoaded) {
      this.map.updateSourceData(MapSourceEnum.SOURCE_CAMERA, data);
      this.map.map.setLayoutProperty(MapLayerEnum.LAYER_CAMERA, 'icon-rotate', this.panoPlayer._getViewerDegreesOrientation() + this.capture.captureOrientation);
    } else {
      let layout = {
        "icon-image": MapImageEnum.IMAGE_CAMERA,
        "icon-size": 0.6,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "icon-rotate": this.panoPlayer._getViewerDegreesOrientation() + this.capture.captureOrientation
      };

      this.map.addSymbolLayer(MapLayerEnum.LAYER_CAMERA, MapSourceEnum.SOURCE_CAMERA, layout, data);
      this.isCameraLoaded = true;
    }
  }

  // Viewer
  _toggleViewerSize() {
    this.isViewerLarge = !this.isViewerLarge;
  }


  // Navigation
  _panoFrontAction() {
    const newPoint = GeometryHelper.createPoint(this.panoPlayer.getNextPosition(0));
    this.loadCaptureGeographic(newPoint, 15);

    if (environment.googleTracking) {
      gtag('event', 'panoFrontAction');
    }
  }

  _panoLeftAction() {
    const newPoint = GeometryHelper.createPoint(this.panoPlayer.getNextPosition(90));
    this.loadCaptureGeographic(newPoint, 15);

    if (environment.googleTracking) {
      gtag('event', 'panoLeftAction');
    }
  }

  _panoRightAction() {
    const newPoint = GeometryHelper.createPoint(this.panoPlayer.getNextPosition(-90));
    this.loadCaptureGeographic(newPoint, 15);

    if (environment.googleTracking) {
      gtag('event', 'panoRightAction');
    }
  }

  _panoBackAction() {
    const newPoint = GeometryHelper.createPoint(this.panoPlayer.getNextPosition(180));
    this.loadCaptureGeographic(newPoint, 15);

    if (environment.googleTracking) {
      gtag('event', 'panoBackAction');
    }
  }

  _linearNextAction() {
    this.loadCaptureLinear(1);

    if (environment.googleTracking) {
      gtag('event', 'linearNextAction');
    }
  }

  _linearPreviousAction() {
    this.loadCaptureLinear(-1);

    if (environment.googleTracking) {
      gtag('event', 'linearPreviousAction');
    }
  }

  _imageFormatTypeChangeAction() {
    this.displayCapture(this.panoPlayer.getImageFormatType());

    if (environment.googleTracking) {
      gtag('event', 'imageFormatTypeChangeAction');
    }
  }

  // View
  displayView(viewIdentifier: string, mode: string, bbox: BboxObject) {
    this.mode = mode;
    this.loadCaptureView(viewIdentifier, bbox);
  }

  displayCapture(mode: string, bbox?: BboxObject) {
    let view = this.views.find(value => value.viewType === mode);

    this._isLoadingView = false;
    if (view) {
      // View
      this.mode = view.viewType;
      this.location = this.capture.captureGeometry;
      this.imageUrl = this.storageUrl + '/' + view.viewStorageFilePath;
      this.panoPlayer.setImageUrl(this.imageUrl, this.mode, this.capture);
      this.panoPlayer.updateVehicleHeight(this.capture.captureDetails["hardwareCaptureHeight"] || 2.50);
      this._moveCamera(this.capture.captureGeometry);

      // Elements - bbox
      if (this.elements !== undefined) {
        for (let item of this.elements) {
          if (this.mode == 'GROUND_L' && item.elementType == 'GROUND') {
            this.panoPlayer.drawGroundBbox(item.viewBbox, item.elementName.toUpperCase(), this._getElementColor(item));
          } else if (this.mode != 'GROUND_L' && item.elementType != 'GROUND') {
            let options = [];
            options['color'] = this._getElementColor(item);
            options['nameColor'] = this._getElementTextColor(item);
            options['outlineWidth'] = 3;
            options['nameFont'] = "150px Arial";

            this.panoPlayer.drawPanoramaBbox(item.viewBbox, item.elementName.toUpperCase(), item, options);
          }
        }
      }
      if (!!bbox) {
        const angle = this.computeBboxAngle(bbox, view);
        this.panoPlayer.panoViewer.rotationLeft(angle);
      }
    } else {
      // this.panoPlayer.displayLoader(false);
    }
  }

  _getElementColor(element: ElementModel) {
    let color: string = '#B80C09';

    if (element.elementProperties !== undefined && element.elementProperties["ui"] !== undefined
      && element.elementProperties["ui"]["bbox_color"] !== undefined) {
      color = element.elementProperties["ui"]["bbox_color"];
    }

    return color;
  }

  _getElementTextColor(element: ElementModel) {
    let color: string = '#B80C09';

    if (element.elementProperties !== undefined && element.elementProperties["ui"] !== undefined
      && element.elementProperties["ui"]["bbox_text_color"] !== undefined) {
      color = element.elementProperties["ui"]["bbox_text_color"];
    }

    return color;
  }

  loadCaptureGeographic(geometry: GeometryModel, distance: number) {
    let point: GeometryModel;

    if (GeometryHelper.isValidPoint(geometry))
      point = geometry;
    else {
      point = GeometryHelper.getCentroid(geometry)
    }

    let excludeViewIdentifier: string = (this.capture != undefined) ? this.capture.captureIdentifier : undefined;

    // this.panoPlayer.displayLoader(true);

    this.viewerService.findNearestCapturePoint(point, distance, excludeViewIdentifier).subscribe(
    // this.viewerService.findNearestCapturePoint(point, distance).subscribe(
      (response: CapturePointResponseModel) => {
        if (response && response.capture && response.capture.captureIdentifier) {
          this.capture = response.capture;
          this.views = response.views;
          this.elements = response.elements;
          this.storageUrl = response.storageUrl;

          this.displayCapture(this.mode);
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Capture not found',
            detail: 'sorry, nothing to show'
          });
          // this.panoPlayer.displayLoader(false);
        }
      }
    );
  }

  loadCaptureLinear(orderOffset) {
    if (this.capture == undefined || this.capture.snapshot == undefined) {
      return;
    }
    // this.panoPlayer.displayLoader(true);

    this.viewerService.getCapturePoint(undefined, undefined, this.capture.snapshot.snapshotIdentifier, this.capture.captureOrder + orderOffset).subscribe(
      (response: CapturePointResponseModel) => {
        if (response && response.capture && response.capture.captureIdentifier) {
          this.capture = response.capture;
          this.views = response.views;
          this.elements = response.elements;
          this.storageUrl = response.storageUrl;

          this.displayCapture(this.mode);
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Capture not found',
            detail: 'sorry, nothing to show'
          });
          // this.panoPlayer.displayLoader(false);
        }
      }
    );
  }

  loadCaptureView(viewIdentifier, bbox: BboxObject) {
    // this.panoPlayer.displayLoader(true);

    this.viewerService.getCapturePoint(undefined, viewIdentifier, undefined, undefined).subscribe(
      (response: CapturePointResponseModel) => {
        if (response && response.capture && response.capture.captureIdentifier) {
          this.capture = response.capture;
          this.views = response.views;
          this.elements = response.elements;
          this.storageUrl = response.storageUrl;
          window['panoPlayer'] = this.panoPlayer;
          this.displayCapture(this.mode, bbox);
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Capture not found',
            detail: 'sorry, nothing to show'
          });
          // this.panoPlayer.displayLoader(false);
        }
      }
    );
  }

  _sendCloseMe() {
    const nextSate = this.viewerStore.state;
    nextSate.isShowViewer = false;
    this.viewerStore.state = nextSate;
  }

  computeBboxAngle(bbox: BboxObject, view: ViewModel): number {
    const bboxCenter = (2 * bbox.x + bbox.width) / 2;
    const imageWidth = bbox.imageWidth || DEFAULT_IMAGE_WIDTH;
    const imageCenter = imageWidth / 2;
    const distanceToCenter = bboxCenter - imageCenter;
    const angleToCenter = (distanceToCenter * 360) / imageWidth;

    const cameraOrientation = this.panoPlayer.panoViewer.getCameraOrientation();
    if (typeof cameraOrientation === "string") {
      const orientationAngle = this.orientationStringToAngle(cameraOrientation);
      return -orientationAngle + angleToCenter;
    } else {
      return -cameraOrientation + angleToCenter;
    }
  }

  private orientationStringToAngle(cameraOrientation: string) {
    switch (cameraOrientation) {
      case "FRONT":
        return 0;
      case "BACK":
        return 180
      case "RIGHT":
        return 90
      case "LEFT":
        return 270
      case "FRONT_RIGHT":
        return 45
      case "FRONT_LEFT":
        return 315;
      case "BACK_RIGHT":
        return 135;
      case "BACK_LEFT":
        return 225;
      default:
        return 0;
    }
  }
}
