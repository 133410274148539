import { Injectable } from '@angular/core';
import { Store } from 'src/app/globals/store';
import { AlertStoreService } from './alert-entry/alert-store.service';
import { BackgroundDetailsStoreService } from './background-details-entry/background-details-store.service';
import { BackgroundStoreService } from './background-entry/background-store.service';
import { BarrierStoreService } from './barrier-entry/barrier-store.service';
import { ConformityStoreService } from './conformity-entry/conformity-store.service';
import { CustomBackgroundStoreService } from './custom-background-entry/custom-background-store.service';
import { FurnitureStoreService } from './furniture-entry/furniture-store.service';
import { GroundStoreService } from './ground-entry/ground-store.service';
import { HeliosStoreService } from './helios-entry/helios-store.service';
import { JourneyStoreService } from './journey-entry/journey-store.service';
import { LaneStoreService } from './lane-entry/lane-store.service';
import { LocationMarkerStoreService } from './location-marker-entry/location-marker-store.service';
import { RetroreflexionStoreService } from './retroreflexion-entry/retroreflexion-store.service';
import { SignStoreService } from './sign-entry/sign-store.service';
import { StreetlightStoreService } from './streetlight-entry/streetlight-store.service';
import { StripStoreService } from './strip-entry/strip-store.service';
import { ViewerStoreService } from './viewer-entry/viewer-store.service';
import { VisibilityStoreService } from './visibility-entry/visibility-store.service';
import { VisibilityOnDemandStoreService } from './visibility-on-demand-entry/visibility-on-demand-store.service';
import { BankStoreService } from "./bank-entry/bank-store.service";
import { RiverSignStoreService } from './river-sign-entry/river-sign-store.service';
import { RoughnessService } from './roughness-entry/roughness.service';
import { RoughnessStoreService } from './roughness-entry/roughness-store.service';
import { RoadRiskStoreService   } from './road-risk-entry/road-risk-store.service';
import { RoadRiskService        } from './road-risk-entry/road-risk.service';
import { RoadFsiStoreService    } from './road-fsi-entry/road-fsi-store.service';
import { RoadFlowService        } from "./road-flow-entry/road-flow.service";

@Injectable({
  providedIn: 'root'
})
export class ClearService {

  constructor(
    private customBackgroundService: CustomBackgroundStoreService,
    private backgroundStoreService: BackgroundStoreService,
    private stripStoreService: StripStoreService,
    private backgroundDetailsStoreService: BackgroundDetailsStoreService,
    private ViewerStoreService: ViewerStoreService,
    private journeyStoreService: JourneyStoreService,
    private signReducer: SignStoreService,
    private groundStoreService: GroundStoreService,
    private laneStoreService: LaneStoreService,
    private bankStoreService: BankStoreService,
    private retroreflexionStoreService: RetroreflexionStoreService,
    private furnitureStoreService: FurnitureStoreService,
    private barrierStoreService: BarrierStoreService,
    private heliosStoreService: HeliosStoreService,
    private alertStoreService: AlertStoreService,
    private streetlightStoreService: StreetlightStoreService,
    private conformityStoreService: ConformityStoreService,
    private visibilityStoreService: VisibilityStoreService,
    private VisibilityOnDemandStoreService: VisibilityOnDemandStoreService,
    private roadFsiStoreService: RoadFsiStoreService,
    private locationMarkerStoreService: LocationMarkerStoreService,
    private roadRiskService: RoadRiskStoreService,
    private riverSignService: RiverSignStoreService,
    private roughnessStoreService : RoughnessStoreService,
    private roadFlowService: RoadFlowService
    ) { }

    clear(){
      Object.keys(this).forEach(k=>this[k].reset())
    }
}
