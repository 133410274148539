import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {ConformityEntryPanelComponent} from './conformity-entry-panel/conformity-entry-panel.component';
import {ConformityStoreService} from './conformity-store.service';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-conformity-entry',
  templateUrl: './conformity-entry.component.html',
  styleUrls: ['./conformity-entry.component.css']
})
export class ConformityEntryComponent implements OnInit {

  constructor(public session: Session, public conformityStore: ConformityStoreService, public panelService: PanelService) { }

  ngOnInit(): void {
  }

  openMenuConformity(){
    this.panelService.push(
      ConformityEntryPanelComponent,
      null
    )
    Matomo.push(['trackEvent', 'Actions', 'Opened conformity menu'])
  }

  get activated(){
    const state = this.conformityStore.state;
    return state.searchConformityActivated || state.searchDangerActivated;
  }

  removeConformity() {
    this.conformityStore.reset();
    Matomo.push(['trackEvent', 'Actions', 'Removed conformity elements'])
  }
}
