    <div class="ui-g" style="padding-right: 10px;">
      <div class="ui-g-1 titleButton">
        <i class="fa fa-arrow-left" (click)="show(false)"></i>
      </div>
      <div class="ui-g-11">
        <h1>{{ 'alertEntry.alertViewPanel.title' | translate }}</h1>
      </div>
    </div>
    <div class="imageContainer" *ngIf="showImages">
      <p-galleria panelWidth="300" panelHeight="200"></p-galleria>
    </div>

    <div>
      <div class="title" style="border-top: 1px solid rgba(0, 0, 0, 0.2); border-bottom: 1px solid rgba(0, 0, 0, 0.2);">{{ 'alertEntry.alertViewPanel.roadwork' | translate }}</div>
      <div class="filterLists" style="margin-left: 10px;">
        <p class="listMenuElementTitle">{{ 'alertEntry.alertViewPanel.nature' | translate }}</p>
        <p>{{category}}</p>
        <p class="listMenuElementTitle">{{ 'alertEntry.alertViewPanel.impact' | translate }}</p>
        <p>{{impact}}</p>
      </div>

      <div class="title" style="border-top: 1px solid rgba(0, 0, 0, 0.2); border-bottom: 1px solid rgba(0, 0, 0, 0.2);">{{ 'alertEntry.alertViewPanel.period' | translate }}</div>
      <div class="filterLists" style="margin-left: 10px;">
        <p>{{ 'common.from' | translate }} {{alertProperties.alertDateBegin | date:'dd-MM-y hh:mm'}} {{ 'common.tobis' | translate }}  {{alertProperties.alertDateEnd | date:'dd-MM-y hh:mm'}}</p>
        <p class="listMenuElementTitle">{{ 'alertEntry.alertViewPanel.duration' | translate }}</p>
        <label style="margin-left: 10px">{{this.screenDuration}}</label>
      </div>

      <div class="title" style="border-top: 1px solid rgba(0, 0, 0, 0.2); border-bottom: 1px solid rgba(0, 0, 0, 0.2);">{{ 'common.details' | translate }}</div>
      <div class="filterLists" style="margin-left: 10px;">
        <p class="listMenuElementTitle">{{ 'alertEntry.alertViewPanel.speedLimit' | translate }}</p>
        <p>{{speed}}</p>
        <p class="listMenuElementTitle">{{ 'alertEntry.alertViewPanel.contributor' | translate }}</p>
        <p>{{speaker}}</p>
        <p class="listMenuElementTitle">{{ 'alertEntry.alertViewPanel.linearlength' | translate }}</p>
        <label style="margin-left: 10px" *ngIf="!unity">{{this.linearLenght | number : '1.0-0'}} m</label>
        <label style="margin-left: 10px" *ngIf="unity">{{this.linearLenght | number }} km</label>
        <p class="listMenuElementTitle">{{ 'common.comment' | translate }}</p>
        <p >{{alertProperties.alertComment}}</p>
      </div>
    </div>
