import {Component, OnInit} from '@angular/core';
import {SpinnerService} from "../../../../shared/spinner/spinner.service";
import {MapService} from "../../../../shared/map/map.service";
import {ToastService} from "../../../../shared/toast/toast.service";
import {environment} from "../../../../../environments/environment";
import {PanelService} from "../../../../shared/panel/panel.service";
import {SearchStoreService} from "../search-store.service";
import {SearchService} from "../search.service";
import {TreeNode} from "primeng/api";
import {AreaService} from "../../../../services/area.service";

declare let gtag: Function;


@Component({
  selector: 'app-search-entry-panel',
  templateUrl: './search-entry-panel.component.html',
  styleUrls: ['./search-entry-panel.component.css']
})

export class SearchEntryPanelComponent implements OnInit {
  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }

  constructor(private spinnerService: SpinnerService,
              private mapService: MapService,
              private panelService: PanelService,
              private toastService: ToastService,
              private searchService: SearchService,
              private searchStore: SearchStoreService,
              private areaService: AreaService) {
  }

  data: any;
  _isVisible: boolean = true;
  startingLocationMarker: string;
  endingLocationMarker: string;
  tabIndex = 0;
  areasTree: TreeNode[];

  private selectedAreaId: string;

  ngOnInit(): void {
    Object.keys(this.searchStore.state).forEach(key => {
      this[key] = this.searchStore.state[key]
    });
    this.areasTree = this.convertAreasToTree(this.areaService.getAreas());
    this.selectedAreaId = this.areaService.getUserSelectedAreaId();
    this.recursiveTreeOpeningOnSelectedItem(this.areasTree);
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if (!show) {
      this.panelService.pop();
    }
  }

  startDrawPolygon() {
    this.map.startDrawPolygon();
    this.show(false);
    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'StartDrawPolygon');
    }
  }

  searchLocationMarkerArea() {
    const state = this.searchStore.buildStateFromComponent(this);
    state.startingLocationMarker = this.startingLocationMarker;
    state.endingLocationMarker = this.endingLocationMarker;
    state.isDrawnLocationMarker = false;
    this.searchStore.state = state;
    this.show(false);
  }

  matchSearch() {
    let locationStartArray = this.startingLocationMarker.split('-');
    if (locationStartArray.length < 3 || locationStartArray[2] === '') {
      this.endingLocationMarker = this.startingLocationMarker;
    }
  }

  private convertAreasToTree(areas: any) {
    if (!areas || areas.length == 0) {
      return [];
    }
    return areas.map(area => {

      let mappedArea = {
        label: area.name,
        key: area.id,
        data: area.geometry,
        children: this.convertAreasToTree(area.children),
        style: area.children ? "" : "padding-left:17px"
      };
      return mappedArea
    })
  }


  selectArea(node: TreeNode) {
    const isNotSameArea = this.selectedAreaId !== node.key
    this.selectedAreaId = node.key;
    this.areaService.setUserSelectedArea(node.key, node.data.type, node.data.coordinates);
    return isNotSameArea;
  }

  isAreaSelected(node: any) {
    return this.selectedAreaId === node.key;
  }

  switchToAreas() {
    this.tabIndex = 0;
  }

  recursiveTreeOpeningOnSelectedItem(nodes: TreeNode[]): boolean {
    if (nodes == null || nodes.length == 0) {
      return false;
    }
    const nodeIndex = nodes.findIndex(n => n.key === this.selectedAreaId);
    if (nodeIndex > -1) {
      return true;
    }

    let hasANodeThatIsExpanded = false;

    nodes.forEach(n => {
      let isAnyNodeExpanded = this.recursiveTreeOpeningOnSelectedItem(n.children);
      if (isAnyNodeExpanded) {
        hasANodeThatIsExpanded = true;
      }
      n.expanded = isAnyNodeExpanded;
    });
    return hasANodeThatIsExpanded;
  }
}
