import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

type ProxyOptionBase = {
  delay: number
  type: 'MULTIPLE' | 'SINGLE'
}

type ProxyOptionUnit<T extends Object> = ProxyOptionBase &  {
  key: keyof T
  type: 'SINGLE'
}

type ProxyOptionList<T extends Object> =  ProxyOptionBase &  {
  keys: (keyof T)[],
  type: 'MULTIPLE'
}

type ProxyOption<T extends Object> =  ProxyOptionUnit<T> | ProxyOptionList<T>;

type ProxyOptions<T extends Object> = Array<ProxyOption<T>>
export class Store<T extends Object>{
    private state$: BehaviorSubject<T>;
    private currentVersion = null;
    public get state(){
      return this.state$.getValue()
    }

    public set state(state:T){
      if(!Store.deepCompare(this.state, state)){
          this.state$.next(Store.deepCopy(state, this.source));
      }
    }

    public refresh(){
      this.state$.next(Store.deepCopy(this.state, this.source))
    }

    constructor(private source: new ()=>T) {
        this.state$ = new BehaviorSubject(new this.source());
    }

    watch(callback: (c:T)=>void){
      return this.state$.subscribe(callback);
    }

    private static deepCompare(a:any,b: any){
        return JSON.stringify(a) == JSON.stringify(b);
    }

    private static deepCopy<T>(obj:T,source: new ()=>any):T{
      const tmpState = new source();
      const stateCopy = JSON.parse(JSON.stringify(obj));
      Object.keys(stateCopy).forEach(k=>tmpState[k] = stateCopy[k])
      return tmpState;
    }

    public useProxy(object: Object,options?:ProxyOptions<T>){
      const delayer: {[key:string]: Subject<any>} = {}
      for(const key of Object.keys(this.state$.getValue())){
        let hasDelay = false;

        const option = options?.find(o=>o.type == 'MULTIPLE' ? o?.keys.includes(key as keyof T) : o.key == key);
        if(option){
          if(option.delay){
            delayer[key] = new Subject();
            delayer[key].pipe(
              debounceTime(option.delay),
            ).subscribe(()=>{
              this.refresh();
            })
            hasDelay = true;
          }
        }
        Object.defineProperty(object, key,{
          enumerable:true,
          get:()=> this.state$.getValue()[key],
          set:(newVal)=>{
            if(!Store.deepCompare(newVal, this.state$.getValue()[key])){
              const actualState = this.state$.getValue();
              actualState[key] = newVal;
              if(!hasDelay){
                this.refresh();
              } else {
                delayer[key].next(newVal)
              }
            }
            return true;
          }
        })
      }
    }

    reset(){
        this.state = new this.source();
    }

    public copy(){
      return Store.deepCopy(this.state$.getValue(), this.source);
    }


    applyOn(destination){
      Object.keys(this.state).forEach(key=>{
        destination[key] = this.state[key]
      })
    }

    buildStateFromComponent(component){
      const nextState = new this.source();
      Object.keys(nextState).forEach(k=>nextState[k]=component[k]);
      return nextState;
    }

  applyAndWatch(callback: (state:T)=>void){
    callback(this.state);
    return this.state$.subscribe(callback);
  }

}
