import {Component, OnInit} from '@angular/core';
import {Options} from 'ng5-slider';
import {Subscription} from 'rxjs';
import {Session} from 'src/app/globals/session';
import {LocalesHelper} from 'src/app/helpers/locales.helper';
import {FeatureModel} from 'src/app/models/geojson/feature-model';
import {ListCollectionModel} from 'src/app/models/masterData/list-collection-model';
import {ListValueModel} from 'src/app/models/masterData/list-value-model';
import {ClassModel} from 'src/app/models/processing/class-model';
import {MasterDataService} from 'src/app/services/master-data.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {AlertStoreService} from '../alert-store.service';
import Matomo from "../../../../../stats";


@Component({
  selector: 'app-alert-entry-panel',
  templateUrl: './alert-entry-panel.component.html',
  styleUrls: ['./alert-entry-panel.component.css']
})
export class AlertEntryPanelComponent implements OnInit, PanelBase<any> {
  constructor(public session: Session,
              private masterdataService: MasterDataService,
              private spinnerService: SpinnerService,
              private mapService:MapService,
              private toastService: ToastService,
              private panelService: PanelService,
              private alertStore: AlertStoreService) { }
  data: any;

  environment = environment;
  subscriptions: Subscription[] = [];

  classList: ClassModel[] = [];
  classSelectedList: ClassModel[] = [];
  isRelevant: boolean = false;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  _activeViewAlert: boolean = false;

  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear ;
  selectedTypeAlertWorks: string;
  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };
  onCreate: boolean = false;
  selectedAlert: boolean = false;
  alertActive: boolean = false;
  alertImpact: string;
  alertType: string;
  alertCategory: string;
  fr = LocalesHelper.getCalendarFr();
  dateBegin: string;
  dateEnd: string;
  alertImpactValues: ListValueModel[] = [];
  alertTypeValues: ListValueModel[] = [];
  alertCategoryValues: ListValueModel[] = [];
  active: string = '';
  features:FeatureModel[] =[];


  ngOnInit() {
    this.subscriptions.push(this.session.onChangeWorkspace.subscribe(() => {
      this.refresh();
    }));

    this.masterdataService.getLists('ALERTING').subscribe((listCollection: ListCollectionModel) => {
      listCollection.lists.forEach((list) => {
        if (list !== undefined) {
          if (list.listCode === "ALERT_TYPE") {
            this.alertTypeValues = list.listValues;
          }
        }
        if (list !== undefined) {
          if (list.listCode === "ALERT_ROADWORK") {
            this.alertCategoryValues = list.listValues;
          }
        }
        if (list !== undefined) {
          if (list.listCode === "ALERT_IMPACT") {
            this.alertImpactValues = list.listValues;
          }
        }
      });
    });
    this.subscriptions.push(this.alertStore.applyAndWatch(_=>{
      this.alertStore.applyOn(this)
    }))
    this.showElement();
  }

  showElement(){
    const state = this.alertStore.buildStateFromComponent(this)
    state._isShowElement = true;
    this.alertStore.state = state;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    // this.clear();
  }

  refresh() {
    this.alertStore.refresh();
  }

  clear() {
    // Reset filters
    this.alertStore.reset();
    this.show(false);
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if(!show){
      this.panelService.pop();
      Matomo.push(['trackEvent', 'Actions', 'Removed alert'])
    }
  }


  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }
}
