<div class="road-fsi-entry-panel">
    <div class="ui-g sticky" style="padding-right: 10px; border-bottom: 1px solid rgba(0,0,0,.2); background: #E5E5E5;">
      <div class="ui-g-1 titleButton">
        <i class="fa fa-arrow-left" (click)="show(false)"></i>
      </div>
      <div class="ui-g-11">
        <div (click)="close($event)" style="border: 1px solid rgba(0,0,0,.5); border-radius: 5px; padding: 5px 10px 5px 5px; background: rgba(0,0,0,.05); cursor: pointer; float: right; filter: drop-shadow(5px 5px 5px rgba(0,0,0,.2));">
            <i class="fa-solid fa-eye-slash"></i>
            <span style="font-size: 8pt; color: rgba(0,0,0,.75);">&nbsp;{{ 'common.clear' | translate }}</span>
        </div>
        <h1>{{ 'roadFsiEntry.title' | translate }}</h1>
      </div>
    </div>

    <div class="filters">
        <div class="filter road-fsi-density">
            <p-checkbox name="density"  [(ngModel)]="selectedDensity" [binary]=true></p-checkbox>
            Visualize accident density 
        </div>

        <!-- START / ROAD FSI USERS ---------------------------------- -->
        <div class="filter road-fsi-users">
            <p-dataView [value]="users" layout="grid">
                <p-header>
                    <span class="headerDataView" role='button' (click)="toggleVisibility('road_users')">
                        {{ 'common.filterBy' | translate }} {{ 'common.roadFsiUsers' | translate }}     
                        <i class="fa-solid fa-angle-{{isVisible.road_users ? 'down' : 'left'}}"></i>
                    </span>
                </p-header>
                <ng-template let-user let-i="rowIndex" pTemplate="gridItem">
                    <div  *ngIf="isVisible.road_users">
                        <div *ngIf="i == 0" class="ui-g-12 ui-g-nopad analytics-header">
                            <div class="ui-g-6">{{ 'common.roadFsiUsers'    | translate }}</div>
                            <div class="ui-g-2">#</div>
                            <div class="ui-g-2">%(#)</div>
                            <div class="ui-g-2">&nbsp;</div>
                        </div>
                        <div class="ui-g-12 ui-g-nopad analytics-row">
                            <div class="ui-g-1"><p-checkbox name="users" [value]="user"  [(ngModel)]="selectedUsers" [checked]="isActiveUser(user)"></p-checkbox></div>
                            <div class="ui-g-5">
                                <span *ngIf="user == 'peds'"    >{{ 'common.peds'       | translate }}</span>
                                <span *ngIf="user == 'bicycle'" >{{ 'common.bicycle'    | translate }}</span>
                                <span *ngIf="user == 'scooter'" >{{ 'common.scooter'    | translate }}</span>
                                <span *ngIf="user == 'moto'"    >{{ 'common.moto'       | translate }}</span>
                                <span *ngIf="user == 'auto'"    >{{ 'common.auto'       | translate }}</span>
                                <span *ngIf="user == 'hgv'"     >{{ 'common.hgv'        | translate }}</span>
                            </div>
                            <div class="ui-g-2"> {{ analytics.n_users[user]       }}</div>
                            <div class="ui-g-2">({{ analytics.f_users[user] * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">
                                <svg height="2ex" width="100%">
                                    <g>
                                    <rect x="0" y="0" [attr.width]="(100 * analytics.f_users[user]) || 0" height="100%" [attr.class]="'fill-' + user" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div *ngIf="i == users.length - 1" class="ui-g-12 ui-g-nopad analytics-footer">
                            <div class="ui-g-6" (click)="toggleUsers()" style="cursor: pointer; text-align: left; font-weight: 400;">
                                <span *ngIf="selectedUsers.length >  0">{{ 'common.unselectAll' | translate }}</span>
                                <span *ngIf="selectedUsers.length == 0">{{ 'common.selectAll'   | translate }}</span>
                            </div>
                            <div class="ui-g-2"> {{ analytics.n_users.total       }}</div>
                            <div class="ui-g-2">({{ analytics.f_users.total * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">&nbsp;</div>
                        </div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
        <!-- END / ROAD FSI USERS -->


        <!-- START / ROAD FSI SEVERITIES FILTER ---------------------------------------- -->
        <div class="filter road-fsi-severities">
            <p-dataView [value]="severities" layout="grid">
                <p-header>
                    <span class="headerDataView" role='button'  (click)="toggleVisibility('severities')">
                        {{ 'common.filterBy' | translate }} {{ 'common.roadFsiSeverities' | translate }}
                        <i class="fa-solid fa-angle-{{isVisible.severities ? 'down' : 'left'}}"></i>
                    </span>
                </p-header>
                <ng-template let-severity ngFor let-i="rowIndex"  pTemplate="gridItem">
                    <div  *ngIf="isVisible.severities">
                        <div *ngIf="i == 0" class="ui-g-12 ui-g-nopad analytics-header">
                            <div class="ui-g-6">{{ 'common.roadFsiSeverities'           | translate }}</div>
                            <div class="ui-g-2">#</div>
                            <div class="ui-g-2">%(#)</div>
                            <div class="ui-g-2">&nbsp;</div>
                        </div>
                        <div class="ui-g-12 ui-g-nopad  analytics-row">
                            <div class="ui-g-1"><p-checkbox name="severities" [value]="severity"  [(ngModel)]="selectedSeverities" [checked]="isActiveSeverity(severity)"></p-checkbox></div>
                            <div class="ui-g-5 color-{{severity}}">
                                <span *ngIf="severity == 'unscathed'"   >{{ 'common.unharmed'           | translate }}</span>
                                <span *ngIf="severity == 'light'"       >{{ 'common.slightlyInjured'    | translate }}</span>
                                <span *ngIf="severity == 'severe'"      >{{ 'common.seriouslyInjured'   | translate }}</span>
                                <span *ngIf="severity == 'killed'"      >{{ 'common.killed'             | translate }}</span>
                            </div>
                            <div class="ui-g-2"> {{ analytics.n_severities[severity]       }}</div>
                            <div class="ui-g-2">({{ analytics.f_severities[severity] * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">
                                <svg height="2ex" width="100%">
                                    <g>
                                    <rect x="0" y="0" [attr.width]="(100 * analytics.f_severities[''+severity]) || 0" height="100%" [attr.class]="'fill-' + severity" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div *ngIf="i == severities.length - 1" class="ui-g-12 ui-g-nopad analytics-footer">
                            <div class="ui-g-6" (click)="toggleSeverities()" style="cursor: pointer; text-align: left; font-weight: 400;">
                                <span *ngIf="selectedSeverities.length >  0">{{ 'common.unselectAll' | translate }}</span>
                                <span *ngIf="selectedSeverities.length == 0">{{ 'common.selectAll'   | translate }}</span>
                            </div>
                            <div class="ui-g-2"> {{ analytics.n_severities.total       }}</div>
                            <div class="ui-g-2">({{ analytics.f_severities.total * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">&nbsp;</div>
                        </div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
        <!-- END / ROAD FSI SEVERITIES FILTER  ------------------------------------------ -->

        <!-- START / ROAD FSI YEARS FILTER  ---------------------------------- -->
        <div class="filter road-fsi-years">
            <p-dataView [value]="years" layout="grid">
                <p-header>
                    <span class="headerDataView" role='button'  (click)="toggleVisibility('years')">
                        {{ 'common.filterBy' | translate }} {{ 'common.roadFsiYears' | translate }}     
                        <i class="fa-solid fa-angle-{{isVisible.years ? 'down' : 'left'}}"></i>
                    </span>
                </p-header>
                <ng-template let-year let-i="rowIndex" pTemplate="gridItem">
                    <div  *ngIf="isVisible.years">
                        <div *ngIf="i == 0" class="ui-g-12 ui-g-nopad analytics-header">
                            <div class="ui-g-6">{{ 'common.roadFsiYears'    | translate }}</div>
                            <div class="ui-g-2">#</div>
                            <div class="ui-g-2">%(#)</div>
                            <div class="ui-g-2">&nbsp;</div>
                        </div>
                        <div class="ui-g-12 ui-g-nopad analytics-row">
                            <div class="ui-g-1"><p-checkbox name="years" [value]="year"  [(ngModel)]="selectedYears" [checked]="isActiveYear(year)"></p-checkbox></div>
                            <div class="ui-g-5">{{ year }}</div>
                            <div class="ui-g-2"> {{ analytics.n_years[''+year]       }}</div>
                            <div class="ui-g-2">({{ analytics.f_years[''+year] * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">
                                <svg height="2ex" width="100%">
                                    <g>
                                    <rect x="0" y="0" [attr.width]="(100 * analytics.f_years[''+year]) || 0" height="100%" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div *ngIf="i == years.length - 1" class="ui-g-12 ui-g-nopad analytics-footer">
                            <div class="ui-g-6" (click)="toggleYears()" style="cursor: pointer; text-align: left; font-weight: 400;">
                                <span *ngIf="selectedYears.length >  0">{{ 'common.unselectAll' | translate }}</span>
                                <span *ngIf="selectedYears.length == 0">{{ 'common.selectAll'   | translate }}</span>
                            </div>
                            <div class="ui-g-2"> {{ analytics.n_years.total       }}</div>
                            <div class="ui-g-2">({{ analytics.f_years.total * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">&nbsp;</div>
                        </div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
        <!-- END / ROAD FSI YEARS FILTER ------------------------------------- -->

        <!-- START / Month FILTER ---------------------------------- -->
        <div class="filter road-fsi-months">
            <p-dataView [value]="months" layout="grid">
                <p-header>
                    <span class="headerDataView" role='button'  (click)="toggleVisibility('months')">
                        {{ 'common.filterBy' | translate }} {{ 'common.roadFsiMonths' | translate }}     
                        <i class="fa-solid fa-angle-{{isVisible.months ? 'down' : 'left'}}"></i>
                    </span>
                </p-header>
                <ng-template let-dow let-i="rowIndex" pTemplate="gridItem">
                    <div  *ngIf="isVisible.months">
                        <div *ngIf="i == 0" class="ui-g-12 ui-g-nopad analytics-header">
                            <div class="ui-g-6">{{ 'common.roadFsiMonths'    | translate }}</div>
                            <div class="ui-g-2">#</div>
                            <div class="ui-g-2">%(#)</div>
                            <div class="ui-g-2">&nbsp;</div>
                        </div>
                        <div class="ui-g-12 ui-g-nopad analytics-row">
                            <div class="ui-g-1"><p-checkbox name="months" [value]="months[i]"  [(ngModel)]="selectedMonths" [checked]="isActiveMonth(months[i])"></p-checkbox></div>
                            <div class="ui-g-5">
                                <span *ngIf="months[i] == '1'">{{ 'common.january'      | translate}}</span> 
                                <span *ngIf="months[i] == '2'">{{ 'common.february'     | translate}}</span> 
                                <span *ngIf="months[i] == '3'">{{ 'common.march'        | translate}}</span> 
                                <span *ngIf="months[i] == '4'">{{ 'common.april'        | translate}}</span> 
                                <span *ngIf="months[i] == '5'">{{ 'common.may'          | translate}}</span> 
                                <span *ngIf="months[i] == '6'">{{ 'common.june'         | translate}}</span> 
                                <span *ngIf="months[i] == '7'">{{ 'common.july'         | translate}}</span> 
                                <span *ngIf="months[i] == '8'">{{ 'common.august'       | translate}}</span> 
                                <span *ngIf="months[i] == '9'">{{ 'common.september'    | translate}}</span> 
                                <span *ngIf="months[i] == '10'">{{ 'common.october'     | translate}}</span> 
                                <span *ngIf="months[i] == '11'">{{ 'common.november'    | translate}}</span> 
                                <span *ngIf="months[i] == '12'">{{ 'common.december'    | translate}}</span> 
                            </div>
                            <div class="ui-g-2"> {{ analytics.n_months[months[i]]       }}</div>
                            <div class="ui-g-2">({{ analytics.f_months[months[i]] * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">
                                <svg height="2ex" width="100%">
                                    <g>
                                    <rect x="0" y="0" [attr.width]="(100 * analytics.f_months[months[i]]) || 0" height="100%" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div *ngIf="i == months.length - 1" class="ui-g-12 ui-g-nopad analytics-footer">
                            <div class="ui-g-6" (click)="toggleMonths()" style="cursor: pointer; text-align: left; font-weight: 400;">
                                <span *ngIf="selectedMonths.length >  0">{{ 'common.unselectAll' | translate }}</span>
                                <span *ngIf="selectedMonths.length == 0">{{ 'common.selectAll'   | translate }}</span>
                            </div>
                            <div class="ui-g-2"> {{ analytics.n_months.total       }}</div>
                            <div class="ui-g-2">({{ analytics.f_months.total * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">&nbsp;</div>
                        </div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
        <!-- END / Month FILTER -------------------------------------- -->

        <!-- START / DOW FILTER ---------------------------------- -->
        <div class="filter road-fsi-dows">
            <p-dataView [value]="dows" layout="grid">
                <p-header>
                    <span class="headerDataView" role='button'  (click)="toggleVisibility('DOWs')">
                        {{ 'common.filterBy' | translate }} {{ 'common.roadFsiDOWs' | translate }}     
                        <i class="fa-solid fa-angle-{{isVisible.DOWs ? 'down' : 'left'}}"></i>
                    </span>
                </p-header>
                <ng-template let-dow let-i="rowIndex" pTemplate="gridItem">
                    <div  *ngIf="isVisible.DOWs">
                        <div *ngIf="i == 0" class="ui-g-12 ui-g-nopad analytics-header">
                            <div class="ui-g-6">{{ 'common.roadFsiDOWs'    | translate }}</div>
                            <div class="ui-g-2">#</div>
                            <div class="ui-g-2">%(#)</div>
                            <div class="ui-g-2">&nbsp;</div>
                        </div>
                        <div class="ui-g-12 ui-g-nopad analytics-row">
                            <div class="ui-g-1"><p-checkbox name="dows" [value]="dows[i]"  [(ngModel)]="selectedDOWs" [checked]="isActiveDOW(dows[i])"></p-checkbox></div>
                            <div class="ui-g-5">
                                <span *ngIf="dows[i] == '1'">{{ 'common.monday'     | translate}}</span> 
                                <span *ngIf="dows[i] == '2'">{{ 'common.tuesday'    | translate}}</span> 
                                <span *ngIf="dows[i] == '3'">{{ 'common.wednesday'  | translate}}</span> 
                                <span *ngIf="dows[i] == '4'">{{ 'common.thursday'   | translate}}</span> 
                                <span *ngIf="dows[i] == '5'">{{ 'common.friday'     | translate}}</span> 
                                <span *ngIf="dows[i] == '6'">{{ 'common.saturday'   | translate}}</span> 
                                <span *ngIf="dows[i] == '7'">{{ 'common.sunday'     | translate}}</span> 
                            </div>
                            <div class="ui-g-2"> {{ analytics.n_dows[dows[i]]       }}</div>
                            <div class="ui-g-2">({{ analytics.f_dows[dows[i]] * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">
                                <svg height="2ex" width="100%">
                                    <g>
                                    <rect x="0" y="0" [attr.width]="(100 * analytics.f_dows[dows[i]]) || 0" height="100%" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div *ngIf="i == dows.length - 1" class="ui-g-12 ui-g-nopad analytics-footer">
                            <div class="ui-g-6" (click)="toggleDOWs()" style="cursor: pointer; text-align: left; font-weight: 400;">
                                <span *ngIf="selectedDOWs.length >  0">{{ 'common.unselectAll' | translate }}</span>
                                <span *ngIf="selectedDOWs.length == 0">{{ 'common.selectAll'   | translate }}</span>
                            </div>
                            <div class="ui-g-2"> {{ analytics.n_dows.total       }}</div>
                            <div class="ui-g-2">({{ analytics.f_dows.total * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">&nbsp;</div>
                        </div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
        <!-- END / DOW FILTER -------------------------------------- -->

        <!-- START / HOURS FILTER ---------------------------------- -->
        <div class="filter road-fsi-hours">
            <p-dataView [value]="hours" layout="grid">
                <p-header>
                    <span class="headerDataView" role='button'  (click)="toggleVisibility('hours')">
                        {{ 'common.filterBy' | translate }} {{ 'common.roadFsiHours' | translate }}     
                        <i class="fa-solid fa-angle-{{isVisible.hours ? 'down' : 'left'}}"></i>
                    </span>
                </p-header>
                <ng-template let-hour let-i="rowIndex" pTemplate="gridItem">
                    <div *ngIf="isVisible.hours">
                        <div *ngIf="i == 0" class="ui-g-12 ui-g-nopad analytics-header">
                            <div class="ui-g-6">{{ 'common.roadFsiHours'    | translate }}</div>
                            <div class="ui-g-2">#</div>
                            <div class="ui-g-2">%(#)</div>
                            <div class="ui-g-2">&nbsp;</div>
                        </div>
                        <div class="ui-g-12 ui-g-nopad analytics-row">
                            <div class="ui-g-1"><p-checkbox name="hours" [value]="hours[i]"  [(ngModel)]="selectedHours" [checked]="isActiveHour(hours[i])"></p-checkbox></div>
                            <div class="ui-g-5">{{ hours[i] }}</div>
                            <div class="ui-g-2"> {{ analytics.n_hours[hours[i]]       }}</div>
                            <div class="ui-g-2">({{ analytics.f_hours[hours[i]] * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">
                                <svg height="2ex" width="100%">
                                    <g>
                                    <rect x="0" y="0" [attr.width]="(100 * analytics.f_hours[hours[i]]) || 0" height="100%" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div *ngIf="hour == 5" class="ui-g-12 ui-g-nopad analytics-row" style="font-weight: 800; border-top: 1px solid #000 !important; background: #eee; margin-bottom: 1ex; border-bottom: 1px solid #ddd;">
                            <div class="ui-g-1"><!-- <p-checkbox name="daytimes" value="night"  [(ngModel)]="selectedDaytimes" [checked]="isActiveDaytime('night')"></p-checkbox>--></div>
                            <div class="ui-g-5">{{ 'night' | translate }}</div>
                            <div class="ui-g-2"> {{ analytics.n_daytimes['night']       }}</div>
                            <div class="ui-g-2">({{ analytics.f_daytimes['night'] * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">
                                <svg height="2ex" width="100%">
                                    <g>
                                    <rect x="0" y="0" [attr.width]="(100 * analytics.f_daytimes['night']) || 0" height="100%" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div *ngIf="hour == 11" class="ui-g-12 ui-g-nopad analytics-row" style="font-weight: 800; border-top: 1px solid #000 !important; background: #eee;margin-bottom: 1ex; border-bottom: 1px solid #ddd;">
                            <div class="ui-g-1"><!--<p-checkbox name="daytimes" value="morning"  [(ngModel)]="selectedDaytimes" [checked]="isActiveDaytime('morning')"></p-checkbox>--></div>
                            <div class="ui-g-5">{{ 'morning' | translate }}</div>
                            <div class="ui-g-2"> {{ analytics.n_daytimes['morning']       }}</div>
                            <div class="ui-g-2">({{ analytics.f_daytimes['morning'] * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">
                                <svg height="2ex" width="100%">
                                    <g>
                                    <rect x="0" y="0" [attr.width]="(100 * analytics.f_daytimes['morning']) || 0" height="100%" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div *ngIf="hour == 17" class="ui-g-12 ui-g-nopad analytics-row" style="font-weight: 800; border-top: 1px solid #000 !important; background: #eee;margin-bottom: 1ex; border-bottom: 1px solid #ddd;">
                            <div class="ui-g-1"><!-- <p-checkbox name="daytimes" value="afternoon"  [(ngModel)]="selectedDaytimes" [checked]="isActiveDaytime('afternoon')"></p-checkbox>--></div>
                            <div class="ui-g-5">{{ 'afternoon' | translate }}</div>
                            <div class="ui-g-2"> {{ analytics.n_daytimes['afternoon']       }}</div>
                            <div class="ui-g-2">({{ analytics.f_daytimes['afternoon'] * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">
                                <svg height="2ex" width="100%">
                                    <g>
                                    <rect x="0" y="0" [attr.width]="(100 * analytics.f_daytimes['afternoon']) || 0" height="100%" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div *ngIf="hour == 23" class="ui-g-12 ui-g-nopad analytics-row" style="font-weight: 800; border-top: 1px solid #000 !important; background: #eee;">
                            <div class="ui-g-1"><!--<p-checkbox name="daytimes" value="evening"  [(ngModel)]="selectedDaytimes" [checked]="isActiveDaytime('evening')"></p-checkbox>--></div>
                            <div class="ui-g-5">{{ 'evening' | translate }}</div>
                            <div class="ui-g-2"> {{ analytics.n_daytimes['evening']       }}</div>
                            <div class="ui-g-2">({{ analytics.f_daytimes['evening'] * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">
                                <svg height="2ex" width="100%">
                                    <g>
                                    <rect x="0" y="0" [attr.width]="(100 * analytics.f_daytimes['evening']) || 0" height="100%" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div *ngIf="i == hours.length - 1" class="ui-g-12 ui-g-nopad analytics-footer">
                            <div class="ui-g-6" (click)="toggleHours()" style="cursor: pointer; text-align: left; font-weight: 400;">
                                <span *ngIf="selectedHours.length >  0">{{ 'common.unselectAll' | translate }}</span>
                                <span *ngIf="selectedHours.length == 0">{{ 'common.selectAll'   | translate }}</span>
                            </div>
                            <div class="ui-g-2"> {{ analytics.n_hours.total       }}</div>
                            <div class="ui-g-2">({{ analytics.f_hours.total * 100 | number: '1.1-1'}}%)</div>
                            <div class="ui-g-2">&nbsp;</div>
                        </div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
        <!-- END / HOURS FILTER ------------------------------------- -->
    </div>

    <div class="ui-g-12 timings">
        {{ timings.delta_t }}ms
    </div>
</div>
