import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LngLatBoundsLike, LngLatLike } from 'mapbox-gl';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type LayerBoundBox = {
  bounds : LngLatBoundsLike,
  bbox : [[LngLatLike,LngLatLike,LngLatLike,LngLatLike,LngLatLike]]
}

@Injectable({
  providedIn: 'root'
})
export class WmsService {

  private static readonly VERSION_PARAMETER = "VERSION";
  private static readonly SERVICE_PARAMETER = "SERVICE"
  private static readonly REQUEST_PARAMETER = "REQUEST";
  private static readonly LAYERS_PARAMETER = "LAYERS";
  
  
  private static readonly WMS_CAPABILITIES_REQUEST_TYPE = "GetCapabilities";
  private static readonly WMS_GET_RESPONSE_TYPE = "text";
  private static readonly WMS_CAPABILITIES_RESPONSE_TYPE = "text/xml";
  
  private static readonly LAYERS_QUERY_SELECTOR = "WMS_Capabilities > Capability > Layer  > Layer";
  private static readonly LAYER_NAME_QUERY_SELECTOR = "Name";
  
  private static readonly LAYER_WEST_BOUND_LONGITUDE_QUERY_SELECTOR = "EX_GeographicBoundingBox > westBoundLongitude";
  private static readonly LAYER_EAST_BOUND_LONGITUDE_QUERY_SELECTOR = "EX_GeographicBoundingBox > eastBoundLongitude";
  private static readonly LAYER_NORTH_BOUND_LATITUDE_QUERY_SELECTOR = "EX_GeographicBoundingBox > northBoundLatitude";
  private static readonly LAYER_SOUTH_BOUND_LATITUDE_QUERY_SELECTOR = "EX_GeographicBoundingBox > southBoundLatitude";

  private static readonly domParser = new DOMParser();

  constructor(private httpClient: HttpClient) {}

  getBoundingBoxOfService(getMapUrl:string): Observable<LayerBoundBox>{

    const capabilitiesRequest = WmsService.buildCapabilitiesUrl(getMapUrl);

    return this.httpClient.get(capabilitiesRequest.capabilitiesUrl,{
      params: capabilitiesRequest.params,
      responseType: WmsService.WMS_GET_RESPONSE_TYPE
    }).pipe(map(xml=>WmsService.processWmsCapabilitiesResponse(xml,capabilitiesRequest.layerName)))
  }

  private static processWmsCapabilitiesResponse(wmsCapabilitiesResponse: string, initialQueriedLayerName:string){
      //Get the layer bounding box information by looking in the xml response
      const layersXml = WmsService.getLayersXml(wmsCapabilitiesResponse);
      const layerXml = WmsService.findLayer(layersXml,initialQueriedLayerName);
      return WmsService.getLayerBoundingBoxCoordinates(layerXml);
  }
  
  /**
   * Build a WMS capabilities url from a get map url and return it with the associated layer name
   * @param getMapUrl 
   */
  private static buildCapabilitiesUrl(getMapUrl){
    const url = new URL(getMapUrl);
    const hostname = url.hostname;
    const pathname = url.pathname;
    const protocol = url.protocol;
    //Normalize url parameter keys since some provided wms url have lower case and others upper case parameters names.
    const paramKeys = {}
    url.searchParams.forEach((value,key)=>paramKeys[key.toUpperCase()] = value); 
    
    const version = paramKeys[WmsService.VERSION_PARAMETER];
    const service = paramKeys[WmsService.SERVICE_PARAMETER];
    const layerName = paramKeys[WmsService.LAYERS_PARAMETER];

    const capabilitiesUrl = `${protocol}//${hostname}${pathname}`;

    return {
      capabilitiesUrl,
      params: {
        version,
        service,
        [WmsService.REQUEST_PARAMETER]: WmsService.WMS_CAPABILITIES_REQUEST_TYPE
      },
      layerName
    };
  }

  private static getLayersXml(capabilitiesXmlText: string){
    const xmlResult = WmsService.domParser.parseFromString(capabilitiesXmlText,WmsService.WMS_CAPABILITIES_RESPONSE_TYPE);
    return xmlResult.querySelectorAll(WmsService.LAYERS_QUERY_SELECTOR);
  }

  private static findLayer(layersXml:NodeListOf<Element>, layerName: string){
    return Array.from(layersXml)
            .find(layer=>
              layer
              .querySelector(WmsService.LAYER_NAME_QUERY_SELECTOR)
                .innerHTML === layerName);
  }

  private static getCoordinatesValue(target: Element, svalueQuerySelector: string){
    return parseFloat(target.querySelector(svalueQuerySelector).innerHTML);
  }

  private static getLayerBoundingBoxCoordinates(layerXml:Element): LayerBoundBox{
      const westLongitude = this.getCoordinatesValue(layerXml, WmsService.LAYER_WEST_BOUND_LONGITUDE_QUERY_SELECTOR);
      const eastLongitude = this.getCoordinatesValue(layerXml, WmsService.LAYER_EAST_BOUND_LONGITUDE_QUERY_SELECTOR);
      const southLatitude = this.getCoordinatesValue(layerXml, WmsService.LAYER_SOUTH_BOUND_LATITUDE_QUERY_SELECTOR);
      const northLatitude = this.getCoordinatesValue(layerXml, WmsService.LAYER_NORTH_BOUND_LATITUDE_QUERY_SELECTOR);
      return {
        bounds:  [
            {
              lat: southLatitude,
              lng: westLongitude
            },
            {
              lat: northLatitude,
              lng: eastLongitude
            }
          ],
          bbox: [[
            [
              westLongitude,
              northLatitude,
            ],
            [
              eastLongitude,
              northLatitude,
            ],
            [
              eastLongitude,
              southLatitude,
            ],
            [
              westLongitude,
              southLatitude,
            ],
            [
              westLongitude,
              northLatitude,
            ],
          ]]
      } 
  };
}
