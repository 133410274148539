<div class="road-flow-entry-panel">
    <div class="ui-g sticky" style="padding-right: 10px; border-bottom: 1px solid rgba(0,0,0,.2); background: #E5E5E5;">
      <div class="ui-g-1 titleButton">
        <i class="fa fa-arrow-left" (click)="show(false)"></i>
      </div>
      <div class="ui-g-11">
        <div (click)="close($event)" style="border: 1px solid rgba(0,0,0,.5); border-radius: 5px; padding: 5px 10px 5px 5px; background: rgba(0,0,0,.05); cursor: pointer; float: right; filter: drop-shadow(5px 5px 5px rgba(0,0,0,.2));">
            <i class="fa-solid fa-eye-slash"></i>
            <span style="font-size: 8pt; color: rgba(0,0,0,.75);">&nbsp;{{ 'common.clear' | translate }}</span>
        </div>
        <h1>{{ 'roadFlowEntry.title' | translate }}</h1>
      </div>
    </div>

    <div class="filters">
        <!-- START / ROAD FLOW LEVELS FILTER  ---------------------------------- -->
        <div class="filter road-flow-levels">
            <p-dataView [value]="quantiles" layout="grid">
                <p-header>
                    <span class="headerDataView">
                        {{ 'common.filterBy' | translate }} {{ 'common.roadFlowLevel' | translate }}
                        <i class="fa-solid fa-angle-down"></i>
                    </span>
                </p-header>
                <ng-template let-quantile let-i="rowIndex" pTemplate="gridItem">
                    <div *ngIf="i == 0" class="ui-g-12 ui-g-nopad analytics-header">
                        <div class="ui-g-4">{{ 'common.roadFlowLevel'    | translate }}</div>
                        <div class="ui-g-2">{{ 'common.roadFlowAADTUnit' | translate }}</div>
                        <div class="ui-g-2">km</div>
                        <div class="ui-g-2">%(km)</div>
                        <div class="ui-g-2">&nbsp;</div>
                    </div>
                    <div class="ui-g-12 ui-g-nopad analytics-row">
                        <div class="ui-g-1"><p-checkbox name="quantiles" [value]="quantile"  [(ngModel)]="selectedQuantiles" [checked]="isActiveQuantile(quantile)"></p-checkbox></div>
                        <div class="ui-g-3"><span *ngFor="let i of [].constructor(+quantile || 1)" class="fa-sharp fa-solid fa-square" iconPos="right" [ngClass]="'color-' + quantile" style="line-height: 1.5; padding-right: 1px; font-size: 6pt;"></span>
                        </div>
                        <div class="ui-g-2" style="text-align: right; font-size: 8pt;">
                            <p-inputNumber *ngIf="quantile > 0 " [(ngModel)]="selectedCutoffs['' + quantile]" (ngModelChange)="selectCutoff(quantile, $event)"></p-inputNumber>
                            <span          *ngIf="quantile == 0"> = 0</span>
                        </div>
                        <div class="ui-g-2"> {{ analytics.n_km_quantiles[''+quantile]       | number: '1.1-1'}}</div>
                        <div class="ui-g-2">({{ analytics.f_km_quantiles[''+quantile] * 100 | number: '1.1-1'}}%)</div>
                        <div class="ui-g-2">
                            <svg height="2ex" width="100%">
                                <g>
                                <rect x="0" y="0" [attr.width]="(100 * analytics.f_km_quantiles[''+quantile]) || 0" height="100%" [attr.class]="'fill-' + quantile" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div *ngIf="i == quantiles.length - 1" class="ui-g-12 ui-g-nopad analytics-footer">
                        <div class="ui-g-6" (click)="toggleQuantiles()" style="cursor: pointer; text-align: left; font-weight: 400;">
                            <span *ngIf="selectedQuantiles.length >  0">{{ 'common.unselectAll' | translate }}</span>
                            <span *ngIf="selectedQuantiles.length == 0">{{ 'common.selectAll'   | translate }}</span>
                        </div>
                        <div class="ui-g-2"> {{ analytics.n_km_quantiles.total       | number: '1.1-1'}}</div>
                        <div class="ui-g-2">({{ analytics.f_km_quantiles.total * 100 | number: '1.1-1'}}%)</div>
                        <div class="ui-g-2">&nbsp;</div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
        <!-- END / ROAD FLOW LEVELS FILTER ------------------------------------- -->

        <!-- START / ROAD TYPES FILTER  ---------------------------------------- -->
        <div class="filter road-types">
            <p-dataView [value]="roadTypeOptions" layout="grid">
                <p-header>
                    <span class="headerDataView">
                        {{ 'common.filterBy' | translate }} {{ 'common.roadType' | translate }}
                        <i class="fa-solid fa-angle-down"></i>
                    </span>
                </p-header>
                <ng-template let-roadType ngFor let-i="rowIndex"  pTemplate="gridItem">
                    <div *ngIf="i == 0" class="ui-g-12 ui-g-nopad analytics-header">
                        <div class="ui-g-6">{{ 'common.roadType'           | translate }}</div>
                        <div class="ui-g-2">km</div>
                        <div class="ui-g-2">%(km)</div>
                        <div class="ui-g-2">&nbsp;</div>
                    </div>
                    <div class="ui-g-12 ui-g-nopad  analytics-row">
                        <div class="ui-g-1"><p-checkbox name="roadtypes" [value]="roadType.code"  [(ngModel)]="selectedRoadTypes" [checked]="isActiveRoadType(roadType.code)"></p-checkbox></div>
                        <div class="ui-g-5">{{ roadType.name }}</div>
                        <div class="ui-g-2"> {{ analytics.n_km_roadTypes[roadType.code]       | number: '1.1-1'}}</div>
                        <div class="ui-g-2">({{ analytics.f_km_roadTypes[roadType.code] * 100 | number: '1.1-1'}}%)</div>
                        <div class="ui-g-2">
                            <svg height="2ex" width="100%">
                                <g>
                                <rect x="0" y="0" [attr.width]="(100 * analytics.f_km_roadTypes[roadType.code ]) || 0" height="100%" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div *ngIf="i == roadTypeOptions.length - 1" class="ui-g-12 ui-g-nopad analytics-footer">
                        <div class="ui-g-6" (click)="toggleRoadTypes()" style="cursor: pointer; text-align: left; font-weight: 400;">
                            <span *ngIf="selectedRoadTypes.length >  0">{{ 'common.unselectAll' | translate }}</span>
                            <span *ngIf="selectedRoadTypes.length == 0">{{ 'common.selectAll'   | translate }}</span>
                        </div>
                        <div class="ui-g-2"> {{ analytics.n_km_roadTypes.total       | number: '1.1-1'}}</div>
                        <div class="ui-g-2">({{ analytics.f_km_roadTypes.total * 100 | number: '1.1-1'}}%)</div>
                        <div class="ui-g-2">&nbsp;</div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
        <!-- END / ROAD TYPES FILTER  ------------------------------------------ -->
    </div>
    <br>
    <br>

    <!-- START / DATA VERSIONS PICKER -------------------------------------- -->
    <div class="data-versions">
        <p-header>
            <i class="fa-solid fa-angle-right"></i>
            &nbsp;
            <span class="headerDataView">{{ 'common.selectBy' | translate }} {{ 'common.roadFlowType' | translate }}</span>
        </p-header>
        <p-dropdown class="ui-g-12"  [style]="{'width':'100%'}"
            optionLabel="name" optionValue="code" 
            [options]="selectMetricOptions" 
            (onChange)="selectMetric($event)"></p-dropdown>
    </div>
    <!-- END / DATA VERSIONS PICKER ---------------------------------------- -->

    <div class="ui-g-12 timings">
        {{ timings.delta_t }}ms
    </div>
</div>
