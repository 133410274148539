import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {GeometryHelper} from "../../../helpers/geometry.helper";
import {GeometryModel} from "../../../models/geojson/geometry-model";
import {GoogleMap} from "@angular/google-maps";
import {MapComponent} from "../../map/map.component";
import {Subscription} from "rxjs";
import {ViewerStoreService} from "../../menu/main-panel/viewer-entry/viewer-store.service";
import {FeatureCollectionModel} from "../../../models/geojson/feature-collection-model";
import {MapSourceEnum} from "../../../enums/map-source-enum.enum";
import {MapImageEnum} from "../../../enums/map-image-enum.enum";
import {MapLayerEnum} from "../../../enums/map-layer-enum.enum";

@Component({
  selector: 'app-google-street-view',
  templateUrl: './google-street-view.component.html',
  styleUrls: ['./google-street-view.component.css']
})
export class GoogleStreetViewComponent implements OnInit {

  private isCameraLoaded: boolean = false;

  @ViewChild(GoogleMap)
  public googleMap: GoogleMap;

  @Input() map: MapComponent;

  @Input()
  set visible(show: boolean) {
    this._visible = show;
    // this.visibleChange.emit(this._visible);

    if (show) {
      if (this.viewerStore.state.geometry !== undefined) {
        this.showStreetView(this.viewerStore.state.geometry);
      }
      setTimeout(()=>{
        // External events
        this.subscriptions.push(this.map.onClickMap.subscribe(geometry => {
          this.viewerStore.state.geometry = geometry;
          this.showStreetView(geometry);
        }));

      },100)


    } else {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
      if (this.map) {
        this.map.removeLayer(MapLayerEnum.LAYER_CAMERA, MapSourceEnum.SOURCE_CAMERA);

        this.isCameraLoaded = false;
      }
    }
  }
  get visible(): boolean {
    return this._visible;
  }

  private subscriptions: Subscription[] = [];

  private _visible: boolean = false;

  streetView: google.maps.StreetViewPanorama;
  display = false;

  center: google.maps.LatLngLiteral = {lat: 24, lng: 12};
  zoom = 4;

  constructor(private viewerStore: ViewerStoreService) {}

  ngOnInit(): void {
  }

  moveTo(geometry: GeometryModel) {
    if (GeometryHelper.isValidPoint(geometry)) {
      this.center = { lat: geometry.coordinates[1], lng: geometry.coordinates[0]};
      this.zoom = 14;
    }
  }

  showStreetView(geometry: GeometryModel) {

    const myTimeout = setTimeout(this.showStreetView, 100);
    if (this.googleMap !== undefined) {
      clearTimeout(myTimeout);
      if (GeometryHelper.isValidPoint(geometry)) {
        this.center = {lat: geometry.coordinates[1], lng: geometry.coordinates[0]};
        this.zoom = 18;

        const panoramaOptions = {
          position: this.center,
          pov: {
            heading: 270,
            pitch: 0,
            zoom: 1
          },
          visible: true
        };
        this.streetView = this.googleMap.getStreetView();
        // this.streetView = new google.maps.StreetViewPanorama(document.getElementById('googleMap'), panoramaOptions);
        this.streetView.setVisible(true);
        this.streetView.setPosition({lat: geometry.coordinates[1], lng: geometry.coordinates[0]});
        this._moveCamera(geometry);
        // this.addEventListenerGoogle();
      }
    }
  }
  // addEventListenerGoogle() {
  //   const myTimeout = setTimeout(this.addEventListenerGoogle, 100);
  //   console.log('in');
  //   if (this.streetView !== undefined) {
  //     clearTimeout(myTimeout);
  //     let panorama = this.streetView;
  //     google.maps.event.addListener(this.streetView, 'position_changed', function () {
  //       // var positionCell = document.getElementById('position_cell');
  //       console.log(panorama.getPosition());
  //     });
  //   }
  // }
  _moveCamera(geometry: GeometryModel) {
    let data: FeatureCollectionModel = new FeatureCollectionModel();
    data.features.push(GeometryHelper.createFeature(geometry));

    if (this.isCameraLoaded) {
      this.map.updateSourceData(MapSourceEnum.SOURCE_CAMERA, data);
    } else {
      let layout = {
        "icon-image": MapImageEnum.IMAGE_CAMERA,
        "icon-size": 0.15,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true
      };

      this.map.addSymbolLayer(MapLayerEnum.LAYER_CAMERA, MapSourceEnum.SOURCE_CAMERA, layout, data);
      this.isCameraLoaded = true;
    }
  }
}
