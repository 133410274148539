import {ISerializable} from '../iserializable';
import {ListValueModel} from "../masterData/list-value-model";
import {ElementClassModel} from "../masterData/element-class-model";

export class ProductModel implements  ISerializable {

  public productId: number;
  public productCode: string;
  public productName: string;
  public productType: ListValueModel;
  public productColor: ListValueModel;
  public productSize: ListValueModel;
  public elementType: string;
  public elementClass: string;
  public priceMethod: ListValueModel;
  public priceValue: number;

  public currentName: ElementClassModel;
  public currentColor: string;
  public currentType: string;
  public currentSize: string;
  public currentMethod: string;

  deserialize(input: any): this {
    Object.assign(this, input);

    this.productType = new ListValueModel().deserialize(input.productType);
    this.productColor = new ListValueModel().deserialize(input.productColor);
    this.productSize = new ListValueModel().deserialize(input.productSize);
    this.priceMethod = new ListValueModel().deserialize(input.priceMethod);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
