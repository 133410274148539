import { Injectable } from '@angular/core';
import { Store } from 'src/app/globals/store';
import { FeatureModel } from 'src/app/models/geojson/feature-model';

export class StreetlightState{

  elementType: string = 'STREETLIGHT';
  isConfirmed : boolean = true;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  selectedElement : FeatureModel;

  selectedNetworks: string[] = [];


  selectedMinQuality: number = 0;
  selectedHighQuality: number = 100;

  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear;

  isRelevant: boolean = true;

  excludeCategories: string[] = [];
  excludeElementNames: string[] = [];

  callUrl: string;
}


@Injectable({
  providedIn: 'root'
})
export class StreetlightStoreService extends Store<StreetlightState>{

  constructor() {
    super(StreetlightState)
  }
}
