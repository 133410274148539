import {Component, Input, OnInit} from '@angular/core';
import {Session} from "../../../globals/session";
import {TreeNode} from 'primeng/api';
import {ElementClassCollectionModel} from "../../../models/masterData/element-class-collection-model";
import {MasterDataService} from "../../../services/master-data.service";
import {SpinnerService} from "../../../shared/spinner/spinner.service";
import {TranslateService} from "@ngx-translate/core";
import {flatMap} from "rxjs/internal/operators";
import {StatisticCollectionModel} from "../../../models/statistic/statistic-collection-model";
import {StatisticModel} from "../../../models/statistic/statistic-model";

@Component({
  selector: 'app-list-element',
  templateUrl: './list-element.component.html',
  styleUrls: ['./list-element.component.css']
})
export class ListElementComponent implements OnInit {

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  _isVisible: boolean = false;

  _rootNode: any[] = [];
  _treeNode: TreeNode[] = [];
  _treeNodeByElementTypes: TreeNode[] = [];

  currentDisplayName: string;
  isChildren: boolean = false;
  elementTypesHandledArray = ['SIGN', 'GROUND', 'LANE', 'STREETLIGHT', 'BARRIER', 'BANK', 'RIVER', 'FURNITURE'];
  elementTypesWithMetersSizes = ['BARRIER', 'BANK', 'LANE'];
  elementTypesWithoutSizes = ['SIGN', 'STREETLIGHT', 'RIVER', 'FURNITURE'];
  displayedElementTypesArray: string[] = [];
  objectElementTypesTranslationKeys = {
    'SIGN': 'signEntry.title', 'GROUND': 'groundEntry.title', 'LANE': 'laneEntry.title',
    'STREETLIGHT': 'streetlightEntry.title', 'BARRIER': 'barrierEntry.title', 'BANK': 'bankEntry.title',
    'RIVER': 'riverSignEntry.title', 'FURNITURE': 'furnitureEntry.title'
  };
  objectElementTypesSizeType = {
    'SIGN': '...', 'GROUND': 'm', 'LANE': 'm', 'STREETLIGHT': '...', 'BARRIER': 'm', 'BANK': 'm',
    'RIVER': '...', 'FURNITURE': '...'
  };
  objectElementTypesTranslationValues: Object;

  constructor(private session: Session,
              private spinnerService: SpinnerService,
              private masterdataService: MasterDataService,
              private translate: TranslateService) {
  }

  ngOnInit() {
  }

  @Input() _userHasGrid: boolean = true;

  @Input() _gridCurrency: boolean = true;

  @Input()
  set objectFeatures(objectArrayStatisticFeatures: StatisticCollectionModel[]) {

    this._treeNodeByElementTypes = [];
    this.getTranslation();

    if (objectArrayStatisticFeatures !== undefined && objectArrayStatisticFeatures.length > 0) {
      objectArrayStatisticFeatures.forEach((objectElementType: StatisticCollectionModel) => {
        let totalPrice: number = 0;
        let totalQuality: number = 0;
        let totalSquareMeterSize: number = 0;
        let totalMeterLength: number = 0;
        let featureCount: number = 0;

        const origin = "ListElementComponent.computeStatisticTable" + objectElementType.elementType;
        this.spinner.addOperation(origin);

        let newElementTypeNode: TreeNode = {};
        newElementTypeNode.data = {};
        newElementTypeNode.data.quantity = 0;
        newElementTypeNode.data.elementType = objectElementType.elementType;
        newElementTypeNode.children = [];

        objectElementType.statistics.forEach((feature) => {
          featureCount++;
          let node: TreeNode = {};
          node.data = feature;
          this.getElementClassDetails(node, newElementTypeNode, objectElementType.statistics.length, featureCount, origin);
          newElementTypeNode.data.quantity += node.data.quantity;
          totalQuality += (node.data.quantity * node.data.quality);
          if (node.data['function'] === 'PAINTED_SURFACE') {
            totalSquareMeterSize += node.data['value'];
            node.data['sizeType'] = 'm2';
          } else if (node.data['function'] === 'PAINTED_LENGTH') {
            totalMeterLength += node.data['value'];
            node.data['sizeType'] = 'm';
          } else {
            node.data['sizeType'] = this.objectElementTypesSizeType[objectElementType.elementType];
          }
          if (node.data.price !== undefined && node.data.price !== '-') {
            totalPrice += node.data.price;
          }
          newElementTypeNode.children.push(node);

          if (featureCount === objectElementType.statistics.length) {
            newElementTypeNode.data.quality = totalQuality / newElementTypeNode.data.quantity;
            newElementTypeNode.data.size = totalMeterLength;
            newElementTypeNode.data.price = totalPrice;
            if (totalSquareMeterSize !== 0 && objectElementType.elementType === 'GROUND') {
              newElementTypeNode.data['sizeSquareMeter'] = totalSquareMeterSize;
              newElementTypeNode.data['sizeTypeSquareMeter'] = 'm2';
            }
            if (this.elementTypesWithoutSizes.includes(objectElementType.elementType)) {
              newElementTypeNode.data['size'] = undefined;
            }
            newElementTypeNode.data['sizeType'] = this.objectElementTypesSizeType[objectElementType.elementType];
            newElementTypeNode.data['elementType'] = this.objectElementTypesTranslationValues[objectElementType.elementType];

            newElementTypeNode.children.sort((a, b) => a.data?.elementType.localeCompare(b.data?.elementType));

            this._treeNodeByElementTypes.push(newElementTypeNode);
          }
        });
      });
    }
    this._treeNodeByElementTypes.sort((a, b) => a.data?.elementType.localeCompare(b.data?.elementType));
  }

  getElementClassDetails(children: TreeNode, newElementTypeNode: TreeNode, totalNumberOfChild: number, childCount: number, origin: string) {
    this.masterdataService.getElementClassByClassName(children.data['elementClass']).subscribe((response: ElementClassCollectionModel) => {
      if (response.elementClasses[0]) {
        children.data['elementType'] = response.elementClasses[0].classDescription;
        if (children.data['paintedSurface']) {
          children.data['size'] = children.data['paintedSurface'];
        } else if (children.data['paintedLength']) {
          children.data['size'] = children.data['paintedLength'];
        } else {
          children.data['size'] = '-';
        }
        if (children.data.price === undefined) {
          children.data.price = '-';
        }
        if (this.elementTypesWithoutSizes.includes(response.elementClasses[0].elementType)) {
          children.data['size'] = undefined;
        }
        if (childCount === totalNumberOfChild) {
          newElementTypeNode.children.sort((a, b) => a.data?.elementType.localeCompare(b.data?.elementType));
          this.spinner.removeOperation(origin);
        }
      }
    });
    return children;
  }

  getTranslation() {
    this.spinner.addOperation("ListElementComponent.getTranslation");
    let elementTypesTranslateKeysArray = Object.values(this.objectElementTypesTranslationKeys);
    this.objectElementTypesTranslationValues = this.objectElementTypesTranslationKeys;
    this.translate.get(elementTypesTranslateKeysArray).subscribe((response: Object) => {
      this.displayedElementTypesArray = Object.values(response);
      Object.keys(this.objectElementTypesTranslationValues).forEach((key: string) => {
        this.objectElementTypesTranslationValues[key] = response[this.objectElementTypesTranslationValues[key]];
      });
      this.spinner.removeOperation("ListElementComponent.getTranslation");
    });
  }
}
