import {Injectable} from '@angular/core';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {EventCodeEnum} from 'src/app/enums/event-code-enum.enum';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {WindowEventHelper} from 'src/app/helpers/window-event.helper';
import {FeatureCollectionModel} from 'src/app/models/geojson/feature-collection-model';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {BankState, BankStoreService} from "./bank-store.service";
import {BankViewPanelComponent} from "./bank-view-panel/bank-view-panel.component";

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class BankService {

  subscriptions = []

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }

  constructor(private bankStore: BankStoreService,
              private mapService: MapService,
              private spinnerService: SpinnerService,
              private toastService: ToastService,
              private panelService: PanelService,) {
    this.bankStore.applyAndWatch(this.updateSearchWrapper.bind(this))
  }


  updateSearchWrapper(bankState: BankState) {
    this.mapService.callWhenAvailable(_ => this.updateSearch(bankState));
  }

  updateSearch(bankState: BankState) {
    if (bankState._isShowElement === true) {
      this.spinner.addOperation("MainMenuLane.showElementLane");

      bankState.arrayLaneContinue = [];
      bankState.arrayLaneDotted = [];

      this.map.removeLayer(MapLayerEnum.LAYER_BANK + ElementTypesEnum.ELEMENT_TYPE_BANK, MapSourceEnum.SOURCE_ELEMENT);

      let layoutBank = {
        'line-cap': 'round',
        'line-join': 'round'
      };

      let paint = {
        'line-color': [
          'case',
          ['has', "layout_line_color"],
          ['get', "layout_line_color"],
          '#ecce21'
        ],
        'line-width': [
          'case',
          ['has', "layout_line_width"],
          ["to-number", ['get', "layout_line_width"]],
          3
        ],
        'line-opacity': [
          'case',
          ['has', "layout_line_opacity"],
          ["to-number", ['get', "layout_line_opacity"]],
          0.8
        ],
        'line-dasharray': [2, 0]
      };

      let tileUrl = environment.urlTilesElements;
      tileUrl = tileUrl + '?elementType=' + ElementTypesEnum.ELEMENT_TYPE_BANK;
      tileUrl = tileUrl + '&isPrimary=' + bankState.isPrimary + '&isRelevant=true' + '&elementQualityMin=' + bankState.selectedMinQuality + '&elementQualityMax=' + bankState.selectedHighQuality;

      let elementClassList: string[];
      const classSelected = bankState.classSelected;
      if (classSelected !== undefined) {
        elementClassList = [];
        Object.keys(classSelected)
          .map(key => classSelected[key])
          .forEach(element => {
            elementClassList.push(element.elementClass)
            tileUrl = tileUrl + '&elementClassList=' + element.elementClass;
          });
      }

      if (this.bankStore.state.selectedNetworks.length > 0) {
        this.bankStore.state.selectedNetworks.forEach((network: string) => {
          if (network.length > 0) {
            tileUrl = tileUrl + '&elementNetworkList=' + network;
          }
        });
      }

      this.bankStore.state.callUrl = tileUrl;

      this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_BANK + ElementTypesEnum.ELEMENT_TYPE_BANK, tileUrl, MapSourceEnum.SOURCE_ELEMENT, MapSourceEnum.SOURCE_VISIBILITY, false, layoutBank, paint);

      this.map.map.on("click", MapLayerEnum.LAYER_BANK + ElementTypesEnum.ELEMENT_TYPE_BANK, e => {
        e.preventDefault();
        this.viewElement(e.features[0].properties['element_identifier']);
      });

      this._onLayerViewElement(MapLayerEnum.LAYER_BANK + ElementTypesEnum.ELEMENT_TYPE_BANK, MapSourceEnum.SOURCE_VISIBILITY + ElementTypesEnum.ELEMENT_TYPE_BANK);

      this.spinnerService.getSpinner().removeOperation("MainMenuLane.showElementLane");

    } else {
      this.map.removeLayer(MapLayerEnum.LAYER_BANK + ElementTypesEnum.ELEMENT_TYPE_BANK, MapSourceEnum.SOURCE_ELEMENT);
      WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_BANK_LOADED);

    }

    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ShowLaneElement');
    }
  }

  _onLayerViewElement(layer, source) {
    this.map.clickLayerMap(layer);
    this.subscriptions.push(this.map.onClickLayerMap.subscribe(e => {
      let features = this.map.map.queryRenderedFeatures(e.point);
      features.forEach(e => {
        if (e.source === source) {
          this.viewElement(e.properties.elementIdentifier);
        }
      })
    }));
  }

  viewElement(elementIdentifier: string) {
    if (elementIdentifier !== undefined) {
      this.panelService.open(
        BankViewPanelComponent,
        elementIdentifier
      )
    }

    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ViewLaneElement');
    }
  }

}
