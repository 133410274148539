import { Injectable	                        } from '@angular/core';
import { ElementTypesEnum	                } from 'src/app/enums/element-types-enum.enum';
import { MapLayerEnum	                    } from 'src/app/enums/map-layer-enum.enum';
import { MapSourceEnum	                    } from 'src/app/enums/map-source-enum.enum';
import { Session	                        } from 'src/app/globals/session';
import { GeojsonService	                    } from 'src/app/services/geojson.service';
import { MapService	                        } from 'src/app/shared/map/map.service';
import { PanelService	                    } from 'src/app/shared/panel/panel.service';
import { SpinnerService	                    } from 'src/app/shared/spinner/spinner.service';
import { SearchStoreService                 } from "src/app/components/main/search/search-store.service"  ;
import { ToastService	                    } from 'src/app/shared/toast/toast.service';
import { environment	                    } from 'src/environments/environment';
import { RoadFsiState, RoadFsiStoreService	} from './road-fsi-store.service';
import { RoadFsiViewPanelComponent	        } from './road-fsi-view-panel/road-fsi-view-panel.component';
import { MapImageEnum	                    } from "../../../../enums/map-image-enum.enum";

declare let gtag: Function;

@Injectable({
    providedIn: 'root'
})

export class RoadFsiService {
    subscriptions   = [];
    clickBound      = false;

    get spinner()   { return this.spinnerService.getSpinner(); }
    get map()       { return this.mapService.getMap(); }
    get toast()     { return this.toastService.getToast(); }

    constructor(
          private session               : Session
        , private geojsonService        : GeojsonService
        , private spinnerService        : SpinnerService
        , private mapService            : MapService
        , public  searchStore           : SearchStoreService
        , private toastService          : ToastService
        , private panelService          : PanelService
        , private roadFsiStoreService   : RoadFsiStoreService
    ) {
        this.roadFsiStoreService.applyAndWatch(this.searchWrapper.bind(this))
    }

    private searchWrapper(state: RoadFsiState){
        this.mapService.callWhenAvailable(_=>this.updateSearchWrapper(state));
    }

    updateSearchWrapper(state: RoadFsiState){
        this.mapService.callWhenAvailable(_=>this.updateSearch(state));
    }

    async updateSearch(state: RoadFsiState) {
        let that            : any       = this;
        let isSymbolLayout  : boolean   = !state.selectedDensity;

        this.map.removeLayer(MapLayerEnum.LAYER_ROAD_FSIS, MapSourceEnum.SOURCE_LAYER_ROAD_FSIS);
        if (state._isShowElement !== true) return 

        this.spinner.addOperation("MenuRoadFsiComponent.showElement");
        environment.googleTracking && gtag('event', 'ShowRoadFsiElement');

        /* 
         * API URL CONSTRUCTION _______________________________________________ 
         */
        let _USER_DEFINED_POLYGONS_HAVE_NO_CRS = 'crs'; // ad hoc...
        let hasDrawnGeometry :boolean   =  this.searchStore.state.drawnGeometry && !this.searchStore.state.drawnGeometry[_USER_DEFINED_POLYGONS_HAVE_NO_CRS] ;
        const selectedUserPolygon       = hasDrawnGeometry                  ? this.searchStore.state.drawnGeometry : null           ;
        let users       = state.selectedUsers.reduce(     (memo, x) => `${memo}&selectedRoadUsers=${x}` , "")
        let severities  = state.selectedSeverities.reduce((memo, x) => `${memo}&selectedSeverities=${x}`, "")
        let ages        = state.selectedAges.reduce(      (memo, x) => `${memo}&selectedAges=${x}`      , "")
        let years       = state.selectedYears.reduce(     (memo, x) => `${memo}&selectedYears=${x}`     , "")
        let months      = state.selectedMonths.reduce(    (memo, x) => `${memo}&selectedMonths=${x}`    , "")
        let dows        = state.selectedDOWs.reduce(      (memo, x) => `${memo}&selectedDOWs=${x}`      , "")
        let hours       = state.selectedHours.reduce(     (memo, x) => `${memo}&selectedHours=${x}`     , "")
        let resultType  = !state.selectedDensity ? `` : `&selectedResultType=summary` 
        let userPolygon = !hasDrawnGeometry      ? `` : `&selectedUserPolygon=${encodeURIComponent(JSON.stringify(selectedUserPolygon))}`
        let tileUrl     = `${environment.urlRoadFsi}?${users}${severities}${userPolygon}${ages}${years}${months}${dows}${hours}${resultType}`; 

        /*
         * HELPERS ____________________________________________________________
         */
        function handleClickOnSymbol(e){
            /* rendering ... */
            const layout = { 'icon-image': MapImageEnum.ICON_CIRCLE , 'icon-size': 0.75 }
            that.map.addSymbolLayer(
                MapLayerEnum.LAYER_CIRCLE
                , MapSourceEnum.SOURCE_ROAD_FSIS + MapSourceEnum.SOURCE_CIRCLE
                , layout
                , that.map.createSymbolLayerDataFromMapboxFeatures(e.features[0]));
            /* ajax call ... */
            let elementIdentifier : string      = e.features[0].properties.accident_id
            let hasNoElementIdentifier: boolean = !elementIdentifier || !elementIdentifier.length
            if(hasNoElementIdentifier) return ;

            that.geojsonService
                .getRoadFsiCollection(elementIdentifier)
                .subscribe(response => {
                    if (response) {
                        that.panelService.open(RoadFsiViewPanelComponent, response)
                    } else {
                        that.toast.showToastWarn();
                    }
                }, error => that.toast.showToastError()
                    , () => environment.googleTracking && gtag('event', 'ViewRoadFsiElement')); 
        }

        /*
         * RENDERING __________________________________________________________
         */
        if(isSymbolLayout){
            /* @Symbol Layout ................................................ */
            let layout = {
                "icon-image"                : "road_fsi_{severity}_{road_user}"
                , "icon-size"                 : 0.3
                , "icon-rotate"               : ["get", "bearing"]
                , "icon-rotation-alignment"   : "map"
                , "icon-allow-overlap"        : true
                , "icon-ignore-placement"     : true
            };
            this.map.addSymbolLayerTiles(
                MapLayerEnum.LAYER_ROAD_FSIS
                ,   tileUrl
                ,   MapSourceEnum.SOURCE_ROAD_FSIS
                ,   false
                ,   layout
                ,   ElementTypesEnum.ELEMENT_TYPE_CRASH
            );

            /* Handlers ... */ 
            if(!this.clickBound) {
                this.map.map.on("click", MapLayerEnum.LAYER_ROAD_FSIS, handleClickOnSymbol);
                this.clickBound = true;
            }
            // this.map.map.on("touchstart", MapLayerEnum.LAYER_ROAD_FSIS , e => this.viewElement(e.features[0].properties.accident_id));
            // this._onLayerViewElement(MapLayerEnum.LAYER_ROAD_FSIS, MapSourceEnum.SOURCE_ROAD_FSIS);
        } else {
            /* @Density map (chloropleth) .............................. */ 
            let layout  = {};
            let paint   = {
                'fill-color': [
                    'interpolate',
                    ['linear'],
                    ['get', 'n(*)'],
                    0, '#FFF',
                    10, '#fee5d9',
                    20, '#fcbba1',
                    40, '#fc9272',
                    80, '#fb6a4a',
                    160, '#ef3b2c',
                    320, '#cb181d',
                    650, '#99000d',
                ], 'fill-opacity': 0.50
            };
            this.map.addPolygonLayerTiles(
                MapLayerEnum.LAYER_ROAD_FSIS
                ,   tileUrl
                ,   MapSourceEnum.SOURCE_ROAD_FSIS
                ,   layout
                ,   paint 
            );

            /* Handlers ... */ 
            // this.map.map.off("click", MapLayerEnum.LAYER_ROAD_FSIS, handleClickOnSymbol);
        }

        /*
         * FINALLY ___________________________________________________________
         */ 
        this.spinner.removeOperation("MenuRoadFsiComponent.showElement");
    }
}
