export enum SearchTypeEnum {
  SEARCH_BY_CITY = 'CITY',
  SEARCH_BY_STATE = 'STATE',
  SEARCH_BY_COUNTRY = 'COUNTRY',
  SEARCH_BY_ZONE = 'ZONE',
  SEARCH_BY_PR = 'PR_POINT',
  SEARCH_BY_GLOBAL = 'ALL',
  SEARCH_BY_PLUS_CODES =  'PLUS_CODES',

  //TODO utiliser le system de traduction, pas dans les enum
  ELEMENT_ZONE = 'Zones',
  ELEMENT_CITY = 'Voies par communes',
  ELEMENT_STATE = 'Voies par régions',
  ELEMENT_COUNTRY = 'Voies par pays',
  ELEMENT_PR = 'Bornes routières',
  ELEMENT_GLOBAL = 'Recherche élargie',
  ELEMENT_PLUS_CODES = 'Plus Codes'
}
