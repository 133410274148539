import {ISerializable} from '../iserializable';

export class ElementClassModel implements ISerializable {

  public elementType: string;
  public elementClass: string;
  public classCode: string;
  public classParent: string;
  public classCategory: string;
  public classFilter01: string;
  public classFilter02: string;
  public classFilter03: string;
  public classDescription: string;
  public classProperties: any = {};
  public classIconFilePath: string;
  public classViewType: string;
  public classGeoType: string;
  public classCallbackFunction: string;
  public isParent: boolean;
  public isAutoClassified: boolean;
  public isVisible: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
