<div class="ui-g sticky" style="padding-right: 10px;">
  <div class="ui-g-offset-11 ui-g-1">
    <i class="fa fa-times icon" (click)="show(false)"></i>
  </div>

  <div class="ui-g-offset-3 ui-g-3 iconContainer">
    <i class="fa fa-bullseye icon" [ngClass]="{'activated': tabIndex===0}"
       pTooltip="{{ 'searchEntryPanel.iconContainer.firstTooltip' | translate }}" tooltipPosition="bottom"
       (click)="switchToAreas()"></i>
    <i class="fa fa-crop icon" (click)="startDrawPolygon()"
       pTooltip="{{ 'searchEntryPanel.iconContainer.secondTooltip' | translate }}" tooltipPosition="bottom"></i>
    <i class="fa ca-milestone icon" [ngClass]="{'activated': tabIndex===2}"
       pTooltip="{{ 'searchEntryPanel.iconContainer.thirdTooltip' | translate }}" tooltipPosition="bottom"
       (click)="tabIndex = 2"></i>
  </div>
  <div class="ui-g-6">
  </div>
  <ng-container *ngIf="tabIndex === 0">
    <div class="ui-g">
      <div class="ui-g-12">
        <h1>{{'searchEntryPanel.container.areaTitle' | translate}}</h1>
      </div>
      <div class="ui-g-12 areaTree" *ngIf="areasTree;else loader">
        <p-tree [value]="areasTree" [filterMode]="'strict'"
                [propagateSelectionUp]="false" [propagateSelectionDown]="false" [filter]="true">
          <ng-template let-node pTemplate="default">
            <span *ngIf="!node.children || node.children.length == 0" class="node-spacer"></span>
            <input type="checkbox" (click)="selectArea(node)" [checked]="isAreaSelected(node)"
                   class="area-checkbox"/>{{node.label}}
          </ng-template>
        </p-tree>
      </div>
      <ng-template #loader>
        <p-progressSpinner></p-progressSpinner>
      </ng-template>
    </div>

  </ng-container>

  <ng-container *ngIf="tabIndex === 2">
    <div class="ui-g">
      <div class="ui-g-9">
        <div class="ui-g-3 labelInput">
          {{ 'searchEntryPanel.container.from' | translate }}
        </div>
        <div class="ui-g-9">
          <input type="text" pInputText placeholder="59-D900-PR22" [(ngModel)]="startingLocationMarker"
                 (ngModelChange)="matchSearch()"/>
        </div>
        <div class="ui-g-3 labelInput">
          {{ 'searchEntryPanel.container.to' | translate }}
        </div>
        <div class="ui-g-9">
          <input type="text" pInputText placeholder="59-D900-PR25" [(ngModel)]="endingLocationMarker"/>
        </div>
      </div>
      <div class="ui-g-3 buttonSearchMarkerLocation">
        <button pButton type="submit" label="{{ 'searchEntryPanel.container.button' | translate }}"
                (click)="searchLocationMarkerArea()"></button>
      </div>
    </div>
    <div class="ui-g-12 textRestriction">
      {{ 'searchEntryPanel.container.explanation' | translate }}
    </div>
  </ng-container>
</div>
