<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_QUALITY')"
appWorkspaceRequired
[ngClass]="{'itemChecked':retroreflexionStore.state._isShowElement, 'itemUnchecked': !retroreflexionStore.state._isShowElement}"
(click)="openMenuRetroreflexion()">
<i class="fa fa-tachometer" id="icons"></i>
<span class="text">{{ 'retroreflexionEntry.title' | translate }}</span>
<a role="button" *ngIf="retroreflexionStore.state._isShowElement">
    <span (click)="close($event)" class="pi pi-times ng-tns-c48-2">
    </span>
</a>
</li>
