import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {GroundEntryPanelComponent} from './ground-entry-panel/ground-entry-panel.component';
import {GroundStoreService} from './ground-store.service';
import {MapService} from "../../../../shared/map/map.service";
import Matomo from "../../../../stats";

@Component({
  selector: 'app-ground-entry',
  templateUrl: './ground-entry.component.html',
  styleUrls: ['./ground-entry.component.css']
})
export class GroundEntryComponent implements OnInit {
  isShowElementGround = false
  constructor(public session: Session,private groundStore: GroundStoreService,private panelService: PanelService, private mapService:MapService,
  ) { }

  ngOnInit(): void {
  }

  openMenuGround(){
    this.panelService.push(
      GroundEntryPanelComponent,
      null
    )
    Matomo.push(['trackEvent', 'Actions', 'Opened ground menu'])
  }

  get map(){
    return this.mapService.getMap();
  }

  close(event:Event){
    event.preventDefault();
    event.stopPropagation();
    this.groundStore.reset();
    Matomo.push(['trackEvent', 'Actions', 'Removed ground'])

  }
}
