import {Component} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SignEntryPanelComponent} from './sign-entry-panel/sign-entry-panel.component';
import {SignStoreService} from './sign-store.service';
import {MapService} from "../../../../shared/map/map.service";
import Matomo from "../../../../stats";

@Component({
  selector: 'app-sign-entry',
  templateUrl: './sign-entry.component.html',
  styleUrls: ['./sign-entry.component.css']
})
export class SignEntryComponent {
  constructor(public session: Session,
              private panelService: PanelService,
              private signStore: SignStoreService,
              private mapService:MapService) { }

  isSearching = false;

  openMenuSign(){
    this.panelService.push(SignEntryPanelComponent,null);
    Matomo.push(['trackEvent', 'Actions', 'Opened sign menu'])
  }

  get map(){
    return this.mapService.getMap();
  }

  close(event:Event){
    event.preventDefault();
    event.stopPropagation();
    this.signStore.reset();
    Matomo.push(['trackEvent', 'Actions', 'Removed sign'])
  }

}
