import {Component, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import {Store} from 'src/app/globals/store';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {MainPanelComponent} from '../../main-panel/main-panel.component';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-shortcut',
  templateUrl: './shortcut.component.html',
  styleUrls: ['./shortcut.component.css']
})
export class ShortcutComponent<T extends {_isShowElement:boolean}> implements OnInit {

  @Input()
  private store: Store<T>;

  @Input()
  private panel: new (...params: any[]) => PanelBase<any>;

  showRemoveFilter = false;

  @HostBinding('class.showRemoveFilter') get show(){
    return this.showRemoveFilter;
  }

  @HostBinding('class.notShowRemoveFilter') get notShow(){
    return !this.showRemoveFilter;
  }

  @HostListener('click') handleClick() {
    this.open();
    Matomo.push(['trackEvent', 'Actions', 'Clicked on shortcut to open '+this.panel.name])
  }

  constructor(private panelService: PanelService) { }

  ngOnInit(): void {
    this.store.applyAndWatch(s=>this.showRemoveFilter = s._isShowElement);
  }

  open(){
    this.panelService.open(MainPanelComponent, null)
    this.panelService.push(this.panel, null);
  }

}
