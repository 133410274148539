import { Injectable } from '@angular/core';
import { SpinnerComponent } from 'src/app/components/main/spinner/spinner.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public spinner: SpinnerComponent;

  constructor() { }

  setSpinner(spinner:SpinnerComponent){
    this.spinner = spinner;
  }

  getSpinner(){
    return this.spinner;
  }
}
