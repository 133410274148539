import { Injectable } from '@angular/core';
import { Options } from 'ng5-slider';
import { Subscription } from 'rxjs';
import { Store } from 'src/app/globals/store';
import { ElementClassModel } from 'src/app/models/masterData/element-class-model';
import { ListValueModel } from 'src/app/models/masterData/list-value-model';
import { environment } from 'src/environments/environment';

export class StatsState{
  _isRefreshed: boolean = false;
}

@Injectable({
  providedIn: 'root'
})
export class StatsStoreService extends Store<StatsState>{

  constructor() {
    super(StatsState)
  }
}
