import {ISerializable} from '../iserializable';

export class StatisticModel implements ISerializable {

  public elementType: string;
  public elementClass: string;
  public quantity: number;
  public quality: number;
  public size: number;
  public length: number;
  public area: number;
  public price: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
