import {Component, OnInit} from '@angular/core';
import {ChangeContext, Options} from 'ng5-slider';
import {Subscription, throwError} from 'rxjs';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {Session} from 'src/app/globals/session';
import {ElementClassCollectionModel} from 'src/app/models/masterData/element-class-collection-model';
import {ElementClassModel} from 'src/app/models/masterData/element-class-model';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MasterDataService} from 'src/app/services/master-data.service';
import {ProcessingService} from 'src/app/services/processing.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {BarrierStoreService} from '../barrier-store.service';
import Matomo from "../../../../../stats";


declare let gtag: Function;

@Component({
  selector: 'app-barrier-entry-panel',
  templateUrl: './barrier-entry-panel.component.html',
  styleUrls: ['./barrier-entry-panel.component.css']
})
export class BarrierEntryPanelComponent implements OnInit, PanelBase<any> {
  constructor(public session: Session,
              private processingService: ProcessingService,
              private masterdataService: MasterDataService,
              private geojsonService: GeojsonService,
              private spinnerService: SpinnerService,
              private mapService: MapService,
              private toastService: ToastService,
              private panelService: PanelService,
              private barrierStore: BarrierStoreService) {
  }

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }

  data: any;
  countSelected: number;
  selectedNetworks: string[] = [];
  environment = environment;
  subscriptions: Subscription[] = [];
  classList: ElementClassModel[] = [];
  classGroups: string[] = [];
  classSelected: { [key: string]: ElementClassModel } = {};
  numberOfSelectClasses = 0;

  isRelevant: boolean = true;
  isElementRemoved: boolean = true;

  selectedMinQuality: number = 0;
  selectedHighQuality: number = 100;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  qualitySliderOptions: Options = {
    floor: 0,
    ceil: 100,
    showOuterSelectionBars: true
  };
  selectedElement: string;
  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear;
  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };
  onCreate: boolean = false;

  ngOnInit() {
    this.subscriptions.push(this.barrierStore.applyAndWatch(_ => this.barrierStore.applyOn(this)));
    this.countSelected = 0;

    this.subscriptions.push(this.session.onChangeWorkspace.subscribe(geometry => {
      this.refresh();
    }));

    this.geojsonService.elementEmiter.subscribe(element => {
      if (element !== undefined) {
        this.selectedElement = element.elementIdentifier;
      }
    });

    this.loadClasses();
    this.showElement();
  }

  loadClasses() {
    if (!this.classList.length) {
      this.spinner.addOperation("MenuBarrierComponent.loadClasses");

      this.masterdataService.getElementClass(ElementTypesEnum.ELEMENT_TYPE_BARRIER).subscribe((response: ElementClassCollectionModel) => {
        if (response && response.elementClasses.length > 0) {
          this.classList = response.elementClasses;
          this.classList.forEach(item => {
            this.mapService.addMissingElementClassImage(item.elementClass, item.classIconFilePath);
            if (this.classGroups.indexOf(item.classCategory) === -1) {
              this.classGroups.push(item.classCategory);
            }
          });

        } else {
          this.toast.showToastWarn();
        }

          this.spinnerService.getSpinner().removeOperation("MenuBarrierComponent.loadClasses")
      },
        (_error => {
          this.spinnerService.getSpinner().removeOperation("MenuBarrierComponent.loadClasses")
          return throwError('error while loading classes')
        })
        );
    }
  }


  toggleFilterByClass(classElement: ElementClassModel) {
    if (!this.isClassSelected(classElement)) {
      const nextclassSelected = {...this.classSelected, [classElement.elementClass]: classElement};
      this.classSelected = nextclassSelected;
      this.numberOfSelectClasses++;
    } else {
      const classSelected = {...this.classSelected};
      delete classSelected[classElement.elementClass];
      this.classSelected = classSelected;
      this.numberOfSelectClasses--;
    }
    this.showElement();
  }

  showElement() {
    const state = this.barrierStore.buildStateFromComponent(this);
    state._isShowElement = true;
    this.barrierStore.state = state;
  }

  refresh() {
    this.barrierStore.refresh();
  }

  clear() {
    this.barrierStore.reset();
    this.show(false);
  }


  show(show: boolean = true) {
    this._isVisible = show;
    if (!show) {
      this.panelService.pop();
      Matomo.push(['trackEvent', 'Actions', 'Removed barrier'])
    }
  }


  isClassSelected(classModel: ElementClassModel) {
    return this.classSelected[classModel.elementClass]
  }


  updateQualityValues($event: ChangeContext) {
    this.selectedMinQuality = $event.value;
    this.selectedHighQuality = $event.highValue;
    this.showElement();
  }
  updateYearValues($event: ChangeContext) {
    this.selectedYearMinValue = $event.value;
    this.selectedYearHighValue = $event.highValue;
    this.showElement();
  }

}
