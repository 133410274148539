<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_FLOW')"
    [ngClass]="roadFlowStore.state._isShowElement ? 'itemChecked': 'itemUnchecked'" 
    appWorkspaceRequired
    (click)="openMenuRoadFlow()">
    <div class="ui-g-11 ui-g-nopad">
        <i class="fa-solid fa-car-on"></i>
        <span class="text">{{ 'roadFlowEntry.title' | translate }}</span>
    </div>
    <a role="button" *ngIf="roadFlowStore.state._isShowElement" (click)="close($event)" class="ui-g-1 ui-g-nopad">
        <span class="pi pi-times" style="margin:0;"></span>
    </a>
</li>
