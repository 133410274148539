<div id="map" [ngClass]="{disabledMap: !isUserInteractionsActive}" #map></div>
<div class="mapboxgl-control-container hide">
  <div class="mapboxgl-ctrl-bottom-right">
    <div class="mapboxgl-ctrl mapboxgl-ctrl-group mapControls">
      <button class="mapboxgl-ctrl-icon " type="button"
              title="Measure Distance" aria-label="Measure Distance" id="measure"
              (click)="toggleMeasure()"><img src="https://img.icons8.com/android/19/000000/length.png"/></button>
      <button class="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in" type="button"
              title="Zoom in" aria-label="Zoom in" (click)="zoomIn()"></button>
      <button class="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out" type="button"
              title="Zoom out" aria-label="Zoom out" (click)="zoomOut()"></button>
      <button pButton class="ui-button-secondary" type="button" icon="fa fa-arrows-alt"
              title="Fit to Content" aria-label="Fit to Content" (click)="fitToWorkspace()"></button>
    </div>
  </div>
</div>
<div *ngIf="isActiveMeasure" id="distance">{{measureDistance}}</div>
