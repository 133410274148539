import { Injectable } from '@angular/core';
import { MapLayerEnum } from 'src/app/enums/map-layer-enum.enum';
import { Store } from 'src/app/globals/store';



export type ActivatedConformityElementType = {
  disabled: false,
  layerName: MapLayerEnum,
  showing: boolean,
  value: string
}

export type DisabledConformityElementType = {
  disabled: true
}

export type ConformityElementType = {
  labels: {
    fr: string,
    en: string
  },
  images: string[],
} & (ActivatedConformityElementType | DisabledConformityElementType)




export class ConformityState {
  selectedConformityValues: string[] = [];
  selectedDangerValues: string[] = [];

  searchDangerActivated = false;
  searchConformityActivated = false;

  conformityElementsIndex: { [key: string]: ActivatedConformityElementType } = {};
  dangerElementsIndex: { [key: string]: ActivatedConformityElementType } = {};

  activatedConformityElements: ActivatedConformityElementType[] = [];
  activatedDangerElements: ActivatedConformityElementType[] = [];

  conformityElements: ConformityElementType[] = [
    {
      value: "curves",
      labels: {
        fr: "Virages dangereux",
        en: "Dangerous curves"
      },
      layerName: MapLayerEnum.LAYER_CURVES,
      showing: false,
      images: ["sign-fr-A1A"],
      disabled: false
    },
    {
      labels: {
        fr: "Passage-Piéton",
        en: "Crosswalk"
      },
      images: ["ground-fr-PEDCROSSING"],
      disabled: true
    },
    {
      labels: {
        fr: "Zone de dépassement",
        en: "Overtaking zone"
      },
      images: ["lane-fr-LANE_MT3"],
      disabled: true
    },
    {
      labels: {
        fr: "Régime priorité",
        en: "Priority regime"
      },
      images: ["sign-fr-AB4", "sign-fr-AB3A"],
      disabled: true
    }
  ];

  dangerElements: ConformityElementType[] = [
    {
      value: "glare",
      labels: {
        fr: "Eblouissement",
        en: "Glare"
      },
      layerName: MapLayerEnum.LAYER_GLARE,
      // applyCallback: this.showGlare.bind(this),
      showing: false,
      images: ["sign-fr-CE21"],
      disabled: false
    },
    {
      value: "narrowRoads",
      labels: {
        fr: "Routes étroites",
        en: "Narrow roads"
      },
      layerName: MapLayerEnum.LAYER_NARROW,
      images: ["sign-fr-A3"],
      disabled: false,
      showing: false
    },
  ];

}


@Injectable({
  providedIn: 'root'
})
export class ConformityStoreService extends Store<ConformityState> {

  constructor() {
    super(ConformityState)
    if (!this.state.activatedConformityElements.length ||
      !this.state.activatedDangerElements.length) {
      const nextState = this.state;

      if (!this.state.activatedConformityElements.length) {
        nextState.activatedConformityElements = nextState.conformityElements.filter(v => !v.disabled) as ActivatedConformityElementType[];
        nextState.conformityElementsIndex = nextState.activatedConformityElements.reduce(
          (pr, curr) => ({ ...pr, [curr.value]: curr }),
          {}
        )
      }

      if (!this.state.activatedDangerElements.length) {
        nextState.activatedDangerElements = nextState.dangerElements.filter(v => !v.disabled) as ActivatedConformityElementType[];
        nextState.dangerElementsIndex = nextState.activatedDangerElements.reduce(
          (pr, curr) => ({ ...pr, [curr.value]: curr }),
          {}
        )
      }
      this.state = nextState;
    }
  }
}
