import { Component, OnInit              } from '@angular/core';
import { MapService                     } from 'src/app/shared/map/map.service';
import { BackgroundDetailsStoreService  } from './background-details-store.service';
import { TranslateService               } from "@ngx-translate/core";

@Component({
  selector      : 'app-background-details-entry',
  templateUrl   : './background-details-entry.component.html',
  styleUrls     : ['./background-details-entry.component.css']
})

export class BackgroundDetailsEntryComponent implements OnInit {

    constructor(
          public backgroundDetailsStore : BackgroundDetailsStoreService
        , private translate             : TranslateService
    ) { }

    ngOnInit(): void { }

    toggleBackgroundDetail() {
        this.backgroundDetailsStore.state = {
            isShowBackgroundDetail: !this.backgroundDetailsStore.state.isShowBackgroundDetail
        }
    }
}


