import { Injectable } from '@angular/core';
import { AlertService } from '../components/menu/main-panel/alert-entry/alert.service';
import { BackgroundDetailsService } from '../components/menu/main-panel/background-details-entry/background-details.service';
import { BackgroundService } from '../components/menu/main-panel/background-entry/background.service';
import { BarrierService } from '../components/menu/main-panel/barrier-entry/barrier.service';
import { ConformityService } from '../components/menu/main-panel/conformity-entry/conformity.service';
import { CustomBackgroundService } from '../components/menu/main-panel/custom-background-entry/custom-background.service';
import { FurnitureService } from '../components/menu/main-panel/furniture-entry/furniture.service';
import { GroundService } from '../components/menu/main-panel/ground-entry/ground.service';
import { HeliosService } from '../components/menu/main-panel/helios-entry/helios.service';
import { JourneyService } from '../components/menu/main-panel/journey-entry/journey.service';
import { LaneService } from '../components/menu/main-panel/lane-entry/lane.service';
import { LocationMarkerService } from '../components/menu/main-panel/location-marker-entry/location-marker.service';
import { RetroreflexionService } from '../components/menu/main-panel/retroreflexion-entry/retroreflexion.service';
import { RiverSignService } from '../components/menu/main-panel/river-sign-entry/river-sign.service';
import { RoughnessService } from '../components/menu/main-panel/roughness-entry/roughness.service';
import { SignService } from '../components/menu/main-panel/sign-entry/sign.service';
import { StreetlightService } from '../components/menu/main-panel/streetlight-entry/streetlight.service';
import { StripService } from '../components/menu/main-panel/strip-entry/strip.service';
import { ViewerStateReducerService } from '../components/menu/main-panel/viewer-entry/viewer-state-reducer.service';
import { VisibilityService } from '../components/menu/main-panel/visibility-entry/visibility.service';
import { VisibilityOnDemandService } from '../components/menu/main-panel/visibility-on-demand-entry/visibility-on-demand.service';
import { BankService        } from "../components/menu/main-panel/bank-entry/bank.service";
import { SearchService      } from "../components/main/search/search.service";
import { RoadFsiService     } from '../components/menu/main-panel/road-fsi-entry/road-fsi.service';
import { RoadFlowService    } from "../components/menu/main-panel/road-flow-entry/road-flow.service";
import { RoadRiskService    } from "../components/menu/main-panel/road-risk-entry/road-risk.service";

@Injectable({
  providedIn: 'root'
})
export class ReducersService {

  constructor(private customBackgroundService: CustomBackgroundService,
              private backgroundService: BackgroundService,
              private stripService: StripService,
              private backgroundDetailsService: BackgroundDetailsService,
              private ViewerService: ViewerStateReducerService,
              private journeyService: JourneyService,
              private signReducer: SignService,
              private roadRisk: RoadRiskService,
              private groundService: GroundService,
              private searchService: SearchService,
              private laneService: LaneService,
              private retroreflexionService: RetroreflexionService,
              private furnitureService: FurnitureService,
              private barrierService: BarrierService,
              private heliosService: HeliosService,
              private alertService: AlertService,
              private streetlightService: StreetlightService,
              private conformityService: ConformityService,
              private visibilityService: VisibilityService,
              private VisibilityOnDemandService: VisibilityOnDemandService,
              private roadFsiService: RoadFsiService,
              private locationMarkerService: LocationMarkerService,
              private roughnessService: RoughnessService,
              private bankService: BankService,
              private riverSignService: RiverSignService,
              private roadFlowService: RoadFlowService) { }
}
