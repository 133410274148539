import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {
  VisibilityOnDemandEntryPanelComponent
} from './visibility-on-demand-entry-panel/visibility-on-demand-entry-panel.component';
import {VisibilityOnDemandStoreService} from './visibility-on-demand-store.service';
import Matomo from "../../../../stats";
import {MapService} from "../../../../shared/map/map.service";
import {MapLayerEnum} from "../../../../enums/map-layer-enum.enum";
import {MapSourceEnum} from "../../../../enums/map-source-enum.enum";

@Component({
  selector: 'app-visibility-on-demand-entry',
  templateUrl: './visibility-on-demand-entry.component.html',
  styleUrls: ['./visibility-on-demand-entry.component.css']
})
export class VisibilityOnDemandEntryComponent implements OnInit {

  constructor(public session: Session, public visibilityOnDemandStore: VisibilityOnDemandStoreService, public panelService: PanelService,
              private mapService:MapService) { }

  ngOnInit(): void {
  }

  openMenuVisibilityOnDemand(){
    this.panelService.push(
      VisibilityOnDemandEntryPanelComponent,
      null
    )
    Matomo.push(['trackEvent', 'Actions', 'Opened on demand visibility menu'])
  }

  removeOnDemandVisibility() {
    this.visibilityOnDemandStore.reset();
    this.mapService.getMap().removeLayer(MapLayerEnum.LAYER_SKETCH_WAY, MapSourceEnum.SOURCE_SKETCH_WAY);
    this.mapService.getMap().removeLayer(MapLayerEnum.LAYER_SKETCH_WAY_UNIQUE, MapSourceEnum.SOURCE_SKETCH_WAY_UNIQUE);
    Matomo.push(['trackEvent', 'Actions', 'Removed on demand visibility'])
  }
}
