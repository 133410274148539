import {ISerializable} from '../iserializable';
import {GeometryModel} from "../geojson/geometry-model";
import {BboxModel} from "./bbox-model";

export class ElementModel implements ISerializable {

  public elementIdentifier: string;
  public elementName: string;
  public elementOrder: number;
  public elementType: string;
  public elementClass: string;
  public elementCategory: string;
  public elementQuality: number;
  public elementStorageType: string;
  public elementStorageFilePath: string;
  public elementProperties: object;
  public viewIdentifier: string;
  public viewBbox: BboxModel;
  public isConfirmed: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.viewBbox) {
      this.viewBbox = new BboxModel().deserialize(input.viewBbox);
    }

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
