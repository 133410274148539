import { Pipe, PipeTransform } from '@angular/core';

const nameMapping = {
  ARROW: "Flèches",
  TS: "Marquages spéciaux",
  OTHER: "Autres"
} as const;

@Pipe({
  name: 'elementClassName'
})
export class ElementClassNamePipe implements PipeTransform {

  transform(elementName: string = ""): string {
    return nameMapping[elementName?.toUpperCase()] ||elementName;
  }
}
