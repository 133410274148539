import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {MessageSeverityEnum} from "../enums/message-severity-enum.enum";

@Injectable({
  providedIn: 'root'
})
export class MessageHelper {

  constructor() {}

  static createMessage(severity: MessageSeverityEnum, error: HttpErrorResponse): any {
    if (error) {
      return {severity:(severity || MessageSeverityEnum.SEVERITY_INFO),
        summary: error.error.code + ' ' + error.status, detail: error.error.message};
    }
    return undefined;
  }

  static createTextMessage(severity: MessageSeverityEnum, summary: string, detail:string = undefined): any {
    return {severity:(severity || MessageSeverityEnum.SEVERITY_INFO),
        summary: summary, detail: detail};
  }
}
