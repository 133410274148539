<div class="ui-g sticky" style="padding-right: 10px;">
  <div class="ui-g-1 titleButton">
    <i class="fa fa-arrow-left" (click)="show(false)"></i>
  </div>
  <div class="ui-g-10">
    <h1>{{ 'streetlightEntry.title' | translate }}</h1>
  </div>
  <div class="ui-g-1 titleButton">
    <i class="fa fa-eraser" [ngClass]="{'itemUnchecked':_isShowElement, 'itemDisabled': !_isShowElement}"
       (click)="clear()"></i>
  </div>
</div>
<div>
  <div class="title">{{ 'common.networkFilter' | translate }}</div>
  <p-chips [(ngModel)]="selectedNetworks" ></p-chips>
</div>
