import {
  Vector3Helper
} from "@mycitymagine/ts-citymagine-core";

import {ResultVisibilityEnum} from "../enums/result-visibility-enum";

import {LineStringHelper} from "./linestring-helper";
import {CartesianHelper} from "./cartesian.helper";

import {GeometryModel} from "../models/geojson/geometry-model";
import {ResultModel} from "../models/result-model"
import {Vector3dModel} from "../models/math/vector3d-model";
import {Line3dModel} from "../models/math/line3d-model";
import {AxisEnum} from "../enums/axis-enum";
import {GeometryHelper} from "./geometry.helper";
import {RoadNode} from "../linked-list/road-node";
import {EventEnum} from "../enums/event-enum";
import {ModelTurnsHelper} from "./model-turns.helper";
import {FeatureHelper} from "./feature-helper";
import {FeatureCollectionModel} from "../models/geojson/feature-collection-model";
import {ArrayHelper} from "./array.helper";
import {GraphHelper, createNode} from "./graph.helper";
import {GraphBasedToolsService} from "./graph-based-tools.service";
import {FeatureModel} from "../models/geojson/feature-model";

export enum VisibilityModeEnum {
  CAR_OVERTAKING = 'CAR_OVERTAKING',
  OBSTACLE = 'OBSTACLE'
}

export class ComputeVisibilityHelper {

  public curvePoints: GeometryModel[] = [];
  public graphHelper: GraphHelper;
  public fullSegments: GeometryModel[];
  public response: FeatureCollectionModel;

  // Points buffer

  constructor() {
  }

  // Compute 3D models & find intersections
  computeVisibilityCurve(wayAxisLineString: GeometryModel, wayLaneWidth: number, wayShoulderWidth: number = 3, driverHeight: number = 1): ResultModel {

    let originPoint: GeometryModel = LineStringHelper.getStartPoint(wayAxisLineString);
    let wayAxis: Vector3dModel[] = CartesianHelper.convertFromLineString(originPoint, wayAxisLineString);

    let checkCurvature = this.checkCurvature(wayAxis, wayLaneWidth, wayShoulderWidth);
    let checkElevation = this.checkElevation(wayAxis, wayLaneWidth, wayShoulderWidth, driverHeight);


    if (checkCurvature.code == ResultVisibilityEnum.NO_VISIBILITY
      || checkElevation.code == ResultVisibilityEnum.NO_VISIBILITY) {

      let values = {
        type: (checkCurvature.values["type"] && checkElevation.values["type"]) ? ResultVisibilityEnum.TYPE_BOTH : (checkCurvature.values["type"] || checkElevation.values["type"]),
        curvature: checkCurvature.values,
        elevation: checkElevation.values
      };
      return new ResultModel(ResultVisibilityEnum.NO_VISIBILITY, values);

    } else {
      return new ResultModel(ResultVisibilityEnum.VISIBILITY);
    }
  }

  protected checkElevation(wayAxis: Vector3dModel[], wayLaneWidth: number = 7, wayShoulderWidth: number = 3, driverHeight: number = 1): ResultModel {

    // Axis align & transpose Z (X length / Y altitude)
    let wayTransposedAxis: Vector3dModel[] = Vector3Helper.transposeZ(Vector3Helper.alignZ(wayAxis));

    let driverView = new Line3dModel(wayTransposedAxis[0].clone(), wayTransposedAxis[wayTransposedAxis.length - 1].clone());
    driverView.addY(driverHeight);

    let intersects = Vector3Helper.lineIntersects2d(driverView, wayTransposedAxis, true);

    if (intersects.length > 0) {
      let values = {
        type: ResultVisibilityEnum.TYPE_ELEVATION,
        intersects: intersects.length,
        startDistance: driverView.start.distance2dTo(intersects[0]),
        geometries: {
          wayTransposedAxis: Vector3Helper.formatPlotlyLine("WayTransposedAxis", wayTransposedAxis),
          driverView: Vector3Helper.formatPlotlyLine("DriverView", driverView.toVector3d()),
          intersects: Vector3Helper.formatPlotlyLine("Intersects", intersects)
        }
      };
      return new ResultModel(ResultVisibilityEnum.NO_VISIBILITY, values)
    } else {
      return new ResultModel(ResultVisibilityEnum.VISIBILITY)
    }
  }

  protected checkCurvature(wayAxis: Vector3dModel[], wayLaneWidth: number = 7, wayShoulderWidth: number = 3): ResultModel {

    // Rives
    let wayRive1: Vector3dModel[] = Vector3Helper.lineOffset2d(wayAxis, (wayLaneWidth + wayShoulderWidth) / 2);
    let wayRive2: Vector3dModel[] = Vector3Helper.lineOffset2d(wayAxis, -(wayLaneWidth + wayShoulderWidth) / 2);

    // Driver view
    let driverView = new Line3dModel(wayAxis[0].clone2d(), wayAxis[wayAxis.length - 1].clone2d());

    // Check Curvature intersects
    let intersects: Vector3dModel[] = [];
    intersects = intersects.concat(Vector3Helper.lineIntersects2d(driverView, wayRive1, true));
    intersects = intersects.concat(Vector3Helper.lineIntersects2d(driverView, wayRive2, true));

    if (intersects.length > 0) {
      let values = {
        type: ResultVisibilityEnum.TYPE_CURVATURE,
        intersects: intersects.length,
        startDistance: driverView.start.distance2dTo(intersects[0]), // minDistance ? + Rive 1 et/ou 2
        geometries: {
          wayRive1: Vector3Helper.formatPlotlyLine("WayRive1", wayRive1),
          wayRive2: Vector3Helper.formatPlotlyLine("WayRive2", wayRive2),
          driverView: Vector3Helper.formatPlotlyLine("DriverView", driverView.toVector3d()),
          intersects: Vector3Helper.formatPlotlyLine("Intersects", intersects)
        }
      };
      return new ResultModel(ResultVisibilityEnum.NO_VISIBILITY, values)
    } else {
      return new ResultModel(ResultVisibilityEnum.VISIBILITY)
    }
  }

  static getDistanceFromSpeed(speed: number, mode: VisibilityModeEnum): number {
    if (mode == VisibilityModeEnum.OBSTACLE) {
      // /*original output*/ return = (speed / 2 + (1 + 0/* Related to turn radius*/) * ((0.394 * speed * speed) / (41 + 0/* Related to slope */))) * 0.9/* Related to tolerance */;
      let brakingDist = (speed / 2 + (1 + 0/* Related to turn radius*/) * ((0.394 * speed * speed) / (41 + 0/* Related to slope */))) * 0.9/* Related to tolerance */;
      //TODO: This is added to code... Is it to be supressed ?
      let reactionDist = 0.83 * (speed / 3.6); //0.83 = reaction time to get information
      return reactionDist + brakingDist
    }
    let result: number = 400;
    let matrix = [
      {speed: 0, distance: 40},
      {speed: 40, distance: 40},
      {speed: 50, distance: 60},
      {speed: 60, distance: 90},
      {speed: 70, distance: 120},
      {speed: 80, distance: 160},
      {speed: 90, distance: 200},
      {speed: 100, distance: 250},
      {speed: 110, distance: 300},
      {speed: 120, distance: 360},
      {speed: 150, distance: 400}
    ];

    for (let i in matrix) {
      if (matrix[i].speed <= speed) {
        result = matrix[i].distance;
      }
    }
    return result;

    /*
    Speed (km/h) > Return Braking dist(m) considering dist made during awareness time (1s) and physical stopping dist
     >>> Awareness dist = time_reaction(1s) * speed(in m/s)
     >>> Stopping dist = speed**2/(2µg) //With g=9.81 m/s**2(gravity acc.), µ=0.3(coef of friction)
     */
    // speed = speed/3.6; //Get speed in m/s
    // return speed + speed**2/2.943
  }

  protected fixIgnMisplacedPoints(segments: GeometryModel[], min: number, maxDistance: number = 20) { // WARNING this doesn't remove the same points whether it's ASC or DSC
    function isMisplaced(index: number, segment: GeometryModel): boolean {
      return index > 0 && index + 2 < segment.coordinates.length &&
        GeometryHelper.getPointToLineDistance(GeometryHelper.createPoint(segment.coordinates[index + 1]), GeometryHelper.createLineString([segment.coordinates[index], segment.coordinates[index + 2]])) < min &&
        (GeometryHelper.getDistance(GeometryHelper.createPoint(segment.coordinates[index + 1]), GeometryHelper.createPoint(segment.coordinates[index])) < maxDistance ||
          GeometryHelper.getDistance(GeometryHelper.createPoint(segment.coordinates[index + 2]), GeometryHelper.createPoint(segment.coordinates[index])) < maxDistance) &&
        !(index > 0 && isMisplaced(index - 1, segment))
    }


    for (let segment of segments) {
      ModelTurnsHelper.fixDifferentZOnSamePoint(ModelTurnsHelper.dropDuplicates(segment));
      let len: number = 0;

      do {
        len = segment.coordinates.length;
        segment.properties.nodes = segment.properties.nodes.filter((_: any, index: number) => !isMisplaced(index, segment));
        segment.coordinates = segment.coordinates.filter((_: any, index: number) => !isMisplaced(index, segment));
      } while (len != segment.coordinates.length)
    }
  }
  computeVisibilityWithSegments(segments: GeometryModel[], speedLimit: number = 70, widthChosen: number = 6, driverHeight: number = 1.6, axis: AxisEnum, way_identifier: string): FeatureCollectionModel {

    // In order to make the computation easier, split segments looping on themselves into two distinct ones
    segments.forEach(s => {
      if (ArrayHelper.equals(s.coordinates[0].slice(0, 2), s.coordinates[s.coordinates.length - 1].slice(0, 2))) {
        segments.push(s.clone());
        segments[segments.length - 1].coordinates = s.coordinates.slice(Math.round(s.coordinates.length / 2) - 1, s.coordinates.length);
        segments[segments.length - 1].properties.segment_identifier = s.properties.segment_identifier + '_bis';
        s.coordinates = s.coordinates.slice(0, Math.round(s.coordinates.length / 2))
      }
    });

    // Remove segments that are contained in others.
    segments = segments.filter(s =>
      !segments.some(s2 => s !== s2 && ArrayHelper.isIn(s.coordinates, s2.coordinates))
    );

    // Compute Graph
    this.graphHelper = new GraphHelper(segments, createNode, false, false, false);


    this.graphHelper.graph.changeStateFromHeads();
    // Get Start Point of each road composed of 'next'
    let startPoints: RoadNode<GeometryModel>[] = GraphBasedToolsService.getStartPoints(this.graphHelper.graph, way_identifier, axis, true);
    this.graphHelper.graph.changeStateFromHeads();
    // Merge segments in each road
    this.fullSegments = GraphBasedToolsService.getMergedSegmentsFromStartPoints(startPoints, way_identifier, axis);

    this.fullSegments = this.fullSegments.filter(s =>
      !this.fullSegments.some(s2 => s !== s2 && ArrayHelper.isIn(s.coordinates, s2.coordinates))
    );

    this.fixIgnMisplacedPoints(this.fullSegments, 0.25, 15);

    // let pg: ProgressBar = new ProgressBar(this.fullSegments.map(s => GeometryHelper.getLength(s)).reduce((a, b) => a + b) / this.metersIncr, "Compute " + axis + " axis");

    let wayShoulderWidth: number = 3;

    this.response = new FeatureCollectionModel();
    this.response.features = [];
    let metersIncr = 20;
    // let wayAxis: AxisEnum;
    // if (axis !== undefined) {
    //   wayAxis = axis;
    // } else {
    //   wayAxis = AxisEnum.UNKNOWN;
    // }


    // for (let segment of this.fullSegments) {
    for (let segment of this.fullSegments) {
      let meters: number = 0;

      let visibilityDistance: number = ComputeVisibilityHelper.getDistanceFromSpeed(speedLimit, VisibilityModeEnum.CAR_OVERTAKING);

      while (GeometryHelper.getLength(segment) > meters + visibilityDistance) {
        let currentPosition = LineStringHelper.getAlong(segment, meters);
        let visibilityPosition = LineStringHelper.getAlong(segment, meters + visibilityDistance);

        let wayAxisLine: GeometryModel = LineStringHelper.sliceLineString(currentPosition, visibilityPosition, segment);

        let result = this.computeVisibilityCurve(wayAxisLine, widthChosen, 3, driverHeight);

        if (this.curvePoints.length > 0 && result.code == ResultVisibilityEnum.VISIBILITY) {
          this.pushCurve(4, 30, widthChosen);
        }
        this.createCurvePoints(result, driverHeight, wayShoulderWidth, currentPosition, segment.properties['nodes'][LineStringHelper.getIndexPointAlong(segment, meters)], axis, speedLimit, visibilityDistance, widthChosen, wayAxisLine);

        meters += metersIncr;
      }
      // let visibilityCurve = this.pushCurve(4, 30, widthChosen);
      this.pushCurve(4, 30, widthChosen);
      // if  (visibilityCurve !== undefined) {
      //   visibilityCurves.features.push(visibilityCurve);
      // }
    }
    return this.response;
  }

  protected createCurvePoints(result: ResultModel,
                              driverHeight: number,
                              wayShoulderWidth: number,
                              currentPosition: GeometryModel,
                              node: RoadNode<GeometryModel>,
                              axis: AxisEnum,
                              speed: number,
                              visibilityDistance: number,
                              wayLaneWidth: number,
                              wayAxisLine: GeometryModel,
                              event: EventEnum = EventEnum.NONE,
                              event_position: GeometryModel = undefined) {

    if (result.code != ResultVisibilityEnum.NO_VISIBILITY)
      return;
    let point = currentPosition.clone();

    point.properties = result.values;
    point.properties["axis"] = axis;
    point.properties["speed"] = speed;
    point.properties["distance"] = visibilityDistance;
    point.properties["wayLaneWidth"] = wayLaneWidth;
    point.properties["wayShoulderWidth"] = wayShoulderWidth;
    point.properties["driverHeight"] = driverHeight;
    point.properties["urban"] = wayAxisLine.properties['segment_urban'];
    point.properties["event_type"] = event;
    point.properties["event_position"] = event_position;
    point.properties["nodes"] = node;




    this.curvePoints.push(point);
  }

  protected pushCurve(pointsMin: Number = 3, lenMin: number = 10, width: number = 8) {
    if (this.curvePoints.length >= pointsMin) {
      let curve = GeometryHelper.createLineStringFromPoints(this.curvePoints, ModelTurnsHelper.mergeRoadProperties(this.curvePoints));

      if (GeometryHelper.getLength(curve) > lenMin) {
        /** Shift linestring **/
        let coords: number[][] = JSON.parse(JSON.stringify(curve.coordinates)); // FIXME remove this & next line
        curve.coordinates = LineStringHelper.getLineOffset(curve, width / 2).coordinates.map((x, i) => x.concat([coords[i][2]])).map(x => [x[0], x[1]]);

        curve.properties['stroke-width'] = 3;
        curve.properties['stroke-opacity'] = 1;
        delete curve.properties['nodes'];
        this.response.features.push(FeatureHelper.createFeature(curve));


        // return FeatureHelper.createFeature(curve);

          //TODO: ADDED, TO REMOVE ?
          //this.visibilityResultForService.push(curve)

        // }
      }
    }
    this.curvePoints = [];

  }
}
