import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {EventCodeEnum} from 'src/app/enums/event-code-enum.enum';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {WindowEventHelper} from 'src/app/helpers/window-event.helper';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MasterDataService} from 'src/app/services/master-data.service';
import {ProcessingService} from 'src/app/services/processing.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {RiverSignState, RiverSignStoreService} from './river-sign-store.service';
import {RiverSignViewPanelComponent} from "./river-sign-view-panel/river-sign-view-panel.component";
import {MapImageEnum} from "../../../../enums/map-image-enum.enum";

@Injectable({
  providedIn: 'root'
})
export class RiverSignService {

  subscriptions: Subscription[] = [];
  clickBound = false;
  public searching = new BehaviorSubject(false);

  constructor(private riverSignStore: RiverSignStoreService,
              private processingService: ProcessingService,
              private masterdataService: MasterDataService,
              private geojsonService: GeojsonService,
              private toastService: ToastService,
              private mapService: MapService,
              private spinnerService: SpinnerService,
              private panelService: PanelService,) {
    this.riverSignStore.applyAndWatch(this.showElementWrapper.bind(this))
  }

  showElementWrapper(riverSignState: RiverSignState) {
    this.mapService.callWhenAvailable(() => this.showElement(riverSignState));
  }

  get map() {
    return this.mapService.getMap();
  }

  showElement(riverSignState: RiverSignState) {
    if (riverSignState._isShowElement === true) {
      this.spinnerService.getSpinner().addOperation("MainMenuComponent.showElementSign");
      this.mapService.getMap().removeLayer(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_RIVER_SIGN, MapSourceEnum.SOURCE_ELEMENT);


      // TODO includes class filter and exclude filter
      let tileUrl = environment.urlTilesElements;
      tileUrl = tileUrl + '?elementType=' + ElementTypesEnum.ELEMENT_TYPE_RIVER_SIGN;
      tileUrl = tileUrl + '&isPrimary=' + true
        + '&isRelevant=' + riverSignState.isRelevant + '&isConfirmed=' + riverSignState.isRelevant + '&elementQualityMin=' + riverSignState.selectedMinQuality
        + '&elementQualityMax=' + riverSignState.selectedHighQuality + '&excludeElementNames=USELESS';

      let elementClassList: string[];
      const classSelected = riverSignState.classSelected;
      if (classSelected !== undefined) {
        elementClassList = [];
        Object.keys(classSelected)
          .map(key => classSelected[key])
          .forEach(element => {
            elementClassList.push(element.elementClass)
            tileUrl = tileUrl + '&elementClassList=' + element.elementClass;
          });
      }

      if (riverSignState.selectedNetworks.length > 0) {
        riverSignState.selectedNetworks.forEach((network: string) => {
          if (network.length > 0) {
            tileUrl = tileUrl + '&elementNetworkList=' + network;
          }
        });
      }
      let layout = {
        "icon-image": "{element_class}",
        "icon-size": 0.25,
        "icon-rotate": ["get", "bearing"],
        "icon-rotation-alignment": "map",
        "icon-allow-overlap": true,
        "icon-ignore-placement": true
      };

      this.riverSignStore.state.callUrl = tileUrl;

      this.mapService.getMap().addSymbolLayerTiles(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_RIVER_SIGN, tileUrl, MapSourceEnum.SOURCE_ELEMENT, false, layout, ElementTypesEnum.ELEMENT_TYPE_RIVER_SIGN);

      this._onLayerViewElement(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_RIVER_SIGN, MapSourceEnum.SOURCE_ELEMENT);

      this.spinnerService.getSpinner().removeOperation("MainMenuComponent.showElementSign");

      WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_RIVER_SIGN_LOADED);

      if (!this.clickBound) {
        this.mapService.getMap().map.on("click", MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_RIVER_SIGN, e => {
          e.preventDefault();

          const layout = {
            'icon-image': MapImageEnum.ICON_CIRCLE,
            'icon-size': 0.75
          }
          this.map.addSymbolLayer(MapLayerEnum.LAYER_CIRCLE, MapSourceEnum.SOURCE_ELEMENT + MapSourceEnum.SOURCE_CIRCLE, layout,
            this.map.createSymbolLayerDataFromMapboxFeatures(e.features[0]));

          this.viewElement(e.features[0].properties['element_identifier']);
        });

        this.clickBound = true;
      }

    } else {
      WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_RIVER_SIGN_LOADED);
      this.mapService.getMap().removeLayer(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_RIVER_SIGN, MapSourceEnum.SOURCE_ELEMENT);
    }

  }

  _onLayerViewElement(layer, source) {
    this.mapService.getMap().clickLayerMap(layer);
    this.subscriptions.push(this.mapService.getMap().onClickLayerMap.subscribe(e => {
      let features = this.mapService.getMap().map.queryRenderedFeatures(e.point);
      const element = features.find((e) => {
        e.source === source
      });

      if (element) {
        this.viewElement(element.properties.elementIdentifier)
      }
    }));
  }

  viewElement(elementIdentifier: string) {
    if (elementIdentifier !== undefined) {
      this.panelService.push(
        RiverSignViewPanelComponent,
        elementIdentifier
      )
    }
  }
}
