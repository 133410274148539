import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from 'src/app/globals/store';

export type BackgroundType = "SATELLITE" | "IGN_SATELLITE" | "DEFAULT";

export class BackgroundState{
  currentBackground: BackgroundType
}

@Injectable({
  providedIn: 'root'
})
export class BackgroundStoreService extends Store<BackgroundState> {
  constructor() {
    super(BackgroundState)
  }
}
