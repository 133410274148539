import {IFeatureProperties} from '../geojson/ifeature-properties';
import {ISerializable} from '../iserializable';

export class FeatureObjectWayModel implements IFeatureProperties, ISerializable {

  public featureType: string;

  public wayName: string;
  public wayDisplayName: string;
  public wayCity: string;
  public wayCounty: string;
  public wayState: string;
  public wayPostcode: string;
  public wayCountry: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
  toExcel(): any {
    let item: any = {};

    item.wayDisplayName = this.wayDisplayName;
    item.wayName = this.wayName;
    item.wayCity = this.wayCity;

    return item;
  }
}
