import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ListCodeEnum} from 'src/app/enums/list-code-enum.enum';
import {ViewTypesEnum} from 'src/app/enums/view-types-enum.enum';
import {Session} from 'src/app/globals/session';
import {ObjectElementModel} from 'src/app/models/annotation/object-element-model';
import {ListCollectionModel} from 'src/app/models/masterData/list-collection-model';
import {ListValueModel} from 'src/app/models/masterData/list-value-model';
import {AnnotationService} from 'src/app/services/annotation.service';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MasterDataService} from 'src/app/services/master-data.service';
import {VersionService} from 'src/app/services/versions/version.service';
import {ViewerService} from 'src/app/services/viewer.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {environment} from 'src/environments/environment';
import {ViewerStoreService} from '../../viewer-entry/viewer-store.service';
import {ViewVersionComponent} from "../../../sharedViews/view-version/view-version.component";

declare let OpenLocationCode: any;

@Component({
  selector: 'app-sign-view-panel',
  templateUrl: './sign-view-panel.component.html',
  styleUrls: ['./sign-view-panel.component.css']
})
export class SignViewPanelComponent implements OnInit, PanelBase<string> {

  constructor(private annotationService: AnnotationService,
              public session: Session,
              private geojsonService: GeojsonService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private masterDataService: MasterDataService,
              private viewerService: ViewerService,
              private versionService: VersionService,
              private panelService: PanelService,
              private spinnerService: SpinnerService,
              private mapService: MapService,
              private viewerStore: ViewerStoreService) {
  }

  data: string;
  element: ObjectElementModel = null;
  elementIcon: string;
  elementImage: string;
  viewType: ViewTypesEnum = ViewTypesEnum.VIEW_PANO_360_M;

  _isVisible: boolean = false;
  onEdition: boolean = false;

  lowQuality: boolean = false;
  mediumQuality: boolean = false;
  highQuality: boolean = false;

  qualificationNames: string[] = ['year_build', 'builder', 'film_class', 'anchor_type', 'implement_type', 'location_marker', 'direction'];
  qualifications = new Map<string, string>()
  builder = "";
  filmClass = "";
  rawText: string[] = [];
  implementType = "";
  anchorType = "";
  size = "";
  heightSp = "";
  locationMarker = "";
  direction = "";

  jpg: string;
  medium: string;
  large: string;

  showQualification = false;
  showMetrics = true;

  showLong: string;
  showLat: string;

  openLocCode: string;
  qualityList: ListValueModel[];

  _versions = null;
  _showVersions = false;

  ngOnInit() {
    this.masterDataService.getList(ListCodeEnum.QUALITY_THRESHOLD).subscribe((response: ListCollectionModel) => {
      if (response) {
        this.qualityList = response.lists[0].listValues;
      }
    });
    for(let qualificationName of this.qualificationNames){
      this.qualifications.set(qualificationName, '');
    }
    this.selectedElement = this.data;
  }

  ngOnDestroy(): void {
  }

  set selectedElement(elementIdentifier: string) {

    this._versions = null;
    this._showVersions = false;
    if (elementIdentifier !== undefined) {

      this.versionService
      .getObjectElementVersions(elementIdentifier)
      .subscribe(response => this._versions = response != null && response.length > 0 ? response : null);
      this.annotationService.getObjectElement(elementIdentifier).subscribe((objectElement: ObjectElementModel) => {
        this.element = objectElement;

        let openLocCode = new OpenLocationCode();
        this.showLat = this.element.elementGeometry.coordinates[1];
        this.showLong = this.element.elementGeometry.coordinates[0];
        //its possible to have more precise data if we add 11 as third parameter
        this.openLocCode = openLocCode.encode(this.element.elementGeometry.coordinates[0], this.element.elementGeometry.coordinates[1]);

        if (this.element.elementClassDetail) {
          this.elementIcon = environment.urlMedia + this.element.elementClassDetail.classIconFilePath;
        } else {
          this.elementIcon = undefined;
        }

        if (this.element.elementProperties['qualification'] != undefined){
          for(let qualificationName of this.qualifications.keys()){
            if(qualificationName in this.element.elementProperties['qualification']){
              this.qualifications.set(qualificationName, this.element.elementProperties['qualification'][qualificationName]);
            }
          }
          this.showQualification = true;
        }
        if (this.element.elementProperties['rawText'] != undefined) {
          this.rawText = this.element.elementProperties['rawText'];
          this.showQualification = true;
        }

        if (this.element.elementProperties['metrics'] != undefined) {
          this.size = this.element.elementProperties['metrics']['size'];
          this.heightSp = this.element.elementProperties['metrics']['height_sp'];
          this.showMetrics = true;
        } else {
          this.showMetrics = false;
        }
      });
      this.show();
    } else {
      this.show(false);
    }
  }

  get map() {
    return this.mapService.getMap();
  }

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  setNewElementQuality(e) {
    this.element = e;
    this.spinner.addOperation("ViewSignComponent.updateElement");

    this.annotationService.updateObjectElement(this.element).subscribe(response => {
      if (response) {
        // this.doReloadElements.emit(true);
      }
      this.spinner.removeOperation("ViewSignComponent.updateElement");

    });
  }

  showVersions() {
    this.panelService.push(
      ViewVersionComponent,
      this._versions
    )
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if (show) {
      this.onEdition = false;
    } else {
      this.panelService.close();
    }
  }
}
