import * as turf from "@turf/turf";
import {GeometryHelper} from "./geometry.helper";
import {MathHelper} from "./math.helper";
import {GeometryModel} from "../models/geojson/geometry-model";
import {Vector3dModel} from "../models/math/vector3d-model";
import {GeometryTypeEnum} from "../enums/geometry-type-enum.enum";

export class CartesianHelper {

  static convertFromPoint(origin: GeometryModel, point: GeometryModel): Vector3dModel {
    let distance: number = turf.distance(origin.toTurf(), point.toTurf(), {units: "meters"});
    let angle: number = MathHelper.degToRad(turf.bearing(origin.toTurf(), point.toTurf()));

    let x = distance * Math.cos(angle);
    let y = distance * Math.sin(angle);
    let z = point.coordinates[2] - origin.coordinates[2];

    return new Vector3dModel(x, y, z);
  }

  static convertToCartesian(pointA: GeometryModel, pointB: GeometryModel) {
    let latMean: number = (pointA.coordinates[1] + pointB.coordinates[1]) / 2;

    pointA.coordinates = [pointA.coordinates[0] * Math.cos(latMean), pointA.coordinates[1]];
    pointB.coordinates = [pointB.coordinates[0] * Math.cos(latMean), pointB.coordinates[1]];
    return latMean; // has to be used in order to do the reverse operation
  }

  static convertToGeo(pointA: GeometryModel, pointB: GeometryModel, latMeanCached: number) {
    pointA.coordinates = [pointA.coordinates[0] / Math.cos(latMeanCached), pointA.coordinates[1]];
    pointB.coordinates = [pointB.coordinates[0] / Math.cos(latMeanCached), pointB.coordinates[1]];
  }

  static convertFromLineString(origin: GeometryModel, linestring: GeometryModel): Vector3dModel[] {
    let result: Vector3dModel[] = [];

    if (GeometryHelper.isValidGeometryType(linestring, GeometryTypeEnum.LINESTRING)) {
      for (let point of linestring.coordinates) {
        result.push(CartesianHelper.convertFromPoint(origin, GeometryHelper.createPoint(point)));
      }
    }

    return result;
  }
}
