import {Injectable} from '@angular/core';
import {Options} from 'ng5-slider';
import {Subscription} from 'rxjs';
import {Store} from 'src/app/globals/store';
import {ElementClassModel} from 'src/app/models/masterData/element-class-model';
import {environment} from 'src/environments/environment';

export class SignState {

  elementType: string = 'SIGN';

  countSelected: number = 0;

  selectedNetworks: string[] = [];

  environment = environment;
  subscriptions: Subscription[] = [];

  classGroups: string[] = [];
  classSelected: { [key: string]: ElementClassModel } = {};

  isRelevant: boolean = true;
  isElementRemoved: boolean = true;

  selectedMinQuality: number = 0;
  selectedHighQuality: number = 100;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  selectedElement: string;
  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear;

  excludeCategories: string[] = [];
  excludeElementNames: string[] = ['USELESS'];

  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };

  callUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class SignStoreService extends Store<SignState> {

  constructor() {
    super(SignState)
  }


}
