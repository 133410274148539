import {RoadList} from "../linked-list/road-list";
import {RoadNode} from "../linked-list/road-node";

import {ArrayHelper} from "./array.helper";
import {FeatureHelper} from "./feature-helper";
import {GeometryHelper} from "./geometry.helper";

import {GeometryModel} from "../models/geojson/geometry-model";
import {FeatureModel} from "../models/geojson/feature-model";

import {GraphHelper, createNode} from "./graph.helper";

// import {IoGeojsonService} from "../services/io-geojson-service";
// import {GeojsonDao} from "../dao/geojson-dao";

// import {
//   importSegmentsAroundWay,
//   computeFeatureRandomColorFromNode,
//   computeFeatureRandomColorFromGeo,
//   importSegmentsAroundPoint,
//   importPrPointsOnWay,
//   importSegmentsGeoPoint,
//   importSegmentsInSquare
// } from "../services/tools"
// import {ModelTurnsHelper} from "./model-turns.helper";

import {AxisEnum} from "../enums/axis-enum";
import {GraphBasedToolsService} from "./graph-based-tools.service";
// import {Injectable} from "@angular/core";

export interface ICommand {
  run(segments, speedLimit, wayWidth, driverHeight, args?: any): Promise<any> ;
}
// @Injectable()

export class GraphBasedHelper implements ICommand {

  // protected ioGeojsonService: IoGeojsonService = new IoGeojsonService();

  protected args: any;
  protected response: FeatureModel[] = [];

  /** DEBUG **/
  protected debug_export_original_segments: boolean = false;
  protected debug_export_graph: boolean = false;
  protected debug_export_graph_merged: boolean = false;
  private debug_export_merged_segments_lanes: boolean = false;
  private debug_export_dual_axis: boolean = false;
  protected debug: boolean = this.debug_export_original_segments || this.debug_export_graph || this.debug_export_graph_merged || this.debug_export_merged_segments_lanes || this.debug_export_dual_axis;
  /** ===== **/

  protected fixIgnData: boolean = true;  // Will delete some points considered as misplaced in line strings

  protected segments: GeometryModel[] = [];
  protected prPoints: GeometryModel[] = [];
  protected fullSegments: GeometryModel[]; // These segments are linked from next property and their property field connect the coordinates to the graph's nodes

  public graphHelper: GraphHelper;

  public log: Function = (message?: any, ...optionalParams: any[]) => {
    if (!this.testMode)
      console.log(message, ...optionalParams)
  };

  constructor(public asAService: boolean = false, public testMode: boolean = false, public way_identifier: string = undefined) {
  }

  public getSegmentsCount(): number {
    return this.segments.length;
  }

  async run(segments, speedLimit, wayWidth, driverHeight, args?: any): Promise<any> {

    this.args = args;

    this.segments = segments;
    if (args && args['way_identifier'])

      this.way_identifier = args['way_identifier'];
    if (!this.asAService)

      await this.loadData();  // Import segments and pr points

    return;
  }

  private async loadData() {
    /**
     * This function load segment to variable 'this.segments' depending on the data source passed as param.
     */

    if (this.args['input_file'] !== undefined) {

      this.log("Import from file", this.args['input_file'])
      // this.segments = this.ioGeojsonService.fetchLineStringsGeoJsonFile(this.args['input_file']);

    } else if ((this.args['geo_point']) || (this.args['way_identifier']) || (this.args['longitude'] && this.args['latitude']) ||
      (this.args['pointA_coordX'] && this.args['pointA_coordY'] && this.args['pointB_coordX'] && this.args['pointB_coordY'])) {

      this.log("Import from db", this.args['database']);

      // let geojsonDao = new GeojsonDao(this.args['host'], this.args['username'], this.args['password'], this.args['database']);

      this.args['range'] = this.args['range'] == undefined ? 500 : this.args.range;



      let query: string = "";
      // if (this.args['way_identifier'])
      //   query = await importSegmentsAroundWay(this.args['way_identifier'], geojsonDao, this.args['range']);
      // if (this.args['longitude'] && this.args['latitude'])
      //   query = await importSegmentsAroundPoint(this.args['longitude'], this.args['latitude'], this.args['range']);
      // if (this.args['geo_point'])
      //   query = await importSegmentsGeoPoint(this.args['geo_point'], this.args['range'])
      // if ((this.args['pointA_coordX'] && this.args['pointA_coordY'] && this.args['pointB_coordX'] && this.args['pointB_coordY']))
      //   query = await importSegmentsInSquare(this.args)


      // console.log(query)
      // let segmentsGeojson = await geojsonDao.fetchGeoJson(query, 'segment_geojson');
      // this.segments = FeatureHelper.fetchLineStrings(segmentsGeojson);


      // if (this.args['command'] == 'compute-pr-segments') {
      //   query = await importPrPointsOnWay(this.args['way_identifier'])
      //   let prPointsGeojson = await geojsonDao.fetchGeoJson(query, 'pr_geo');
      //   this.prPoints = FeatureHelper.fetchPoints(prPointsGeojson);
      // }

    } else {
      console.error('Missing source input');
    }

    this.log("Import done. Segments:", this.segments.length);
  }

  protected process(): boolean {
    return false;
  }

  public processGraph(createNodeCallBack: Function = createNode,
                      forceUseLanesForNext: boolean = false,
                      forceUseClassForNext: boolean = false): boolean {
    /**
     * This function call the graph service to compute it.
     * It includes some debug process to export segments at specific steps of the processing.
     * When debug mode is activated processGraph return false so the program is stopped and no further feature is computed above the debug features.
     */

    // /** DEBUG export raw segments **/
    // if (this.debug_export_original_segments) {
    //   console.log('\x1b[33m%s\x1b[0m', "DEBUG MODE: export original segments");
    //   this.exportSegmentsArray(this.segments);
    //   return false;
    // }
    //
    // /** DEBUG export raw segments **/
    // if (this.debug_export_dual_axis) {
    //   console.log('\x1b[33m%s\x1b[0m', "DEBUG MODE: export original segments");
    //   this.exportSegmentsArrayDualAxis(this.segments);
    //   return false;
    // }

    // In order to make the computation easier, split segments looping on themselves into two distinct ones
    this.segments.forEach(s => {
      if (ArrayHelper.equals(s.coordinates[0].slice(0, 2), s.coordinates[s.coordinates.length - 1].slice(0, 2))) {
        this.segments.push(s.clone());
        this.segments[this.segments.length - 1].coordinates = s.coordinates.slice(Math.round(s.coordinates.length / 2) - 1, s.coordinates.length);
        this.segments[this.segments.length - 1].properties.segment_identifier = s.properties.segment_identifier + '_bis';
        s.coordinates = s.coordinates.slice(0, Math.round(s.coordinates.length / 2))
      }
    });

    // Remove segments that are contained in others.
    this.segments = this.segments.filter(s =>
      !this.segments.some(s2 => s !== s2 && ArrayHelper.isIn(s.coordinates, s2.coordinates))
    );

    // Compute Graph
    this.graphHelper = new GraphHelper(this.segments, createNodeCallBack, false, false, this.testMode);

    /** DEBUG export graph **/
    if (this.debug_export_graph) {
      console.log('\x1b[33m%s\x1b[0m', "DEBUG MODE: export graph");
      this.graphHelper.exportGraph(this.response);
      return false;
    }

    /** DEBUG export graph with next segments merged **/
    if (this.debug_export_graph_merged) {
      console.log('\x1b[33m%s\x1b[0m', "DEBUG MODE: export graph merged");
      // this.graphHelper.exportGraphMerged(this.response);
      return false;
    }

    return true;
  }

  protected computeWithGraph(graph: RoadList<GeometryModel>, axis: AxisEnum, way_identifier?: string): boolean {
    /**
     * From the graph, creates a list of geometry model that represents different road parts.
     * These road parts are composed of ign segments considered as 'next' in the graph. So they have about the same properties.
     * The output of this command is stored in this.fullSegments.
     *
     * In addition this command fix IGN points considered as misplaced by removing them.
     */
    graph.changeStateFromHeads();
    // Get Start Point of each road composed of 'next'
    let startPoints: RoadNode<GeometryModel>[] = GraphBasedToolsService.getStartPoints(graph, way_identifier, axis, true);
    graph.changeStateFromHeads();
    // Merge segments in each road
    this.fullSegments = GraphBasedToolsService.getMergedSegmentsFromStartPoints(startPoints, way_identifier, axis);

    this.fullSegments = this.fullSegments.filter(s =>
        !this.fullSegments.some(s2 => s !== s2 && ArrayHelper.isIn(s.coordinates, s2.coordinates))
    );

    if (this.fixIgnData)
      this.fixIgnMisplacedPoints(this.fullSegments, 0.25, 15);

    /** DEBUG export full segments **/
    if (this.debug_export_merged_segments_lanes) {
      console.log('\x1b[33m%s\x1b[0m', "DEBUG MODE: export merged segments lane. Export", this.fullSegments.length, "full segments")
      this.exportSegmentsArray(this.fullSegments, true);
      return false;
    }
    /** ========================== **/

    return true;
  }

  protected exportSegmentsArray(segments: GeometryModel[], removeProperties: boolean = false) {
    for (let s of segments) {
      if (removeProperties)
        s.properties = {}
      // computeFeatureRandomColorFromGeo(s, this.response);
    }
  }

  protected exportSegmentsArrayDualAxis(segments: GeometryModel[], removeProperties: boolean = false) {
    for (let s of segments) {
      if (s.properties.segment_axis != 'DUAL_AXIS')
        continue;
      if (removeProperties)
        s.properties = {}
      // computeFeatureRandomColorFromGeo(s, this.response);
    }
  }

  protected fixIgnMisplacedPoints(segments: GeometryModel[], min: number, maxDistance: number = 20) { // WARNING this doesn't remove the same points whether it's ASC or DSC
    // function isMisplaced(index: number, segment: GeometryModel): boolean {
      // return index > 0 && index + 2 < segment.coordinates.length &&
        // GeometryHelper.getPointToLineDistance(GeometryHelper.createPoint(segment.coordinates[index + 1]), GeometryHelper.createLineString([segment.coordinates[index], segment.coordinates[index + 2]])) < min &&
        // (GeometryHelper.getDistance(GeometryHelper.createPoint(segment.coordinates[index + 1]), GeometryHelper.createPoint(segment.coordinates[index])) < maxDistance ||
        //   GeometryHelper.getDistance(GeometryHelper.createPoint(segment.coordinates[index + 2]), GeometryHelper.createPoint(segment.coordinates[index])) < maxDistance) &&
        // !(index > 0 && isMisplaced(index - 1, segment))
    // }

    console.log("Fix IGN data by removing some points considered as misplaced");
    // for (let segment of segments) {
    //   ModelTurnsHelper.fixDifferentZOnSamePoint(ModelTurnsHelper.dropDuplicates(segment));
    //   let len: number = 0;
    //
    //   do {
    //     len = segment.coordinates.length;
        // segment.properties.nodes = segment.properties.nodes.filter((_: any, index: number) => !isMisplaced(index, segment))
        // segment.coordinates = segment.coordinates.filter((_: any, index: number) => !isMisplaced(index, segment))
      // } while (len != segment.coordinates.length)
    // }
  }

  static nodesLen(buf: RoadNode<GeometryModel>[]): number {
    return GeometryHelper.getSumLength(buf.map(n => n.dt))
  }
}

