import {ISerializable} from '../iserializable';
import {SignModel} from "./sign-model";

export class ElementPropertiesModel implements ISerializable {

  public numDossier: string;
  public commentaire:string;
  public photos: any[];
  public signs : SignModel[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }

}
