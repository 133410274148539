import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ChangeContext, Options} from 'ng5-slider';
import {Subscription, throwError} from 'rxjs';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {EventCodeEnum} from 'src/app/enums/event-code-enum.enum';
import {GeometryTypeEnum} from 'src/app/enums/geometry-type-enum.enum';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {Session} from 'src/app/globals/session';
import {GeometryHelper} from 'src/app/helpers/geometry.helper';
import {WindowEventHelper} from 'src/app/helpers/window-event.helper';
import {FeatureCollectionModel} from 'src/app/models/geojson/feature-collection-model';
import {ElementClassCollectionModel} from 'src/app/models/masterData/element-class-collection-model';
import {ElementClassModel} from 'src/app/models/masterData/element-class-model';
import {ListCollectionModel} from 'src/app/models/masterData/list-collection-model';
import {ListValueModel} from 'src/app/models/masterData/list-value-model';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MasterDataService} from 'src/app/services/master-data.service';
import {ProcessingService} from 'src/app/services/processing.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import * as turf from "@turf/turf";
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {LaneStoreService} from '../lane-store.service';

declare let gtag: Function;
@Component({
  selector: 'app-lane-entry-panel',
  templateUrl: './lane-entry-panel.component.html',
  styleUrls: ['./lane-entry-panel.component.css']
})
export class LaneEntryPanelComponent implements OnInit, PanelBase<any> {

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }

  @Output() showMenuLane = new EventEmitter<boolean>();

  constructor(public session: Session,
    private processingService: ProcessingService,
    private geojsonService: GeojsonService,
    private masterdataService: MasterDataService,
    private spinnerService: SpinnerService,
    private mapService: MapService,
    private toastService: ToastService,
    private panelService: PanelService,
    private laneStore: LaneStoreService) {
  }
  data: any;

  selectedNetworks: string[] = [];

  countSelected: number;

  productColor: string[] = [];
  productAxis: string[] = [];
  productType: string[] = [];

  productColorValues: ListValueModel[] = [];
  productAxisValues: ListValueModel[] = [];
  productTypeValues: ListValueModel[] = [];


  environment = environment;
  subscriptions: Subscription[] = [];


  classList: ElementClassModel[] = [];
  classGroups: string[] = [];
  classSelected: { [key: string]: ElementClassModel } = {};
  numberOfSelectClasses = 0;

  isRelevant: boolean = true;
  isPrimary: boolean = true;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  selectedMinQuality: number = 0;
  selectedHighQuality: number = 100;

  qualitySliderOptions: Options = {
    floor: 0,
    ceil: 100,
    showOuterSelectionBars: true
  };
  selectedElement: string;

  arrayLaneContinue: string[];
  arrayLaneDotted: string[];

  onCreate: boolean = false;

  ngOnInit() {

    this.countSelected = 0;
    this.subscriptions.push(this.session.onChangeWorkspace.subscribe(() => {
      this.refresh();
    }));

    this.masterdataService.getLists('GROUND').subscribe((listCollection: ListCollectionModel) => {
      listCollection.lists.forEach((list) => {
        if (list !== undefined) {
          if (list.listCode === 'PRODUCT_TYPE_GROUND') {
            this.productTypeValues = list.listValues;
          } else if (list.listCode === 'PRODUCT_COLOR_GROUND') {
            this.productColorValues = list.listValues;
          } else if (list.listCode === 'PRODUCT_AXIS_GROUND') {
            this.productAxisValues = list.listValues;
          }
        }
      });
    });

    this.geojsonService.elementEmiter.subscribe(element => {
      if (element !== undefined) {
        this.selectedElement = element.elementIdentifier;
      }
    });
    Object.keys(this.laneStore.state).forEach(key => {
      this[key] = this.laneStore.state[key]
    })
    this.laneStore.useProxy(this, [{ keys: ['selectedMinQuality', 'selectedHighQuality'], delay: 500, type: 'MULTIPLE' }])
    this.loadClasses();
    this._isShowElement = true;
  }

  loadClasses() {
    if (!this.classList.length) {

      this.spinner.addOperation("MenuLaneComponent.loadClasses");

      this.masterdataService.getElementClass(ElementTypesEnum.ELEMENT_TYPE_LANE).subscribe((response: ElementClassCollectionModel) => {
        if (response && response.elementClasses.length > 0) {
          this.classList = response.elementClasses;

          this.classList.forEach(item => {
            if (this.classGroups.indexOf(item.classCategory) === -1) {
              this.classGroups.push(item.classCategory);
            }
          });

        } else {
          this.toast.showToastWarn();
        }

          this.spinnerService.getSpinner().removeOperation("MenuLaneComponent.loadClasses")
      },
        (_error => {
          this.spinnerService.getSpinner().removeOperation("MenuLaneComponent.loadClasses")
          return throwError('error while loading classes')
        })
        );
    }
  }

  toggleFilterByClass(classElement: ElementClassModel) {
    if (!this.isClassSelected(classElement)) {
      const nextclassSelected = { ...this.classSelected, [classElement.elementClass]: classElement };
      this.classSelected = nextclassSelected;
      this.numberOfSelectClasses++;
    } else {
      const classSelected = { ...this.classSelected };
      delete classSelected[classElement.elementClass];
      this.classSelected = classSelected;
      this.numberOfSelectClasses--;
    }
  }

  clear() {
    this.laneStore.reset();
    this.show(false)
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if (!show) {
      this.panelService.pop();
    }
  }

  viewElement(elementIdentifier: string) {
    if (elementIdentifier !== undefined) {
      this.selectedElement = elementIdentifier;
      this.countSelected = 0;
      this.show(false);
    }


    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ViewLaneElement');
    }
  }

  refresh() {
    this.laneStore.refresh();
  }


  isClassSelected(classModel: ElementClassModel) {
    return this.classSelected[classModel.elementClass]
  }

  updateQualityValues($event: ChangeContext) {
    this.selectedMinQuality = $event.value;
    this.selectedHighQuality = $event.highValue;
  }

}
