import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
@Pipe({
  name:
    'elementQualityPipe'
})

export class ElementQualityPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }
  transform(elementQuality: number): string {
    let elementQualityTransform = '';

    if (elementQuality > 90) {
      this.translate.get('common.qualityHigh').subscribe((response: string) => {
        elementQualityTransform = response;
      })
      // elementQualityTransform = 'Très satisfaisant';
    } else if (elementQuality > 50) {
      this.translate.get('common.qualityMediumPlus').subscribe((response: string) => {
        elementQualityTransform = response;
      })
      // elementQualityTransform = 'Satisfaisant';
    } else if (elementQuality > 10) {
      this.translate.get('common.qualityMediumLess').subscribe((response: string) => {
        elementQualityTransform = response;
      })
      // elementQualityTransform = 'Insuffisant';
    } else {
      this.translate.get('common.qualityLow').subscribe((response: string) => {
        elementQualityTransform = response;
      })
      // elementQualityTransform = 'Très insuffisant'
    }
    return elementQualityTransform;
  }
}
