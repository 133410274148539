import {Observable, throwError} from 'rxjs';
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpHeadersEnum} from "../enums/http-headers-enum.enum";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from "rxjs/operators";
import {DoneResponseModel} from "../models/done-response-model";
// import {LoaderService} from "./loader.service";
import {MessageService} from "primeng/api";
import {MessageHelper} from "../helpers/message.helper";
import {MessageSeverityEnum} from "../enums/message-severity-enum.enum";
// import {ElementCriterion} from "../models/criterion/element-criterion";
// import {ObjectElementModel} from "../models/common/object-element-model";
// import {ObjectElementResponseModel} from "../models/common/object-element-response-model";
// import {SegmentCollectionModel} from "../models/datasource/segment-collection-model";
// import {SegmentModel} from "../models/datasource/segment-model";
import {FeatureCollectionModel} from "../models/geojson/feature-collection-model";
import {GeometryModel} from "../models/geojson/geometry-model";
import {FeatureModel} from "../models/geojson/feature-model";
import {Session} from "../globals/session";

@Injectable({
    providedIn: 'root'
})
export class DatasourceService {

    constructor(private session: Session, private httpService: HttpClient, private messageService: MessageService) {
    }

    getMainSegments(wayIdentifier: string): Observable<FeatureCollectionModel> {
        const url = environment.urlGetMainSegments;

        // this.loaderService.addOperation('getMainSegments');


        const headers = new HttpHeaders()
            .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
            
        const options = {headers: headers};

        // no need header because no need session for openData
        // responseLevel out of header
        return this.httpService.post(url, {
            responseLevel: [
                ResponseLevelEnum.ALL
            ],
            wayIdentifier: wayIdentifier
        }, options).pipe(map(data => {

                // this.loaderService.removeOperation('getMainSegments');
                return new FeatureCollectionModel().deserialize(data);
            }),
            catchError(error => {


                // this.loaderService.removeOperation('getMainSegments');
                // this.messageService.add(MessageHelper.createMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
                return throwError('Segments not found');
            })
        );
    }

    getAnnotation(wayIdentifier: string): Observable<FeatureCollectionModel> {
        const url = environment.getSegmentsComputed;

        // this.loaderService.addOperation('getSegmentsComputed');


        const headers = new HttpHeaders()
            .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
            
        const options = {headers: headers};

        // no need header because no need session for openData
        // responseLevel out of header
        return this.httpService.post(url, {
            responseLevel: [
                ResponseLevelEnum.ALL
            ],
            wayIdentifierOrigin: wayIdentifier
        }, options).pipe(map(data => {

                // this.loaderService.removeOperation('getSegmentsComputed');
                return new FeatureCollectionModel().deserialize(data);
            }),
            catchError(error => {


                // this.loaderService.removeOperation('getSegmentsComputed');
                this.messageService.add(MessageHelper.createMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
                return throwError('Segments Compute not found');
            })
        );
    }
}
