import {ISerializable} from '../iserializable';
import {GeometryModel} from "../geojson/geometry-model";
import {SnapshotModel} from "./snapshot-model";

export class CaptureModel implements ISerializable {

  public captureIdentifier: string;
  public captureType: string;
  public captureOrder: number;
  public captureTimestamp: Date;
  public captureVideoFrame: number;
  public captureOrientation: number;
  public captureGeometry: GeometryModel;
  public captureDetails: any;
  public snapshot: SnapshotModel;

  deserialize(input: any): this {
    Object.assign(this, input);

    this.captureTimestamp = new Date(input.captureTimestamp);

    if (input.captureGeometry) {
      this.captureGeometry = new GeometryModel().deserialize(input.captureGeometry);
    }

    if (input.snapshot) {
      this.snapshot = new SnapshotModel().deserialize(input.snapshot);
    }

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
