import {Component, OnInit} from '@angular/core';
import {Options} from 'ng5-slider';
import {Subscription, throwError} from 'rxjs';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {Session} from 'src/app/globals/session';
import {ElementClassModel} from 'src/app/models/masterData/element-class-model';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MasterDataService} from 'src/app/services/master-data.service';
import {ProcessingService} from 'src/app/services/processing.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {RiverSignState, RiverSignStoreService} from '../river-sign-store.service';
import * as turf from "@turf/turf";
import {FeatureCollectionModel} from 'src/app/models/geojson/feature-collection-model';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {GeometryTypeEnum} from 'src/app/enums/geometry-type-enum.enum';
import {GeometryHelper} from 'src/app/helpers/geometry.helper';
import {EventCodeEnum} from 'src/app/enums/event-code-enum.enum';
import {WindowEventHelper} from 'src/app/helpers/window-event.helper';
import {ElementClassCollectionModel} from 'src/app/models/masterData/element-class-collection-model';


@Component({
  selector: 'app-river-sign-entry-panel',
  templateUrl: './river-sign-entry-panel.component.html',
  styleUrls: ['./river-sign-entry-panel.component.css']
})
export class RiverSignEntryPanelComponent implements OnInit, PanelBase<any> {

  get map(){
    return this.mapService.getMap();
  }

  constructor(public session: Session,
    private processingService: ProcessingService,
    private masterdataService: MasterDataService,
    private geojsonService: GeojsonService,
    private toastService: ToastService,
    private mapService: MapService,
    private spinnerService: SpinnerService,
    private panelService: PanelService,
    public riverSignStore: RiverSignStoreService) {
  }
  data: any;

  countSelected: number;

  selectedNetworks: string[] = [];

  environment = environment;
  subscriptions: Subscription[] = [];

  classList: ElementClassModel[] = [];
  classGroups: string[] = [];
  classSelected: { [key: string]: ElementClassModel } = {};
  numberOfSelectClasses = 0;

  isRelevant: boolean = true;
  isElementRemoved: boolean = true;

  selectedMinQuality: number = 0;
  selectedHighQuality: number = 100;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;
  _showClassMenu: boolean = false;

  qualitySliderOptions: Options = {
    floor: 0,
    ceil: 100,
    showOuterSelectionBars: true
  };
  selectedElement: string;
  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear;
  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };
  onCreate: boolean = false;

  ngOnInit() {
    this.countSelected = 0;

    this.geojsonService.elementEmiter.subscribe(element => {
      if (element !== undefined) {
        this.selectedElement = element.elementIdentifier;
      }
    });


    this.riverSignStore.useProxy(this);
    this.loadClasses();

    this.numberOfSelectClasses = Object.keys(this.classSelected).length;

    this._isShowElement = true;
    console.warn(this.riverSignStore.state)

  }

  loadClasses() {
    if (!this.classList.length) {
      this.spinnerService.getSpinner().addOperation("MenuSignComponent.loadClasses");
      this.masterdataService.getElementClass(ElementTypesEnum.ELEMENT_TYPE_RIVER_SIGN).subscribe((response: ElementClassCollectionModel) => {
        if (response && response.elementClasses.length > 0) {
          this.classList = response.elementClasses;
          this.classList.forEach(item => {
            this.mapService.addMissingElementClassImage(item.elementClass, item.classIconFilePath);
            if (this.classGroups.indexOf(item.classCategory) === -1) {
              this.classGroups.push(item.classCategory);
            }
          });

        } else {
          this.toastService.getToast().showToastWarn();
        }

        this.spinnerService.getSpinner().removeOperation("MenuSignComponent.loadClasses");
      },
        (_error => {
          this.spinnerService.getSpinner().removeOperation("MenuSignComponent.loadClasses")
          return throwError('error while loading classes')
        })
        );
    }
  }

  toggleFilterByClass(classElement: ElementClassModel) {
    if (!this.isClassSelected(classElement)) {
      const nextclassSelected = { ...this.classSelected, [classElement.elementClass]: classElement };
      this.classSelected = nextclassSelected;
      this.numberOfSelectClasses++;
    } else {
      const classSelected = { ...this.classSelected };
      delete classSelected[classElement.elementClass];
      this.classSelected = classSelected;
      this.numberOfSelectClasses--;
    }
  }

  show() {
    const nextState = new RiverSignState();
    Object.keys(nextState).forEach(k => nextState[k] = this[k]);
    nextState._isShowElement = true;
    this.riverSignStore.state = nextState;
  }

  clear() {
    this.riverSignStore.reset();
    this.close();
  }

  close() {
    this.panelService.pop();
  }

  isClassSelected(classModel: ElementClassModel) {
    return this.classSelected[classModel.elementClass]
  }

}
