<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_GROUND')"
    appWorkspaceRequired
    [ngClass]="{'itemChecked':groundStore.state._isShowElement, 'itemUnchecked': !groundStore.state._isShowElement}"
    (click)="openMenuGround()">
    <i class="fa ca-cross"></i>
    <span class="text">{{ 'groundEntry.title' | translate }}</span><br />
    <a role="button" *ngIf="groundStore.state._isShowElement">
        <span (click)="close($event)" class="pi pi-times ng-tns-c48-2">
        </span>
    </a>
</li>
