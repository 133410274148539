import moment                         from "moment"
import { Component, OnInit          } from '@angular/core';
import { FeatureCollectionModel     } from 'src/app/models/geojson/feature-collection-model';
import { FeatureModel               } from 'src/app/models/geojson/feature-model'
import { FeatureObjectRoadFsiModel  } from 'src/app/models/geojson/feature-object-road-fsi-model';
import { PanelBase                  } from 'src/app/shared/panel/component/PanelBase';
import { PanelService               } from 'src/app/shared/panel/panel.service';

@Component({
  selector      : 'app-road-fsi-view-panel',
  templateUrl   : './road-fsi-view-panel.component.html',
  styleUrls     : ['./road-fsi-view-panel.component.css']
})

export class RoadFsiViewPanelComponent implements OnInit, PanelBase<FeatureCollectionModel> {

    constructor(
        private panelService    : PanelService
    ) { }


    data                : FeatureCollectionModel;
    element             : FeatureModel              = new FeatureModel();
    moment              : any;
    t_hour              : Number;
    age_in_y            : Number;
    p                   : FeatureObjectRoadFsiModel = new FeatureObjectRoadFsiModel();
    _isVisible          : boolean                   = false;

    ngOnInit() {
        this.setElement(this.data);
        this.moment = moment
    }

    ngOnDestroy(): void {
    }

    show(show:boolean = true) {
        this._isVisible = show;
        if(!show){
            this.panelService.pop();
        }
    }

    setElement(element: any) {
        if (element !== undefined) {
            this.p      = element ;
            this.t_hour = (new Date(this.p.t_accident)).getHours()
            let json = JSON.parse(this.p.crash_raw);
            if(!json){
                this.p.data= [];
            } else{
                this.p.data = json.data ? json.data[0] : json;
            }
            if(this.p.data['an_nais'] && this.p.t_accident){
                let t1: any     = (new Date(this.p.t_accident)).getFullYear();
                let t0: any     = this.p.data['an_nais'];
                this.age_in_y   = t1 - t0 
            }
            this.show();
        } else {
            this.show(false);
        }
    }
}
