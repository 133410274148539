<div *ngIf="_isVisible">
  <div class="ui-g" (click)="toggleShowClass()" style="cursor: pointer;">
    <div class="ui-g-1">
      <img src="{{environment.urlMedia}}/icon/{{_iconFolder}}/element/{{_selectedElement.elementName | uppercase}}.png"
           style="width:24px;display:inline-block;margin:5px 0 2px 5px"/>
    </div>
    <div class="ui-g-10" class="elementSelected">{{_selectedElement.elementName  | elementNameGround}}</div>
    <div class="ui-g-1"><i class="fa" [ngClass]="{'fa-plus-square-o': !_showClass, 'fa-minus-square-o': _showClass}"></i></div>
  </div>
  <p-accordion *ngIf="_showClass">
    <p-accordionTab *ngFor="let classGroup of classGroups">
      <p-header>
        <img width="18" align="middle"
             src="{{environment.urlMedia}}/icon/{{_iconFolder}}/category/{{classGroup | uppercase}}.png" alt="{{classGroup}}"
             class="groupImage">
        <span class="groupHeader">{{classGroup  | elementGroupGround}}</span>
      </p-header>
      <div *ngFor="let itemClass of classList | filterByFieldValue:{field: 'classGroup', value:classGroup}"
           (click)="onSelectClass(itemClass)" class="ui-helper-clearfix"
           style="border-bottom:1px solid #D5D5D5; cursor: pointer;">
        <img src="{{environment.urlMedia}}/icon/{{_iconFolder}}/element/{{itemClass.classLabel | uppercase}}.png"
             style="width:24px;display:inline-block;margin:5px 0 2px 5px"/>
        <div style="font-size:14px;float:right;margin:0px 20px 0 5px"
             [ngClass]="{'itemChecked':(_selectedElement.elementClass === itemClass), 'itemUnchecked': !(_selectedElement.elementClass === itemClass)}">{{itemClass.classDescription}}</div>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>

