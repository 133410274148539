<app-shortcut *ngIf="session.hasRole('ROLE_MAP_SIGN')" [store]="signStore" [panel]="signPanel">
    <i class="fa fa-exclamation-triangle"></i>
    <span>{{ 'signEntry.title' | translate }}</span><br />
</app-shortcut>

<app-shortcut *ngIf="session.hasRole('ROLE_MAP_GROUND')" [store]="groundStore" [panel]="groundPanel">
    <i class="fa ca-cross"></i>
    <span>{{ 'groundEntry.title' | translate }}</span><br />
</app-shortcut>

<app-shortcut *ngIf="session.hasRole('ROLE_MAP_LANE')" [store]="laneStore" [panel]="lanePanel">
    <i class="fa fa-road"></i>
    <span>{{ 'laneEntry.title' | translate }}</span><br />
</app-shortcut>

<app-shortcut *ngIf="session.hasRole('ROLE_MAP_STREETLIGHT')" [store]="streetlightStore" [panel]="streetlightPanel">
    <i class="fa-regular fa-lightbulb"></i>
    <span>{{ 'streetlightEntry.title' | translate }}</span><br />
</app-shortcut>

<app-shortcut  *ngIf="session.hasRole('ROLE_MAP_QUALITY')" [store]="retroreflexionStore" [panel]="retroreflexionPanel">
    <i class="fa fa-tachometer" id="icons"></i>
    <span>{{ 'retroreflexionEntry.title' | translate }}</span>
</app-shortcut>
