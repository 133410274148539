import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {AlertEntryPanelComponent} from './alert-entry-panel/alert-entry-panel.component';
import {AlertStoreService} from './alert-store.service';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-alert-entry',
  templateUrl: './alert-entry.component.html',
  styleUrls: ['./alert-entry.component.css']
})
export class AlertEntryComponent implements OnInit {

  constructor(public session: Session, public alertStore: AlertStoreService, public panelService: PanelService) { }

  ngOnInit(): void {
  }

  openMenuAlert(){
    this.panelService.push(
      AlertEntryPanelComponent,
      null
    )
    Matomo.push(['trackEvent', 'Actions', 'Opened alert menu'])
  }
  removeAlerts(){
    this.alertStore.reset();
    Matomo.push(['trackEvent', 'Actions', 'Removed alert elements'])

  }

  }
