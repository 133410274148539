<div class="ui-g sticky" style="padding-right: 10px;">
  <div class="ui-g-1 titleButton">
    <i class="fa fa-arrow-left" (click)="show(false)"></i>
  </div>
  <div class="ui-g-11">
    <h1>{{ 'bankEntry.title' | translate }}</h1>
  </div>
</div>
<div class="imageContainer" *ngIf="element">
  <!--    <img alt="sign image" *ngIf="(elementImage !== undefined)" [src]="elementImage" class="compareImageSign" (click)="showViewer()"/>-->
  <img alt="sign icon" [src]="elementIcon" class="compareImageSign"/>
</div>
<div *ngIf="element">
  <ul class="subMenu">
    <li class="listMenuElement ui-g" >
      <!--        <div class="signTitle ui-g-7">{{element.elementName | elementNameGround}} ({{element.elementCategory | elementGroupGround}})</div>-->
      <div class="signTitle ui-g-7">{{element.elementClassDetail.classDescription}} ({{element.elementCategory | elementGroupGround}})</div>
      <div class="ui-g-5">
        <app-quality-selector *ngIf="qualityList !== undefined" [data]="element" [qualityList]="qualityList" (dataChange)="setNewElementQuality($event)"></app-quality-selector>
      </div>
    </li>
  </ul>
  <ul class="subMenu">
    <li class="listMenuElement" >
      <p class="listMenuElementTitle">{{ 'bankEntry.bankViewPanel.quality' | translate }}</p>
      <p>{{element.elementQuality | elementQualityPipe}} ({{element.elementQuality}})</p>
    </li>
  </ul>
</div>
<p-accordion [multiple]="true" *ngIf="element">
  <p-accordionTab header="{{ 'common.snapshot' | translate }}">
    <div>
      <ul class="subMenu">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.date' | translate }}</p>
          <p>{{element.elementTimestamp | moment: 'LLL'}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="element.snapshotName">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.snapshotName' | translate }}</p>
          <p>{{element.snapshotName}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="element.snapshotIdentifier">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.snapshotIdentifier' | translate }}</p>
          <p>{{element.snapshotIdentifier}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
  <p-accordionTab header="{{ 'common.details' | translate }}">
    <div>
      <ul class="subMenu">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.elementClass' | translate }}</p>
          <p>{{element.elementClass}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.elementIdentifier' | translate }}</p>
          <p>{{element.elementIdentifier}}</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="element.viewIdentifier">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.viewIdentifier' | translate }}</p>
          <p>{{element.viewIdentifier}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
  <p-accordionTab header="{{ 'common.localization' | translate }}">
    <div>
      <ul class="subMenu">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.coordinates' | translate }}</p>
          <p>Long: {{showLong}}</p>
          <p>Lat : {{showLat}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement" >
          <p class="listMenuElementTitle">{{ 'common.openLocationCode' | translate }}</p>
          <p>{{openLocCode}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
</p-accordion>
<p-confirmDialog [style]="{width: '50vw'}" [transitionOptions]="'200ms'"   key="GroundComponent"></p-confirmDialog>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
