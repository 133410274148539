export enum MapLayerEnum {
          LAYER_SATELLITE               = 'city_satelliteLayer'
        , LAYER_SATELLITE_IGN           = 'city_satelliteIgnLayer'
        , LAYER_WORKSPACE               = 'city_workspaceLayer'
        , LAYER_MEASURE_POINTS          = 'city_measurePoints'
        , LAYER_MEASURE_LINES           = 'city_measureLines'
        , LAYER_CAMERA                  = 'city_cameraLayer'
        , LAYER_JOURNEY                 = 'city_journeyLayer'
        , LAYER_LOCATION_MARKER         = 'city_locationMarkerLayer'
        , LAYER_VISIBILITY              = "city_visibilityLayer"
        , LAYER_STREETLIGHT             = 'city_streetlightLayer'
        , LAYER_SEGMENT_GRADUATION      = 'city_locationMarkerSegmentGraduationLayer'
        , LAYER_SEGMENT_GRADUATION_TEXT = 'city_locationMarkerSegmentGraduationTextLayer'
        , LAYER_CUSTOM_URL              = 'city_customMapUrlLayer'
        , LAYER_SKETCH_WAY              = 'city_layer-segments'
        , LAYER_COMPUTE_WAY             = 'city_layerComputeWay'
        , LAYER_SKETCH_WAY_UNIQUE       = 'city_layer-segments'
        , LAYER_STRIP_L                 = 'city_layer_strip_l'
        , LAYER_ELEMENT                 = 'city_elementLayer'
        , LAYER_CIRCLE                  = 'city_circleLayer'

        , LAYER_REFLECTO                = 'city_elementReflectoLayer'
        , LAYER_LANE                    = 'city_elementLaneLayer'
        , LAYER_LANE_TEXT               = 'city_elementLaneLayerText'

    /* 
     * CITYMAPPER ____________________________________________________ 
     */
        , LAYER_BANK                    = 'city_elementBankLayer'
        , LAYER_CURVES                  = 'city_LAYER_CURVES'
        , LAYER_GLARE                   = 'city_LAYER_GLARE'
        , LAYER_NARROW                  = 'city_LAYER_NARROW'
        , LAYER_ROUGHNESS               = 'city_LAYER_ROUGHNESS'

    /*
     * ROAD SAFETY & ACCIDENTOLOGY  _________________________________
     */
        , LAYER_RISK_SCORE              = 'city_LAYER_RISKSCORE'
        , LAYER_ROAD_FLOW               = 'city_LAYER_ROADFLOW'
        , LAYER_ROAD_FSIS               = "city_crashLayer"
}
