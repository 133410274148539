import { Injectable }                               from "@angular/core"                                        ;
import { MapService }                               from "src/app/shared/map/map.service"                       ;
import { SpinnerService }                           from "src/app/shared/spinner/spinner.service"               ;
import { ToastService }                             from "src/app/shared/toast/toast.service"                   ;
import { SearchStoreService }                       from "src/app/components/main/search/search-store.service"  ;
import { MapLayerEnum }                             from "../../../../enums/map-layer-enum.enum"                ;
import { MapSourceEnum }                            from "../../../../enums/map-source-enum.enum"               ;
import { environment }                              from "../../../../../environments/environment"              ;
import { RoadRiskState, RoadRiskStoreService }      from "./road-risk-store.service"                            ;
import { TranslateService }                         from "@ngx-translate/core"                                  ;

declare let gtag: Function;

@Injectable({
    providedIn: "root"
})
export class RoadRiskService {
    subscriptions = []

    get spinner()   { return this.spinnerService.getSpinner(); }
    get map()       { return this.mapService.getMap(); }
    get toast()     { return  this.toastService.getToast(); }

    constructor(
          private roadRiskStore : RoadRiskStoreService
        , private mapService    : MapService
        , private spinnerService: SpinnerService
        , public searchStore    : SearchStoreService
        , private toastService  : ToastService
        , private translate     : TranslateService
    ) {
        this.roadRiskStore.applyAndWatch(this.searchWrapper.bind(this))
        this.roadRiskStore      = roadRiskStore
    }

    private searchWrapper(state: RoadRiskState){
        this.mapService.callWhenAvailable(_=>this.updateSearchWrapper(state));
    }

    updateSearchWrapper(state: RoadRiskState){
        this.mapService.callWhenAvailable(_=>this.updateSearch(state));
    }

    updateSearch(state: RoadRiskState) {
        if (state._isShowElement === true) {
            let layoutLane = {
                "line-cap": "round"
              , "line-join": "round"
            };

            let   cutoffs       = this.roadRiskStore.state.selectedCutoffs 
            const quantileOne   = [        ">"  , ["get", "sc"], cutoffs['1']];
            const quantileTwo   = ["all", [">=" , ["get", "sc"], cutoffs['2']], ["<", ["get", "sc"], cutoffs['1']]];
            const quantileThree = ["all", [">=" , ["get", "sc"], cutoffs['3']], ["<", ["get", "sc"], cutoffs['2']]];
            const quantileFour  = ["all", [">=" , ["get", "sc"], cutoffs['4']], ["<", ["get", "sc"], cutoffs['3']]];
            const quantileFive  = [        "<=" , ["get", "sc"], cutoffs['5']];

            let paint = {
                  "line-color": [
                    "case"          ,
                    quantileOne     , "#d7191c" ,
                    quantileTwo     , "#fdae61" ,
                    quantileThree   , "#ffffbf" ,
                    quantileFour    , "#abd9e9" ,
                    quantileFive    , "#2c7bb6" ,
                    "#2c7bb6" 
                ]
                , "line-width": 3
            };

            /* 
             * API URL CONSTRUCTION _______________________________________________ 
             */
            let _USER_DEFINED_POLYGONS_HAVE_NO_CRS = 'crs'; // ad hoc...
            let hasDrawnGeometry :boolean =  this.searchStore.state.drawnGeometry && !this.searchStore.state.drawnGeometry[_USER_DEFINED_POLYGONS_HAVE_NO_CRS] ;
            const selectedUserPolygon = hasDrawnGeometry ? this.searchStore.state.drawnGeometry : null          ;
            const selectedRoadTypes   = state.selectedRoadTypes.length ? state.selectedRoadTypes      : state.roadTypes       ;
            const selectedQuantiles   = state.selectedQuantiles.length ? state.selectedQuantiles      : state.quantiles       ;
            const selectedCutoffs     = state.selectedCutoffs ;
            const selectedDataVersion = state.selectedDataVersion      ? state.selectedDataVersion    : state.dataVersions    ;
            let tileUrl: string =`${environment.urlRoadRisk}` 
                + `?roadUser=${state.selectedRoadUser}`
                + `&dataVersion=${selectedDataVersion}`
                + `&cutoffs=${Object.keys(state.selectedCutoffs).map(k => state.selectedCutoffs[k]).join("&cutoffs=")}`
                + (!hasDrawnGeometry ? `` : `&userPolygon=${encodeURIComponent(JSON.stringify(selectedUserPolygon))}`); 
            for(let roadType of selectedRoadTypes) tileUrl += `&roadTypes=${String(roadType)}`;
            for(let quantile of selectedQuantiles) tileUrl += `&quantiles=${String(quantile)}`;

            let layerName         :string = MapLayerEnum.LAYER_RISK_SCORE     + "Full"    ; 
            let sourceLayer       :string = MapSourceEnum.SOURCE_LAYER_RISK               ;
            let sourceName        :string = MapSourceEnum.SOURCE_LAYER_RISK   + "Full"    ; 
            let sourceId          :string = layerName                                     ;
            let beforeWorkspace   :boolean= false                                         ; 
            this.map.addLineVectorLayerTiles(layerName, tileUrl, sourceLayer, sourceName, beforeWorkspace, layoutLane, paint);
        } else {
            this.map.removeLayer(MapLayerEnum.LAYER_RISK_SCORE + "Full", MapSourceEnum.SOURCE_LAYER_RISK);
        }

        if (environment.googleTracking) {
            gtag("event", "showScoreRisk");
        }
    }
}
