import {ISerializable} from '../iserializable';
import * as turf from "@turf/turf";
import {ArrayHelper} from "../../helpers/array.helper";
import {GeometryTypeEnum} from "../../enums/geometry-type-enum.enum";

export class GeometryModel implements ISerializable {

  public type: string;
  public coordinates: any[];
  public properties: any;
  // public geometry: GeometryModel;

  constructor(type: GeometryTypeEnum = GeometryTypeEnum.POINT, coordinates: any[] = []) {
    this.type = type;
    this.coordinates = coordinates;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toString(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }

  toTurf(): any {
    return turf.helpers.geometry(this.type, this.coordinates);
  }

  clone(): GeometryModel {
    let newGeometryModel: GeometryModel = new GeometryModel();
    newGeometryModel.type = this.type;
    newGeometryModel.coordinates = ArrayHelper.dup(this.coordinates); // Deep copy
    newGeometryModel.properties = Object.assign({}, this.properties);

    return newGeometryModel;
  }

  reverse() {
    if (this.type != GeometryTypeEnum.LINESTRING)


    this.coordinates.reverse();
  }
}
