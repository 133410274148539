import { Injectable } from '@angular/core';
import { MapService } from 'src/app/shared/map/map.service';
import { ViewerState } from './viewer-store.service';

@Injectable({
  providedIn: 'root'
})
export class ViewerStateReducerService {

  constructor(private mapService: MapService) {

  }

  showViewer(viewerState: ViewerState){
    if(viewerState.isShowViewer){
      this.mapService.callWhenAvailable(m=>{
        m._measureRemove()
      });
    }
  }
}
