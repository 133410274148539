import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from 'src/app/globals/store';

export class BackgroundDetailsState{
  isShowBackgroundDetail: boolean = true;
}

@Injectable({
  providedIn: 'root'
})
export class BackgroundDetailsStoreService  extends Store<BackgroundDetailsState>{
  constructor() {
    super(BackgroundDetailsState)
  }


}
