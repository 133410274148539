export enum GeometryTypeEnum {
  POINT = 'Point',
  MULTIPOINT = 'MultiPoint',

  LINE_STRING = 'LineString',
  LINESTRING = 'LineString',
  MULTI_LINESTRING = 'MultiLineString',
  MULTILINESTRING = 'MultiLineString',

  POLYGON = 'Polygon',
  MULTI_POLYGON = 'MultiPolygon',
  MULTIPOLYGON = 'MultiPolygon'
}


export function GeometryTypeEnumFrom(enumString: string): GeometryTypeEnum {
  switch (enumString) {
    case "Point":
      return GeometryTypeEnum.POINT;
    case "MultiPolygon":
      return GeometryTypeEnum.MULTIPOLYGON
    case "Multi_Polygon":
      return GeometryTypeEnum.MULTI_POLYGON
    case "LineString":
      return GeometryTypeEnum.LINESTRING
    case "MultiLineString":
      return GeometryTypeEnum.MULTILINESTRING
    case "Polygon":
      return GeometryTypeEnum.POLYGON
  }

}
