import { Component, OnInit } from '@angular/core';
import { Session } from 'src/app/globals/session';
import { PanelService } from 'src/app/shared/panel/panel.service';
import { UserEntryPanelComponent } from './user-entry-panel/user-entry-panel.component';

@Component({
  selector: 'app-user-entry',
  templateUrl: './user-entry.component.html',
  styleUrls: ['./user-entry.component.css']
})
export class UserEntryComponent implements OnInit {

  constructor(public session:Session, private panelService: PanelService) { }

  ngOnInit(): void {
  }

  openMenuUser(){
    this.panelService.push(
      UserEntryPanelComponent,
      null
    )
  }

}
