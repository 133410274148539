<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_STREETLIGHT')"
appWorkspaceRequired
[ngClass]="{'itemChecked':streetlightStore.state._isShowElement, 'itemUnchecked': !streetlightStore.state._isShowElement}"
(click)="openMenuStreetLight()">
<i class="fa-regular fa-lightbulb"></i>
<span class="text">{{ 'streetlightEntry.title' | translate }}</span><br />
<a role="button" *ngIf="streetlightStore.state._isShowElement">
    <span (click)="close($event)" class="pi pi-times ng-tns-c48-2">
    </span>
</a>
</li>
