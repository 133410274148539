<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_VISIBILITY')"
    appWorkspaceRequired
    [ngClass]="{'itemChecked':visibilityStore.state._isShowElement, 'itemUnchecked': !visibilityStore.state._isShowElement}" (click)="openMenuVisibility()">
    <i class="fa fa-eye"></i>
    <span>{{ 'visibilityEntry.title' | translate }}</span> <br>
    <a role="button"
       *ngIf="visibilityStore.state._isShowElement"
       [ngClass]="{'itemChecked':visibilityStore.state._isShowElement, 'itemUnchecked': !visibilityStore.state._isShowElement}">
        <span (click)="removeVisibility()" class="pi pi-times ng-tns-c48-2">
        </span>
    </a>
</li>
