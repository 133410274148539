<div class="ui-g sticky" style="padding-right: 10px; border-bottom: 1px solid rgba(0,0,0,.2); background: #E5E5E5;">
    <div class="ui-g-1 titleButton">
        <i class="fa fa-arrow-left" (click)="show(false)"></i>
    </div>
    <div class="ui-g-11">
        <h1>{{ 'roadFsiEntry.title' | translate }}</h1>
    </div>
</div>

<ul class="subMenu">
    <li class="listMenuElement">
        <div class="ui-g-12 ui-g-nopad" style="display: flex;">
            <div class="ui-g-3" style="font-size: 20pt;background: #f6f6f6; border-right: 1px solid #ddd;">
                <div style="color: #333333; font-weight: 800 !important; font-family: monospace; text-align: center; margin-bottom: .25ex;">
                    {{ p.t_accident | date: 'yyyy' }}
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-label="Calendar" role="img" viewBox="0 0 512 512">
                        <path d="M512 455c0 32-25 57-57 57H57c-32 0-57-25-57-57V128c0-31 25-57 57-57h398c32 0 57 26 57 57z"                                     fill="#e0e7ec"/>
                        <path d="M484 0h-47c2 4 4 9 4 14a28 28 0 1 1-53-14H124c3 4 4 9 4 14A28 28 0 1 1 75 0H28C13 0 0 13 0 28v157h512V28c0-15-13-28-28-28z"    fill="#dd2f45"/>
                        <g fill="#f3aab9">
                        <circle cx="470" cy="142" r="14"/>
                        <circle cx="470" cy="100" r="14"/>
                        <circle cx="427" cy="142" r="14"/>
                        <circle cx="427" cy="100" r="14"/>
                        <circle cx="384" cy="142" r="14"/>
                        <circle cx="384" cy="100" r="14"/>
                        </g>
                        <text x="32"    y="164" fill="#fff"     font-family="monospace" font-size="140px"   style="text-anchor: left"   id="month"  >{{ p.t_accident | date: 'MMM'  }}</text>
                        <text x="256"   y="400" fill="#66757f"  font-family="monospace" font-size="256px"   style="text-anchor: middle" id="day"    >{{ p.t_accident | date: 'dd'   }}</text>
                        <text x="256"   y="480" fill="#66757f"  font-family="monospace" font-size="64px"    style="text-anchor: middle" id="weekday">{{ p.t_accident | date: 'EEEE' }}</text>
                    </svg>
                </div>
            </div>
            <div class="ui-g-9">
                <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.dateCrash' | translate }}</div>
                <div style="font-size: 14pt; color: #000; ">
                    <div style="text-transform: capitalize; ">{{ moment(p.t_accident).format('dddd Do MMMM YYYY')}}</div>
                    ({{ moment(p.t_accident).fromNow()}})
                </div>
            </div>
        </div>

        <div class="ui-g-12 ui-g-nopad" style="border-top: 1px solid #ddd; display:flex;">
            <div class="ui-g-3" style="font-size: 20pt;background: #f6f6f6; border-right: 1px solid #ddd;">
                <div style="font-family: monospace; font-size: 16pt; text-align: center;">
                    {{ p.t_accident | date: 'HH'}}:{{ p.t_accident | date: 'mm'}}
                </div>
            </div>
            <div class="ui-g-9">
                <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.dateTime' | translate }}</div>
                <div style="font-size: 14pt; color: #000; text-transform: capitalize; ">
                    <span *ngIf="(t_hour >=  6) && (t_hour < 12)">Morning</span> 
                    <span *ngIf="(t_hour >= 12) && (t_hour < 18)">Afternoon</span> 
                    <span *ngIf="(t_hour >= 18) && (t_hour < 24)">Evening</span> 
                    <span *ngIf="(t_hour >= 0 ) && (t_hour <  6)">Night</span> 
                </div>
            </div>
        </div>

        <div class="ui-g-12 ui-g-nopad" style="border-top: 1px solid #ddd; display:flex;">
            <div class="ui-g-3" style="font-size: 20pt;background: #f6f6f6; border-right: 1px solid #ddd; text-align: center;">
                <div style="max-height: 3ex; max-width: 3ex; display: flex; margin: auto;">
                    <svg *ngIf="p.road_user == 'PEDESTRIAN'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152z"/></svg>
                    <svg *ngIf="p.road_user == 'BIKE'"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M312 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h25.7l34.6 64H222.9l-27.4-38C191 99.7 183.7 96 176 96H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h43.7l22.1 30.7-26.6 53.1c-10-2.5-20.5-3.8-31.2-3.8C57.3 224 0 281.3 0 352s57.3 128 128 128c65.3 0 119.1-48.9 127-112h49c8.5 0 16.3-4.5 20.7-11.8l84.8-143.5 21.7 40.1C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L375.4 48.8C369.8 38.4 359 32 347.2 32H312zM458.6 303.7l32.3 59.7c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-32.3-59.7c3.6-.6 7.4-.9 11.2-.9c39.8 0 72 32.2 72 72s-32.2 72-72 72s-72-32.2-72-72c0-18.6 7-35.5 18.6-48.3zM133.2 368h65c-7.3 32.1-36 56-70.2 56c-39.8 0-72-32.2-72-72s32.2-72 72-72c1.7 0 3.4 .1 5.1 .2l-24.2 48.5c-9 18.1 4.1 39.4 24.3 39.4zm33.7-48l50.7-101.3 72.9 101.2-.1 .1H166.8zm90.6-128H365.9L317 274.8 257.4 192z"/></svg>
                    <svg *ngIf="p.road_user == 'SCOOTER'" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.13 122.88"><title>scooter-vespa-moped</title><path d="M34.08,22.83l.09.07h0l.08.06.08.07,0,0,.06,0,.08.07,0,0,0,0,.09.07.07.05h0l.09.06.16.11h0l.09.06,0,0,.06,0,.09.06.05,0,0,0,.1.06.07,0h0l.09.06.1,0h0l.09.05.1.05h0l.07,0,.09.05.06,0,0,0,.1.05.08,0h0l.1.05.1,0h0l.1,0,.1,0,0,0,.07,0,.1,0,.06,0,0,0,.1,0,.08,0h0l.11,0,.1,0h0l.11,0,.1,0h0l.08,0,.11,0,0,0h.05l.11,0,.08,0h0l.22.05h0l.11,0,.1,0h.11l.11,0h.12l.11,0h.11l.22,0h0l.23,0h0l.25,0h1l.25,0h0l.23,0h0l.22,0h.12l.11,0h.11l.11,0h.11l.11,0,.11,0h0l.22-.05h0l.08,0,.11,0h0l.06,0,.11,0h0l.1,0,.11,0,.1,0h0l.11,0,.1,0h0l.09,0,.1,0,.05,0,0,0,.1,0,.08,0,0,0,.11,0,.09,0h0l.1,0,.1-.05h0l.07,0,.1-.05.05,0,0,0,.1-.05.07,0h0l.1-.05.09-.05h0l.09,0,.09-.06h0l.08,0,.09-.06,0,0,0,0,.09-.06.07,0,0,0,.08-.06h0l.16-.11.09-.06h0l.07-.05.09-.07,0,0,0,0,.09-.07.06,0,0,0,.08-.07.08-.06h0l.08-.07a10.05,10.05,0,1,0-13,0Zm4.35,15.49a2.13,2.13,0,1,1,4.26,0V57.84a2.13,2.13,0,1,1-4.26,0V38.32Zm-5.64,66.35h15.4v10.51a7.72,7.72,0,0,1-7.7,7.7h0a7.72,7.72,0,0,1-7.7-7.7V104.67Zm5-33.74h5.34A11.88,11.88,0,0,1,55,82.79v17a2,2,0,0,1-2,2H28a2,2,0,0,1-2-2v-17A11.88,11.88,0,0,1,37.82,70.93Zm5.34,4H37.82A7.89,7.89,0,0,0,30,82.79v15H51v-15a7.89,7.89,0,0,0-7.87-7.87ZM55.21,23.14A20.39,20.39,0,0,1,72,43.12v37.6A20.4,20.4,0,0,1,61.17,98.64v-5A16.31,16.31,0,0,0,63,92a16,16,0,0,0,4.71-11.31V43.12a16.08,16.08,0,0,0-16-16H50.63a15.18,15.18,0,0,1-9.67,3,16.77,16.77,0,0,1-10.3-3A16.05,16.05,0,0,0,16.25,43.12v37.6A15.91,15.91,0,0,0,20,90.94v5.87a17.9,17.9,0,0,1-2-1.77l-.12-.12A20.25,20.25,0,0,1,12,80.72V43.12a20.18,20.18,0,0,1,6-14.32h0a20.39,20.39,0,0,1,8.56-5.12c-2.12-1.86-4.33-3.39-8.13-3.39H5.21A5.22,5.22,0,0,1,0,15.08H0a5.22,5.22,0,0,1,5.21-5.2H18.38C28,9.86,27.47,0,41,0,53.25,0,53.2,9.86,62.74,9.86H75.92a5.22,5.22,0,0,1,5.21,5.2h0a5.22,5.22,0,0,1-5.21,5.2H62.74a11,11,0,0,0-7.53,2.86Z"/></svg>
                    <svg *ngIf="p.road_user == 'MOTORBIKE'"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>
                    <svg *ngIf="p.road_user == 'CAR'"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>
                    <svg *ngIf="p.road_user == 'PL'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
                </div>
            </div>
            <div class="ui-g-9">
                <div style="font-size: 8pt; color: #999; text-transform: uppercase; font-weight: 600;">{{ 'roadFsiEntry.roadFsiViewPanel.vehicleCat' | translate }}</div>
                <div style="font-size: 14pt; color: #000;">
                    <span *ngIf="p.road_user == 'peds'"     >{{ 'common.peds'    | translate }}</span>
                    <span *ngIf="p.road_user == 'bicycle'"  >{{ 'common.bicycle' | translate }}</span>
                    <span *ngIf="p.road_user == 'scooter'"  >{{ 'common.scooter' | translate }}</span>
                    <span *ngIf="p.road_user == 'moto'"     >{{ 'common.moto'    | translate }}</span>
                    <span *ngIf="p.road_user == 'auto'"     >{{ 'common.auto'    | translate }}</span>
                    <span *ngIf="p.road_user == 'hgv'"      >{{ 'common.hgv'     | translate }}</span>
                    {{ p.data['catv'] ? '(' + p.data['catv'] + ')' : ''}}
                </div>

                <div *ngIf="p.moving_obstacle">
                    <div style="font-size: 8pt; color: #999; text-transform: uppercase; font-weight: 600;">{{ 'roadFsiEntry.roadFsiViewPanel.mobile' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{p.moving_obstacle }}</div>
                </div>

                <div *ngIf="p.data['choc']">
                    <div style="font-size: 8pt; color: #999; text-transform: uppercase; font-weight: 600;">{{ 'roadFsiEntry.roadFsiViewPanel.initial' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{p.data['choc']}}</div>
                </div>

                <div *ngIf="p.data['manv']">
                    <div style="font-size: 8pt; color: #999; text-transform: uppercase; font-weight: 600;">{{ 'roadFsiEntry.roadFsiViewPanel.front' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{p.data['manv']}}</div>
                </div>
            </div>
        </div>

        <div class="ui-g-12 ui-g-nopad" style="border-top: 1px solid #ddd; display:flex;">
            <div class="ui-g-3" style="background: #f6f6f6; border-right: 1px solid #ddd; font-family: monospace;">
                <div class="color-killed" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                    <div style="display: inline-block; width: 2ex; height: 2ex;">
                        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" fill="#993404" />
                            <circle *ngIf="p.severity == 'killed'" cx="15" cy="15" r="7.5" fill="#000000"/>
                        </svg>
                    </div>
                    {{ 'common.killed' | translate }}
                </div>

                <div class="color-severe" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                    <div  style="display: inline-block; width: 2ex; height: 2ex;">
                        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" fill="#d95f0e" />
                            <circle *ngIf="p.severity == 'severe'" cx="15" cy="15" r="7.5" fill="#000000"/>
                        </svg>
                    </div>
                    {{ 'common.seriouslyInjured' | translate }}
                </div>

                <div class="color-light" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                    <div  style="display: inline-block; width: 2ex; height: 2ex;">
                        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" fill="#fe9929" />
                            <circle *ngIf="p.severity == 'light'" cx="15" cy="15" r="7.5" fill="#000000"/>
                        </svg>
                    </div>
                    {{ 'common.slightlyInjured' | translate }}
                </div>

                <div class="color-unscathed" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                    <div  style="display: inline-block; width: 2ex; height: 2ex;">
                        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" fill="#fed98e" />
                            <circle *ngIf="p.severity == 'unscathed'" cx="15" cy="15" r="7.5" fill="#000000"/>
                        </svg>
                    </div>
                    {{ 'common.unharmed' | translate }}
                </div>
            </div>
            <div class="ui-g-9">
                <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.severity' | translate }}</div>
                <div style="font-size: 14pt; color: #000;">
                    <span *ngIf="p.severity == 'unscathed'" >{{ 'common.unharmed'           | translate }}</span>
                    <span *ngIf="p.severity == 'light'"     >{{ 'common.slightlyInjured'    | translate }}</span>
                    <span *ngIf="p.severity == 'severe'"    >{{ 'common.seriouslyInjured'   | translate }}</span>
                    <span *ngIf="p.severity == 'killed'"    >{{ 'common.killed'             | translate }}</span>
                </div>
            </div>
        </div>

        <!-- START / VICTIM -->
        <div class="ui-g-12 ui-g-nopad" style="border-top: 1px solid #ddd; display:flex;">
            <div class="ui-g-3" style="background: #f6f6f6; border-right: 1px solid #ddd; font-family: monospace;">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 9.8c0 39-23.7 74-59.9 88.4C71.6 154.5 32 213 32 278.2V352c0 17.7 14.3 32 32 32s32-14.3 32-32l0-73.8c0-10 1.6-19.8 4.5-29L261.1 497.4c9.6 14.8 29.4 19.1 44.3 9.5s19.1-29.4 9.5-44.3L222.6 320H224l80 0 38.4 51.2c10.6 14.1 30.7 17 44.8 6.4s17-30.7 6.4-44.8l-43.2-57.6C341.3 263.1 327.1 256 312 256l-71.5 0-56.8-80.2-.2-.3c44.7-29 72.5-79 72.5-133.6l0-9.8zM96 80A48 48 0 1 0 0 80a48 48 0 1 0 96 0zM464 286.1l58.6 53.9c4.8 4.4 11.9 5.5 17.8 2.6s9.5-9 9-15.5l-5.6-79.4 78.7-12.2c6.5-1 11.7-5.9 13.1-12.2s-1.1-13-6.5-16.7l-65.6-45.1L603 92.2c3.3-5.7 2.7-12.8-1.4-17.9s-10.9-7.2-17.2-5.3L508.3 92.1l-29.4-74C476.4 12 470.6 8 464 8s-12.4 4-14.9 10.1l-29.4 74L343.6 68.9c-6.3-1.9-13.1 .2-17.2 5.3s-4.6 12.2-1.4 17.9l39.5 69.1-65.6 45.1c-5.4 3.7-8 10.3-6.5 16.7c.1 .3 .1 .6 .2 .8l19.4 0c20.1 0 39.2 7.5 53.8 20.8l18.4 2.9L383 265.3l36.2 48.3c2.1 2.8 3.9 5.7 5.5 8.6L464 286.1z"/></svg>
            </div>
            <div class="ui-g-9">
                <div *ngIf="p.data['catu']">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.userCat' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{p.data["catu"]}}</div>
                </div>

                <div *ngIf="p.data['an_nais']">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.birth' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{ age_in_y}} {{ 'roadFsiEntry.roadFsiViewPanel.years' | translate }} ({{ 'roadFsiEntry.roadFsiViewPanel.dob' | translate }} {{p.data["an_nais"]}})</div>
                </div>

                <div *ngIf="p.data['trajet']">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.path' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{p.data["trajet"]}}</div>
                </div>

                <div *ngIf="p.data['Num_Acc']">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.accidentNumber' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{p.data["Num_Acc"]}}</div>
                </div>

                <div *ngIf="p.luminosity">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.light' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{p.luminosity}}</div>
                </div>

                <div *ngIf="p.data['atm']">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.weather' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{p.data['atm']}}</div>
                </div>

                <div *ngIf="p.collision">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.collision' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{p.collision}}</div>
                </div>
            </div>
        </div>
        <!-- END / VICTIM -->

        <!-- START / LOCATION -->
        <div class="ui-g-12 ui-g-nopad" style="border-top: 1px solid #ddd; display:flex;">
            <div class="ui-g-3" style="background: #f6f6f6; border-right: 1px solid #ddd; font-family: monospace;">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
            </div>
            <div class="ui-g-9">
                <div *ngIf="p.data['addr']">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.location' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{p.data["addr"]}}</div>
                </div>

                <div *ngIf="p.landmark_id">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.prNumber' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">
                        PR : {{ p.landmark_id }} 
                        <span *ngIf="p.data['pr1']">({{ 'roadFsiEntry.roadFsiViewPanel.distance' | translate }} {{p.data["pr1"]}})</span>
                    </div>
                </div>

                <div *ngIf="p.data['catr']">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadFsiViewPanel.roadCat' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{ p.data['catr']}}</div>
                </div>

                <div *ngIf="p.data['circ']">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadTraffic.drawing' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{ p.data['circ']}}</div>
                </div>

                <div *ngIf="p.data['plan']">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadTraffic.drawing' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{ p.data['plan']}}</div>
                </div>

                <div *ngIf="p.surface">
                    <div style="font-size: 8pt; color: #999;text-transform: uppercase; font-weight: 600; ">{{ 'roadFsiEntry.roadTraffic.surfaceState' | translate }}</div>
                    <div style="font-size: 14pt; color: #000;">{{ p.surface }}</div>
                </div>
            </div>
        </div>
        <!-- END / LOCATION -->
    </li>
</ul>
