<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_HELIOS')" appWorkspaceRequired
    [ngClass]="{'itemChecked':heliosStore.state._isShowElement, 'itemUnchecked': !heliosStore.state._isShowElement}"
    (click)="openMenuHelios()">
    <i class="fa ca-cone"></i>
    <span class="text">{{ 'heliosEntry.title' | translate }}</span><br />
    <a role="button" *ngIf="heliosStore.state._isShowElement"
       [ngClass]="{'itemChecked':heliosStore.state._isShowElement, 'itemUnchecked': !heliosStore.state._isShowElement}">
        <span (click)="closeHelioMenu()" class="pi pi-times ng-tns-c48-2">
        </span>
    </a>
</li>
