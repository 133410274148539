import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {FeatureCollectionModel} from '../models/geojson/feature-collection-model';
import {catchError, map} from 'rxjs/operators';
import {GeometryModel} from '../models/geojson/geometry-model';
import {Session} from "../globals/session";

@Injectable({
  providedIn: 'root'
})
export class ModelingService {

  errorMessage: any;
  @Output() ErrorMessage: EventEmitter<any> = new EventEmitter();

  constructor(private session: Session, private httpService: HttpClient) {
  }

  displayStatus(errorMessage){
    this.errorMessage = errorMessage;
    this.ErrorMessage.emit(this.errorMessage);
  }

  getModelPoiCollection(poiType: string, geometry: GeometryModel, distance: number): Observable<FeatureCollectionModel> {
    const url = environment.urlGeoJsonGetModelPoiCollection;
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
        ,
        limitOffset: -1,
        limitCount: -1,
      },
      poiType: poiType,
      distance: distance,
      geometry: geometry
    }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(() => throwError('POI Collection not found'))
    );
  }

  getModelPoiPRCollection(poiType: string = 'PR_POINT', poiDisplayName: string, distance: number = 1500): Observable<FeatureCollectionModel> {
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers: headers};
    return this.httpService.post(environment.urlGeoJsonGetModelPoiCollection, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      poiType,
      poiDisplayName,
      distance,
      geometry: this.session._workspaceGeo
    }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(() => throwError('View not found'))
    );
  }

  getModelingCollection(searchText: string): Observable<FeatureCollectionModel> {
    const url = environment.urlGetModelingCollection;
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      searchText: searchText
    }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(() => throwError('Model Collection not found'))
    );
  }

  getModelWayCollection(searchText: string, searchType: string, geometry: GeometryModel = undefined): Observable<FeatureCollectionModel> {
    const url = environment.urlGetModelWayCollection;
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      wayDisplayName: searchText,
      groupBy: searchType,
      geometry: geometry
    }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(() => throwError('Way Model Collection not found'))
    );
  }

  getModelZoneCollection(searchText: string): Observable<FeatureCollectionModel> {
    const url = environment.urlGetModelZoneCollection;
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      zoneDisplayName: searchText
    }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      // catchError(() => throwError('Zone Model Collection not found'))
      catchError(err => {
        this.displayStatus(err);
        return throwError('Zone Model Collection not found');

      })
    );
  }

  getNominatim(string, country?: string) : Observable<any> {
    const url = environment.urlApiNominatim + string + environment.urlApiNominatimParam;
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)

    const options = {headers};
    return this.httpService.get(url, options);
  }

  getNominatimReverse(string) : Observable<any> {
    const url = environment.urlApiNominatimReverse + string + "&zoom=1";
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)

    const options = {headers};
    return this.httpService.get(url, options);
  }

}
