import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from 'src/app/globals/store';


export class StripState{
  isShowBackgroundStrip:boolean = false;
}

@Injectable({
  providedIn: 'root'
})
export class StripStoreService extends Store<StripState>{
  constructor() {
    super(StripState)
  }
}
