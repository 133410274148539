import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MapLayerEnum } from 'src/app/enums/map-layer-enum.enum';
import { MapSourceEnum } from 'src/app/enums/map-source-enum.enum';
import { Session } from 'src/app/globals/session';
import { FeatureCollectionModel } from 'src/app/models/geojson/feature-collection-model';
import { GeometryModel } from 'src/app/models/geojson/geometry-model';
import { GeojsonService } from 'src/app/services/geojson.service';
import { MapService } from 'src/app/shared/map/map.service';
import { PanelBase } from 'src/app/shared/panel/component/PanelBase';
import { PanelService } from 'src/app/shared/panel/panel.service';
import { environment } from 'src/environments/environment';
import { ClearService } from './clear.service';

type BackgroundType = "SATELLITE" | "IGN_SATELLITE" | "DEFAULT";
type BackgroundOption = {name:string,type:BackgroundType}

@Component({
  selector: 'app-main-panel',
  templateUrl: './main-panel.component.html',
  styleUrls: ['./main-panel.component.css']
})
export class MainPanelComponent implements PanelBase<null> {

  constructor(public session: Session,
            private panelService: PanelService,
            private clearService: ClearService) { }
  data: any;
  subscriptions: Subscription[] = [];
  isWorkspaceDefined = false;

  ngOnInit(): void {

    // // Workspace
    this.isWorkspaceDefined = (this.session.getWorkspace() !== undefined);
    this.subscriptions.push(this.session.onChangeWorkspace.subscribe(geometry => {
      this.isWorkspaceDefined = (this.session.getWorkspace() !== undefined);
    }));

    // // this.refreshWorkspace();

    // if (this.session.sessionProperties !== undefined
    //       && this.session.sessionProperties["citymap"] !== undefined) {

    //   let userProperties = this.session.sessionProperties["citymap"];
    //   // this.showJourney(userProperties["showJourney"]);
  }


  close(){
    this.panelService.close();
  }

  clearAll(){
    this.clearService.clear();
  }


  navigateDoc() {
    window.open("https://docs.citymagine.com/v/english/citymap", '_blank');
  }
}
