import {IFeatureProperties} from './ifeature-properties';
import {GeometryModel} from './geometry-model';
import {ISerializable} from '../iserializable';
import {FeatureObjectElementModel} from './feature-object-element-model';
import {FeatureTypesEnum} from '../../enums/feature-types-enum.enum';
import {FeatureObjectViewModel} from "./feature-object-view-model";
import {FeatureObjectSnapshotModel} from "./feature-object-snapshot-model";
import {FeatureObjectPoiModel} from "../modeling/feature-object-poi-model";
import {FeatureObjectWayModel} from "../modeling/feature-object-way-model";
import {FeatureObjectZoneModel} from "../modeling/feature-object-zone-model";
import {FeatureObjectAlertModel} from "./feature-object-alert-model";
import * as turf from "@turf/turf";
import {FeatureObjectRoadFsiModel} from "./feature-object-road-fsi-model";
import {GeometryTypeEnum} from "../../enums/geometry-type-enum.enum";

export class FeatureModel implements ISerializable  {

  public type: string = 'Feature';

  public geometry: GeometryModel;

  public properties: any;

  deserialize(input: any): this {
    Object.assign(this, input);

    this.geometry = new GeometryModel().deserialize(input.geometry);

    if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_ELEMENT){
      this.properties = new FeatureObjectElementModel().deserialize(input.properties);

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_VIEW){
      this.properties = new FeatureObjectViewModel().deserialize(input.properties);

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_SNAPSHOT){
      this.properties = new FeatureObjectSnapshotModel().deserialize(input.properties);

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_POI){
      this.properties = new FeatureObjectPoiModel().deserialize(input.properties);

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_WAY){
      this.properties = new FeatureObjectWayModel().deserialize(input.properties);

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_ZONE){
      this.properties = new FeatureObjectZoneModel().deserialize(input.properties);

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_ALERT){
      this.properties = new FeatureObjectAlertModel().deserialize(input.properties);

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_CRASH){
      this.properties = new FeatureObjectRoadFsiModel().deserialize(input.properties);

    }

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }

  toString(): string {
    let value: string;

    if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_ELEMENT){
      value = this.properties["elementName"];

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_VIEW){

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_SNAPSHOT){

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_POI){
      value = '(' + this.properties["poiCounty"] + ') ' + this.properties["poiDisplayName"];
    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_WAY){
      value = this.properties["wayDisplayName"];
    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_ZONE){
      value = this.properties["zoneDisplayName"];
    }

    return value;
  }

  toTurf(): any {
    return turf.helpers.feature(this.geometry.toTurf(), this.properties.toJson());
  }
  toExcel(): any {
    let item: any = {};

    if (this.properties !== undefined) {
      item = this.properties.toExcel();
    }

    if (this.geometry !== undefined
      && this.geometry.type === GeometryTypeEnum.LINE_STRING) {
      item.coordinates = this.geometry.coordinates.toString();
    }

    return item;
  }

}
