import {GeometryModel} from "../models/geojson/geometry-model";

export class MathHelper {

  static roundDecimal(value: number, decimal: number = 2): number {
    return Math.round((value + Number.EPSILON) * Math.pow(10, decimal)) / Math.pow(10, decimal);
  }

  static roundPow(value: number, pow: number = 2): number {
    return Math.round((value + Number.EPSILON) / Math.pow(10, pow)) * Math.pow(10, pow);
  }

  static degToRad(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  static getSlopeBetweenPoints(pointA: GeometryModel, pointB: GeometryModel) {

    return (pointB.coordinates[1] - pointA.coordinates[1]) / (pointB.coordinates[0] - pointA.coordinates[0])
  }
}
