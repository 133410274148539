<div class="ui-g sticky" style="padding-right: 10px;">
    <div class="ui-g-1 titleButton">
      <i class="fa fa-arrow-left" (click)="close()"></i>
    </div>
    <div class="ui-g-10">
      <h1>{{ 'riverSignEntry.title' | translate }}</h1>
    </div>
    <div class="ui-g-1 titleButton">
      <i class="fa fa-eraser" [ngClass]="{'itemUnchecked':_isShowElement, 'itemDisabled': !_isShowElement}" (click)="clear()"></i>
    </div>
  </div>

  <div>
    <div>
      <div class="title">{{ 'common.qualityCheckFilter' | translate }}</div>
      <span class="itemText">{{selectedMinQuality | elementQualityPipe}} {{ 'common.to' | translate }} {{selectedHighQuality | elementQualityPipe}}</span>
      <div class="custom-slider3 qualitySlider">
        <ng5-slider [(value)]="selectedMinQuality" [(highValue)]="selectedHighQuality" [options]="qualitySliderOptions"></ng5-slider>
      </div>
      <br/>
    </div>

    <div>
      <div class="title">{{ 'common.networkFilter' | translate }}</div>
      <p-chips [(ngModel)]="selectedNetworks" ></p-chips>
    </div>

    <div *ngIf="classList?.length">
      <div class="title">{{ 'common.symbolFilter' | translate }} ({{numberOfSelectClasses}} {{ 'common.selected' | translate }})</div>
      <p-accordion>
        <p-accordionTab *ngFor="let classGroup of classGroups">
          <p-header>
<!--            <img width="18" align="middle"-->
<!--                 src="{{environment.urlMedia}}icon/sign-fr/category/{{classGroup | uppercase}}.png" alt="{{classGroup}}"-->
<!--                 class="groupImage">-->
            <span class="groupHeader">{{classGroup | elementClassName}}</span>
          </p-header>
          <div ngModel #elementClassDiv ></div>
          <div *ngFor="let itemClass of classList | filterByFieldValue:{field: 'classCategory', value:classGroup}"
               (click)="toggleFilterByClass(itemClass)" class="ui-helper-clearfix"
               style="border-bottom:1px solid #D5D5D5">
               <i [class]="itemClass | ElementFilePathToClass: elementClassDiv"></i>

            <div style="font-size:14px;float:right;margin:0px 20px 0 5px"
                 [ngClass]="{'itemChecked':(isClassSelected(itemClass)), 'itemUnchecked': !(isClassSelected(itemClass))}">{{itemClass.classDescription}}</div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
