import { Injectable         } from '@angular/core'          ;
import { Store              } from 'src/app/globals/store'  ;

export class RoadFsiState{
    _isShowElement            : boolean   = false       ;
    severities                : string[]  = ["killed", "severe", "light", "unscathed"];
    users                     : string[]  = ["peds", "bicycle", "scooter", "moto", "auto", "hgv"];
    years                     : number[]  = [2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009] ;
    months                    : string[]  = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'] ;
    dows                      : string[]  = ['1', '2', '3', '4', '5', '6', '7'] ;
    hours                     : string[]  = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
    daytimes                  : string[]  = ['night', 'morning', 'afternoon', 'evening'];
    ages                      : string[]  = [];
    isVisible                 : any       = { 'road_users': true, 'severities': true, 'years': false, 'months': false, 'DOWs': false, 'hours': false } ; 
    selectedDensity           : boolean   = false ;
    selectedSeverities        : string[]  = ["unscathed", "light", "severe", "killed"]          ;
    selectedUsers             : string[]  = ["auto"]     ;
    selectedYears             : number[]  = [2022, 2021, 2020, 2019, 2018] ;
    selectedMonths            : string[]  = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'] ;
    selectedDOWs              : string[]  = ['1', '2', '3', '4', '5', '6', '7'] ;
    selectedHours             : string[]  = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
    selectedAges              : string[]  = [];
}

@Injectable({
    providedIn: 'root'
})

export class RoadFsiStoreService extends Store<RoadFsiState> {
    constructor() {
        super(RoadFsiState)
    }
}
