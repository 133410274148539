import { Injectable } from '@angular/core';
import { AxisEnum } from 'src/app/enums/axis-enum';
import { MapLayerEnum } from 'src/app/enums/map-layer-enum.enum';
import { MapSourceEnum } from 'src/app/enums/map-source-enum.enum';
import { Session } from 'src/app/globals/session';
import { ComputeVisibilityHelper } from 'src/app/helpers/compute-visibility.helper';
import { FeatureCollectionModel } from 'src/app/models/geojson/feature-collection-model';
import { DatasourceService } from 'src/app/services/datasource.service';
import { MapService } from 'src/app/shared/map/map.service';
import { PanelService } from 'src/app/shared/panel/panel.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { VisibilityOnDemandState, VisibilityOnDemandStoreService } from './visibility-on-demand-store.service';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class VisibilityOnDemandService {

  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }

  constructor(private session: Session,
              private datasourceService: DatasourceService,
              private computeVisibilityHelper: ComputeVisibilityHelper,
              private spinnerService: SpinnerService,
              private mapService:MapService,
              private toastService: ToastService,
              private panelService: PanelService,
              private visibilityOnDemandStore: VisibilityOnDemandStoreService) {
      this.visibilityOnDemandStore.applyAndWatch(this.show.bind(this))

  }

  show(state: VisibilityOnDemandState) {
    if  (state._isShowElement) {

      this.spinner.addOperation("VisibilityOnDemand.getVisibility");
      let visibilityFeatures: FeatureCollectionModel = this.computeVisibilityHelper.computeVisibilityWithSegments(state.segments, state.speedLimit, state.wayWidth, state.driverHeight, AxisEnum.ASC, state.wayIdentifier);
      let visibilityFeatures2: FeatureCollectionModel = this.computeVisibilityHelper.computeVisibilityWithSegments(state.segments, state.speedLimit, state.wayWidth, state.driverHeight, AxisEnum.DSC, state.wayIdentifier);

      visibilityFeatures2.features.forEach(feature => {
      visibilityFeatures.features.push(feature);
    });
    this.drawNonVisibility(visibilityFeatures);
    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ShowVisibilityElement');
    }
    }

  }

  drawNonVisibility(visibilityFeatures: FeatureCollectionModel) {
    let layout = {
      'line-cap': 'round',
      'line-join': 'round'
    };

    let paint = {
      'line-color': [
        'match',
        ['get', 'axis'],
        'ASC',
        '#002FFF',
        'DSC',
        '#FF3A44',
        '#FF8F3A'
      ],
      'line-width': 2.1,
      'line-opacity': .9,
      'line-offset': [
        'interpolate',
        ['exponential', 0.5],
        ['zoom'],
        15, 3,
        22, 18]
    };

    this.map.addLineLayer(MapLayerEnum.LAYER_VISIBILITY, MapSourceEnum.SOURCE_LAYER_VISIBILITY, layout, paint, visibilityFeatures);
    this.spinner.removeOperation("VisibilityOnDemand.getVisibility");

  }


}
