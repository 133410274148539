import {Injectable} from '@angular/core';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {Session} from 'src/app/globals/session';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {StreetlightState, StreetlightStoreService} from './streetlight-store.service';
import {StreetlightViewPanelComponent} from './streetlight-view-panel/streetlight-view-panel.component';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class StreetlightService {

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }

  constructor(public session: Session, private geojsonService: GeojsonService,
              private spinnerService: SpinnerService,
              private mapService: MapService,
              private toastService: ToastService,
              private panelService: PanelService,
              private streetlightStore: StreetlightStoreService) {
    this.streetlightStore.applyAndWatch(this.showElementWrapper.bind(this))
  }


  showElementWrapper(state: StreetlightState) {
    this.mapService.callWhenAvailable(_ => this.showElement(state))
  }

  showElement(state: StreetlightState) {

    this.map.removeLayer(MapLayerEnum.LAYER_STREETLIGHT, MapSourceEnum.SOURCE_STREETLIGHT);

    if (state._isShowElement) {
      let tileUrl = environment.urlTilesElements;
      tileUrl += '?elementType=' + ElementTypesEnum.ELEMENT_TYPE_STREETLIGHT;
      tileUrl += '&isRelevant=true';
      tileUrl += '&isConfirmed=' + state.isConfirmed;
      tileUrl += '&isDuplicate=false';
      tileUrl += '&isPrimary=true';

      if (state.selectedNetworks.length > 0) {
        state.selectedNetworks.forEach((network: string) => {
          if (network.length > 0) {
            tileUrl = tileUrl + '&elementNetworkList=' + network;
          }
        });
      }

      let paint = {
        'circle-color': [
          'match',
          ['get', 'element_category'],
          '1',
          '#fb0911',
          '2',
          '#eccb3d',
          '3',
          '#3bb2d0',
          '4',
          '#3068c0',
          '#0dcc33'
        ],
        'circle-radius': 3
      };

      this.streetlightStore.state.callUrl = tileUrl;

      this.map.addCircleLayerTiles(MapLayerEnum.LAYER_STREETLIGHT, tileUrl, MapSourceEnum.SOURCE_ELEMENT, MapSourceEnum.SOURCE_STREETLIGHT, true, paint);

      this.map.map.on("click", MapLayerEnum.LAYER_STREETLIGHT, e => {
        this.viewElement(e.features[0].properties['element_identifier']);
      });

    }
  }

  viewElement(elementIdentifier: string) {

    this.spinner.addOperation("MenuStreetLightComponent.viewElement");
    this.geojsonService.getObjectElementCollection(undefined, undefined, undefined, undefined,
      ElementTypesEnum.ELEMENT_TYPE_STREETLIGHT, undefined, undefined, undefined, elementIdentifier, undefined, undefined,
      undefined, 0, undefined
    ).subscribe(response => {
      if (response && response.features.length > 0) {
        this.panelService.open(
          StreetlightViewPanelComponent,
          response.features.pop()
        )
      } else {
        this.toast.showToastWarn();
      }

      this.spinner.removeOperation("MenuStreetLightComponent.viewElement");
    }, () => {
      this.toast.showToastError();
      this.spinner.removeOperation("MenuStreetLightComponent.viewElement");
    });
    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ViewStreetLightElement');
    }
  }

}
