import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {FeatureCollectionModel} from '../models/geojson/feature-collection-model';
import {catchError, map} from 'rxjs/operators';
import {GeometryModel} from '../models/geojson/geometry-model';
import {Session} from "../globals/session";
import {RequestHelper} from "../helpers/request.helper";
import {GeometryTypeEnum} from "../enums/geometry-type-enum.enum";
import {ElementTypesEnum} from "../enums/element-types-enum.enum";

@Injectable({
  providedIn: 'root'
})
export class GeojsonService {

  constructor(private session: Session, private requestHelper: RequestHelper, private httpService: HttpClient) {}


  errorMessage: any;
  @Output() ErrorMessage: EventEmitter<any> = new EventEmitter();
  element: any;
  @Output() elementEmiter: EventEmitter<any> = new EventEmitter();

  resetElement() {
    this.element = undefined;
    this.elementEmiter.emit(this.element);
  }
  displayStatus(errorMessage){
    this.errorMessage = errorMessage;
    this.ErrorMessage.emit(this.errorMessage);
  }

  getObjectViewCollection(viewType: string, viewIdentifier: string = undefined, geometry: GeometryModel = undefined, distance: number = 15): Observable<FeatureCollectionModel> {
    const url = environment.urlGeoJsonGetObjectViewCollection;

    let headers = this.requestHelper.createHttpHeaders();
    let options = {headers};

    return this.httpService.post(url, {
      header: this.requestHelper.createHeader([ResponseLevelEnum.ALL], -1,-1),
      viewType: viewType,
      viewIdentifier: viewIdentifier,
      geometry: geometry,
      distance: distance
    }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(err => {
        return throwError('ViewCollection not found');
      })
    );
  }

  getObjectViewCollectionStrip(viewType: string, viewIdentifier: string = undefined, geometry: GeometryModel = undefined, distance: number = 15): Observable<FeatureCollectionModel> {
    const url = environment.urlGeoJsonGetObjectViewCollection;

    let headers = this.requestHelper.createHttpHeaders();
    let options = {headers};

    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      viewType: viewType,
      viewIdentifier: viewIdentifier,
      geometry: geometry,
      distance: distance
    }, options).pipe(map(data => {
        return new FeatureCollectionModel().deserialize(data);
      }),
      catchError(error => {
        return throwError('View not found');
      })
    );
  }

  getObjectElementCollection(qualificationAxis: string[] = undefined, qualificationType: string = undefined,
                             qualificationColour: string[] = undefined, qualificationPaint: string[] = undefined,
                             elementType: string, elementName: string = undefined, elementClass: string = undefined,
                             elementClassList: string[] = undefined, elementIdentifier: string = undefined,
                             elementDateBegin: string = undefined, elementDateEnd: string = undefined,
                             geometry: GeometryModel = undefined,  distance: number = 15,
                             onlyRelevant:boolean = undefined, onlyConfirmed:boolean = undefined,
                             onlyPrimary: boolean = undefined, elementQualityMin : number = undefined,
                             elementQualityMax : number = undefined, viewIdentifier: string = undefined,
                             excludeCategories: string[] = undefined, excludeElementName: string[] = undefined,
                             elementNetworkList: string[] = undefined): Observable<FeatureCollectionModel> {
    const url = environment.urlGeoJsonGetObjectElementCollection;
    let headers = this.requestHelper.createHttpHeaders();

    let options = {headers};

    let elementProperties: any = {};
    if (qualificationAxis) {
      elementProperties["qualification.axis"] = qualificationAxis;
    }
    if (qualificationType) {
      elementProperties["qualification.type"] = qualificationType;
    }
    if (qualificationColour) {
      elementProperties["qualification.colour"] = qualificationColour;
    }
    if (qualificationPaint) {
      elementProperties["qualification.painting_type"] = qualificationPaint;
    }

    if (geometry != undefined
          && geometry.type === GeometryTypeEnum.POINT
          && distance < 3) {
      distance = 3;
    }

    return this.httpService.post(url, {
      header: this.requestHelper.createHeader([ResponseLevelEnum.ALL], -1,-1),
      elementProperties: elementProperties,
      elementName: elementName,
      elementType: elementType,
      elementClass: elementClass,
      elementClassList: elementClassList,
      elementIdentifier: elementIdentifier,
      elementDateBegin: elementDateBegin,
      elementDateEnd: elementDateEnd,
      elementQualityMin : elementQualityMin,
      elementQualityMax : elementQualityMax,
      viewIdentifier: viewIdentifier,
      distance: distance,
      geometry: geometry,
      isRelevant: (onlyRelevant === true ? true : undefined),
      isConfirmed: (onlyConfirmed === true ? true : undefined),
      isPrimary: (onlyPrimary === true ? true : undefined),
      elementPropertiesExist: (onlyRelevant === true && elementType === ElementTypesEnum.ELEMENT_TYPE_GROUND ? 'qualification' : undefined),
      excludeCategories: excludeCategories,
      excludeElementNames: excludeElementName,
      elementNetworkList: elementNetworkList
    }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      //catchError(() => throwError('Collection not found'))
      catchError(err => {
        this.displayStatus(err);
        return throwError('ElementCollection not found');

      })
    );
  }

    getRoadFsiCollection(accidentId: string = undefined): Observable<FeatureCollectionModel> {
        let isAccidentIdMissing: boolean = !accidentId || !accidentId.length;
        if(isAccidentIdMissing) return  

        const url = environment.urlRoadFsiDetails.replace('{id}', accidentId || "") ;
        let headers = this.requestHelper.createHttpJsonHeaders();
        let options = {headers};

        return this.httpService.get(url, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
            catchError(error => {
                this.displayStatus(error);
                return throwError('RoadFsiDetails not found');
            })
        );
    }

  getSnapshotCollection(snapshotIdentifier: string): Observable<FeatureCollectionModel> {
    const url = environment.urlGeoJsonGetSnapshotCollection + "snapshotIdentifier=" + snapshotIdentifier;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.get(url , options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(() => throwError('SnapshotCollection not found'))
    );
  }

   findNearestViewCollection(long:number, lat:number, mode:string, distance:number, excludeView:string, viewOrientation= 0, elementType:string): Observable<FeatureCollectionModel> {
    const url = environment.urlFindNearestViewCollection +
      mode + '&distance=' + distance + '&long=' + long + '&lat=' + lat + '&excludeViewIdentifier=' + excludeView +"&viewOrientation=" + viewOrientation +"&elementType="+elementType ;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.get(url , options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(() => throwError('View not found'))
    );
  }

  getPreviousAndNextViewCollection(viewType:string, snapshotIdentifier:string, viewOrder:number, elementType): Observable<FeatureCollectionModel> {
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers: headers};
    return this.httpService.post(environment.urlGetPreviousAndNextViewCollection, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      viewType: viewType,
      snapshotIdentifier: snapshotIdentifier,
      viewOrder: viewOrder,
      elementTypes: [elementType]
    }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(() => throwError('View not found'))
    );
  }

  getObjectAlertCollection(alertImpact?: string,alertType?:string, alertCategory?:string, alertDateBegin?:string,alertDateEnd?:string,alertIdentifier?:string,snapshotIdentifier?:string,alertActive?:string,geometry: GeometryModel = undefined, distance?: number): Observable<FeatureCollectionModel> {
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers: headers};
    return this.httpService.post(environment.urlGetObjectAlertCollection, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL,

        ]
      },
      distance: distance,
      alertImpact: alertImpact,
      alertType: alertType,
      alertCategory:alertCategory,
      alertDateBegin:alertDateBegin,
      alertDateEnd:alertDateEnd,
      alertIdentifier: alertIdentifier,
      snapshotIdentifier: snapshotIdentifier,
      geometry : geometry,
      alertActive: alertActive,
    }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(() => throwError('Alert not found'))
    );
  }

  getLocationMarkerSegment(locationMarkerIdentifier: string): Observable<FeatureCollectionModel> {
    // const url = environment.urlGetLocationMarker;
    const url = environment.urlGetLocationMarker + '/' + locationMarkerIdentifier + '/segment';
    let headers = this.requestHelper.createHttpJsonHeaders();

    let options = {headers};

    return this.httpService.get(url, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(error => {
        this.displayStatus(error);
        return throwError('LocationMarkerDetails not found');
      })
    );
  }

  getLocationMarkerDetail(locationMarkerIdentifier: string): Observable<FeatureCollectionModel> {
    // const url = environment.urlGetLocationMarker;
    const url = environment.urlGetLocationMarker + '/' + locationMarkerIdentifier;
    let headers = this.requestHelper.createHttpJsonHeaders();

    let options = {headers};

    return this.httpService.get(url, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(error => {
        this.displayStatus(error);
        return throwError('LocationMarkerDetails not found');
      })
    );
  }

  getLocationMarkerIds(county: string, way: string, from: number, to: number): Observable<FeatureCollectionModel> {
    // const url = environment.urlGetLocationMarker;
    const url = environment.urlGetLocationMarker + '?county=' + county + '&way=' + way + '&from='+ from + '&to=' + to;
    let headers = this.requestHelper.createHttpJsonHeaders();

    let options = {headers};

    return this.httpService.get(url, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(error => {
        this.displayStatus(error);
        return throwError('LocationMarkerDetails not found');
      })
    );
  }
}
