import {Component, OnDestroy, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {MapService} from 'src/app/shared/map/map.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {LocationMarkerStoreService} from './location-marker-store.service';
import Matomo from "../../../../stats";
import {PanelService} from "../../../../shared/panel/panel.service";
import {LocationMarkerEntryPanelComponent} from "./location-marker-entry-panel/location-marker-entry-panel.component";

@Component({
  selector: 'app-location-marker-entry',
  templateUrl: './location-marker-entry.component.html',
  styleUrls: ['./location-marker-entry.component.css']
})
export class LocationMarkerEntryComponent implements OnInit {

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }

  constructor(public session: Session,
              private mapService: MapService,
              private spinnerService: SpinnerService,
              private toastService: ToastService,
              public locationMarkerStoreService: LocationMarkerStoreService,
              private panelService: PanelService) {

  }

  ngOnInit(): void {
  }

  openLocationMarkers() {
    this.panelService.push(
      LocationMarkerEntryPanelComponent,
      null
    )
    Matomo.push(['trackEvent', 'Actions', 'Displayed location marker'])
  }

  removeLocationMarkers(event:Event){
    event.preventDefault();
    event.stopPropagation();
    this.locationMarkerStoreService.reset();
    Matomo.push(['trackEvent', 'Actions', 'Hide location marker'])
  }


}
