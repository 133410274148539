import { Injectable } from '@angular/core';
import { ElementTypesEnum } from 'src/app/enums/element-types-enum.enum';
import { EventCodeEnum } from 'src/app/enums/event-code-enum.enum';
import { Session } from 'src/app/globals/session';
import { WindowEventHelper } from 'src/app/helpers/window-event.helper';
import { AlertModel } from 'src/app/models/alerting/alert-model';
import { FeatureCollectionModel } from 'src/app/models/geojson/feature-collection-model';
import { GeojsonService } from 'src/app/services/geojson.service';
import { MapService } from 'src/app/shared/map/map.service';
import { PanelService } from 'src/app/shared/panel/panel.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { AlertState, AlertStoreService } from './alert-store.service';
import { AlertViewPanelComponent } from './alert-view-panel/alert-view-panel.component';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }

  constructor(public session: Session,
    private geojsonService: GeojsonService,
    private spinnerService: SpinnerService,
    private mapService:MapService,
    private toastService: ToastService,
    private panelService: PanelService,
    private alertStore: AlertStoreService) {
      this.alertStore.applyAndWatch(this.showElementWrapper.bind(this))
    }


    showElementWrapper(state: AlertState){
      this.mapService.callWhenAvailable(_=>this.showElement(state));
    }

  showElement(state: AlertState) {
    if (state.alertActive === true) {
      state.active = 'true';
    }
    // Remove Markers
    let elements = document.getElementsByClassName('marker-' + ElementTypesEnum.ELEMENT_TYPE_ALERT);
    while (elements.length > 0) {
      elements[0].remove();
    }
    //Show Markers
    if (state._isShowElement === true) {
      this.spinner.addOperation("MainMenuComponent.showElementAlert");

      this.geojsonService.getObjectAlertCollection(state.alertImpact, state.alertType, state.alertCategory, state.dateBegin, state.dateEnd, undefined, undefined, undefined, this.session.getWorkspace()).subscribe((response: FeatureCollectionModel) => {
          if (response && response.features.length > 0) {
            // Add Markers
            response.features.forEach((feature) => {

              let featureProperties: any = feature.properties as any;
              let elementMarker = document.createElement('div');
              elementMarker.className = 'marker-' + ElementTypesEnum.ELEMENT_TYPE_ALERT;

              if (featureProperties.alertCategory !== null) {
                state.features.push(feature);

                if (featureProperties.alertActive === false) {
                  elementMarker.style.backgroundImage = 'url(assets/images/'+ featureProperties.alertCategory.toLowerCase() + '_Inactif.png' + ')';

                }
                if (featureProperties.alertActive === true) {
                  elementMarker.style.backgroundImage = 'url(assets/images/' + featureProperties.alertCategory.toLowerCase() + '_Actif.png'+ ')';
                }

                if (featureProperties.alertClosed === true) {
                  elementMarker.style.backgroundImage = 'url(assets/images/' + featureProperties.alertCategory.toLowerCase() + '_Close.png'+ ')';
                }
                elementMarker.style.width = '28px';
                elementMarker.style.height = '28px';
                elementMarker.style.backgroundRepeat = 'no-repeat';
                elementMarker.style.backgroundSize = 'contain';

                elementMarker.addEventListener('click', () => {
                    this.viewElement(featureProperties.alertIdentifier);
                    this.map.resizeElementsMarkers();
                    elementMarker.style.width = '45px';
                    elementMarker.style.height = '45px';
                  }
                );
                elementMarker.addEventListener('touchstart', () => {
                    this.viewElement(featureProperties.alertIdentifier);
                    this.map.resizeElementsMarkers();
                    elementMarker.style.width = '45px';
                    elementMarker.style.height = '45px';
                  }
                );

                this.map.addElementMarker(feature, elementMarker);
              }
            });

            WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_ALERT, response.features);
          } else {
            this.toast.showToastWarn();

            WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_ALERT);
          }
          this.spinner.removeOperation("MainMenuComponent.showElementAlert");
        }
        , () => {
          this.toast.showToastError();
          this.spinner.removeOperation("MainMenuComponent.showElementAlert");
        });
    } else {
      WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_ALERT);
    }
    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ShowAlertElement');
    }
  }




  viewElement(alertIdentifier: string) {
    this.spinner.addOperation("MenuAlertComponent.viewElement");
    this.geojsonService.getObjectAlertCollection( undefined,undefined,undefined,undefined, undefined, alertIdentifier, undefined).subscribe(response => {
      if (response && response.features.length > 0) {
        this.panelService.open(
          AlertViewPanelComponent,
          response.features.pop() as unknown as AlertModel
        )
      } else {
        this.toast.showToastWarn();
      }
      this.spinner.removeOperation("MenuAlertComponent.viewElement");
    }, () => {
      this.toast.showToastError();
      this.spinner.removeOperation("MenuAlertComponent.viewElement");
    });

    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ViewAlertElement');
    }
  }

}
