import {ISerializable} from '../iserializable';

export class SnapshotUpdateModel implements ISerializable {

  public done: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
