    <div class="ui-g sticky" style="padding-right: 10px;">
      <div class="ui-g-1 titleButton">
        <i class="fa fa-arrow-left" (click)="show(false)"></i>
      </div>
      <div class="ui-g-10">
        <h1>{{ 'furnitureEntry.title' | translate }}</h1>
      </div>
      <div class="ui-g-1 titleButton">
        <i class="fa fa-eraser" [ngClass]="{'itemUnchecked':_isShowElement, 'itemDisabled': !_isShowElement}" (click)="clear()"></i>
      </div>
    </div>

    <div class="title">{{ 'common.yearRange' | translate }}</div>
    <div class="custom-slider3 yearsSlider">
      <ng5-slider  [value]="selectedYearMinValue" [highValue]="selectedYearHighValue" (userChangeEnd)="updateYearValues($event)" [options]="options" ></ng5-slider>
    </div>

    <div>
      <div>
        <div class="title">{{ 'common.qualityCheckFilter' | translate }}</div>
        <span class="itemText">{{selectedMinQuality | elementQualityPipe}} {{ 'common.to' | translate }} {{selectedHighQuality | elementQualityPipe}}</span>
        <div class="custom-slider3 qualitySlider">
          <ng5-slider [value]="selectedMinQuality" [highValue]="selectedHighQuality" (userChangeEnd)="updateQualityValues($event)" [options]="qualitySliderOptions"></ng5-slider>
        </div>
        <br/>
      </div>
      <div>
        <div class="title">{{ 'common.networkFilter' | translate }}</div>
        <p-chips [(ngModel)]="selectedNetworks" ></p-chips>

      </div>
      <div *ngIf="classList?.length">
        <div class="title">{{ 'common.symbolFilter' | translate }} ({{numberOfSelectClasses}} {{ 'common.selected' | translate }})</div>
        <p-accordion>
          <p-accordionTab *ngFor="let classGroup of classGroups">
            <p-header>
              <span class="groupHeader">{{classGroup}}</span>
            </p-header>
            <div ngModel #elementClassDiv ></div>
            <div *ngFor="let itemClass of classList | filterByFieldValue:{field: 'classCategory', value:classGroup}"
                 (click)="toggleFilterByClass(itemClass)" class="ui-helper-clearfix"
                 style="border-bottom:1px solid #D5D5D5">
                 <i [class]="itemClass | ElementFilePathToClass: elementClassDiv"></i>

                 <div *ngIf="itemClass.classDescription.length < 40" style="font-size:14px;float:right;margin:0px 20px 0 5px"
                 [ngClass]="{'itemChecked':(isClassSelected(itemClass)), 'itemUnchecked': !(isClassSelected(itemClass))}"
                 >{{itemClass.classDescription}}</div>
                 <div *ngIf="itemClass.classDescription.length >= 40" style="font-size:14px;float:right;margin:0px 20px 0 5px"
                 [ngClass]="{'itemChecked':(isClassSelected(itemClass)), 'itemUnchecked': !(isClassSelected(itemClass))}"
                 [pTooltip]="itemClass.classDescription" >{{itemClass.classDescription | truncate}}</div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>

