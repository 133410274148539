<div class="ui-g sticky" style="padding-right: 10px;">
  <div class="ui-g-1 titleButton">
    <i class="fa fa-arrow-left" (click)="close()"></i>
  </div>
  <div class="ui-g-10">
    <h1>{{ 'bankEntry.title' | translate }}</h1>
  </div>
  <div class="ui-g-1 titleButton">
    <i class="fa fa-eraser" [ngClass]="{'itemUnchecked':_isShowElement, 'itemDisabled': !_isShowElement}" (click)="clear()"></i>
  </div>
</div>

<div>
  <div>
    <div class="title">{{ 'common.networkFilter' | translate }}</div>
    <p-chips [(ngModel)]="selectedNetworks"></p-chips>
  </div>
  <div *ngIf="classList?.length">
    <div ngModel #elementClassDiv ></div>
    <div class="title">{{ 'bankEntry.bankEntryPanel.filter' | translate }} ({{numberOfSelectClasses}} {{ 'common.selected' | translate }})</div>
    <p-accordion>
      <p-accordionTab *ngFor="let classGroup of classGroups">
        <p-header>
          <span class="groupHeader">{{classGroup}}</span>
        </p-header>
        <div *ngFor="let itemClass of classList | filterByFieldValue:{field: 'classCategory', value:classGroup}"
             (click)="toggleFilterByClass(itemClass)" class="ui-helper-clearfix"
             style="border-bottom:1px solid #D5D5D5">
          <i [class]="itemClass | ElementFilePathToClass: elementClassDiv"></i>

          <div style="font-size:14px;float:right;margin:0px 20px 0 5px"
               [ngClass]="{'itemChecked':isClassSelected(itemClass), 'itemUnchecked': !isClassSelected(itemClass)}">{{itemClass.classDescription}}</div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
