import {Component, Input, OnInit} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ViewTypesEnum} from 'src/app/enums/view-types-enum.enum';
import {Session} from 'src/app/globals/session';
import {StringUtils} from 'src/app/helpers/string.utils';
import {FeatureModel} from 'src/app/models/geojson/feature-model';
import {FeatureObjectElementModel} from 'src/app/models/geojson/feature-object-element-model';
import {AnnotationService} from 'src/app/services/annotation.service';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {ViewerStoreService} from '../../viewer-entry/viewer-store.service';
import {BboxObject} from "../../../../../models/detection/bbox-object";

declare let OpenLocationCode: any;

@Component({
  selector: 'app-streetlight-view-panel',
  templateUrl: './streetlight-view-panel.component.html',
  styleUrls: ['./streetlight-view-panel.component.css']
})
export class StreetlightViewPanelComponent implements OnInit, PanelBase<FeatureModel> {


  get spinner() {
    return this.spinnerService.getSpinner();
  }
  get map() {
    return this.mapService.getMap();
  }
  get toast() {
    return this.toastService.getToast();
  }

  Asset_code: string;
  Road_code: string;
  showDetails: boolean;
  Asset_type: string;
  Road_description: string;
  elementImage: string;
  elementProperties: FeatureObjectElementModel = new FeatureObjectElementModel();
  luwaProperties: any;
  _isVisible: boolean = false;
  onEdition: boolean = false;
  zone: string = "";
  underneathHeight: number;
  heightDetection: number;
  widthDetection: number;
  totalHeight: number;
  numOfDetections: number;
  openLocCode: string;
  element: FeatureModel = new FeatureModel();

  constructor(private annotationService: AnnotationService,
              public session: Session,
              private messageService: MessageService,
              private geojsonService: GeojsonService,
              private confirmationService: ConfirmationService,
              private spinnerService: SpinnerService,
              private mapService: MapService,
              private toastService: ToastService,
              private panelService: PanelService,
              private viewerStore: ViewerStoreService) {
  }

  data: FeatureModel;

  ngOnInit() {
    this.selectedElement = this.data;
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if (show) {
      this.onEdition = false;
    }
    if (!show && !this.onEdition) {
      this.map.resizeElementsMarkers();
    }
    if (!show) {
      this.panelService.close();
    }
  }

  @Input()
  set selectedElement(element: FeatureModel) {
    if (element !== undefined) {
      this.element = element;
      let openLocCode = new OpenLocationCode();
      this.openLocCode = openLocCode.encode(element.geometry.coordinates[0], element.geometry.coordinates[1]);

      this.elementProperties = element.properties as any;
      if (this.elementProperties != undefined) {

        if (StringUtils.isNotEmpty(this.elementProperties.elementUrl)) {
          this.elementImage = environment.urlStorage + this.elementProperties.elementUrl;
        } else {
          this.elementImage = undefined;
        }
        this.underneathHeight = this.elementProperties.viewBbox.underneathHeightInMeter;
        this.heightDetection = this.elementProperties.viewBbox.verticalSizeInMeter;
        this.widthDetection = this.elementProperties.viewBbox.widthInMeter;
        this.totalHeight = this.elementProperties.viewBbox.aboveHeightInMeter;
        this.numOfDetections = this.elementProperties.viewBbox.numOfDetections;

        if (this.elementProperties.elementProperties != undefined) {
          // @ts-ignore
          if (this.elementProperties.elementProperties.luwa) {
            // @ts-ignore
            this.luwaProperties = this.elementProperties.elementProperties.luwa as any;
            this.Asset_code = this.luwaProperties[0].Asset_code;
            this.Asset_type = this.luwaProperties[0].Asset_type;
            this.Road_code = this.luwaProperties[0].Road_code;
            this.Road_description = this.luwaProperties[0].Road_description;
            this.zone = this.luwaProperties[0].Zone;

            this.showDetails = true;
          }
        } else {
          this.showDetails = false;
        }
      }

      this.show();
    } else {
      this.show(false);
    }
  }

  showViewer() {
    this.viewerStore.state = {
      isShowViewer: true,
      isShowGoogle: false,
      viewIdentifier: this.elementProperties.viewIdentifier,
      bbox: new BboxObject().create(
        this.elementProperties.viewBbox.x,
        this.elementProperties.viewBbox.y,
        this.elementProperties.viewBbox.width,
        this.elementProperties.viewBbox.height,
        0,
        0, 0
      ),
      mode: ViewTypesEnum.VIEW_PANO_360_M
    }
  }

}
