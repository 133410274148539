import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectElementModel, HttpHeadersEnum, ContentTypesEnum } from '@mycitymagine/ts-citymagine-core';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthorizationTypesEnum } from '../../enums/authorization-types-enum.enum';
import { ResponseLevelEnum } from '../../enums/response-level-enum.enum';
import { Session } from '../../globals/session';
import { ObjectElementVersionModel } from '../../models/version/object-element-version-model';
import { GetObjectElementVersionsResponseDto, GetHasObjectElementVersionsResponseDto } from './dtos/dtos';

@Injectable({
  providedIn: 'root'
})
export class VersionService {


  constructor(private session: Session, private httpService: HttpClient) {
  }

  getObjectElementVersions(elementIdentifier: string): Observable<ObjectElementVersionModel[]> {
    const url = `${environment.urlVersionGetObjectElementVersions}/${elementIdentifier}`;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = { headers };

    return this.httpService
        .get<GetObjectElementVersionsResponseDto>(url, options)
        .pipe(
          catchError(()=>of({elements: []})),
          map(body =>
            body
              .elements
              .map(element =>
                new ObjectElementVersionModel().deserialize(element))));
  }
}
