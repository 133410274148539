<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_RIVER_SIGN')"
appWorkspaceRequired
[ngClass]="{'itemChecked':riverSignStore.state._isShowElement, 'itemUnchecked': !riverSignStore.state._isShowElement}"
(click)="openMenuRiverSign()">
<i class="ca ca-river-sign"></i>
<span class="text">{{ 'riverSignEntry.title' | translate }}</span><br />
<a role="button" *ngIf="riverSignStore.state._isShowElement">
    <span (click)="close($event)" class="pi pi-times ng-tns-c48-2">
    </span>
</a>
</li>
