<div class="ui-g sticky" style="padding-right: 10px;">
  <div class="ui-g-1 titleButton">
    <i class="fa fa-arrow-left" (click)="show(false)"></i>
  </div>
  <div class="ui-g-11">
    <h1>{{ 'streetlightEntry.title' | translate }}</h1>
  </div>
</div>

<div class="imageContainer">
  <img alt="Crop image" *ngIf="(elementImage !== undefined)" [src]="elementImage" class="showStreetlight"
       (click)="showViewer()"/>
</div>

<p-accordion [multiple]="true">
  <p-accordionTab header="{{ 'common.details' | translate }}" *ngIf="showDetails">
    <div>
      <ul class="subMenu">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'streetlightEntry.streetlightViewPanel.assetCode' | translate }}</p>
          <p>{{Asset_code}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'streetlightEntry.streetlightViewPanel.assetType' | translate }}</p>
          <p>{{Asset_type}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'streetlightEntry.streetlightViewPanel.roadDescription' | translate }}</p>
          <p>{{Road_description }}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'streetlightEntry.streetlightViewPanel.roadCode' | translate }}</p>
          <p>{{Road_code}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'streetlightEntry.streetlightViewPanel.zone' | translate }}</p>
          <p>{{zone}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
  <p-accordionTab header="{{ 'common.metrics' | translate }}">
    <div>
      <ul class="subMenu" *ngIf="underneathHeight">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'streetlightEntry.streetlightViewPanel.underneathHeight' | translate }}</p>
          <p>{{underneathHeight | number: '1.0-2'}} m</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="heightDetection">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'streetlightEntry.streetlightViewPanel.heightDetection' | translate }}</p>
          <p>{{heightDetection | number: '1.0-2'}} m</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="widthDetection">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'streetlightEntry.streetlightViewPanel.widthDetection' | translate }}</p>
          <p>{{widthDetection | number: '1.0-2'}} m</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="totalHeight">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'streetlightEntry.streetlightViewPanel.totalHeight' | translate }}</p>
          <p>{{totalHeight | number: '1.0-2'}} m</p>
        </li>
      </ul>
      <ul class="subMenu" *ngIf="numOfDetections">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'streetlightEntry.streetlightViewPanel.number' | translate }}</p>
          <p>{{numOfDetections | number: '1.0-0'}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
  <p-accordionTab header="{{ 'common.localization' | translate }}">
    <div>
      <ul class="subMenu">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.coordinates' | translate }}</p>
          <p>Long: {{element.geometry.coordinates[0]}}</p>
          <p>Lat : {{element.geometry.coordinates[1]}}</p>
        </li>
      </ul>
      <ul class="subMenu">
        <li class="listMenuElement">
          <p class="listMenuElementTitle">{{ 'common.openLocationCode' | translate }}</p>
          <p>{{openLocCode}}</p>
        </li>
      </ul>
    </div>
  </p-accordionTab>
</p-accordion>

<p-confirmDialog [style]="{width: '50vw'}" [transitionOptions]="'200ms'" key="SignComponent"></p-confirmDialog>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
