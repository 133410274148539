import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {HeliosEntryPanelComponent} from './helios-entry-panel/helios-entry-panel.component';
import {HeliosStoreService} from './helios-store.service';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-helios-entry',
  templateUrl: './helios-entry.component.html',
  styleUrls: ['./helios-entry.component.css']
})
export class HeliosEntryComponent implements OnInit {

  constructor(public session: Session, public heliosStore: HeliosStoreService, public panelService: PanelService) { }

  ngOnInit(): void {
  }

  openMenuHelios(){
    this.panelService.push(
      HeliosEntryPanelComponent,
      null
    )
    Matomo.push(['trackEvent', 'Actions', 'Opened helios menu'])
  }

  closeHelioMenu(){
    Matomo.push(['trackEvent','Actions','Closed helios menu'])
    this.heliosStore.reset();
  }

}
