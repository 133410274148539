<ul [style]="{'margin-top': '0', 'margin-right': '10px', 'display': 'flex',
                                'flex-direction': 'row-reverse'}">
    <li class="listMenuElementQuality" *ngFor="let quality of qualityList">
        <div *ngIf="(data.elementQuality >= quality.valueParameters.minimumQuality) &&
                    (data.elementQuality <= quality.valueParameters.maximumQuality); else defaultTemplate"
                    class="listMenuQuality" [style]="{backgroundColor: quality.valueParameters.color}"
                    pTooltip="{{quality.valueParameters.state}}">
        </div>
        <ng-template #defaultTemplate>
            <div class="listMenuQuality defaultQuality" [style]="{'cursor': 'pointer'}" (click)="setNewElementQuality(quality)"></div>
        </ng-template>
    </li>
</ul>
