import {Injectable} from '@angular/core';
import {Store} from 'src/app/globals/store';
import {GeometryModel} from "../../../../models/geojson/geometry-model";
import {BboxObject} from "../../../../models/detection/bbox-object";

export class ViewerState{
  isShowViewer = false;
  isShowGoogle = false;
  viewIdentifier = '';
  mode = '';
  bbox?: BboxObject
  geometry?: GeometryModel;
}

@Injectable({
  providedIn: 'root'
})
export class ViewerStoreService extends Store<ViewerState> {

  constructor() {
    super(ViewerState);
  }
}
