import {Component, OnInit} from '@angular/core';
import {ObjectElementModel} from '@mycitymagine/ts-citymagine-core';
import {StringUtils} from 'src/app/helpers/string.utils';
import {ObjectElementVersionModel} from 'src/app/models/version/object-element-version-model';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {environment} from 'src/environments/environment';
import * as moment from 'moment'

@Component({
  selector: 'app-view-version',
  templateUrl: './view-version.component.html',
  styleUrls: ['./view-version.component.css'],
})
export class ViewVersionComponent implements PanelBase<ObjectElementVersionModel[]>, OnInit {

  sourceObjectElement: ObjectElementModel;
  elementVersions: (ObjectElementVersionModel | ObjectElementModel)[];

  currentlySelected = 0;
  currentSelectedImage = null;

  currentInterventionDate = null;
  currentAppliedProduct = null;
  currentQuality = null;

  moment = moment


  constructor(private panelService: PanelService) {
  }

  ngOnInit(): void {
    this.load(this.data)
  }

  data: ObjectElementVersionModel[];

  load(versions: ObjectElementVersionModel[]) {
    this.elementVersions = versions;
    this.setSelected(0);
  }

  setSelected(index: number) {
    const element = this.elementVersions[index];
    this.currentlySelected = index;
    this.currentInterventionDate = this.elementRest(element.elementTimestamp) + " " + this.elementYear(element.elementTimestamp)
    this.currentQuality = element.elementQuality
    this.currentAppliedProduct = element.elementProperties?.["qualification"]?.["painting_type"] ||
      element.elementProperties?.["qualification"]?.["film_class"];
    if (StringUtils.isNotEmpty(element.elementStorageFilePath)) {
      this.currentSelectedImage = environment.urlStorage + element.elementStorageFilePath;
    } else {
      this.currentSelectedImage = undefined;
    }
  }

  elementYear(timestamp) {
    return moment.default(timestamp).format("Y");
  }

  elementRest(timestamp) {
    return moment.default(timestamp).format("D MMMM");
  }

  _closeVersions() {
    this.panelService.pop();
  }
}
