import { Pipe, PipeTransform } from '@angular/core';

const nameMapping = {
  AXE: 'Flèche - simple axe',
  AXE_LEFT: 'Flèche axe et gauche',
  AXE_RIGHT: 'Flèche axe et droite',
  DOUBLE_LEFT: 'Flèche - double gauche',
  DOUBLE_RIGHT: 'Flèche - double droite',
  TRIPLE: 'Flèche - gauche-axe-droite',
  DRAWDOWN: 'Flèche rabattement',
  DRAWDOWN_LEFT: 'Flèche rabattement gauche',
  LEFT: 'Flèche tourner à gauche',
  LEFT_RIGHT: 'Flèche - gauche / droite',
  RIGHT: 'Flèche tourner à droite',
  CDP: 'Cedez le passage',
  DOTTED: 'Ligne discontinue', 
  FIRM: 'Ligne continue',
  MIXED: 'Ligne mixte',
  PARKING: 'Zone de stationnement',
  PARKING_BARRE: 'Zone de stationnement interdit',
  BUS: 'Station de bus',
  PARKING_EPI: 'Zone de stationnement en épit',
  PARKING_SLOT: 'Zone de stationnement en slot',
  PEDCROSSING: 'Passage piétons',
  SPEEDBUMP: 'Ralentisseurs',
  STOP: 'Ligne stop',
  ZEBRA: 'Zebra slash',
  ZEBRA_CHEVRON: 'Zebra chevron',
  ZEBRA_DAMIER: 'Zebra en damier',
  ZEBRA_PLEIN: 'Zebra plein',
  PMR: 'Sigle PMR',
  BIKE: 'Sigle Velo',
  T1: 'Ligne discontinue T1',
  T1_PRIME: 'Ligne discontinue T\'1',
  T2: 'Ligne discontinue T2',
  T2_PRIME: 'Ligne discontinue T\'2',
  T3: 'Ligne discontinue T3',
  T3_PRIME: 'Ligne discontinue T\'3',
  T4: 'Ligne discontinue T4',
  MRE: 'Ligne discontinue MRE',
  MT1: 'Ligne mixe T1',
  MT1_PRIME: 'Ligne mixe T\'1',
  MT2: 'Ligne mixe T2',
  MT2_PRIME: 'Ligne mixe T\'2',
  MT3: 'Ligne mixe T3',
  MT3_PRIME: 'Ligne mixe T\'T3',
  MT4: 'Ligne mixe T4',
  DT1: 'Ligne double T1',
  DT1_PRIME: 'Ligne double T\'1',
  DT2: 'Ligne double T2',
  DT2_PRIME: 'Ligne double T\'2',
  DT3: 'Ligne double T3',
  DT3_PRIME: 'Ligne double T\'3',
  DT4: 'Ligne double T4',
  DC: 'Ligne double continue',
  USELESS: 'Indéterminé',
  PEDESTRIAN: 'Sigle piéton',
  FAMILY: 'Sigle famille',
  MOTHER_KID: 'Sigle mère',
  LIVRAISON: 'Sigle livraison',
  PAYANT: 'Sigle payant',
  AUTOELEC: 'Sigle auto-élect',
  LOGO30: 'Sigle logo 30',
  LOGO30EXT: 'Sigle logo 30 étendu',
  ZONE30: 'Sigle zone 30',
  STOPTEXT: 'Sigle STOP',
  PTRIANGLE: 'Sigle petit triangle',
  GTRIANGLE: 'Sigle grand triangle',
} as const;

@Pipe({
  name: 'elementNameGround'
})
export class ElementNameGroundPipe implements PipeTransform {

  transform(elementName: string = ""): string {
    return nameMapping[elementName?.toUpperCase()] ||elementName;
  }
}
