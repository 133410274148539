import { Injectable } from '@angular/core';
import { Options } from 'ng5-slider';
import { Store } from 'src/app/globals/store';
import { ElementClassModel } from 'src/app/models/masterData/element-class-model';

export class HeliosState{
  countSelected: number = 0;

  selectedNetworks: string[] = [];

  classGroups: string[] = [];
  classSelectedList: ElementClassModel[] = [];

  _isShowElement: boolean = false;
  _isVisible: boolean = true;
  isRelevant: boolean = true;

  _activeViewHelios: boolean = false;

  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 1;
  selectedYearHighValue: number = this.actualYear ;
  selectedTypeHeliosWorks: string = "";
  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };
}


@Injectable({
  providedIn: 'root'
})
export class HeliosStoreService extends Store<HeliosState> {

  constructor() {
    super(HeliosState)
  }
}
