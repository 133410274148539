<li class="listMenu"  *ngIf="session.hasRole('ROLE_MAP_BARRIER')"
    appWorkspaceRequired
    [ngClass]="{'itemChecked':barrierStore.state._isShowElement, 'itemUnchecked': !barrierStore.state._isShowElement}"
    (click)="openMenuBarrier()">
    <i class="fa ca-barrier"></i>
    <span>{{ 'barrierEntry.title' | translate }}</span><br />
    <a role="button" *ngIf="barrierStore.state._isShowElement"
       [ngClass]="{'itemChecked':barrierStore.state._isShowElement, 'itemUnchecked': !barrierStore.state._isShowElement}">
        <span (click)="closeBarrier()" class="pi pi-times ng-tns-c48-2">
        </span>
    </a>
</li>
