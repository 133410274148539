import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {BarrierEntryPanelComponent} from './barrier-entry-panel/barrier-entry-panel.component';
import {BarrierStoreService} from './barrier-store.service';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-barrier-entry',
  templateUrl: './barrier-entry.component.html',
  styleUrls: ['./barrier-entry.component.css']
})
export class BarrierEntryComponent implements OnInit {

  constructor(public session: Session, public barrierStore: BarrierStoreService, public panelService: PanelService) { }


  ngOnInit(): void {
  }

  openMenuBarrier(){
    this.panelService.push(
      BarrierEntryPanelComponent,
      null
    )
    Matomo.push(['trackEvent', 'Actions', 'Opened barrier menu'])
  }

  closeBarrier(){
    this.barrierStore.reset();
    Matomo.push(['trackEvent', 'Actions', 'Removed barrier elements'])
  }

}
