import {Injectable} from '@angular/core';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {Session} from 'src/app/globals/session';
import {ComputeLaneHelper} from 'src/app/helpers/compute-lane.helper';
import {FeatureCollectionModel} from 'src/app/models/geojson/feature-collection-model';
import {FeatureModel} from 'src/app/models/geojson/feature-model';
import {GeometryModel} from 'src/app/models/geojson/geometry-model';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MapService} from 'src/app/shared/map/map.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {LocationMarkerState, LocationMarkerStoreService} from './location-marker-store.service';

import * as turf from '@turf/turf';
import {circle} from '@turf/turf';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class LocationMarkerService {

  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }

  constructor(public session: Session,
    private mapService:MapService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private computeLaneHelper: ComputeLaneHelper,
    private locationMarkerStoreService: LocationMarkerStoreService,
    private geojsonService: GeojsonService) {
      this.locationMarkerStoreService.applyAndWatch(this.showLocationMarkerWrapper.bind(this));
    }

    showLocationMarkerWrapper(state: LocationMarkerState){
      this.mapService.callWhenAvailable(_=>this.showLocationMarker(state));
    }

    showLocationMarker(state: LocationMarkerState) {
      let tileUrl = environment.tilesPrServer;
      if(state.selectedWayTypes){
        tileUrl += "?prWayType=" + state.selectedWayTypes[0];
      }
      state.selectedWayTypes.slice(1).forEach(wayType => tileUrl += "&prWayType=" + wayType);
      if (state.isShowLocationMarker === true) {

        state.dataGraduations = new FeatureCollectionModel();
        state.dataGraduationTags = new FeatureCollectionModel();
        state.dataGraduations.features = [];
        state.dataGraduationTags.features = [];
        state.locationMarkerIdDisplay = [];
        let layoutIcon = {
          "icon-image": "borne-pr-{pr_way_type}",
          "icon-size": 0.45,
          "icon-rotate": ["get", "bearing"],
          "icon-rotation-alignment": "map",
          "icon-allow-overlap": true,
          "icon-ignore-placement": true
        };

        let layoutLabel = {
          "text-field": "{pr_way_name}\n{pr_code}",
          "text-font": [
            "DIN Offc Pro Medium",
            "Arial Unicode MS Bold"
          ],
          "text-size": 10
        };

        this.map.addSymbolLabelLayerTiles(MapLayerEnum.LAYER_LOCATION_MARKER, tileUrl, MapSourceEnum.SOURCE_LAYER_MARKER, MapSourceEnum.SOURCE_LOCATION_MARKER,false, layoutIcon, layoutLabel);

        this.map.map.on("click", MapLayerEnum.LAYER_LOCATION_MARKER, e => {
          state.countSelected++;
          if (state.countSelected === 1) {
            e.preventDefault();
            if (state.locationMarkerIdDisplay.includes(e.features[0].properties['pr_identifier'])) {
              state.locationMarkerIdDisplay.splice(state.locationMarkerIdDisplay.indexOf(e.features[0].properties['pr_identifier']), 1);
              state.dataGraduations.features = [];
              state.dataGraduationTags.features = [];
            } else {
              state.locationMarkerIdDisplay.push(e.features[0].properties['pr_identifier']);
            }
            if (state.locationMarkerIdDisplay.length > 0) {
              state.locationMarkerIdDisplay.forEach((prIdentifier: string) => {
                this.getGraduation(state, prIdentifier);
              });
            } else {
              state.countSelected = 0;
              this.map.removeLayer(MapLayerEnum.LAYER_SEGMENT_GRADUATION, MapSourceEnum.SOURCE_SEGMENT_GRADUATION);
              this.map.removeLayer(MapLayerEnum.LAYER_SEGMENT_GRADUATION_TEXT + 'Label', MapSourceEnum.SOURCE_SEGMENT_GRADUATION_TEXT);
            }
          }
        });
      } else {
        this.map.removeLayer(MapLayerEnum.LAYER_LOCATION_MARKER + 'Label');
        this.map.removeLayer(MapLayerEnum.LAYER_LOCATION_MARKER, MapSourceEnum.SOURCE_LOCATION_MARKER);
        this.map.removeLayer(MapLayerEnum.LAYER_SEGMENT_GRADUATION, MapSourceEnum.SOURCE_SEGMENT_GRADUATION);
        this.map.removeLayer(MapLayerEnum.LAYER_SEGMENT_GRADUATION_TEXT + 'Label', MapSourceEnum.SOURCE_SEGMENT_GRADUATION_TEXT);
        state.isPrError = false;
      }
      // Google Analytics
      if (environment.googleTracking) {
        gtag('event', 'MenuLocationMarker');
      }
    }

    getGraduation(state: LocationMarkerState, elementIdentifier: string) {
      state.countSelected = 0;
      // this.map.removeLayer(MapLayerEnum.LAYER_SEGMENT_LOCATION, MapSourceEnum.SOURCE_SEGMENT_LOCATION);
      this.geojsonService.getLocationMarkerDetail(elementIdentifier).subscribe(response => {
        if  (response !== undefined) {
          let prCode = response.properties['pr_code'];
          let prGeometry = response.geometry;
          let prIdentifier = response.properties['pr_identifier'];

          const radius = 0.5;
          const options = {steps: 11, units: 'kilometers', properties: {foo: 'bar'}};
          // @ts-ignore
          const prCircle = circle(response.geometry.coordinates, radius, options);
          prGeometry.coordinates = prCircle.geometry.coordinates;
          prGeometry.type = prCircle.geometry.type;

          this.geojsonService.getLocationMarkerSegment(elementIdentifier).subscribe(segmentResponse => {

            let distance = 10;

            if (segmentResponse.features.length > 0) {

              if (segmentResponse.features[0].geometry.coordinates) {
                let geometryToTurf :any = turf.helpers.geometry(segmentResponse.features[0].geometry.type, segmentResponse.features[0].geometry.coordinates);
                let bbox: any = turf.bbox(geometryToTurf);

                this.map.map.fitBounds(bbox, {padding: 70});
                this.map.removeLayer(MapLayerEnum.LAYER_SEGMENT_GRADUATION, MapSourceEnum.SOURCE_SEGMENT_GRADUATION);
                this.map.removeLayer(MapLayerEnum.LAYER_SEGMENT_GRADUATION_TEXT + 'Label', MapSourceEnum.SOURCE_SEGMENT_GRADUATION_TEXT);

                let segment = new GeometryModel();
                segment.coordinates = segmentResponse.features[0].geometry.coordinates;
                segment.type = segmentResponse.features[0].geometry.type;

                let graduationObject = this.computeLaneHelper.graduate(segment, distance);

                let graduations = graduationObject.graduations;


                graduations.forEach((graduation: GeometryModel) => {
                  let featureGraduation = new FeatureModel();
                  featureGraduation.geometry = graduation;
                  state.dataGraduations.features.push(featureGraduation);
                });

                let layout = {
                  'line-cap': 'round',
                  'line-join': 'round',
                };

                let paintGraduation = {
                  'line-color': '#29ff1e',
                  'line-width': 3,
                  'line-opacity': 0.5,
                };

                this.map.addLineLayer(MapLayerEnum.LAYER_SEGMENT_GRADUATION, MapSourceEnum.SOURCE_SEGMENT_GRADUATION, layout, paintGraduation, state.dataGraduations);

                let graduationTags = graduationObject.tags;


                graduationTags.forEach((tag: GeometryModel) => {
                  let featureTag = new FeatureModel();
                  featureTag.geometry = tag;
                  featureTag.properties = tag.properties;
                  featureTag.properties['pr_code'] = prCode;
                  featureTag.properties['pr_identifier'] = prIdentifier;
                  state.dataGraduationTags.features.push(featureTag);
                });

                this.geojsonService.getObjectAlertCollection(undefined, 'PR_ERROR', undefined,
                  undefined,undefined,undefined,undefined,undefined,
                  prGeometry, 500).subscribe(alertResponse => {

                  if  (alertResponse.features.length > 0) {
                    alertResponse.features.forEach(alert => {
                      // @ts-ignore
                      if (alert.properties.alertProperties['pr_identifier'] === elementIdentifier) {
                        state.isPrError = true;
                        state.dataGraduationTags.features.forEach( feature => {

                          if  (feature.properties['pr_identifier'] === elementIdentifier) {
                            // @ts-ignore
                            feature.properties['prError'] = Number(alert.properties.alertProperties['distanceError']);
                            feature.properties['correctGraduation'] = Number(feature.properties['currentDistance']) + feature.properties['prError']
                          } else {
                            // @ts-ignore
                            feature.properties['prError'] = 0;
                          }
                        });
                      }
                    });
                    let layoutLabel = {
                      "text-field": "PR{pr_code}\n+{currentDistance}\n({prError})",
                      "text-font": [
                        "DIN Offc Pro Medium",
                        "Arial Unicode MS Bold"
                      ],
                      "text-size": 10,
                    };
                    let paint = {
                      "text-halo-color": "#fff",
                      "text-halo-width": 2,
                    };
                    this.map.addLabelLayer(MapLayerEnum.LAYER_SEGMENT_GRADUATION_TEXT, MapSourceEnum.SOURCE_SEGMENT_GRADUATION_TEXT, layoutLabel, state.dataGraduationTags, paint);

                  } else if (state.isPrError) {
                    state.dataGraduationTags.features.forEach( feature => {
                      if (feature.properties['prError'] === undefined) {
                        feature.properties['prError'] = 0;
                      }
                    });
                      let layoutLabel = {
                      "text-field": "PR{pr_code}\n+{currentDistance}\n({prError})",
                      "text-font": [
                        "DIN Offc Pro Medium",
                        "Arial Unicode MS Bold"
                      ],
                      "text-size": 10,
                    };
                    let paint = {
                      "text-halo-color": "#fff",
                      "text-halo-width": 2
                    };
                    this.map.addLabelLayer(MapLayerEnum.LAYER_SEGMENT_GRADUATION_TEXT, MapSourceEnum.SOURCE_SEGMENT_GRADUATION_TEXT, layoutLabel, state.dataGraduationTags, paint);

                  } else {
                    let layoutLabel = {
                      "text-field": "PR{pr_code}\n+{currentDistance}",
                      "text-font": [
                        "DIN Offc Pro Medium",
                        "Arial Unicode MS Bold"
                      ],
                      "text-size": 10,
                    };
                    let paint = {
                      "text-halo-color": "#fff",
                      "text-halo-width": 2
                    };
                    this.map.addLabelLayer(MapLayerEnum.LAYER_SEGMENT_GRADUATION_TEXT, MapSourceEnum.SOURCE_SEGMENT_GRADUATION_TEXT, layoutLabel, state.dataGraduationTags, paint);

                  }

                });
              }
            } else {
              // state.messageService.add(MessageHelper.createMessage(MessageSeverityEnum.SEVERITY_ERROR, error));

            }

          });
        }

      });

    }

}
