import {Pipe, PipeTransform} from "@angular/core";
import {StringUtils} from "../../helpers/string.utils";

@Pipe({
  name:
    'elementAlertPipe'
})

export class ElementAlertPipe implements PipeTransform {

  transform(elementAlert: string): string {
    let elementAlertransform = '';

    if (elementAlert === 'ROADWORK') {
      elementAlertransform = 'Travaux'
    }
    if (elementAlert === 'PROPRETE') {
      elementAlertransform = 'Propreté'
    }
    if (elementAlert === 'ELAGAGE') {
      elementAlertransform = 'Elagage'
    }
    if (elementAlert === 'FAUCHAGE') {
      elementAlertransform = 'Fauchage'
    }
    if (elementAlert === 'MAINTENANCE') {
      elementAlertransform = 'Maintenance'
    }
    if (elementAlert === 'EVENEMENT_CULTUREL') {
      elementAlertransform = 'Evenement culturel'
    }
    if (elementAlert === 'RESEAUX') {
      elementAlertransform = 'Travaux sur réseaux'
    }
    if (elementAlert === 'LIMITATION') {
      elementAlertransform = 'Restriction'
    }
    if (elementAlert === 'BREAK') {
      elementAlertransform = 'Interruption'
    }
    if (elementAlert === 'WARNING') {
      elementAlertransform = 'Vigilance'
    }
    if (elementAlert === 'TONTEELAGAGE') {
      elementAlertransform = 'Tonte Elagage'
    }
    if (elementAlert === 'ELECTRIFICATION') {
      elementAlertransform = 'Electrification'
    }
    if (elementAlert === 'PURGE') {
      elementAlertransform = 'Purge'
    }
    if (elementAlert === 'BLOCAGEVOIRIE') {
      elementAlertransform = 'Blocage Voirie'
    }
    if (elementAlert === 'REDUCTIONVOIE') {
      elementAlertransform = 'Réduction Voie'
    }
    if (elementAlert === 'ACCIDENTS') {
      elementAlertransform = 'Accidents'
    }
    if (elementAlert === 'MANIFESTATION') {
      elementAlertransform = 'Manifestation'
    }
    if (elementAlert === 'GOVERNED') {
      elementAlertransform = 'Régie'
    }
    if (elementAlert === 'THIRD') {
      elementAlertransform = 'Tiers'
    }
    if (elementAlert === 'NO') {
      elementAlertransform = 'Aucun'
    }
    if (elementAlert === '30') {
      elementAlertransform = '30 km/h'
    }
    if (elementAlert === '50') {
      elementAlertransform = '50 km/h'
    }
    if (elementAlert === '70') {
      elementAlertransform = '70 km/h'
    }
    if (StringUtils.isEmpty(elementAlertransform)) {
      elementAlertransform = elementAlert;
    }
    return elementAlertransform;
  }
}
