import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from 'src/app/globals/store';

export class CustomBackgroundState{
  public isActivated: boolean = false;
  public selectedMapUrl:string = null;
}

@Injectable({
  providedIn: 'root'
})
export class CustomBackgroundStoreService extends Store<CustomBackgroundState>{
  constructor() {
    super(CustomBackgroundState)
  }

}
