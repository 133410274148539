import {RoadList} from "../linked-list/road-list";
import {RoadNode, linkTypeEnum, stateEnum} from "../linked-list/road-node";
import {GeometryHelper} from "./geometry.helper";
import {LineStringHelper} from "./linestring-helper";

import {GeometryModel} from "../models/geojson/geometry-model";
import {AxisEnum} from "../enums/axis-enum";
// import {idt} from "./tools";

export class GraphBasedToolsService {

  public static getStartPoints(graph: RoadList<GeometryModel>, way_identifier: string, axis: AxisEnum, useNextLinks: boolean = false): RoadNode<GeometryModel>[] {
    /**
     * useNextLinks as true considers PREV link status as a real prev.
     */
    let startPoints: RoadNode<GeometryModel>[] = [];

    function getStartPointsFromNode(node: RoadNode<GeometryModel>) {
      if (node.state == stateEnum.DONE)
        return;
      node.state = stateEnum.DONE;


      if ((node.prev.length == 0 || node.prev[0].dt.properties.way_identifiers.indexOf(way_identifier) == -1) && // no prev
        node.dt.properties.way_identifiers.indexOf(way_identifier) >= 0 ) { //good wayIdentifier
        startPoints.push(node);
      }

      for (let n of node.next)
        getStartPointsFromNode(n);
      for (let p of node.prev)
        getStartPointsFromNode(p);
      for (let l of node.links)
        getStartPointsFromNode(l[0]);
    }

    for (let head of graph.getSavedHeads())
      getStartPointsFromNode(head);
    return startPoints;
  }

  public static getMergedSegmentsFromStartPoints(startPoints: RoadNode<GeometryModel>[], way_identifier: string, axis: AxisEnum): GeometryModel[] {
    let mergedSegments: GeometryModel[] = [];

    for (let startPoint of startPoints)
      mergedSegments = mergedSegments.concat(GraphBasedToolsService.mergeNextNodes(startPoint, way_identifier, axis)); // FIXME use next links it not used in next functions
    return mergedSegments;
  }

  public static mergeNextNodes(startPoint: RoadNode<GeometryModel>, way_identifier: string, axis: AxisEnum): GeometryModel {
    let mergedSegment: GeometryModel = startPoint.dt.clone();
    mergedSegment.properties = {"nodes": mergedSegment.coordinates.map(() => startPoint)};

    for (let node: RoadNode<GeometryModel> = startPoint.next[0]; node && node.state != stateEnum.DONE && node.dt.properties.way_identifiers.indexOf(way_identifier) != -1; node = node.next[0]) {
      node.state = stateEnum.DONE;
      mergedSegment = LineStringHelper.mergeToLineString([mergedSegment, GraphBasedToolsService.adjustZ(mergedSegment, node.dt)], {"nodes": mergedSegment.properties.nodes.concat(node.dt.coordinates.map(() => node))})
    }
    return axis == AxisEnum.DSC ? LineStringHelper.reverseLineString(mergedSegment) : mergedSegment;
  }

  public static mergeNodesUsingLinks(lastPoint: RoadNode<GeometryModel>, way_identifier: string, reverseFirst: boolean = false): GeometryModel[] {
    /**
     * This force every segment to be in the same direction as the first one.
     * It Creates multiple segments since a segment can be linked to different others.
     */
    let selector: number;
    let currentSelector: number = 0;
    let otherWay: boolean = true;

    let reverseMe: boolean = reverseFirst;
    let mergedSegments: GeometryModel[] = [];

    function getNextRoadNode(node: RoadNode<GeometryModel>): RoadNode<GeometryModel> {
      /**
       * Because we start from last segment, we keep searching for the prev one.
       * That's why we do reverse segments before merging them.
       */
      let validLinks: [RoadNode<GeometryModel>, linkTypeEnum][] = node.links.filter(n =>
        (reverseMe && node.dt.properties.segment_axis == 'DIRECT_AXIS' && n[1] & linkTypeEnum.PREV) ||
        (reverseMe && node.dt.properties.segment_axis == 'DUAL_AXIS' && n[1] & linkTypeEnum.PREV && n[1] & linkTypeEnum.START) ||
        (!reverseMe && node.dt.properties.segment_axis == 'DUAL_AXIS' && n[1] & linkTypeEnum.PREV && n[1] & linkTypeEnum.END)
      );

      // if (debug)


      if ((node.prev[0] && reverseMe))
        return node.prev[0];

      if ((node.next[0] && !reverseMe && node.dt.properties.segment_axis == 'DUAL_AXIS'))
        return node.next[0];

      if (!validLinks.length)
        return undefined;

      if (validLinks[currentSelector]) {
        let tmpSelector: number = currentSelector;
        if (validLinks.length > currentSelector + 1)
          otherWay = true;
        currentSelector = 0;
        reverseMe = GraphBasedToolsService.isReversed(validLinks, tmpSelector) != reverseMe;

        return validLinks[tmpSelector][0]
      }

      currentSelector -= validLinks.length;
      reverseMe = GraphBasedToolsService.isReversed(validLinks, validLinks.length - 1) != reverseMe;
      return validLinks[validLinks.length - 1][0]
    }

    // let debug: boolean = false;
    // if (idt(lastPoint) == 'bbb1a414-857e-4bb5-ad1e-616748998d56')
    //     debug = true;

    for (selector = 0; otherWay; selector++, otherWay = false) {
      currentSelector = selector;
      let mergedSegment: GeometryModel = reverseMe ? LineStringHelper.reverseLineString(lastPoint.dt.clone()) : lastPoint.dt.clone();
      mergedSegment.properties = {"nodes": mergedSegment.coordinates.map(() => lastPoint)};

      for (let node: RoadNode<GeometryModel> = getNextRoadNode(lastPoint); node && node.state != stateEnum.DONE && node.dt.properties.way_identifiers.indexOf(way_identifier) != -1; node = getNextRoadNode(node)) {
        node.state = stateEnum.DONE;
        let segToMerge: GeometryModel = reverseMe ? LineStringHelper.reverseLineString(node.dt.clone()) : node.dt.clone();
        // if (debug)

        mergedSegment = LineStringHelper.mergeToLineString([mergedSegment, GraphBasedToolsService.adjustZ(mergedSegment, segToMerge)], {"nodes": mergedSegment.properties.nodes.concat(segToMerge.coordinates.map(() => node))})
      }
      // if (debug)

      mergedSegments.push(LineStringHelper.reverseLineString(mergedSegment)); // FIXME check reverse
      reverseMe = reverseFirst;
    }
    return mergedSegments;
  }

  private static isReversed(validLinks: [RoadNode<GeometryModel>, linkTypeEnum][], currentSelector: number): boolean {
    return !!((validLinks[currentSelector][1] & linkTypeEnum.END && validLinks[currentSelector][1] & linkTypeEnum.L_END) ||
      (validLinks[currentSelector][1] & linkTypeEnum.START && validLinks[currentSelector][1] & linkTypeEnum.L_START))
  }

  public static adjustZ(currentSegment: GeometryModel, nextSegment: GeometryModel) {
    if (currentSegment.coordinates[currentSegment.coordinates.length - 1][0] == nextSegment.coordinates[0][0] &&
      currentSegment.coordinates[currentSegment.coordinates.length - 1][1] == nextSegment.coordinates[0][1]) {
      let zDiff: number = currentSegment.coordinates[currentSegment.coordinates.length - 1][2] - nextSegment.coordinates[0][2];
      nextSegment.coordinates.forEach(value => value[2] += zDiff);
    }
    return nextSegment;
  }

  public static wayLength(fullSegments: GeometryModel[]) {
    return GeometryHelper.getSumLength(fullSegments) / 1000
  }
}
