import { Injectable     } from '@angular/core'          ;
import { Store          } from 'src/app/globals/store'  ;
import { Subscription   } from "rxjs"                   ;

interface RoadType { name: string, code: string }

export class RoadFlowState {

    _isShowElement        : boolean = false     ;
    _isVisible            : boolean = true      ;
    isPrimary             : boolean = true      ;

    roadTypes             : string[] = ["highways", "primary", "secondary", "tertiary", "residential", "unclassified"]      ;
    quantiles             : number[] = [7,6,5,4,3,2,1,0]                                                                    ;
    metrics               : string[] = ["aadt_obs", "aadt_est_v0", "aadt_est_v1", "aadt_est_v2", "aadt_est_v3"]             ;
    cutoffs               : any = {'0': 0, '1': 1, '2': 500, '3': 2000, '4': 4000, '5': 15000, '6': 30000, '7': 60000}      ;

    selectedCutoffs       : any = {}            ;
    selectedQuantiles     : number[] = []       ;
    selectedRoadTypes     : string[] = []       ;
    selectedMetric        : string = "aadt_obs" ;

    subscriptions         : Subscription[] = [] ;

    callUrl               : string              ;
}

@Injectable({
  providedIn: 'root'
})

export class RoadFlowStoreService extends Store<RoadFlowState> {
  constructor() {
    super(RoadFlowState)
  }
}
