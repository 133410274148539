import {Injectable} from '@angular/core';
import {Options} from 'ng5-slider';
import {Store} from 'src/app/globals/store';
import {ElementClassModel} from 'src/app/models/masterData/element-class-model';

export class BankState {

  elementType: string = 'BANK';
  selectedNetworks: string[] = [];

  countSelected: number = 0;

  productColor: string[] = [];
  productAxis: string[] = [];
  productType: string[] = [];

  classGroups: string[] = [];
  classSelected: { [key: string]: ElementClassModel } = {};

  isRelevant: boolean = true;
  isPrimary: boolean = true;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  selectedMinQuality: number = 0;
  selectedHighQuality: number = 100;

  qualitySliderOptions: Options = {
    floor: 0,
    ceil: 100,
    showOuterSelectionBars: true
  };
  selectedElement: string;

  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear;

  arrayLaneContinue: string[];
  arrayLaneDotted: string[];

  excludeCategories: string[] = [];
  excludeElementNames: string[] = [];

  onCreate: boolean = false;
  callUrl: string;
}


@Injectable({
  providedIn: 'root'
})
export class BankStoreService extends Store<BankState> {

  constructor() {
    super(BankState)
  }
}
