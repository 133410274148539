import { Directive, ElementRef, HostBinding, HostListener, OnInit } from '@angular/core';
import { Session } from './session';

@Directive({
  selector: '[appWorkspaceRequired]'
})
export class WorkspaceRequiredDirective implements OnInit {

  constructor(private session: Session,
              private elRef: ElementRef) {

  }
  ngOnInit(): void {
    this.session.watchWorkspace(this.checkWorkspace.bind(this))
    this.checkWorkspace(this.session._workspaceGeo);
    this.elRef.nativeElement
  }

  @HostBinding('style.pointer-events')
  pointerEvents:string = 'none';


  @HostBinding('class.itemDisabled')
  private disabled = false;
  protected _elementClass: string[] = [];

  checkWorkspace(workspace){
    if(workspace){
      this.disabled = false;
      this.pointerEvents = 'auto';
    } else {
      this.disabled = true;
      this.pointerEvents = 'none';
    }
  }

}
