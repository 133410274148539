import {ISerializable} from '../iserializable';
import {CaptureModel} from "./capture-model";
import {ViewModel} from "./view-model";
import {ElementModel} from "./element-model";

export class CapturePointResponseModel implements ISerializable {

  public capture: CaptureModel;
  public views: Array<ViewModel> = [];
  public elements:Array<ElementModel> = [];
  public storageUrl:String;

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.capture != undefined) {
      this.capture = new CaptureModel().deserialize(input.capture);
    }
    if (input.views != undefined) {
      this.views = input.views.map(view => new ViewModel().deserialize(view));
    }
    if (input.elements != undefined) {
      this.elements = input.elements.map(element => new ElementModel().deserialize(element));
    }

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
