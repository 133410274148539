import { Pipe, PipeTransform } from '@angular/core';
import {StringUtils} from "../../helpers/string.utils";

@Pipe({
  name: 'elementGroupLane'
})
export class ElementGroupLanePipe implements PipeTransform {

  transform(classGroup: string = ""): string {
    let elementGroundNameTransfrom = '';

    classGroup = classGroup.toUpperCase();

    if(classGroup === 'LANE_DASHED'){
      elementGroundNameTransfrom = 'Lignes discontinues'
    }
    if(classGroup === 'LANE_FULL'){
      elementGroundNameTransfrom = 'Lignes continues'
    }

    if (StringUtils.isEmpty(elementGroundNameTransfrom)) {
      elementGroundNameTransfrom = classGroup;
    }

    return elementGroundNameTransfrom;
  }

}
