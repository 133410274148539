import {ISerializable} from "../iserializable";

export class ElementModel implements ISerializable {

  public featureType: string;

  public viewBbox: object;

  public elementOrder: number;
  public elementQuality: number;
  public elementIdentifier: string;
  public elementCategory: string;
  public elementClass: string;
  public elementType: string;
  public elementName: string;
  public elementTimestamp: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
