export enum EventCodeEnum {
  WORKSPACE_CHANGE = 'WORKSPACE_CHANGE',
  MAP_CLICK = 'MAP_CLICK',
  MAP_LAYER_CLICK = 'MAP_LAYER_CLICK',
  ELEMENTS_GROUND_LOADED = 'ELEMENTS_GROUND_LOADED',
  ELEMENTS_LANE_LOADED = 'ELEMENTS_LANE_LOADED',
  ELEMENTS_BANK_LOADED = 'ELEMENTS_BANK_LOADED',
  ELEMENTS_SIGN_LOADED = 'ELEMENTS_SIGN_LOADED',
  ELEMENTS_RIVER_SIGN_LOADED = 'ELEMENTS_RIVER_SIGN_LOADED',
  ELEMENTS_FURNITURE_LOADED = 'ELEMENTS_FURNITURE_LOADED',
  ELEMENTS_BARRIER_LOADED = 'ELEMENTS_BARRIER_LOADED',
  ELEMENTS_QUALITY_LOADED = 'ELEMENTS_QUALITY_LOADED',
  ELEMENTS_HELIOS_LOADED = 'ELEMENTS_HELIOS_LOADED',
  ELEMENTS_ALERT = 'ELEMENTS_ALERT',
  ELEMENTS_LIGHT_LOADED = 'ELEMENTS_LIGHT_LOADED',
  JOURNEY_LOADED = 'JOURNEY_LOADED',
  CMD_SHOW_SUMMARY = 'CMD_SHOW_SUMMARY',
  CMD_SHOW_LIST = 'CMD_SHOW_LIST',
  LOGOUT = 'LOGOUT',
  QUALITY_RED= 'QUALITY_RED',
  QUALITY_ORANGE ='QUALITY_ORANGE',
  QUALITY_GREEN='QUALITY_GREEN',
  QUALITY_FULL='QUALITY_FULL'
}
