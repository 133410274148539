import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  HostBinding,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {PanelEvent, PanelService} from '../../panel.service';
import {PanelBase} from '../PanelBase';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.css'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        opacity: 1,
        transform: 'translateX(0%)'
      })),
      state('closed', style({
        opacity: 0,
        transform: 'translateX(100%)',
        width: 0
      })),
      transition('open => closed', [
        animate('0.3s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
    ]),
  ],
})
export class RightPanelComponent implements OnInit {

  @ViewChild("panelContent", {read: ViewContainerRef}) panelContent !: ViewContainerRef;
  @HostBinding('@openClose') get openClose() {
    return this.stack.length ? 'open' : 'closed';
  }
  stack : ComponentRef<any>[] = [];

  // @HostBinding('style.display') display = "none";

  constructor(
    private panelService: PanelService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngOnInit(): void {
    this.panelService.subjectRight.subscribe(this.handleEvent.bind(this));
  }

  handleEvent(event: PanelEvent){
    switch(event.type){
      case "CLOSE":
        this.close();
        break;
      case "POP":
        this.pop();
        break;
      case "PUSH":
        this.push(event.data.component, event.data.componentData);
        break;
      case "OPEN":
        this.open(event.data.component, event.data.componentData);
        break;
    }
    // if(this.stack.length){
    //   this.display = "initial";
    // } else {
    //   this.display = "none";
    // }
  }

  push<T,C extends PanelBase<T> & (new ()=>any)>(component: C, data: T){
    if(this.stack.length){
      (this.stack[this.stack.length - 1].location.nativeElement as HTMLElement).style.display = "none";
    }
    const dynamicComponentFactory = this.componentFactoryResolver.resolveComponentFactory(component.prototype.constructor);
    const componentRef = this.panelContent.createComponent<C>(dynamicComponentFactory as any);
    componentRef.instance.data = data;
    this.stack.push(componentRef);
  }

  open<T,C extends PanelBase<T> & (new ()=>any)>(component: C, data: T){
    this.close();
    this.push(component,data)
  }

  pop(){
    if(this.stack.length){
      const ref = this.stack.pop();
      Matomo.push(['trackEvent', 'Actions', 'Closed '+ref.componentType.name+' right menu'])
      ref.destroy();
      if(this.stack.length){
        (this.stack[this.stack.length - 1].location.nativeElement as HTMLElement).style.display = "";
      }
    }
  }

  close(){
    this.stack.forEach(ref=>ref.destroy());
    this.stack = [];
  }

}
