<div class="ui-g sticky" style="padding-right: 10px;">
  <div class="ui-g-1 titleButton">
    <i class="fa fa-arrow-left" (click)="show(false)"></i>
  </div>
  <div class="ui-g-10">
    <h1>{{ 'locationMarkerEntry.title' | translate }}</h1>
  </div>
  <div class="ui-g-1 titleButton">
    <i class="fa fa-eraser" [ngClass]="{'itemUnchecked':isShowLocationMarker, 'itemDisabled': !isShowLocationMarker}"
       (click)="close($event)"></i>
  </div>
</div>

<p-dataView [value]="wayTypes" layout="grid">
  <p-header class="headerDataView">{{ 'locationMarkerEntry.wayType' | translate }}</p-header>
  <ng-template let-wayType pTemplate="gridItem">
    <div  class="ui-g-6">
      <p-panel [header]="wayType.name" [style]="{'text-align':'center', 'font-size':'10px'}">
        <p-checkbox name="groupcar" [value]="wayType.letter" [ngModel]="selectedWayTypes" (onChange)="updateWayTypes(wayType.letter)"></p-checkbox>
      </p-panel>
    </div>
  </ng-template>
</p-dataView>
