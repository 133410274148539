    <div class="ui-g sticky" style="padding-right: 10px;">
      <div class="ui-g-1 titleButton">
        <i class="fa fa-arrow-left" (click)="show(false)"></i>
      </div>
      <div class="ui-g-10">
        <h1>{{ 'visibilityEntry.title' | translate }}</h1>
      </div>
      <div class="ui-g-1 titleButton">
        <i class="fa fa-eraser"[ngClass]="{'itemUnchecked':_isShowElement, 'itemDisabled': !_isShowElement}" (click)="clear()"></i>
      </div>
    </div>

    <div class="title">{{ 'visibilityEntry.profile' | translate }}</div>
    <div class="ui-g a" (click)="showElement('SPEEDLIMIT')">
      <img src="assets/images/speedlimit00.png"
           style="width:35px;display:inline-block;margin:5px 0 2px 5px"/>
      <div style="font-size:14px;float:right;margin:10px 20px 0 5px"
           [ngClass]="{'itemChecked':(_curveProfile === 'SPEEDLIMIT'), 'itemUnchecked': !(_curveProfile === 'SPEEDLIMIT')}">Limite autorisée</div>
    </div>

    <div class="ui-g a" (click)="showElement('SPEEDLIMIT%2B10')">
      <img src="assets/images/speedlimit10.png"
           style="width:35px;display:inline-block;margin:5px 0 2px 5px"/>
      <div style="font-size:14px;float:right;margin:10px 20px 0 5px"
           [ngClass]="{'itemChecked':(_curveProfile === 'SPEEDLIMIT%2B10'), 'itemUnchecked': !(_curveProfile === 'SPEEDLIMIT%2B10')}">Limite autorisée plus 10 km/h</div>
    </div>
