import {ElementRef, Pipe, PipeTransform, ViewChild} from '@angular/core';
import { ElementClassModel } from 'src/app/models/masterData/element-class-model';

@Pipe({
  name: 'ElementFilePathToClass',
})
export class ElementFilePathToClassPipe implements PipeTransform {
  transform(elementClass: ElementClassModel, elementClassDiv: HTMLDivElement): string {
    let className = `${(elementClass.elementClass as any).replaceAll('.','-').replaceAll('_','-')} ct-icon`;
    elementClassDiv.className = className;
    const style = getComputedStyle(elementClassDiv);
    if(style.backgroundPosition == '0% 0%') {
      className = "no-picto ct-icon";
    }
    elementClassDiv.className = "";
    return className;
  }

}
