import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {FurnitureEntryPanelComponent} from './furniture-entry-panel/furniture-entry-panel.component';
import {FurnitureStoreService} from './furniture-store.service';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-furniture-entry',
  templateUrl: './furniture-entry.component.html',
  styleUrls: ['./furniture-entry.component.css']
})
export class FurnitureEntryComponent implements OnInit {

  constructor(public session: Session, public panelService: PanelService, public furnitureStore: FurnitureStoreService) { }

  ngOnInit(): void {
  }

  openMenuFurniture(){
    this.panelService.push(
      FurnitureEntryPanelComponent,
      null
    )
    Matomo.push(['trackEvent', 'Actions', 'Opened furniture menu'])
  }

  closeFurniture(){
    this.furnitureStore.reset();
    Matomo.push(['trackEvent', 'Actions', 'Removed furniture'])
  }

}
