<div class="ui-g sticky" style="padding-right: 10px;">
    <div class="ui-g-1 titleButton">
    <i class="fa fa-arrow-left" (click)="show(false)"></i>
    </div>
    <div class="ui-g-11">
    <h1>{{ 'heliosEntry.title' | translate }}</h1>
    </div>
</div>
<div class="imageContainer">
    <p-galleria [value]="images" panelWidth="300" panelHeight="200">
      <ng-template pTemplate="item" let-item>
        <img [src]="item.src" [alt]="item.alt" />
      </ng-template>
    </p-galleria>
</div>
<div >
    <ul class="subMenu">
    <li class="listMenuElement ui-g" >
        <span class="signTitle ui-g-7">{{elmProperties.elementName}} ({{elmProperties.elementCategory}})</span>
        <img class="compareImageSign ui-g-2" alt="sign icon" [src]="elementIcon" />
        <div class="ui-g-3">
        <div [className]="highQuality ? 'green' : 'notSelected' "></div>
        <div [className]="mediumQuality ? 'orange' : 'notSelected' "></div>
        <div [className]="lowQuality ? 'red' : 'notSelected' "></div>
        </div>
    </li>
    </ul>
    <ul class="subMenu">
    <li class="listMenuElement" >
        <p class="listMenuElementTitle">{{ 'heliosEntry.heliosViewPanel.quality' | translate }}</p>
        <p>{{elmProperties.elementQuality | elementQualityPipe}} ({{elmProperties.elementQuality}})</p>
    </li>
    </ul>
</div>
<p-accordion [multiple]="true">
    <p-accordionTab header="{{ 'common.folder' | translate }}" >
    <div>
        <ul class="subMenu">
        <li class="listMenuElement" >
            <p class="listMenuElementTitle">{{ 'common.date' | translate }}</p>
            <p>{{elmProperties.elementTimestamp | date:'dd-MM-y'}}</p>
        </li>
        </ul>
        <ul class="subMenu" *ngIf="elmProperties.elementProperties">
        <li class="listMenuElement" >
            <p class="listMenuElementTitle">{{ 'common.folderNumber' | translate }}</p>
            <p>{{elmProperties.elementProperties["numDossier"]}}</p>
        </li>
        </ul>
        <ul class="subMenu" *ngIf="elmProperties.elementProperties">
        <li class="listMenuElement" >
            <p class="listMenuElementTitle">{{ 'common.comment' | translate }}</p>
            <p>{{elmProperties.elementProperties["commentaire"]}}</p>
        </li>
        </ul>
        <ul class="subMenu">
        <li class="listMenuElement" *ngFor="let sign of elementSign; let i= index">
            <p class="listMenuElementTitle">{{ 'heliosEntry.heliosViewPanel.symbol' | translate }}</p>
            <p>{{sign.signSymbol}}</p>
            <p class="listMenuElementTitle">{{ 'heliosEntry.heliosViewPanel.class' | translate }}</p>
            <p>{{sign.signClasse}}</p>
            <p class="listMenuElementTitle">{{ 'heliosEntry.heliosViewPanel.text' | translate }}</p>
            <p>{{sign.signText}}</p>
            <p class="listMenuElementTitle">{{ 'heliosEntry.heliosViewPanel.comment' | translate }}</p>
            <p>{{sign.signComment}}</p>
        </li>
        </ul>
    </div>
    </p-accordionTab>
    <p-accordionTab header="{{ 'common.details' | translate }}">
    <div>
        <ul class="subMenu">
        <li class="listMenuElement" >
            <p class="listMenuElementTitle">{{ 'common.elementClass' | translate }}</p>
            <p>{{elmProperties.elementClass}}</p>
        </li>
        </ul>
        <ul class="subMenu">
        <li class="listMenuElement" >
            <p class="listMenuElementTitle">{{ 'common.elementIdentifier' | translate }}</p>
            <p>{{elmProperties.elementIdentifier}}</p>
        </li>
        </ul>
    </div>
    </p-accordionTab>
</p-accordion>
