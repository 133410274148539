import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {MapService} from 'src/app/shared/map/map.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {environment} from 'src/environments/environment';
import {RoughnessState, RoughnessStoreService} from './roughness-store.service';


declare let gtag: Function;



@Injectable({
  providedIn: 'root'
})
export class RoughnessService {

  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }


  constructor(
    private mapService: MapService,
    private spinnerService: SpinnerService,
    private roughnessStore: RoughnessStoreService
  ) {
    this.roughnessStore.applyAndWatch(this.searchWrapper.bind(this))
  }

  private searchWrapper(state: RoughnessState){
    this.mapService.callWhenAvailable(_=>this.search(state));
  }

  private search(state: RoughnessState){
    if (state.activated === true) {
      let layout = {
        'line-cap': 'round',
        'line-join': 'round'
      };

      this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_ROUGHNESS,
                                      this.buildTileUrl(state),
                                      MapSourceEnum.SOURCE_LAYER_ROUGHNESS,
                                      MapSourceEnum.SOURCE_LAYER_ROUGHNESS,
                                      false,
                                      layout,
                                      this.buildPaintStyle(state));

    } else {
      this.map.removeLayer(MapLayerEnum.LAYER_ROUGHNESS, MapSourceEnum.SOURCE_LAYER_ROUGHNESS);
    }
    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'showRoughness');
    }
  }

  private buildTileUrl(state: RoughnessState){
    let params = new HttpParams()
    params = params.set('sampleMinYear',String(state.minPeriod));
    params = params.set('sampleMaxYear',String(state.maxPeriod));
    const tileUrl = `${environment.tilesRoughnessServer}?${params.toString()}`;
    return tileUrl;
  }

  private buildPaintStyle(state: RoughnessState){
    const green = ['<', ['get', 'iri'], state.maxGoodQuality];
    const orange = ['all', ['>=', ['get', 'iri'], state.maxGoodQuality], ['<', ['get', 'iri'], state.minWorstQuality]];
    const red = ['>=', ['get', 'iri'], state.minWorstQuality];

    let paint = {
      'line-color': [
        'case',
        red,
        '#dd1e37',
        orange,
        '#dd8d0a',
        green,
        '#21dd15',
        '#21dd15'
      ],
      'line-width': 2.1,
      'line-opacity': .9,
      'line-offset' : [
        'interpolate',
        ['exponential', 0.5],
        ['zoom'],
        15, 3,
        22, 18]
    };
    return paint;
  }
}
