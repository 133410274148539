import { Injectable } from '@angular/core';
import { MapComponent } from 'src/app/components/map/map.component';
import { GeometryTypeEnum } from 'src/app/enums/geometry-type-enum.enum';
import { MapLayerEnum } from 'src/app/enums/map-layer-enum.enum';
import { MapSourceEnum } from 'src/app/enums/map-source-enum.enum';
import { Session } from 'src/app/globals/session';
import { GeometryModel } from 'src/app/models/geojson/geometry-model';
import { WmsService } from 'src/app/services/wms.service';
import { MapService } from 'src/app/shared/map/map.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import {environment} from 'src/environments/environment';
import { CustomBackgroundState, CustomBackgroundStoreService } from './custom-background-store.service';

@Injectable({
  providedIn: 'root'
})
export class CustomBackgroundService {

  constructor(
            public session: Session,
              private mapService: MapService,
              private wmsService: WmsService,
              private spinnerService: SpinnerService,
              private customBackgroundStore: CustomBackgroundStoreService) {
    this.customBackgroundStore.watch(this.setCustomBackground.bind(this))
  }

  setCurrentUrl(mapUrl:string){
    if(mapUrl){
      this.customBackgroundStore.state = {
        selectedMapUrl: mapUrl,
        isActivated: true
      }
    } else {
      this.customBackgroundStore.state = {
        selectedMapUrl: null,
        isActivated: false
      }
    }
  }

  getCurrentUrl(){
    return this.customBackgroundStore.state;
  }

  setCustomBackground(customBackgroundState: CustomBackgroundState) {
    if(customBackgroundState.isActivated){
      let mapUrl = customBackgroundState.selectedMapUrl;
      this.spinnerService.getSpinner().addOperation("MainMenuComponent.loadCustomBackground");
      // this.mapService.getMap().makeInactiveForAnimation();
      this.mapService.getMap().showBackground(true);
      this.mapService.getMap().addRasterWmtsLayer(MapLayerEnum.LAYER_CUSTOM_URL, MapSourceEnum.SOURCE_CUSTOM_URL, mapUrl);
      //this.mapService.getMap().addRasterLayer(MapLayerEnum.LAYER_SATELLITE, MapSourceEnum.SOURCE_SATELLITE, environment.mapStyleSatellite);
      //this.mapService.getMap().moveLayerFirst(MapLayerEnum.LAYER_WORKSPACE + "line");
      this.mapService.getMap().moveLayerFirst(MapLayerEnum.LAYER_CUSTOM_URL);
      this.mapService.getMap().map.moveLayer(MapLayerEnum.LAYER_WORKSPACE, this.mapService.getMap().map.getStyle().layers[0].id);
      this.mapService.getMap().map.moveLayer(MapLayerEnum.LAYER_WORKSPACE + "line", this.mapService.getMap().map.getStyle().layers[0].id);
      this.mapService.getMap().map.setPaintProperty(MapLayerEnum.LAYER_WORKSPACE + "line", "line-color", MapComponent.getLineColorForWorkspace(this.mapService.map.map))
      this.mapService.getMap().map.setPaintProperty(MapLayerEnum.LAYER_WORKSPACE, "fill-color", "lightgrey");

      // this.spinnerService.getSpinner().removeOperation("MainMenuComponent.loadCustomBackground")
      if(!mapUrl.startsWith('https://geoserver.citymagine.com')) {
        //Move the map to the wms layer center
        this.wmsService
            .getBoundingBoxOfService(mapUrl)
            .subscribe(response=>{
              this.mapService.getMap().map.fitBounds(response.bounds,{padding: 20}) &&
              this.session.setWorkspace(new GeometryModel(GeometryTypeEnum.POLYGON,response.bbox))
              this.spinnerService.getSpinner().removeOperation("MainMenuComponent.loadCustomBackground")
            }
        )
      } else {
        let alHoceimaGeo = new GeometryModel();
        alHoceimaGeo.type = "Polygon";
        alHoceimaGeo.coordinates = [
          [
            [
              -3.9637853048766374,
              35.27102543372402
            ],
            [
              -3.8920720118447605,
              35.27102543372402
            ],
            [
              -3.8920720118447605,
              35.19940912021568
            ],
            [
              -3.9637853048766374,
              35.19929664302077
            ],
            [
              -3.9637853048766374,
              35.27102543372402
            ]
          ]
        ];
        this.session.setWorkspace(alHoceimaGeo);
        this.spinnerService.getSpinner().removeOperation("MainMenuComponent.loadCustomBackground")
      }
    } else if (this.mapService.getMap() != undefined){
        this.mapService.getMap().removeLayer(MapLayerEnum.LAYER_CUSTOM_URL, MapSourceEnum.SOURCE_CUSTOM_URL);
        this.mapService.getMap().showBackground(true);
    }
  }

}
