export class ArrayHelper {

    static deepIndexOf(arr: any[], obj: any) {
        return arr.findIndex(function (cur) {
            return Object.keys(obj).every(function (key) {
                return obj[key] === cur[key];
            });
        });
    }

  static isIn(c1: number[][], c2: number[][]): boolean {
    if (!c1 || !c2 || c1.some(e => !e) || c2.some(e => !e)) {


      return false;
    }
    return c1.every(elem => c2.map(x => x[0]).includes(elem[0]) && c2.map(x => x[1]).includes(elem[1]))
  }

    static dropDuplicates(arr: any[]): any[] {
        return arr.filter((thing, index) => {
            const _thing = JSON.stringify(thing);
            return index === arr.findIndex(obj => {
                return JSON.stringify(obj) === _thing;
            });
        });
    }

    static getDuplicatesIndexes(arr: any[]): any[] {
        return arr.map((thing, index) => {
            const _thing = JSON.stringify(thing);
            return index === arr.findIndex(obj => {
                return JSON.stringify(obj) === _thing;
            }) ? -1 : index;
        }).filter((value) => value != -1);
    }

    static dropDuplicatesStd(arr: any[]): any[] {
        return arr.filter((thing, index) => {
            return index === arr.findIndex(obj => {
                return obj === thing;
            });
        });
    }

    static countItem(arr: any[], item: any) {
        var count = 0;
        for (var i = 0; i < arr.length; ++i) {
            if (arr[i] == item)
                count++;
        }
        return count;
    }

    static max(array: number[]) {
        return Math.max.apply(null, array);
    }

    static min(array: number[]) {
        return Math.min.apply(null, array);
    }

    static dup(array: any[]): any[] {
        return JSON.parse(JSON.stringify(array))
    }

  static equals(arr: number[], arr2: number[]) {
    return (
      arr.length === arr2.length &&
      arr.every((value, index) => value === arr2[index])
    );
  };
}
