<div class="ui-g sticky" style="padding: 10px;">
  <div class="ui-g-offset-1 ui-g-10">
    <h1 style="text-align: right">{{ 'statistic.title' | translate }}</h1>
  </div>
  <div class="ui-g-1 titleButton" align="right">
    <i class="fa fa-arrow-right" (click)="close()" ></i>
  </div>
  <div *ngIf="_userHasGrid" style="margin-top: 10px;">
    <span>{{ 'statistic.pricingGrid' | translate }} : </span>
    <p-dropdown  [options]="pricingGrids" placeholder="- {{ 'statistic.listHeader' | translate }} -" [(ngModel)]="selectedGrid"
                 (onChange)="onChangeCode(selectedGrid ? selectedGrid.code : undefined)" [showClear]="true"></p-dropdown>
  </div>
</div>

<app-list-element [_userHasGrid]="_userHasGrid" [_gridCurrency]="_gridCurrency" [objectFeatures]="objectArrayStatisticFeatures" style="padding-bottom: 53px;" *ngIf="gridInitiated"></app-list-element>

<div class="exportButtonDiv">

  <p-button label="{{ 'statistic.refresh' | translate }}" [disabled]="_isRefreshed" icon="fa fa-refresh" (onClick)="getStatisticByGridCode(selectedGrid?.code)" class="refreshButton" ></p-button>

  <p-splitButton [disabled]="_waitingExport" label="{{ 'statistic.export' | translate }} EXCEL" (onClick)="exportFeatures('EXCEL')" [model]="exportItems" class="exportButton"></p-splitButton>
</div>
