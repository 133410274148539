import { Injectable, Component, OnInit  } from '@angular/core';
import { TranslateService               } from "@ngx-translate/core";

import { Subscription                   } from "rxjs";
import { PanelBase                      } from "../../../../../shared/panel/component/PanelBase";
import { Session                        } from "../../../../../globals/session";
import { ProcessingService              } from "../../../../../services/processing.service";
import { GeojsonService                 } from "../../../../../services/geojson.service";
import { SpinnerService                 } from "../../../../../shared/spinner/spinner.service";
import { MapService                     } from "../../../../../shared/map/map.service";
import { ToastService                   } from "../../../../../shared/toast/toast.service";
import { PanelService                   } from "../../../../../shared/panel/panel.service";
import { RoadFlowStoreService           } from "../road-flow-store.service";
import { RoadFlowService                } from "../road-flow.service";

interface RoadType      { name : string, code: string }
interface Metric  { name : string, code: string }
interface Cutoffs       { '0': number, '1': number, '2': number, '3': number, '4': number, '5': number, '6': number, '7': number }
interface Timings       { 't0': number, 't1': number, 'delta_t': number }
interface Analytics     {
      n_quantiles       : any, f_quantiles     : any
    , n_km_quantiles    : any, f_km_quantiles  : any 
    , n_roadTypes       : any, f_roadTypes     : any
    , n_km_roadTypes    : any, f_km_roadTypes  : any 
}


@Component({
    selector          : 'app-road-flow-entry-panel'
  , templateUrl       : './road-flow-entry-panel.component.html'
  , styleUrls         : ['./road-flow-entry-panel.component.css']
})

@Injectable({ providedIn: 'root' })

export class RoadFlowEntryPanelComponent implements OnInit, PanelBase<any> {
    /*
    * COMPONENT / PROPERTIES _______________________________
    */
    data                        : any                   ;

    subscriptions               : Subscription[] = []   ;

    _isShowElement              : boolean               ;
    _isVisible                  : boolean   = true      ;

    roadTypeOptions             : RoadType[]            ;
    selectMetricOptions   : Metric[]        ;
    quantiles                   : number[]  = []        ;
    roadTypes                   : string[]  = []        ;

    timings                     : Timings               ;
    analytics                   : Analytics             ;

    selectedRoadTypes           : string[]              ;
    selectedQuantiles           : number[]  = [7,6,5,4,3,2,1,0];
    selectedCutoffs             : Cutoffs               ;
    selectedMetric              : string    = "aadt_osb";
    selectedInput               : number    = 0         ;

    /*
     * COMPONENT / HELPERS _____________________________________
     */
    get spinner()   { return this.spinnerService.getSpinner(); }
    get map()       { return this.mapService.getMap(); }
    get toast()     { return this.toastService.getToast(); }

    isActiveQuantile(q){ 
        return this.selectedQuantiles.includes(q) || this.selectedQuantiles.length == 0 
    }

    isActiveRoadType(roadTypeCode){ 
        return this.selectedRoadTypes.includes(roadTypeCode) || this.selectedRoadTypes.length == 0 
    }

    show(show: boolean = true) {
        this._isVisible = show;
        if (!show) {
            this.panelService.pop();
        }
    }

    refresh() {
        this.roadFlowStore.refresh();
    }

    close(event: Event) {
        this.roadFlowStore.reset();
        this.show(false);
    }

    updateAnalytics(){
        let that            = this                                  ;
        let map         :any= this.mapService.getMap().map          ;
        let data        :any= map.querySourceFeatures('flow_geo', {sourceLayer: 'flow_geo'});
        this.resetAnalytics()
        let cutoffs     :any= this.roadFlowStore.state.selectedCutoffs;
        for(let i = 0 ; i < data.length ; i++){
            let p: any      = data[i].properties    ;
            let r: string   = p.road_type           ;
            let f: number   = p.aadt                ;
            let km: number  = p.length_in_m / 1000  ;
            if(this.roadTypes.includes(r)){ 
                this.analytics.n_roadTypes[   `${r}`] += 1
                this.analytics.n_km_roadTypes[`${r}`] += km
            }
                   if(f > cutoffs['7']){ this.analytics.n_quantiles['7'] += 1; this.analytics.n_km_quantiles['7'] += km
            } else if(f > cutoffs['6']){ this.analytics.n_quantiles['6'] += 1; this.analytics.n_km_quantiles['6'] += km
            } else if(f > cutoffs['5']){ this.analytics.n_quantiles['5'] += 1; this.analytics.n_km_quantiles['5'] += km
            } else if(f > cutoffs['4']){ this.analytics.n_quantiles['4'] += 1; this.analytics.n_km_quantiles['4'] += km
            } else if(f > cutoffs['3']){ this.analytics.n_quantiles['3'] += 1; this.analytics.n_km_quantiles['3'] += km
            } else if(f > cutoffs['2']){ this.analytics.n_quantiles['2'] += 1; this.analytics.n_km_quantiles['2'] += km
            } else if(f > cutoffs['1']){ this.analytics.n_quantiles['1'] += 1; this.analytics.n_km_quantiles['1'] += km
            } else if(f == 0          ){ this.analytics.n_quantiles['0'] += 1; this.analytics.n_km_quantiles['0'] += km 
            }
        }
        // totals ...
        this.analytics.n_quantiles.total   = this.quantiles.reduce((total, x) => total + that.analytics.n_quantiles[   `${x}`], 0);
        this.analytics.n_km_quantiles.total= this.quantiles.reduce((total, x) => total + that.analytics.n_km_quantiles[`${x}`], 0);
        this.analytics.n_roadTypes.total   = this.roadTypes.reduce((total, x) => total + that.analytics.n_roadTypes[   `${x}`], 0);
        this.analytics.n_km_roadTypes.total= this.roadTypes.reduce((total, x) => total + that.analytics.n_km_roadTypes[`${x}`], 0);
        // frequencies ...
        this.quantiles.forEach(x => that.analytics.f_quantiles[   `${x}`] = that.analytics.n_quantiles[   `${x}`] / that.analytics.n_quantiles.total     );
        this.quantiles.forEach(x => that.analytics.f_km_quantiles[`${x}`] = that.analytics.n_km_quantiles[`${x}`] / that.analytics.n_km_quantiles.total  );
        this.roadTypes.forEach(x => that.analytics.f_roadTypes[   `${x}`] = that.analytics.n_roadTypes[   `${x}`] / that.analytics.n_roadTypes.total     );
        this.roadTypes.forEach(x => that.analytics.f_km_roadTypes[`${x}`] = that.analytics.n_km_roadTypes[`${x}`] / that.analytics.n_km_roadTypes.total  );
        // (checksum) make sure total is 100%, if not, it must be revealed: 
        ["quantiles", "roadTypes"].forEach(dimension => {
            this.analytics[`f_${dimension}`   ].total   = this[dimension].reduce((total, x) => total + that.analytics[`f_${dimension}`][`${x}`], 0);
            this.analytics[`f_km_${dimension}`].total   = this[dimension].reduce((total, x) => total + that.analytics[`f_km_${dimension}`][`${x}`], 0);
        })
    }

    resetAnalytics(){
        let that        = this;
        let dimensions: string[] = ["quantiles", "roadTypes"];
        this.analytics  = { 
              n_quantiles      : {}, f_quantiles     : {}
            , n_km_quantiles   : {}, f_km_quantiles  : {} 
            , n_roadTypes      : {}, f_roadTypes     : {}
            , n_km_roadTypes   : {}, f_km_roadTypes  : {} 
        }
        dimensions.forEach(dimension => {
            that[dimension].forEach(value => {
                that.analytics[`n_${dimension}`     ][`${value}`] = 0
                that.analytics[`n_km_${dimension}`  ][`${value}`] = 0
                that.analytics[`f_${dimension}`     ][`${value}`] = 0
                that.analytics[`f_km_${dimension}`  ][`${value}`] = 0
                that.analytics[`n_${dimension}`     ].total = 0
                that.analytics[`f_${dimension}`     ].total = 0
                that.analytics[`n_km_${dimension}`  ].total = 0
                that.analytics[`f_km_${dimension}`  ].total = 0
            })
        })
    }

    /*
     * COMPONENT / CONSTRUCTOR _________________________________
     */
    constructor(
        private session             : Session
        , private processingService : ProcessingService
        , private spinnerService    : SpinnerService
        , private mapService        : MapService
        , private toastService      : ToastService
        , private panelService      : PanelService
        , private translate         : TranslateService
        , private roadFlowStore     : RoadFlowStoreService
        , private roadFlowService   : RoadFlowService
    ) {
        this.roadTypeOptions = [
              { code: "highways"      , name: "Highways" }
            , { code: "primary"       , name: "Primary" }
            , { code: "secondary"     , name: "Secondary" }
            , { code: "tertiary"      , name: "Tertiary" }
            , { code: "residential"   , name: "Residential" }
            , { code: "unclassified"  , name: "Unclassified" }
        ];
        this.selectMetricOptions = [ 
              {code: 'aadt_obs'     , name: 'Observed AADT (on-site real counts)'       }
            , {code: 'aadt_est_v0'  , name: 'v0: Baseline, nation-wide, estimated AADT' }
            , {code: 'aadt_est_v1'  , name: 'v1: Ventilate traffic based on topology'   }
            , {code: 'aadt_est_v2'  , name: 'v2: (v1+) Influence of population density' }
            , {code: 'aadt_est_v3'  , name: 'v3: (v2+) Influence of topology / road type'}
        ];
        let that    :any        = this
        let map     :any        = this.mapService.getMap().map
        this.roadFlowService    = roadFlowService   ;
        this.spinnerService     = spinnerService    ;
        map.on("sourcedata", function(e) {
            let isComponentData: boolean = true &&
                map.getSource('flow_geo')       &&
                map.isSourceLoaded('flow_geo')  ;
            if (isComponentData)  that.updateAnalytics();
        });
        /*
         * Timings and spinner  ...
         */
        map.on("sourcedataloading", function(e) { /* Start event */
            that.timings.t0         = new Date()
            spinnerService.getSpinner().addOperation("MainMenuComponent.showRoadFlowData");
            setTimeout(function(){
                spinnerService.getSpinner().removeOperation("MainMenuComponent.showRoadFlowData");
            }, 60000);
        })
        map.on("idle", function(e){ /* End event*/
            that.timings.t1         = new Date()
            that.timings.delta_t    = that.timings.t1 - that.timings.t0
            spinnerService.getSpinner().removeOperation("MainMenuComponent.showRoadFlowData");
        })
    }

    ngOnInit() {
        this.resetAnalytics();
        this.timings = {'t0': null, 't1': null, 'delta_t': null};
        this.subscriptions.push(this.session.onChangeWorkspace.subscribe(geometry => {
            this.refresh();
        }));

        this.roadFlowStore.useProxy(this);
        this._isShowElement = true;
        this.quantiles = [7,6,5,4,3,2,1,0];
        for(let i = 0 ; i < this.roadFlowStore.state.quantiles.length ; i++){
            this.selectedQuantiles.push(this.roadFlowStore.state.quantiles[i]);
            this.selectedCutoffs[`${i}`] = this.roadFlowStore.state.cutoffs[`${i}`];
        }
        for(let i = 0 ; i < this.roadTypeOptions.length ; i++){
            this.selectedRoadTypes.push(this.roadTypeOptions[i].code);
        }
    }

    /*
     * COMPONENT / HANDLERS ___________________________________________________
     */
    toggleQuantiles(){
        if(this.selectedQuantiles.length){
            this.selectedQuantiles = [];
        } else {
            this.selectedQuantiles = [7,6,5,4,3,2,1,0];
        }
    }

    toggleRoadTypes(){
        if(this.selectedRoadTypes.length){
            this.selectedRoadTypes = [];
        } else {
            this.selectedRoadTypes = this.roadFlowStore.state.roadTypes;
        }
    }

    selectMetric(event: any) {
        this.selectedMetric     = event.value.code ;
    }

    selectCutoff(quantile: number, cutoff: number) {
        this.selectedCutoffs['' + quantile] = cutoff
        this.roadFlowService.updateSearch(this.roadFlowStore.state)
    }
}
