import { IFeatureProperties } from './ifeature-properties';
import { ISerializable      } from '../iserializable';

export class FeatureObjectRoadFsiModel implements IFeatureProperties, ISerializable {
    public featureType              : string;
    public accident_id              : string;
    public road_user                : string;
    public severity                 : string;
    public name                     : string;
    public t_accident               : string;
    public luminosity               : string;
    public fixed_obstacle           : string;
    public moving_obstacle          : string;
    public surface                  : string;
    public collision                : string;
    public data                     : object = [];
    public crash_raw                : string;
    public landmark_id              : string;

    deserialize(input: any): this {
        Object.assign(this, input);
        this.data                   = {};
        return this;
    }

    serialize(): string {
        return JSON.stringify(this);
    }

    toJson(): any {
        return JSON.parse(this.serialize());
    }

    toExcel(): any {
        let item: any = {};
        item.accident_id = this.accident_id;
        return item;
    }
}
