import {GeometryHelper} from "./geometry.helper";
import {LineStringHelper} from "./linestring-helper";
import {GeometryModel} from "../models/geojson/geometry-model";
import {FeatureModel} from "../models/geojson/feature-model";
import {FeatureCollectionModel} from "../models/geojson/feature-collection-model";
import {GeometryTypeEnum} from "../enums/geometry-type-enum.enum";
import {FeatureTypesEnum} from "../enums/feature-types-enum.enum";

export class FeatureHelper {

  static createFeature(geometry: GeometryModel, properties: any = undefined): FeatureModel {
    let result: FeatureModel = new FeatureModel();
    result.geometry = geometry;
    result.properties = properties || geometry.properties || {};

    return result;
  }

  static createFeatureCollection(features: FeatureModel[], properties: any = undefined): FeatureCollectionModel {
    let result: FeatureCollectionModel = new FeatureCollectionModel();
    result.features = features;
    result.properties = properties;

    return result;
  }

  static createFeatureCollectionFromGeometries(geometries: GeometryModel[]): FeatureCollectionModel {
    let result: FeatureCollectionModel = new FeatureCollectionModel();

    if (geometries !== undefined && geometries.length > 0) {
      for (let geometry of geometries) {
        result.features.push(this.createFeature(geometry, geometry.properties));
      }
    }

    return result;
  }

  static createSingleFeatureCollection(feature: FeatureModel, properties: any = undefined): FeatureCollectionModel {
    let result: FeatureCollectionModel = new FeatureCollectionModel();
    result.features.push(feature);
    result.properties = properties;

    return result;
  }

  static isValidFeature(value: FeatureModel): boolean {
    return (value !== undefined
        && value.type === FeatureTypesEnum.FEATURE
        && GeometryHelper.isValidGeometry(value.geometry))
  }

  static fetchLineStrings(features: FeatureModel[]): GeometryModel[] {
    let linestrings: GeometryModel[] = [];

    for (let feature of features) {
      if (GeometryHelper.isValidGeometryType(feature.geometry, GeometryTypeEnum.LINE_STRING)) {
        linestrings.push(GeometryHelper.createLineString(feature.geometry.coordinates, feature.properties));
      } if (GeometryHelper.isValidGeometryType(feature.geometry, GeometryTypeEnum.MULTI_LINESTRING)) {
        linestrings.concat(LineStringHelper.extractLineStrings(feature.geometry));
      }
    }

    return linestrings;
  }

}
