<li class="listMenu" appWorkspaceRequired
    *ngIf="session.hasRole('ROLE_ROUGHNESS')"
    [ngClass]="{'itemChecked':roughnessStore.state.activated, 'itemUnchecked': !roughnessStore.state.activated}"
    (click)="openRoughnessEntryPanel()">
    <i class="fas fa-vial"></i>
    <span class="text">{{ 'roughnessEntry.title' | translate }}</span>
    <a role="button" *ngIf="roughnessStore.state.activated">
        <span (click)="close($event)" class="pi pi-times ng-tns-c48-2">
        </span>
    </a>
</li>
