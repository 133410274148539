import {Injectable} from '@angular/core';
import {LocaleSettings} from "primeng/calendar";

@Injectable({
  providedIn: 'root'
})
export class LocalesHelper {

  constructor() {}

  static getCalendarFr(): LocaleSettings {
    return {
      firstDayOfWeek: 1,
      dayNames: [ "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi" ],
      dayNamesShort: [ "dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam." ],
      dayNamesMin: [ "D","L","M","M","J","V","S" ],
      monthNames: [ "janvier", "février", "mars", "avril", "mai", "juin",
        "juillet", "août", "septembre", "octobre", "novembre", "décembre" ],
      monthNamesShort: [ "janv.", "févr.", "mars", "avr.", "mai", "juin",
        "juil.", "août", "sept.", "oct.", "nov.", "déc." ],
      today: "Aujourd'hui",
      clear: "Effacer",
      dateFormat: "dd/mm/yy",
      weekHeader: "Sem."
    };
  }
}
