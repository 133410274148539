<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_ALERT')"
    appWorkspaceRequired
    [ngClass]="{'itemChecked':alertStore.state._isShowElement, 'itemUnchecked': !alertStore.state._isShowElement}"
    (click)="openMenuAlert()">
    <i class="fa fa-exclamation-circle"></i>
    <span>{{ 'alertEntry.title' | translate }}</span><br />
    <a role="button" *ngIf="alertStore.state._isShowElement"
       [ngClass]="{'itemChecked':alertStore.state._isShowElement, 'itemUnchecked': !alertStore.state._isShowElement}">
        <span (click)="removeAlerts()" class="pi pi-times ng-tns-c48-2">
        </span>
    </a>
</li>
