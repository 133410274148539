import {Component, OnInit} from '@angular/core';
import {ChangeContext, Options} from 'ng5-slider';
import {Subscription, throwError} from 'rxjs';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {EventCodeEnum} from 'src/app/enums/event-code-enum.enum';
import {GeometryTypeEnum} from 'src/app/enums/geometry-type-enum.enum';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {Session} from 'src/app/globals/session';
import {GeometryHelper} from 'src/app/helpers/geometry.helper';
import {WindowEventHelper} from 'src/app/helpers/window-event.helper';
import {FeatureCollectionModel} from 'src/app/models/geojson/feature-collection-model';
import {ElementClassCollectionModel} from 'src/app/models/masterData/element-class-collection-model';
import {ElementClassModel} from 'src/app/models/masterData/element-class-model';
import {ListCollectionModel} from 'src/app/models/masterData/list-collection-model';
import {ListValueModel} from 'src/app/models/masterData/list-value-model';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MasterDataService} from 'src/app/services/master-data.service';
import {ProcessingService} from 'src/app/services/processing.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import * as turf from "@turf/turf";
import {PanelService} from 'src/app/shared/panel/panel.service';
import {GroundService} from '../ground.service';
import {GroundStoreService} from '../ground-store.service';

export class Grid {
  name: string;
  code: string;
}

declare let gtag: Function;

@Component({
  selector: 'app-ground-entry-panel',
  templateUrl: './ground-entry-panel.component.html',
  styleUrls: ['./ground-entry-panel.component.css']
})
export class GroundEntryPanelComponent implements OnInit, PanelBase<any> {
  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }

  constructor(public session: Session,
              private processingService: ProcessingService,
              private geojsonService: GeojsonService,
              private masterdataService: MasterDataService,
              private spinnerService: SpinnerService,
              private mapService: MapService,
              private toastService: ToastService,
              private panelService: PanelService,
              private groundService: GroundService,
              private groundStore: GroundStoreService) {
  }

  data: any;

  selectedNetworks: string[] = [];

  productColor: string[] = [];
  productAxis: string[] = [];
  productType: string[] = [];

  productColorValues: ListValueModel[] = [];
  productAxisValues: ListValueModel[] = [];
  productTypeValues: ListValueModel[] = [];

  environment = environment;
  subscriptions: Subscription[] = [];


  classList: ElementClassModel[] = [];
  classGroups: string[] = [];
  classSelected: { [key: string]: ElementClassModel } = {};
  numberOfSelectClasses = 0;

  isRelevant: boolean = true;
  isElementRemoved: boolean = true;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  selectedMinQuality: number = 0;
  selectedHighQuality: number = 100;

  qualitySliderOptions: Options = {
    floor: 0,
    ceil: 100,
    showOuterSelectionBars: true
  };
  selectedElement: string;

  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear;
  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };
  onCreate: boolean = false;

  ngOnInit() {
    this.masterdataService.getLists('GROUND').subscribe((listCollection: ListCollectionModel) => {
      listCollection.lists.forEach((list) => {
        if (list !== undefined) {
          if (list.listCode === 'PRODUCT_TYPE_GROUND') {
            this.productTypeValues = list.listValues;
          } else if (list.listCode === 'PRODUCT_COLOR_GROUND') {
            this.productColorValues = list.listValues;
          } else if (list.listCode === 'PRODUCT_AXIS_GROUND') {
            this.productAxisValues = list.listValues;
          }
        }
      });
    });

    this.geojsonService.elementEmiter.subscribe(element => {
      if (element !== undefined) {
        this.selectedElement = element.elementIdentifier;
      }
    });

    Object.keys(this.groundStore.state).forEach(key => {
      this[key] = this.groundStore.state[key]
    });
    this.groundStore.useProxy(this, [
      {
        type: 'MULTIPLE',
        keys: ['selectedYearHighValue', 'selectedYearMinValue', 'selectedMinQuality', 'selectedHighQuality'],
        delay: 500
      }
    ]);
    this.loadClasses();
    this._isShowElement = true;

  }

  loadClasses() {
    if (!this.classList.length) {

      this.spinner.addOperation("MenuGroundComponent.loadClasses");

      this.masterdataService.getElementClass(ElementTypesEnum.ELEMENT_TYPE_GROUND).subscribe((response: ElementClassCollectionModel) => {
          if (response && response.elementClasses.length > 0) {
            this.classList = response.elementClasses;
            this.classList.forEach(item => {
              this.mapService.addMissingElementClassImage(item.elementClass, item.classIconFilePath);
              if (this.classGroups.indexOf(item.classCategory) === -1) {
                this.classGroups.push(item.classCategory);
              }
            });

          } else {
            this.toast.showToastWarn();
          }

          this.spinnerService.getSpinner().removeOperation("MenuGroundComponent.loadClasses")
        },
        (_error => {
          this.spinnerService.getSpinner().removeOperation("MenuGroundComponent.loadClasses")
          return throwError('error while loading classes')
        })
      );
    }
  }

  toggleFilterByClass(classElement: ElementClassModel) {
    if (!this.isClassSelected(classElement)) {
      const nextclassSelected = {...this.classSelected, [classElement.elementClass]: classElement};
      this.classSelected = nextclassSelected;
      this.numberOfSelectClasses++;
    } else {
      const classSelected = {...this.classSelected};
      delete classSelected[classElement.elementClass];
      this.classSelected = classSelected;
      this.numberOfSelectClasses--;
    }
  }

  show(show: boolean = true) {
    this.panelService.pop();
  }

  clear() {
    this.groundStore.reset();
    this.panelService.pop();
  }

  isClassSelected(classModel: ElementClassModel) {
    return this.classSelected[classModel.elementClass]
  }

  updateQualityValues($event: ChangeContext) {
    this.selectedMinQuality = $event.value;
    this.selectedHighQuality = $event.highValue;
  }

  updateYearValues($event: ChangeContext) {
    this.selectedYearMinValue = $event.value;
    this.selectedYearHighValue = $event.highValue;
  }
}
