import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {VisibilityStoreService} from '../visibility-store.service';


@Component({
  selector: 'app-visibility-entry-panel',
  templateUrl: './visibility-entry-panel.component.html',
  styleUrls: ['./visibility-entry-panel.component.css']
})
export class VisibilityEntryPanelComponent implements OnInit, PanelBase<any> {

  constructor(
    private spinnerService: SpinnerService,
    private mapService: MapService,
    private toastService: ToastService,
    private panelService: PanelService,
    private visibilityStore: VisibilityStoreService) {
  }

  data: any;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  _curveProfile: string = 'NONE';
  subscriptions: Subscription[] = [];

  ngOnInit() {
    this.subscriptions.push(this.visibilityStore.applyAndWatch(_ => this.visibilityStore.applyOn(this)))
    this.showElement('SPEEDLIMIT');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  showElement(curveprofile: string) {
    const state = this.visibilityStore.buildStateFromComponent(this)
    state._isShowElement = true;
    state._curveProfile = curveprofile;
    this.visibilityStore.state = state;
  }

  refresh() {
    this.visibilityStore.refresh();
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if (!show) {
      this.panelService.pop();
    }
  }

  clear() {
    this.visibilityStore.reset();
    this.show(false);
  }

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }
}
