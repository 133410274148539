import {Pipe, PipeTransform} from "@angular/core";
import moment from "moment";

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform{
  transform(value: any, ...args: any[]): any {
    if(args && args.length>0){
      return moment(value).format(args[0]);
    }
    return moment(value).format()

  }

}
