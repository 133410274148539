import { Component, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import { PanelBase } from 'src/app/shared/panel/component/PanelBase';
import { PanelService } from 'src/app/shared/panel/panel.service';
import { RoughnessState, RoughnessStoreService } from '../roughness-store.service';

@Component({
  selector: 'app-roughness-entry-panel',
  templateUrl: './roughness-entry-panel.component.html',
  styleUrls: ['./roughness-entry-panel.component.css']
})
export class RoughnessEntryPanelComponent implements OnInit, PanelBase<any>, RoughnessState {

  constructor(
    public roughnessStore: RoughnessStoreService,
    public panelService: PanelService
  ) { }
  period: number[];
  quality: number[];


  maxGoodQuality: number;
  minWorstQuality: number;
  minPeriod: number;
  maxPeriod: number;
  activated: boolean;
  data: any;

  actualYear: number = new Date().getFullYear();

  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };

  qualitySliderOptions: Options = {
    floor: 0,
    ceil: 30,
    showOuterSelectionBars: true,
  };

  ngOnInit(): void {
    this.roughnessStore.useProxy(this,[{
        keys: ['maxGoodQuality', 'minWorstQuality', 'minPeriod', 'maxPeriod'],
        type: 'MULTIPLE',
        delay: 500
      }
    ]);
    this.activated = true;
  }

  close(){
    this.panelService.pop();
  }

  clear(){
    this.roughnessStore.reset();
    this.close();
  }
}
