import {Injectable} from '@angular/core';
import {BboxObject} from "../models/detection/bbox-object";

@Injectable({
  providedIn: 'root'
})
export class BboxHelper {

  constructor() {
  }

  static bboxOrientationCorrection(bbox: BboxObject):BboxObject {

    if (bbox.imageOrientation == 3) {
      this.flopBbox(bbox);
      this.flipBbox(bbox);

    } else if (bbox.imageOrientation == 6) {
      this.reverseBbox(bbox);
      this.flipBbox(bbox);

    } else if (bbox.imageOrientation == 8) {
      this.reverseBbox(bbox);
      this.flopBbox(bbox);

    }
    bbox.imageOrientation = 1;

    return bbox;
  }

  static flipBbox(bbox: BboxObject):BboxObject {
    let y_min = bbox.y;
    let y_max = bbox.y + bbox.height;
    let x_min = bbox.imageWidth - bbox.x - bbox.width;
    let x_max = x_min + bbox.width;

    bbox.x = x_min;
    bbox.y = y_min;
    bbox.width = x_max-x_min;
    bbox.height = y_max-y_min;

    return bbox;
  }

  static flopBbox(bbox: BboxObject):BboxObject {
    let y_min = bbox.imageHeight - bbox.y - bbox.height;
    let y_max = y_min + bbox.height;
    let x_min = bbox.x;
    let x_max = bbox.x + bbox.width;

    bbox.x = x_min;
    bbox.y = y_min;
    bbox.width = x_max-x_min;
    bbox.height = y_max-y_min;

    return bbox;
  }

  static reverseBbox(bbox: BboxObject):BboxObject {
    let y = bbox.x;
    let x = bbox.y;
    let width = bbox.height;
    let height = bbox.width;
    let imageWidth = bbox.imageHeight;
    let imageHeight = bbox.imageWidth;

    bbox.x = x;
    bbox.y = y;
    bbox.imageWidth = imageWidth;
    bbox.imageHeight = imageHeight;
    bbox.width = width;
    bbox.height = height;

    return bbox;
  }
}
