import {Component} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {RoughnessEntryPanelComponent} from './roughness-entry-panel/roughness-entry-panel.component';
import {RoughnessStoreService} from './roughness-store.service';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-roughness-entry',
  templateUrl: './roughness-entry.component.html',
  styleUrls: ['./roughness-entry.component.css']
})
export class RoughnessEntryComponent {

  constructor(
    public session: Session,
    public roughnessStore: RoughnessStoreService,
    public panelService: PanelService
    ) { }

  openRoughnessEntryPanel(){
    this.panelService.push(
      RoughnessEntryPanelComponent,
      null
    )
    Matomo.push(['trackEvent', 'Actions', 'Opened road quality menu'])
  }

  close(event: Event){
    event.stopPropagation();
    const nexState = this.roughnessStore.state;
    nexState.activated = false;
    this.roughnessStore.state = nexState;
    Matomo.push(['trackEvent', 'Actions', 'Removed road quality'])
  }

}
