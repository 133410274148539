import { Injectable } from '@angular/core';
import { Store } from 'src/app/globals/store';

export class JourneyState{
  isShowJourneys = false
}

@Injectable({
  providedIn: 'root'
})
export class JourneyStoreService extends Store<JourneyState>{

  constructor() { 
    super(JourneyState)
  }
}
