<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_PR')"
    appWorkspaceRequired
    [ngClass]="{'itemChecked':locationMarkerStoreService.state.isShowLocationMarker, 'itemUnchecked': !locationMarkerStoreService.state.isShowLocationMarker}"
    (click)="openLocationMarkers()">
  <i class="fa ca-milestone"></i>
  <span class="text">{{ 'locationMarkerEntry.title' | translate }}</span>
  <a role="button" *ngIf="locationMarkerStoreService.state.isShowLocationMarker">
    <span (click)="removeLocationMarkers($event)" class="pi pi-times ng-tns-c48-2"> </span>
  </a>
</li>

