import {ISerializable} from '../iserializable';

export class ConfigModel implements ISerializable {

  configCode: string;
  configGroup: string;
  key: string;
  valueJson: any;
  valueText: string;

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
