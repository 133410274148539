import { Component, OnInit } from '@angular/core';
import { Session } from 'src/app/globals/session';
import { GroundEntryPanelComponent } from '../main-panel/ground-entry/ground-entry-panel/ground-entry-panel.component';
import { GroundStoreService } from '../main-panel/ground-entry/ground-store.service';
import { LaneEntryPanelComponent } from '../main-panel/lane-entry/lane-entry-panel/lane-entry-panel.component';
import { LaneStoreService } from '../main-panel/lane-entry/lane-store.service';
import { RetroreflexionEntryPanelComponent } from '../main-panel/retroreflexion-entry/retroreflexion-entry-panel/retroreflexion-entry-panel.component';
import { RetroreflexionStoreService } from '../main-panel/retroreflexion-entry/retroreflexion-store.service';
import { SignEntryPanelComponent } from '../main-panel/sign-entry/sign-entry-panel/sign-entry-panel.component';
import { SignStoreService } from '../main-panel/sign-entry/sign-store.service';
import { StreetlightEntryPanelComponent } from '../main-panel/streetlight-entry/streetlight-entry-panel/streetlight-entry-panel.component';
import { StreetlightStoreService } from '../main-panel/streetlight-entry/streetlight-store.service';


@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.css']
})
export class ShortcutsComponent implements OnInit {

  constructor(
    public signStore: SignStoreService,
    public groundStore: GroundStoreService,
    public laneStore: LaneStoreService,
    public streetlightStore: StreetlightStoreService,
    public retroreflexionStore: RetroreflexionStoreService,
    public session: Session
  ) { }

  signPanel = SignEntryPanelComponent;
  groundPanel = GroundEntryPanelComponent;
  lanePanel = LaneEntryPanelComponent;
  streetlightPanel = StreetlightEntryPanelComponent;
  retroreflexionPanel = RetroreflexionEntryPanelComponent;


  ngOnInit(): void {
  }

}
