import {EventEmitter, Injectable, Output} from "@angular/core";
import {GeometryModel} from "../models/geojson/geometry-model";
import {GeometryHelper} from "../helpers/geometry.helper";
import {SessionModel} from "../models/session/session-model";
import {EventCodeEnum} from "../enums/event-code-enum.enum";
import {WindowEventHelper} from "../helpers/window-event.helper";
import {BehaviorSubject} from "rxjs";
import {DomainCollectionModel} from "../models/session/domain-collection-model";


@Injectable({
  providedIn: 'root'
})
export class Session {

  @Output() onChangeWorkspace: EventEmitter<GeometryModel> = new EventEmitter();

  isLogged: boolean = false;
  isLogging = false;
  token: string;
  currentSession: SessionModel;
  sessionIdentifier: string;
  sessionLocale: string;
  sessionProperties: any;
  domains: DomainCollectionModel;
  _workspaceGeo: GeometryModel;
  workspace$ = new BehaviorSubject(null);
  embedded = false;

  setWorkspace(geometry: GeometryModel) {
    if (GeometryHelper.isValidGeometry(geometry)) {
      this._workspaceGeo = geometry;
    } else {
      this._workspaceGeo = undefined;
    }
    this.workspace$.next(this._workspaceGeo);
    WindowEventHelper.sendEvent(EventCodeEnum.WORKSPACE_CHANGE, geometry);
    this.onChangeWorkspace.emit(geometry);
  }

  watchWorkspace(callback:(workspace: GeometryModel)=>void){
    this.workspace$.subscribe(callback);
  }

  getWorkspace(): GeometryModel {
    return this._workspaceGeo;
  }

  loadSession(sessionModel: SessionModel) {
    if (sessionModel != undefined) {
      this.sessionIdentifier = sessionModel.sessionIdentifier;
      this.isLogged = true;
      this.isLogging = false;
      this.currentSession = sessionModel;
      this.sessionLocale = sessionModel.user.userLocale;
      this.sessionProperties = sessionModel.user.userProperties;

    } else {
      this.sessionIdentifier = undefined;
      this.isLogged = false;
      this.isLogging = false;
      this.currentSession = undefined;
      this.sessionLocale = "en_GB";
      this.sessionProperties = undefined;

      this.setWorkspace(undefined);
    }
  }

  hasRole(role:string): boolean {
    return this.currentSession.user.userRoles.includes(role);
  }
}
