import {Injectable} from '@angular/core';
import {EventModel} from "../models/event-model";
import {EventCodeEnum} from "../enums/event-code-enum.enum";

@Injectable({
  providedIn: 'root'
})
export class WindowEventHelper {

  constructor() {}

  static sendEvent(eventCode: EventCodeEnum, eventDetails: any = undefined): EventModel {

    let event: EventModel = new EventModel();
    event.eventCode = eventCode;
    event.eventDetails = eventDetails;
    window.parent.postMessage(event, '*');

    return event;
  }
}
