import {ISerializable} from '../iserializable';
import {PricingGridModel} from "./pricing-grid-model";

export class PricingGridCollectionModel implements ISerializable {

  pricingGrids: PricingGridModel[];

  deserialize(input: any): this {
    Object.assign(this, input);
    this.pricingGrids = input.pricingGrids.map(pricingGrid => new PricingGridModel().deserialize(pricingGrid));
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
