import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {LaneEntryPanelComponent} from './lane-entry-panel/lane-entry-panel.component';
import {LaneStoreService} from './lane-store.service';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-lane-entry',
  templateUrl: './lane-entry.component.html',
  styleUrls: ['./lane-entry.component.css']
})
export class LaneEntryComponent implements OnInit {

  constructor(public session: Session, public laneStore: LaneStoreService, public panelService: PanelService) { }

  ngOnInit(): void {
  }

  openMenuLane(){
    this.panelService.push(
      LaneEntryPanelComponent,
      null
    )
    Matomo.push(['trackEvent', 'Actions', 'Opened lane menu'])
  }

  close(event:Event){
    event.preventDefault();
    event.stopPropagation();
    this.laneStore.reset();
    Matomo.push(['trackEvent', 'Actions', 'Removed lane'])
  }

}
