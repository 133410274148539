import {Component, OnInit} from '@angular/core';
import moment from 'moment';
import {MasterDataService} from "../../../services/master-data.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {Session} from "../../../globals/session";

const companyLogoKey = "companyLogo";
const companyLogExpirationKey = "companyLogoCacheExpiration";
const companyLogoConfigurationCode = 'LOGO';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  companyLogo: string;

  constructor(private masterdataService: MasterDataService, private session:Session) {
  }

  ngOnInit(): void {
    let companyLogoInStorage = localStorage.getItem(companyLogoKey+this.session.currentSession.domain.domainId);
    let companyLogoCacheExpirationInStorage: moment.Moment = moment(localStorage.getItem(companyLogExpirationKey+this.session.currentSession.domain.domainId));
    if (companyLogoInStorage === null
      || (companyLogoCacheExpirationInStorage && companyLogoCacheExpirationInStorage.isBefore(moment()))) {
      this.retrieveLogoFromConfiguration().subscribe(companyLogo => {
        localStorage.setItem(companyLogoKey+this.session.currentSession.domain.domainId, companyLogo);
        localStorage.setItem(companyLogExpirationKey+this.session.currentSession.domain.domainId, moment().add(1, 'hour').toISOString())
        this.companyLogo = companyLogo;
      })
    } else {
      this.companyLogo = companyLogoInStorage;
    }
  }

  retrieveLogoFromConfiguration(): Observable<string> {
    return this.masterdataService.getConfigurations(companyLogoConfigurationCode).pipe(map(value => {
      return value && value.configurations && value.configurations[0] && value.configurations[0].valueText ? value.configurations[0].valueText : 'null';
    }));
  }

}
