<app-left-panel></app-left-panel>

<app-login *ngIf="!session.isLogged && !session.isLogging"></app-login>

<span *ngIf="session.isLogged">

    <button id="menuButton" type="text" (click)="toggleMenu()" class="buttonStyle" pButton icon="fa fa-bars"></button>

    <app-search [toast]="toastsComponent" [spinner]="spinnerComponent" [map]="mapComponent" class="ui-inputgroup appSearch" *ngIf=" snapshot ==''"></app-search>

    <app-shortcuts></app-shortcuts>

    <p-tabView class="appViewer" *ngIf="viewerStore.state.isShowViewer || viewerStore.state.isShowGoogle" (onChange)="handleTabChange($event)">
        <p-tabPanel header="City Viewer">
            <app-viewer id="viewerComponent" #viewerComponent [visible]="viewerStore.state.isShowViewer"
                        [map]="mapComponent" ></app-viewer>
        </p-tabPanel>
        <p-tabPanel header="Google street view" [disabled]="!session.hasRole('ROLE_MAP_GOOGLESTREET') || viewerStore.state.geometry === undefined">
            <app-google-street-view id="googleComponent" #googleComponent
                                    [map]="mapComponent" [visible]="viewerStore.state.isShowGoogle"></app-google-street-view>
        </p-tabPanel>
    </p-tabView>

    <app-map id="mapComponent" #mapComponent data-tap-disabled="true" (onCloseViewer)="viewerStore.state = {isShowViewer: false}"></app-map>

    <app-spinner id="spinnerComponent" #spinnerComponent class="hide"></app-spinner>

    <app-spiderify [mapComponent]="mapComponent"></app-spiderify>

    <app-toasts id="toastsComponent" #toastsComponent class="hide"></app-toasts>

    <button *ngIf="session.hasRole('ROLE_MAP_STATS')" id="statButton" type="text" (click)="toggleStat()" class="buttonStyle" pButton icon="fa-regular fa-chart-column" pTooltip="Afficher les statistiques"></button>
</span>

<app-right-panel></app-right-panel>
