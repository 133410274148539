import {Injectable} from '@angular/core';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {MapService} from 'src/app/shared/map/map.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {VisibilityState, VisibilityStoreService} from './visibility-store.service';


declare let gtag: Function;



@Injectable({
  providedIn: 'root'
})
export class VisibilityService {

  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }

  constructor(
    private spinnerService: SpinnerService,
    private mapService:MapService,
    private toastService: ToastService,
    private visibilityStore: VisibilityStoreService) {
      this.visibilityStore.applyAndWatch(this.showElementWrapper.bind(this))
    }

  showElementWrapper(state: VisibilityState){
    this.mapService.callWhenAvailable(()=>this.showElement(state))
  }

  showElement(state: VisibilityState) {
    let curveProfile = state._curveProfile;
    // Show Markers
    if (state._isShowElement === true) {
      let tileUrl = environment.tilesVisibilityServer + '?curveProfile=' + curveProfile;

      let layout = {
        'line-cap': 'round',
        'line-join': 'round'
      };

      let paint = {
        'line-color': [
          'match',
          ['get', 'axis'],
          'ASC',
          '#002FFF',
          'DSC',
          '#FF3A44',
          '#FF8F3A'
        ],
        'line-width': 2.1,
        'line-opacity': .9,
        'line-offset' : [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15, 3,
          22, 18]
      };

      this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_VISIBILITY, tileUrl,MapSourceEnum.SOURCE_LAYER_VISIBILITY, MapSourceEnum.SOURCE_VISIBILITY,false , layout, paint);

      this.map.map.on('click', MapLayerEnum.LAYER_VISIBILITY, (e) => {
        if (e.features.length > 0) {
          let feature = e.features[0];

          if (feature) {


            let description = "<b>Informations</b>";
            description += "</br> Profil : " + feature.properties["curve_profile"];
            description += "</br> Type : " + feature.properties["type"];
            description += "</br> Vitesse : " +feature.properties["speed"] + " km/h";
            description += "</br> Distance : " + feature.properties["distance"] + " m";
            description += "</br> Largeur : " + feature.properties["way_lane_width"] + " + " + feature.properties["way_shoulder_width"] + " m";
            description += "</br> Conducteur : " + feature.properties["driver_height"] + " m";
            description += "</br> Urbain : " + feature.properties["urban"];
            description += "</br> Identifier : " + feature.properties["curve_identifier"];
            description += "</br>";

            this.map.addPopup(feature.geometry["coordinates"][0], description);
          }
        }
      });
    } else {
      this.map.removeLayer(MapLayerEnum.LAYER_VISIBILITY, MapSourceEnum.SOURCE_LAYER_VISIBILITY);
    }
    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'ShowVisibilityElement');
    }
  }

}
