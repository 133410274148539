import {linkTypeEnum, RoadNode, stateEnum, typeLinkMap} from "./road-node";

interface ILinkedList<T> {
// TODO
}

export class RoadList<T> implements ILinkedList<T> {
  private head: RoadNode<T> | null = null;
  private savedHeads: RoadNode<T>[] = [];

  setHead(head: RoadNode<T>) {
    this.head = head
  }

  saveHead() {
    this.savedHeads.push(this.head);
  }

  addNextSegments(nextSegments: RoadNode<T>[]): RoadNode<T>[] {
    for (let node of nextSegments)
      this.addNextNode(node);

    return nextSegments;
  }

  addLinks(links: [RoadNode<T>, linkTypeEnum][]): RoadNode<T>[] {
    for (let node of links)
      this.addLink(node);

    return links.map(value => value[0]);
  }

  addNextNode(node: any) {
    if (this.head != node) {
      this.head.next.push(node);
      node.prev.push(this.head);
    }
  }

  addLink(node: any) {
    if (!this.head.links.some(value => value[0] === node[0] && (value[1] & linkTypeEnum.START) == (node[1] & linkTypeEnum.START))) {
      this.head.links.push(node);
      node[0].links.push([this.head, this.getLinkStats(node[1])]);
    }
  }

  getLinkStats(type: linkTypeEnum): linkTypeEnum {
    let L_type: linkTypeEnum = 0;
    for (let e = 1; e <= 1 << 25; e = e << 1)
      if (type & e)
        L_type |= typeLinkMap.get(e);
    return L_type
  }

  getHead(): RoadNode<T> {
    return this.head;
  }

  getSavedHeads(): RoadNode<T>[] {
    return this.savedHeads;
  }

  changeStateFromHeads(state: stateEnum = stateEnum.WAITING, heads: RoadNode<T>[] = this.savedHeads): void {
    for (let head of heads)
      this.changeStateFromHead(state, head);
  }

  changeStateFromHead(state: stateEnum, head: RoadNode<T>): void {
    this.changeState(stateEnum.PRIVATE, head);
    this.changeState(state, head);
  }

  changeState(state: stateEnum, node: RoadNode<T>) {
    function changeState__(state: stateEnum, node: RoadNode<T>) {
      if (node.state == state)
        return;
      node.state = state;
      for (let n of node.next)
        changeState__(state, n);
      for (let p of node.prev)
        changeState__(state, p);
      for (let l of node.links) {
        changeState__(state, l[0]);
      }
    }
    changeState__(state, node);
  }

  changePrivateStateFromHeads(state: stateEnum = stateEnum.WAITING, heads: RoadNode<T>[] = this.savedHeads): void {
    for (let head of heads)
      this.changePrivateStateFromHead(state, head);
  }

  changePrivateStateFromHead(state: stateEnum, head: RoadNode<T>): void {
    this.changePrivateState(stateEnum.PRIVATE, head);
    this.changePrivateState(state, head);
  }

  changePrivateState(state: stateEnum, node: RoadNode<T>) {
    function changePrivateState__(state: stateEnum, node: RoadNode<T>) {
      if (node.privateState == state)
        return;
      node.privateState = state;
      for (let n of node.next)
        changePrivateState__(state, n);
      for (let p of node.prev)
        changePrivateState__(state, p);
      for (let l of node.links) {
        changePrivateState__(state, l[0]);
      }
    }
    changePrivateState__(state, node);
  }

  apply(callback: Function, heads: RoadNode<T>[] = this.savedHeads): void {
    /**
     * Apply function on every node of the graph
     */
    this.changePrivateStateFromHeads();
    for (let head of heads)
      this.applyFromHead(head, callback);
  }

  applyFromHead(node: RoadNode<T>, callback: Function) {
    function applyFromHead__(node: RoadNode<T>, callback: Function) {
      if (node.privateState == stateEnum.DONE)
        return;
      node.privateState = stateEnum.DONE;
      callback(node);
      for (let n of node.next)
        applyFromHead__(n, callback);
      for (let p of node.prev)
        applyFromHead__(p, callback);
      for (let l of node.links) {
        applyFromHead__(l[0], callback);
      }
    }
    applyFromHead__(node, callback);
  }

  getHeadsLink(head: RoadNode<T>): RoadNode<T>[] {
    /**
     * Get first node of each intersection / of each road way composed of many segments
     */
    this.changeStateFromHead(stateEnum.WAITING, head);
    let headLinks: RoadNode<T>[] = [this.findFirstNode(head)];

    const getHeadLink__ = (node: RoadNode<T>) => {
      node.state = stateEnum.DONE;
      for (let n of node.next) {
        if (n.state != stateEnum.DONE)
          getHeadLink__(n);
      }
      for (let n of node.links) {
        if (n[0].state != stateEnum.DONE) {
          headLinks.push(this.findFirstNode(n[0]));
          getHeadLink__(this.findFirstNode(n[0]));
        }
      }
    }

    getHeadLink__(this.findFirstNode(head));
    return headLinks;
  }

  private findFirstNode(startNode: RoadNode<T>): RoadNode<T> {
    let node: RoadNode<T>;
    for (node = startNode; node.prev.length > 0 && startNode !== node.prev[0]; node = node.prev[0]) ;
    return node;
  }

  countNodesFromHeads(heads: RoadNode<T>[] = this.savedHeads): number {
    let c: number = 0;

    for (let head of heads) {
      c += this.countNodesFromHead(head);
    }
    return c;
  }

  countNodesFromHead(head: RoadNode<T> = this.head): number {
    this.changeState(stateEnum.WAITING, head);
    let c: number = 0;

    function countNodes__(node: RoadNode<T>): number {
      if (node.state != stateEnum.DONE) {
        c += 1;
      } else {
        return;
      }
      node.state = stateEnum.DONE;
      for (let n of node.next)
        countNodes__(n);
      for (let p of node.prev)
        countNodes__(p);
      for (let l of node.links)
        countNodes__(l[0]);
    }

    countNodes__(head);
    return c;
  }

}
