import { Component, OnInit } from '@angular/core';
import { Session } from 'src/app/globals/session';
import { MasterDataService } from 'src/app/services/master-data.service';
import { ListCodeEnum } from 'src/app/enums/list-code-enum.enum';
import { ListValueModel } from 'src/app/models/masterData/list-value-model';
import { CustomBackgroundState, CustomBackgroundStoreService } from './custom-background-store.service';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-custom-background-entry',
  templateUrl: './custom-background-entry.component.html',
  styleUrls: ['./custom-background-entry.component.css']
})
export class CustomBackgroundEntryComponent implements OnInit {

  constructor(public session: Session,
              public customBackgroundStoreService: CustomBackgroundStoreService,
              private masterdataService: MasterDataService,  
              ) { }

  ngOnInit(): void {
    this.masterdataService.getList(ListCodeEnum.CUSTOM_MAP_URL)
          .pipe(
            map(response => (response && response.lists) ? response.lists[0].listValues : null),
          ).subscribe(this.load.bind(this))

    this.customBackgroundStoreService.watch(this.updateSelectedMapUrl.bind(this));
  }

  //CUSTOM BACKGROUND
  customMapUrls: ListValueModel[] = [];
  isShowAerial = false;
  selectedMapUrl: ListValueModel;
  toggleMenuAerial() {
    this.isShowAerial = !this.isShowAerial;
  }

  load(urls: ListValueModel[]){
    this.customMapUrls = urls;
    this.updateSelectedMapUrl(this.customBackgroundStoreService.state);
  }

  updateSelectedMapUrl(customBackgroundState: CustomBackgroundState){
    this.selectedMapUrl = this.customMapUrls.find(v=>v.valueParameters.mapCurrentUrl == customBackgroundState.selectedMapUrl)
  }

  setCustomBackground(e) {
    if(e?.value?.valueParameters){
      this.customBackgroundStoreService.state = {
        isActivated: true,
        selectedMapUrl: e.value.valueParameters.mapCurrentUrl
      }
    } else {
      this.customBackgroundStoreService.state = {
        isActivated: false,
        selectedMapUrl: null
      }
    }

  }
}
