export enum MapSourceEnum {
  SOURCE_SATELLITE = 'satelliteSource',
  SOURCE_SATELLITE_IGN = 'satelliteIgnSource',
  SOURCE_WORKSPACE = 'workspaceSource',
  SOURCE_CUSTOM_URL = 'customMapUrlSource',
  SOURCE_WORKSPACE_MASK = 'workspaceSourceMask',
  SOURCE_MEASURE = 'measure',
  SOURCE_CAMERA = 'cameraSource',
  SOURCE_JOURNEY = 'journeySource',
  SOURCE_LAYER_JOURNEY = 'snapshot_geo',
  SOURCE_LOCATION_MARKER = 'locationMarkerSource',
  SOURCE_LAYER_MARKER = 'data',
  SOURCE_ALERT= 'alertSource',
  SOURCE_LAYER_ALERT= 'alert',
  SOURCE_VISIBILITY= 'visibilitySource',
  SOURCE_REFLECTO= 'reflectoSource',
  SOURCE_RISK_SCORE = 'riskScoreSource',
  SOURCE_LAYER_VISIBILITY= 'data',
  SOURCE_LOCATION_MARKER_W = 'locationMarkerSourceW',
  SOURCE_GPS_RGP = 'gpsRpgSource',
  SOURCE_STREETLIGHT = 'streetlightSource',
  SOURCE_SEGMENT_LOCATION = 'locationMarkerSegmentSource',
  SOURCE_SEGMENT_GRADUATION = 'locationMarkerSegmentGraduationSource',
  SOURCE_SEGMENT_GRADUATION_TEXT = 'locationMarkerSegmentGraduationTextSource',
  SOURCE_TILE_SEGMENTS = 'data',
  SOURCE_SKETCH_WAY = 'tile-segments',
  SOURCE_SKETCH_WAY_UNIQUE = 'tile-segments',

  SOURCE_QUALITY_RED = 'qualityRedSource',
  SOURCE_QUALITY_GREEN = 'qualityGreenSource',
  SOURCE_QUALITY_ORANGE = 'qualityOrangeSource',
  SOURCE_QUALITY_WHITE = 'qualityWhiteSource',
  SOURCE_GROUND_WHITE = 'groundWhiteSource',
  SOURCE_GROUND_DOTTED = 'groundDottedSource',
  SOURCE_GROUND_T1 = 'groundT1Source',
  SOURCE_GROUND_T2 = 'groundT2Source',
  SOURCE_GROUND_T3 = 'groundT3Source',
  SOURCE_GROUND_T4 = 'groundT4Source',
  SOURCE_CIRCLE = 'circleSource',


  /* CityMapper */
  SOURCE_ELEMENT = 'element_geo',
  SOURCE_LAYER_LANE = 'element_lane_geo',
  SOURCE_LAYER_TEXT = 'element_text_geo',

  SOURCE_LAYER_CURVES = "data",
  SOURCE_LAYER_ROUGHNESS = 'data',
  SOURCE_LAYER_GLARE = "data",
  SOURCE_LAYER_NARROW = 'data',
        /*
         * ROAD SAFETY & ACCIDENTOLOGY _______________________
         */
  SOURCE_LAYER_RISK     = 'risk_geo',
  SOURCE_LAYER_FLOW     = 'flow_geo',
  SOURCE_LAYER_ROAD_FSIS= 'fsi_geo',//SOURCE_LAYER_ROAD_FSIS= 'data',
  SOURCE_ROAD_FSIS      = 'fsi_geo', // SOURCE_CRASH= 'data', [2023-08-21]
}
