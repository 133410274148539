import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ListCodeEnum } from 'src/app/enums/list-code-enum.enum';
import { Session } from 'src/app/globals/session';
import { StringUtils } from 'src/app/helpers/string.utils';
import { ObjectElementModel } from 'src/app/models/annotation/object-element-model';
import { ListCollectionModel } from 'src/app/models/masterData/list-collection-model';
import { ListValueModel } from 'src/app/models/masterData/list-value-model';
import { AnnotationService } from 'src/app/services/annotation.service';
import { GeojsonService } from 'src/app/services/geojson.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { MapService } from 'src/app/shared/map/map.service';
import { PanelBase } from 'src/app/shared/panel/component/PanelBase';
import { PanelService } from 'src/app/shared/panel/panel.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';

declare let OpenLocationCode: any;

@Component({
  selector: 'app-barrier-view-panel',
  templateUrl: './barrier-view-panel.component.html',
  styleUrls: ['./barrier-view-panel.component.css']
})
export class BarrierViewPanelComponent implements OnInit, PanelBase<string> {

  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }


  // @Output() doReloadElements = new EventEmitter<boolean>();
  // @Output() doEditElement = new EventEmitter<string>();

  constructor(private annotationService: AnnotationService,
              public session: Session,
              private geojsonService: GeojsonService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private masterDataService: MasterDataService,
              private spinnerService: SpinnerService,
              private mapService:MapService,
              private toastService: ToastService,
              private panelService: PanelService,
              private viewerService: ViewerService) {
  }
  data: string;

  element: ObjectElementModel = null;
  elementIcon: string;
  elementImage: string;

  _isVisible: boolean = false;
  onEdition: boolean = false;

  lowQuality: boolean = false;
  mediumQuality: boolean = false;
  highQuality: boolean = false;

  yearBuild="";
  builder="";
  filmClass="";
  implementType="";
  anchorType="";
  size="";
  heightSp="";

  showQualification=true;
  showMetrics= true;

  showLong: string;
  showLat: string;

  openLocCode:string;
  qualityList: ListValueModel[];

  ngOnInit() {
    this.masterDataService.getList(ListCodeEnum.QUALITY_THRESHOLD).subscribe((response: ListCollectionModel) => {
      if (response) {
        this.qualityList = response.lists[0].listValues;
      }
    });
    this.selectedElement = this.data;
  }

  ngOnDestroy(): void {
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if (show) {
      this.onEdition = false;
    } else {
      this.panelService.close();
    }
  }

  @Input ()
  set selectedElement(elementIdentifier: string) {
    if (elementIdentifier !== undefined) {
      this.annotationService.getObjectElement(elementIdentifier).subscribe( (objectElement: ObjectElementModel) => {
        this.element = objectElement;

        let openLocCode = new OpenLocationCode();
        this.showLat= this.element.elementGeometry.coordinates[1];
        this.showLong = this.element.elementGeometry.coordinates[0];
        //its possible to have more precise data if we add 11 as third parameter
        this.openLocCode = openLocCode.encode(this.element.elementGeometry.coordinates[0], this.element.elementGeometry.coordinates[1]);


        if (this.element.elementClassDetail) {
          this.elementIcon = environment.urlMedia + this.element.elementClassDetail.classIconFilePath;
        } else {
          this.elementIcon = undefined;
        }

        if (StringUtils.isNotEmpty(this.element.elementStorageFilePath)) {
          this.elementImage = environment.urlStorage + this.element.elementStorageFilePath;
        } else {
          this.elementImage = undefined;
        }

        if (this.element.elementProperties['qualification'] != undefined) {
          this.yearBuild = this.element.elementProperties['qualification']['year_build'];
          this.builder = this.element.elementProperties['qualification']['builder'];
          this.filmClass = this.element.elementProperties['qualification']['film_class'];
          this.anchorType = this.element.elementProperties['qualification']['anchor_type'];
          this.implementType = this.element.elementProperties['qualification']['implement_type'];

          this.showQualification = true;
        } else {
          this.showQualification = false;
        }

        if (this.element.elementProperties['metrics'] != undefined) {
          this.size = this.element.elementProperties['metrics']['size'];
          this.heightSp = this.element.elementProperties['metrics']['height_sp'];

          this.showMetrics = true;
        } else {
          this.showMetrics = false;
        }
      });
      this.show();
    } else {
      this.show(false);
    }
  }

  setNewElementQuality(e) {
    this.element = e;
    this.spinner.addOperation("ViewBarrierComponent.updateElement");

    this.annotationService.updateObjectElement(this.element).subscribe( response => {
      if (response) {
        // this.doReloadElements.emit(true);
      }
      this.spinner.removeOperation("ViewBarrierComponent.updateElement");

    });
  }

}
