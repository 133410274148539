import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from "../globals/session";
import {ConnectionOriginEnum} from "../enums/connection-origin-enum";
import {DoneResponseModel} from "../models/done-response-model";

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private session: Session, private httpService: HttpClient) {
  }

  resetPassword(): Observable<DoneResponseModel> {
    const url = environment.urlSecurityResetPassword;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_CITYMAP,
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      username: this.session.currentSession.user.userName
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError(() => throwError('Closing session not possible'))
    );
  }

  updateUserGeo(user, geometry): Observable<DoneResponseModel> {
    const url = environment.urlSecurityUpdateUser;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_CITYMAP,
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      username: user.userName,
      roles: user.userRoles,
      geometry: {
        type: geometry.type,
        coordinates: geometry.coordinates
      }
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError(() => throwError('Setting default zone not possible'))
    );
  }

}
