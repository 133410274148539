import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ChangeContext, Options} from 'ng5-slider';
import {Subscription} from 'rxjs';
import {Session} from 'src/app/globals/session';
import {ElementClassModel} from 'src/app/models/masterData/element-class-model';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {environment} from 'src/environments/environment';
import {HeliosStoreService} from '../helios-store.service';
import {TranslateService} from "@ngx-translate/core";
import Matomo from "../../../../../stats";

@Component({
  selector: 'app-helios-entry-panel',
  templateUrl: './helios-entry-panel.component.html',
  styleUrls: ['./helios-entry-panel.component.css']
})
export class HeliosEntryPanelComponent implements OnInit, PanelBase<any> {

  get map(){
    return this.mapService.getMap();
  }

  @Output() showMenuHelios = new EventEmitter<boolean>();


  constructor(private session: Session,
              private mapService:MapService,
              private panelService: PanelService,
              private translate: TranslateService,
              private heliosStore: HeliosStoreService){ }
  data: any;

  selectedNetworks: string[] = [];

  environment = environment;
  subscriptions: Subscription[] = [];
  countSelected: number;

  classList: ElementClassModel[] = [];
  classGroups: string[] = [];

  _isShowElement: boolean = false;
  _isVisible: boolean = true;
  isRelevant: boolean = true;

  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear ;
  typeHeliosWorks : any[] = [];
  selectedTypeHeliosWorks: string;
  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };

  ngOnInit() {
    this.heliosStore.useProxy(this);

    this.countSelected = 0;
    this.subscriptions.push(this.session.onChangeWorkspace.subscribe(geometry => {
      this.refresh();
    }));

    this.translate.get('common.roadMarkings').subscribe((response: string) => {
      this.typeHeliosWorks.push({name: response, flag: 'HORIZONTALE.png', class: 'HORIZONTALE'});
    })
    this.translate.get('common.implantation').subscribe((response: string) => {
      this.typeHeliosWorks.push({name: response, flag: 'IMPLANTATION.png', class: 'IMPLANTATION'});
    })
    this.translate.get('common.signPosts').subscribe((response: string) => {
      this.typeHeliosWorks.push({name: response, flag: 'VERTICALE.png', class:'VERTICALE'});
    })
    this.translate.get('common.signing').subscribe((response: string) => {
      this.typeHeliosWorks.push({name: response, flag: 'BALISAGE.png', class: 'BALISAGE'});
    })
    this.showElement();
  }

  showElement(){
    const state = this.heliosStore.buildStateFromComponent(this)
    state._isShowElement = true;
    this.heliosStore.state = state;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  refresh() {
    this.heliosStore.refresh();
  }

  clear() {
    // // Reset filters
    this.heliosStore.reset();
    this.show(false)
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if(!show){
      this.panelService.pop();
      Matomo.push(['trackEvent', 'Actions', 'Removed helios'])
    }
  }
  updateYearValues($event: ChangeContext) {
    this.selectedYearMinValue = $event.value;
    this.selectedYearHighValue = $event.highValue;
    this.showElement();
  }
}
