import {Injectable} from '@angular/core';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {EventCodeEnum} from 'src/app/enums/event-code-enum.enum';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {WindowEventHelper} from 'src/app/helpers/window-event.helper';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MasterDataService} from 'src/app/services/master-data.service';
import {ProcessingService} from 'src/app/services/processing.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {GroundState, GroundStoreService} from './ground-store.service';
import {GroundViewPanelComponent} from './ground-view-panel/ground-view-panel.component';
import {StatsStoreService} from "../../../stats/stats-store.service";
import {MapImageEnum} from "../../../../enums/map-image-enum.enum";

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GroundService {
  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }

  subscriptions = []
  clickBound = false;

  constructor(
    private processingService: ProcessingService,
    private geojsonService: GeojsonService,
    private masterdataService: MasterDataService,
    private mapService:MapService,
    private toastService: ToastService,
    private panelService: PanelService,
    private spinnerService: SpinnerService,
    private statsStore: StatsStoreService,
    private groundStore: GroundStoreService) {
      this.groundStore.applyAndWatch(this.showElementWrapper.bind(this))
    }

    showElementWrapper(groundState: GroundState){
      this.mapService.callWhenAvailable((_)=>this.showElement(groundState));
    }

    showElement(groundState: GroundState) {
      this.map.removeLayer(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_GROUND, MapSourceEnum.SOURCE_ELEMENT);

      if (groundState._isShowElement === true) {
        const state = this.statsStore.buildStateFromComponent(this);
        state._isRefreshed = false;
        this.statsStore.state = state;

        this.spinner.addOperation("MainMenuComponent.showElementGround");

        this.map.removeLayer(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_GROUND, MapSourceEnum.SOURCE_ELEMENT);

        let excludeElementNames = [];
        if (groundState.isElementRemoved) {
          excludeElementNames = ['USELESS'];
        } else {
          excludeElementNames = undefined;
        }

        // TODO includes class filter and exclude filter
        let tileUrl = environment.urlTilesElements;
        tileUrl = tileUrl + '?elementType=' + ElementTypesEnum.ELEMENT_TYPE_GROUND;
        tileUrl = tileUrl + '&isPrimary=' + true
          + '&isRelevant=' + groundState.isRelevant + '&isConfirmed=' + groundState.isRelevant + '&elementQualityMin=' + groundState.selectedMinQuality
          + '&elementQualityMax=' + groundState.selectedHighQuality  + '&excludeCategories=' + groundState.excludeCategories[0];

        tileUrl = tileUrl + '&elementDateBegin=' + groundState.selectedYearMinValue + '&elementDateEnd=' + groundState.selectedYearHighValue

        const classSelected = groundState.classSelected;
        if (classSelected !== undefined) {
          Object.keys(classSelected)
          .map(key=>classSelected[key])
          .forEach(element => {
            tileUrl = tileUrl + '&elementClassList=' + element.elementClass;
          });
        }

        if (groundState.selectedNetworks.length > 0) {
          groundState.selectedNetworks.forEach((network: string) => {
            if (network.length > 0) {
              tileUrl = tileUrl + '&elementNetworkList=' + network;
            }
          });
        }

        let layout = {
          "icon-image": "{element_class}",
          "icon-size": 0.25,
          "icon-rotate": ["get", "bearing"],
          "icon-rotation-alignment": "map",
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
        };

        this.groundStore.state.callUrl = tileUrl;

        this.map.addSymbolLayerTiles(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_GROUND, tileUrl, MapSourceEnum.SOURCE_ELEMENT, false, layout, ElementTypesEnum.ELEMENT_TYPE_GROUND);
        this._onLayerViewElement(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_GROUND, MapSourceEnum.SOURCE_ELEMENT);


        if(!this.clickBound) {
          this.map.map.on("click", MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_GROUND, e => {
            e.preventDefault();

            const layout = {
              'icon-image': MapImageEnum.ICON_CIRCLE,
              'icon-size': 0.75
            }
            this.map.addSymbolLayer(MapLayerEnum.LAYER_CIRCLE, MapSourceEnum.SOURCE_ELEMENT + MapSourceEnum.SOURCE_CIRCLE, layout,
              this.map.createSymbolLayerDataFromMapboxFeatures(e.features[0]));

            this.viewElement(e.features[0].properties['element_identifier']);
          });
          this.clickBound = true;
        }

        this.spinner.removeOperation("MainMenuComponent.showElementGround");
        WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_GROUND_LOADED);

      } else {
        this.map.removeLayer(MapLayerEnum.LAYER_ELEMENT + ElementTypesEnum.ELEMENT_TYPE_GROUND, MapSourceEnum.SOURCE_ELEMENT);

        WindowEventHelper.sendEvent(EventCodeEnum.ELEMENTS_GROUND_LOADED);
      }

      // this.show(false);

      // Google Analytics
      if (environment.googleTracking) {
        gtag('event', 'ShowGroundElement');
      }
    }

    _onLayerViewElement(layer, source) {
      this.map.clickLayerMap(layer);
      this.subscriptions.push(this.map.onClickLayerMap.subscribe(e => {
        let feature = this.map.map.queryRenderedFeatures(e.point)?.find(e=>e===source);
        if(feature){
          this.viewElement(feature.properties.elementIdentifier);
        }
      }));

    }

    viewElement(elementIdentifier: string) {

      if (elementIdentifier !== undefined) {
        this.panelService.open(
          GroundViewPanelComponent,
          elementIdentifier
        )
        // this.show(false);
      }
      // Google Analytics
      if (environment.googleTracking) {
        gtag('event', 'ViewGroundElement');
      }
    }

  }
