import { AfterViewInit, Component, Input, OnInit    } from '@angular/core';
import { FormBuilder, FormGroup, Validators         } from '@angular/forms';
import { SpinnerComponent                           } from "../spinner/spinner.component";
import { ToastsComponent                            } from "../toasts/toasts.component";
import { ModelingService                            } from "../../../services/modeling.service";
import { FeatureCollectionModel                     } from "../../../models/geojson/feature-collection-model";
import { Session                                    } from "../../../globals/session";
import { MapComponent                               } from "../../map/map.component";
import { GeometryModel                              } from "../../../models/geojson/geometry-model";
import { environment                                } from "../../../../environments/environment";
import { PanelService                               } from "../../../shared/panel/panel.service";
import { SearchEntryPanelComponent                  } from "./search-entry-panel/search-entry-panel.component";
import { SearchService                              } from "./search.service";
import { SearchStoreService                         } from "./search-store.service";
import Matomo from "../../../stats";

declare let gtag: Function;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, AfterViewInit {

  @Input() spinner: SpinnerComponent;
  @Input() map: MapComponent;
  @Input() toast: ToastsComponent;

  constructor(private session: Session,
              private formBuilder: FormBuilder,
              private panelService: PanelService,
              private searchService: SearchService,
              public searchStore: SearchStoreService,
              private modelingService: ModelingService) {
  }

  searchForm: FormGroup;

  isSearching: boolean = false;

  i = 0;
  resultCollection: FeatureCollectionModel = new FeatureCollectionModel();
  resultCollectionNominatim: any;
  filteredResults: string[];
  temp: string[];
  displayHistory: boolean;
  searchHistory: any[] = [];
  historyResults: any[];
  objFilteredResults: any[];
  countryCode: string = "";
  searchText: string;
  resultGeo: GeometryModel = new GeometryModel();

  ngOnInit() {

    this.searchForm = this.formBuilder.group({
      searchText: ['', Validators.required]
    });
    Object.keys(this.searchStore.state).forEach(key => {
      this[key] = this.searchStore.state[key];
    });

    let localSearchHistory = localStorage.getItem('searchHistory');

    if (localSearchHistory !== null) {
      this.searchHistory = JSON.parse(localSearchHistory);
    }

    const state = this.searchStore.buildStateFromComponent(this);
    state.isDrawnLocationMarker = false;
    this.searchStore.state = state;
  }

  ngAfterViewInit(): void {
    if (this.searchHistory.length > 0) {
      const parent = document.getElementById('searchNominatim');
      const nominatimInput = parent.querySelector('.ui-autocomplete-input');
      const arr = Array.from(document.getElementsByTagName('input'));
    }
  }

  search(event) {
    this.displayHistory = false;
    if (this.isSearching === false) {
      this.isSearching = true;
      this.spinner.addOperation("SearchComponent.search");

      this.modelingService.getNominatim(event.query).subscribe((response) => {
        this.resultCollectionNominatim = response;
        this.filteredResults = [];
        this.objFilteredResults = [];

        this.resultCollectionNominatim.forEach(result => {
          const type = result.display_name + ', ' + result.type;
          result.newName = type;

          this.filteredResults.push(type);
          this.temp = this.filteredResults;
          this.objFilteredResults.push(result);
        });
        this.isSearching = false;
        this.spinner.removeOperation("SearchComponent.search");
      });
    }

    // Google Analytics
    if (environment.googleTracking) {
      gtag('event', 'Search');
    }
  }

  history() {
    this.displayHistory = true;
    let localSearchHistory = localStorage.getItem('searchHistory');

    if (localSearchHistory !== null) {
      let temp = JSON.parse(localSearchHistory);
      this.filteredResults = [];
      this.objFilteredResults = [];
      temp.forEach(historyEntry => {
        this.filteredResults.push(historyEntry.historyName);
        this.objFilteredResults.push(historyEntry);
      })
    }
  }

  searchSelect(event) {
    this.objFilteredResults.forEach(result => {
      if (result.newName === event) {
        Matomo.push(['trackEvent', 'Search nominatim', event])

        let historyName = result.display_name.split(",");
        let historyEntry: any = {};
        historyEntry.historyName = historyName[0] + ", " + result.type;
        historyEntry.type = result.geojson.type;
        historyEntry.coordinates = result.geojson.coordinates;
        historyEntry.nominatimIdentifier = result.place_id;

        this.searchText                 = result.display_name;
        const state                     = this.searchStore.buildStateFromComponent(this);
        state.searchText                = this.searchText;
        state.drawnGeometry             = new GeometryModel();
        state.drawnGeometry.type        = result.geojson.type;
        state.drawnGeometry.coordinates = result.geojson.coordinates;


        this.searchStore.state          = state;
        let localSearchHistory = localStorage.getItem('searchHistory');

        if (localSearchHistory !== null) {
          this.searchHistory = JSON.parse(localSearchHistory);
        }

        this.checkArrayHistory(historyEntry);

        localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory));

        this.resultGeo.type = result.geojson.type;
        this.resultGeo.coordinates = result.geojson.coordinates;
        this.session.setWorkspace(this.resultGeo);
      } else if (result.historyName === event) {
        Matomo.push(['trackEvent', 'Search by history', event])
        let localSearchHistory = localStorage.getItem('searchHistory');
        if (localSearchHistory !== null) {
          this.searchHistory = JSON.parse(localSearchHistory);
        }
        this.checkArrayHistory(result);
        this.resultGeo.type = result.type;
        this.resultGeo.coordinates = result.coordinates;
        const state = this.searchStore.buildStateFromComponent(this);
        state.drawnGeometry = new GeometryModel();
        state.drawnGeometry = this.resultGeo;
        this.searchStore.state = state;
        this.session.setWorkspace(this.resultGeo);
      }
    });

  }

  checkArrayHistory(historyEntryToCheck: any) {
    let i = 0;
    if (this.searchHistory.length > 0) {
      this.searchHistory.forEach(searchHistoryEntry => {
        if (searchHistoryEntry.historyName === historyEntryToCheck.historyName) {
          this.searchHistory.splice(i, 1);
          this.searchHistory.unshift(historyEntryToCheck);
        } else if (this.searchHistory.length - 1 === i) {
          this.searchHistory.unshift(historyEntryToCheck);
          this.searchHistory = [...new Set(this.searchHistory)]; //renvoie un array sans doublon
        }
        i++;
      })
    } else {
      this.searchHistory.unshift(historyEntryToCheck);
    }
    if (this.searchHistory.length > 5) {
      this.searchHistory.pop();
    }
  }

  clearItem(autocomplete: any) {
    autocomplete.value = '';
    autocomplete.handleDropdownClick();
  }

  eraseHistoryEntry(event, filteredResult) {
    event.stopPropagation();
    let i = 0;
    this.searchHistory.forEach(historyEntry => {
      if (historyEntry.historyName === filteredResult) {
        this.searchHistory.splice(i, 1);
      }
      i++;
    });
    localStorage.removeItem('searchHistory');
    localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory));
    this.history();
  }


  openMenuSearch() {
    this.panelService.push(
      SearchEntryPanelComponent,
      null
    )
  }

}
