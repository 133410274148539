import {ISerializable} from '../iserializable';
import {StatusModel} from "./status-model";

export class StatusListModel implements ISerializable {

  public status: StatusModel[];


  deserialize(input: any): this {
    Object.assign(this, input);

    this.status = input.status.map(status => new StatusModel().deserialize(status));
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
