import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {Session} from 'src/app/globals/session';
import {ComputeVisibilityHelper} from 'src/app/helpers/compute-visibility.helper';
import {FeatureHelper} from 'src/app/helpers/feature-helper';
import {FeatureCollectionModel} from 'src/app/models/geojson/feature-collection-model';
import {GeometryModel} from 'src/app/models/geojson/geometry-model';
import {DatasourceService} from 'src/app/services/datasource.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {VisibilityOnDemandStoreService} from '../visibility-on-demand-store.service';
import Matomo from "../../../../../stats";

declare let gtag: Function;

@Component({
  selector: 'app-visibility-on-demand-entry-panel',
  templateUrl: './visibility-on-demand-entry-panel.component.html',
  styleUrls: ['./visibility-on-demand-entry-panel.component.css']
})
export class VisibilityOnDemandEntryPanelComponent implements OnInit, PanelBase<any> {


  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }


  constructor(private session: Session, private datasourceService: DatasourceService, private computeVisibilityHelper: ComputeVisibilityHelper,              private spinnerService: SpinnerService,
    private mapService:MapService,
    private toastService: ToastService,
    private panelService: PanelService,
    private visibilityOnDemandStore: VisibilityOnDemandStoreService) { }
  data: any;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  _curveProfile: string = 'NONE';

  driverHeight: number = 1.5;
  speedLimit: number = 70;
  wayWidth: number = 6;
  segments: GeometryModel[];

  wayIdentifier: string;

  subscriptions: Subscription[] = []

  ngOnInit() {
    this.subscriptions.push(this.visibilityOnDemandStore.applyAndWatch(_=>this.visibilityOnDemandStore.applyOn(this)))
    let tileUrl = environment.urlTilesWays + 'wayTypeList=A&wayTypeList=N&wayTypeList=E&wayTypeList=D&wayTypeList=R&wayTypeList=S';

    let paint = {
      'line-color': 'rgba(49,161,234,0.3)',
      'line-width': 3,
    };
    let layout = {
      'line-cap': 'round',
      'line-join': 'round'
    };

    this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_SKETCH_WAY, tileUrl, MapSourceEnum.SOURCE_TILE_SEGMENTS, MapSourceEnum.SOURCE_SKETCH_WAY, false, layout, paint);
    this.map.map.on("mouseenter", MapLayerEnum.LAYER_SKETCH_WAY, e => {
      this.map.map.getCanvas().style.cursor = 'pointer'
    });
    this.map.map.on("mouseleave", MapLayerEnum.LAYER_SKETCH_WAY, e => {
      this.map.map.getCanvas().style.cursor = 'default'
    });
    this.map.map.on("click", MapLayerEnum.LAYER_SKETCH_WAY, e => {
      if (this.map.map.getLayer(MapLayerEnum.LAYER_COMPUTE_WAY) === undefined) {

        this.wayIdentifier = e.features[0].properties["way_identifier"];

        let layout = {
          'line-cap': 'round',
          'line-join': 'round'
        };

        let paint = {
          'line-color': '#FF8F3A',
          'line-width': 2.1,
          'line-opacity': .9,
          'line-dasharray': [2, 2]
        };
        this.datasourceService.getMainSegments(this.wayIdentifier).subscribe(resp => {

          let segments = new FeatureCollectionModel();
          segments.features = [];
          resp.features.forEach(feature => {
            if (feature.properties['way_identifiers'][0] === this.wayIdentifier) {
              segments.features.push(feature);
            }
          });
          this.map.addLineLayer(MapLayerEnum.LAYER_SKETCH_WAY_UNIQUE, MapSourceEnum.SOURCE_SKETCH_WAY_UNIQUE, layout, paint, segments);
          this.segments = FeatureHelper.fetchLineStrings(resp.features);

          const nextState = this.visibilityOnDemandStore.buildStateFromComponent(this)
          this.visibilityOnDemandStore.state = nextState;
        });

      }
    });
    this.showElement();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s=>s.unsubscribe())
  }


  showElement(){
    const nextState = this.visibilityOnDemandStore.buildStateFromComponent(this)
    nextState._isShowElement = true;
    this.visibilityOnDemandStore.state = nextState;
  }

  refresh() {
    this.visibilityOnDemandStore.refresh();
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if (!show) {
      this.panelService.pop();
    } else {
      let tileUrl = environment.urlTilesWays + 'wayTypeList=A&wayTypeList=N&wayTypeList=E&wayTypeList=D&wayTypeList=R&wayTypeList=S';

      let paint = {
        'line-color': 'rgba(49,161,234,0.3)',
        'line-width': 3,
      };
      let layout = {
        'line-cap': 'round',
        'line-join': 'round'
      };

      this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_SKETCH_WAY, tileUrl, MapSourceEnum.SOURCE_TILE_SEGMENTS, MapSourceEnum.SOURCE_SKETCH_WAY, false, layout, paint);
      this.visibilityOnDemandStore.state = this.visibilityOnDemandStore.buildStateFromComponent(this);
    }
  }

  clear() {
    // Reset filters
    this.map.removeLayer(MapLayerEnum.LAYER_SKETCH_WAY, MapSourceEnum.SOURCE_SKETCH_WAY);
    this.map.removeLayer(MapLayerEnum.LAYER_SKETCH_WAY_UNIQUE, MapSourceEnum.SOURCE_SKETCH_WAY_UNIQUE);
    this.visibilityOnDemandStore.reset();
    Matomo.push(['trackEvent', 'Actions', 'Removed on demand visibility'])
    this.show(false)
  }

  setSpeedLimit(e) {
    this.speedLimit = e.value;
    this.showElement();
  }
  setDriverHeight(e) {
    this.driverHeight = e.value;
    this.showElement();
  }
  setWayWidth(e) {
    this.wayWidth = e.value;
    this.showElement();
  }
}
