import {ISerializable} from '../iserializable';
import * as moment from 'moment';

export class AlertPropertiesModel implements ISerializable {

  public alertPriority: string;
  public alertType: string;
  public alertIdentifier: string;
  public alertCategory: string;
  public alertDateBegin: string;
  public alertDateEnd: string;
  public alertComment: string;
  public alertActive: boolean;
  public linearLength : number;
  public alertSpeaker: string;
  public alertImpact: string;
  public alertDuration: moment.Duration;
  public alertSpeed: string;
  public speaker: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
