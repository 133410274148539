import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FeatureModel } from '@mycitymagine/ts-citymagine-core';
import { ElementPropertiesModel } from 'src/app/models/geojson/element-properties-model';
import { FeatureObjectElementModel } from 'src/app/models/geojson/feature-object-element-model';
import { SignModel } from 'src/app/models/geojson/sign-model';
import { MapService } from 'src/app/shared/map/map.service';
import { PanelBase } from 'src/app/shared/panel/component/PanelBase';
import { PanelService } from 'src/app/shared/panel/panel.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { HeliosService } from '../helios.service';

@Component({
  selector: 'app-helios-view-panel',
  templateUrl: './helios-view-panel.component.html',
  styleUrls: ['./helios-view-panel.component.css']
})
export class HeliosViewPanelComponent implements OnInit, PanelBase<string>, OnDestroy {

  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }

  @Output() doReloadElements = new EventEmitter<boolean>();

  constructor(              private spinnerService: SpinnerService,
    private mapService:MapService,
    private toastService: ToastService,
    private panelService: PanelService) {
  }
  ngOnDestroy(): void {
  }
  data: string;

  element: FeatureModel = new FeatureModel();
  elementIcon: string;
  elementSign: SignModel[];
  elmProperties: FeatureObjectElementModel = new FeatureObjectElementModel();
  elProperties: ElementPropertiesModel = new ElementPropertiesModel();
  images: any[];

  _isVisible: boolean = false;

  lowQuality: boolean = false;
  mediumQuality: boolean = false;
  highQuality: boolean = false;

  ngOnInit() {
    this.images = [];
    this.setElement(this.data)
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if (!show ) {
      this.map.resizeElementsMarkers();
      this.panelService.close();
    }
  }


  setElement(element) {

    if (element != undefined) {
      this.element = element;
      this.elmProperties = element.properties as FeatureObjectElementModel;
      this.elementIcon = 'assets/images/'+ this.elmProperties.elementCategory.toUpperCase() + '.png';
      let imageTitle ="";
      let imageAlt = "";

      if(this.elmProperties.elementProperties!= undefined){
        this.elProperties = this.elmProperties.elementProperties as ElementPropertiesModel;
        this.elementSign = this.elProperties.signs as SignModel[];
        imageTitle =this.elProperties.numDossier;
        imageAlt = this.elProperties.commentaire.toString();
      }


      this.images = [];
      // let stringFormated = this.elmProperties.elementUrl.substr(13);

      if(this.elmProperties.elementUrl !== undefined){
        console.log(environment.urlStorage + this.elmProperties.elementUrl);
        this.images.push({src: environment.urlStorage + this.elmProperties.elementUrl, alt: imageAlt, title: imageTitle})
        // this.images = this.elProperties.photos.map((e, index) => {
        //   return {source: e.photoUrl, alt: this.elProperties.commentaire.toString() , title: this.elProperties.numDossier+ " " + index}
        // })
        console.log(this.images);
      }

      this.lowQuality = false;
      this.mediumQuality = false;
      this.highQuality = false;
      if (this.elmProperties.elementQuality === 100) {
        this.highQuality = true;
      } else if (this.elmProperties.elementQuality > 25) {
        this.mediumQuality = true;
      } else {
        this.lowQuality = true;
      }
      this.show();
    } else {
      this.show(false);
    }
  }
}
