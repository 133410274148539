import {ISerializable} from "../iserializable";
import {BboxHelper} from "../../helpers/bbox.helper";

export class BboxObject implements ISerializable {

  public x: number;
  public y: number;
  public width: number;
  public height: number;
  public imageWidth: number = 6000;
  public imageHeight: number = 3000;
  public imageOrientation: number;

  constructor() {}

  create(x: number, y: number, width: number, height: number, imageOrientation: number, imageWidth: number, imageHeight: number):BboxObject {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;

    this.imageOrientation = imageOrientation;
    this.imageWidth = imageWidth;
    this.imageHeight = imageHeight;

    return BboxHelper.bboxOrientationCorrection(this);
  }

  toArray(): [number, number, number, number] {
    return [this.x, this.y, this.width, this.height];
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
