import {ISerializable} from "../iserializable";
import {Vector3dModel} from "./vector3d-model";

export class Line3dModel implements ISerializable {

  public start: Vector3dModel;
  public end: Vector3dModel;

  public properties: any;

  constructor(start: Vector3dModel, end: Vector3dModel) {
    this.start = start;
    this.end = end;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toString(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }

  toVector3d(): Vector3dModel[] {
    return [this.start, this.end];
  }

  minX(): number {
    return (this.start.x < this.end.x) ? this.start.x : this.end.x;
  }

  minY(): number {
    return (this.start.y < this.end.y) ? this.start.y : this.end.y;
  }

  maxX(): number {
    return (this.start.x > this.end.x) ? this.start.x : this.end.x;
  }

  maxY(): number {
    return (this.start.y > this.end.y) ? this.start.y : this.end.y;
  }

  addZ(z: number) {
    this.start.addZ(z);
    this.end.addZ(z);
  }

  addY(y: number) {
    this.start.addY(y);
    this.end.addY(y);
  }

  addX(x: number) {
    this.start.addX(x);
    this.end.addX(x);
  }

  insideBbox2d(v: Vector3dModel): boolean {
    return (v.x >= this.minX() && v.x <= this.maxX()
      && v.y >= this.minY() && v.y <= this.maxY());
  }

  clone(): Line3dModel {
    return new Line3dModel(this.start.clone(), this.end.clone());
  }
}
