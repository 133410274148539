import {ISerializable} from '../iserializable';
import {GeometryModel} from "../geojson/geometry-model";

export class UserModel implements ISerializable {

  public userId: string;
  public userName: string;
  public userFirstName: string;
  public userLastName: string;
  public userEmail: string;
  public userLocale: string;
  public userRoles: string[];
  public userGeometry: GeometryModel;
  public userProperties: any;

  deserialize(input: any): this {
    Object.assign(this, input);

    this.userGeometry = new GeometryModel().deserialize(input.userGeometry);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
