<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_CRASH')" appWorkspaceRequired 
    [ngClass]="{'itemChecked':roadFsiStoreService.state._isShowElement, 'itemUnchecked': !roadFsiStoreService.state._isShowElement}" 
    (click)="openMenuRoadFsi()">
    <div class="ui-g-11 ui-g-nopad">
        <i class="fas fa-car-crash"></i>
        <span>{{ 'roadFsiEntry.title' | translate }}</span>
    </div>
    <a role="button" *ngIf="roadFsiStoreService.state._isShowElement" (click)="close($event)" class="ui-g-1 ui-g-nopad">
        <span class="pi pi-times" style="margin:0;"></span>
    </a>
</li>
