import {ISerializable} from "./iserializable";

export class ResultModel implements ISerializable {

  public code: string;
  public values: any;

  constructor(code: string, values: any = {}) {
    this.code = code;
    this.values = values;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toString(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
