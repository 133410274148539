import {ISerializable} from "./iserializable";
import {ResponseLevelEnum} from "../enums/response-level-enum.enum";

export class HeaderModel implements ISerializable {

  public domainCode: string;
  public sessionIdentifier: string;

  public requestDate: Date;
  public requestIdentifier: string;

  public responseDate: Date;
  public responseLevel: ResponseLevelEnum[];

  public limitOffset: number;
  public limitCount: number;
  public totalCount: number;

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
