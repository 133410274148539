import {Injectable} from '@angular/core';
import {Options} from 'ng5-slider';
import {Subscription} from 'rxjs';
import {Store} from 'src/app/globals/store';
import {ElementClassModel} from 'src/app/models/masterData/element-class-model';
import {environment} from 'src/environments/environment';

export class GroundState{

  elementType: string = 'GROUND';
  countSelected: number = 0;

  selectedNetworks: string[] = [];

  productColor: string[] = [];
  productAxis: string[] = [];
  productType: string[] = [];

  environment = environment;
  subscriptions: Subscription[] = [];

  classGroups: string[] = [];
  classSelected: {[key :string]:ElementClassModel} = {};

  isRelevant: boolean = true;
  isElementRemoved: boolean = true;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  selectedMinQuality: number = 0;
  selectedHighQuality: number = 100;

  qualitySliderOptions: Options = {
    floor: 0,
    ceil: 100,
    showOuterSelectionBars: true
  };
  selectedElement: string;

  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear;
  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };
  onCreate: boolean = false;

  excludeCategories: string[] = ['OTHER'];
  excludeElementNames: string[] = [];

  callUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class GroundStoreService extends Store<GroundState>{

  constructor() {
    super(GroundState)
  }
}
