<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_RISK')"
    appWorkspaceRequired
    [ngClass]="roadRiskStore.state._isShowElement ? 'itemChecked':'itemUnchecked'" 
    (click)="openMenuRoadRisk()">
    <div class="ui-g-11 ui-g-nopad">
        <i class="fa-solid fa-road-circle-exclamation"></i>
        <span class="text">{{ 'roadRiskEntry.title' | translate }}</span>
    </div>
    <a role="button" *ngIf="roadRiskStore.state._isShowElement"  (click)="close($event)" class="ui-g-1 ui-g-nopad">
        <span class="pi pi-times" style="margin:0;"></span>
    </a>
</li>
