import {Component, Input, OnInit} from '@angular/core';
import {ObjectElementModel} from "../../../../models/annotation/object-element-model";
import {StringUtils} from "../../../../helpers/string.utils";
import {environment} from "../../../../../environments/environment";
import {ViewTypesEnum} from "../../../../enums/view-types-enum.enum";
import {ViewerStoreService} from "../../main-panel/viewer-entry/viewer-store.service";
import {ViewerService} from "../../../../services/viewer.service";

@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.css']
})
export class CropImageComponent implements OnInit {

  constructor(private viewerStore: ViewerStoreService,
              private viewerService: ViewerService) { }

  _element: ObjectElementModel;
  elementImage: string;
  crops: string[] = [];
  currentImageId: number = 0;
  _viewType: ViewTypesEnum;

  @Input()
  set viewType(viewType: ViewTypesEnum) {
    this._viewType = viewType;
  }

  @Input()
  set element(element: ObjectElementModel){
    this._element = element;
    if (StringUtils.isNotEmpty(this._element.elementStorageFilePath)) {
      this.elementImage = environment.urlStorage + this._element.elementStorageFilePath;
    } else {
      this.elementImage = undefined;
    }

    this.crops[0] = this.elementImage;
    if (this.elementImage !== undefined) {
      this.checkImage(this.elementImage.slice(0, this.elementImage.length - 3) + "medium.jpg", () => {
        this.crops[1] = this.elementImage.slice(0, this.elementImage.length - 3) + "medium.jpg";
      }, () => {});
      this.checkImage(this.elementImage.slice(0, this.elementImage.length - 3) + "large.jpg", () => {
        this.crops[2] = this.elementImage.slice(0, this.elementImage.length - 3) + "large.jpg";
      }, () => {});
    }
  }

  ngOnInit(): void {
  }

  checkImage(url, good, bad) {
    let img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = url;
  }

  zoomCrop(i: number) {
    if (this.crops.length > 1) {
      this.currentImageId += i;
      this.elementImage = this.crops[this.currentImageId];
    }
  }

  showViewer() {
    this.viewerStore.state = {
      isShowViewer: true,
      isShowGoogle: false,
      viewIdentifier: this._element.viewIdentifier,
      bbox: this._element.viewBbox,
      mode: this._viewType
    }
  }

  showViewerNewElement() {
    this.viewerService.findNearestCapturePoint(this.element.elementGeometry, 15).subscribe(response => {
      let view = response.views.find(view => view.viewType === this._viewType);
      if (view) {
        this.viewerStore.state = {
          isShowViewer: true,
          isShowGoogle: false,
          viewIdentifier: view.viewIdentifier,
          mode: this._viewType
        }
      }
    });
  }
}
