import {Observable, throwError} from "rxjs";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {HttpHeadersEnum} from "../enums/http-headers-enum.enum";
import {ContentTypesEnum} from "../enums/content-types-enum.enum";
import {catchError, map} from "rxjs/operators";
import {EventEmitter, Injectable, Output} from "@angular/core";
import {Session} from "../globals/session";
import {StatisticCollectionModel} from "../models/statistic/statistic-collection-model";
import {RequestHelper} from "../helpers/request.helper";
import {ExportModel} from "../models/statistic/export-model";
import {ConnectionOriginEnum} from "../enums/connection-origin-enum";
import {GroundState} from "../components/menu/main-panel/ground-entry/ground-store.service";
import {SignState} from "../components/menu/main-panel/sign-entry/sign-store.service";
import {LaneState} from "../components/menu/main-panel/lane-entry/lane-store.service";
import {StreetlightState} from "../components/menu/main-panel/streetlight-entry/streetlight-store.service";
import {FurnitureState} from "../components/menu/main-panel/furniture-entry/furniture-store.service";
import {BankState} from "../components/menu/main-panel/bank-entry/bank-store.service";
import {BarrierState} from "../components/menu/main-panel/barrier-entry/barrier-store.service";
import {RiverSignState} from "../components/menu/main-panel/river-sign-entry/river-sign-store.service";
import {GeometryModel} from "../models/geojson/geometry-model";

@Injectable({
  providedIn: 'root'
})
export class StatisticService {

  constructor(private session: Session, private requestHelper: RequestHelper, private httpService: HttpClient) {
  }

  errorMessage: any;
  @Output() ErrorMessage: EventEmitter<any> = new EventEmitter();

  getStatisticByElementType(elementState: GroundState | SignState | LaneState | StreetlightState | FurnitureState
    | BankState | BarrierState | RiverSignState, elementGeometry?: GeometryModel, pricingGrid?: string): Observable<StatisticCollectionModel> {
    let url = environment.urlGetStatisticElements;

    let elementClassList: string[];
    if (!(elementState instanceof StreetlightState)) {
      const classSelected = elementState.classSelected;
      if (classSelected !== undefined) {
        elementClassList = Object.keys(classSelected);
      }
    }

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)

    const options = {headers};

    return this.httpService.post(url, {
      elementType: [elementState.elementType],
      elementNetwork: !(elementState instanceof StreetlightState) && elementState.selectedNetworks.length > 0 ? elementState.selectedNetworks : undefined,
      elementClass: !(elementState instanceof StreetlightState) && elementClassList.length > 0 ? elementClassList : undefined,
      elementQualityMin: elementState.selectedMinQuality,
      elementQualityMax: elementState.selectedHighQuality,
      excludeElementNames: elementState.excludeElementNames.length > 0 ? elementState.excludeElementNames : undefined,
      excludeElementCategories: elementState.excludeCategories.length > 0 ? elementState.excludeCategories : undefined,
      relevant: true,
      confirmed: true,
      primary: true,
      elementDateBegin: new Date(elementState.selectedYearMinValue, 0, 1).toISOString(),
      elementDateEnd: new Date(elementState.selectedYearHighValue, 11, 31).toISOString(),
      elementGeometry,
      pricingGrid,
    }, options).pipe(map(data => new StatisticCollectionModel().deserialize(data)));
  }

  getStatisticExport(activityIds: string[], format: string): Observable<ExportModel> {
    let url = environment.urlGetExportStatisticElements;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)

    const options = {headers};

    return this.httpService.post(url, {
      activityIds,
      format
    }, options).pipe(map(data => new ExportModel().deserialize(data)));
  }
}
