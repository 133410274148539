export enum ElementTypesEnum {
  ELEMENT_TYPE_SIGN = 'SIGN',
  ELEMENT_TYPE_RIVER_SIGN = 'RIVER',
  ELEMENT_TYPE_FURNITURE = 'FURNITURE',
  ELEMENT_TYPE_BARRIER = 'BARRIER',
  ELEMENT_TYPE_GROUND = 'GROUND',
  ELEMENT_TYPE_LANE = 'LANE',
  ELEMENT_TYPE_BANK = 'BANK',
  ELEMENT_TYPE_CRASH = 'CRASH',
  ELEMENT_TYPE_TRASH = 'TRASH',
  ELEMENT_TYPE_HELIOS= 'HELIOS_MAP',
  ELEMENT_TYPE_ALERT= 'ALERT',
  ELEMENT_TYPE_TRAVAUX= 'TRAVAUX',
  ELEMENT_TYPE_STREETLIGHT= 'STREETLIGHT',
  ELEMENT_TYPE_SIGN_FR = 'sign-fr',
  ELEMENT_TYPE_REFLECTO = 'REFLECTO',
  ELEMENT_TYPE_POSITION = "POSITION",
  ELEMENT_TYPE_REFLECTO_RED = 'REFLECTO_RED',

}
