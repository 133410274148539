import { Injectable } from '@angular/core';
import { MapService } from 'src/app/shared/map/map.service';
import { BackgroundDetailsState, BackgroundDetailsStoreService } from './background-details-store.service';

@Injectable({
  providedIn: 'root'
})
export class BackgroundDetailsService {

  constructor(private backgroundDetailsStore: BackgroundDetailsStoreService,
              private mapService:MapService) {
    this.backgroundDetailsStore.applyAndWatch(this.showBackgroundDetails.bind(this))
  }

  showBackgroundDetails(state: BackgroundDetailsState){
    this.mapService.callWhenAvailable(map=>{
      map.showBackgroundDetails(state.isShowBackgroundDetail);
    })
  }


}
