import {Component, OnInit} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Session} from 'src/app/globals/session';
import {SessionModel} from 'src/app/models/session/session-model';
import {DomainService} from 'src/app/services/domain.service';
import {SecurityService} from 'src/app/services/security.service';
import {SessionService} from 'src/app/services/session.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {environment} from 'src/environments/environment';
import {TranslateService} from "@ngx-translate/core";
import jwtDecode from 'jwt-decode'
import * as moment from 'moment'

declare let gtag: Function;

@Component({
  selector: 'app-user-entry-panel',
  templateUrl: './user-entry-panel.component.html',
  styleUrls: ['./user-entry-panel.component.css']
})
export class UserEntryPanelComponent implements OnInit, PanelBase<any> {

  data: any;
  currentDomain: number;
  defaultDomain: number;
  currentLocale: string;
  domain;
  currentSession: SessionModel = this.session.currentSession;
  domainList:{label:string,value:number,icon?:string}[]  = []
  localeList:{label:string, value: string}[] = [
    {label: 'userEntry.en', value: 'en'},
    {label: 'userEntry.fr', value: 'fr'},
    {label: 'userEntry.sv', value: 'sv'},
    {label: 'userEntry.es', value: 'es'},
    {label: 'userEntry.de', value: 'de'},
    {label: 'userEntry.it', value: 'it'},
    {label: 'userEntry.pt-br', value: 'pt-br'}
  ]
  loading = false;

  constructor(public session: Session,
              private authService: AuthService,
              private confirmationService: ConfirmationService,
              private domainService: DomainService,
              private messageService: MessageService,
              private panelService: PanelService,
              private securityService: SecurityService,
              private sessionService: SessionService,
              private spinnerService: SpinnerService,
              private translationService: TranslateService) {

    this.currentLocale = window.localStorage.getItem('locale');
  }


  ngOnInit() {
    this.domainService.getDefaultDomain().subscribe(d => {
    this.defaultDomain = d.defaultDomain
    const currentDomainFromToken = jwtDecode(this.session.token)['https://www.citymagine.com/domainId'] || 1;
      const domains = this.session.domains;
      if(currentDomainFromToken){
        this.currentDomain = domains.domains.find(p => p.domainId == currentDomainFromToken).domainId;
      } else{
        this.currentDomain = domains.domains.find(p => p.domainId == this.defaultDomain).domainId;
      }
      this.domain = domains.domains.find(p=>p.domainId == this.currentDomain).domainName;
      this.domainList = domains.domains.map(d => ({ value: d.domainId,label: d.domainName }));
    })

  }

  show(show: boolean = true) {
    if(!show){
      this.panelService.pop();
    }
  }

  setCurrentDomain(event){
    this.loading = true;
    this.domainService.setCurrentDomain(event.value).subscribe(res => {
      this.authService.getAccessTokenSilently({audience:environment.auth.audience, ignoreCache: true})
        .subscribe(_res => {
          window.location.reload();
        });
    }, (_) => this.loading = false)
  }

  logOut() {
    let getUrl = window.location;
    let baseUrl = getUrl.protocol + "//" + getUrl.host;
    this.authService.logout({
      returnTo: baseUrl,
    })
  }

  setLocale($event: any) {
    this.translationService.use($event);
    moment.locale($event);
    window.localStorage.setItem('locale',$event);
  }


  get currentDomainLabel(){
    return this.domainList.find(p=>p.value == this.currentDomain).label;
  }

  get spinner(){
    return this.spinnerService.getSpinner();
  }
}
