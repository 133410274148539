<li class="listMenu"
[ngClass]="{'itemChecked':isShowBackground, 'itemUnchecked': !isShowBackground}" (click)="toggleMenuBackground()">
<i class="fa fa-globe"></i>
<span>{{ 'backgroundEntry.title' | translate }}</span>
</li>
<li class="listMenu" *ngIf="isShowBackground">
<p-dropdown [options]="backgroundOptions"
          (onChange)="setBackground($event.value.type)"
          [(ngModel)]="selectedBackground"
          optionValue="type"
          optionLabel="name"
          styleClass="ui-large"></p-dropdown>
</li>
