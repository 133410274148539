import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ElementTypesEnum} from "../../../enums/element-types-enum.enum";
import {ObjectElementModel} from "../../../models/annotation/object-element-model";
import {SpinnerComponent} from "../../main/spinner/spinner.component";
import {environment} from "../../../../environments/environment";
import {ClassModel} from "../../../models/processing/class-model";
import {AnnotationService} from "../../../services/annotation.service";
import {ProcessingService} from "../../../services/processing.service";
import {ClassCollectionModel} from "../../../models/processing/class-collection-model";

@Component({
  selector: 'app-element-class-selector',
  templateUrl: './element-class-selector.component.html',
  styleUrls: ['./element-class-selector.component.css']
})
export class ElementClassSelectorComponent implements OnInit {

  @Input() spinner: SpinnerComponent;

  @Output() ngModelChange = new EventEmitter<ObjectElementModel>();
  @Input()
  set ngModel(selectedElement: ObjectElementModel) {
    this._selectedElement = selectedElement;

    this._elementType = selectedElement.elementType;
    if (this._elementType == ElementTypesEnum.ELEMENT_TYPE_SIGN) {
      this._datasetType = "SIGN.FR";
      this._iconFolder = "sign-fr"
    } else if (this._elementType == ElementTypesEnum.ELEMENT_TYPE_GROUND) {
      this._datasetType = "GROUND";
      this._iconFolder = "ground"
    } else if (this._elementType == ElementTypesEnum.ELEMENT_TYPE_TRASH) {
      this._datasetType = "TRASH";
      this._iconFolder = "trash"
    }
  }
  get ngModel():ObjectElementModel {
    return this._selectedElement;
  }
  _selectedElement: ObjectElementModel;

  environment = environment;

  _elementType: string;
  _datasetType: string;
  _iconFolder: string;

  classList: ClassModel[] = [];
  classGroups: string[] = [];
  classSelected: ClassModel;

  _isVisible: boolean = true;
  _showClass: boolean = false;

  constructor(private annotationService: AnnotationService,
              private processingService: ProcessingService) { }

  ngOnInit() {

  }

  show(show:boolean = true) {

    this._isVisible = show;
  }


  loadClasses() {
    this.spinner.addOperation("ElementClassSelectorComponent.loadClasses");

    this.processingService.getAnalysisClass(this._datasetType).subscribe((response: ClassCollectionModel) => {
      if (response && response.classList.length > 0) {

        this.classList = response.classList;

        this.classList.forEach(item => {
          if (this.classGroups.indexOf(item.classGroup) === -1) {
            this.classGroups.push(item.classGroup);
          }
        });

      } else {
      }

      this.spinner.removeOperation("ElementClassSelectorComponent.loadClasses");
    });
  }

  onSelectClass(classElement: ClassModel) {
    this.classSelected = classElement;
    this._selectedElement.elementName = classElement.classLabel;
    this._selectedElement.elementClass = classElement.classCode;
    this._selectedElement.elementCategory = classElement.classGroup;
    this.ngModelChange.emit(this._selectedElement);

    this._showClass = false;
  }

  toggleShowClass(){
    this._showClass = !this._showClass;

    if (this._showClass === true
      && this.classList.length === 0) {
      this.loadClasses();
    }
  }
}
