import {Injectable} from '@angular/core';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {MapService} from 'src/app/shared/map/map.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import {ConformityState, ConformityStoreService} from './conformity-store.service';

@Injectable({
  providedIn: 'root'
})
export class ConformityService {

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }

  constructor(private spinnerService: SpinnerService,
              private mapService: MapService,
              private toastService: ToastService,
              private conformityStore: ConformityStoreService) {
    this.conformityStore.applyAndWatch(this.searchWrapper.bind(this))
  }

  searchWrapper() {
    this.mapService.callWhenAvailable(_ => this.search())
  }

  search() {
    let nextState = this.removeNotAnymoreSelectedElements();
    nextState = this.showSelectedElements(nextState);
    this.conformityStore.state = nextState;
  }

  removeNotAnymoreSelectedElements() {
    const nextState = this.conformityStore.state;
    nextState.activatedConformityElements.map(v => v.value)
      .filter(v => !nextState.selectedConformityValues.includes(v))
      .forEach(verify => {
        const el = nextState.conformityElementsIndex[verify]
        if (el.showing) {
          this.map.removeLayer(el.layerName);
          el.showing = false;
        }
      });
    nextState.activatedDangerElements.map(v => v.value)
      .filter(v => !nextState.selectedDangerValues.includes(v))
      .forEach(verify => {
        const el = nextState.dangerElementsIndex[verify]
        if (el.showing) {
          this.map.removeLayer(el.layerName);
          el.showing = false;
        }
      });
    return nextState;
  }

  showSelectedElements(nextState: ConformityState) {
    nextState.selectedConformityValues.map(v => nextState.conformityElementsIndex[v])
      .filter(v => v && !v.showing)
      .forEach(v => {
        v.showing = true;
        switch (v.value) {
          case "curves":
            this.showCurves();
            break
        }
      });

    nextState.selectedDangerValues.map(v => nextState.dangerElementsIndex[v])
      .filter(v => v && !v.showing)
      .forEach(v => {
        v.showing = true;
        switch (v.value) {
          case "glare":
            this.showGlare();
            break;
          case "narrowRoads":
            this.showNarrowRoad();
            break;
          default:
            break;
        }
      });
    return nextState;
  }

  showCurves() {
    this.spinner.addOperation("MenuConformityComponent.showCurves");

    let tileUrl = environment.tilesCurveServer;

    let layout = {
      'line-cap': 'round',
      'line-join': 'round'
    };

    let paint = {
      'line-color': [
        "match",
        ["get", "curve_shape"],
        "S_TURN",
        "orange",
        "HAIRPIN_BEND",
        "red",
        "ARC",
        "yellow",
        "black"
      ],//'#a6a6a6',
      'line-width': 5,
      'line-opacity': .8
    };
    this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_CURVES, tileUrl, MapSourceEnum.SOURCE_LAYER_CURVES, MapSourceEnum.SOURCE_LAYER_CURVES + 'Curves', false, layout, paint);
    this.spinner.removeOperation("MenuConformityComponent.showCurves");
  }

  showGlare() {
    this.spinner.addOperation("MenuConformityComponent.showGlare");

    let tileUrl = environment.tilesGlareServer;

    // default filter before UI
    tileUrl = tileUrl + '?minSumGlare=230&maxSumGlare=500';

    let layout = {
      'line-cap': 'round',
      'line-join': 'round'
    };

    let paint = {
      'line-color': [
        'step',
        ['get', 'sum_glare'],
        '#2bd634',
        230,
        '#efe63c',
        300,
        '#f29100'
      ],
      'line-width': 5,
      'line-opacity': 0.7,
      'line-offset': [
        "match",
        ["get", "way_axis"],
        "ASC",
        3,
        "DSC",
        -3,
        0
      ]
    };
    this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_GLARE, tileUrl, MapSourceEnum.SOURCE_LAYER_GLARE, MapSourceEnum.SOURCE_LAYER_GLARE + 'Glare', false, layout, paint);
    this.spinner.removeOperation("MenuConformityComponent.showGlare");
    this.map.map.on("click", MapLayerEnum.LAYER_GLARE, e => {
      console.log(e.features[0]);
    });
  }

  showNarrowRoad(){
    this.spinner.addOperation("MenuConformityComponent.showCurves");

    let tileUrl = environment.tilesNarrowRoadsServer;

    let layout = {
      'line-cap': 'round',
      'line-join': 'round'
    };

    let paint = {
      'line-color': 'black',
      'line-width': 5,
      'line-opacity': .8,
    };
    this.map.addLineVectorLayerTiles(MapLayerEnum.LAYER_NARROW, tileUrl,MapSourceEnum.SOURCE_LAYER_NARROW, MapSourceEnum.SOURCE_LAYER_NARROW + 'Narrow',false , layout, paint);
    this.spinner.removeOperation("MenuConformityComponent.showCurves");
  }
}
