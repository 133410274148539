<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_SNAPSHOT')" appWorkspaceRequired
    [ngClass]="{'itemChecked':activated, 'itemUnchecked': !activated}" (click)="openMenuConformity()">
    <i class="fa fa-bullhorn"></i>
    <span class="text">{{ 'conformityEntry.title' | translate }}</span>
    <a role="button"
       *ngIf="activated || activated"
       [ngClass]="{'itemChecked':activated, 'itemUnchecked': !activated}">
        <span (click)="removeConformity()" class="pi pi-times ng-tns-c48-2">
        </span>
    </a>
</li>
