import {ISerializable} from '../iserializable';
import {GeometryModel} from "../geojson/geometry-model";

export class SnapshotModel implements ISerializable {

  public snapshotIdentifier: string;
  public statusDate: Date;
  public statusCode: string;
  public statusReason: string;
  public snapshotType: string;
  public snapshotDate: Date;
  public snapshotName: string;
  public isEnable: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);

    this.statusDate = new Date(input.statusDate);
    this.snapshotDate = new Date(input.snapshotDate);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
