import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftPanelComponent } from './panel/component/left-panel/left-panel.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RightPanelComponent } from './panel/component/right-panel/right-panel.component';



@NgModule({
  declarations: [LeftPanelComponent, RightPanelComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule
  ],
    exports: [
        LeftPanelComponent,
        RightPanelComponent
    ]
})
export class SharedModule { }
