import { Injectable } from '@angular/core';
import { Options } from 'ng5-slider';
import { ElementClassEnum } from 'src/app/enums/element-class-enum.enum';
import { Store } from 'src/app/globals/store';

export class RetroreflexionState{

  _isShowElement: boolean = false;
  _isVisible: boolean = true;
  isPrimary: boolean = true;

  typeAxes: any[];

  selectedNetworks: string[] = [];


  selectedTypeAxe: string = ElementClassEnum.ELEMENT_CLASS_REFLECTO_AXE;
  selectedMinQuality: number = 100;
  selectedHighQuality: number = 150;

  qualitySliderOptions: Options = {
    floor: 0,
    ceil: 500,
    showOuterSelectionBars: true,
  };

  selectedDistance: number = 500;
  distanceliderOptions: Options = {
    floor: 0,
    ceil: 2000,
    showOuterSelectionBars: true,
  };
  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear;
  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };

}


@Injectable({
  providedIn: 'root'
})
export class RetroreflexionStoreService extends Store<RetroreflexionState> {

  constructor() {
    super(RetroreflexionState)
  }
}
