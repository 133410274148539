<div class="header">
    <div (click)="_closeVersions()" class="ui-g-2 titleButton">
        <i class="fa fa-arrow-left"></i>
      </div>
    <h1>{{ 'viewVersion.title' | translate }}</h1>
</div>
<div class="content-wrapper">
    <div class="timeline">
        <div (click)="setSelected(index)" class="timeline-block" [ngClass]="{'selected':currentlySelected == index}" *ngFor="let item of elementVersions;let index = index">
            <div class="timeline-text">
                <div class="timeline-date"  *ngIf="index == 0">
                    <span class="timeline-year">
                        {{elementYear(elementVersions[index].updateDate)}}
                    </span>
                    <span class="timeline-day-month">
                        {{elementRest(elementVersions[index].updateDate)}}
                    </span>
                </div>
                <div class="timeline-date" *ngIf="index < elementVersions.length - 1">
                    <span class="timeline-year">
                        {{elementYear(elementVersions[index+1].versionCreationDate)}}
                    </span>
                    <span class="timeline-day-month">
                        {{elementRest(elementVersions[index+1].versionCreationDate)}}
                    </span>
                </div>
                <div class="timeline-date" *ngIf="index == elementVersions.length - 1">
                    <span class="timeline-year">
                        {{elementYear(elementVersions[index].creationDate)}}
                    </span>
                    <span class="timeline-day-month">
                        {{elementRest(elementVersions[index].creationDate)}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="version-infos">
        <h2>{{ 'viewVersion.report' | translate }}</h2>
        <div class="image-wrapper">
            <img alt="sign image" *ngIf="(currentSelectedImage !== undefined)" [src]="currentSelectedImage" class="compareImageSign"/>
        </div>
        <ul>
            <li *ngIf="currentInterventionDate">
                <span>{{ 'viewVersion.date' | translate }}</span>
                <br/>
                {{currentInterventionDate}}
            </li>
            <li *ngIf="currentAppliedProduct">
                <span>{{ 'viewVersion.appliedProduct' | translate }}</span>{{currentAppliedProduct}}
            </li>
            <li>
                <span>{{ 'viewVersion.quality' | translate }}</span>{{currentQuality | elementQualityPipe}} ({{currentQuality}})
            </li>
        </ul>
    </div>
</div>
