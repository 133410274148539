import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByFieldValue'
})
export class FilterByFieldValuePipe implements PipeTransform {
  transform(items: any[], search: any): any[] {
    if(!items) return [];
    if(!search.field) return items;
    if(!search.value) return items;

    return items.filter( item => {
      return item[search.field] === search.value;
    });
  }
}
