<div class="mainCropDiv" *ngIf="_element" >

  <div class="mainImageDiv">
    <button *ngIf="elementImage === undefined" pButton type="button" icon="pi pi-camera" class="ui-button-primary ui-button-margin viewerButton" (click)="showViewerNewElement()"></button>
    <img alt="sign image" style="width: 100%; height: 100%" *ngIf="(elementImage !== undefined)" [src]="elementImage" (click)="showViewer()" class="hoverImage"/>
  </div>

  <div class="mainButtonDiv">
    <div class="mapboxgl-ctrl mapboxgl-ctrl-group mapControl">
      <button [disabled]="this.currentImageId === 0"
              [ngClass]="{ 'lowOpacity': this.currentImageId === 0}"
              class="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in" type="button"
              title="ZoomIn" pTooltip="Zoomer dans l'image" (click)="zoomCrop(-1)" ></button>
      <button [disabled]="this.crops.length <= 1 || this.currentImageId === 2"
              [ngClass]="{ 'lowOpacity': (this.crops.length <= 1 || this.currentImageId === 2)}"
              class="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out" type="button"
              title="ZoomOut" pTooltip="Dézoomer dans l'image"  (click)="zoomCrop(1)"></button>
    </div>
  </div>

</div>
