import {Component, OnInit} from '@angular/core';
import {Session} from 'src/app/globals/session';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {RetroreflexionEntryPanelComponent} from './retroreflexion-entry-panel/retroreflexion-entry-panel.component';
import {RetroreflexionStoreService} from './retroreflexion-store.service';
import Matomo from "../../../../stats";

@Component({
  selector: 'app-retroreflexion-entry',
  templateUrl: './retroreflexion-entry.component.html',
  styleUrls: ['./retroreflexion-entry.component.css']
})
export class RetroreflexionEntryComponent implements OnInit {

  constructor(public session: Session, public retroreflexionStore: RetroreflexionStoreService, public panelService: PanelService) { }

  ngOnInit(): void {
  }

  openMenuRetroreflexion(){
    this.panelService.push(
      RetroreflexionEntryPanelComponent,
      null
    )
    Matomo.push(['trackEvent', 'Actions', 'Opened retroreflexion menu'])
  }

  close(event:Event){
    event.preventDefault();
    event.stopPropagation();
    this.retroreflexionStore.reset();
    Matomo.push(['trackEvent', 'Actions', 'Removed retroreflexion'])
  }

}
