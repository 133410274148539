import { Injectable } from '@angular/core';
import { Options } from 'ng5-slider';
import { Store } from 'src/app/globals/store';
import { LocalesHelper } from 'src/app/helpers/locales.helper';
import { FeatureModel } from 'src/app/models/geojson/feature-model';
import { ListValueModel } from 'src/app/models/masterData/list-value-model';
import { ClassModel } from 'src/app/models/processing/class-model';

export class AlertState{
  classList: ClassModel[] = [];
  classSelectedList: ClassModel[] = [];
  isRelevant: boolean = false;

  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  _activeViewAlert: boolean = false;

  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear ;
  selectedTypeAlertWorks: string;
  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };
  onCreate: boolean = false;
  selectedAlert: boolean = false;
  alertActive: boolean = false;
  alertImpact: string;
  alertType: string;
  alertCategory: string;
  fr = LocalesHelper.getCalendarFr();
  dateBegin: string;
  dateEnd: string;
  alertImpactValues: ListValueModel[] = [];
  alertTypeValues: ListValueModel[] = [];
  alertCategoryValues: ListValueModel[] = [];
  active: string = '';
  features:FeatureModel[] =[];

}


@Injectable({
  providedIn: 'root'
})
export class AlertStoreService extends Store<AlertState>{

  constructor() {
    super(AlertState)
  }
}
