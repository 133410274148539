<li class="listMenu"
    *ngIf="session.hasRole('ROLE_MAP_SNAPSHOT')"
    appWorkspaceRequired
    [ngClass]="{'itemChecked':journeyStore.state.isShowJourneys, 'itemUnchecked': !journeyStore.state.isShowJourneys}"
    (click)="openJourney()">
    <i class="fa-solid fa-route"></i>
    <span class="text">{{ 'journeyEntry.title' | translate }}</span>
    <a role="button" *ngIf="journeyStore.state.isShowJourneys" [ngClass]="{'itemChecked':journeyStore.state.isShowJourneys, 'itemUnchecked': !journeyStore.state.isShowJourneys}">
        <span (click)="close()" class="pi pi-times ng-tns-c48-2">
        </span>
    </a>

</li>
