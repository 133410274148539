import {Component, OnInit} from '@angular/core';
import {ChangeContext, Options} from 'ng5-slider';
import {Subscription} from 'rxjs';
import {ElementClassEnum} from 'src/app/enums/element-class-enum.enum';
import {ElementTypesEnum} from 'src/app/enums/element-types-enum.enum';
import {EventCodeEnum} from 'src/app/enums/event-code-enum.enum';
import {GeometryTypeEnum} from 'src/app/enums/geometry-type-enum.enum';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {Session} from 'src/app/globals/session';
import {GeometryHelper} from 'src/app/helpers/geometry.helper';
import {WindowEventHelper} from 'src/app/helpers/window-event.helper';
import {FeatureCollectionModel} from 'src/app/models/geojson/feature-collection-model';
import {FeatureObjectElementModel} from 'src/app/models/geojson/feature-object-element-model';
import {GeojsonService} from 'src/app/services/geojson.service';
import {ProcessingService} from 'src/app/services/processing.service';
import {MapService} from 'src/app/shared/map/map.service';
import {PanelBase} from 'src/app/shared/panel/component/PanelBase';
import {PanelService} from 'src/app/shared/panel/panel.service';
import {SpinnerService} from 'src/app/shared/spinner/spinner.service';
import {ToastService} from 'src/app/shared/toast/toast.service';
import {environment} from 'src/environments/environment';
import * as turf from "@turf/turf";
import {RetroreflexionStoreService} from '../retroreflexion-store.service';
import {TranslateService} from "@ngx-translate/core";


declare let gtag: Function;
@Component({
  selector: 'app-retroreflexion-entry-panel',
  templateUrl: './retroreflexion-entry-panel.component.html',
  styleUrls: ['./retroreflexion-entry-panel.component.css']
})
export class RetroreflexionEntryPanelComponent implements OnInit, PanelBase<any> {

  get spinner() {
    return this.spinnerService.getSpinner();
  }

  get map() {
    return this.mapService.getMap();
  }

  get toast() {
    return this.toastService.getToast();
  }


  constructor(private session: Session,
    private processingService: ProcessingService,
    private geojsonService: GeojsonService,
    private spinnerService: SpinnerService,
    private mapService: MapService,
    private toastService: ToastService,
    private panelService: PanelService,
    private translate: TranslateService,
    private retroreflexionStore: RetroreflexionStoreService) {
  }

  data: any;

  selectedNetworks: string[] = [];

  subscriptions: Subscription[] = [];

  _isShowElement: boolean = false;
  _isVisible: boolean = true;
  isPrimary: boolean = true;

  typeAxes: any[] = [];

  selectedTypeAxe: string = ElementClassEnum.ELEMENT_CLASS_REFLECTO_AXE;
  selectedMinQuality: number = 100;
  selectedHighQuality: number = 150;

  qualitySliderOptions: Options = {
    floor: 0,
    ceil: 500,
    showOuterSelectionBars: true,
  };

  selectedDistance: number = 500;
  distanceliderOptions: Options = {
    floor: 0,
    ceil: 2000,
    showOuterSelectionBars: true,
  };
  actualYear: number = new Date().getFullYear();
  selectedYearMinValue: number = this.actualYear - 5;
  selectedYearHighValue: number = this.actualYear;
  options: Options = {
    floor: this.actualYear - 5,
    ceil: this.actualYear,
    showOuterSelectionBars: true
  };

  ngOnInit() {
    this.subscriptions.push(this.session.onChangeWorkspace.subscribe(geometry => {
      this.refresh();
    }));
    this.retroreflexionStore.useProxy(this, [
      {
        type: 'MULTIPLE',
        keys: ['selectedMinQuality', 'selectedHighQuality', 'selectedYearHighValue', 'selectedYearMinValue', 'selectedDistance'],
        delay: 500
      }
    ]);
    this.translate.get('common.axis').subscribe((response: string) => {
      this.typeAxes.push({ name: response, code: 'AXE', class: 'reflecto.axe' });
    })
    this.translate.get('common.leftSide').subscribe((response: string) => {
      this.typeAxes.push({ name: response, code: 'RIVE_G', class: 'reflecto.rive.gauche' });
    })
    this.translate.get('common.rightSide').subscribe((response: string) => {
      this.typeAxes.push({ name: response, code: 'RIVE_D', class: 'reflecto.rive.droite' });
    })
    this.translate.get('common.axisLane1').subscribe((response: string) => {
      this.typeAxes.push({ name: response, code: 'AXE_1', class: 'reflecto.axe.voie1' });
    })
    this.translate.get('common.axisLane2').subscribe((response: string) => {
      this.typeAxes.push({ name: response, code: 'AXE_2', class: 'reflecto.axe.voie2' });
    })
    this.translate.get('common.axisLane3').subscribe((response: string) => {
      this.typeAxes.push({ name: response, code: 'AXE_3', class: 'reflecto.axe.voie3' });
    })
    this.translate.get('common.medianSeparation').subscribe((response: string) => {
      this.typeAxes.push({ name: response, code: 'TPC', class: 'reflecto.tpc' });
    })
    this.translate.get('common.emergencyLane').subscribe((response: string) => {
      this.typeAxes.push({ name: response, code: 'BA', class: 'reflecto.bau' });
    })

    this._isShowElement = true;
  }

  clear() {
    this.retroreflexionStore.reset();
    this.show(false)
  }

  refresh() {
    this.retroreflexionStore.refresh();
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if (!show) {
      this.panelService.pop();
    }
  }

  updateQualityValues($event: ChangeContext) {
    this.selectedMinQuality = $event.value;
    this.selectedHighQuality = $event.highValue;
  }

  updateYearValues($event: ChangeContext) {
    this.selectedYearMinValue = $event.value;
    this.selectedYearHighValue = $event.highValue;
  }

  updateDistanceValues($event: ChangeContext) {
    this.selectedDistance = $event.value;
  }
}
