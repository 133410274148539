import {RoadNode, linkTypeEnum, RoadNodeAnnotation, stateEnum, typeLinkMap} from "../linked-list/road-node";
import {RoadList} from "../linked-list/road-list";
import {FeatureHelper} from "./feature-helper";
import {GeometryHelper} from "./geometry.helper";
import {LineStringHelper} from "./linestring-helper";
import {SegmentTypeEnum} from "../enums/segment-type-enum.enum";
import {msToTime} from "./compute-lane.helper";
import {GeometryModel} from "../models/geojson/geometry-model";
import {FeatureModel} from "../models/geojson/feature-model";
import {RoadNodeHelper} from "./road-node.helper"
import {ArrayHelper} from "./array.helper";
import {
  computeFeatureRandomColorFromGeo,
  computeFeatureRandomColorFromNode,
} from "./tools";
// FIXME  git+ssh://git@bitbucket.org/citymagineinc/ts-citymagine-core.git#master

export enum SegmentPosEnum {
  START,
  END
}

function idt(n: RoadNode<GeometryModel>) {
  if (n)
    return n.dt.properties.segment_identifier;
  return undefined
}

function timeEstimation(iterationsDone: number, iterationsToDo: number, start: number) {
  let current = new Date().getTime();

  let timeRemaining = (current - start) * iterationsToDo / iterationsDone

  console.log('Remaining time: ' + msToTime(timeRemaining));
}

export function createNode(dt: GeometryModel) {
  return new RoadNode<GeometryModel>(dt)
}

export function createNodeAnnotation(dt: GeometryModel) {
  return new RoadNodeAnnotation<GeometryModel>(dt)
}

export class GraphHelper {

  public graph: RoadList<GeometryModel>;
  public nodes: RoadNode<GeometryModel>[] = [];

  public log: Function = (message?: any, ...optionalParams: any[]) => {
    if (!this.testMode)
      console.log(message, ...optionalParams)
  };

  /**
   * Class is more important than lane to determine 'next' segment :
   */

  private fNextMap = new Map<number, any>(
    [
      /** Force usage **/
      [-1, (p1: any, p2: any): boolean => (this.forceUseLanesForNext ? p1.segment_lanes == p2.segment_lanes : true) && (this.forceUseClassForNext ? p1.segment_class == p2.segment_class : true)],

      /** Tools **/
      [-2, (p1: any, p2: any): boolean => Math.abs(p1.segment_lanes - p2.segment_lanes) <= 1],


      /** Conditions **/
      [0, (p1: any, p2: any): boolean => this.fNextMap.get(6)(p1, p2) && p1.segment_lanes == p2.segment_lanes && p1.segment_class == p2.segment_class],
      [1, (p1: any, p2: any): boolean => this.fNextMap.get(7)(p1, p2) && p1.segment_lanes == p2.segment_lanes && p1.segment_class == p2.segment_class],
      [2, (p1: any, p2: any): boolean => this.fNextMap.get(6)(p1, p2) && p1.segment_class == p2.segment_class],
      [3, (p1: any, p2: any): boolean => this.fNextMap.get(7)(p1, p2) && p1.segment_class == p2.segment_class],
      [4, (p1: any, p2: any): boolean => this.fNextMap.get(6)(p1, p2) && p1.segment_lanes == p2.segment_lanes],
      [5, (p1: any, p2: any): boolean => this.fNextMap.get(7)(p1, p2) && p1.segment_lanes == p2.segment_lanes],
      [6, (p1: any, p2: any): boolean => p1.segment_type == p2.segment_type],
      [7, (p1: any, p2: any): boolean => (this.fNextMap.get(6)(p1, p2) ||
        (p1.segment_type == 'DOUBLE_WAY' && p2.segment_type == 'SINGLE_WAY') ||
        (p1.segment_type == 'SINGLE_WAY' && p2.segment_type == 'DOUBLE_WAY'))],

      [8, (p1: any, p2: any): boolean => this.fNextMap.get(6)(p1, p2) && this.fNextMap.get(-2)(p1, p2) && p1.segment_class == p2.segment_class],
      [9, (p1: any, p2: any): boolean => this.fNextMap.get(7)(p1, p2) && this.fNextMap.get(-2)(p1, p2) && p1.segment_class == p2.segment_class],
      [10, (p1: any, p2: any): boolean => this.fNextMap.get(6)(p1, p2) && this.fNextMap.get(-2)(p1, p2)],
      [11, (p1: any, p2: any): boolean => this.fNextMap.get(7)(p1, p2) && this.fNextMap.get(-2)(p1, p2)],
    ]);

  constructor(segments: GeometryModel[],
              private createNodeCallBack: Function,
              public forceUseLanesForNext: boolean = false,
              public forceUseClassForNext = false,
              public testMode = false
  ) {
    this.nodes = this.createNodesFromSegments(segments);
    this.log('\x1b[36m%s\x1b[0m', "Graph Computation:", this.nodes.length.toString(), "segments");
    this.fixSegmentDir();
    this.computeGraph();
    this.highLevelFeatures();
    this.log('\x1b[36m%s\x1b[0m', "Graph Done:", this.nodes.length, "nodes");
  }


  /**
   * The graph computation works like so :
   * takes a random segment not already computed (all segments are in one node. It's one node per segment and one segment per node)
   * from this segment it search the "previous" (in dual axis road there is not really a "previous") intersection.
   * We consider that an intersection occurs when the segment is not connected to an other segment with a "next" relation.
   *
   * The fact that 2 segments are connected as "next" and "prev" is defined by the function 'isNextSegment'
   *
   * This first segment that we just found is set as an head in the graph. A graph can have multiple heads, they are saved in savedHeads variable.
   * The head can be any node in the graph.
   * the heads must exist in such a way that it is impossible to reach one from another. (Otherwise one of them is useless)
   *
   * Once this first part is done we do have the node that will allow us to start our journey.
   * The next step is to get the "next segments".
   * Note: 'next Segments' is plural and the property 'next' in RoadNode is an array. However each segment can only have one next segment, and so one only prev too. (It is an array because the graph can be changed to have multiple "next" (i.e. next property in HighLevelFeatures)
   *
   * Then we add the "links" to the current node. Each node geometrically connected to an other which is not "next" become a link
   *
   * @private
   */
  private computeGraph(): void {
    this.graph = new RoadList<GeometryModel>();

    let nextSegments: RoadNode<GeometryModel>[] = [];
    let links: RoadNode<GeometryModel>[] = [];
    let currentSegment: RoadNode<GeometryModel> = this.findIntersectionFromSegment(this.nodes[0]);
    let n: number = 0;
    // let pg: ProgressBar = new ProgressBar(this.nodes.length, "Graph Computation", this.testMode)

    // In this graph there is no real "head" (because of circular aspect of the road). head changes as needed
    this.graph.setHead(currentSegment);
    this.graph.saveHead();

    while (!this.allNodesAreDone()) {
      nextSegments = nextSegments.concat(this.graph.addNextSegments(this.getNextSegments(currentSegment, this.nodes)));
      links = links.concat(this.graph.addLinks(this.getLinks(currentSegment, this.nodes)).filter(value => value.recompute === true));

      currentSegment.state = stateEnum.DONE;

      nextSegments = nextSegments.filter(value => value.state !== stateEnum.DONE);
      links = links.filter(value => value.state !== stateEnum.DONE);
      const __ret = this.findStartSegmentOfNewLinks(links, nextSegments);
      links = __ret.links;
      nextSegments = __ret.nextSegments;

      /*
       * Done: Segment computed
       * Processing: Segment in the "right" direction and will be computed
       * Waiting: Segment not computed yet
       */

      currentSegment = this.getNextSegmentToCompute(nextSegments, links);

      // Update progress bar
      n += 1;
      // if (n % 5 == 0) // Make it less resources & time consuming
        // pg.update(n)
    }
    // pg.end()
  }

  /**
   * This function tells which segment is the next one to compute
   *
   * The first case is when the previous node has a "next" one. In this case this "next node" is the next node to compute.
   *
   * If the first case is not true :
   * The second case is when the previous node (or previous nodes) have had links. In this case the links are the segments to compute
   *
   * Finally if the two previous options lead to nothing, this means there is not no more node connected to all the previous ones.
   * So we create a new head, find the previous intersection and so on .. (explained in computeGraph function)
   *
   * Note: Node and segment can be used interchangeably given that one node contains one segment and each segment is stored in one node
   *
   * @param nextSegments
   * @param links
   * @private
   */
  private getNextSegmentToCompute(nextSegments: RoadNode<GeometryModel>[], links: RoadNode<GeometryModel>[]) {
    if (nextSegments.length > 0) {
      this.graph.setHead(nextSegments.shift());
    } else if (links.length > 0) {
      this.graph.setHead(links.shift());
    } else if (this.nodes.some(value => value.state == stateEnum.WAITING)) {
      this.graph.setHead(this.findIntersectionFromSegment(this.nodes.filter(value => value.state == stateEnum.WAITING)[0]));
      this.graph.saveHead();  // Save heads for unlinked segments groups.
    }

    return this.graph.getHead();
  }

  /**
   * Determine the first node, so probably at an intersection of the segments suite (connected as 'next' and 'prev' to each other
   *
   * @param segment
   * @param stack
   * @private
   */
  private findIntersectionFromSegment(segment: RoadNode<GeometryModel>, stack: RoadNode<GeometryModel>[] = []): RoadNode<GeometryModel> {
    let startPoint: GeometryModel = LineStringHelper.getStartPoint(segment.dt);
    let endPoint: GeometryModel = LineStringHelper.getEndPoint(segment.dt);
    let contenders: RoadNode<GeometryModel>[] = this.getContenders(this.nodes.filter(value => value !== segment), startPoint);
    let others: RoadNode<GeometryModel>[] = this.getContenders(this.nodes.filter(value => value !== segment), endPoint);

    // Check reverse
    contenders.forEach(contender => {
      if (GeometryHelper.getDistance(LineStringHelper.getEndPoint(contender.dt), startPoint) > GeometryHelper.getDistance(LineStringHelper.getStartPoint(contender.dt), startPoint) &&
        contender.state == stateEnum.WAITING && contender.dt.properties.segment_axis == 'DUAL_AXIS') {  // "Processing segments" are already in the right direction
        this.reverseNodeDuringComputation(contender);
      }
    });

    let contendersPrev: RoadNode<GeometryModel>[] = contenders.filter(value => this.isNextSegment(value, segment, contenders.filter(v => v !== value).concat([segment]),
      this.getContenders(this.nodes.filter(v => v !== value), LineStringHelper.getStartPoint(value.dt))));

    if (contendersPrev.length > 0) {
      let finalPrev: RoadNode<GeometryModel> = RoadNodeHelper.orderByClosest(startPoint, contendersPrev)[0];
      if (stack.indexOf(finalPrev) > -1)
        return segment;
      finalPrev.state = finalPrev.state == stateEnum.WAITING ? stateEnum.PROCESSING : finalPrev.state;
      segment.state = segment.state == stateEnum.WAITING ? stateEnum.PROCESSING : segment.state;

      /** Pre compute next segments to freeze them **/
      let next: RoadNode<GeometryModel>[] = [segment];
      let buffer: RoadNode<GeometryModel>[] = [];
      do {
        buffer.push(next[0]);
        next = this.getNextSegments(next[0], this.nodes);
      } while (next.length > 0 && buffer.indexOf(next[0]) < 1);
      /** ======================================== **/
      return this.findIntersectionFromSegment(finalPrev, stack.concat([finalPrev]));
    } else if (segment.state == stateEnum.WAITING && segment.dt.properties.segment_axis == 'DUAL_AXIS') {
      segment.state = stateEnum.PROCESSING;
      this.reverseNodeDuringComputation(segment);
      return this.findIntersectionFromSegment(segment, []);
    }

    return segment;
  }

  private getContenders(contenders: RoadNode<GeometryModel>[], point: GeometryModel) {
    return contenders.filter(value => GeometryHelper.getDistance(LineStringHelper.getEndPoint(value.dt), point) < 0.01 ||
      GeometryHelper.getDistance(LineStringHelper.getStartPoint(value.dt), point) < 0.01)
  }

  private allNodesAreDone() {
    return !this.nodes.some(value => value.state !== stateEnum.DONE);
  }

  private findStartSegmentOfNewLinks(links: RoadNode<GeometryModel>[], nextSegments: RoadNode<GeometryModel>[]) {
    let updatedLinks: RoadNode<GeometryModel>[] = [];

    links.filter(value => value.recompute == true).forEach(value => {
      let seg: RoadNode<GeometryModel> = this.findIntersectionFromSegment(value);
      if (updatedLinks.indexOf(seg) < 0)
        updatedLinks.push(seg);
    });
    links = links.filter(value => value.recompute == false).concat(updatedLinks);
    this.nodes.forEach(value => value.recompute = false); // Reset

    nextSegments = nextSegments.filter(value => value.state !== stateEnum.DONE);
    links = links.filter(value => value.state !== stateEnum.DONE);
    return {links, nextSegments};
  }

  private static reverseNode(node: RoadNode<GeometryModel>) {
    node.dt = LineStringHelper.reverseLineString(node.dt);
    node.links.forEach(value => value[1] = value[1] ^ linkTypeEnum.START ^ linkTypeEnum.END);
    node.links.forEach(value => value[0].links.forEach(v => v[1] = v[0] === node ? v[1] ^ linkTypeEnum.L_START ^ linkTypeEnum.L_END : v[1]))
  }

  private reverseNodeDuringComputation(node: RoadNode<GeometryModel>) {
    node.dt = LineStringHelper.reverseLineString(node.dt);
    node.links.forEach(value => value[1] = value[1] ^ linkTypeEnum.START ^ linkTypeEnum.END);
    this.nodes.forEach(value => value.links.forEach(v => v[1] = v[0] === node ? v[1] ^ linkTypeEnum.L_START ^ linkTypeEnum.L_END : v[1]))
  }

  static reverseNodeAlreadyComputed(node: RoadNode<GeometryModel>, from: string = null) {
    GraphHelper.reverseNode(node);
    node.next.push(node.prev.shift()); // work because there is only one element for each prev / next
    node.prev.push(node.next.shift());

    if (from == null || from == 'prev')
      for (let n of node.next)
        GraphHelper.reverseNodeAlreadyComputed(n, "prev");
    if (from == null || from == 'next')
      for (let p of node.prev)
        GraphHelper.reverseNodeAlreadyComputed(p, "next");
  }

  // TODO Check best angle and way_identifiers if both segment are equally likely to be the next of one segment.
  private getNextSegments(currentSegment: RoadNode<GeometryModel>, segments: RoadNode<GeometryModel>[]): RoadNode<GeometryModel>[] {
    let endPoint: GeometryModel = LineStringHelper.getEndPoint(currentSegment.dt);
    let startPoint: GeometryModel = LineStringHelper.getEndPoint(currentSegment.dt);
    let contenders: RoadNode<GeometryModel>[] = this.getContenders(segments.filter(value => value != currentSegment), endPoint);
    let others: RoadNode<GeometryModel>[] = this.getContenders(segments.filter(value => value != currentSegment), startPoint);

    // Check reverse
    contenders.forEach(contender => {
      if (GeometryHelper.getDistance(LineStringHelper.getEndPoint(contender.dt), endPoint) < GeometryHelper.getDistance(LineStringHelper.getStartPoint(contender.dt), endPoint) &&
        contender.state == stateEnum.WAITING && contender.dt.properties.segment_axis == 'DUAL_AXIS') {
        this.reverseNodeDuringComputation(contender);
      }
    });
    contenders = contenders.filter(value => GeometryHelper.getDistance(LineStringHelper.getStartPoint(value.dt), endPoint) < 0.01 && this.isNextSegment(currentSegment, value, contenders, others));
    contenders.forEach(value => {
      value.state = value.state == stateEnum.WAITING ? stateEnum.PROCESSING : value.state;
    });
    return contenders;
  }

  private getLinks(currentSegment: RoadNode<GeometryModel>, segments: RoadNode<GeometryModel>[]): [RoadNode<GeometryModel>, linkTypeEnum][] {

    /**
     * @param point end of the segment from which connections are sought
     * @param pos connection placement information
     *
     * Get every segment which is not next or prev, connected to the current one, same end as 'point'
     *
     */
    let getLinksFromPoint = (point: GeometryModel, pos: SegmentPosEnum): [RoadNode<GeometryModel>, linkTypeEnum][] => {
      let contenders: RoadNode<GeometryModel>[] = this.getContenders(segments.filter(value => value != currentSegment), point);

      contenders = contenders.filter(value => (pos == SegmentPosEnum.START && currentSegment.prev.indexOf(value) < 0)
        || (pos == SegmentPosEnum.END && currentSegment.next.indexOf(value) < 0));

      contenders = contenders.filter(value => value.state !== stateEnum.DONE);
      if (contenders.length > 0)
        contenders[0].recompute = true;

      return contenders.map(value => {
        return [value, GraphHelper.defineLinkType(currentSegment, value, contenders, pos, GraphHelper.getLinkSide(value, point))];
      });
    };

    let contendersFinal: [RoadNode<GeometryModel>, linkTypeEnum][] = getLinksFromPoint(LineStringHelper.getEndPoint(currentSegment.dt), SegmentPosEnum.END).concat(
      getLinksFromPoint(LineStringHelper.getStartPoint(currentSegment.dt), SegmentPosEnum.START));

    return (GraphHelper.dropDuplicatesLinks(contendersFinal));
  }

  /**
   * Determine if the 'node' is connected to the 'point' from its start or end
   *
   * @param node
   * @param point
   * @private
   */
  private static getLinkSide(node: RoadNode<GeometryModel>, point: GeometryModel) {
    return GeometryHelper.getDistance(LineStringHelper.getEndPoint(node.dt), point) < GeometryHelper.getDistance(LineStringHelper.getStartPoint(node.dt), point) ? SegmentPosEnum.END : SegmentPosEnum.START;
  }

  /**
   * This function add qualifiers to a node linked to the current segment.
   * For example a link can be a round about, a standard intersection, an exit from the road ...
   * In fact, most of link types features are defined when doing high level features
   * The only information actually computed in this function is the basic connections : START, END, L_START, L_END
   * See more details about these connections in "linkTypeEnum"
   *
   * @param currentSegment
   * @param observedSegment
   * @param contenders
   * @param pos
   * @param posObserved
   * @private
   */
  private static defineLinkType(currentSegment: RoadNode<GeometryModel>, observedSegment: RoadNode<GeometryModel>, contenders: RoadNode<GeometryModel>[], pos: SegmentPosEnum, posObserved: SegmentPosEnum) {

    let type: linkTypeEnum = linkTypeEnum.UNKNOWN;

    type |= (pos === SegmentPosEnum.END) ? linkTypeEnum.END : linkTypeEnum.START;
    type |= (posObserved === SegmentPosEnum.END) ? linkTypeEnum.L_END : linkTypeEnum.L_START;
    return type;
  }

  private isNextSegment(currentSegment: RoadNode<GeometryModel>,
                        potentialNextSegment: RoadNode<GeometryModel>,
                        contenders: RoadNode<GeometryModel>[],
                        others: RoadNode<GeometryModel>[],
                        doubleCheck: boolean = true,
                        reverseGeo: boolean = false): boolean {

    let isNextSegment__Geo = (currentSegment: GeometryModel, potentialNextSegment: GeometryModel, segmentDirectionAutoAdjustment: boolean = false): boolean => {
      if (segmentDirectionAutoAdjustment && GeometryHelper.getDistance(LineStringHelper.getEndPoint(currentSegment), LineStringHelper.getStartPoint(potentialNextSegment)) >= 0.01 &&
        potentialNextSegment.properties.segment_axis == 'DUAL_AXIS') {

        return GeometryHelper.getDistance(LineStringHelper.getEndPoint(currentSegment), LineStringHelper.getStartPoint(LineStringHelper.reverseLineString(potentialNextSegment.clone()))) < 0.01 &&
          GraphHelper.areFollowing(currentSegment, LineStringHelper.reverseLineString(potentialNextSegment.clone()));
      }
      return GeometryHelper.getDistance(LineStringHelper.getEndPoint(currentSegment), LineStringHelper.getStartPoint(potentialNextSegment)) < 0.01 &&
        GraphHelper.areFollowing(currentSegment, potentialNextSegment);
    };

    let isNextSegment__Template = (currentSegment: RoadNode<GeometryModel>, potentialNextSegment: RoadNode<GeometryModel>, contenders: RoadNode<GeometryModel>[], others: RoadNode<GeometryModel>[], level: number): boolean => {
      return !GraphHelper.f_direct_axis_to_other_by_roundabout_template(contenders, currentSegment, potentialNextSegment, others);
    };

    let isNextSegment__Properties = (currentSegment: RoadNode<GeometryModel>, potentialNextSegment: RoadNode<GeometryModel>, contenders: RoadNode<GeometryModel>[], others: RoadNode<GeometryModel>[], level: number): boolean => {
      return currentSegment.dt.properties.segment_axis == potentialNextSegment.dt.properties.segment_axis &&
        // TODO currentSegment.dt.properties.segment_level == potentialNextSegment.dt.properties.segment_level &&
        this.fNextMap.get(level)(currentSegment.dt.properties, potentialNextSegment.dt.properties) &&
        this.fNextMap.get(-1)(currentSegment.dt.properties, potentialNextSegment.dt.properties)
      // TODO add conditions related with to way_identifiers in fNextMap (allow us to make a difference between entrance in highway and actual highway
    };


    let isNextSegment__ = (currentSegment: RoadNode<GeometryModel>,
                           potentialNextSegment: RoadNode<GeometryModel>,
                           contenders: RoadNode<GeometryModel>[],
                           others: RoadNode<GeometryModel>[],
                           level: number,
                           reverseGeo: boolean = false,
                           segmentDirectionAutoAdjustment: boolean = false) => {
      return ((!reverseGeo ? isNextSegment__Geo(currentSegment.dt, potentialNextSegment.dt, segmentDirectionAutoAdjustment) :
        isNextSegment__Geo(LineStringHelper.reverseLineString(currentSegment.dt.clone()), LineStringHelper.reverseLineString(potentialNextSegment.dt.clone()), segmentDirectionAutoAdjustment)) &&
        isNextSegment__Template(currentSegment, potentialNextSegment, contenders, others, level) &&
        isNextSegment__Properties(currentSegment, potentialNextSegment, contenders, others, level));
    };


    // @ts-ignore
    let valid: any[] = [...Array(this.fNextMap.size).keys()].map(level => this.fNextMap.has(level)
        && contenders.filter(value => isNextSegment__(currentSegment, value, contenders, others, level,
            reverseGeo, value != potentialNextSegment)).length == 1 ? level : -1).filter(x => x != -1);

    let level: number = valid.length > 0 ? ArrayHelper.min(valid) : -1;

    if (doubleCheck && !this.isNextSegment(potentialNextSegment, currentSegment, contenders.filter(v => v !== potentialNextSegment).concat([currentSegment]), // TODO make me cleaner &  clearer
      this.getContenders(this.nodes.filter(v => v !== potentialNextSegment), LineStringHelper.getEndPoint(potentialNextSegment.dt)), false, true)) {
      return false;
    }

    if (level == -1)
      return false;
    return isNextSegment__(currentSegment, potentialNextSegment, contenders, others, level, reverseGeo);
  }


  /** UTILS **/

  private createNodesFromSegments(allSegments: GeometryModel[]): RoadNode<GeometryModel>[] {
    let nodes: RoadNode<GeometryModel>[] = [];

    for (let segment of allSegments)
      nodes.push(this.createNodeCallBack(segment));
    return nodes;
  }

  /**
   * Reverse segments are useless. So they are reversed to become Direct Axis.
   *
   * @private
   */
  private fixSegmentDir() {
    this.nodes.forEach(value => {
      if (value.dt.properties.segment_axis == "REVERSE_AXIS") {
        value.dt = LineStringHelper.reverseLineString(value.dt);
        value.dt.properties.segment_axis = "DIRECT_AXIS";
      }
    })
  }

  static dropDuplicatesLinks(arr: any[]): any[] {
    return arr.filter((thing, index) => {
      return index === arr.findIndex(obj => {
        return obj[0] === thing[0] && (!!(obj[1] & linkTypeEnum.START) == !!(thing[1] & linkTypeEnum.START));
      });
    });
  }

  private static areFollowing(currentSegment: GeometryModel, potentialNextSegment: GeometryModel, reversed: boolean = false, max: number = 60) {
    return GeometryHelper.isFollowing(
      GeometryHelper.getBearing(GeometryHelper.createPoint(currentSegment.coordinates[currentSegment.coordinates.length - 2]), GeometryHelper.createPoint(currentSegment.coordinates[currentSegment.coordinates.length - 1])),
      GeometryHelper.getBearing(GeometryHelper.createPoint(potentialNextSegment.coordinates[0]), GeometryHelper.createPoint(potentialNextSegment.coordinates[1])), max, reversed);
  }


  /** EXPORT GRAPH RELATED METHODS **/

  public exportGraph(result: FeatureModel[]): any {
    this.graph.changeStateFromHeads();
    this.graph.getSavedHeads().forEach(head => this.exportFromHead(head, result))
  }

  public exportFromHead(node: RoadNode<GeometryModel>, result: FeatureModel[]): void {
    if (node.state != stateEnum.DONE)
      computeFeatureRandomColorFromNode(node, result);
    if (node.state == stateEnum.DONE)
      return;
    node.state = stateEnum.DONE;
    for (let n of node.next)
      this.exportFromHead(n, result);
    for (let p of node.prev)
      this.exportFromHead(p, result);
    for (let l of node.links)
      this.exportFromHead(l[0], result);
    this.exportFromHead(node, result);
  }

  public exportGraphMerged(result: FeatureModel[]): any {
    let roadFullSegmentsList = this.getRoadFullSegmentsList();
    let roadFullSegmentsMergedArray: GeometryModel[] = GraphHelper.mergeLists(roadFullSegmentsList);

    roadFullSegmentsMergedArray.forEach(mergedSegment => computeFeatureRandomColorFromGeo(mergedSegment, result));
  }

  /**
   * Return lists composed in each list of all segments connected as "next" to each other.
   *
   * @private
   */
  private getRoadFullSegmentsList() {
    this.graph.changeStateFromHeads();
    return this.toSubListsFromHeads(this.graph.getSavedHeads(), this.graph);
  }

  public toSubListsFromHeads(heads: RoadNode<GeometryModel>[] = this.graph.getSavedHeads(), graph: RoadList<GeometryModel> = this.graph): RoadList<GeometryModel>[] {
    let roadFullSegmentsList: RoadList<GeometryModel>[] = [];

    heads.forEach(head => {
      roadFullSegmentsList = roadFullSegmentsList.concat(this.toSubListsFromHead(head, graph))
    });
    return roadFullSegmentsList
  }

  public toSubListsFromHead(head: RoadNode<GeometryModel>, graph: RoadList<GeometryModel>): RoadList<GeometryModel>[] {
    let roadFullSegmentsList: RoadList<GeometryModel>[] = [];
    let startNodes: RoadNode<GeometryModel>[] = graph.getHeadsLink(head);

    startNodes.forEach(node => roadFullSegmentsList.push(this.getListFromNode(node, graph)));
    return roadFullSegmentsList;
  }

  /**
   * Creates new RoadList containing all nodes that are next to each other in graph from one node (links are not considered)
   *
   * @param firstNode
   * @param graph
   */
  public getListFromNode(firstNode: RoadNode<GeometryModel>, graph: RoadList<GeometryModel>): RoadList<GeometryModel> {
    graph.changeStateFromHead(stateEnum.WAITING, firstNode);
    let segmentList: RoadList<GeometryModel> = new RoadList<GeometryModel>();
    let newNode = this.createNodeCallBack(firstNode.dt.clone());
    segmentList.setHead(newNode);
    segmentList.saveHead();

    for (let node: RoadNode<GeometryModel> = firstNode.next[0]; node !== undefined && node.state != stateEnum.DONE; node = node.next[0]) {
      newNode = this.createNodeCallBack(node.dt.clone());
      segmentList.addNextNode(newNode);
      segmentList.setHead(newNode);
      node.state = stateEnum.DONE;
    }
    return segmentList
  }

  public static mergeLists(segmentsLists: RoadList<GeometryModel>[]): GeometryModel[] {
    let mergedSegments: GeometryModel[] = [];

    segmentsLists.forEach(s => mergedSegments.push(GraphHelper.mergeList(s)));
    return mergedSegments;
  }

  private static mergeList(list: RoadList<GeometryModel>): GeometryModel {
    list.changeStateFromHeads();
    let result: GeometryModel = list.getSavedHeads()[0].dt.clone();

    for (let node: RoadNode<GeometryModel> = list.getSavedHeads()[0].next[0]; node !== undefined && node.state != stateEnum.DONE; node = node.next[0]) {
      if (result.coordinates[result.coordinates.length - 1][0] == node.dt.coordinates[0][0] &&
        result.coordinates[result.coordinates.length - 1][1] == node.dt.coordinates[0][1]) {
        let tmp: GeometryModel = node.dt.clone();
        tmp.coordinates = tmp.coordinates.slice(1);
        result = LineStringHelper.mergeToLineString([result, tmp], result.properties);
      } else
        result = LineStringHelper.mergeToLineString([result, node.dt], result.properties);

      node.state = stateEnum.DONE;
    }
    return result;
  }

  public dump(): any {
    let result: any = {};

    this.graph.apply((node: RoadNode<GeometryModel>) => {
      result[idt(node)] = {};
      result[idt(node)]['NEXT'] = node.next.map(x => idt(x));
      result[idt(node)]['LINKS'] = node.links.map(x => [idt(x[0]), x[1]]);
    });
    return result;
  }

  /** HIGH LEVEL FEATURES **/

  private highLevelFeatures() {
    this.graph.apply(this.computeRoundAboutConnections);
    this.graph.apply(this.computeNextPrevLinks);
    this.graph.apply(this.computeIntersections);
  }

  private computeRoundAboutConnections(node: RoadNode<GeometryModel>) {
    node.links = node.links.map(link => [link[0], typeRoundAboutConnection(node, link[0], link[1])]);

    function typeRoundAboutConnection(node: RoadNode<GeometryModel>, next: RoadNode<GeometryModel>, type: linkTypeEnum): linkTypeEnum {
      if (node.dt.properties.segment_type != SegmentTypeEnum.ROUNDABOUT && next.dt.properties.segment_type == SegmentTypeEnum.ROUNDABOUT)
        type |= linkTypeEnum.ROUNDABOUT;

      if (type & linkTypeEnum.UNKNOWN && !!(type & linkTypeEnum.ROUNDABOUT))
        type = type ^ linkTypeEnum.UNKNOWN;
      return type;
    }
  }

  private computeNextPrevLinks(node: RoadNode<GeometryModel>) {
    const typeNextPrev = (node: RoadNode<GeometryModel>, target: [RoadNode<GeometryModel>, linkTypeEnum], others: [RoadNode<GeometryModel>, linkTypeEnum][]): linkTypeEnum => {
      let type: linkTypeEnum = GraphHelper.getNextTemplate(node, target, others);
      GraphHelper.updateTypes(type, target, node);
      return target[1];
    };

    node.links = node.links.map(link => [link[0], typeNextPrev(node, link, node.links.filter(f =>
      f[0] !== link[0] &&
      (!(link[1] & linkTypeEnum.START)) == (!(f[1] & linkTypeEnum.START)))
    )]);
  }

  private static updateTypes(type: linkTypeEnum, target: [RoadNode<GeometryModel>, linkTypeEnum], node: RoadNode<GeometryModel>) {
    if (type) {
      target[1] |= type;
      target[1] = target[1] & linkTypeEnum.UNKNOWN ? target[1] ^ linkTypeEnum.UNKNOWN : target[1];
      target[0].links.forEach(link => link[1] = link[0] === node ? link[1] | typeLinkMap.get(type) ^ linkTypeEnum.UNKNOWN : link[1]);
    }
  }

  private computeIntersections(node: RoadNode<GeometryModel>) {
    const typeIntersection = (node: RoadNode<GeometryModel>, target: [RoadNode<GeometryModel>, linkTypeEnum], others: [RoadNode<GeometryModel>, linkTypeEnum][]): linkTypeEnum => {
      let type: linkTypeEnum = GraphHelper.getIntersectionTemplate(node, target, others);

      GraphHelper.updateTypes(type, target, node);
      return target[1];
    };

    node.links = node.links.map(link => [link[0], typeIntersection(node, link, node.links.filter(f =>
      f[0] !== link[0] &&
      (!(link[1] & linkTypeEnum.START)) == (!(f[1] & linkTypeEnum.START))) // Same side as the targeted segment
    )]);
  }

  private static getNextTemplate(current: RoadNode<GeometryModel>,
                                 target: [RoadNode<GeometryModel>, linkTypeEnum],
                                 others: [RoadNode<GeometryModel>, linkTypeEnum][]): linkTypeEnum {

    if (others.length != 1 || // TODO make this consider stuff with more than one other link
      ((current.next.length > 0 && target[1] & linkTypeEnum.END) || (current.prev.length > 0 && target[1] & linkTypeEnum.START)) ||
      !(target[1] & linkTypeEnum.UNKNOWN))
      return 0;

    if (this.f_2x1_dual_axis_to_1x2_template(current, target, others[0]))
      return linkTypeEnum.NEXT;
    if (this.f_2x1_dual_axis_to_1x2_template(current, others[0], target))
      return linkTypeEnum.PREV;
    return 0
  }

  private static getIntersectionTemplate(current: RoadNode<GeometryModel>,
                                         target: [RoadNode<GeometryModel>, linkTypeEnum],
                                         others: [RoadNode<GeometryModel>, linkTypeEnum][]): linkTypeEnum {

    // if (idt(current) == 'f9903b91-85e4-4ebe-b6b9-8d32ac5628f4') {
    //     console.log("conditions for", idt(current), (target[1] & linkTypeEnum.END ? current.next.length : current.prev.length) + others.length < 1,
    //         !(target[1] & linkTypeEnum.UNKNOWN), current.dt.properties.segment_type == SegmentTypeEnum.ROUNDABOUT, target[0].dt.properties.segment_type == SegmentTypeEnum.ROUNDABOUT ||
    //         others.some(o => o[0].dt.properties.segment_type == SegmentTypeEnum.ROUNDABOUT))
    //
    //     console.log((target[1] & linkTypeEnum.END ? current.next.length : current.prev.length), others.length)
    //     console.log(target[1] & linkTypeEnum.END ? "next" : "prev", idt(target[1] & linkTypeEnum.END ? current.next[0] : current.prev[0]))
    // }

    if ((target[1] & linkTypeEnum.END ? current.next.length : current.prev.length) + others.length < 1 || // Not enough relations to create an intersection
      !(target[1] & linkTypeEnum.UNKNOWN) || // Already associated to an other relation
      current.dt.properties.segment_type == SegmentTypeEnum.ROUNDABOUT ||
      target[0].dt.properties.segment_type == SegmentTypeEnum.ROUNDABOUT ||
      others.some(o => o[0].dt.properties.segment_type == SegmentTypeEnum.ROUNDABOUT))
      return 0;

    // if (idt(current) == 'f9903b91-85e4-4ebe-b6b9-8d32ac5628f4') {
    //     console.log("check f_T_Junction_template for", idt(current), "to", idt(target[0]))
    //     others.forEach(item => console.log("link", idt(item[0])))
    // }
    if (this.f_T_Junction_template(current, target, others)) {
      // console.log("T JUNCTION detected on segment", idt(current))
      return linkTypeEnum.T_JUNCTION;
    }
    if (this.f_Crossroads_template(current, target, others))
      return linkTypeEnum.CROSSROADS;
    return 0
  }


  /** METHODS IDENTIFYING ROAD TEMPLATES **/


  /**
   *
   * Created to prevent S1 from being next of S2
   *
   *                      __S1__<___     / S3
   *                    /           \  /
   *     --S0----<->----             :|
   *                    \__S2__>____/ \
   *                                   \
   *                                    ^
   *                                     \
   *
   * @param contenders
   * @param currentSegment
   * @param potentialNextSegment
   * @param others
   * @private
   */
  private static f_2x1_to_2x1_perpendicular_template(contenders: RoadNode<GeometryModel>[], currentSegment: RoadNode<GeometryModel>, potentialNextSegment: RoadNode<GeometryModel>, others: RoadNode<GeometryModel>[]): boolean {
    // TODO it would be better to compute one first layer with basic "next" stuff only, and then to compute a second layer with advanced relation detection between roads (a layer that include road geometry and because of this may contains errors).

    let ctd_tmp: RoadNode<GeometryModel>[] = contenders.filter(value => value !== potentialNextSegment);

    return ctd_tmp.length == 2 &&
      currentSegment.dt.properties.segment_axis == 'DIRECT_AXIS' &&
      currentSegment.dt.properties.segment_type != SegmentTypeEnum.ROUNDABOUT &&
      !contenders.some(x => x.dt.properties.segment_axis != 'DIRECT_AXIS') &&

      !GraphHelper.f_direct_axis_crossing_direct_axis_way_template(contenders, potentialNextSegment, currentSegment)
  }

  /**
   *
   * Created to prevent S1 from being next of S2
   *                      __S1__>___
   *                    /
   *     --S0----<->----
   *                    \__S2__<____
   *
   *
   * @param s0
   * @param s1
   * @param s2
   * @private
   */
  private static f_2x1_dual_axis_to_1x2_template(s0: RoadNode<GeometryModel>, s1: [RoadNode<GeometryModel>, linkTypeEnum], s2: [RoadNode<GeometryModel>, linkTypeEnum]): boolean {

    return (
      s0.dt.properties.segment_axis == 'DUAL_AXIS' &&
      s2[0].dt.properties.segment_axis == 'DIRECT_AXIS' &&
      s1[0].dt.properties.segment_axis == 'DIRECT_AXIS' &&
      s0.dt.properties.segment_class == s1[0].dt.properties.segment_class &&
      s0.dt.properties.segment_class == s2[0].dt.properties.segment_class &&
      ((s1[1] & linkTypeEnum.END && this.areFollowing(s0.dt, s1[0].dt, false) && this.areFollowing(s2[0].dt, LineStringHelper.reverseLineString(s0.dt.clone()), false)) ||
        (s1[1] & linkTypeEnum.START && this.areFollowing(LineStringHelper.reverseLineString(s1[0].dt.clone()), s0.dt, false) && this.areFollowing(s2[0].dt, s0.dt, false))) &&
      !this.areFollowing(s1[0].dt, s2[0].dt, false) && s1[1] != s2[1]
    )
  }

  private static f_direct_axis_to_other_by_roundabout_template(contenders: RoadNode<GeometryModel>[], currentSegment: RoadNode<GeometryModel>, potentialNextSegment: RoadNode<GeometryModel>, others: RoadNode<GeometryModel>[]): boolean {
    return (contenders.length >= 2 &&
      currentSegment.dt.properties.segment_type != SegmentTypeEnum.ROUNDABOUT &&
      contenders.some(value => value.dt.properties.segment_type == SegmentTypeEnum.ROUNDABOUT))
  }

  // private static f_dual_axis_crossing_direct_axis_way_template(contenders: RoadNode<GeometryModel>[], potentialNextSegment: RoadNode<GeometryModel>, currentSegment: RoadNode<GeometryModel>) {
  //     /**
  //      *
  //      * Prevent S1 and S2 from being considered as S1 and S2 in f_2x1_to_1x2_template function
  //      *
  //      *              ||
  //      *  --S1-->----- ---S2--->--
  //      *             ||
  //      */
  //
  //     let ctd_tmp: RoadNode<GeometryModel>[] = contenders.filter(value => value !== potentialNextSegment);
  //     return ctd_tmp.length >= 1
  //         && potentialNextSegment.dt.properties.segment_axis == 'DIRECT_AXIS'
  //         && currentSegment.dt.properties.segment_axis == 'DIRECT_AXIS'
  //         && !ctd_tmp.some(value => (value.dt.properties.segment_axis != 'DUAL_AXIS'))
  //
  //         && potentialNextSegment.dt.properties.segment_class == currentSegment.dt.properties.segment_class
  //
  //         && currentSegment.dt.properties.segment_type != SegmentTypeEnum.ROUNDABOUT
  //
  //         && this.areFollowing(currentSegment.dt, potentialNextSegment.dt);
  // }

  /**
   *
   * Prevent S1 and S2 from being considered as S1 and S2 in f_2x1_to_2x1_perpendicular_template function
   *               |
   *  --S1-->----- ---S2--->--
   *              |
   *
   * @param contenders
   * @param potentialNextSegment
   * @param currentSegment
   * @private
   */
  private static f_direct_axis_crossing_direct_axis_way_template(contenders: RoadNode<GeometryModel>[], potentialNextSegment: RoadNode<GeometryModel>, currentSegment: RoadNode<GeometryModel>) {

    let ctd_tmp: RoadNode<GeometryModel>[] = contenders.filter(value => value !== potentialNextSegment);

    return ctd_tmp.length == 2
      && potentialNextSegment.dt.properties.segment_axis == 'DIRECT_AXIS'
      && !contenders.some(value => (value.dt.properties.segment_axis != 'DIRECT_AXIS'))

      && potentialNextSegment.dt.properties.segment_class == currentSegment.dt.properties.segment_class

      && currentSegment.dt.properties.segment_type != SegmentTypeEnum.ROUNDABOUT
      && potentialNextSegment.dt.properties.segment_type != SegmentTypeEnum.ROUNDABOUT

      && this.areFollowing(currentSegment.dt, potentialNextSegment.dt);
  }

  /**
   *
   * One dual axis main road connected to an other dual axis road
   *          //
   *         //
   * ================ (main road)
   *
   * @param current
   * @param target
   * @param other
   * @private
   */
  private static f_T_Junction_template(current: RoadNode<GeometryModel>, target: [RoadNode<GeometryModel>, linkTypeEnum], other: [RoadNode<GeometryModel>, linkTypeEnum][]) {

    return current.next.length == 1 &&
      other.length == 0 &&
      target[1] & linkTypeEnum.END
    // && current.dt.properties.segment_axis == 'DUAL_AXIS' && target[0].dt.properties.segment_axis == 'DUAL_AXIS'

    // TODO T junction should be junctions where you can go on the main road from the secondary road. This is not implemented yet
  }

  /**
   *
   * 2 main axes crossing each other
   *         ||
   *         ||
   * ================
   *         ||
   *         ||
   *
   * @param current
   * @param target
   * @param other
   * @private
   */
  private static f_Crossroads_template(current: RoadNode<GeometryModel>, target: [RoadNode<GeometryModel>, linkTypeEnum], other: [RoadNode<GeometryModel>, linkTypeEnum][]) {

    // if (current.next.length == 1 &&
    //     other.length == 1 &&
    //     (other[0][0].next[0] == target[0] || other[0][0].prev[0] == target[0]))
    //     console.log("f_Crossroads_template valid on", idt(current.next[0]), idt(target[0]), idt(other[0][0]))
    return current.next.length == 1 &&
      other.length == 1 &&
      target[1] & linkTypeEnum.END &&
      (other[0][0].next[0] == target[0] || other[0][0].prev[0] == target[0]) // The 2 other follow each other
  }
}

export function getRandomColor() {
  let letters: string = '0123456789ABCDEF';
  let color: string = '#';
  for (let i: number = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function slow(c: number = 1000000000) { // TODO remove me
  let i = 0;
  while (i < c) {
    i++;
  }
  console.log("slow", i);
}
