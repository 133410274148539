import { Component, OnInit	        }   from '@angular/core';
import { Session	                }   from 'src/app/globals/session';
import { PanelService	            }   from 'src/app/shared/panel/panel.service';
import { RoadFsiPanelComponent	    }   from './road-fsi-entry-panel/road-fsi-entry-panel.component';
import { RoadFsiStoreService        }   from './road-fsi-store.service';
import Matomo                           from "../../../../stats";

@Component({
  selector      : 'app-road-fsi-entry',
  templateUrl   : './road-fsi-entry.component.html',
  styleUrls     : ['./road-fsi-entry.component.css']
})

export class RoadFsiEntryComponent implements OnInit {
    constructor(
          public session                : Session
        , public roadFsiStoreService    : RoadFsiStoreService 
        , public panelService           : PanelService
    ) { }

    ngOnInit(): void {
    }

    openMenuRoadFsi(){
        this.panelService.push( RoadFsiPanelComponent, null)
        Matomo.push(['trackEvent', 'Actions', 'Opened crash menu'])
    }

    close(event:Event){
        this.roadFsiStoreService.reset();
        event.preventDefault();
        event.stopPropagation();
        Matomo.push(['trackEvent', 'Actions', 'Removed crash elements'])
    }
}
