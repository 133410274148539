import { Component, OnInit                      }   from '@angular/core';
import { BackgroundStoreService, BackgroundType }   from './background-store.service';
import { TranslateService                       }   from "@ngx-translate/core";
import Matomo                                       from "../../../../stats";

type BackgroundOption = {name:string, type: BackgroundType}
@Component({
    selector      : 'app-background-entry',
    templateUrl   : './background-entry.component.html',
    styleUrls     : ['./background-entry.component.css']
})
export class BackgroundEntryComponent implements OnInit {
    backgroundOptions : BackgroundOption[] = [
          { name: "Par défaut"      , type: "DEFAULT" }
        , { name: "Satellite"       , type: "SATELLITE" }
        , { name: "Statellite IGN©" , type: "IGN_SATELLITE" }
    ]

    selectedBackground: BackgroundOption;
    isShowBackground = false;

    setBackground(background: BackgroundType){
        this.backgroundStore.state = {currentBackground: background}
    }

    toggleMenuBackground(){
        this.isShowBackground = !this.isShowBackground
        Matomo.push(['trackEvent', 'Actions', `${ this.isShowBackground ? 'Opened' : 'Closed'} background menu`])
    }

    constructor(
          private backgroundStore   : BackgroundStoreService
        , private translate         : TranslateService
    ) {
        this.selectedBackground = this.backgroundOptions.find(b => b.type == backgroundStore.state.currentBackground)
    }

    ngOnInit(): void { }
}
