<li class="listMenu" *ngIf="session.hasRole('ROLE_MAP_SIGN')"
    appWorkspaceRequired
    [ngClass]="{'itemChecked':signStore.state._isShowElement, 'itemUnchecked': !signStore.state._isShowElement}"
    (click)="openMenuSign()">
  <i class="fa fa-exclamation-triangle"></i>
  <span class="text">{{ 'signEntry.title' | translate }}</span><br/>
  <a role="button" *ngIf="signStore.state._isShowElement">
    <span (click)="close($event)" class="pi pi-times ng-tns-c48-2">
    </span>
  </a>
</li>
