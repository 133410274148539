import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {Session} from "../globals/session";
import {HeaderModel} from "../models/header-model";
import {v4 as uuid} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class RequestHelper {

  constructor(private session: Session) {}

  createHeader(levels: ResponseLevelEnum[], limitOffset: number = 0, limitCount: number = 10): HeaderModel {

    let header: HeaderModel = new HeaderModel();

    header.limitOffset = limitOffset;
    header.limitCount = limitCount;

    header.requestDate = new Date();
    header.requestIdentifier = uuid();

    header.responseLevel = levels;
    header.sessionIdentifier = this.session.sessionIdentifier;

    return header;
  }

  createHttpHeaders(): HttpHeaders {

    let headers: HttpHeaders = this.createHttpJsonHeaders();
    // headers = headers.append(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BASIC + ' ' + this.session.token);
    return headers;
  }

  createHttpJsonHeaders(): HttpHeaders {

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON);
    return headers;
  }

}
