import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class SpiderifyHelper {

  private messageSource = new BehaviorSubject("");
  currentMessage = this.messageSource.asObservable();
  private showElement = new BehaviorSubject("");
  currentShow: Observable<string> = this.showElement.asObservable();
  private clearElement = new BehaviorSubject(true);
  currentDelete :Observable<boolean> = this.clearElement.asObservable();

  constructor() { }

  transferFeatures(message: any) {
    this.messageSource.next(message)
  }
  onShowElement(mess: string) {
    this.showElement.next(mess)
  }
  onClearElements(mess:boolean){
    this.clearElement.next(mess)
  }

}
