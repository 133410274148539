import { Component, OnInit              }   from '@angular/core';
import { Session                        }   from "../../../../globals/session";
import { PanelService                   }   from "../../../../shared/panel/panel.service";
import Matomo                               from "../../../../stats";
import { RoadFlowStoreService           }   from "./road-flow-store.service";
import { RoadFlowEntryPanelComponent    }   from "./road-flow-entry-panel/road-flow-entry-panel.component";

@Component({
    selector        : 'app-road-flow-entry',
    templateUrl     : './road-flow-entry.component.html',
    styleUrls       : ['./road-flow-entry.component.css']
})
export class RoadFlowEntryComponent implements OnInit {

    constructor(
          public session        : Session
        , public roadFlowStore  : RoadFlowStoreService
        , public panelService   : PanelService
    ) { }

    ngOnInit(): void {
    }

    openMenuRoadFlow(){
        this.panelService.push(
            RoadFlowEntryPanelComponent,
            null
        )
        Matomo.push(['trackEvent', 'Actions', 'Opened roadFlow menu'])
    }

    close(event:Event){
        event.preventDefault();
        event.stopPropagation();
        this.roadFlowStore.reset();
        Matomo.push(['trackEvent', 'Actions', 'Removed roadFlow'])
    }
}
