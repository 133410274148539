import { Injectable                             } from '@angular/core';
import { HttpClient                             } from "@angular/common/http";
import { TranslateService                       } from "@ngx-translate/core";
import { catchError, map                        } from "rxjs/operators";
import { throwError                             } from "rxjs";
import { MessageService                         } from "primeng/api";
import { MapService                             } from 'src/app/shared/map/map.service';
import { SpinnerService                         } from 'src/app/shared/spinner/spinner.service';
import { ToastService                           } from 'src/app/shared/toast/toast.service';
import { SearchStoreService                     } from "src/app/components/main/search/search-store.service"  ;
import { RoadFlowState, RoadFlowStoreService    } from "./road-flow-store.service";
import { environment                            } from "../../../../../environments/environment";
import { MapLayerEnum                           } from "../../../../enums/map-layer-enum.enum";
import { MapSourceEnum                          } from "../../../../enums/map-source-enum.enum";
import { MessageSeverityEnum                    } from "../../../../enums/message-severity-enum.enum";
import { RoadFlowModel                          } from "../../../../models/roadFlow/road-flow-model";
import { MessageHelper                          } from "../../../../helpers/message.helper";


declare let gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class RoadFlowService {
    subscriptions = []

    get spinner()   { return this.spinnerService.getSpinner()   ; }
    get map()       { return this.mapService.getMap()           ; }
    get toast()     { return this.toastService.getToast()       ; }

    constructor(
            private roadFlowStore   : RoadFlowStoreService
        ,   private mapService      : MapService
        ,   private spinnerService  : SpinnerService
        ,   public searchStore      : SearchStoreService
        ,   private toastService    : ToastService
        ,   private httpService     : HttpClient
        ,   private messageService  : MessageService
        ,   private translate       : TranslateService) {
        this.roadFlowStore.applyAndWatch(this.searchWrapper.bind(this))
    }

    private searchWrapper(state: RoadFlowState){
        this.mapService.callWhenAvailable(_ => this.updateSearchWrapper(state));
    }

    updateSearchWrapper(state: RoadFlowState){
        this.mapService.callWhenAvailable(_ => this.updateSearch(state));
    }

    formatNumberWithSpaces(number: number): string {
        let numberString = number.toString();

        const groups = [];
        while (numberString.length > 3) {
            groups.push(numberString.substr(-3));
            numberString = numberString.slice(0, -3);
        }

        groups.push(numberString);
        groups.reverse();
        return groups.join(" ");
    }

    async updateSearch(state: RoadFlowState) {
        if (state._isShowElement === true) {
            /* 
             * API URL CONSTRUCTION _______________________________________________ 
             */
            let _USER_DEFINED_POLYGONS_HAVE_NO_CRS = 'crs'; // ad hoc...
            let hasDrawnGeometry :boolean   = this.searchStore.state.drawnGeometry && !this.searchStore.state.drawnGeometry[_USER_DEFINED_POLYGONS_HAVE_NO_CRS] ;
            const selectedUserPolygon       = hasDrawnGeometry                  ? this.searchStore.state.drawnGeometry : null           ;
            const selectedQuantiles         = state.selectedQuantiles.length    ? state.selectedQuantiles       : state.quantiles       ;
            const selectedRoadTypes         = state.selectedRoadTypes.length    ? state.selectedRoadTypes       : state.roadTypes       ;
            const selectedMetric            = state.selectedMetric              ? state.selectedMetric          : state.metrics         ;
            const selectedCutoffs           = state.selectedCutoffs                                                                     ;
            let tileUrl: string =`${environment.urlRoadFlow}` 
                + `?selectedMetric=${selectedMetric}`
                + (!hasDrawnGeometry ? `` : `&selectedUserPolygon=${encodeURIComponent(JSON.stringify(selectedUserPolygon))}`); 
            for(let x of selectedQuantiles){ 
                selectedCutoffs['' + x]     = selectedCutoffs['' + x] || state.cutoffs['' + x]; 
                tileUrl                     += `&selectedQuantiles=${String(x)}&selectedCutoffs=${String(selectedCutoffs['' + x])}`;
            }
            for(let x of selectedRoadTypes) tileUrl += `&selectedRoadTypes=${String(x)}`;

            let layoutLane = {
                'line-cap': 'round',
                'line-join': 'round'
            };

            /*
             * MAPBOX / PARAMETERS TO COLORIZE LINESTRINGS
             */
            let cutoffs = selectedCutoffs;
            const roadFlowLevel7 = [        ">=" , ["get", "aadt"], cutoffs['7']                                             ];
            const roadFlowLevel6 = ["all", [">=" , ["get", "aadt"], cutoffs['6']], ["<", ["get", "aadt"], cutoffs['7']]];
            const roadFlowLevel5 = ["all", [">=" , ["get", "aadt"], cutoffs['5']], ["<", ["get", "aadt"], cutoffs['6']]];
            const roadFlowLevel4 = ["all", [">=" , ["get", "aadt"], cutoffs['4']], ["<", ["get", "aadt"], cutoffs['5']]];
            const roadFlowLevel3 = ["all", [">=" , ["get", "aadt"], cutoffs['3']], ["<", ["get", "aadt"], cutoffs['4']]];
            const roadFlowLevel2 = ["all", [">=" , ["get", "aadt"], cutoffs['2']], ["<", ["get", "aadt"], cutoffs['3']]];
            const roadFlowLevel1 = ["all", [">=" , ["get", "aadt"], cutoffs['1']], ["<", ["get", "aadt"], cutoffs['2']]];
            const roadFlowLevelNA= [        "<"  , ["get", "aadt"], cutoffs['1']       ];
            /*
, 'line-dasharray': [
                  'case'
                  , ["==", ["get", "is_est"], true ], 5
                  , ["==", ["get", "is_est"], false], 0

              , 'line-dasharray': [
                  "match"   , ["get", "is_est"]
                  , "true"  , ["literal", [5, 5]]
                  , ["literal", [0, 0]]
              ]
              ]*/
            let paint = {
                'line-width'    : 3
              , 'line-color'    : [
                    'case'
                    , roadFlowLevel7  , '#d73027' 
                    , roadFlowLevel6  , '#fc8d59' 
                    , roadFlowLevel5  , '#fee090'
                    , roadFlowLevel4  , '#ffffbf'
                    , roadFlowLevel3  , '#e0f3f8'
                    , roadFlowLevel2  , '#91bfdb'
                    , roadFlowLevel1  , '#4575b4'
                    , roadFlowLevelNA , '#636363'
                    , '#636363' 
                ]
            };

            let layerName         :string = MapLayerEnum.LAYER_ROAD_FLOW;
            let sourceLayer       :string = MapSourceEnum.SOURCE_LAYER_FLOW;
            let sourceName        :string = MapSourceEnum.SOURCE_LAYER_FLOW;
            let beforeWorkspace   :boolean= false;
            this.map.addLineVectorLayerTiles(layerName, tileUrl, sourceLayer, sourceName, beforeWorkspace, layoutLane, paint);

            /* 
             * EVENT HANDLERS ____________________________________________________ 
             */
            this.map.map.on('click', MapLayerEnum.LAYER_ROAD_FLOW, (e) => {
                e.preventDefault();

                const coordinates   = [e.lngLat.lng, e.lngLat.lat];
                const roadType      : any       = e.features[0].properties["road_type"  ];
                const aadtNumber    : number    = e.features[0].properties["aadt"       ];
                const aadt          : string    = this.formatNumberWithSpaces(aadtNumber);

                let roadTypeTranslate: string;
                this.translate.get('common.roadType').subscribe((response: string) => {
                    roadTypeTranslate = response + ': ';
                })
                let aadtTranslate: string;
                this.translate.get('roadFlowEntry.aadt').subscribe((response: string) => {
                    aadtTranslate = response + ': ';
                })
                const description: string =  `
                    <p>
                        <b>${roadTypeTranslate}</b>: ${roadType}<br>
                        <b>${aadtTranslate}</b>: ${aadt}
                    </p>`
                this.map.addPopup(coordinates, description);
            });
            this.map.map.on('mouseenter', MapLayerEnum.LAYER_ROAD_FLOW, () => {
                this.map.map.getCanvas().style.cursor = 'pointer';
            });
            this.map.map.on('mouseleave', MapLayerEnum.LAYER_ROAD_FLOW, () => {
                this.map.map.getCanvas().style.cursor = 'default';
            });
        } else {
            this.map.removeLayer(MapLayerEnum.LAYER_ROAD_FLOW, MapSourceEnum.SOURCE_LAYER_FLOW);
        }

        /* 
         * ANALYTICS ____________________________________________________ 
         */
        if (environment.googleTracking) {
            gtag('event', 'showRoadFlow');
        }
    }

}
