import { Injectable } from '@angular/core';
import { Store } from 'src/app/globals/store';

export class VisibilityState{
  _isShowElement: boolean = false;
  _isVisible: boolean = true;

  _curveProfile: string = 'NONE';
}

@Injectable({
  providedIn: 'root'
})
export class VisibilityStoreService extends Store<VisibilityState>{

  constructor() {
    super(VisibilityState)
  }
}
