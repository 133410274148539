import {Injectable} from '@angular/core';
import {MapLayerEnum} from 'src/app/enums/map-layer-enum.enum';
import {MapSourceEnum} from 'src/app/enums/map-source-enum.enum';
import {MapService} from 'src/app/shared/map/map.service';
import {environment} from 'src/environments/environment';
import {BackgroundState, BackgroundStoreService} from './background-store.service';
import {MapComponent} from '../../../map/map.component';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {

  constructor(private mapService: MapService,
              private backgroundStore: BackgroundStoreService) {
    this.setBackground(this.backgroundStore.state);
    this.backgroundStore.watch(this.setBackground.bind(this))
  }

  setBackground(backgroundState: BackgroundState) {
    this.resetBackground();
    switch (backgroundState.currentBackground) {
      case "IGN_SATELLITE":
        this.showBackgroundIgn();
        break;
      case "SATELLITE":
        this.showBackgroundSatellite();
        break;
      case "DEFAULT":
      default:
        this.displayBackground();
        break;
    }
  }

  showBackgroundIgn() {
    this.mapService.getMap()?.addRasterWmtsLayer(MapLayerEnum.LAYER_SATELLITE_IGN, MapSourceEnum.SOURCE_SATELLITE_IGN, environment.mapIgnUrl);
    this.mapService.getMap()?.moveLayerFirst(MapLayerEnum.LAYER_SATELLITE_IGN);
    this.mapService.getMap()?.map.setPaintProperty(MapLayerEnum.LAYER_WORKSPACE + "line", "line-color", MapComponent.getLineColorForWorkspace(this.mapService.map.map))
    this.mapService.getMap()?.map.setPaintProperty(MapLayerEnum.LAYER_WORKSPACE, "fill-color", "lightgrey")
  }

  showBackgroundSatellite() {
    this.mapService.getMap()?.addRasterLayer(MapLayerEnum.LAYER_SATELLITE, MapSourceEnum.SOURCE_SATELLITE, environment.mapStyleSatellite);
    this.mapService.getMap()?.moveLayerFirst(MapLayerEnum.LAYER_SATELLITE);
    this.mapService.getMap()?.map.setPaintProperty(MapLayerEnum.LAYER_WORKSPACE + "line", "line-color", MapComponent.getLineColorForWorkspace(this.mapService.map.map))
    this.mapService.getMap()?.map.setPaintProperty(MapLayerEnum.LAYER_WORKSPACE, "fill-color", "lightgrey")
  }

  resetBackground() {
    this.mapService.getMap()?.showBackground(false);
    this.mapService.getMap()?.removeLayer(MapLayerEnum.LAYER_SATELLITE_IGN, MapSourceEnum.SOURCE_SATELLITE_IGN);
    this.mapService.getMap()?.removeLayer(MapLayerEnum.LAYER_SATELLITE, MapSourceEnum.SOURCE_SATELLITE);
    this.mapService.getMap()?.map.setPaintProperty(MapLayerEnum.LAYER_WORKSPACE + "line", "line-color", MapComponent.getLineColorForWorkspace(this.mapService.map.map))
    this.mapService.getMap()?.map.setPaintProperty(MapLayerEnum.LAYER_WORKSPACE, "fill-color", "lightgrey")
  }

  displayBackground() {
    this.mapService.getMap()?.showBackground(true);
  }

}
