import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { ElementAlertPipe } from 'src/app/globals/pipes/element-alert.pipe';
import { Session } from 'src/app/globals/session';
import { AlertModel } from 'src/app/models/alerting/alert-model';
import { AlertPropertiesModel } from 'src/app/models/alerting/alert-properties-model';
import { FeatureObjectAlertModel } from 'src/app/models/geojson/feature-object-alert-model';
import { MasterDataService } from 'src/app/services/master-data.service';
import { MapService } from 'src/app/shared/map/map.service';
import { PanelBase } from 'src/app/shared/panel/component/PanelBase';
import { PanelService } from 'src/app/shared/panel/panel.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-alert-view-panel',
  templateUrl: './alert-view-panel.component.html',
  styleUrls: ['./alert-view-panel.component.css']
})
export class AlertViewPanelComponent implements OnInit, PanelBase<AlertModel> {
  get spinner(){
    return this.spinnerService.getSpinner();
  }

  get map(){
    return this.mapService.getMap();
  }

  get toast(){
    return  this.toastService.getToast();
  }
  // @Output() doReloadElements = new EventEmitter<boolean>();
  // @Output() doEditElement = new EventEmitter<object>();

  constructor(private masterdataService: MasterDataService,
              private elementAlertPipe: ElementAlertPipe,
              private spinnerService: SpinnerService,
              private mapService:MapService,
              private toastService: ToastService,
              private panelService: PanelService,
              public session: Session) { }
  data: AlertModel;

  unity: boolean = false;
  selectedAlert: AlertModel = new AlertModel();
  properties: AlertPropertiesModel = new AlertPropertiesModel();
  alertProperties: FeatureObjectAlertModel = new FeatureObjectAlertModel();
  images: any[];
  _isVisible: boolean = false;
  showImages = false;
  onEdition: boolean = false;
  measureDistance: number;
  linearLenght: number;
  type: string;
  category: string;
  impact: string;
  speed: string;
  speaker: string;
  screenDuration: string;
  duration: number;


  ngOnInit() {
    this.images = [];
    this.setElement(this.data);
  }

  show(show: boolean = true) {
    this._isVisible = show;
    if (!show ) {
      this.map.resizeElementsMarkers();
      this.panelService.close();
    }else{
      this.onEdition = false;
    }
  }

  setElement(element) {

    this.selectedAlert = element;
    delete this.selectedAlert.featureType;
    if (element !== undefined) {
      this.alertProperties = element.properties;
      this.properties = element.properties.alertProperties;
      this.images = [];
      this.showImages= false;

      this.measureDistance = this.properties.linearLength;
      const start = moment(this.alertProperties.alertDateBegin, 'YYYY-MM-DD, hh:mm');
      const end = moment(this.alertProperties.alertDateEnd, 'YYYY-MM-DD, hh:mm');
      this.duration = moment.duration(end.diff(start)).asMilliseconds();
      // @ts-ignore
      const humanizeDuration = require('humanize-duration');
      humanizeDuration(this.duration, { language: 'fr' });
      this.screenDuration = humanizeDuration(this.duration, { language: 'fr', round: true, units: ['d', 'h', 'm'] });

      this.speed = this.elementAlertPipe.transform(this.properties.alertSpeed);
      this.type = this.elementAlertPipe.transform(this.properties.alertType);
      this.category = this.elementAlertPipe.transform(this.properties.alertCategory);
      this.impact = this.elementAlertPipe.transform(this.properties.alertImpact);
      this.speaker = this.elementAlertPipe.transform(this.properties.alertSpeaker);

      if (this.measureDistance < 1) {
        this.linearLenght = this.measureDistance * 1000;
        this.unity = false;
      } if (this.measureDistance > 1){
        this.linearLenght = this.measureDistance;
        this.unity = true;
      }

      if(this.alertProperties.alertStorageUrl01 != undefined){
        this.images.push({source:this.alertProperties.alertStorageUrl01, alt:this.alertProperties.alertTimestamp, title:this.alertProperties.alertCategory} )
        this.showImages= true
      }
      if(this.alertProperties.alertStorageUrl02 != undefined){
        this.images.push({source:this.alertProperties.alertStorageUrl02, alt:this.alertProperties.alertTimestamp, title:this.alertProperties.alertCategory })
        this.showImages= true
      }
      if(this.alertProperties.alertStorageUrl03 != undefined){
        this.images.push({source:this.alertProperties.alertStorageUrl03, alt:this.alertProperties.alertTimestamp, title:this.alertProperties.alertCategory })
        this.showImages= true
      }
      if(this.alertProperties.alertStorageUrl04 != undefined){
        this.images.push({source:this.alertProperties.alertStorageUrl04, alt:this.alertProperties.alertTimestamp, title:this.alertProperties.alertCategory })
        this.showImages= true
      }
      this.show();
    } else {
      this.show(false);
    }
  }
}
