import {Injectable, OnInit} from '@angular/core';
import {FeatureCollectionModel} from 'src/app/models/geojson/feature-collection-model';
import {GeometryModel} from 'src/app/models/geojson/geometry-model';
import {GeojsonService} from 'src/app/services/geojson.service';
import {MapService} from 'src/app/shared/map/map.service';
import {StripState, StripStoreService} from './strip-store.service';
import {MapLayerEnum} from "../../../../enums/map-layer-enum.enum";


@Injectable({
  providedIn: 'root'
})
export class StripService implements OnInit {

  countRefresh = 0;

  constructor(private stripStore:StripStoreService,
              private mapService: MapService,
              private geojsonService: GeojsonService) {
    this.showBackgroundStrip(stripStore.state)
    this.stripStore.watch(this.showBackgroundStrip.bind(this))
  }
  ngOnInit(): void {
    this.mapService.getMap().onMapMoved.subscribe((geometry: GeometryModel) => {
      this.refreshBackgroundStrip(geometry);
    });
  }

  showBackgroundStrip(stripState: StripState) {
    let show = stripState.isShowBackgroundStrip;
    if (show === false) {
      this.removeBackgroundStrip();
    } else {
      this.mapService.getMap().isShowBackgroundStrip = true;
    }
  }

  refreshBackgroundStrip(geometry: GeometryModel) {
    this.countRefresh++;
    if (this.mapService.getMap().mapboxgl.getZoom() < 18) {
      this.mapService.getMap().removeLayersContain(MapLayerEnum.LAYER_STRIP_L);
      this.countRefresh = 0;
      return;
    }

    if (this.stripStore.state.isShowBackgroundStrip === true && this.mapService.getMap().mapboxgl.getZoom() > 18) {

      this.geojsonService.getObjectViewCollectionStrip(MapLayerEnum.LAYER_STRIP_L, undefined, geometry, 50).subscribe((response: FeatureCollectionModel) => {
        if (response && response.features.length > 0) {
          if (this.countRefresh > 4) {
            this.mapService.getMap().removeLayersContain(MapLayerEnum.LAYER_STRIP_L);
            this.countRefresh = 0;
            response.features.forEach((feature) => {
              this.mapService.getMap().addViewLayer(MapLayerEnum.LAYER_STRIP_L, feature);
            });
          } else {
            response.features.forEach((feature) => {
              this.mapService.getMap().addViewLayer(MapLayerEnum.LAYER_STRIP_L, feature);
            });
          }
        }
      });
    }
  }

  removeBackgroundStrip() {
    this.mapService.getMap()?.removeLayersContain(MapLayerEnum.LAYER_STRIP_L);
    if(this.mapService.getMap())
    {
        this.mapService.getMap().isShowBackgroundStrip = false;
    }
  }


}
